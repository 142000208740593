import {
  TemplateStructureEnum,
  ConfigurationPanelStructure,
  ConfigurationPanelStructureItemEnum,
  TabItem,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import { getCssPropertyByName } from '@/components/template-builder/getters';
// eslint-disable-next-line import/no-cycle
import { setCssProperty } from '@/components/template-builder/setters';
import getColorFieldConfig from '@/components/template-builder/config/fields/color-config';

export const rowTabItemConfig: TabItem = {
  type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
  tabItemType: TemplateStructureEnum.LINE,
  label: 'templateBuilder.configs.line',
  tabPanel: {
    label: 'templateBuilder.configs.lineEdit',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.line',
        selector: 'body #spm_body table#{LINE_ID}',
        items: [
          {
            label: 'templateBuilder.fields.backgroundColor',
            selector: '{SELECTOR}',
            properties: [{ name: 'background-color', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getColorFieldConfig(),
          },
        ],
      },
    ],
  },
};

const rowItemConfig: ConfigurationPanelStructure = {
  items: [
    rowTabItemConfig,
  ],
};

export default rowItemConfig;
