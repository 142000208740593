import { FiltreCurrentOrderFilters } from '@/components/automated-scenarios/metadata/filtres/FiltreCurrentOrderMetadata';
import { List, ListSettings } from '@/composables/GraphQL';
import { UserState } from '@/composables/User';
import { showToastError } from '@/helpers';
import { i18n } from '@/i18n';
import { DropdownOption, StringMap, TypeCampaignEnum } from '@/types';
import { ErrorConfigForm, FilterSegment } from '@/types/automated-scenarios';
import {
  OperatorType, ShopsManufacturers, ShopsOrdersStatus, ShopsProducts, ShopsProductsCategoriesList, ShopsVouchers,
} from '@/types/generated-types/graphql';
import { computed } from 'vue';
import { required } from '@vuelidate/validators';
import { itemsPickerValidator } from '@/helpers/CustomValidator';
import useVuelidate from '@vuelidate/core';

const fetchList = async <T>(name: string, settings: ListSettings, fields: string[]): Promise<T[]> => {
  const res = await List<T>(
    {
      name,
      settings,
      fields,
    },
  );
  if (res.err) {
    showToastError('GENERIC_ERROR');
  } else {
    return res.items;
  }
  return [];
};

const fetchStatuses = () => {
  let defaultLang: string = UserState.user.lang;
  if (UserState.activeShop) {
    if (!UserState.activeShop.langs.find((lang: any) => lang.id === defaultLang)) {
      const shopDefaultLang = UserState.activeShop.langs.find((lang: any) => lang.default);
      if (shopDefaultLang) {
        defaultLang = shopDefaultLang.id;
      }
    }
  }
  const settings = {
    limit: 1000,
    offset: 0,
    order: [],
    filter: [
      { field: 'id_shop', operator: OperatorType.Equals, value: UserState.activeShop ? UserState.activeShop.id : 0 },
      { field: 'lang', operator: OperatorType.Equals, value: defaultLang },
    ],
  };
  return fetchList<ShopsOrdersStatus>('ShopsOrdersStatus', settings, ['id_status', 'name']);
};

const fetchVouchers = (query: string) => {
  const settings = {
    limit: 1000,
    offset: 0,
    order: [],
    filter: [
      { field: 'id_shop', operator: OperatorType.Equals, value: UserState.activeShop ? UserState.activeShop.id : 0 },
      { field: 'code', operator: OperatorType.StartsWith, value: query },
    ],
  };
  return fetchList<ShopsVouchers>('ShopsVouchers', settings, ['code']);
};

let translation: any;
(async () => {
  translation = await i18n;
})();

export default async () => ([
  {
    id: 'filterproductinorder',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filterproductinorder', label: translation.global.t('automatedScenarios.fields.segments.productsInOrder') },
    fields: [
      {
        id: 'product_picker_order',
        component: 'ItemList',
        data: { selection: { selected: [], operator: 'and' } },
        props: {
          config: {
            mainLabel: 'automatedScenarios.fields.productList',
            buttonLabel: 'automatedScenarios.fields.chooseProducts',
            operatorLabel: 'automatedScenarios.fields.visitorMustHaveChosen',
            name: 'ShopsProducts',
            fields: { id: 'id_shop_product', label: 'name', productId: 'id_product' },
            defaultFilters: [{ field: 'lang', value: 'fr', operator: OperatorType.Equals }, { field: 'active', value: true, operator: OperatorType.Equals }],
            format: (product: ShopsProducts) => ({
              id: product.id_product, spmId: product.id_shop_product, name: product.name, combinations: {},
            }),
          },
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.numberOfSelectedProducts'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        product_picker_order: {
          required,
          itemsPickerValidator: itemsPickerValidator(),
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
  {
    id: 'filterproductcategoryinorder',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filterproductcategoryinorder', label: translation.global.t('automatedScenarios.fields.segments.categoriesOfProductsInOrder') },
    fields: [
      {
        id: 'product_picker_category',
        component: 'ItemList',
        data: { selection: { selected: [], operator: 'and' } },
        props: {
          config: {
            mainLabel: 'automatedScenarios.fields.categoryList',
            buttonLabel: 'automatedScenarios.fields.chooseCategories',
            operatorLabel: 'automatedScenarios.fields.visitorMustHaveChosen',
            name: 'ShopsProductsCategoriesList',
            fields: { id: 'id_shop_product_category_list', label: 'name', categoryId: 'id_category' },
            defaultFilters: [{ field: 'lang', value: 'fr', operator: OperatorType.Equals }],
            format: (category: ShopsProductsCategoriesList) => ({
              id: category.id_category, spmId: category.id_shop_product_category_list, name: category.name, combinations: {},
            }),
          },
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.numberOfSelectedCategories'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        product_picker_category: {
          required,
          itemsPickerValidator: itemsPickerValidator(),
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
  {
    id: 'filterproductmanufacturerinorder',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filterproductmanufacturerinorder', label: translation.global.t('automatedScenarios.fields.segments.manufacturersOfProductsInOrder') },
    fields: [
      {
        id: 'product_picker_manufacturer',
        component: 'ItemList',
        data: { selection: { selected: [], operator: 'and' } },
        props: {
          config: {
            mainLabel: 'automatedScenarios.fields.manufacturerList',
            buttonLabel: 'automatedScenarios.fields.chooseManufacturers',
            operatorLabel: 'automatedScenarios.fields.visitorMustHaveChosen',
            name: 'ShopsManufacturers',
            fields: { id: 'id_shop_manufacturer', label: 'name', manufacturerId: 'id_manufacturer' },
            defaultFilters: [],
            format: (manufacturer: ShopsManufacturers) => ({
              id: manufacturer.id_manufacturer, spmId: manufacturer.id_shop_manufacturer, name: manufacturer.name, combinations: {},
            }),
          },
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.numberOfSelectedManufacturers'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        product_picker_manufacturer: {
          required,
          itemsPickerValidator: itemsPickerValidator(),
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
  {
    id: 'filterorderamount',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filterorderamount', label: translation.global.t('automatedScenarios.fields.segments.orderAmount') },
    fields: [
      {
        id: 'order_amount_currency',
        component: 'NumberRange',
        data: { min: 1, max: 1000000 },
        props: {
          title: 'automatedScenarios.fields.orderAmount',
          suffix: ' €',
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.hasOrderedBetween'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        order_amount_currency: {
          required,
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    multiple: true,
  },
  {
    id: 'filterstatusorder',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filterstatusorder', label: translation.global.t('automatedScenarios.fields.segments.orderStatus') },
    fields: [
      {
        id: 'status_order',
        component: 'MultiDropdown',
        data: [],
        props: {
          label: 'automatedScenarios.fields.orderStatus',
          options: (await fetchStatuses()).map((item: ShopsOrdersStatus) => ({ value: item.id_status, label: item.name }) as DropdownOption),
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.orderStatus'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        status_order: {
          required,
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
  {
    id: 'filtervoucherused',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filtervoucherused', label: translation.global.t('automatedScenarios.fields.segments.vouchersUsed') },
    fields: [
      {
        id: 'voucherUsed',
        component: 'EditAutocomplete',
        data: [],
        props: {
          title: 'automatedScenarios.fields.vouchersUsed',
          multiple: true,
          field: 'code',
          complete: (query: string) => fetchVouchers(query),
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.remindVouchers'),
        format: (codes: StringMap) => Object.keys(codes).map((item) => ({ code: item })),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        voucherUsed: {
          required,
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
]) as FilterSegment<FiltreCurrentOrderFilters>[];
