<template>
  <div
    v-if="templatesType === 'none'"
  >
    <Dialog
      id="dialog-type-template"
      v-model:visible="displayDialog"
      :modal="true"
      :draggable="false"
      :closable="true"
      :breakpoints="{ '960px': '65vw', '640px': '90vw' }"
      :style="{ width: '45vw' }"
      @hide="handleCloseChoosingTemplatesType"
    >
      <template #header>
        <h3>{{ t('templateSelector.dialogTypeTemplate.header') }}</h3>
      </template>

      <div class="grid ">
        <div
          v-for="type in listTypes"
          :key="type"
          class="col-4"
        >
          <Button
            type="button"
            class="p-button-outlined p-button-success button-dialog-type"
            @click="chooseTemplatesType(type)"
          >
            <div class="icon">
              <i
                :class="t('templateSelector.dialogTypeTemplate.types.'+type+'.icon')"
              />
            </div>
            <div class="legend">
              {{ t('templateSelector.dialogTypeTemplate.types.'+type+'.label') }}
            </div>
          </Button>
        </div>
      </div>
    </Dialog>
  </div>
  <div
    v-else
  >
    <EditorModal
      :title="t('templateSelector.title')"
      :show-close-button="true"
      @on-close-modal="closeModal"
    >
      <template
        #content
      >
        <div class="p-4">
          <div class="grid p-fluid">
            <div class="col-10">
              <SelectButton
                v-model="templateCategorySelected"
                :options="templateCategoriesOptions"
                option-label="title"
                data-key="category"
                class="p-button-success"
              />
            </div>
          </div>
          <div
            v-if="templateCategorySelected.category"
            class="grid p-fluid"
          >
            <div class="col-5">
              <div class="p-inputgroup">
                <InputText
                  v-model="searchTemplatesInput"
                  class="form-control"
                  type="text"
                  :placeholder="t('templateSelector.searchInput')"
                  @keyup.enter="handleSearchTemplates(false)"
                />
                <Button
                  icon="far fa-search"
                  :label="t('templateSelector.buttons.search')"
                  class="p-button-success"
                  @click="handleSearchTemplates(false)"
                />
              </div>
            </div>
          </div>
          <div
            class="mt-3"
            style="text-align: center"
          >
            <Loader v-if="isListLoading" />
            <div
              v-else-if="!isListLoading"
              class="flex justify-content-start"
            >
              <div
                v-if="list.length"
                class="grid flex flex-grow-1 justify-content-start"
              >
                <div
                  v-for="record of list"
                  :key="record.id_template"
                  class="ctx xl:col-3 lg:col-3 md:col-4 sm:col-6 mb-3 p-4"
                >
                  <div class="preview-label mb-2">
                    <span
                      v-tooltip="{ value: record.label }"
                      class="mb-3 font-bold"
                    >
                      {{ record.label }}
                    </span>
                  </div>

                  <div class="preview-container-template">
                    <img
                      :src="`${baseUrl + record.imageKey}${isDisplayTemplate(templatesType) ? '-isdisplay' : ''}.png&w=400&${new Date().getTime()}`"
                      style="display: none"
                      @load="imageLoaded(record.id_template)"
                      @error="imageFailed(record.id_template)"
                    >
                    <div
                      v-if="imageLoadTime[record.id_template] && imageLoadTime[record.id_template] > 0"
                      class="template_preview_container"
                      :style="`background-image:url(${baseUrl + record.imageKey}${isDisplayTemplate(templatesType) ? '-isdisplay' : ''}.png&w=400&${imageLoadTime[record.id_template]}`"
                    />
                    <Button
                      v-if="imageLoadTime[record.id_template] && imageLoadTime[record.id_template] > 0"
                      icon="far fa-search-plus"
                      :label="t('templateSelector.buttons.previewTemplate')"
                      class="p-button p-button-secondary preview-button"
                      @click="handlePreviewImage(record.id_template)"
                    />
                    <div
                      v-else
                      class="template_preview_container"
                      style="  display: flex;   justify-content: center;   align-items: center;"
                    >
                      <ProgressSpinner
                        style="width: 25px; height: 25px"
                        stroke-width="8"
                        fill="var(--surface-ground)"
                        animation-duration="1.0s"
                        aria-label="Custom ProgressSpinner"
                      />
                    </div>
                  </div>
                  <div class="action-buttons">
                    <Button
                      :label="t('templateSelector.buttons.chooseTemplate')"
                      class="p-button-sm p-button-success"
                      @click="handleChooseTemplate(record.id_template, record.label)"
                    />
                  </div>
                </div>
                <div class="flex-new-line" />
                <Button
                  class="center mt-2 mb-2"
                  :label="t('templateSelector.buttons.loadMore')"
                  @click="handleSearchTemplates(true)"
                />
              </div>
              <div
                v-else
                class="flex justify-content-between"
              >
                <span>{{ t('templateSelector.list.empty') }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </EditorModal>
    <PreviewModal
      v-model:visible="displayPreviewModal"
      :images="imagesPreview"
      @on-close-preview-modal="handleClosePreview"
    />
  </div>
</template>

<script lang="ts">
import Button from 'primevue/button';
import {
  defineComponent, ref, Ref, SetupContext, onMounted, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
  ToolbarItemsModel,
} from '@/types';
import EditorModal from '@/components/modals/EditorModal.vue';
import SelectButton from 'primevue/selectbutton';
import InputText from 'primevue/inputtext';
import { TemplatesCustom } from '@/types/generated-types/graphql';
import { fetchRecursiveTemplate, fetchUserTemplates } from '@/composables/template/TemplateHelper';
import Loader from '@/components/layout/Loader.vue';
import { duplicateTemplate, TemplateTypeEnum } from '@/composables/shop/Templates';
import { UserState } from '@/composables/User';
import { asInt } from '@/helpers';
import PreviewModal from '@/components/modals/PreviewModal.vue';
import Dialog from 'primevue/dialog';
import { store } from '@/store';
import { isDisplayTemplate } from '@/components/template-builder/utils/helpers';
import Tooltip from 'primevue/tooltip';
import ProgressSpinner from 'primevue/progressspinner';
import { getCountOperatorsByType, mappingTemplateTypeToOperatorType } from '@/composables/AutomatedScenarios';

export default defineComponent({
  name: 'TemplateSelector',

  components: {
    PreviewModal,
    Button,
    EditorModal,
    SelectButton,
    InputText,
    Loader,
    Dialog,
    ProgressSpinner,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    typeTemplates: {
      type: String,
      required: true,
      default: '',
    },

    originCall: {
      type: String,
      required: true,
      default: '',
    },

    idCampaign: {
      type: Number,
      required: true,
    },

    duplicate: {
      type: Boolean,
      required: false,
      default: false,
    },

    baseLinkData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  emits: {
    'on-close-dialog': null,
    'on-choose-template': Object,
  },

  setup(props: { typeTemplates: string; originCall: string|null; idCampaign: number|null; duplicate: boolean; baseLinkData: any}, context: SetupContext) {
    const { t } = useI18n();
    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
    const baseUrl = `${process.env.VUE_APP_URL_MEDIAL_IMAGE_PREFIX_WITH_RESIZE}`;
    const imageLoadTime = ref<Record<number, number>>({});
    const imagesLoaded = ref<Record<number, boolean>>({});

    const displayDialog = ref(true);

    const listTypes = [TemplateTypeEnum.EMAIL, TemplateTypeEnum.SMS, TemplateTypeEnum.FACEBOOKMESSENGER,
      TemplateTypeEnum.PUSHNOTIFICATIONS, TemplateTypeEnum.POPUP, TemplateTypeEnum.EMBED];

    const list: Ref<TemplatesCustom[]> = ref([]);
    const templatesType = ref(props.typeTemplates);

    const searchTemplatesInput = ref('');
    const displayPreviewModal = ref(false);
    const imagesPreview = ref([{}]);
    const isListLoading = ref(false);
    let limit = 12;
    const templateCategorySelected = ref({ category: 'recent', title: t('templateSelector.categories.recent') });
    const templateCategoriesOptions = ref([
      { category: 'recent', title: t('templateSelector.categories.recent') },
      { category: 'gallery', title: t('templateSelector.categories.gallery') },
      { category: 'self', title: t('templateSelector.categories.self') },
    ]);

    const closeModal = () => {
      context.emit('on-close-dialog');
    };

    const getListTemplates = async (loadMore: boolean) => {
      let {
        // eslint-disable-next-line prefer-const
        originCall,
      } = props;
      if (templatesType.value !== 'none') {
        isListLoading.value = true;
        const result = await fetchUserTemplates(searchTemplatesInput.value, templateCategorySelected.value.category, templatesType.value, originCall, loadMore ? limit : 12);
        if (result) {
          list.value = result;
          isListLoading.value = false;
        }
      }
    };

    const imageLoaded = (id_template: number) => {
      // verifier si l'image est déjà marquée comme chargée
      if (!imagesLoaded.value[id_template]) {
        imagesLoaded.value[id_template] = true;
        imageLoadTime.value[id_template] = new Date().getTime();
      }
    };

    const imageFailed = (id_template: number) => {
      // Vérifier si l'image est déjà marquée comme chargée
      if (!imagesLoaded.value[id_template]) {
        setTimeout(() => {
          imageLoadTime.value[id_template] = new Date().getTime();
        }, 3000); // Tente de recharger l'image toutes les 3 secondes
      }
    };

    const handleSearchTemplates = async (loadMore: boolean) => {
      limit += 12;
      await getListTemplates(loadMore);
    };

    const handleChooseTemplate = (idTemplate: number, label: string) => {
      store.commit('general/showTheSpinner');
      let name: any = null;
      // duplicate template
      if (props.duplicate) {
        let {
          // eslint-disable-next-line prefer-const
          originCall, idCampaign, baseLinkData,
        } = props;

        if (originCall === 'myTemplates' || (originCall === 'templateBuilder' && !idCampaign)) {
          originCall = null;
          idCampaign = null;
          if (typeof baseLinkData === 'object'
              && Object.prototype.hasOwnProperty.call(baseLinkData, 'namePrimaryTemplate')
              && Object.prototype.hasOwnProperty.call(baseLinkData, 'countRedirectTemplate')) {
            name = `${baseLinkData.namePrimaryTemplate} - ${t(`templates.channels.${templatesType.value}`)} #${baseLinkData.countRedirectTemplate + 1}`;
          }
        } else {
          // choose template from campaign
          // eslint-disable-next-line no-lonely-if
          if (typeof baseLinkData === 'object'
              && Object.prototype.hasOwnProperty.call(baseLinkData, 'namePrimaryTemplate')
              && Object.prototype.hasOwnProperty.call(baseLinkData, 'countRedirectTemplate')) {
            name = `${baseLinkData.namePrimaryTemplate} - ${t(`templates.channels.${templatesType.value}`)} #${baseLinkData.countRedirectTemplate + 1}`;
          } else {
            // generate dynamic name
            const boxTypeToCount = mappingTemplateTypeToOperatorType[templatesType.value];
            const countOperatorType = getCountOperatorsByType(boxTypeToCount);
            name = `${t(`templates.channels.${templatesType.value}`)} #${countOperatorType + 1}`;
          }
        }

        duplicateTemplate(idShop.value, idTemplate, originCall, idCampaign, name).then((result) => {
          if (result && result.success && result.id) {
            context.emit('on-choose-template', asInt(result.id), '', templatesType.value);
          }
        });
      } else {
        context.emit('on-choose-template', idTemplate, label);
      }
    };

    const handlePreviewImage = async (idTemplate: number) => {
      const getTemplates = await fetchRecursiveTemplate(idTemplate);
      imagesPreview.value = [];
      if (getTemplates && getTemplates.length) {
        getTemplates.forEach((templateData) => {
          if (templateData.imageKey) {
            imagesPreview.value.push({
              itemImageSrc: `${baseUrl + templateData.imageKey}${isDisplayTemplate(templatesType.value) ? '-isdisplay' : ''}.png&w=400`,
              thumbnailImageSrc: `${baseUrl + templateData.imageKey}${isDisplayTemplate(templatesType.value) ? '-isdisplay' : ''}.png&w=400`,
              title: templateData?.label,
            });
          }
        });
        displayPreviewModal.value = true;
      }
    };

    const handleClosePreview = () => {
      displayPreviewModal.value = false;
    };

    const chooseTemplatesType = async (type: string) => {
      templatesType.value = type;
      await getListTemplates(false);
    };

    const handleCloseChoosingTemplatesType = () => {
      context.emit('on-close-dialog');
    };

    watch(templateCategorySelected, async () => {
      await getListTemplates(false);
    });

    onMounted(async () => {
      await getListTemplates(false);
    });

    return {
      t,
      displayPreviewModal,
      imagesPreview,
      templatesType,
      listTypes,
      handleCloseChoosingTemplatesType,
      chooseTemplatesType,
      handleClosePreview,
      handlePreviewImage,
      handleChooseTemplate,
      handleSearchTemplates,
      templateCategorySelected,
      templateCategoriesOptions,
      list,
      baseUrl,
      searchTemplatesInput,
      isListLoading,
      displayDialog,
      imageLoadTime,
      imageLoaded,
      imageFailed,
      closeModal,
    };
  },

  methods: { isDisplayTemplate },
});
</script>

<style scoped lang="scss">
$img-size: 12rem;

.flex-new-line {
  flex-basis: 100%;
  height: 0;
}

.button-dialog-type {
  width: 100%;
  height: 7rem;
  display: block;
  position: relative;
  border: solid 1px $solitude !important;
  color: $heather !important;

  &:hover {
    background: $solitude !important;
  }

  .icon {
    font-size: 2rem;
  }

  .legend {
    font-size: 1em;
    color: black;
  }
}

.template-image {
  width: $img-size;
  height: $img-size;
  object-fit: cover;
  object-position: bottom;
}

.preview-container {
  position: relative;
  width: $img-size;
  height: $img-size;
  margin: 1rem auto !important;
  &:hover {
    &::after {
      content: '';
      background: rgba(0, 0, 0, .3);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .p-button {
      display: block;
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
    }
  }
  .p-button {
    display: none;
  }
}

.file-manager {
  .gallery-image {
    width: $img-size;
    object-fit: cover;
    object-position: bottom;
  }

  .preview-container {
    position: relative;
    width: $img-size;
    margin: 1rem auto !important;
    &:hover {
      &::after {
        content: "";
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .action-buttons {
        display: block;
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
      }
    }
    .action-buttons {
      display: none;
    }
  }
  .dialog-footer {
    text-align: center !important;
    :deep() .menu-icon {
      font-size: 2rem !important;
      color: $brand-color-primary;
    }
  }
}

.wrapper {
  height: 100%;
}

.wrapper,
.content-wrapper {
  min-height: 100%;
}

.content {
  padding-top: 8rem;
  flex-grow: 1;
}

.template_search_group {
  margin: 0 5px 0 0;
  max-width: 800px;
}
.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0
}

.chooser-body {
  width: calc(100% - 40px);
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.preview-container-template {
  position: relative;

  .template_preview_container {
    width:100%;
    background-size: 100% auto;
    height:450px;
    text-align: center;
    padding: 2em;
    -webkit-flex:0 0 16%;
    -ms-flex: 0 0 16%;
    flex: 0 0 16%;
    background-position: center top;
    &:hover {
      webkit-animation: UPANDDOWN 10s infinite; /* Safari 4+ */
      -moz-animation:    UPANDDOWN 10s infinite; /* Fx 5+ */
      -o-animation:      UPANDDOWN 10s infinite; /* Opera 12+ */
      animation:         UPANDDOWN 10s infinite; /* IE 10+, Fx 29+ */
    }
  }

  .preview-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* centre le bouton */
    opacity: 0; /* cache le bouton par défaut */
    transition: opacity 0.3s ease; /* animation pour faire apparaître/disparaître le bouton */
  }
}

.template_preview_container:hover + .preview-button,
.preview-button:hover {
  opacity: 1;
}

.preview-label span {
  display: inline-block;
  max-width: 80%;  /* Assurez-vous que cela corresponde à la largeur du conteneur parent */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10%;
  margin-right: 10%;
}

.center {
  margin:0 auto;
  display:block;
}
</style>
