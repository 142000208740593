<template>
  <div
    class="flex flex-column justify-content-center"
    style="margin: auto; width: 70%"
  >
    <div class="flex auth-logo-icon">
      <img
        src="@/assets/images/logo-sm-green.svg"
        width="100%"
        alt="logo Icon"
      />
    </div>
    <h3 class="flex">
      {{ t('user.password.title') }}
    </h3>
    <p class="flex text-page my-2">
      {{ t('user.password.subTitle') }}
    </p>

    <form
      class="flex flex-column mt-4"
      data-test-id="form"
      @submit.prevent="validateRegisterForm"
    >
      <div class="flex flex-column">
        <div class="field mb-5">
          <BaseTextInput
            v-model="userInfo.email"
            class="mb-3"
            field-id="email"
            label="user.register.email"
            input-style="width: 100%;"
            :errors="formValidation"
          />
        </div>
      </div>

      <div class="flex flex-column">
        <SpmButton
          :label="t('user.password.reset')"
          type="submit"
          :loading="isLoadingForm"
          style="width: 100%"
          class="flex p-button p-button-success"
        />
      </div>

      <div class="flex align-items-center justify-content-center text-page mt-3">
        {{ t('user.password.remember') }}
        <router-link
          :to="{ name: 'login' }"
          class="text-page ml-1"
        >
          {{ t('user.password.logMe') }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { defineComponent, reactive, ref } from 'vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { emailFormatValidator } from '@/helpers/CustomValidator';
import useVuelidate from '@vuelidate/core';
import { showToastError, showToastSuccess } from '@/helpers';
import BaseTextInput from '@/components/fields/BaseTextInput.vue';
import { useRouter } from 'vue-router';
import { nestPost } from '@/composables/nestApi';

export default defineComponent({
  name: 'ForgotPassword',
  components: {
    BaseTextInput,
    SpmButton,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const isLoadingForm = ref(false);
    const formValidation = ref<any>({});

    const userInfo = reactive({
      email: '',
    });

    const validateRegisterFormData = async (): Promise<ErrorConfigForm> => {
      const rules = {
        email: {
          emailFormatValidator: emailFormatValidator(),
        },
      };

      const v$ = useVuelidate(rules, userInfo);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    const validateRegisterForm = async () => {
      isLoadingForm.value = true;
      const validation = await validateRegisterFormData();
      if (!validation.success) {
        formValidation.value = validation.validate;
        isLoadingForm.value = false;
      } else {
        try {
          const result = await nestPost('v4', '/user/forgot-password', {}, {
            email: userInfo.email,
          });

          if (result && result.success) {
            await showToastSuccess(t('user.password.success'));
            await router.push('/auth/login');
          }
        } catch (e) {
          await showToastError(t('errorMessages.GENERIC_ERROR'));
        }

        isLoadingForm.value = false;
      }
    };

    return {
      t,
      formValidation,
      userInfo,
      isLoadingForm,
      validateRegisterForm,
    };
  },
});
</script>

<style scoped lang="scss">
.text-page {
  font-size: 14px;
  line-height: 21px;
  color: rgb(107, 114, 128);
}
.auth-logo-icon {
  width: 6rem;
  margin-bottom:20px;
}
</style>
