
import {
  defineComponent,
  PropType,
  ref,
} from 'vue';
import draggable from 'vuedraggable';
import StatsBuilderRow from '@/components/stats-builder/StatsBuilderRow.vue';
import { StatsBuilderRowConfig } from '@/types/stats-editor-types';
import Tooltip from 'primevue/tooltip';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'StatsBuilder',

  components: {
    StatsBuilderRow,
    draggable,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    pageRowsState: {
      type: Array as PropType<StatsBuilderRowConfig[]>,
      required: true,
    },
  },

  setup(props: { pageRowsState: StatsBuilderRowConfig[] }) {
    const enabled = ref(true);
    const dragging = ref(false);
    const dragOptions = ref({
      animation: 200,
      group: { name: 'line_builder', pull: false, put: ['line_element', 'widget_element'] },
      disabled: false,
    });

    return {
      enabled,
      dragging,
      dragOptions,
    };
  },
});
