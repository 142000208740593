
import { defineComponent, ref } from 'vue';
import ToggleButton from 'primevue/togglebutton';
import OverlayPanel from 'primevue/overlaypanel';
import { VuemojiPicker, EmojiClickEventDetail } from 'vuemoji-picker';

export default defineComponent({
  name: 'EmojisButton',

  components: {
    VuemojiPicker,
    ToggleButton,
    OverlayPanel,
  },

  emits: {
    'on-choose-emoji': Object,
  },

  setup(_, context) {
    const displayEmojiPicker = ref();
    const buttonDisplayRef = ref();

    const computeDialogPosition = (event: any) => {
      displayEmojiPicker.value.show(event);
    };

    const handleEmojiClicked = (detail: EmojiClickEventDetail) => {
      context.emit('on-choose-emoji', detail.unicode);
      displayEmojiPicker.value.hide();
    };
    return {
      buttonDisplayRef,
      computeDialogPosition,
      displayEmojiPicker,
      handleEmojiClicked,
    };
  },

});
