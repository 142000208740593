
import { computed, defineComponent } from 'vue';
import {
  AutomatedScenarioState as state,
  OperatorBox,
} from '@/composables/AutomatedScenarios';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'FilterLinkedOperators',

  setup() {
    const { t } = useI18n();

    const linkedOperatorsData = computed(() => state.operators
      .filter((operator: OperatorBox) => state.linkedOperators.includes(operator.operatorId)));

    return {
      t,
      linkedOperatorsData,
    };
  },
});
