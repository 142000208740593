
import {
  computed,
  defineComponent, PropType, Ref, ref, watch,
} from 'vue';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Panel from 'primevue/panel';
import Dialog from 'primevue/dialog';
import { useI18n } from 'vue-i18n';
import {
  DropdownOption,
  FieldConfig,
  ActionTypeEnum,
  ParserFieldObject,
  Property,
  FacebookMessengerButton,
} from '@/types';
import InputVariables from '@/components/template-builder/fields/InputVariables.vue';
import ChooseTemplateModal from '@/components/modals/ChooseTemplateModal.vue';
import { Templates } from '@/types/generated-types/graphql';
import { Get } from '@/composables/GraphQL';
import { getAttributeByName } from '@/components/template-builder/getters';
import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import { store } from '@/store';

export default defineComponent({
  name: 'FacebookButtons',

  components: {
    LocalizedTextInput,
    ChooseTemplateModal,
    Panel,
    Dropdown,
    Button,
    Dialog,
    InputVariables,
    InputText,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const imageUrl = 'https://media.shopimind.io/img/templates_preview_v3/35b251d8f5dac17f80b3a2b34b9aae1abe24190b.png';
    const displayTemplateModal = ref(false);
    const displayTemplatePreview = ref(false);
    const indexForTemplateModal = ref(-1);

    const redirectTypes = ref(props.configs.options.redirectTypes);
    const properties: Ref<Array<Property>> = ref(props.parserValues.properties);
    const buttons: Ref<Property> = ref(properties.value.filter((property: Property) => property.name === 'buttons')[0]);

    const dataRedirectParserValues = computed((): Array<ParserFieldObject> => {
      const res: Array<ParserFieldObject> = [];

      buttons.value.value.forEach((button: FacebookMessengerButton, index: number) => {
        const parserValues: ParserFieldObject = {
          selector: `${props.parserValues.selector} .button:nth-child(${index})`,
          properties: [{
            name: 'data-redirect', getter: getAttributeByName, setters: [], value: button.dataRedirect,
          }],
        };

        res.push(parserValues);
      });

      return res;
    });

    const actionTypeOptions: DropdownOption[] = redirectTypes.value
      .map((key: string) => ({ value: key, label: t(`templateBuilder.fields.actionOptions.${key}`) }));

    // Handle each change after specific operations
    // eslint-disable-next-line no-shadow
    const handleChange = ({ selector, properties }: { selector: string; properties: Array<Property> }) => {
      context.emit('on-change-properties', {
        selector,
        properties,
      });
    };

    // Handle button label change
    const handleChangeButtonLabel = (index: number, params: any) => {
      buttons.value.value[index].text = params.target.value.trim();
      handleChange({ selector: props.parserValues.selector, properties: [buttons.value] });
    };

    // Handle button action change
    const handleChangeActionType = (index: number, { value }: { value: string }) => {
      buttons.value.value[index].action = value;
      buttons.value.value[index].dataRedirect = '';
      buttons.value.value[index].selectedTemplate = null;
      handleChange({ selector: props.parserValues.selector, properties: [buttons.value] });
    };

    // Handle button url change
    // eslint-disable-next-line no-shadow
    const handleChangeDataRedirect = (index: number, { properties }: { properties: Array<Property> }) => {
      const property = { ...properties[0] };
      buttons.value.value[index].dataRedirect = property.value;
      handleChange({ selector: props.parserValues.selector, properties: [buttons.value] });
    };

    // Handle template choosing action
    const onChooseTemplate = (template: Templates) => {
      const index = indexForTemplateModal.value;
      buttons.value.value[index].dataRedirect = template.id_template.toString();
      buttons.value.value[index].selectedTemplate = template;
      handleChange({ selector: props.parserValues.selector, properties: [buttons.value] });
      store.commit('general/hideTheSpinner');
    };

    const addButton = () => {
      const newButton: FacebookMessengerButton = {
        action: ActionTypeEnum.REDIRECT,
        dataRedirect: '',
        text: t('templateBuilder.fields.fbButtons.label'),
        selectedTemplate: null,
      };

      buttons.value.value.push(newButton);
      handleChange({ selector: props.parserValues.selector, properties: [buttons.value] });
    };

    const removeButton = (index: number) => {
      buttons.value.value.splice(index, 1);
      handleChange({ selector: props.parserValues.selector, properties: [buttons.value] });
    };

    watch(() => buttons.value, async (values, oldValues) => {
      if (values !== oldValues) {
        for await (const button of buttons.value.value) {
          const index: number = buttons.value.value.indexOf(button);

          // We check if the current button is set to redirect to template
          if (button.action === ActionTypeEnum.DISPLAY_TEMPLATE) {
            // Retrieve template informations
            const { item, err } = await Get<Templates>({
              name: 'Templates',
              id: button.dataRedirect,
              keyName: 'id_template',
              fields: ['id_template', 'label', 'type'],
            });

            if (item) {
              buttons.value.value[index].selectedTemplate = item;
            }
          }
        }
      }
    }, { immediate: true });

    return {
      t,
      imageUrl,
      actionTypeOptions,
      displayTemplateModal,
      indexForTemplateModal,
      buttons,
      displayTemplatePreview,
      ActionTypeEnum,
      dataRedirectParserValues,
      onChooseTemplate,
      handleChangeButtonLabel,
      handleChangeActionType,
      handleChangeDataRedirect,
      addButton,
      removeButton,
    };
  },
});
