
import {
  computed,
  defineComponent,
} from 'vue';
import Chip from 'primevue/chip';
import {
  hasAccessToFeatures,
} from '@/composables/User';

export default defineComponent({
  name: 'WidgetsPanelButton',
  components: {
    Chip,
  },

  props: {
    button: {
      type: Object,
      required: true,
    },

    translation: {
      type: Function,
      required: true,
    },
  },

  setup(props) {
    const featurePermission = computed(() => hasAccessToFeatures(props.button.type));
    return {
      featurePermission,
    };
  },
});
