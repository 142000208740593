import { i18n } from '@/i18n';
import { StatsWidgetComponentData, StatsWidgetComponentMetadata } from '@/types/stats-editor-types';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { computed } from 'vue';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { StatsBuilderColumnLayoutEnum, StatsType, StringMap } from '@/types';
// eslint-disable-next-line import/no-cycle
import { uniqueIdS6Generator } from '@/store/modules/stats-editor';
import defaultLanguages from '@/configs/languages';

export interface WidgetMenuData {
  toolbar: {
    label: StringMap;
    panelId?: string;
    children?: any[];
    items?: any[];
  }[];
}

let translation: any;
(async () => {
  translation = await i18n;
})();

const formatToolbar = (arr: any) => arr.reduce(
  async (a: any, item: any) => {
    await a;

    if (item.items && !item.items.length) {
      // eslint-disable-next-line no-param-reassign
      delete item.items;
      // eslint-disable-next-line no-param-reassign
      item.panelId = `panel_${uniqueIdS6Generator()}`;
    } else if (item.items) {
      if (item.panelId) {
        // eslint-disable-next-line no-param-reassign
        delete item.panelId;
      }

      if (item.children) {
        // eslint-disable-next-line no-param-reassign
        delete item.children;
      }

      if (item.command) {
        // eslint-disable-next-line no-param-reassign
        delete item.command;
      }

      await formatToolbar(item.items);
    }
  },
  Promise.resolve(),
);

const WidgetMenuMetadata: StatsWidgetComponentMetadata<WidgetMenuData> = {
  StatsWidgetMeta: {
    type: 'widgetMenu',
    label: 'Menu',
    icon: 'far fa-bars',
    min_columns_required: StatsBuilderColumnLayoutEnum.THREE,
    component: 'StatsWidgetMenu',
    availability: [StatsType.STATS],
  },
  Create({ toolbar }: WidgetMenuData = {
    toolbar: [
      {
        label: Object.assign(
          {},
          ...defaultLanguages.map((lang) => ({ [lang]: 'Menu A' })),
        ),
        children: [],
      },
      {
        label: Object.assign(
          {},
          ...defaultLanguages.map((lang) => ({ [lang]: 'Menu B' })),
        ),
        items: [
          {
            label: Object.assign(
              {},
              ...defaultLanguages.map((lang) => ({ [lang]: 'Menu B.1' })),
            ),
            children: [],
          },
        ],
        children: [],
      },
    ],
  }): StatsWidgetComponentData<WidgetMenuData> {
    return {
      toolbar,
      type: 'StatsWidgetMenu',
    };
  },
  formatData({ toolbar }: WidgetMenuData) {
    formatToolbar(toolbar);
  },
  async Validate(data: WidgetMenuData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      toolbar: {
        required,
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
};

export default WidgetMenuMetadata;
