<template>
  <div
    class="grid mb-2 pt-3"
  >
    <slot name="content" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StatsWidgetRow',
});
</script>
