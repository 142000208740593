<template>
  <div class="flex">
    <div class="flex w-12 choose-shop-container">
      <div
        class="w-12 flex flex-column justify-content-center choose-shop-content"
      >
        <Card
          class="choose-shop"
          :class="{ 'with-search-bar': nbTotalShops > 10 }"
        >
          <template #content>
            <div class="choose-shop-logo">
              <img
                src="@/assets/images/logo-tc-black.svg"
                alt="logo"
              >
            </div>
            <h3 class="flex">
              {{ t('chooseShops.title') }}
            </h3>
            <p class="flex text-page my-2">
              {{ t('chooseShops.message') }}
            </p>
            <div
              v-if="nbTotalShops > 10"
              class="col-12 px-0"
            >
              <div class="w-full p-input-icon-left">
                <i class="far fa-search" />
                <InputText
                  v-model="searchValue"
                  class="w-full"
                  :placeholder="t('search')"
                />
              </div>
            </div>
            <div class="col-12 p-0">
              <ScrollPanel
                class="choose-shop-scrollbar"
              >
                <SpmPanelMenu
                  :items="items"
                />
              </ScrollPanel>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, onBeforeMount, ref, Ref,
} from 'vue';
import { useI18n } from 'vue-i18n';

import {
  getIsAuthenticated, Me, refreshTokenLogin, setChangingShop, Shop, SwitchActiveShop, UserState,
} from '@/composables/User';
import { useRouter } from 'vue-router';
import Card from 'primevue/card';
import { PrimvueMenuModel } from '@/types';
import { useStore } from '@/store';
import InputText from 'primevue/inputtext';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import ScrollPanel from 'primevue/scrollpanel';
import { showToastError } from '@/helpers';

export default defineComponent({
  name: 'ChooseShop',

  components: {
    ScrollPanel,
    SpmPanelMenu,
    Card,
    InputText,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();

    const shopToMenu = (shop: Shop) => {
      const label = shop.name ?? shop.url;
      const legend = shop.url;
      const logo = `https://media.shopimind.io/clients/logos/${shop.logo}`;
      const command = async () => {
        store.commit('general/showTheSpinner');

        try {
          const res: any = await refreshTokenLogin(shop.id);
          if (res && res.success) {
            const { idShop } = res;
            await Me(true, Number(idShop));
            store.commit('liveEditor/resetWebsiteContents');
            await router.push({ name: 'home' });
          } else {
            await showToastError('GENERIC_ERROR');
          }
        } catch (e) {
          await showToastError('GENERIC_ERROR');
        }

        store.commit('general/hideTheSpinner');
      };
      return {
        label,
        legend,
        logo,
        command,
      };
    };

    const searchValue = ref('');
    const items: Ref<PrimvueMenuModel[]> = computed(() => {
      const regex = new RegExp(searchValue.value, 'i');
      const shops = UserState.user.shops
        .filter((s: Shop) => (regex.test(s.name) || regex.test(s.id.toString()) || regex.test(s.url)))
        .map(shopToMenu);
      return [
        ...shops,
      ].slice(-10);
    });
    const nbTotalShops = computed(() => UserState.user.shops.length);

    onBeforeMount(async () => {
      const isAuthenticated = getIsAuthenticated();
      if (!isAuthenticated) {
        await Me(false);
      }

      return true;
    });

    return {
      t,
      nbTotalShops,
      items,
      searchValue,
    };
  },
});
</script>

<style scoped lang="scss">
:deep() .p-card-content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.text-page {
  font-size: 14px;
  line-height: 21px;
  color: rgb(107, 114, 128);
}

.choose-shop {
  width: 100%;
  max-width: 650px;
  height: 350px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;

  &.with-search-bar {
    height: 400px;
  }
}

.choose-shop-container {
  background-color: #F8F9FA;
  color: #495057;

  .choose-shop-logo {
    width: 12rem;
    margin-bottom:20px;
  }

  .p-button {
    color:$brand-color-primary;
    background:transparent;
  }
}

.choose-shop-content {
  min-height: 100vh;
  max-width: none;
}

.choose-shop-scrollbar {
  width: 100%;
  height: 190px
}

::v-deep(.p-scrollpanel.choose-shop-scrollbar .p-scrollpanel-bar) {
  background-color: $solitude;
  opacity: 1;
  transition: background-color 0.3s;
  box-shadow: none !important;
}

::v-deep(.p-scrollpanel.choose-shop-scrollbar .p-scrollpanel-bar:hover) {
  background-color: $solitude;
}

::v-deep(.p-scrollpanel.custombar2 .p-scrollpanel-wrapper) {
  border-right: 10px solid var(--surface-50);
  border-bottom: 10px solid var(--surface-50);
}

::v-deep(.p-scrollpanel.custombar2 .p-scrollpanel-bar) {
  background-color: var(--surface-300);
  border-radius: 0;
  opacity: 1;
  transition: background-color 0.3s;
}
</style>
