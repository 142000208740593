
import {
  defineComponent,
  ref,
} from 'vue';
import draggable from 'vuedraggable';
import InputText from 'primevue/inputtext';
import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import defaultLanguages from '@/configs/languages';

export default defineComponent({
  name: 'MenuItemsNestedDraggable',

  components: {
    InputText,
    draggable,
    LocalizedTextInput,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },

    level: {
      type: Number,
      required: false,
      default: 1,
    },
  },

  setup(props: { items: any[]; type: string }) {
    const dragOptions = ref({
      animation: 200,
      group: 'description',
      ghostClass: 'ghost',
    });

    const removeItem = (index: number) => {
      // eslint-disable-next-line no-param-reassign,vue/no-mutating-props
      props.items.splice(index, 1);
    };

    const checkMove = (evt: any) => true;

    return {
      removeItem,
      dragOptions,
      checkMove,
      defaultLanguages,
    };
  },
});
