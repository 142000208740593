import {
  Property, StringMap, TemplateParentTypeEnum, TemplateStructureEnum,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import {
  getSectionIdsFromIframe, setActiveSection,
  TemplateEditorState as state, updateSectionsInState,
} from '@/composables/template-editor/TemplateEditor';
import { asInt } from '@/helpers';
import {
  TEMPLATE_SECTION_IDENTIFIER,
  TEMPLATE_LINE_IDENTIFIER,
  TEMPLATE_COLUMN_IDENTIFIER,
  TEMPLATE_WIDGET_IDENTIFIER,
  TEMPLATE_STRUCTURE_MAP,
  TEMPLATE_ITEM_IDENTIFIER_PREFIX_MAP,
} from './constants';
// eslint-disable-next-line import/no-cycle
import {
  createNewStylesheet,
  generateUniqStructureId,
  getTemplateIframeDocument,
  reconstructDynamicPersistentMobileCss,
} from './helpers';

const GLOBAL_CSS = 'spm_styles_to_keep';

export const getTemplateSections = (): HTMLElement[] => {
  const template = getTemplateIframeDocument();
  return Array.from(template?.querySelector('#spm_body:not(.spm_facebook)')?.querySelectorAll(TEMPLATE_SECTION_IDENTIFIER) || []);
};

export const getTemplateLines = (): HTMLElement[] => {
  const template = getTemplateIframeDocument();
  return Array.from(template?.querySelectorAll(TEMPLATE_LINE_IDENTIFIER) || []);
};

export const getTemplateColumns = (): HTMLElement[] => {
  const template = getTemplateIframeDocument();
  return Array.from(template?.querySelectorAll(TEMPLATE_COLUMN_IDENTIFIER) || []);
};

export const getTemplateWidgets = (): HTMLElement[] => {
  const template = getTemplateIframeDocument();
  return Array.from(template?.querySelectorAll(TEMPLATE_WIDGET_IDENTIFIER) || []);
};

export const getGlobalCSS = (template: HTMLDocument): CSSStyleSheet | undefined => {
  const { styleSheets } = template;
  return Object.values(styleSheets).find(({ title }: CSSStyleSheet) => title === GLOBAL_CSS);
};

export const getDynamicStylesForActiveSection = (sectionId: string | number | null = null): HTMLStyleElement => (getTemplateIframeDocument()
  .querySelector(`[data-spm-styles="dynamic"][data-spm-section="${sectionId ?? state.template?.activeSection}"]`) as HTMLStyleElement);

export const setActiveSectionFromSelector = (selector: string) => {
  if (!state.template?.activeSection || state.template?.activeSection === -1) {
    const element = getTemplateIframeDocument().querySelector(selector);
    if (element) {
      const section = element.closest('[data-spmelementid]');
      if (section) {
        const sectionId = section.getAttribute('data-spmelementid');
        if (sectionId) {
          setActiveSection(parseInt(sectionId, 10));
        }
      }
    }
  }
};

export const addNewDynamicStyle = (selector: string, value: StringMap) => {
  setActiveSectionFromSelector(selector);
  if (state.template?.activeSection && state.template?.activeSection !== -1) {
    let stylesheet = getDynamicStylesForActiveSection();

    if (!stylesheet && state.template?.activeSection) {
      // Active section has no dynamic styles stylesheet, we create it
      stylesheet = createNewStylesheet('dynamic', state.template?.activeSection.toString());
    }

    if (stylesheet) {
      const { sheet } = stylesheet;
      if (sheet) {
        let reconstructDynamicPersistentMobile = false;
        Object.entries(value)
          .forEach(([property, propertyValue]) => {
            if (propertyValue && propertyValue !== '') {
              if (property.match(/max-width|font-size/)) {
                reconstructDynamicPersistentMobile = true;
              }
              sheet.insertRule(`${selector} { ${property}: ${propertyValue}; }`, sheet.cssRules.length);
            }
          });
        stylesheet.innerHTML = Array.from(sheet.cssRules)
          .map((rule) => rule.cssText)
          .join('\n');

        if (reconstructDynamicPersistentMobile) {
          reconstructDynamicPersistentMobileCss();
        }
      }
    }
  }
};

export const moveDynamicStyleFromSectionToAnother = (selector: string, sectionIdFrom: string, sectionIdTo: string) => {
  const template = getTemplateIframeDocument();
  if (sectionIdFrom && sectionIdTo) {
    const dynamicStylesFrom = getDynamicStylesForActiveSection(sectionIdFrom);
    const dynamicStylesTo = getDynamicStylesForActiveSection(sectionIdTo);
    if (dynamicStylesFrom && dynamicStylesTo) {
      const { sheet: sheetFrom } = dynamicStylesFrom;
      const { sheet: sheetTo } = dynamicStylesTo;

      if (sheetFrom && sheetTo) {
        let reconstructFrom = false;
        let index = 0;

        const rulesToAdd: string[] = [];

        Object.values(sheetFrom.cssRules).forEach((styleRule: any) => {
          if (typeof styleRule.selectorText === 'string' && styleRule.selectorText.match(selector)) {
            rulesToAdd.push(styleRule.cssText);
            sheetFrom.deleteRule(index);
            index -= 1;
            reconstructFrom = true;
          }
          index += 1;
        });

        // Reconstruct the innerHTML of the source section's dynamic styles
        if (reconstructFrom) {
          dynamicStylesFrom.innerHTML = Array.from(sheetFrom.cssRules)
            .map((rule) => rule.cssText)
            .join('\n');
        }

        // Add the collected rules to the target section's dynamic styles
        if (rulesToAdd.length > 0) {
          rulesToAdd.forEach((rule) => {
            sheetTo.insertRule(rule, sheetTo.cssRules.length);
          });

          // Reconstruct the innerHTML of the target section's dynamic styles
          dynamicStylesTo.innerHTML = Array.from(sheetTo.cssRules)
            .map((rule) => rule.cssText)
            .join('\n');
        }
      }
    }
  }
};

export const removeDynamicStyle = (selector: string, value: StringMap) => {
  // We check if selector is dedicated to a widget or not
  const idWidget = selector.match(new RegExp('#spm_widget_([a-zA-Z0-9]+)'));
  const isWidget = Array.isArray(idWidget) && idWidget.length > 0;
  const template = getTemplateIframeDocument();
  getSectionIdsFromIframe(!isWidget).forEach((sectionId) => {
    // eslint-disable-next-line radix
    if (sectionId) {
      const dynamicStyles = getDynamicStylesForActiveSection(sectionId);
      if (dynamicStyles) {
        const { sheet } = dynamicStyles;
        if (sheet) {
          let reconstruct = false;
          Object.keys(value).forEach((property) => {
            const cssRuleSelector = property.replace(/-./g, (char) => char.toUpperCase()[1]);
            let index = 0;
            Object.values(sheet.cssRules).forEach((styleRule: any) => {
              if (typeof styleRule.selectorText === 'string') {
                let notWidgetCondition = !isWidget && styleRule.selectorText === selector;
                if (cssRuleSelector === 'backgroundImage') {
                  // Check it because background-image is applied with multiple selectors
                  notWidgetCondition = !isWidget && styleRule.selectorText.match(new RegExp(selector));
                }
                const currentIdWidget = styleRule.selectorText.match(new RegExp('#spm_widget_([a-zA-Z0-9]+)'));
                const currentIsWidget = Array.isArray(currentIdWidget) && currentIdWidget.length > 0;

                if (
                  (
                    (notWidgetCondition || (!isWidget && template.querySelector(selector) && template.querySelector(selector) === template.querySelector(styleRule.selectorText)))
                    || (idWidget && isWidget && (styleRule.selectorText === selector
                      || (currentIdWidget && currentIsWidget && template.querySelector(selector) === template.querySelector(styleRule.selectorText))))
                  )
                  && (styleRule.style[cssRuleSelector as any] || (cssRuleSelector.match('padding') && styleRule.style['padding' as any]))
                ) {
                  if (cssRuleSelector === 'backgroundImage' && (styleRule.style[cssRuleSelector].match('gradient') || styleRule.style.backgroundColor.match('rgb'))) {
                    let bgColor = styleRule.style.backgroundColor;
                    if (styleRule.style[cssRuleSelector].match('gradient')) {
                      bgColor = styleRule.style[cssRuleSelector].replace(/.*((linear|radial)-gradient.*\))$/, '$1');
                    }
                    sheet.insertRule(`${selector} {background: ${bgColor};}`, index);
                    index += 1;
                    sheet.deleteRule(index);
                    index -= 1;
                    reconstruct = true;
                  } else if (styleRule.cssText.match(`${property}:`) || (styleRule.cssText.match('padding:') && cssRuleSelector.match('padding'))) {
                    sheet.deleteRule(index);
                    index -= 1;
                    reconstruct = true;
                  }
                }
              }
              index += 1;
            });
          });
          if (reconstruct) {
            dynamicStyles.innerHTML = Array.from(sheet.cssRules)
              .map((rule) => rule.cssText)
              .join('\n');
          }
        }
      }
    }
  });
};

export const cleanupDynamicStyle = () => {
  const template = getTemplateIframeDocument();
  let updateSections = false;
  const cssTextExists: any[] = [];
  getSectionIdsFromIframe(false).forEach((sectionId) => {
    // eslint-disable-next-line radix
    if (sectionId) {
      const dynamicStyles = getDynamicStylesForActiveSection(sectionId);
      if (dynamicStyles) {
        const { sheet } = dynamicStyles;
        if (sheet) {
          let reconstruct = false;
          let index = 0;
          Object.values(sheet.cssRules).forEach((styleRule: any) => {
            if (typeof styleRule.selectorText === 'string') {
              if (!cssTextExists.includes(styleRule.cssText)) {
                cssTextExists.push(styleRule.cssText);
                // We check if selector is dedicated to a widget or not
                const idWidget = styleRule.selectorText.match(new RegExp('#spm_widget_([a-zA-Z0-9]+)'));
                const isWidget = Array.isArray(idWidget) && idWidget.length > 0;
                if (isWidget) {
                  const currentSectionId = template.querySelector(`[data-spmelementid="${sectionId}"]`) as HTMLElement;
                  const selectorToCheck = `${styleRule.selectorText}`;
                  const currentTarget = template.querySelector(selectorToCheck);
                  let deleteRule = false;
                  if (currentTarget) {
                    const targetSectionId = currentTarget.closest('[data-spmelementid]');
                    if (currentSectionId && targetSectionId && currentSectionId !== targetSectionId) {
                      deleteRule = true;
                    }
                  } else {
                    deleteRule = true;
                  }
                  if (deleteRule) {
                    reconstruct = true;
                    sheet.deleteRule(index);
                    index -= 1;
                  }
                }
              } else {
                reconstruct = true;
                sheet.deleteRule(index);
                index -= 1;
              }
            }
            index += 1;
          });
          if (reconstruct) {
            dynamicStyles.innerHTML = Array.from(sheet.cssRules)
              .map((rule) => rule.cssText)
              .join('\n');
            updateSections = true;
          }
        }
      }
    }
  });
  if (updateSections) {
    updateSectionsInState();
  }
};

export const removeDynamicStyleWithoutPseudoClasses = (selector: string, property: Property) => {
  const { name, value } = property;
  let newSelector = selector;
  newSelector = newSelector.replace(new RegExp('(:[a-zA-Z-]+\\([-:.a-zA-Z#]+\\))+', 'g'), '');
  removeDynamicStyle(newSelector, { [name]: value });
};

export const getDynamicStylesForSelector = (selector: string): StringMap => {
  setActiveSectionFromSelector(selector);
  const selectorStyles: StringMap = {};
  if (state.template?.activeSection && state.template?.activeSection !== -1) {
    const dynamicStylesheet = getDynamicStylesForActiveSection();

    if (dynamicStylesheet) {
      const { sheet } = dynamicStylesheet;
      if (sheet) {
        Array.from(sheet.cssRules)
          .forEach((rule: CSSRule) => {
            const styleRule = rule as CSSStyleRule;
            if (styleRule.selectorText === selector) {
              const updatedStyles = Object.keys(styleRule.style)
                .filter((item) => Number.isInteger(+item));
              updatedStyles.forEach((key: any) => {
                const modifier: any = styleRule.style[key];
                selectorStyles[modifier] = styleRule.style[modifier];
              });
            }
          });
      }
    }
  }
  return selectorStyles;
};

export const addOrUpdateDynamicStyle = (selector: string, value: StringMap) => {
  removeDynamicStyle(selector, value);
  addNewDynamicStyle(selector, value);
};

export const getChildrenType = (parentType: TemplateStructureEnum, templateType: TemplateParentTypeEnum): TemplateStructureEnum => {
  switch (parentType) {
    case TemplateStructureEnum.PAGE: return templateType === TemplateParentTypeEnum.EMAIL ? TemplateStructureEnum.SECTION : TemplateStructureEnum.LINE;
    case TemplateStructureEnum.SECTION: return TemplateStructureEnum.LINE;
    case TemplateStructureEnum.LINE: return TemplateStructureEnum.COLUMN;
    default: return TemplateStructureEnum.WIDGET;
  }
};

export const getChildrenStructure = (parentId: string, childrenType: string): Array<HTMLElement> => {
  let childrenStructures: Array<HTMLElement> = [];
  const parentIdentifier = parentId.indexOf('#') !== -1 ? parentId : `#${parentId}`;
  const template = getTemplateIframeDocument();
  const parentStructure = template.querySelector(parentIdentifier);
  if (parentStructure) {
    childrenStructures = Array.from(parentStructure.querySelectorAll(TEMPLATE_STRUCTURE_MAP[childrenType]));
  }
  return childrenStructures;
};

export const getParentClosestStructure = (elementId: string, structure: string): string|null => {
  const template = getTemplateIframeDocument();
  const element = template?.querySelector(`#${elementId}`) as HTMLElement;
  if (element) {
    const parent = (element.closest(TEMPLATE_STRUCTURE_MAP[structure]) as HTMLElement);
    if (parent) {
      if (!parent.id) { // Generate id if not exists
        const parentId = `${TEMPLATE_ITEM_IDENTIFIER_PREFIX_MAP[structure]}_${generateUniqStructureId()}`;
        parent.setAttribute('id', parentId);
      }
      return parent.id;
    }
  }
  return null;
};

export const addValuesToProperties = (selector: string, properties: Array<Property>, structureId: string) => properties.map((property: Property) => {
  // eslint-disable-next-line no-param-reassign
  property.value = property.getter(selector, property.name, structureId);
  return property;
});

export const computeActiveSection = (type: string, identifier: string): string | number => {
  if (state.template) {
    if (state.template.type !== 'email') return state.template.content.sections[0]; // template is not email => only one section
    if (type === TemplateStructureEnum.PAGE) return state.template.content.design; // click on global style button => design section

    const template = getTemplateIframeDocument();
    const structureId = getParentClosestStructure(identifier, 'section'); // get the parent section identifier
    if (structureId) {
      const elementId = (template?.querySelector(`#${structureId}`) as HTMLElement).getAttribute('data-spmelementid'); // get the section id
      if (elementId) {
        return asInt(elementId);
      }
    }
  }
  return -1;
};
