// eslint-disable-next-line import/no-cycle
import { StringMap, TypeCampaignEnum } from '@/types';
// eslint-disable-next-line import/no-cycle
import {
  ComponentData, ComponentMetadata, ErrorConfigForm, NumberRange, Period, PickerData,
} from '@/types/automated-scenarios';
// eslint-disable-next-line import/no-cycle
import { decreaseNumberOfBranchesOfAnOperator } from '@/composables/AutomatedScenarios';
import { computed } from 'vue';
import {
  integer, maxLength, minLength, required, requiredIf,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/no-cycle
import { atLeastOneSegmentValidator, includesValuesValidator } from '@/helpers/CustomValidator';

export interface FiltreCurrentOrderFilters {
  filterproductinorder?: {
    segment_number: number;
    litteral_title: StringMap;
    product_picker_order: {
      selection: PickerData;
    }[];
  };
  filterproductcategoryinorder?: {
    segment_number: number;
    litteral_title: StringMap;
    product_picker_category: {
      selection: PickerData;
    }[];
  };
  filterproductmanufacturerinorder?: {
    segment_number: number;
    litteral_title: StringMap;
    product_picker_manufacturer: {
      selection: PickerData;
    }[];
  };
  filterorderamount?: NumberRange;
  filterstatusorder?: {
    segment_number: number;
    litteral_title: StringMap;
    status_order: StringMap;
  };
  filtervoucherused?: {
    segment_number: number;
    litteral_title: StringMap;
    voucherUsed: StringMap;
  };
}

export interface FiltreCurrentOrderData {
  custom_title: string;
  custom_description: string;
  outputs_number: number;
  initial_number_outputs?: null|number; // for updating outputs
  grant_additional_delay: string;
  extra_time: Period;
  inclusion: FiltreCurrentOrderFilters;
  exclusion: FiltreCurrentOrderFilters;
}

const FiltreCurrentOrderMetadata: ComponentMetadata<FiltreCurrentOrderData> = {
  Meta: {
    kind: 'filtre',
    id: 'boxfiltre_2',
    label: 'automatedScenarios.filters.currentOrder',
    icon: 'fa-cogs',
    hasConfiguration: true,
    persistent: [
      { segmentId: 'filterstatusorder', boxId: 'boxstatuscommand' },
    ],
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxperiodiclaunching', 'boxfiltre_1',
        ],
      },
      bulk: {
        next_operators: [
          'boxfiltre_1',
        ],
      },
    },
    dependencies: {
      automation: {
        operators: {
          any_parents: ['boxstatuscommand'],
        },
        segments: {
          any_parents_contain: ['filtertimesincelastorder', 'filternoorderfrom'],
        },
      },
      bulk: {
        segments: {
          any_parents_contain: ['filtertimesincelastorder', 'filternoorderfrom'],
        },
      },
    },
    component: 'FiltreCurrentOrder',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
  },
  Create(data: FiltreCurrentOrderData = {
    custom_title: '',
    custom_description: '',
    outputs_number: 1,
    grant_additional_delay: '1',
    extra_time: {
      value: 1,
      unit: 'DAY',
    },
    inclusion: {},
    exclusion: {},
  }): ComponentData<FiltreCurrentOrderData> {
    return {
      id: 'boxfiltre_2',
      form_name: 'boxfiltre_2',
      logo_box: 'fa-cogs',
      ...data,
    };
  },
  async Validate(data: FiltreCurrentOrderData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      custom_title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(45),
      },
      outputs_number: {
        required,
        includesValuesValidator: includesValuesValidator([1, 2]),
      },
      grant_additional_delay: {
        required,
        includesValuesValidator: includesValuesValidator(['0', '1']),
      },
      extra_time: {
        value: {
          required: requiredIf(() => data.grant_additional_delay === '1'),
          integer,
        },
        unit: {
          required: requiredIf(() => data.grant_additional_delay === '1'),
          includesValuesValidator: includesValuesValidator(['SECOND', 'MINUTE', 'HOUR', 'DAY']),
        },
      },
      inclusion: {
        required: atLeastOneSegmentValidator(data.exclusion),
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
  BeforeInsertData(data: FiltreCurrentOrderData): FiltreCurrentOrderData {
    const dataToReturn: FiltreCurrentOrderData = data;
    const { initial_number_outputs } = dataToReturn;

    if (typeof dataToReturn.initial_number_outputs !== 'undefined') {
      delete dataToReturn.initial_number_outputs;
    }

    if (initial_number_outputs !== null && initial_number_outputs !== undefined
      && data.outputs_number < initial_number_outputs) {
      /* En cas de dimunition du nombre de branches, on supprime la branche "non" */
      decreaseNumberOfBranchesOfAnOperator(['output_2']);
    }

    return dataToReturn;
  },
  Outputs(data: FiltreCurrentOrderData): number {
    return data.outputs_number;
  },
  Label(data: FiltreCurrentOrderData): string {
    return data.custom_title;
  },
};

export default FiltreCurrentOrderMetadata;
