import { ComponentNameEnum, BaseButtonFieldConfig, ButtonWidthValueEnum } from '@/types';

const getButtonWidthFieldConfig = (defaultValue = ButtonWidthValueEnum.AUTO): BaseButtonFieldConfig => ({
  componentName: ComponentNameEnum.BUTTON_GROUP,
  options: {
    [ButtonWidthValueEnum.AUTO]: {
      title: 'templateBuilder.fields.widthAuto',
      property: 'width',
      defaultValue,
    },
    [ButtonWidthValueEnum.FULL_WIDTH]: {
      title: 'templateBuilder.fields.fullWidth',
      property: 'width',
      defaultValue,
    },
  },
});

export default getButtonWidthFieldConfig;
