
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SectionTitle',

  props: {
    title: {
      type: String,
      required: true
    },
  }
})
