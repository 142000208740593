<template>
  <div>
    <label
      class="block my-3"
      data-test-id="dropdown-label"
    >
      {{ t(configs.label, null) }}
    </label>
    <div>
      <MultiSelect
        :model-value="selectedValues"
        :options="options"
        :option-disabled="isMandatory"
        option-label="label"
        :show-toggle-all="false"
        @update:model-value="handleSelected"
      >
        <template #option="slotProps">
          <div class="p-multiselect-option">
            <span>{{ t(slotProps.option.label, null) }}</span>
          </div>
        </template>
      </MultiSelect>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, onBeforeMount, PropType, Ref, ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
  DropdownOption, FieldConfig, ParserFieldObject, Property,
} from '@/types';
import MultiSelect from 'primevue/multiselect';

export default defineComponent({
  name: 'MultiDropdown',

  components: {
    MultiSelect,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: ['on-change-properties'],

  setup(props, context) {
    const { t } = useI18n();
    const property: Ref<Property> = ref(props.parserValues.properties && props.parserValues.properties[0]);
    const options: Ref<DropdownOption[]> = ref(props.configs.options.list);
    const selectedValues = ref(options.value.filter((option: DropdownOption) => property.value.value.indexOf(option.value) !== -1));
    const handleSelected = (value: DropdownOption[]) => {
      selectedValues.value = value;
      property.value.value = selectedValues.value.reduce((acc: Array<string|number>, option: DropdownOption) => { acc.push(option.value); return acc; }, []);
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: [property.value],
      });
    };

    const isMandatory = (option: DropdownOption): boolean => (props.configs.options.mandatoryFields && props.configs.options.mandatoryFields.includes(option.value));

    watch(() => property.value.value, (values, oldValues) => {
      if (values !== oldValues) {
        selectedValues.value = options.value.filter((option: DropdownOption) => property.value.value.indexOf(option.value) !== -1);
      }
    }, { immediate: true });

    onBeforeMount(() => {
      options.value.forEach((option: DropdownOption) => {
        // eslint-disable-next-line no-param-reassign
        option.label = t(option.label);
      });
    });

    return {
      t,
      selectedValues,
      options,
      handleSelected,
      isMandatory,
    };
  },

});
</script>

<style lang="scss" scoped>
.p-multiselect {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  border: 1px solid $solitude;
  border-radius: $field-border-radius;
  width: 100%;
  font-size: $field-font-size;
  line-height: $field-line-height;
  padding: $field-padding;
  cursor: pointer;
  font-size: 0.9rem;

  &:focus,
  &:focus-visible {
    box-shadow: $box-shadow;
    outline: 0 none;
  }

  &:deep() .p-multiselect-trigger {
    color: $heather !important;
  }
}
</style>
