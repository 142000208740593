<template>
  <ReloadNotice :display-dialog="showReloadNotice" />
  <div class="flex stepper">
    <div class="w-3 hidden lg:flex lg:flex-column stepper__container">
      <div class="w-12rem mt-6 ml-6">
        <img
          src="@/assets/images/logo-tc-black.svg"
          alt="logo"
        >
      </div>
      <div class="flex mt-8 ml-2 flex-column">
        <div
          v-for="(step, index) in steps"
          :key="index"
          class="step"
        >
          <div class="v-stepper">
            <div class="flex bg-white border-1 align-items-center justify-content-center border-round-sm h-2rem w-2rem circle">
              <i
                :class="step.icon"
                class="text-sm"
              />
            </div>
            <div class="line" />
          </div>
          <div class="content">
            <div class="flex flex-column">
              <div
                :class="{ 'text-500': activeStep.title !== step.title }"
                class="font-semibold"
              >
                {{ step.title }}
              </div>
              <div
                :class="{ 'text-500': activeStep.title !== step.title }"
                class="text-sm"
              >
                {{ step.subtitle }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-6rem"
        style="position: absolute; bottom: 1rem; right:1rem;"
      >
        <img
          src="@/assets/images/logo-sm-green.svg"
          style="opacity: 0.4;"
          alt="logo Icon"
        >
      </div>
    </div>
    <div class="flex flex-column w-12 lg:w-9 stepper__content align-items-center">
      <div
        class="flex complete-header justify-content-between"
      >
        <div class="flex align-items-center justify-content-center m-2">
          <MySitesMenu
            v-if="UserState.user.shops.length > 1"
            :add-shop="false"
          />
        </div>
        <div class="flex align-items-center justify-content-center m-2 mr-4">
          <div class="mr-2">
            <SpmButton
              :label="t('profileMenu.logout')"
              icon="fa-regular fa-arrow-right-from-bracket"
              class-style="p-button p-button-secondary"
              @click="logout"
            />
          </div>
        </div>
      </div>
      <div class="stepper__content__wrapper">
        <component
          :is="activeStep.component"
          :data="activeStep.data"
          :title="activeStep.title"
          :sub-title="activeStep.subtitle"
          @next="handleNext"
        />
      </div>
      <div class="flex justify-content-between align-items-center lg:mt-auto mb-8 md:mt-3 gap-3">
        <span
          v-for="(step, index) in steps"
          :key="index"
          class="stepper__progress border-round-2xl"
          :class="{ 'stepper__progress--active': activeStep.title === step.title }"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, onBeforeMount, onMounted, ref,
} from 'vue';
import ComponentsComplete from '@/components/complete';
import { Logout, Me, UserState } from '@/composables/User';
import Loader from '@/components/layout/Loader.vue';
import { UserInfosData } from '@/components/complete/metadata/UserInfosMetadata';
import { useRouter } from 'vue-router';
import { useStore } from '@/store';
import Header from '@/components/layout/Header.vue';
import MyProfileMenu from '@/components/header/MyProfileMenu.vue';
import MySitesMenu from '@/components/header/MySitesMenu.vue';
import MyNotificationsMenu from '@/components/header/MyNotificationsMenu.vue';
import MyStoreMenu from '@/components/header/MyStoreMenu.vue';
import { getErrorMessage, showToastError } from '@/helpers';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { useI18n } from 'vue-i18n';
import { checkIfShopConnected } from '@/configs/complete';
import { nestPost } from '@/composables/nestApi';
import { getLocalStorageElement } from '@/helpers/LocalStorage';
import ReloadNotice from '@/views/layout/ReloadNotice.vue';

export default defineComponent({
  name: 'Complete',

  components: {
    ReloadNotice,
    SpmButton,
    MyStoreMenu,
    MyNotificationsMenu,
    MySitesMenu,
    MyProfileMenu,
    Header,
    Loader,
    ...ComponentsComplete,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const showReloadNotice = ref(false);

    const steps: any = ref([
      {
        title: t('complete.connectShop.step.title'),
        subtitle: t('complete.connectShop.step.subtitle'),
        icon: t('complete.connectShop.step.icon'),
        component: 'ConnectShop',
      },
      {
        title: t('complete.configureShop.step.title'),
        subtitle: t('complete.configureShop.step.subtitle'),
        icon: t('complete.configureShop.step.icon'),
        component: 'ConfigureShop',
      },
      {
        title: t('complete.welcome.step.title'),
        subtitle: t('complete.welcome.step.subtitle'),
        icon: t('complete.welcome.step.icon'),
        component: 'Welcome',
      },
    ]);

    const activeStep: any = ref(false);
    const activeStepIndex = ref(0);

    const handleNext = () => {
      activeStepIndex.value += 1;
      activeStep.value = steps.value[activeStepIndex.value];
    };

    const logout = async () => {
      store.commit('general/showTheSpinner');
      try {
        await Logout();
      } catch (error) {
        await showToastError(getErrorMessage(error));
      } finally {
        await router.push({ name: 'login' });
        window.location.reload();
      }
    };

    const checkIfSameShopInUse = () => {
      const activeShopIdFromLocalStorage = getLocalStorageElement('spm_auth_current_shop_id', false, false);

      if ((!UserState.activeShop || UserState.activeShop.id.toString() !== activeShopIdFromLocalStorage) && !UserState.changingShop) {
        showReloadNotice.value = true;
      }
    };

    onMounted(() => {
      // Check for changes every 5 seconds
      setInterval(checkIfSameShopInUse, 5000);
    });

    onBeforeMount(async () => {
      store.commit('general/showTheSpinner');
      await Me(false);
      const userInfosData: UserInfosData = {
        firstName: UserState.user?.firstName ?? '',
        lastName: UserState.user?.lastName ?? '',
        societe: UserState.user?.societe ?? '',
        vatNumber: UserState.user?.vatNumber ?? '',
        rue: UserState.user?.rue ?? '',
        complement: UserState.user?.complement ?? '',
        zipCode: UserState.user?.zipCode ?? '',
        city: UserState.user?.city ?? '',
        country: UserState.user?.country,
        phoneMobile: UserState.user?.phoneMobile ?? '',
        phoneFixe: UserState.user?.phoneFixe ?? '',
      };

      const hasAllUserInfos = UserState.user?.idPsCustomer
        && Object.values(userInfosData).every((value) => value !== '');

      // if the user has not all the infos, we add the user infos step
      if (!hasAllUserInfos) {
        steps.value.unshift({
          title: t('complete.userInfos.step.title'),
          subtitle: t('complete.userInfos.step.subtitle'),
          icon: t('complete.userInfos.step.icon'),
          component: 'UserInfos',
          data: userInfosData,
        });
      }

      if (await checkIfShopConnected()) {
        await nestPost('v4', '/shop/createEnvironment', {}, {
          idUser: UserState.user.id ?? 0,
          idShop: UserState.activeShop?.id ?? 0,
        });
        steps.value = steps.value.filter((step: any) => step.component !== 'ConnectShop');
      }

      if (UserState.activeShop?.name && UserState.activeShop?.name !== '') {
        steps.value = steps.value.filter((step: any) => step.component !== 'ConfigureShop');
      }

      const [firstStep] = steps.value;
      activeStep.value = firstStep;
      store.commit('general/hideTheSpinner');
    });

    return {
      t,
      UserState,
      steps,
      activeStep,
      handleNext,
      logout,
      showReloadNotice,
    };
  },
});
</script>

<style lang="scss" scoped>
.stepper {
  display: flex;
  height: 100vh;

  .complete-header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: transparent;
    width: 100%;
  }

  &__container {
    height: 100vh; /* Hauteur pleine écran pour fixer la sidebar */
    background-color: #F8F9FA;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    color:#495057;
    position: relative;
  }

  &__content {
    background: #fff;
    min-height: 100vh;
    max-width: none;
    overflow-y: auto;
    &__wrapper {
      width: 850px;
    }
  }

  &__vertical-line {
    left: 1rem;
    top: 2rem;
    bottom: 0.5rem;
    width: 0.125rem;
    background-color: rgb(209 213 219 / 1);
  }

  &__progress {
    height: 0.5rem;
    width: 5rem;
    background-color: var(--gray-300);

    &--active {
      background-color: $brand-color-primary;
    }
  }

  .step {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .v-stepper {
    position: relative;
    /*   visibility: visible; */
  }

  /* regular step */
  .step .circle {
    margin-left: 1rem;
  }

  .step .line {
    left: 2rem;
    height: 100%;
    position: absolute;
    top: 32px;
    width: 0.125rem;
    background-color: #d1d5db;
  }

  .step:last-child .line {
    border-left: 3px solid white;
    z-index: -1; /* behind the circle to completely hide */
  }

  .content {
    margin-left: 1rem;
    margin-bottom: 2rem;
    display: inline-block;
  }
}
</style>
