<template>
  <img
    :src="imageLink"
    class="w-3rem sm:w-8rem flex-shrink-0 mr-3 shadow-2"
  >
  <div class="flex flex-column">
    <a :href="product.productLink" target="_blank">
      <span class="hover:underline text-900 font-medium text-xl mb-2">{{ productName }}</span>
    </a>
    <a
      v-if="product.idShopProductAttribute"
      :href="product.combinationLink"
      target="_blank"
    >
      <span
        class="hover:underline text-600 mb-3"
      >
        {{ combinationProductName }}
      </span>
    </a>
    <div class="flex gap-2 align-items-center mt-2">
      <span class="text-900 font-medium">
        {{ `${t('dataExplorer.orderFile.fields.quantity')} ` }}
      </span>
      <span class="font-medium text-xl order-product-price">
        {{ product.quantity }}
      </span>
    </div>
  </div>
  <span
    class="order-product-price font-medium text-lg ml-auto"
  >
    {{ formatNumberToCurrency(product.price, selectedData.currency || 'EUR') }}
  </span>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';

import { formatNumberToCurrency } from '@/helpers/Number';

import {
  Product,
} from '@/types/data-explorer-types';

import { useStore } from '@/store';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ProductItem',

  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
  },

  setup (props) {
    const { t } = useI18n();
    const store = useStore();

    const selectedData = computed(() => store.getters['dataExplorer/getSelectedData']);

    const productName = computed(() => {
      return `#${props.product.productReference} - ${props.product.productName} (${props.product.idProduct})`;
    });

    const combinationProductName = computed(() => {
      return `${t('dataExplorer.orderFile.fields.combination')} : #${props.product.combinationReference} - ${props.product.combinationName} (${props.product.idCombination})`;
    });

    const imageLink = computed(() => {
      if (props.product.idShopProductAttribute) {
        return props.product.combinationImageLink;
      }
      return props.product.productImageLink;
    });

    return {
      t,
      productName,
      combinationProductName,
      imageLink,
      selectedData,

      formatNumberToCurrency,
    };
  }
})
</script>

<style lang="scss" scoped>
.order-product-price {
  color: $brand-color-primary;
}
</style>