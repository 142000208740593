import UserInfos from '@/components/complete/UserInfos.vue';
import ConnectShop from '@/components/complete/ConnectShop.vue';
import ConfigureShop from '@/components/complete/ConfigureShop.vue';
import Welcome from '@/components/complete/Welcome.vue';

const ComponentsComplete = {
  UserInfos,
  ConnectShop,
  ConfigureShop,
  Welcome,
};

export default ComponentsComplete;
