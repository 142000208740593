import { FiltreCurrentCartFilters } from '@/components/automated-scenarios/metadata/filtres/FiltreCurrentCartMetadata';
import { i18n } from '@/i18n';
import { ErrorConfigForm, FilterSegment } from '@/types/automated-scenarios';
import {
  OperatorType, ShopsManufacturers, ShopsProducts, ShopsProductsCategoriesList,
} from '@/types/generated-types/graphql';
import { TypeCampaignEnum } from '@/types';
import { computed } from 'vue';
import { required } from '@vuelidate/validators';
import { itemsPickerValidator } from '@/helpers/CustomValidator';
import useVuelidate from '@vuelidate/core';

let translation: any;
(async () => {
  translation = await i18n;
})();

export default () => ([
  {
    id: 'filterproductinbag',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filterproductinbag', label: translation.global.t('automatedScenarios.fields.segments.productsInCart') },
    fields: [
      {
        id: 'product_picker_bag',
        component: 'ItemList',
        data: { selection: { selected: [], operator: 'and' } },
        props: {
          config: {
            mainLabel: 'automatedScenarios.fields.productList',
            buttonLabel: 'automatedScenarios.fields.chooseProducts',
            operatorLabel: 'automatedScenarios.fields.visitorMustHaveChosen',
            name: 'ShopsProducts',
            fields: { id: 'id_shop_product', label: 'name', productId: 'id_product' },
            defaultFilters: [{ field: 'lang', value: 'fr', operator: OperatorType.Equals }, { field: 'active', value: true, operator: OperatorType.Equals }],
            format: (product: ShopsProducts) => ({
              id: product.id_product, spmId: product.id_shop_product, name: product.name, combinations: {},
            }),
          },
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.numberOfSelectedProducts'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        product_picker_bag: {
          required,
          itemsPickerValidator: itemsPickerValidator(),
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
  {
    id: 'filterproductcategoryinbag',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filterproductcategoryinbag', label: translation.global.t('automatedScenarios.fields.segments.categoriesOfProductsInCart') },
    fields: [
      {
        id: 'product_picker_category_bag',
        component: 'ItemList',
        data: { selection: { selected: [], operator: 'and' } },
        props: {
          config: {
            mainLabel: 'automatedScenarios.fields.categoryList',
            buttonLabel: 'automatedScenarios.fields.chooseCategories',
            operatorLabel: 'automatedScenarios.fields.visitorMustHaveChosen',
            name: 'ShopsProductsCategoriesList',
            fields: { id: 'id_shop_product_category_list', label: 'name', categoryId: 'id_category' },
            defaultFilters: [{ field: 'lang', value: 'fr', operator: OperatorType.Equals }],
            format: (category: ShopsProductsCategoriesList) => ({
              id: category.id_category, spmId: category.id_shop_product_category_list, name: category.name, combinations: {},
            }),
          },
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.numberOfSelectedCategories'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        product_picker_category_bag: {
          required,
          itemsPickerValidator: itemsPickerValidator(),
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
  {
    id: 'filterproductmanufacturerinbag',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filterproductmanufacturerinbag', label: translation.global.t('automatedScenarios.fields.segments.manufacturersOfProductsInCart') },
    fields: [
      {
        id: 'product_picker_manufacturer_bag',
        component: 'ItemList',
        data: { selection: { selected: [], operator: 'and' } },
        props: {
          config: {
            mainLabel: 'automatedScenarios.fields.manufacturerList',
            buttonLabel: 'automatedScenarios.fields.chooseManufacturers',
            operatorLabel: 'automatedScenarios.fields.visitorMustHaveChosen',
            name: 'ShopsManufacturers',
            fields: { id: 'id_shop_manufacturer', label: 'name', manufacturerId: 'id_manufacturer' },
            defaultFilters: [],
            format: (manufacturer: ShopsManufacturers) => ({
              id: manufacturer.id_manufacturer, spmId: manufacturer.id_shop_manufacturer, name: manufacturer.name, combinations: {},
            }),
          },
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.numberOfSelectedManufacturers'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        product_picker_manufacturer_bag: {
          required,
          itemsPickerValidator: itemsPickerValidator(),
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
  {
    id: 'filtercartamount',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filtercartamount', label: translation.global.t('automatedScenarios.fields.segments.cartAmount') },
    fields: [
      {
        id: 'cart_amount_currency',
        component: 'NumberRange',
        data: { min: 1, max: 1000000 },
        props: {
          title: 'automatedScenarios.fields.cartAmount',
          suffix: ' €',
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.hasOrderedBetween'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        cart_amount_currency: {
          required,
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    multiple: true,
  },
]) as FilterSegment<FiltreCurrentCartFilters>[];
