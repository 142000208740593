
import {
  defineComponent,
  PropType,
  Ref,
  ref,
} from 'vue';
import { UserState } from '@/composables/User';
import { DEFAULT_CURRENCY } from '@/components/template-builder/utils/constants';
import { formatNumbersInString, formatNumberToCurrency } from '@/helpers/Number';
import Knob from 'primevue/knob';
import { SpmKpiConfiguration } from '@/types/stats-types';
import { useI18n } from 'vue-i18n';
import Tooltip from 'primevue/tooltip';

export default defineComponent({
  name: 'SpmKpi',

  components: {
    Knob,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    kpiName: {
      type: String,
      required: true,
    },

    kpiData: {
      type: Array as PropType<SpmKpiConfiguration[]>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const data: Ref<SpmKpiConfiguration[]> = ref(props.kpiData);

    const statsIcons: Record<string, any> = {
      channels: {
        'stats.statsLabels.channels.email': 'fa-regular fa-envelope',
        'stats.statsLabels.channels.display': 'fa-regular fa-desktop',
        'stats.statsLabels.channels.popup': 'fa-regular fa-share',
        'stats.statsLabels.channels.embed': 'fa-regular fa-list-alt',
        'stats.statsLabels.channels.pushnotifications': 'fa-regular fa-bell',
        'stats.statsLabels.channels.sms': 'fa-regular fa-sms',
        'stats.statsLabels.channels.facebookmessenger': 'fa-brands fa-facebook-messenger',
        'stats.statsLabels.channelsIntegers.1': 'fa-regular fa-envelope',
        'stats.statsLabels.channelsIntegers.2': 'fa-regular fa-sms',
        'stats.statsLabels.channelsIntegers.3': 'fa-regular fa-share',
        'stats.statsLabels.channelsIntegers.4': 'fa-regular fa-list-alt',
        'stats.statsLabels.channelsIntegers.5': 'fa-brands fa-facebook-messenger',
        'stats.statsLabels.channelsIntegers.6': 'fa-regular fa-bell',
      },

      boxes: {
        'stats.statsLabels.boxes.boxsendemail': 'fa-regular fa-envelope',
        'stats.statsLabels.boxes.boxdisplayfreemodel': 'fa-regular fa-list-alt',
        'stats.statsLabels.boxes.boxdisplayfreepopup': 'fa-regular fa-share',
        'stats.statsLabels.boxes.boxsendpushnotification': 'fa-regular fa-bell',
        'stats.statsLabels.boxes.boxsendsms': 'fa-regular fa-sms',
        'stats.statsLabels.boxes.boxsendfbmessenger': 'fa-brands fa-facebook-messenger',
      },

      registrations: {
        'stats.statsLabels.registrations.registration': 'fa-regular fa-store',
        'stats.statsLabels.registrations.newsletter': 'fa-regular fa-envelope',
      },
    };

    const hasData = data.value.length > 0;

    data.value.forEach((kpi) => {
      const current = kpi;

      let icon = null;
      if (current.icon && current.label) {
        icon = Object.prototype.hasOwnProperty.call(statsIcons, `${current.icon}`)
        && Object.prototype.hasOwnProperty.call(statsIcons[current.icon], current.label) ? statsIcons[current.icon][current.label] : null;
      }

      if (current.label) {
        current.label = t(current.label);
      }

      if (current.legend) {
        current.legend = t(current.legend);
      }

      if (icon) {
        current.icon = icon;
      }

      if (current.type === 'price') {
        // Format value as price
        current.value = formatNumbersInString(formatNumberToCurrency(parseFloat(current.value), UserState.activeShop?.currency ?? DEFAULT_CURRENCY));
      } else if (current.type !== 'string') {
        current.type = 'number';
        current.value = formatNumbersInString(current.value);
      }
    });

    return {
      t,
      hasData,
      data,
    };
  },
});
