<template>
  <div
    v-if="tabItem.tabPanel.label && displayLabel"
    class="tab-panel-label"
  >
    {{ t(tabItem.tabPanel.label) }}
  </div>
  <ul v-if="tabItem.tabPanel.items">
    <template
      v-for="(fieldGroup, fieldGroupIndex) of tabItem.tabPanel.items"
      :key="fieldGroup.id"
    >
      <li
        v-if="fieldGroup.type"
        class="field-group"
      >
        <div
          v-if="fieldGroup.type === 'structure-edit'"
          class="field-group-control"
        >
          <StructureEdit
            :structure-config="fieldGroup.structureConfig"
            :children-count="parserValues[tabItem.label].childrenStructureCount"
          />
        </div>
        <div
          v-else-if="fieldGroup.type === 'tab-item'"
          class="tabs-field-group"
        >
          <div
            v-if="fieldGroup.isNotCollapsible"
          >
            <span v-if="fieldGroup.label !== ''">{{ t(fieldGroup.label) }}</span>
          </div>
          <button
            v-else
            class="field-group-control field-group-control--collapsible flex justify-content-between align-items-center"
            @click="toggleFieldGroup(fieldGroupIndex)"
          >
            <span>{{ t(fieldGroup.label) }}</span>
            <i
              class="far"
              :class="activeFieldGroupIndex.includes(fieldGroupIndex) ? 'fa-chevron-up' : 'fa-chevron-down'"
            />
          </button>
          <div
            class="field-group-content field-group-content-tabbed"
            :class="{ 'hidden': (!activeFieldGroupIndex.includes(fieldGroupIndex) && !fieldGroup.isNotCollapsible) }"
          >
            <TabView
              :key="`tabs_${fieldGroup.id}`"
              :multiple="true"
              :scrollable="Object.prototype.hasOwnProperty.call(fieldGroup, 'scrollable') && fieldGroup.scrollable"
            >
              <TabPanel
                v-for="(subTabItem, subIndex) of fieldGroup.tabPanel.items"
                :key="subIndex"
              >
                <template
                  #header
                  :title="t(subTabItem.label)"
                >
                  {{ t(subTabItem.label) }}
                </template>
                <ConfigurationPanelTabContentFieldGroup
                  :field-group="subTabItem"
                  :tab-item="tabItem"
                  :parser-values="parserValues"
                />
              </TabPanel>
            </TabView>
          </div>
        </div>
        <div v-else>
          <div
            v-if="fieldGroup.isNotCollapsible"
          >
            <span v-if="fieldGroup.label !== ''">{{ t(fieldGroup.label) }}</span>
          </div>
          <button
            v-else
            class="field-group-control field-group-control--collapsible flex justify-content-between align-items-center"
            @click="toggleFieldGroup(fieldGroupIndex)"
          >
            <span>{{ t(fieldGroup.label) }}</span>
            <i
              class="far"
              :class="activeFieldGroupIndex.includes(fieldGroupIndex) ? 'fa-chevron-up' : 'fa-chevron-down'"
            />
          </button>
          <ConfigurationPanelTabContentFieldGroup
            :field-group="fieldGroup"
            :tab-item="tabItem"
            :parser-values="parserValues"
            :custom-class="{ 'hidden': (!activeFieldGroupIndex.includes(fieldGroupIndex) && !fieldGroup.isNotCollapsible) }"
          />
        </div>
      </li>
    </template>
  </ul>
</template>

<script lang="ts">
import {
  defineComponent, onMounted,
  PropType, Ref,
  ref,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';

import {
  TabItem,
  TabSubitem,
  ParserObject,
} from '@/types';
import StructureEdit from '@/components/template-builder/fields/StructureEdit.vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

import ConfigurationPanelTabContentFieldGroup from '@/components/template-builder/panels/ConfigurationPanelTabContentFieldGroup.vue';

export default defineComponent({
  name: 'ConfigurationPanelTabContent',

  components: {
    StructureEdit,
    TabView,
    TabPanel,
    ConfigurationPanelTabContentFieldGroup,
  },

  props: {
    tabItem: {
      type: Object as PropType<TabItem | TabSubitem>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserObject>,
      required: true,
    },

    displayLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const activeFieldGroupIndex: Ref<number[]> = ref([]);
    const toggleFieldGroup = (index: number) => {
      if (activeFieldGroupIndex.value.includes(index)) {
        activeFieldGroupIndex.value.splice(activeFieldGroupIndex.value.indexOf(index), 1);
      } else {
        activeFieldGroupIndex.value.push(index);
      }
    };

    watch([
      () => props.parserValues,
      () => props.tabItem,
    ],
    () => {
      if (props.tabItem.tabPanel) {
        activeFieldGroupIndex.value = props.tabItem.tabPanel.items.reduce((acc: number[], item, index) => {
          acc.push(index);
          return acc;
        }, []);
      }
    });

    onMounted(() => {
      if (props.tabItem.tabPanel) {
        activeFieldGroupIndex.value = props.tabItem.tabPanel.items.reduce((acc: number[], item, index) => {
          if (index === 0) {
            acc.push(index);
          }
          return acc;
        }, []);
      }
    });

    return {
      activeFieldGroupIndex,
      toggleFieldGroup,
      t,
    };
  },
});
</script>

<style lang="scss" scoped>
.tab-panel-label {
  font-size: 1.2rem;
  font-weight: 800;
  color: $montana;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  line-height: 1rem;
  margin-bottom: 1rem;
}

.field-group:not(:last-child) {
  margin-bottom: .5rem;
}

.configuration-wrapper {
  & .p-panel {
    & > .p-toggleable-content {
      & .p-panel-content {
        & .field-group {
            & .field-group-control {
              text-align: left;
              background-color: $shuttle-light-grey !important;

              &--collapsible:deep() {
                width: 100%;
                border: 0 none;
                cursor: pointer;
                background-color: #fbfbfb !important;
                border-bottom: 1px solid #e9ecef;
              }
            }
          }
      }
    }
  }

  .tabs-field-group:deep() {
    & .p-tabview {
      margin: 5px 0;

      & .p-tabview-nav {
        font-size: 0.875rem;
        line-height: 1.0625rem;
        white-space: nowrap;

        & li {
          flex-grow: unset !important;

          & .p-tabview-nav-link {
            text-transform: none;
            font-weight: bold !important;
            color: $tuna !important;
            padding: 12px;
          }
        }
      }
    }

    & .p-tabview-panels {
      padding: 0;

      & .field-group-content {
        padding: 0;
      }
    }
  }
}
</style>
