<template>
  <StatsWidgetRow
    v-if="config.type === StatsWidgets.ROW"
  >
    <template #content>
      <StatsWidgetRenderer
        v-for="(item, index) in config.children"
        :key="index"
        :configuration="item"
        :default-filters="defaultFilters"
        :page-name="pageName"
      />
    </template>
  </StatsWidgetRow>
  <StatsWidgetColumn
    v-else-if="config.type === StatsWidgets.COLUMN && config.widgets"
    :layout="config.layout"
  >
    <template #content>
      <StatsWidgetRenderer
        v-for="(item, index) in config.widgets"
        :key="index"
        :configuration="item"
        :default-filters="defaultFilters"
        :page-name="pageName"
      />
    </template>
  </StatsWidgetColumn>
  <div
    v-else-if="config.type === StatsWidgets.TABS"
    class="p-card"
  >
    <div class="h-full">
      <div class="col-12 h-full p-0">
        <TabView class="tabview-container">
          <TabPanel
            v-for="(tab, indexTab) in config.tabs"
            :key="(indexTab + 1)"
            :header="tab.name"
            content-class="p-0"
          >
            <StatsWidgetRenderer
              v-for="(item, index) in tab.children"
              :key="index"
              :configuration="item"
              :default-filters="defaultFilters"
              :page-name="pageName"
            />
          </TabPanel>
        </TabView>
      </div>
    </div>
  </div>
  <div
    v-else-if="config.type === StatsWidgets.MENU"
    ref="elRef"
    class="menubar-container flex flex-column"
  >
    <div class="col-12 p-0 flex flex-column">
      <Menubar
        :model="config.toolbar"
      />
    </div>
    <div
      v-for="(panel, index) in filteredToolbar"
      :id="`StatsWidgetMenuPanel-${panel.panelId}`"
      :key="index"
      class="stats-widget-menu-panel col-12 flex flex-auto flex-column py-0"
      :class="{ 'hidden': index > 0 }"
    >
      <div v-if="activeMenuPanel === `StatsWidgetMenuPanel-${panel.panelId}`">
        <StatsWidgetRenderer
          v-for="(item, index2) in panel.children"
          :key="index2"
          :configuration="item"
          :default-filters="defaultFilters"
          :page-name="pageName"
        />
      </div>
    </div>
  </div>
  <div
    v-else-if="config.type === StatsWidgets.DATA"
    class="p-card"
  >
    <StatsWidgetData
      :key="componentKey"
      :name="config.widgetName"
      :configuration="config.widgetConfiguration"
      :default-filters="defaultFilters"
      :page-name="pageName"
    />
  </div>
  <div
    v-else-if="config.type === StatsWidgets.TITLE"
  >
    <StatsWidgetTitle
      :name="config.widgetName"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  nextTick,
  onMounted,
  ref,
  Ref,
  watch,
} from 'vue';
import StatsWidgetRow from '@/components/stats/StatsWidgetRow.vue';
import StatsWidgetColumn from '@/components/stats/StatsWidgetColumn.vue';
import StatsWidgetData from '@/components/stats/StatsWidgetData.vue';
import StatsWidgetTitle from '@/components/stats/StatsWidgetTitle.vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Menubar from 'primevue/menubar';
import { StatsWidgets } from '@/types';
import { getParents, setActiveMenubarItem } from '@/helpers';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';

export default defineComponent({
  name: 'StatsWidgetRenderer',

  components: {
    StatsWidgetRow,
    StatsWidgetColumn,
    StatsWidgetData,
    StatsWidgetTitle,
    TabView,
    TabPanel,
    Menubar,
  },

  props: {
    configuration: {
      type: Object,
      required: true,
    },

    defaultFilters: {
      type: Object,
      required: true,
    },

    pageName: {
      type: String,
      required: false,
      default: '',
    },
  },

  setup(props) {
    const { locale } = useI18n();
    const store = useStore();
    const elRef = ref(null);
    const config: Ref<object> = ref(props.configuration);
    const filteredToolbar: Ref<{ [key: string]: any }[]> = ref([]);
    const activeMenuPanel: Ref<string> = ref('');
    const componentKey = ref(0);

    // Hide all panels and show the panel with ID in parameter
    const showStatMenuPage = (id: string) => {
      const menuPanel = document.querySelector(`#StatsWidgetMenuPanel-${id}`) as HTMLElement;

      if (menuPanel) {
        const parentMenu: HTMLElement = getParents(menuPanel, '.menubar-container')[0];
        // eslint-disable-next-line no-unused-expressions
        (parentMenu.querySelectorAll('.stats-widget-menu-panel') ?? []).forEach((el) => {
          el.classList.add('hidden');
        });

        // eslint-disable-next-line no-unused-expressions
        menuPanel.classList.remove('hidden');

        activeMenuPanel.value = `StatsWidgetMenuPanel-${id}`;
      }
    };

    let i = 0;
    const searchPanelsInToolbar = (arr: any) => arr.reduce(
      (acc: any, item: any) => {
        const newItem = JSON.parse(JSON.stringify(item));

        if (item.items) {
          newItem.items = searchPanelsInToolbar(item.items);
        }

        if (Object.prototype.hasOwnProperty.call(newItem, 'panelId')) {
          // eslint-disable-next-line no-param-reassign
          item.command = (event: any) => {
            setActiveMenubarItem(event.originalEvent.target);
            showStatMenuPage(item.panelId);
          };

          if (i === 0) {
            // eslint-disable-next-line no-param-reassign
            item.class = 'current-active-menu';
          }

          // We assign the class element for the toolbar item
          acc.push(newItem);

          if (i === 0) {
            i += 1;
          }
        }

        return acc;
      },
      filteredToolbar.value,
    );

    const formatData = (arr: any) => arr.reduce(
      async (a: any, item: any) => {
        await a;

        item.label = typeof item.label === 'string' ? item.label : item.label[locale.value];

        if (item.items) {
          await formatData(item.items);
        }
      },
      Promise.resolve(),
    );

    onMounted(() => {
      filteredToolbar.value = [];

      if (props.configuration.type === StatsWidgets.MENU) {
        nextTick(async () => {
          searchPanelsInToolbar(props.configuration.toolbar);
          await formatData(props.configuration.toolbar);

          // Mark first menu item as active
          if (elRef.value) {
            const firstItem = (elRef.value as unknown as HTMLElement).querySelector('.p-menubar li.p-menuitem:first-child');

            if (firstItem) {
              firstItem.classList.add('current-active-menu');
              activeMenuPanel.value = `StatsWidgetMenuPanel-${filteredToolbar.value[0].panelId}`;
            }
          }
        });
      }
    });

    watch(() => store.getters['statsEditor/getPageFilters'], () => {
      componentKey.value += 1;
    }, { deep: true });

    return {
      elRef,
      config,
      StatsWidgets,
      filteredToolbar,
      activeMenuPanel,
      componentKey,
    };
  },
});
</script>

<style scoped lang="scss">
.p-card {
  box-shadow: none;
  height: 100%;
}

:deep() {
  .menubar-container,
  .tabview-container {
    box-shadow: none;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    height: 100%;

    & .graph-container {
      border: none !important;
      padding-bottom: 0 !important;
    }
  }

  .menubar-container {
    & .p-menubar {
      border-radius: 0.5rem;
    }
  }

  .stats-widget-menu-panel {
    background-clip: content-box;
    background-color: $white;

    &.hidden {
      display: none !important;
    }

    & > .grid {
      height: 100%;
    }
  }

  .p-tabview {
    .p-tabview-panels {
      padding: 0 0.5rem;
    }
  }
}
</style>
