import {
  OperatorType,
  TemplatesElementsHistory,
  TemplatesElementsHistoryGroup,
  TemplatesElementsHistoryGroupInputItem,
  TemplatesElementsHistoryInputItem,
} from '@/types/generated-types/graphql';
// eslint-disable-next-line import/no-cycle
import {
  getSectionIdsFromIframe,
  replaceSectionContent,
  setTemplateConfigurationKeyInState,
  TemplateEditorState as state,
  updateSectionsInState,
} from '@/composables/template-editor/TemplateEditor';
import { Section } from '@/types/template-builder-types';
import { HistoryType } from '@/types';
import { computed, ComputedRef } from 'vue';
import {
  Insert,
  List,
} from '../GraphQL';
import { UserState } from '../User';

export const getTemplateHistoryGroupList = async (): Promise<TemplatesElementsHistoryGroup[]> => {
  const { items } = await List<TemplatesElementsHistoryGroup>({
    name: 'TemplatesElementsHistoryGroup',
    settings: {
      offset: 0,
      limit: 100,
      order: [{ field: 'date_creation', type: 'DESC' }],
      filter: [
        { field: 'id_template', value: state.template?.id ?? 0, operator: OperatorType.Equals },
        { field: 'id_shop', value: UserState.activeShop?.id ?? 0, operator: OperatorType.Equals },
      ],
    },
    fields: ['id_template_elements_history_group', 'action_detail', 'sections_order_after', 'date_creation'],
  });
  return items;
};

export const getClosestPointOfSave = (historyList: TemplatesElementsHistoryGroup[]) => historyList
  .findIndex((history: TemplatesElementsHistoryGroup) => history.action_detail === HistoryType.POINT_OF_SAVE);

export const getLastPointOfSaveId = (historyList: TemplatesElementsHistoryGroup[]) => {
  const historyItem = historyList
    .find((history: TemplatesElementsHistoryGroup) => history.action_detail === HistoryType.POINT_OF_SAVE);
  return historyItem ? historyItem.id_template_elements_history_group : 0;
};

export const getHistoryIndexFromGroupId = (historyList: TemplatesElementsHistoryGroup[], groupId: number) => historyList
  .findIndex((history: TemplatesElementsHistoryGroup) => history.id_template_elements_history_group === groupId);

export const getHistoryElements = async (historyGroupIds: string): Promise<TemplatesElementsHistory[]> => {
  const { items } = await List<TemplatesElementsHistory>({
    name: 'TemplatesElementsHistory',
    settings: {
      offset: 0,
      limit: 100,
      order: [{ field: 'id_template_elements_history', type: 'ASC' }],
      filter: [
        { field: 'id_template_elements_history_group', value: historyGroupIds, operator: OperatorType.In },
        { field: 'id_template', value: state.template?.id ?? 0, operator: OperatorType.Equals },
        { field: 'id_shop', value: UserState.activeShop?.id ?? 0, operator: OperatorType.Equals },
      ],
    },
    fields: ['id_template_elements_history', 'id_template_elements', 'html_after', 'css_after', 'translations_after'],
  });
  return items;
};

export const loadHistoryGroups = async (historyGroups: TemplatesElementsHistoryGroup[]) => {
  const ids = historyGroups.map((history) => {
    if (!history || !history.id_template_elements_history_group) {
      return false;
    }
    return history.id_template_elements_history_group;
  }).join(',');
  if (ids) {
    (await getHistoryElements(ids))
      .forEach((item: TemplatesElementsHistory) => {
        replaceSectionContent(item.id_template_elements, item.html_after ?? null, item.css_after ?? null, item.translations_after ?? null);
      });
  }
};

export const computeSectionsToSave = (historyType: HistoryType, activeSection: number | string | null) => {
  // @TODO Vérifier si la liste est complète
  switch (historyType) {
    case HistoryType.POINT_OF_SAVE:
    case HistoryType.TRANSLATIONS_CHANGE:
      return [...state.template?.sections || []];
    case HistoryType.DESIGN_CHANGE:
    case HistoryType.ADVANCED_CSS_STATIC_CHANGE:
    case HistoryType.ADVANCED_CSS_STATIC_MOBILE_CHANGE:
      return [...state.template?.sections.filter((current: Section) => current.id_template_elements === state.template?.content.design) || []];
    default:
      return [...state.template?.sections.filter((current: Section) => current.id_template_elements === activeSection) || []];
  }
};

export const computeElementsToSave = (historyType: HistoryType): string[] => {
  // @TODO Vérifier si la liste est complète
  switch (historyType) {
    case HistoryType.DESIGN_CHANGE:
    case HistoryType.ADVANCED_CSS_STATIC_CHANGE:
    case HistoryType.ADVANCED_CSS_STATIC_MOBILE_CHANGE:
      return ['css'];
    case HistoryType.SECTIONS_ORDER_CHANGE:
      return ['html'];
    case HistoryType.TRANSLATIONS_CHANGE:
      return ['translations'];
    default:
      return ['html', 'css', 'translations'];
  }
};

/**
 * Create a new history point
 * @param historyType
 * @param checkCurrentHistoryId - if true, we check if we are on the latest history point or if the user has restored an old history point
 */
export const createHistory = async (historyType: HistoryType) => {
  const activeSection = state.template?.activeSection ?? -1;

  const inputs: TemplatesElementsHistoryGroupInputItem[] = [];
  inputs.push({
    id_template: state.template?.id,
    id_shop: UserState.activeShop?.id ?? 0,
    action_detail: historyType,
    sections_order_after: JSON.stringify(getSectionIdsFromIframe()),
    date_creation: new Date().toLocaleString('en-US', { timeZone: 'Europe/Paris' }),
    date_modification: new Date().toLocaleString('en-US', { timeZone: 'Europe/Paris' }),
  });

  const {
    id, err,
  } = await Insert<TemplatesElementsHistoryGroupInputItem>({
    name: 'TemplatesElementsHistoryGroup',
    input: inputs,
    type: 'TemplatesElementsHistoryGroupInput',
  });

  const sectionstoSave: Section[] = computeSectionsToSave(historyType, activeSection);
  const elementstoSave: string[] = computeElementsToSave(historyType);

  if (err === '') {
    // Store last id of history group in template
    setTemplateConfigurationKeyInState('lastIdTemplateElementsHistoryGroup', +id);
    setTemplateConfigurationKeyInState('currentIdTemplateElementsHistoryGroup', +id);

    // Save elements
    const items: TemplatesElementsHistoryInputItem[] = sectionstoSave.map((section: Section) => {
      if (typeof section.id_template_elements !== 'number') {
        // eslint-disable-next-line no-param-reassign
        section.id_template_elements = parseInt(section.id_template_elements, 10);
      }

      const item: TemplatesElementsHistoryInputItem = {
        id_template_elements_history_group: id,
        id_template_elements: section.id_template_elements,
        id_template: state.template?.id,
        id_shop: UserState.activeShop?.id ?? 0,
        type: section.id_template_elements === state.template?.content.design ? 'design' : 'section',
        date_creation: new Date().toLocaleString('en-US', { timeZone: 'Europe/Paris' }),
        date_modification: new Date().toLocaleString('en-US', { timeZone: 'Europe/Paris' }),
      };
      if (elementstoSave.includes('html')) item.html_after = section.html;
      if (elementstoSave.includes('css')) item.css_after = section.css;
      if (elementstoSave.includes('translations')) item.translations_after = section.translations;
      return item;
    }) ?? [];

    if (items.length) {
      await Insert<TemplatesElementsHistoryInputItem>({
        name: 'TemplatesElementsHistory',
        input: items,
        type: 'TemplatesElementsHistoryInput',
      });
    }
  }
};

/**
 * Checks if a save point is needed based on the current state.
 */
export const checkIfSavePointNeeded = async () => {
  const lastHistoryGroupId: number | null = state.template?.configuration.lastIdTemplateElementsHistoryGroup;
  const currentHistoryGroupId: number | null = state.template?.configuration.currentIdTemplateElementsHistoryGroup ?? lastHistoryGroupId;
  if ((lastHistoryGroupId !== currentHistoryGroupId)) {
    // We are not on the last history for this template, so first we create a new history point of save, then we save the action done by the user
    await createHistory(HistoryType.POINT_OF_SAVE);
  }
};
