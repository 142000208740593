<template>
  <div
    :style="styles"
    class="LanguageDropdown"
  >
    <div class="LanguageList">
      <label v-if="showLabel">{{ t('languages.label') }}</label>
      <div v-if="!loading">
        <MultiSelect
          v-model="inputValue"
          :options="optionValues"
          :loading="loading"
          option-label="label"
          option-value="value"
          :filter="filter"
          :placeholder="placeholder"
          @change="onLanguageChanged"
        >
          <template #option="slotProps">
            <div class="flex align-items-center">
              <LanguageIcons
                class="mt-2 mr-2"
                :language="slotProps.option.value"
                :translation="t"
              />
              <div>
                {{ slotProps.option.label }}
              </div>
            </div>
          </template>
          <template #footer>
            <div class="py-2 px-3">
              <b>{{ inputValue ? inputValue.length : 0 }}</b> {{ t('itemsSelected') }}.
            </div>
          </template>
        </MultiSelect>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import {
  defineComponent, onMounted,
  PropType,
  ref,
} from 'vue';
import MultiSelect from 'primevue/multiselect';
import { SpmTableFilterOption } from '@/types';
import LanguageIcons from '@/components/table/renderers/LanguageIcons.vue';

export default defineComponent({
  name: 'LanguageMultiDropdown',
  components: {
    LanguageIcons,
    MultiSelect,
  },

  props: {
    modelValue: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },

    styles: {
      type: String,
      required: false,
      default: '',
    },

    options: {
      type: Array as PropType<SpmTableFilterOption[]|Function>,
      required: true,
    },

    placeholder: {
      type: String,
      required: false,
      default: '',
    },

    showLabel: {
      type: Boolean,
      required: false,
      default: false,
    },

    filter: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, context) {
    const { t } = useI18n();
    const loading = ref(true);

    const optionValues = ref<any>([]);

    const inputValue = ref(props.modelValue ?? []);

    const onLanguageChanged = (event: any) => {
      context.emit('update:modelValue', event.value);
    };

    onMounted(async () => {
      if (typeof props.options === 'function') {
        optionValues.value = await props.options();
      } else {
        optionValues.value = props.options;
      }
      loading.value = false;
    });
    return {
      t,
      inputValue,
      onLanguageChanged,
      optionValues,
      loading,
    };
  },
});
</script>

<style lang="scss" scoped>
.phone-code {
  color: $mid-grey;
}

.countryLabelWithPhoneCode {
  margin-left: 2px;
}

.Flag {
  height: 24px;
}
</style>
