import { i18n } from '@/i18n';
import { ComponentData, ComponentMetadata, ErrorConfigForm } from '@/types/automated-scenarios';

let translation: any;
(async () => {
  translation = await i18n;
})();

const FiltreActionOnCampaignMetadata: ComponentMetadata<{}> = {
  Meta: {
    kind: 'filtre',
    id: 'actionOnCampaign',
    label: 'automatedScenarios.filters.actionOnCampaign',
    icon: 'fa-sun',
    hasConfiguration: false,
    persistent: [''],
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxperiodiclaunching', 'boxvisitpage',
        ],
      },
      bulk: {
        next_operators: [
          'boxabandonpanier', 'boxperiodiclaunching', 'boxvisitpage',
        ],
      },
    },
    component: 'FiltreActionOnCampaign',
    availableInCampaign: [],
  },
  Create(): ComponentData<{}> {
    return {
      id: 'actionOnCampaign',
      form_name: 'actionOnCampaign',
      logo_box: 'fa-sun',
    };
  },
  async Validate(): Promise<ErrorConfigForm> {
    return {
      success: true,
    };
  },
  Outputs(): number {
    return 1;
  },
  Label(): string {
    return translation.global.t('automatedScenarios.filters.actionOnCampaign');
  },
};

export default FiltreActionOnCampaignMetadata;
