import {
  ConfigurationPanelStructureItemEnum, FieldConfig, TemplateParentTypeEnum, WidgetTypeConfig,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import emailSeparatorFieldsConfig from '@/components/template-builder/config/templates/email/widgets/widget-separator-fields-config';
// eslint-disable-next-line import/no-cycle
import popupSeparatorFieldsConfig from '@/components/template-builder/config/templates/popup/widgets/widget-separator-fields-config';
// eslint-disable-next-line import/no-cycle
import embedSeparatorFieldsConfig from '@/components/template-builder/config/templates/embed/widgets/widget-separator-fields-config';

const getWidgetSeparatorConfig = (templateType: TemplateParentTypeEnum): WidgetTypeConfig => {
  let fieldItems: Array<FieldConfig> = [];
  switch (templateType) {
    case TemplateParentTypeEnum.POPUP:
      fieldItems = popupSeparatorFieldsConfig;
      break;
    case TemplateParentTypeEnum.EMBED:
      fieldItems = embedSeparatorFieldsConfig;
      break;
    default:
      fieldItems = emailSeparatorFieldsConfig;
  }

  return {
    tabPanel: {
      label: 'templateBuilder.widgetsHeaders.separator',
      items: [
        {
          type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
          label: 'templateBuilder.configs.widgetStyles',
          selector: 'body #spm_body #{ID}',
          items: fieldItems,
        },
      ],
    },
  };
};

export default getWidgetSeparatorConfig;
