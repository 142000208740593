
import {
  defineComponent,
  nextTick,
  onMounted,
  ref,
  Ref,
  watch,
} from 'vue';
import StatsWidgetRow from '@/components/stats/StatsWidgetRow.vue';
import StatsWidgetColumn from '@/components/stats/StatsWidgetColumn.vue';
import StatsWidgetData from '@/components/stats/StatsWidgetData.vue';
import StatsWidgetTitle from '@/components/stats/StatsWidgetTitle.vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Menubar from 'primevue/menubar';
import { StatsWidgets } from '@/types';
import { getParents, setActiveMenubarItem } from '@/helpers';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';

export default defineComponent({
  name: 'StatsWidgetRenderer',

  components: {
    StatsWidgetRow,
    StatsWidgetColumn,
    StatsWidgetData,
    StatsWidgetTitle,
    TabView,
    TabPanel,
    Menubar,
  },

  props: {
    configuration: {
      type: Object,
      required: true,
    },

    defaultFilters: {
      type: Object,
      required: true,
    },

    pageName: {
      type: String,
      required: false,
      default: '',
    },
  },

  setup(props) {
    const { locale } = useI18n();
    const store = useStore();
    const elRef = ref(null);
    const config: Ref<object> = ref(props.configuration);
    const filteredToolbar: Ref<{ [key: string]: any }[]> = ref([]);
    const activeMenuPanel: Ref<string> = ref('');
    const componentKey = ref(0);

    // Hide all panels and show the panel with ID in parameter
    const showStatMenuPage = (id: string) => {
      const menuPanel = document.querySelector(`#StatsWidgetMenuPanel-${id}`) as HTMLElement;

      if (menuPanel) {
        const parentMenu: HTMLElement = getParents(menuPanel, '.menubar-container')[0];
        // eslint-disable-next-line no-unused-expressions
        (parentMenu.querySelectorAll('.stats-widget-menu-panel') ?? []).forEach((el) => {
          el.classList.add('hidden');
        });

        // eslint-disable-next-line no-unused-expressions
        menuPanel.classList.remove('hidden');

        activeMenuPanel.value = `StatsWidgetMenuPanel-${id}`;
      }
    };

    let i = 0;
    const searchPanelsInToolbar = (arr: any) => arr.reduce(
      (acc: any, item: any) => {
        const newItem = JSON.parse(JSON.stringify(item));

        if (item.items) {
          newItem.items = searchPanelsInToolbar(item.items);
        }

        if (Object.prototype.hasOwnProperty.call(newItem, 'panelId')) {
          // eslint-disable-next-line no-param-reassign
          item.command = (event: any) => {
            setActiveMenubarItem(event.originalEvent.target);
            showStatMenuPage(item.panelId);
          };

          if (i === 0) {
            // eslint-disable-next-line no-param-reassign
            item.class = 'current-active-menu';
          }

          // We assign the class element for the toolbar item
          acc.push(newItem);

          if (i === 0) {
            i += 1;
          }
        }

        return acc;
      },
      filteredToolbar.value,
    );

    const formatData = (arr: any) => arr.reduce(
      async (a: any, item: any) => {
        await a;

        item.label = typeof item.label === 'string' ? item.label : item.label[locale.value];

        if (item.items) {
          await formatData(item.items);
        }
      },
      Promise.resolve(),
    );

    onMounted(() => {
      filteredToolbar.value = [];

      if (props.configuration.type === StatsWidgets.MENU) {
        nextTick(async () => {
          searchPanelsInToolbar(props.configuration.toolbar);
          await formatData(props.configuration.toolbar);

          // Mark first menu item as active
          if (elRef.value) {
            const firstItem = (elRef.value as unknown as HTMLElement).querySelector('.p-menubar li.p-menuitem:first-child');

            if (firstItem) {
              firstItem.classList.add('current-active-menu');
              activeMenuPanel.value = `StatsWidgetMenuPanel-${filteredToolbar.value[0].panelId}`;
            }
          }
        });
      }
    });

    watch(() => store.getters['statsEditor/getPageFilters'], () => {
      componentKey.value += 1;
    }, { deep: true });

    return {
      elRef,
      config,
      StatsWidgets,
      filteredToolbar,
      activeMenuPanel,
      componentKey,
    };
  },
});
