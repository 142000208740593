
import { computed, defineComponent } from 'vue';

import Card from 'primevue/card';

import List from '@/components/data-explorer/fields/List.vue';
import DataExplorerLink from '@/components/data-explorer/fields/DataExplorerLink.vue';
import OrderItem from '@/components/data-explorer/fields/OrderItem.vue';
import AddressMap from '@/components/data-explorer/fields/AddressMap.vue';

import {
  getAddressOrders,
} from '@/composables/data-explorer/DataExplorer';

import { countries } from '@/types/country-language-options';
import {
  Order,
} from '@/types/data-explorer-types';

import { useStore } from '@/store';

import { useI18n } from 'vue-i18n';
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';

export default defineComponent({
  name: 'AddressFile',

  components: {
    Card,
    DataExplorerLink,
    List,
    OrderItem,
    AddressMap,
  },

  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const selectedData = computed(() => store.getters['dataExplorer/getSelectedData']);
    const country = computed(() => {
      if (selectedData.value) {
        const item = countries.find((countryItem) => countryItem.code.toLowerCase() === (selectedData.value.country || '').toLowerCase());
        if (item) {
          return {
            code: item.code,
            label: item.label,
          };
        }
      }
      return {
        label: '-',
        code: '',
      };
    });
    
    const title = computed(() => {
      if (selectedData.value) {
        return t(`dataExplorer.tabs.${selectedData.value.dataType}`, [selectedData.value.tabLabel])
      }
      return '';
    });

    const circleOptions = {
      strokeColor: '#629d03',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#629d03',
      fillOpacity: 0.35,
    };

    const formatPhoneNumber = (phoneNumber: string, country: string) => {
      if (phoneNumber && country) {
        const phoneNumberObj = parsePhoneNumberFromString(phoneNumber, country.toUpperCase() as CountryCode);
        if (phoneNumberObj && phoneNumberObj.isValid()) {
          return phoneNumberObj.formatInternational();
        }
        if (phoneNumber) {
          return phoneNumber;
        }
      }
      return '-';
    };

    const handleRetrieveOrders = (orders: Array<Order>, dataKey: string, total: number) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          newLists: orders,
          dataKey,
          total,
        }
      );
    };

    const handleOrdersNextPage = (currentPage: number, dataKey: string) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          currentPage,
          dataKey,
        }
      );
    };

    return {
      t,
      locale,
      selectedData,
      title,
      country,
      circleOptions,

      getAddressOrders,
      formatPhoneNumber,
      handleRetrieveOrders,
      handleOrdersNextPage,
    }
  }
})
