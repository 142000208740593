<template>
  <div class="field-wrapper">
    <label
      v-if="label && label !== ''"
      class="block field-label mb-2"
    >
      {{ t(label) }}
    </label>

    <InputText
      v-if="type === 'text'"
      v-model="inputValue"
      :type="inputType || 'text'"
      class="input-text"
      :style="inputStyle"
      :class="{ 'p-invalid': errors && errors[fieldId] && errors[fieldId].$silentErrors.length }"
      :disabled="disable"
      :placeholder="placeholder"
    />
    <Password
      v-else-if="type === 'password'"
      v-model="inputValue"
      :feedback="false"
      :input-style="inputStyle"
      :class="{ 'p-invalid': errors && errors[fieldId] && errors[fieldId].$silentErrors.length }"
      toggle-mask
    />
    <FieldErrors
      :key="componentFieldErrorsKey"
      :errors="formValidationErrors"
      :field="fieldId"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent, Ref, ref, SetupContext, watch,
} from 'vue';
import InputText from 'primevue/inputtext';
import { useI18n } from 'vue-i18n';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import Password from 'primevue/password';

export default defineComponent({
  name: 'BaseTextInput',
  components: {
    InputText,
    Password,
    FieldErrors,
  },

  props: {
    fieldId: {
      type: String,
      required: false,
      default: '',
    },

    label: {
      type: String,
      required: false,
      default: '',
    },

    type: {
      type: String,
      required: false,
      default: 'text',
    },

    errors: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },

    inputStyle: {
      type: String,
      required: false,
      default: '',
    },

    inputType: {
      type: String,
      required: false,
      default: '',
    },

    modelValue: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      required: false,
      default: '',
    },

    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: {
    'update:modelValue': String,
  },

  setup(props: { modelValue: string; errors: any }, { emit }: SetupContext) {
    const { t } = useI18n();
    const inputValue: Ref<string> = ref(props.modelValue);
    const formValidationErrors = ref(props.errors);
    const componentFieldErrorsKey = ref(0);

    watch(() => props.errors, () => {
      formValidationErrors.value = props.errors;
      componentFieldErrorsKey.value += 1;
    });

    watch(inputValue, () => {
      emit('update:modelValue', inputValue.value);
    });

    return {
      t,
      formValidationErrors,
      componentFieldErrorsKey,
      inputValue,
    };
  },
});
</script>

<style  lang="scss">
.field-wrapper {
  .field-label {
    color: $tuna;
    font-size: 0.875rem;
    font-weight: 600;
  }

  .p-password {
    width: 100%;
  }

  .p-component.p-inputtext, .p-component.p-password-input {
    font-size: 0.9rem !important;
    font-family: "Inter", "Source Sans Pro", sans-serif;
    font-weight: normal;
    height: 2.5rem;
    line-height: 1.3125rem;
    border-color: #BABFC3;
    color: $tuna !important;
    border-radius: 0.3rem;
  }
}
</style>
