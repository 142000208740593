import { Get, GetResult } from '@/composables/GraphQL';
import { ShopsUsers, Users } from '@/types/generated-types/graphql';
import { UserTypeEnum } from '@/types';
import { getShopsUser } from '@/composables/user/ShopsUsers';

export default async function getUser(idUser: number) {
  return Get<Users>({
    name: 'Users',
    id: idUser,
    keyName: 'id_user',
    fields: ['id_user', 'first_name', 'last_name', 'email', 'lang', 'country'],
  });
}
export async function getUserLocale(idUser: number, userType: string) {
  let userLocale = '';
  // get lang and country to build locale
  if (userType === UserTypeEnum.ADMIN) {
    const users: GetResult<Users> = await getUser(idUser);
    userLocale = `${users.item?.lang}-${users.item?.country}`;
  } else {
    const shopsUsers: GetResult<ShopsUsers> = await getShopsUser(idUser);
    userLocale = shopsUsers.item?.lang ?? '';
  }

  return userLocale;
}
