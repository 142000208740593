<template>
  <div
    id="automatedScenariosLegend"
    class="pb-3"
  >
    <p
      v-html="t('automatedScenarios.history.legend')"
    />

    <Button
      id="backToSavedHistory"
      class="p-button-sm p-button-secondary mx-auto mt-3 hidden"
      :label="t('automatedScenarios.history.buttonBackToSavedHistory')"
      icon="far fa-redo"
      iconPos="left"
      @click="restoreHistory(state.scenario.savedHistoryIndex)"
    />
  </div>

  <ScrollPanel
    id="historyScrollPanel"
  >
    <ul
      class="savedList list-group"
    >
      <li
        v-for="(historyItem, index) in workflowHistory"
        :key="index"
        class="list-group-item"
        :class="index === state.scenario.currentHistoryIndex ? 'active' : ''"
        @click="restoreHistory(index)"
      >
        <span
          v-if="!!Object.getOwnPropertyDescriptor(historyItem, 'name')"
          v-html="historyItem.name"
          class="date"
        />
        <span
          v-else
          class="date"
        >
          <i
            :class="t('automatedScenarios.history.historyTypes.' + historyItem.type)"
          />
          {{ t(historyItem.label) }}
        </span>
      </li>
    </ul>
  </ScrollPanel>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import ScrollPanel from 'primevue/scrollpanel';
import Button from 'primevue/button';
import { AutomatedScenarioState as state, setActiveHistory } from '@/composables/AutomatedScenarios';
import { computed, onMounted, watch, SetupContext, defineComponent } from "vue";

export default defineComponent({
  name: 'HistoryPanel',

  components: {
    Button,
    ScrollPanel,
  },

  setup(_: any, context: SetupContext) {
    const { t } = useI18n();

    const workflowHistory = computed(() => {
      return state.scenario.history && Object.prototype.hasOwnProperty.call(state.scenario.history, 'history') && state.scenario.history.history && state.scenario.history.history !== '' ? JSON.parse(state.scenario.history.history).reverse() : [];
    });

    const restoreHistoryButtonDisplayed = computed(() => workflowHistory.value.length > 0 && state.scenario.savedHistoryIndex !== -1 && state.scenario.savedHistoryIndex !== state.scenario.currentHistoryIndex);

    const calculateScrollPanelHeight = () => {
      if (workflowHistory.value.length > 0) {
        let heightToDeduct = 0;

        const button: HTMLElement | null = document.querySelector('#backToSavedHistory');

        if (button) {
          if (restoreHistoryButtonDisplayed.value) {
            button.classList.remove('hidden');
            button.classList.add('block');
          } else {
            button.classList.add('hidden');
            button.classList.remove('block');
          }
        }

        const legend = <HTMLElement>document.querySelector('#automatedScenariosLegend');
        const scrollPanel = <HTMLElement>document.querySelector('#historyScrollPanel');
        const sideBar = <HTMLElement>document.querySelectorAll('.content-sidebar')[0];
        const panelHeader = <HTMLElement>document.querySelectorAll('.p-panel-header')[0];
        const panelContent = <HTMLElement>document.querySelectorAll('.p-panel-header')[0];
        // const sideBarIcons = <HTMLElement> document.querySelectorAll('.content-sidebar .p-tabview-nav-content')[0];
        const legendStyles = window.getComputedStyle(legend);
        const panelContentStyles = window.getComputedStyle(panelContent);

        heightToDeduct += parseInt(legendStyles.marginTop, 10);
        heightToDeduct += parseInt(legendStyles.marginBottom, 10);
        heightToDeduct += parseInt(legendStyles.paddingTop, 10);
        heightToDeduct += parseInt(legendStyles.paddingBottom, 10);
        heightToDeduct += parseInt(panelContentStyles.marginTop, 10);
        heightToDeduct += parseInt(panelContentStyles.paddingTop, 10);
        heightToDeduct += parseInt(panelContentStyles.borderBottomWidth, 10);
        heightToDeduct += panelHeader.offsetHeight;
        heightToDeduct += legend.offsetHeight;

        scrollPanel.style.height = (sideBar.offsetHeight - heightToDeduct) + 'px';
      }
    };

    const restoreHistory = (historyIndex: number) => {
      setActiveHistory(historyIndex);
    };

    watch(restoreHistoryButtonDisplayed, calculateScrollPanelHeight);

    onMounted(calculateScrollPanelHeight);

    return {
      t,
      state,
      calculateScrollPanelHeight,
      restoreHistory,
      workflowHistory
    };
  }
});
</script>

<style lang="scss" scoped>
.savedList .list-group-item {
  padding: 5px 10px;
  height: 41px;
  vertical-align: middle;
  position: relative;
  display: block;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
  font-size: 13px;
  cursor: pointer;
}

.savedList .list-group-item:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.savedList .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.savedList .list-group-item.active {
  background: #91c53f;
  border-color: #91c53f;
  color: #ffffff;
}

.savedList .list-group-item .date {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1em;
}

#historyScrollPanel {
  width: 100%;
  height: 500px;
}

#historyScrollPanel .p-scrollpanel-bar {
  background: #666 !important;
}
</style>
