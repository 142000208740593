<template>
  <div
    class="flex w-full relative align-items-center justify-content-start my-4 px-4"
  >
    <div class="border-top-1 surface-border top-50 left-0 absolute w-full"></div>
    <div class="px-2 z-1 surface-0 flex align-items-center">
      <span class="text-900 font-semibold">{{ title }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SectionTitle',

  props: {
    title: {
      type: String,
      required: true
    },
  }
})
</script>
