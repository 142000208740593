
import { defineComponent, ref, SetupContext } from 'vue';
import StatsBuilderSubRow from '@/components/stats-builder/StatsBuilderSubRow.vue';
import draggable from 'vuedraggable';
import { useI18n } from 'vue-i18n';
import Tooltip from 'primevue/tooltip';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

export default defineComponent({
  name: 'StatsWidgetTabsRender',
  components: {
    StatsBuilderSubRow,
    draggable,
    TabView,
    TabPanel,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    rowId: {
      type: String,
      required: true,
    },

    columnId: {
      type: String,
      required: true,
    },

    editMode: {
      type: Boolean,
      required: true,
    },

    widgetData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  setup(props: { widgetData: object; editMode: boolean}, { emit }: SetupContext) {
    const enabled = ref(true);
    const dragging = ref(false);
    const dragOptions = ref({
      animation: 200,
      group: { name: 'line_builder', pull: false, put: ['line_element'] },
      disabled: false,
    });

    return {
      enabled,
      dragging,
      dragOptions,
    };
  },
});
