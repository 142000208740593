
import { defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import SpmTable from '@/components/table/SpmTable.vue';
import { SpmTableColumns, SpmTableState } from '@/types';
import Synchronization from '@/components/header/Synchronization.vue';
import ShopsSyncsInProgress from '@/components/header/ShopsSyncsInProgress.vue';
import { ListResult } from '@/composables/GraphQL';
import { ShopsSyncs } from '@/types/generated-types/graphql';
import { findPermission, UserState } from '@/composables/User';
import ShopsSyncsList from '@/composables/shop/ShopSyncs';
import Button from 'primevue/button';
import {getShopCustomerLanguages} from "@/types/country-language-options";

export default defineComponent({
  name: 'Vouchers',
  components: {
    Synchronization,
    SpmTable,
    ShopsSyncsInProgress,
    Button,
  },

  setup() {
    const { t } = useI18n();
    const synchroData = ref<ListResult<ShopsSyncs>>();
    const idShop = UserState.activeShop?.id ?? 0;
    const launchSynchro = ref(false);
    const columns: SpmTableColumns[] = [
      {
        field: 'id_shop_voucher',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'date_modification',
        header: t('myShopData.vouchers.headers.date_modification'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:15%',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
      },
      {
        field: 'id_voucher',
        header: t('id'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:10%',
        type: 'text',
      },
      {
        field: 'code',
        header: t('myShopData.vouchers.headers.code'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:15%',
        type: 'text',
      },
      {
        field: 'name',
        header: t('myShopData.vouchers.headers.name'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'lang',
        header: t('myShopData.vouchers.headers.lang'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:10%',
        type: 'language',
        filterSettings: { type: 'language', options: getShopCustomerLanguages, hideFilterMenu: true },
      },
      {
        field: 'active',
        header: t('myShopData.vouchers.headers.active'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:10%',
        type: 'active-status-icon',
        filterSettings: {
          type: 'multiSelect',
          options: [{ value: 1, label: t('active') }, { value: 0, label: t('inactive') }],
          hideFilterMenu: true,
          showFilterInput: false,
        },
      },
    ];

    onMounted(async () => {
      synchroData.value = await ShopsSyncsList('vouchers,all', idShop, 'user');
    });

    const synchroAction = (launch: boolean) => {
      launchSynchro.value = launch;
    };

    return {
      t,
      columns,
      findPermission,
      synchroData,
      idShop,
      launchSynchro,
      synchroAction,
    };
  },
});
