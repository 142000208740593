<template>
  <div
    v-if="(!displayOnPageView || defaultFiltersData['date_interval'].visibleOnView)"
    class="field-wrapper filter-content"
  >
    <label class="block field-label mb-1">
      <div class="flex justify-content-between flex-wrap">
        <div class="flex align-items-center justify-content-center">
          {{ t(StatsPageFiltersMetaData['date_interval'].label) }}
        </div>
        <div
          v-if="!displayOnPageView && !hideVisibleIcon"
          class="flex align-items-center justify-content-center"
        >
          <InputSwitch
            v-model="defaultFiltersData['date_interval'].visibleOnView"
            v-tooltip.bottom="defaultFiltersData['date_interval'].visibleOnView ?
              t('statsEditor.sidebarPanel.filters.hideFilterOnPageTooltip') : t('statsEditor.sidebarPanel.filters.showFilterOnPageTooltip')"
          />
        </div>
      </div>
    </label>
    <div
      v-if="!displayOnPageView && !hideVisibleIcon"
      class="mb-2"
    >
      <InlineMessage
        v-if="defaultFiltersData['date_interval'].visibleOnView"
        severity="info"
        style="width: 100%;"
      >
        {{ t('statsEditor.sidebarPanel.filters.showFilterOnPageInfo') }}
      </InlineMessage>
      <InlineMessage
        v-else
        severity="info"
        style="width: 100%;"
      >
        {{ t('statsEditor.sidebarPanel.filters.hideFilterOnPageInfo') }}
      </InlineMessage>
    </div>
    <CustomDatePicker
      v-model="defaultFiltersData['date_interval'].value"
      :update-value="updateDateInterval"
      @reset-update-value="updateDateInterval = false"
      @value-choosed="updateDateMessage = ''"
    />
    <InlineMessage
      v-if="updateDateMessage"
      severity="info"
      class="mt-1"
      style="width: 100%;"
    >
      {{ updateDateMessage }}
    </InlineMessage>
  </div>

  <template
    v-for="(itemFilter, itemId) of defaultFiltersData"
    :key="itemId"
  >
    <div
      v-if="(StatsPageFiltersMetaData[itemId].component === 'MultiSelect' || StatsPageFiltersMetaData[itemId].component === 'Dropdown') && (!displayOnPageView || defaultFiltersData[itemId].visibleOnView)"
      class="field-wrapper filter-content"
    >
      <label class="block field-label mb-1">
        <div class="flex justify-content-between flex-wrap">
          <div class="flex align-items-center justify-content-center">
            {{ t(StatsPageFiltersMetaData[itemId].label) }}
          </div>
          <div
            v-if="!displayOnPageView"
            class="flex align-items-center justify-content-center"
          >
            <div
              v-if="!hideVisibleIcon"
              class="flex"
            >
              <InputSwitch
                v-model="defaultFiltersData[itemId].visibleOnView"
                v-tooltip.bottom="defaultFiltersData[itemId].visibleOnView ?
                  t('statsEditor.sidebarPanel.filters.hideFilterOnPageTooltip') : t('statsEditor.sidebarPanel.filters.showFilterOnPageTooltip')"
              />
            </div>
            <div
              v-tooltip.right="t('statsEditor.actions.tooltip.column.remove')"
              class="flex sb-filter-action ml-2"
              @click="(e) => removeFilter(e, itemId)"
            >
              <i class="far fa-trash" />
            </div>

          </div>
        </div>
      </label>
      <div
        v-if="!displayOnPageView && !hideVisibleIcon"
        class="mb-2"
      >
        <InlineMessage
          v-if="defaultFiltersData[itemId].visibleOnView"
          severity="info"
          style="width: 100%;"
        >
          {{ t('statsEditor.sidebarPanel.filters.showFilterOnPageInfo') }}
        </InlineMessage>
        <InlineMessage
          v-else
          severity="info"
          style="width: 100%;"
        >
          {{ t('statsEditor.sidebarPanel.filters.hideFilterOnPageInfo') }}
        </InlineMessage>
      </div>

      <component
        :is="StatsPageFiltersMetaData[itemId].component"
        v-model="defaultFiltersData[itemId].value"
        style="width: 100%;"
        :options="(optionsOfFilters && optionsOfFilters.find((findingItem) => findingItem.id == itemId))
          ? optionsOfFilters.find((findingItem) => findingItem.id == itemId).options : []"
        :filter="true"
        :loading="!(optionsOfFilters && optionsOfFilters.find((findingItem) => findingItem.id == itemId))"
        option-label="name"
        option-value="id"
        :showClear="defaultFiltersData[itemId].value !== ''"
        :selection-limit="1"
        :placeholder="t('statsEditor.sidebarPanel.filters.multiSelectPlaceHolder')"
        @update:model-value="() => handleFilterChange(itemId)"
      />
    </div>
  </template>

  <div
    v-if="!displayOnPageView"
    class="flex align-items-center justify-content-center m-2"
  >
    <Dropdown
      v-model="selectedNewFilter"
      :options="listOfAvailableFilters"
      :option-disabled="getListOfNotAvailableFilters"
      option-label="name"
      :placeholder="t('statsEditor.sidebarPanel.filters.addNewFilter')"
      class="w-full"
    />
    <ConfirmDialog group="deleteFilter" />
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, onBeforeMount, reactive, Ref,
  ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { StatsPageFiltersMetaData } from '@/components/stats-builder/metadata/filters';
import { store } from '@/store';
import MultiSelect from 'primevue/multiselect';
import CustomDatePicker from '@/components/fields/CustomDatePicker.vue';
import InputSwitch from 'primevue/inputswitch';
import InlineMessage from 'primevue/inlinemessage';
import Tooltip from 'primevue/tooltip';
import Dropdown from 'primevue/dropdown';
import { useConfirm } from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';
import { IntervalDateEnum } from '@/types';
import deepcopy from 'deepcopy';
import moment from 'moment';

export default defineComponent({
  name: 'StatsBuilderFilters',

  components: {
    InputSwitch,
    CustomDatePicker,
    MultiSelect,
    InlineMessage,
    Dropdown,
    ConfirmDialog,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    defaultFilters: {
      type: Object,
      required: false,
    },

    hideVisibleIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['filters-changed'],

  setup(props: {defaultFilters: any}, { emit }) {
    const { t } = useI18n();
    const confirm = useConfirm();

    /* contains asynchronous options of some filters which need to be loaded from the API */
    const optionsOfFilters: Ref<any[]> = ref([]);

    const defaultFiltersData: any = reactive(props.defaultFilters ?? store.getters['statsEditor/getPageFilters']);
    let appliedFilters = deepcopy(defaultFiltersData); // Keep a copy (without reference) of currently applied filters
    let resetFilters = false; // Boolean to prevent triggering the "watch" on defaultFiltersData if we need to reset some filters
    const displayOnPageView = ref(!!props.defaultFilters);

    const selectedNewFilter = ref();
    const listOfAvailableFilters = computed(() => Object.keys(StatsPageFiltersMetaData)
      .filter((item: any) => !(item === 'date_interval'))
      .map((item: any) => ({
        name: t(StatsPageFiltersMetaData[item].label),
        code: item,
      })));

    const getListOfNotAvailableFilters = (option: any) => Object.keys(defaultFiltersData).includes(option.code);

    const removeFilter = (e: any, filterId: string) => {
      e.stopPropagation();
      confirm.require({
        group: 'deleteFilter',
        message: t('statsEditor.sidebarPanel.filters.removeFilterConfirmation'),
        header: 'Confirmation',
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('yes'),
        rejectLabel: t('no'),
        accept: async () => {
          delete defaultFiltersData[filterId];
          if (filterId === 'id_campaign_bulk') {
            updateDateMessage.value = '';
          }
        },
      });
    };

    const loadFiltersOptions = async (triggerEmit = true) => {
      // Reset available filters options
      optionsOfFilters.value = [];

      /* load options of filters which need to be loaded from the API */
      const optionPromises = Object.keys(StatsPageFiltersMetaData)
        .filter((itemKey: any) => StatsPageFiltersMetaData[itemKey].getOptions)
        .map(async (itemKey: any) => ({
          id: itemKey,
          options: await StatsPageFiltersMetaData[itemKey].getOptions(defaultFiltersData),
        }));

      optionsOfFilters.value = await Promise.all(optionPromises);

      // Emit filters-changed to reload stats widgets after options are loaded
      if (triggerEmit) {
        emit('filters-changed');
      }
    };

    /* Watch selectedNewFilter to add new filter */
    watch(() => selectedNewFilter.value, () => {
      if (selectedNewFilter.value !== null) {
        defaultFiltersData[selectedNewFilter.value.code] = StatsPageFiltersMetaData[selectedNewFilter.value.code].defaultData;
        selectedNewFilter.value = null;
      }
    });

    watch(() => defaultFiltersData.date_interval.value.interval, async (newValue, oldValue) => {
      if (newValue !== oldValue) {
        // Clear values of other filters
        resetFilters = true;

        await loadFiltersOptions();
        appliedFilters = deepcopy(defaultFiltersData);
        resetFilters = false;
      }
    });

    watch(defaultFiltersData, (newValue) => {
      if (!resetFilters && newValue.date_interval.value.interval === appliedFilters.date_interval.value.interval) {
        // Trigger emit only if the date interval has not been changed and if we are not resetting some filters (in previous watch).
        // If it has been changed, the "emit" will be done by the loadFiltersOptions function.
        emit('filters-changed');
      }

      appliedFilters = deepcopy(defaultFiltersData);
    }, { deep: true });

    const updateDateInterval = ref(false);
    const updateDateMessage = ref('');

    const updateFilterDate = () => {
      let campaignValue = defaultFiltersData.id_campaign_bulk.value;
      if (Array.isArray(defaultFiltersData.id_campaign_bulk.value) && defaultFiltersData.id_campaign_bulk.value.length) {
        campaignValue = defaultFiltersData.id_campaign_bulk.value[0];
      }
      const campaign = optionsOfFilters.value.find((option) => option.id === 'id_campaign_bulk');
      if (campaign) {
        const selectedCampaign = campaign.options.find((item: any) => item.id === campaignValue);
        if (selectedCampaign) {
          defaultFiltersData.date_interval.value.interval = IntervalDateEnum.CUSTOM_DATE_RANGE;
          const startDate = moment(selectedCampaign.dateSend, 'YYYYMMDDHHmmss');
          defaultFiltersData.date_interval.value.customDateRange = [startDate.toDate(), startDate.clone().add(30, 'days').toDate()];
          updateDateInterval.value = true;
          updateDateMessage.value = t('statsEditor.sidebarPanel.filters.dateFilterAdjustmentMessage');
        }
      }
    }

    const handleFilterChange = (itemId: string) => {
      if (itemId === 'id_campaign_bulk') {
        if (defaultFiltersData.id_campaign_bulk && defaultFiltersData.id_campaign_bulk.visibleOnView && defaultFiltersData.id_campaign_bulk.value) {
          updateFilterDate();
        } else {
          defaultFiltersData.date_interval.value.interval = IntervalDateEnum.LAST_30_DAYS;
          delete defaultFiltersData.date_interval.value.customDateRange;
          defaultFiltersData.id_campaign_bulk.value = '';
          updateDateInterval.value = true;
          updateDateMessage.value = '';
        }
      }
    };

    onBeforeMount(async () => {
      await loadFiltersOptions(false);
      if (defaultFiltersData.id_campaign_bulk
        && defaultFiltersData.id_campaign_bulk.visibleOnView
        && defaultFiltersData.id_campaign_bulk.value
        && defaultFiltersData.date_interval.value.interval !== IntervalDateEnum.CUSTOM_DATE_RANGE
      ) {
        updateFilterDate();
      }
    });

    return {
      t,
      StatsPageFiltersMetaData,
      optionsOfFilters,
      displayOnPageView,
      defaultFiltersData,
      selectedNewFilter,
      listOfAvailableFilters,
      updateDateInterval,
      updateDateMessage,
      getListOfNotAvailableFilters,
      removeFilter,
      handleFilterChange,
    };
  },
});
</script>

<style lang="scss">
.visibleOnView {
  cursor: pointer;

  &.visible {
    color: #10b981;
  }

  &.notVisible {
    color: #db5858;
  }
}
</style>

<style scoped lang="scss">
  .filter-content {
    padding: 0.5rem 1rem 1rem;
    border-radius: 4px;
    border: 1px solid #e9ecef;
  }

  .sb-filter-action {
    cursor: pointer;
    padding: 9px;
    background: white;
    border: solid 1px #e5e5e5;
    border-radius: 5px;
  }
</style>
