
import { useI18n } from 'vue-i18n';
import { defineComponent, reactive, ref } from 'vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { emailFormatValidator } from '@/helpers/CustomValidator';
import useVuelidate from '@vuelidate/core';
import { showToastError, showToastSuccess } from '@/helpers';
import BaseTextInput from '@/components/fields/BaseTextInput.vue';
import { useRouter } from 'vue-router';
import { nestPost } from '@/composables/nestApi';
import { maxLength, minLength, required, sameAs } from '@vuelidate/validators';

export default defineComponent({
  name: 'ChangePassword',
  components: {
    BaseTextInput,
    SpmButton,
  },

  props: {
    token: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const isLoadingForm = ref(false);
    const formValidation = ref<any>({});
    const token = ref(props.token);

    const userInfo = reactive({
      password: '',
      confirm: '',
    });

    const validateRegisterFormData = async (): Promise<ErrorConfigForm> => {
      const rules = {
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(40),
        },

        confirm: {
          required,
          sameAsPassword: sameAs(userInfo.password),
        },
      };

      const v$ = useVuelidate(rules, userInfo);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    const validateRegisterForm = async () => {
      isLoadingForm.value = true;
      const validation = await validateRegisterFormData();
      if (!validation.success) {
        formValidation.value = validation.validate;
        isLoadingForm.value = false;
      } else {
        try {
          const result = await nestPost('v4', '/user/regenerate-password', {}, {
            token: token.value,
            password: userInfo.password,
          });

          if (result && result.success) {
            await showToastSuccess(t('user.changePassword.success'));
            await router.push('/auth/login');
          } else {
            await showToastError(t('errorMessages.GENERIC_ERROR'));
          }
        } catch (e) {
          await showToastError(t('errorMessages.GENERIC_ERROR'));
        }

        isLoadingForm.value = false;
      }
    };

    return {
      t,
      formValidation,
      userInfo,
      isLoadingForm,
      validateRegisterForm,
    };
  },
});
