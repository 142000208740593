import { BaseFieldConfig, ComponentNameEnum } from '@/types';

const bordersFieldConfig: BaseFieldConfig = {
  componentName: ComponentNameEnum.BORDERS,
  options: {
    defaultBorderStyle: 'none',
    defaultColor: '#808080',
    defaultWidth: 0,
  },
};
export default bordersFieldConfig;
