<template>
  <EditorModal
    :toolbar-right-buttons="toolbarRightButtons"
    :title="title"
    :tabs="tabs"
    class="data-explorer"
  >
    <template #content>
      <component
        :is="dataType"
        class="m-5 p-5"
      />
    </template>
  </EditorModal>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  ComputedRef
} from 'vue';

import EditorModal from '@/components/modals/EditorModal.vue';
import ContactFile from '@/components/data-explorer/data-type/ContactFile.vue';
import AddressFile from '@/components/data-explorer/data-type/AddressFile.vue';
import OrderFile from '@/components/data-explorer/data-type/OrderFile.vue';
import CartFile from '@/components/data-explorer/data-type/CartFile.vue';

import { useStore } from '@/store';

import { PrimvueMenuModel, DataExplorerDataType } from '@/types';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'DataExplorer',

  components: {
    EditorModal,
    ContactFile,
    AddressFile,
    OrderFile,
    CartFile,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();

    const openedDatas = computed(() => store.getters['dataExplorer/getOpenedDatas']);
    const selectedData = computed(() => store.getters['dataExplorer/getSelectedData']);
    const dataType = computed(() => {
      switch (selectedData.value.dataType) {
        case DataExplorerDataType.CONTACT_FILE:
          return 'ContactFile';
        case DataExplorerDataType.ADDRESS_FILE:
          return 'AddressFile';
        case DataExplorerDataType.ORDER_FILE:
          return 'OrderFile';
        case DataExplorerDataType.CART_FILE:
          return 'CartFile';
        default:
          return 'ContactFile';
      }
    });

    const title = computed(() => {
      if (selectedData.value) {
        return t(`dataExplorer.tabs.${selectedData.value.dataType}`, [selectedData.value.tabLabel])
      }
      return '';
    });

    const tabs: ComputedRef<PrimvueMenuModel[]> = computed(() => {
      return openedDatas.value.map((openedData: any) => {
        const isActiveData = selectedData.value ? (openedData.dataId === selectedData.value.dataId) : true;

        return {
          label: t(`dataExplorer.tabs.${openedData.dataType}`, [openedData.tabLabel]),
          class: `${isActiveData ? 'p-button-primary' : 'p-button-secondary'} footer-btn`,
          command: !isActiveData ? () => { store.dispatch('dataExplorer/switchData', { dataId: openedData.dataId }) } : undefined,
          items: [
            {
              label: t('dataExplorer.tabs.closeTab'),
              command: () => {
                store.dispatch('dataExplorer/closeData', { dataId: openedData.dataId });
              }
            }
          ],
        }
      });
    });

    const toolbarRightButtons: PrimvueMenuModel[] = [
      {
        label: t('dataExplorer.minimize'),
        command: () => { store.dispatch('dataExplorer/minimize') }
      },
      {
        label: t('dataExplorer.leave'),
        command: () => { store.dispatch('dataExplorer/leave') }
      }
    ];

    return {
      t,
      toolbarRightButtons,
      tabs,
      selectedData,
      dataType,
      title,
    };
  },
});
</script>

