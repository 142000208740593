<template>
  <div class="p-fluid">
    <div class="field">
      <EditAutocomplete
        v-model="selectedTags"
        field="tag"
        title="automatedScenarios.actions.tag"
        :complete="(query) => fetchTags(query)"
      />
      <FieldErrors
        :key="componentFieldErrorsKey"
        :errors="formValidationErrors"
        field="autocomp_tag"
      />
    </div>
    <div class="field-wrapper">
      <div class="field">
        <ButtonGroup
          v-model="type"
          :options="options"
          title="automatedScenarios.actions.tagActionChoice"
        />
        <FieldErrors
          :key="componentFieldErrorsKey"
          :errors="formValidationErrors"
          field="multiplebox_type"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType, ref, Ref, SetupContext, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { InsertShopsTags, ShopsTagsList } from '@/composables/shop/ShopsTags';
import { ShopsTags, ShopsTagsInputItem } from '@/types/generated-types/graphql';
import { UserState } from '@/composables/User';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import EditAutocomplete from '../../fields/EditAutocomplete.vue';
import ButtonGroup from '../../fields/ButtonGroup.vue';
import ActionTagMetaData, { ActionTagData } from '../../metadata/actions/ActionTagMetadata';

export default defineComponent({
  name: 'ActionTag',

  components: {
    FieldErrors,
    EditAutocomplete,
    ButtonGroup,
  },

  props: {
    modelValue: {
      type: Object as PropType<ActionTagData>,
      required: true,
      default() {
        return ActionTagMetaData.Create();
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: ActionTagData; formValidation: any }, context: SetupContext) {
    const { t } = useI18n();
    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    const selectedTags: any = ref(props.modelValue.autocomp_tag && props.modelValue.autocomp_tag.length > 0 && Object.keys(props.modelValue.autocomp_tag[0]).includes('id_shop_tag')
      ? props.modelValue.autocomp_tag : Object.entries(props.modelValue.autocomp_tag).map(([id, tag]) => ({
        id_shop_tag: parseInt(id, 10),
        tag,
      })));


    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
    const type: Ref<number> = ref(props.modelValue.multiplebox_type);
    const options = [
      { value: 0, label: t('automatedScenarios.actions.add') },
      { value: 1, label: t('automatedScenarios.actions.remove') },
    ];

    async function fetchTags(tagFilter: string) {
      let result: ShopsTags[]| null = await ShopsTagsList({ shopId: idShop.value, tag: tagFilter });

      if (result === null || result.length === 0) {
        const input: ShopsTagsInputItem[] = [
          {
            id_shop: idShop.value,
            tag: tagFilter,
          },
        ];
        const newShopTagId = await InsertShopsTags(input);
        if (newShopTagId) {
          result = [{ id_shop_tag: Number(newShopTagId), tag: tagFilter }];
        }
      }
      return result;
    }

    const update = () => {
      context.emit('update:modelValue', ActionTagMetaData.Create({
        autocomp_tag: selectedTags.value,
        multiplebox_type: type.value,
      }));
    };

    watch([selectedTags, type], update);

    return {
      formValidationErrors,
      componentFieldErrorsKey,
      fetchTags,
      type,
      options,
      selectedTags,
    };
  },
});
</script>
