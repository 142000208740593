<template>
  <div v-if="kpiData.type === 'knob'">
    <div class="col-12 text-center font-bold pb-3 kpi-name">
      {{ kpiName }}
    </div>
    <div class="col-12 kpi-content">
      <Knob
        v-if="hasData"
        v-model="dataValue"
        readonly="true"
        size="350"
        stroke-width="10"
      />
      <div
        v-else
        class="no-data"
      >
        {{ t('stats.noData') }}
      </div>
    </div>
  </div>
  <div
    v-else
    class="grid"
  >
    <div class="col-12 text-center font-bold kpi-name">
      {{ kpiName }}
    </div>
    <div
      class="col-12 kpi-content text-center"
    >
      <div
        v-if="hasData"
        class="flex flex-wrap h-full align-items-center"
      >
        <div
          v-for="(kpi, index) in data"
          :key="index"
          v-tooltip.bottom="kpi.legend"
          data-toggle="tooltip"
          class="kpi-container flex align-items-center justify-content-center flex-wrap"
        >
          <div
            v-if="kpi.label !== ''"
            class="kpi-label"
          >
            <i
              class="fa fa-fw"
              :class="kpi.icon"
            />
            {{ kpi.label }}
          </div>
          <div
            class="kpi-value"
            :style="kpi.color ? `color: ${kpi.color};` : ''"
            v-html="kpi.value"
          />
        </div>
      </div>
      <div
        v-else
        class="no-data"
      >
        {{ t('stats.noData') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  Ref,
  ref,
} from 'vue';
import { UserState } from '@/composables/User';
import { DEFAULT_CURRENCY } from '@/components/template-builder/utils/constants';
import { formatNumbersInString, formatNumberToCurrency } from '@/helpers/Number';
import Knob from 'primevue/knob';
import { SpmKpiConfiguration } from '@/types/stats-types';
import { useI18n } from 'vue-i18n';
import Tooltip from 'primevue/tooltip';

export default defineComponent({
  name: 'SpmKpi',

  components: {
    Knob,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    kpiName: {
      type: String,
      required: true,
    },

    kpiData: {
      type: Array as PropType<SpmKpiConfiguration[]>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const data: Ref<SpmKpiConfiguration[]> = ref(props.kpiData);

    const statsIcons: Record<string, any> = {
      channels: {
        'stats.statsLabels.channels.email': 'fa-regular fa-envelope',
        'stats.statsLabels.channels.display': 'fa-regular fa-desktop',
        'stats.statsLabels.channels.popup': 'fa-regular fa-share',
        'stats.statsLabels.channels.embed': 'fa-regular fa-list-alt',
        'stats.statsLabels.channels.pushnotifications': 'fa-regular fa-bell',
        'stats.statsLabels.channels.sms': 'fa-regular fa-sms',
        'stats.statsLabels.channels.facebookmessenger': 'fa-brands fa-facebook-messenger',
        'stats.statsLabels.channelsIntegers.1': 'fa-regular fa-envelope',
        'stats.statsLabels.channelsIntegers.2': 'fa-regular fa-sms',
        'stats.statsLabels.channelsIntegers.3': 'fa-regular fa-share',
        'stats.statsLabels.channelsIntegers.4': 'fa-regular fa-list-alt',
        'stats.statsLabels.channelsIntegers.5': 'fa-brands fa-facebook-messenger',
        'stats.statsLabels.channelsIntegers.6': 'fa-regular fa-bell',
      },

      boxes: {
        'stats.statsLabels.boxes.boxsendemail': 'fa-regular fa-envelope',
        'stats.statsLabels.boxes.boxdisplayfreemodel': 'fa-regular fa-list-alt',
        'stats.statsLabels.boxes.boxdisplayfreepopup': 'fa-regular fa-share',
        'stats.statsLabels.boxes.boxsendpushnotification': 'fa-regular fa-bell',
        'stats.statsLabels.boxes.boxsendsms': 'fa-regular fa-sms',
        'stats.statsLabels.boxes.boxsendfbmessenger': 'fa-brands fa-facebook-messenger',
      },

      registrations: {
        'stats.statsLabels.registrations.registration': 'fa-regular fa-store',
        'stats.statsLabels.registrations.newsletter': 'fa-regular fa-envelope',
      },
    };

    const hasData = data.value.length > 0;

    data.value.forEach((kpi) => {
      const current = kpi;

      let icon = null;
      if (current.icon && current.label) {
        icon = Object.prototype.hasOwnProperty.call(statsIcons, `${current.icon}`)
        && Object.prototype.hasOwnProperty.call(statsIcons[current.icon], current.label) ? statsIcons[current.icon][current.label] : null;
      }

      if (current.label) {
        current.label = t(current.label);
      }

      if (current.legend) {
        current.legend = t(current.legend);
      }

      if (icon) {
        current.icon = icon;
      }

      if (current.type === 'price') {
        // Format value as price
        current.value = formatNumbersInString(formatNumberToCurrency(parseFloat(current.value), UserState.activeShop?.currency ?? DEFAULT_CURRENCY));
      } else if (current.type !== 'string') {
        current.type = 'number';
        current.value = formatNumbersInString(current.value);
      }
    });

    return {
      t,
      hasData,
      data,
    };
  },
});
</script>

<style scoped lang="scss">
.kpi-name {
  font-size: 1.2rem;
  height: 40px;
}

.kpi-content {
  position: relative;
  height: 100%;

  & .no-data {
    font-size: 1.4rem;
    text-align: center;
    color: $light-grey;
  }

  & .kpi-container {
    flex: 1 0 50%;
    margin-bottom: 25px;
  }

  & .kpi-label {
    font-size: 1rem;
    color: $nero;
    flex-basis: 100%;
    margin-bottom: 5px;
  }

  & .kpi-value {
    font-size: 1.2rem;
    color: $shuttle-grey;
    flex-basis: 100%;
    margin-bottom: 5px;

    &:deep() .kpi-count-value {
      display: block;
      font-size: 1.1rem;
      color: $heather;
      margin-top: 5px;
    }
  }

  & .kpi-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    border-radius: 50%;
    color: $shuttle-grey;
  }

  & .p-knob:deep() {
    & .p-knob-value {
      stroke: $knobValueStroke !important;
    }

    & .p-knob-text {
      opacity: 0.6 !important;
    }
  }
}
</style>
