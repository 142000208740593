import {
  CustomMutation,
  Get, List, Update,
} from '@/composables/GraphQL';
import {
  OperatorType, ReplaceRedirectionTemplateInputItem,
  Templates, TemplatesDuplicateInputItem,
  TemplatesLang,
  TemplatesLangUpdateInputItem,
  TemplatesUpdateInputItem,
} from '@/types/generated-types/graphql';

interface TemplateArgs {
  shopId: number;
  limit: number;
  offset: number;
  type: string;// email
  fields: string;
}

export enum TemplateTypeEnum {
  EMAIL = 'email',
  SMS = 'sms',
  FACEBOOKMESSENGER = 'facebookmessenger',
  PUSHNOTIFICATIONS = 'pushnotifications',
  POPUP = 'popup',
  EMBED = 'embed'
}

export const GetTemplatesList = async ({
  shopId, type, fields, limit, offset,
}: TemplateArgs): Promise<Array<Templates>|null> => {
  const { items, total, err } = await List<Templates>({
    name: 'Templates',
    settings: {
      filter: [
        {
          field: 'id_shop',
          operator: OperatorType.Equals,
          value: shopId,
        },
        {
          field: 'type',
          operator: OperatorType.Equals,
          value: type,
        },
      ],
      order: [
        { field: 'id_template', type: 'ASC' },
      ],
      limit,
      offset,
    },
    fields: [fields],
  });
  // 'id_templates_lang, label, template_config, templatesLang{id_template, subject, from_email, from_name, config}'
  if (err === '') {
    return items;
  }
  return null;
};

export const GetFullTemplateLang = async (id_templates_lang: number, fields: string): Promise<TemplatesLang|null> => {
  const {
    item,
    err,
  } = await Get<TemplatesLang>({
    id: id_templates_lang,
    name: 'TemplatesLang',
    keyName: 'id_templates_lang',
    fields: [
      fields,
    ],
  });

  if (err) {
    return null;
  }

  if (!item) {
    return null;
  }

  return item;
};

export const UpdateTemplate = async (templateInput: TemplatesUpdateInputItem[]) => {
  const {
    id, status, messages, err,
  } = await Update<TemplatesUpdateInputItem>({
    name: 'UpdateTemplate',
    input: templateInput,
    type: 'TemplatesUpdateInput',
  });

  if (err === '') {
    return id;
  }
  return messages;
};

export const UpdateTemplatesLang = async (templatesLangInput: TemplatesLangUpdateInputItem[]) => {
  const {
    id, status, messages, err,
  } = await Update<TemplatesLangUpdateInputItem>({
    name: 'TemplatesLang',
    input: templatesLangInput,
    type: 'TemplatesLangUpdateInput',
  });

  if (err === '') {
    return id;
  }
  return messages;
};

export async function duplicateTemplate(id_shop: number, id_template: number, origin_call: null|string = null, id_campaign: null|number = null, name: null|string = null) {
  const templateDuplicateInput: TemplatesDuplicateInputItem = {
    id_shop,
    id_template,
  };

  if (origin_call !== null) {
    templateDuplicateInput.origin_call = origin_call;
    templateDuplicateInput.id_campaign = id_campaign;
  }

  if (name !== null) {
    templateDuplicateInput.label = name;
  }

  const {
    id, messages, err,
  } = await CustomMutation<TemplatesDuplicateInputItem>({
    name: 'DuplicateTemplate',
    input: templateDuplicateInput,
    type: 'TemplatesDuplicateInput',
  });

  if (err === '') {
    return {
      success: true,
      id,
    };
  }

  return {
    success: false,
    messages,
  };
}

export async function replaceRedirectionTemplate(id_shop: number, id_templates_parent: number[]|string[], old_id_template: number, new_id_template: number) {
  const replaceRedirectionTemplateInput: ReplaceRedirectionTemplateInputItem = {
    id_shop,
    id_templates_parent: JSON.stringify(id_templates_parent),
    old_id_template,
    new_id_template,
  };

  await CustomMutation<ReplaceRedirectionTemplateInputItem>({
    name: 'ReplaceRedirectionTemplate',
    input: replaceRedirectionTemplateInput,
    type: 'ReplaceRedirectionTemplateInput',
  });
}
