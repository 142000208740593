import {
  ComponentData, ComponentMetadata, ErrorConfigForm, Period,
} from '@/types/automated-scenarios';
import { i18n } from '@/i18n';
// eslint-disable-next-line import/no-cycle
import {
  AutomatedScenarioState as state,
  getOperatorChildByOutput,
  isRootOfTree,
} from '@/composables/AutomatedScenarios';
import { computed } from 'vue';
import {
  required, integer, between,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/no-cycle
import { includesValuesValidator } from '@/helpers/CustomValidator';
import { TypeCampaignEnum } from '@/types';

export interface DeclencheurSitePassTimeData {
  multiplebox_global_session: string;
  time_unit_select: Period;
}

let translation: any;
(async () => {
  translation = await i18n;
})();

const DeclencheurSitePassTimeMetadata: ComponentMetadata<DeclencheurSitePassTimeData> = {
  Meta: {
    kind: 'declencheur',
    id: 'boxsitepasstime',
    label: 'automatedScenarios.triggers.passTime.shortLabel',
    icon: 'fa-clock',
    hasConfiguration: true,
    incompatibilities: {
      automation: {
        next_operators: [
          'boxsitepasstime', 'boxperiodiclaunching', 'boxstatuscommand',
          'boxsendemail', 'boxsendsms', 'boxsendfbmessenger',
          'boxsendpushnotification',
        ],
      },
    },
    component: 'DeclencheurSitePassTime',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
  },
  Create({
    multiplebox_global_session,
    time_unit_select: { value, unit },
  }: DeclencheurSitePassTimeData = {
    multiplebox_global_session: 'session',
    time_unit_select: {
      value: 10,
      unit: 'MINUTE',
    },
  }): ComponentData<DeclencheurSitePassTimeData> {
    return {
      id: 'boxsitepasstime',
      form_name: 'boxsitepasstime',
      logo_box: 'fa-clock-o',
      multiplebox_global_session,
      time_unit_select: {
        value,
        unit,
      },
    };
  },
  async Validate(data: DeclencheurSitePassTimeData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      time_unit_select: {
        value: {
          required,
          integer,
          between: between(1, 1000),
        },
        unit: {
          required,
          includesValuesValidator: includesValuesValidator(['SECOND', 'MINUTE', 'HOUR']),
        },
      },
      multiplebox_global_session: {
        required,
        includesValuesValidator: includesValuesValidator(['global', 'session']),
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
  Outputs(data: DeclencheurSitePassTimeData): number {
    return 1;
  },
  CanBeDeleted(operatorId: string): boolean|string {
    const isRoot = isRootOfTree(operatorId);

    if (isRoot) {
      const childOperator = getOperatorChildByOutput(operatorId, 'output_1');
      if (childOperator !== null && typeof (state.scenario.data.operators[childOperator]) !== undefined
        && state.scenario.data.operators[childOperator].properties.class !== 'declencheur') {
        return 'automatedScenarios.cant_remove_operator';
      }
    }

    return true;
  },
  Label(data: DeclencheurSitePassTimeData): string {
    const unit = translation.global.t(`automatedScenarios.fields.durations.${data.time_unit_select.unit.toLowerCase()}${data.time_unit_select.value > 1 ? 's' : ''}`);
    return translation.global.t('automatedScenarios.triggers.passTime.fullLabel', { duration: `${data.time_unit_select.value} ${unit}` });
  },
};

export default DeclencheurSitePassTimeMetadata;
