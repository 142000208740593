
import {
  computed,
  defineComponent, onBeforeMount, ref, Ref,
} from 'vue';
import { useI18n } from 'vue-i18n';

import {
  getIsAuthenticated, Me, refreshTokenLogin, setChangingShop, Shop, SwitchActiveShop, UserState,
} from '@/composables/User';
import { useRouter } from 'vue-router';
import Card from 'primevue/card';
import { PrimvueMenuModel } from '@/types';
import { useStore } from '@/store';
import InputText from 'primevue/inputtext';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import ScrollPanel from 'primevue/scrollpanel';
import { showToastError } from '@/helpers';

export default defineComponent({
  name: 'ChooseShop',

  components: {
    ScrollPanel,
    SpmPanelMenu,
    Card,
    InputText,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();

    const shopToMenu = (shop: Shop) => {
      const label = shop.name ?? shop.url;
      const legend = shop.url;
      const logo = `https://media.shopimind.io/clients/logos/${shop.logo}`;
      const command = async () => {
        store.commit('general/showTheSpinner');

        try {
          const res: any = await refreshTokenLogin(shop.id);
          if (res && res.success) {
            const { idShop } = res;
            await Me(true, Number(idShop));
            store.commit('liveEditor/resetWebsiteContents');
            await router.push({ name: 'home' });
          } else {
            await showToastError('GENERIC_ERROR');
          }
        } catch (e) {
          await showToastError('GENERIC_ERROR');
        }

        store.commit('general/hideTheSpinner');
      };
      return {
        label,
        legend,
        logo,
        command,
      };
    };

    const searchValue = ref('');
    const items: Ref<PrimvueMenuModel[]> = computed(() => {
      const regex = new RegExp(searchValue.value, 'i');
      const shops = UserState.user.shops
        .filter((s: Shop) => (regex.test(s.name) || regex.test(s.id.toString()) || regex.test(s.url)))
        .map(shopToMenu);
      return [
        ...shops,
      ].slice(-10);
    });
    const nbTotalShops = computed(() => UserState.user.shops.length);

    onBeforeMount(async () => {
      const isAuthenticated = getIsAuthenticated();
      if (!isAuthenticated) {
        await Me(false);
      }

      return true;
    });

    return {
      t,
      nbTotalShops,
      items,
      searchValue,
    };
  },
});
