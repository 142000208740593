
import {
  defineComponent,
  PropType,
  Ref,
  ref,
  computed,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Tooltip from 'primevue/tooltip';

export default defineComponent({
  name: 'SubPanelConfigContent',

  directives: {
    tooltip: Tooltip,
  },

  props: {
    panels: {
      type: Array as PropType<object[]>,
      required: true,
    },

    collapsed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props) {
    const { t } = useI18n();
    // Make all panels collapsed on init if property is defined
    const inactivePanelsIndex: Ref<number[]> = ref(props.collapsed ? [...props.panels.keys()] : []);

    const showedPanels = computed(() => props.panels.filter((panel: any) => panel.show));

    const togglePanel = (index: number) => {
      if (inactivePanelsIndex.value.includes(index)) {
        inactivePanelsIndex.value = inactivePanelsIndex.value.filter((i: number) => i !== index);
      } else {
        inactivePanelsIndex.value.push(index);
      }
    };

    return {
      t,
      inactivePanelsIndex,
      showedPanels,

      togglePanel,
    };
  },
});
