import { ComponentNameEnum, DropdownOption } from '@/types';

const getInputVariablesFieldConfig = (defaultValue = '', variablesList: DropdownOption[], displayEmojis = false, displayVariables = false) => ({
  componentName: ComponentNameEnum.INPUT_VARIABLES,
  options: {
    defaultValue,
    variablesList,
    displayEmojis,
    displayVariables,
  },
});

export default getInputVariablesFieldConfig;
