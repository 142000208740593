
import Dialog from 'primevue/dialog';
import {
  defineComponent,
  ref, SetupContext,
} from 'vue';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { maxLength, minLength, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default defineComponent({
  name: 'DuplicateModal',

  components: {
    Button,
    Dialog,
    FieldErrors,
    InputText,
  },

  props: {
    translation: {
      type: Function,
      required: true,
    },

    listName: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      required: false,
      default: '',
    },

    message: {
      type: String,
      required: true,
    },

  },

  emits: ['on-close-modal', 'on-execute'],

  setup(props, { emit }: SetupContext) {
    const displayModal = ref(true);

    const name = ref(props.listName);
    const duplicationValidationErrors = ref({});
    const duplicationFieldErrorsKey = ref(0);

    const updateDialogVisibility = (event: any) => {
      displayModal.value = false;
      emit('on-close-modal');
    };

    const validateDuplicate = async () => {
      let success;
      const rules = {
        name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(300),
        },
      };
      const v$ = useVuelidate(rules, { name: name.value });

      if (typeof v$ !== 'undefined') {
        success = await v$.value.$validate();
      }

      return {
        success,
        v$,
      };
    };

    const execute = async () => {
      const {
        success,
        v$,
      } = await validateDuplicate();
      if (!success && typeof v$ !== 'undefined') {
        duplicationValidationErrors.value = v$;
        duplicationFieldErrorsKey.value += 1;
      } else {
        emit('on-execute', name.value);
      }
    };

    return {
      displayModal,
      updateDialogVisibility,
      name,
      execute,
      duplicationValidationErrors,
      duplicationFieldErrorsKey,
    };
  },
});
