// eslint-disable-next-line import/no-cycle
import {
  ComponentData, ComponentMetadata, ErrorConfigForm, Period, PickerData,
} from '@/types/automated-scenarios';
// eslint-disable-next-line import/no-cycle
import { decreaseNumberOfBranchesOfAnOperator } from '@/composables/AutomatedScenarios';
import { computed } from 'vue';
import {
  integer, maxLength, minLength, required, requiredIf,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/no-cycle
import {
  includesValuesValidator,
  minNavigationCriterionValidator,
} from '@/helpers/CustomValidator';
// eslint-disable-next-line import/no-cycle
import { TypeCampaignEnum } from '@/types';

export interface ProductData {
  litteral_title?: {
    minimum_product_nbr?: string;
    product_picker_product?: string;
  };
  minimum_product_nbr?: number;
  product_picker_product?: {
    selection: PickerData;
  };
  operatorElementNav?: string;
}

export interface CategoryData {
  litteral_title?: {
    minimum_category_nbr?: string;
    product_picker_category?: string;
  };
  minimum_category_nbr?: number;
  product_picker_category?: {
    selection: PickerData;
  };
  operatorElementNav?: string;
}

export interface ManufacturerData {
  litteral_title?: {
    minimum_manufacturer_nbr?: string;
    product_picker_manufacturer?: string;
  };
  minimum_manufacturer_nbr?: number;
  product_picker_manufacturer?: {
    selection: PickerData;
  };
  operatorElementNav?: string;
}

export interface Url {
  url: string;
}
export interface UrlData {
  lists?: Url[];
  operator?: string;
}

export interface FiltreBoxNavigationTriggerFilters {
  products: ProductData;
  categories: CategoryData;
  manufacturers: ManufacturerData;
  urls: UrlData;
}

export interface FiltreBoxNavigationTriggerData {
  custom_title: string;
  custom_description: string;
  outputs_number: number;
  initial_number_outputs?: null|number; // for updating outputs
  products: ProductData;
  categories: CategoryData;
  manufacturers: ManufacturerData;
  urls: UrlData;
  operatorBoxNav: string;
  period_concerned: string;
  period_time: Period;
}

const FiltreBoxNavigationTriggerMetadata: ComponentMetadata<FiltreBoxNavigationTriggerData> = {
  Meta: {
    kind: 'filtre',
    id: 'boxnavigationtrigger',
    label: 'automatedScenarios.filters.navigationTrigger',
    icon: 'fa-mouse-pointer',
    hasConfiguration: true,
    persistent: [''],
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxperiodiclaunching', 'boxvisitpage',
        ],
      },
      bulk: {
        next_operators: [
          'boxchoosebulklist',
        ],
      },
    },
    component: 'FiltreBoxNavigationTrigger',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
  },
  Create(data: FiltreBoxNavigationTriggerData = {
    custom_title: '',
    custom_description: '',
    products: {},
    categories: {},
    manufacturers: {},
    urls: {},
    operatorBoxNav: 'and',
    period_concerned: '0',
    outputs_number: 1,
    period_time: {
      value: 1,
      unit: 'MINUTE',
    },
  }): ComponentData<FiltreBoxNavigationTriggerData> {
    return {
      id: 'boxnavigationtrigger',
      form_name: 'boxnavigationtrigger',
      logo_box: 'fa-mouse-pointer',
      ...data,
    };
  },
  async Validate(data: FiltreBoxNavigationTriggerData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      custom_title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(45),
      },
      outputs_number: {
        required,
        includesValuesValidator: includesValuesValidator([1, 2]),
      },
      period_concerned: {
        required,
        includesValuesValidator: includesValuesValidator(['0', '1']),
      },
      period_time: {
        value: {
          required: requiredIf(() => data.period_concerned === '1'),
          integer,
        },
        unit: {
          required: requiredIf(() => data.period_concerned === '1'),
          includesValuesValidator: includesValuesValidator(['SECOND', 'MINUTE', 'HOUR', 'DAY']),
        },
      },
      operatorBoxNav: {
        required,
      },
      products: {
        minNavigationCriterionValidator: minNavigationCriterionValidator('filter', data.products, data.categories, data.manufacturers, data.urls),
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
  BeforeInsertData(data: FiltreBoxNavigationTriggerData): FiltreBoxNavigationTriggerData {
    const dataToReturn: FiltreBoxNavigationTriggerData = data;
    const { initial_number_outputs } = dataToReturn;

    if (typeof dataToReturn.initial_number_outputs !== 'undefined') {
      delete dataToReturn.initial_number_outputs;
    }

    if (initial_number_outputs !== null && initial_number_outputs !== undefined
      && data.outputs_number < initial_number_outputs) {
      /* En cas de dimunition du nombre de branches, on supprime la branche "non" */
      decreaseNumberOfBranchesOfAnOperator(['output_2']);
    }

    return dataToReturn;
  },
  Outputs(data: FiltreBoxNavigationTriggerData): number {
    return data.outputs_number;
  },
  Label(data: FiltreBoxNavigationTriggerData): string {
    return data.custom_title;
  },
};

export default FiltreBoxNavigationTriggerMetadata;
