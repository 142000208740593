<template>
  <div class="mx-auto">
    <div class="flex flex-column p-4 justify-content-center align-items-center">
      <div class="w-6rem">
        <img
          src="@/assets/images/logo-sm-green.svg"
          alt="logo Icon"
        >
      </div>
      <h3 class="text-2xl font-semibold">
        {{ title }}
      </h3>
      <p class="text-sm">
        {{ subTitle }}
      </p>
    </div>
    <div class="flex">
      <div class="col-12">
        <div class="p-fluid field">
          <label
            v-tooltip="t('storeParameters.storeInformations.tooltips.name')"
            for="name"
          >
            {{ t('storeParameters.storeInformations.name') }} <i class="fas fa-question-circle" />
          </label>
          <InputText
            id="name"
            v-model="field.name"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="name"
          />
        </div>
        <div class="p-fluid field">
          <label for="shopCategory">{{ t('storeParameters.storeInformations.category') }}</label>
          <Dropdown
            id="shopCategory"
            v-model="field.shopCategory"
            :options="categories"
            option-label="label"
            option-value="code"
            :placeholder="t('storeParameters.storeInformations.category')"
          >
            <template #value="slotProps">
              <span v-if="slotProps.value">
                {{ t(categories.filter((category) => category.code === slotProps.value)[0].label) }}
              </span>
            </template>
            <template #option="slotProps">
              {{ t(slotProps.option.label) }}
            </template>
          </Dropdown>
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="shopCategory"
          />
        </div>
        <div class="p-fluid field">
          <label for="defaultLang">{{ t('storeParameters.storeInformations.language') }}</label>
          <Dropdown
            id="defaultLang"
            v-model="field.defaultLang"
            :options="shopLanguages"
            option-label="label"
            option-value="value"
          >
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <img
                  v-if="slotProps.value"
                  :src="`/images/flags/${slotProps.value}.png`"
                  alt="language-flag"
                >
                  &nbsp;
                {{ slotProps.value ? t(`languages.codes.${slotProps.value}`): 'undefined' }}
              </div>
            </template>
            <template #option="slotProps">
              <img
                v-if="slotProps.option"
                :src="`/images/flags/${slotProps.option.value}.png`"
                alt="language-flag"
              >
                &nbsp;
              {{ slotProps.option.label ? t(`languages.codes.${slotProps.option.label}`): '' }}
            </template>
          </Dropdown>
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="defaultLang"
          />
        </div>
        <div class="p-fluid field">
          <label for="currency">{{ t('storeParameters.storeInformations.currency') }}</label>
          <Dropdown
            id="currency"
            v-model="field.currency"
            :options="currencies"
            option-label="label"
            option-value="code"
            :placeholder="t('storeParameters.storeInformations.currency')"
            filter
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="currency"
          />
        </div>
        <div class="p-fluid field">
          <label for="privacyPolicyUrl">{{ t('storeParameters.rgpd.privacyPolicyUrl') }}</label>
          <LocalizedTextInput
            id="privacyPolicyUrl"
            v-model="field.privacyPolicyUrl"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="privacyPolicyUrl"
          />
        </div>
        <div class="p-fluid field">
          <label for="dataManagerEmail">{{ t('storeParameters.rgpd.dataManagerEmail') }}</label>
          <LocalizedTextInput
            id="dataManagerEmail"
            v-model="field.dataManagerEmail"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="dataManagerEmail"
          />
        </div>
      </div>
    </div>
    <div class="flex mt-3 p-2">
      <SpmButton
        :label="t('next')"
        :loading="isLoading"
        class-style="p-button p-button-primary w-full"
        @click="handleNext"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, onBeforeMount,
  ref,
} from 'vue';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Tooltip from 'primevue/tooltip';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import useVuelidate from '@vuelidate/core';
import { maxLength, minLength, required } from '@vuelidate/validators';
import { localizedTextInputValidator } from '@/helpers/CustomValidator';
import { useI18n } from 'vue-i18n';
import categories from '@/configs/shop-categories';
import currencies from '@/configs/currencies';
import fetchShopInformation, { updateShop } from '@/composables/shop/Shops';
import { Me, UserState } from '@/composables/User';
import { fetchShopConfigurations } from '@/composables/shop/MyShopParameters';
import updateShopsLang from '@/composables/shop/ShopsLang';
import { showToastError } from '@/helpers';
import { saveShopParamsOnRedis, SaveShopsConfiguration } from '@/composables/shop/ShopsConfiguration';
import { useStore } from '@/store';

interface StoreInfoState {
  name: string;
  shopCategory: any;
  currency: string;
  defaultLang: any;
  privacyPolicyUrl: any;
  dataManagerEmail: any;
}

export default defineComponent({
  name: 'ConfigureShop',

  components: {
    InputText,
    Dropdown,
    SpmButton,
    FieldErrors,
    LocalizedTextInput,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },

    subTitle: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['next'],

  setup(_, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const isLoading = ref(false);
    const formValidationErrors = ref({});
    const componentFieldErrorsKey = ref(0);

    const shopLanguages: any = ref([]);

    const field = ref<StoreInfoState>({
      name: '',
      shopCategory: null,
      currency: '',
      defaultLang: null,
      privacyPolicyUrl: {},
      dataManagerEmail: {},
    });

    const validateShopInfo = async (): Promise<ErrorConfigForm> => {
      const rules = {
        name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(25),
        },

        shopCategory: {
          required,
        },

        currency: {
          required,
        },

        defaultLang: {
          required,
        },

        privacyPolicyUrl: {
          localizedTextInputValidator: localizedTextInputValidator('url'),
        },

        dataManagerEmail: {
          localizedTextInputValidator: localizedTextInputValidator('email'),
        },
      };

      const v$ = useVuelidate(rules, field);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    const handleNext = async () => {
      isLoading.value = true;
      const validation = await validateShopInfo();
      if (!validation.success) {
        formValidationErrors.value = validation.validate;
        componentFieldErrorsKey.value += 1;
        isLoading.value = false;
      } else {
        updateShop([{
          id_shop: UserState.activeShop?.id ?? 0,
          name: field.value.name,
          id_category_shop: field.value.shopCategory,
          currency: field.value.currency,
        }])
          .then(() => {
            updateShopsLang(
              shopLanguages.value.map((item: any) => ({
                id_shop: UserState.activeShop?.id ?? 0,
                lang: item.value,
                default: item.value === field.value.defaultLang,
              })),
            ).then(() => {
              const configToSave = Object.keys(field.value.privacyPolicyUrl).map((key) => ({
                key: 'shopRequiredDataRgpd_privacyPolicyUrl',
                lang: key,
                value: field.value.privacyPolicyUrl[key],
              })).concat(Object.keys(field.value.dataManagerEmail).map((key) => ({
                key: 'shopRequiredDataRgpd_dataManagerEmail',
                lang: key,
                value: field.value.dataManagerEmail[key],
              })));

              SaveShopsConfiguration({
                id_shop: UserState.activeShop?.id ?? 0,
                configs: configToSave,
              }).then(async () => {
                await saveShopParamsOnRedis(UserState.activeShop?.id ?? 0);
                isLoading.value = false;
                emit('next');
              }).catch(async () => {
                isLoading.value = false;
                await showToastError('GENERIC_ERROR');
              });
            })
              .catch(async () => {
                isLoading.value = false;
                await showToastError('GENERIC_ERROR');
              });
          })
          .catch(async (err: any) => {
            isLoading.value = false;
            await showToastError('GENERIC_ERROR');
          });
      }
    };

    onBeforeMount(async () => {
      store.commit('general/showTheSpinner');
      await Me(false);
      const shopsInfosData = await fetchShopInformation({ shopId: UserState.activeShop?.id ?? 0 });
      if (shopsInfosData) {
        shopLanguages.value = shopsInfosData.langs.filter((item) => !item.default).map((item) => ({
          label: item.lang,
          value: item.lang,
        }));
        shopLanguages.value.unshift({
          label: shopsInfosData.defaultLang ?? 'fr',
          value: shopsInfosData.defaultLang ?? 'fr',
        });

        const shopsRgpdConfiguration = await fetchShopConfigurations({ shopId: UserState.activeShop?.id ?? 0 }, 'shopRequiredDataRgpd');
        let privacyPolicyUrl = shopsRgpdConfiguration?.filter((item) => item.key === 'shopRequiredDataRgpd_privacyPolicyUrl');
        privacyPolicyUrl = privacyPolicyUrl?.length ? privacyPolicyUrl.reduce((acc: any, current: any) => {
          acc[current.lang] = current.value;
          return acc;
        }, {}) : '';
        let dataManagerEmail = shopsRgpdConfiguration?.filter((item) => item.key === 'shopRequiredDataRgpd_dataManagerEmail');
        dataManagerEmail = dataManagerEmail?.length ? dataManagerEmail.reduce((acc: any, current: any) => {
          acc[current.lang] = current.value;
          return acc;
        }, {}) : '';

        field.value = {
          name: shopsInfosData.name,
          shopCategory: shopsInfosData.idCategoryShop,
          currency: shopsInfosData.currency,
          defaultLang: shopsInfosData.defaultLang ?? 'fr',
          privacyPolicyUrl,
          dataManagerEmail,
        };
      }
      store.commit('general/hideTheSpinner');
    });

    return {
      t,
      formValidationErrors,
      componentFieldErrorsKey,
      categories,
      shopLanguages,
      currencies,
      field,
      handleNext,
      isLoading,
    };
  },

});
</script>
