
import {
  defineComponent,
  PropType, ref, watch,
} from 'vue';
import WidgetTabsMetadata, { WidgetTabsData } from '@/components/stats-builder/metadata/WidgetTabsMetadata';
import { useI18n } from 'vue-i18n';
import MenuItemsManage from '@/components/stats-builder/fields/MenuItemsManage.vue';

export default defineComponent({
  name: 'StatsWidgetTabsConfig',
  components: {
    MenuItemsManage,
  },

  props: {
    modelValue: {
      type: Object as PropType<WidgetTabsData>,
      required: true,
      default() {
        return WidgetTabsMetadata.Create();
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  setup(props: { modelValue: WidgetTabsData; formValidation: any }) {
    const { t } = useI18n();
    const list = ref(props.modelValue.tabs);
    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    return {
      t,
      list,
    };
  },
});
