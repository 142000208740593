import {
  List,
  Request,
  Update,
} from '@/composables/GraphQL';
import {
  Maybe,
  OperatorType,
  ShopsCredits,
  ShopsCreditsUpdateInputItem,
} from '@/types/generated-types/graphql';
import { showToastError } from '@/helpers';

/**
 * Deduct <amount> credits of <type> if shop has enough credits and returns true if deduction is possible otherwise returns false
 * @param idShop
 * @param type
 * @param amount
 */
export async function deductCredits(idShop: number, type: string, amount: number): Promise<Maybe<boolean>> {
  if (idShop === 0) {
    return null;
  }

  // We get shop's credits
  // We check if the shop has enough credits
  // If true, we deduct the <amount> of credits needed
  const query = 'query ($idShop: Int!, $type: String!, $amount: Float!) { DeductShopsCredits(id_shop: $idShop, type: $type, amount: $amount) }';

  // GraphQL variables
  const variables = {
    idShop,
    type,
    amount,
  };

  const { data, err } = await Request<boolean>({
    name: 'DeductShopsCredits',
    query,
    variables,
  });

  if (err) {
    showToastError(err);
    return null;
  }

  return data;
}

/**
 * Get shop credits by <type>
 * @param idShop
 * @param type
 * @param onlyAmount
 */
export async function getShopsCredits(idShop: number, type: string, onlyAmount = false): Promise<Maybe<number | ShopsCredits>> {
  if (idShop === 0) {
    return null;
  }

  const { items, err } = await List<ShopsCredits>({
    name: 'ShopsCredits',
    settings: {
      filter: [
        {
          field: 'id_shop',
          operator: OperatorType.Equals,
          value: idShop,
        },
        {
          field: 'type',
          operator: OperatorType.Equals,
          value: type,
        },
      ],
      order: [
        { field: 'date', type: 'DESC' },
      ],
      limit: 1,
      offset: 0,
    },
    fields: ['id_shop', 'date', 'type', 'amount', 'renew_data', 'suspend'],
  });

  if (err) {
    await showToastError(err);
    return null;
  }

  if (!items || items.length === 0) {
    return onlyAmount ? 0 : null;
  }

  return onlyAmount ? items[0].amount : items[0];
}

export async function toggleSmsCreditsRenewal(idShop: number, shopsCredits: Record<string, any>) {
  const autoRenew = shopsCredits.autoRenew ? 0 : 1;
  // eslint-disable-next-line no-param-reassign
  shopsCredits.renewData.autoRenew = autoRenew.toString();
  const shopsCreditsUpdateInput: ShopsCreditsUpdateInputItem[] = [
    {
      id_shop: idShop,
      date: shopsCredits.date,
      type: shopsCredits.type,
      renew_data: JSON.stringify(shopsCredits.renewData),
    },
  ];
  await Update<ShopsCreditsUpdateInputItem>({
    name: 'ShopsCredits',
    input: shopsCreditsUpdateInput,
    type: 'ShopsCreditsUpdateInput',
  });
}
