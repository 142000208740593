
import {
  defineComponent, PropType, Ref, ref,
} from 'vue';
import { useI18n } from 'vue-i18n';

import { FieldConfig, ParserFieldObject, Property } from '@/types';
import BaseTextarea from '@/components/fields/BaseTextarea.vue';
import { calculateSmsLength } from '@/components/template-builder/setters';

export default defineComponent({
  name: 'TextareaVariables',
  components: {
    BaseTextarea,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const properties: Ref<Array<Property>> = ref(props.parserValues.properties ?? []);
    const inputValue: Ref<string> = ref(properties.value[0].value ?? props.configs.options.defaultValue);

    const handleValueChange = () => {
      const updatedProperties: Array<Property> = properties.value.map((prop: Property) => {
        const updatedProperty = prop;
        updatedProperty.value = inputValue.value;
        return updatedProperty;
      });
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: updatedProperties,
      });
    };

    return {
      t,
      inputValue,
      handleValueChange,
    };
  },

  computed: {
    charactersCount(): string {
      if (this.$props.configs.options?.countCharactersFor === 'sms') {
        const { returnMessage } = calculateSmsLength(this.inputValue);
        return returnMessage;
      }

      return '';
    },

    maxLengthTextarea(): number {
      if (this.$props.configs.options?.countCharactersFor === 'sms') {
        const { nbMessages, nbCharactersMax } = calculateSmsLength(this.inputValue);
        return nbMessages === 5 ? nbCharactersMax : -1;
      }

      return -1;
    },
  },
});
