import {
  WidgetTypeConfig,
  ConfigurationPanelStructureItemEnum,
  ComponentNameEnum,
  FacebookMessengerSkins,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import { getAttributeByName, getClassByName } from '@/components/template-builder/getters';
// eslint-disable-next-line import/no-cycle
import {
  setAttribute,
  setFacebookMessengerSkin, setMediaDisplay,
} from '@/components/template-builder/setters';
import getDropdownFieldConfig from '@/components/template-builder/config/fields/dropdown-config';
import mediaDisplayFieldConfig from '@/components/template-builder/config/fields/media-display-config';

const widgetFacebookMessengerConfig: WidgetTypeConfig = {
  tabPanel: {
    label: 'templateBuilder.widgetsHeaders.facebookMessenger',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.widgetConfiguration',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.informations.facebookMessengerWidgetInformationMessage',
            selector: '',
            properties: [],
            componentName: ComponentNameEnum.INFORMATION,
            options: {
              severity: 'warn',
            },
          },
          {
            label: 'templateBuilder.fields.facebookMessengerTheme.theme',
            selector: '{SELECTOR} .fb-messenger-checkbox',
            properties: [
              { name: 'skin', getter: getAttributeByName, setters: [setAttribute, setFacebookMessengerSkin] },
            ],
            ...getDropdownFieldConfig(Object.values(FacebookMessengerSkins)
              .map((skin) => ({ value: skin, label: `templateBuilder.fields.facebookMessengerTheme.options.${skin}` })), FacebookMessengerSkins.LIGHT),
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.media',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.mediaDisplay',
            selector: '{SELECTOR}',
            properties: [{ name: 'show-for-', getter: getClassByName, setters: [setMediaDisplay] }],
            ...mediaDisplayFieldConfig,
          },
        ],
      },
    ],
  },
};

export default widgetFacebookMessengerConfig;
