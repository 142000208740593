<template>
  <div>
    <label
      class="block my-3"
      data-test-id="sharing-type-label"
    >
      {{ t(configs.label) }}
    </label>
    <Dropdown
      v-model="sharingType"
      data-test-id="sharing-type-dropdown"
      class="col-12 p-0"
      :options="sharingTypeOptions"
      option-label="label"
      option-value="value"
    />

    <div v-if="sharingType === SharingTypeEnum.CUSTOM_URL">
      <InputVariables
        v-for="(field, index) in customUrlParserValues"
        :key="index"
        data-test-id="sharing-type-input"
        :configs="field"
        :parser-values="field"
        class="mb-3"
        @on-change-properties="handleChange"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, PropType, Ref, ref,
} from 'vue';
import Dropdown from 'primevue/dropdown';
import { useI18n } from 'vue-i18n';
import {
  DropdownOption,
  FieldConfig,
  SharingTypeEnum,
  ParserFieldObject,
  Property,
} from '@/types';
import InputVariables from '@/components/template-builder/fields/InputVariables.vue';

export default defineComponent({
  name: 'SharingType',

  components: {
    Dropdown,
    InputVariables,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const properties: Ref<Array<Property>> = ref(props.parserValues.properties);
    const sharingType = ref(properties.value[0].value ?? props.configs.options.defaultValue);
    const sharingTypeOptions: DropdownOption[] = Object.values(SharingTypeEnum)
      .map((key: string) => ({ value: key, label: t(`templateBuilder.fields.sharingTypeOptions.${key}`) }));

    // Custom parserValues for custom url and description
    const customUrlParserValues: Array<ParserFieldObject> = props.configs.options.customUrlConfig.map((config: any) => {
      // eslint-disable-next-line no-param-reassign
      config.selector = props.parserValues.selector;
      const propertiesWithValues = config.properties.map((property: Property) => {
        // eslint-disable-next-line no-param-reassign
        property.value = property.getter(props.parserValues.selector, property);
        return property;
      });
      // eslint-disable-next-line no-param-reassign
      config.properties = propertiesWithValues;
      return config;
    });

    const handleChange = (update: {selector: string; properties: Array<Property>}) => {
      context.emit('on-change-properties', update);
    };

    return {
      t,
      sharingType,
      sharingTypeOptions,
      handleChange,
      SharingTypeEnum,
      customUrlParserValues,
    };
  },
});
</script>
