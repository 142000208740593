// eslint-disable-next-line import/prefer-default-export
export const removeCharactersForSms = (value: string): string => {
  let newValue: string = value;

  newValue = newValue.replace(new RegExp('’', 'g'), "'");
  newValue = newValue.replace(new RegExp('…', 'g'), '...');
  newValue = newValue.replace(new RegExp('«', 'g'), '"');
  newValue = newValue.replace(new RegExp('»', 'g'), '"');
  newValue = newValue.replace(new RegExp('[ÀÁÂÃ]', 'g'), 'A');
  newValue = newValue.replace(new RegExp('[ÈÊË]', 'g'), 'E');
  newValue = newValue.replace(new RegExp('[ÌÍÎÏ]', 'g'), 'I');
  newValue = newValue.replace(new RegExp('[ÐÒÓÔÕ]', 'g'), 'O');
  newValue = newValue.replace(new RegExp('[ÙÚÛ]', 'g'), 'U');
  newValue = newValue.replace(new RegExp('[ÝŸ]', 'g'), 'Y');
  newValue = newValue.replace(new RegExp('[áâã]', 'g'), 'a');
  newValue = newValue.replace(new RegExp('[êë]', 'g'), 'e');
  newValue = newValue.replace(new RegExp('[íîï]', 'g'), 'i');
  newValue = newValue.replace(new RegExp('[ðóôõ]', 'g'), 'o');
  newValue = newValue.replace(new RegExp('[úûµ]', 'g'), 'u');
  newValue = newValue.replace(new RegExp('[ýÿ]', 'g'), 'y');
  newValue = newValue.replace(new RegExp('ç', 'g'), 'c');
  // eslint-disable-next-line max-len
  newValue = newValue.replace(new RegExp('[^ \\{\\}\\|\\^\\€\\[\\]\\\\\\~@Δ0¡P¿p£_!1AQaq$Φ"2BRbr¥Γ#3CScsèΛ¤4DTdtéΩ%5EUeuùΠ&6FVfvìΨ\'7GWgwòΣ(8HXhxÇΘ)9IYiyΞ*:JZjz\\r\\nØ+;KÄkäøÆ,LÖlö=MÑmñÅß.>NÜnüåÉ/?O§oà<-]*', 'g'), '');

  return newValue;
};
