// eslint-disable-next-line import/no-cycle
import {
  ComponentData, ComponentMetadata, ErrorConfigForm, PickerData,
} from '@/types/automated-scenarios';
import { i18n } from '@/i18n';
// eslint-disable-next-line import/no-cycle
import {
  getOperatorChildByOutput,
  isRootOfTree,
  AutomatedScenarioState as state,
} from '@/composables/AutomatedScenarios';
import { computed } from 'vue';
import { maxLength, minLength, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/no-cycle
import { minNavigationCriterionValidator } from '@/helpers/CustomValidator';
// eslint-disable-next-line import/no-cycle
import { TypeCampaignEnum } from '@/types';

export interface ProductData {
  litteral_title?: {
    all?: string;
    product_picker_product?: string;
  };
  all?: number; // 0 = Une selection de produits. 1 = N'importe quel produit
  product_picker_product?: {
    selection: PickerData;
  };
}

export interface CategoryData {
  litteral_title?: {
    all?: string;
    product_picker_category?: string;
  };
  all?: number; // 0 = Une selection de categories. 1 = N'importe quel categorie
  product_picker_category?: {
    selection: PickerData;
  };
}

export interface ManufacturerData {
  litteral_title?: {
    all?: string;
    product_picker_manufacturer?: string;
  };
  all?: number;
  product_picker_manufacturer?: {
    selection: PickerData;
  };
}

export interface Url {
  url: string;
}
export interface UrlData {
  included?: Url[];
  excluded?: Url[];
}

export interface DeclencheurVisitPageFilters {
  products: ProductData;
  categories: CategoryData;
  manufacturers: ManufacturerData;
  urls: UrlData;
}

export interface DeclencheurVisitPageData {
  custom_title: string;
  custom_description: string;
  trigger_location: number;
  products: ProductData;
  categories: CategoryData;
  manufacturers: ManufacturerData;
  urls: UrlData;
  operatorBoxNav: string;
  outputs_number: number;
}

let translation: any;
(async () => {
  translation = await i18n;
})();

const DeclencheurVisitPageMetadata: ComponentMetadata<DeclencheurVisitPageData> = {
  Meta: {
    kind: 'declencheur',
    id: 'boxvisitpage',
    label: 'automatedScenarios.triggers.boxVisitPage.label',
    icon: 'fa-eye',
    hasConfiguration: true,
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxperiodiclaunching', 'boxstatuscommand',
          'registeronsite', 'subscribetonewsletter', 'boxsendemail',
          'boxsendsms', 'boxsendfbmessenger', 'boxsendpushnotification',
          'openemail', 'clicklink',
        ],
      },
    },
    component: 'DeclencheurVisitPage',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
  },
  Create(data: DeclencheurVisitPageData = {
    custom_title: translation.global.t('automatedScenarios.triggers.boxVisitPage.label'),
    custom_description: '',
    trigger_location: 1, // 1 = sur tout le site. 0 = sur des pages spécifiques
    products: {},
    categories: {},
    manufacturers: {},
    urls: {},
    operatorBoxNav: 'or',
    outputs_number: 1,
  }): ComponentData<DeclencheurVisitPageData> {
    return {
      id: 'boxvisitpage',
      form_name: 'boxvisitpage',
      logo_box: 'fa-eye',
      ...data,
    };
  },
  async Validate(data: DeclencheurVisitPageData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      custom_title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(45),
      },
      trigger_location: {
        required,
        minNavigationCriterionValidator: minNavigationCriterionValidator('trigger', data.products, data.categories, data.manufacturers, data.urls),
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
  Outputs(data: DeclencheurVisitPageData): number {
    return data.outputs_number;
  },
  CanBeDeleted(operatorId: string): boolean|string {
    const isRoot = isRootOfTree(operatorId);

    if (isRoot) {
      const childOperator = getOperatorChildByOutput(operatorId, 'output_1');
      if (childOperator !== null && typeof (state.scenario.data.operators[childOperator]) !== undefined
        && state.scenario.data.operators[childOperator].properties.class !== 'declencheur') {
        return 'automatedScenarios.cant_remove_operator';
      }
    }

    return true;
  },
  Label(data: DeclencheurVisitPageData): string {
    return data.custom_title;
  },
};

export default DeclencheurVisitPageMetadata;
