import { FiltreBoxNavigationTriggerFilters } from '@/components/automated-scenarios/metadata/filtres/FiltreBoxNavigationTriggerMetadata';
import { i18n } from '@/i18n';
import { ErrorConfigForm, FilterField, FilterSegment } from '@/types/automated-scenarios';

import {
  OperatorType, ShopsManufacturers, ShopsProducts, ShopsProductsCategoriesList,
} from '@/types/generated-types/graphql';
import { TypeCampaignEnum } from '@/types';
import { computed } from 'vue';
import { between, required, url } from '@vuelidate/validators';
import { itemsPickerValidator } from '@/helpers/CustomValidator';
import useVuelidate from '@vuelidate/core';

let translation: any;
(async () => {
  translation = await i18n;
})();

export default () => ([
  {
    id: 'products',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'products', label: translation.global.t('automatedScenarios.fields.segments.products') },
    fields: [
      {
        id: 'minimum_product_nbr',
        component: 'InputNumber',
        data: 1,
        props: {
          title: 'automatedScenarios.fields.minimumNumberOfProducts',
          min: 1,
          max: 365,
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.minimumNumberOfProducts'),
      },
      {
        id: 'operatorElementNav',
        component: 'ButtonGroup',
        data: 'and',
        props: {
          options: [
            { value: 'and', label: translation.global.t('automatedScenarios.fields.logicalOperators.and') },
            { value: 'or', label: translation.global.t('automatedScenarios.fields.logicalOperators.or') },
          ],
        },
        litteral_title: '',
      },
      {
        id: 'product_picker_product',
        component: 'ItemList',
        data: { selection: { selected: [], operator: 'and' } },
        props: {
          config: {
            mainLabel: 'automatedScenarios.fields.productList',
            buttonLabel: 'automatedScenarios.fields.chooseProducts',
            operatorLabel: 'automatedScenarios.fields.visitorMustHaveSeen',
            name: 'ShopsProducts',
            fields: { id: 'id_shop_product', label: 'name', productId: 'id_product' },
            defaultFilters: [{ field: 'lang', value: 'fr', operator: OperatorType.Equals }, { field: 'active', value: true, operator: OperatorType.Equals }],
            format: (product: ShopsProducts) => ({
              id: product.id_product, spmId: product.id_shop_product, name: product.name, combinations: {},
            }),
          },
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.numberOfSelectedProducts'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        minimum_product_nbr: {
          required,
          between: between(1, 365),
        },
        operatorElementNav: {
          required,
        },
        product_picker_product: {
          itemsPickerValidator: itemsPickerValidator(false),
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
  {
    id: 'categories',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'categories', label: translation.global.t('automatedScenarios.fields.segments.categories') },
    fields: [
      {
        id: 'minimum_category_nbr',
        component: 'InputNumber',
        data: 1,
        props: {
          title: 'automatedScenarios.fields.minimumNumberOfCategories',
          min: 1,
          max: 365,
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.minimumNumberOfCategories'),
      },
      {
        id: 'operatorElementNav',
        component: 'ButtonGroup',
        data: 'and',
        props: {
          options: [
            { value: 'and', label: translation.global.t('automatedScenarios.fields.logicalOperators.and') },
            { value: 'or', label: translation.global.t('automatedScenarios.fields.logicalOperators.or') },
          ],
        },
        litteral_title: '',
      },
      {
        id: 'product_picker_category',
        component: 'ItemList',
        data: { selection: { selected: [], operator: 'and' } },
        props: {
          config: {
            mainLabel: 'automatedScenarios.fields.categoryList',
            buttonLabel: 'automatedScenarios.fields.chooseCategories',
            operatorLabel: 'automatedScenarios.fields.visitorMustHaveSeen',
            name: 'ShopsProductsCategoriesList',
            fields: { id: 'id_shop_product_category_list', label: 'name', categoryId: 'id_category' },
            defaultFilters: [{ field: 'lang', value: 'fr', operator: OperatorType.Equals }],
            format: (category: ShopsProductsCategoriesList) => ({
              id: category.id_category, spmId: category.id_shop_product_category_list, name: category.name, combinations: {},
            }),
          },
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.numberOfSelectedCategories'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        minimum_category_nbr: {
          required,
          between: between(1, 365),
        },
        operatorElementNav: {
          required,
        },
        product_picker_category: {
          itemsPickerValidator: itemsPickerValidator(false),
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
  {
    id: 'manufacturers',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'manufacturers', label: translation.global.t('automatedScenarios.fields.segments.manufacturers') },
    fields: [
      {
        id: 'minimum_manufacturer_nbr',
        component: 'InputNumber',
        data: 1,
        props: {
          title: 'automatedScenarios.fields.minimumNumberOfManufacturers',
          min: 1,
          max: 365,
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.minimumNumberOfManufacturers'),
      },
      {
        id: 'operatorElementNav',
        component: 'ButtonGroup',
        data: 'and',
        props: {
          options: [
            { value: 'and', label: translation.global.t('automatedScenarios.fields.logicalOperators.and') },
            { value: 'or', label: translation.global.t('automatedScenarios.fields.logicalOperators.or') },
          ],
        },
        litteral_title: '',
      },
      {
        id: 'product_picker_manufacturer',
        component: 'ItemList',
        data: { selection: { selected: [], operator: 'and' } },
        props: {
          config: {
            mainLabel: 'automatedScenarios.fields.manufacturerList',
            buttonLabel: 'automatedScenarios.fields.chooseManufacturers',
            operatorLabel: 'automatedScenarios.fields.visitorMustHaveSeen',
            name: 'ShopsManufacturers',
            fields: { id: 'id_shop_manufacturer', label: 'name', manufacturerId: 'id_manufacturer' },
            defaultFilters: [],
            format: (manufacturer: ShopsManufacturers) => ({
              id: manufacturer.id_manufacturer, spmId: manufacturer.id_shop_manufacturer, name: manufacturer.name, combinations: {},
            }),
          },
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.numberOfSelectedManufacturers'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        minimum_manufacturer_nbr: {
          required,
          between: between(1, 365),
        },
        operatorElementNav: {
          required,
        },
        product_picker_manufacturer: {
          itemsPickerValidator: itemsPickerValidator(false),
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
  {
    id: 'urls',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'urls', label: translation.global.t('automatedScenarios.fields.segments.urls') },
    fields: [
      {
        id: 'lists',
        component: 'InputList',
        data: [],
        props: {
          validate: async (data: any): Promise<ErrorConfigForm> => {
            const rules = computed(() => ({
              value: { required, url },
            }));

            const v$ = useVuelidate(rules, data);
            const success = await v$.value.$validate();
            return {
              success,
              validate: v$,
            };
          },
          callback: (data: any) => data.replace(new RegExp('^http(s):'), ''),
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.numberOfUrls'),
        format: (entries: {url: string}[]) => (entries.map((entry: {url: string}) => entry.url)),
      },
      {
        id: 'operator',
        component: 'ButtonGroup',
        data: 'and',
        props: {
          title: 'automatedScenarios.fields.visitorMustHaveSeen',
          options: [
            { value: 'and', label: translation.global.t('automatedScenarios.fields.allUrls') },
            { value: 'or', label: translation.global.t('automatedScenarios.fields.atLeastOneUrl') },
          ],
        },
        show: (fields: FilterField[]) => (fields[0].data.length > 1), // field "lists" must have length > 1
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        lists: {
          required,
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
]) as FilterSegment<FiltreBoxNavigationTriggerFilters>[];
