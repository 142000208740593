
import Message from 'primevue/message';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'BaseInformation',

  components: {
    Message,
  },

  props: {
    message: {
      type: String,
      required: true,
    },

    severity: {
      type: String,
      required: false,
      default: 'info',
    },
  },

  setup() {
    const { t } = useI18n();

    return { t };
  },
});
