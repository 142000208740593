
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AdvisorNewLink',
  setup() {
    return {

    };
  },
});
