<template>
  <Button
    :title="button.title"
    class="p-button-sm p-button-plain"
    :class="isButtonSelected(button) ? 'p-button-raised' : 'p-button-outlined'"
    data-test-id="button"
    @click="handleClick(button)"
  >
    <i
      v-if="button.iconClass"
      class="far"
      :class="button.iconClass"
    />
    <img
      v-if="button.src"
      :src="button.src"
      class="spm_icon"
    >
  </Button>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Button from 'primevue/button';
import { ButtonFieldOption, Property } from '@/types';

export default defineComponent({
  name: 'BaseButton',
  components: { Button },

  props: {
    button: {
      type: Object as PropType<ButtonFieldOption>,
      required: true,
    },

    properties: {
      type: Object as PropType<Array<Property>>,
      required: true,
    },
  },

  emits: {
    'on-click': Object,
  },

  setup(props: {properties: Array<Property>}, context) {
    const isButtonSelected = (button: ButtonFieldOption): boolean => {
      if (props.properties && props.properties.length > 0) {
        const currentProperty = props.properties.filter((property: Property) => property.name === button.property)[0];
        if (currentProperty && currentProperty.value !== undefined) {
          if (button.multipleChoice) {
            return currentProperty.value.split(' ')?.includes(button.value);
          }
          return (currentProperty.value.toString() === button.value);
        }
      }
      return button.value === button.defaultValue;
    };

    const handleMultipleChoiceProperty = (button: ButtonFieldOption, currentProperty: Property): string => {
      const currentValue = currentProperty.value?.split(' ') || [];
      const index = currentValue.indexOf(button.value);
      if (isButtonSelected(button)) {
        currentValue.splice(index, 1);
      } else {
        currentValue.push(button.value);
      }
      return currentValue.join(' ');
    };

    const handleClick = (button: ButtonFieldOption) => {
      let value: string;
      const currentProperty = props.properties.filter((property: Property) => property.name === button.property)[0];
      if (button.multipleChoice) {
        value = handleMultipleChoiceProperty(button, currentProperty);
      } else {
        value = isButtonSelected(button) ? button.defaultValue : button.value;
      }
      currentProperty.value = value;
      context.emit('on-click', currentProperty);
    };

    return {
      isButtonSelected,
      handleClick,
    };
  },
});
</script>

<style scoped lang="scss">
.p-button {
  width: 39px;
  box-shadow: none !important;
  background: $white;
  color: $heather;

  > i, img {
    margin: auto;
  }
  &:nth-child(7n+1) {
    border-right: 1px solid !important;
  }
  &:enabled:focus,
  &:focus {
    border-color: $brand-color-primary;
  }

  &.p-button-raised {
    background-color: $brand-color-primary;
    border-color: $brand-color-primary;
    color: $white !important;
  }
  &.p-button-sm {
    font-size: 1rem;
    padding: 0.3125rem 0.5rem;
  }
  &.no-border {
    border: none !important;
  }
}
.spm_icon {
  min-width: 1.4rem;
  min-height: 1.01rem;
}
</style>
