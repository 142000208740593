
import {
  defineComponent,
  PropType, Ref, ref, SetupContext, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import { ShopsListsMin } from '@/types/automated-scenarios';
import { ShopsListsList } from '@/composables/shop/ShopsLists';
import { UserState } from '@/composables/User';
import ActionListMetadata, { ActionListData } from '@/components/automated-scenarios/metadata/actions/ActionListMetadata';
import { asInt } from '@/helpers';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import ButtonGroup from '../../fields/ButtonGroup.vue';
import EditAutocomplete from '../../fields/EditAutocomplete.vue';

export default defineComponent({
  name: 'ActionList',
  components: {
    FieldErrors,
    EditAutocomplete,
    ButtonGroup,
  },

  props: {
    modelValue: {
      type: Object as PropType<ActionListData>,
      required: true,
      default() {
        return ActionListMetadata.Create();
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: ActionListData; formValidation: any }, context: SetupContext) {
    const { t } = useI18n();
    const store = useStore();
    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    const selectedLists: any = ref(props.modelValue.id_list && props.modelValue.id_list.length > 0 && Object.keys(props.modelValue.id_list[0]).includes('id_shop_list')
      ? props.modelValue.id_list : Object.entries(props.modelValue.id_list).map(([id, name]) => ({
        id_shop_list: parseInt(id, 10),
        name,
      })));

    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
    const type: Ref<number> = ref(props.modelValue.multiplebox_type);

    const options = [
      { value: 0, label: t('automatedScenarios.actions.add') },
      { value: 1, label: t('automatedScenarios.actions.remove') },
    ];

    async function fetchLists(listFilter: string) {
      const result: ShopsListsMin[]| null = await ShopsListsList({ shopId: idShop.value, name: listFilter });
      if (result != null && result.length > 0) {
        return result;
      }
      return [{ id_shop_list: 0, id_shop: idShop.value, name: listFilter }];
    }

    const addToList = async (list: any) => {
      const newList: any[] = [];

      for (let i = 0; i < list.length; i++) {
        const item = list[i];

        if (item.id_shop_list === 0) {
          // eslint-disable-next-line no-await-in-loop
          const newItem = await store.dispatch('listImportEditor/saveNoImportAction', { idShop: idShop.value, listName: item.name, returnResult: true });
          if (newItem) {
            // eslint-disable-next-line no-param-reassign
            item.id_shop_list = asInt(newItem.id);
          }
        }

        newList.push(item);
      }

      return newList;
    };

    const update = () => {
      context.emit('update:modelValue', ActionListMetadata.Create({
        id_list: selectedLists.value,
        multiplebox_type: type.value,
      }));
    };

    watch([selectedLists, type], update);

    return {
      formValidationErrors,
      componentFieldErrorsKey,
      type,
      options,
      selectedLists,

      fetchLists,
      addToList,
    };
  },
});
