<template>
  <div class="field-wrapper">
    <label class="block mb-2">
      {{ t('statsEditor.widgets.widgetData.config.field.metric.label') }}
    </label>
    <CascadeSelect
      v-model="selectedMetric"
      :options="predefinedMetrics"
      option-label="childrenLabel"
      option-group-label="label"
      :option-group-children="['items', 'value']"
      :placeholder="t('statsEditor.widgets.widgetData.config.field.metric.placeholder')"
      class="w-full"
    >
      <template #option="{ option }">
        <span v-if="option.label">{{ option.label }}</span>
        <StatsWidgetDataConfigOption
          v-else-if="option.childrenLabel"
          :option="option"
        />
      </template>
    </CascadeSelect>
    <FieldErrors
      :key="componentFieldErrorsKey"
      :errors="formValidationErrors"
      field="widgetConfiguration.metric"
    />
  </div>

  <SubPanelConfigContent
    :panels="chartConfigPanel"
    :collapsed="true"
  >
    <template #chart_config>
      <div class="field-wrapper">
        <label class="block mb-2">
          {{ t('statsEditor.widgets.widgetData.config.field.label') }}
        </label>
        <LocalizedTextInput
          id="stats_widget_data_name"
          v-model="widgetName"
          type="text"
          :display-translate="true"
          style="width: 100%;"
          :default-languages="defaultLanguages"
        />
        <FieldErrors
          :key="componentFieldErrorsKey"
          :errors="formValidationErrors"
          field="widgetName"
        />
      </div>
      <div
        v-if="listOfMetricWidgetTypes.length > 1"
        class="field-wrapper mb-1"
      >
        <label class="block mb-2">
          {{ t('statsEditor.widgets.widgetData.config.field.widgetType.label') }}
        </label>
        <div class="choose-type-graph-container">
          <div class="grid list-type-graph">
            <div
              v-for="(item, i) in listOfMetricWidgetTypes"
              :key="i"
              class="col-4 type-graph-container"
              @click="selectMetricWidgetType(item)"
            >
              <div
                class="type-graph"
                :class="{ 'selected': item === selectedMetricWidgetType }"
              >
                <i :class="t(`statsEditor.widgets.widgetData.config.field.widgetType.types.${item}.icon`)" />
                <span>{{ t(`statsEditor.widgets.widgetData.config.field.widgetType.types.${item}.label`) }}</span>
              </div>
            </div>
          </div>
        </div>
        <FieldErrors
          :key="componentFieldErrorsKey"
          :errors="formValidationErrors"
          field="widgetConfiguration.widgetType"
        />
      </div>
      <div v-if="listOfChartCategories.length">
        <div
          v-if="listOfChartCategories.length > 1"
          class="field-wrapper"
        >
          <label class="block mb-2">
            {{ t(`statsEditor.widgets.widgetData.config.charts.categories.label${listOfChartCategoriesIsMultiple ? 'Multiple' : ''}`) }}
          </label>
          <MultiSelect
            v-model="selectedChartCategory"
            :options="listOfChartCategories"
            :show-toggle-all="false"
            option-label="name"
            :placeholder="t(`statsEditor.widgets.widgetData.config.charts.categories.placeholder${listOfChartCategoriesIsMultiple ? 'Multiple' : ''}`)"
            :max-selected-labels="3"
            :selection-limit="listOfChartCategoriesIsMultiple ? listOfChartCategories.length : 1"
            class="w-full"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="widgetConfiguration.charts"
          />
        </div>
        <SubPanelConfigContent
          v-if="chartSubConfigPanels.length"
          :panels="chartSubConfigPanels"
        >
          <template
            v-for="(item, i) in chartSubConfigPanels"
            :key="i"
            #[item.name]
          >
            <!-- Selection de type de chart par catégorie -->
            <div
              v-if="Object.keys(item.charts).length > 1"
              class="field-wrapper mb-1"
            >
              <label class="block mb-2">
                {{ t('statsEditor.widgets.widgetData.config.charts.type.label') }}
              </label>
              <div class="choose-type-graph-container">
                <div class="grid list-type-graph">
                  <div
                    v-for="(chartItem, j) in Object.keys(item.charts)"
                    :key="j"
                    class="col-4 type-graph-container"
                    @click="selectChartTypeByCategory(item.code, chartItem)"
                  >
                    <div
                      class="type-graph"
                      :class="{ 'selected': (selectedChartTypeByCat[item.code] && chartItem === selectedChartTypeByCat[item.code]) }"
                    >
                      <i :class="t(`statsEditor.widgets.widgetData.config.charts.type.lists.icons.${chartItem}`)" />
                      <span>{{ t(`statsEditor.widgets.widgetData.config.charts.type.lists.label.${chartItem}`) }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                :field="`widgetConfiguration.charts.${i}.type`"
              />
            </div>

            <!-- Selection de filtre "channel" par catégorie -->
            <div
              v-if="selectedChartTypeByCat[item.code] && item.charts[selectedChartTypeByCat[item.code]]
                && item.charts[selectedChartTypeByCat[item.code]].channel
                && item.charts[selectedChartTypeByCat[item.code]].channel.length"
              class="field-wrapper"
            >
              <label class="block mb-2">
                {{ t('statsEditor.widgets.widgetData.config.field.filters.labels.channel') }}
              </label>
              <MultiSelect
                v-model="selectedChannelFiltersByCat[item.code]"
                :options="item.charts[selectedChartTypeByCat[item.code]].channel"
                :show-toggle-all="false"
                option-label="name"
                :placeholder="t('statsEditor.widgets.widgetData.config.field.filters.placeholders.channel')"
                :max-selected-labels="3"
                class="w-full"
              />
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                :field="`widgetConfiguration.charts.${i}.filters.channel`"
              />
            </div>

            <!-- Selection de filtre "type" par catégorie -->
            <div
              v-if="selectedChartTypeByCat[item.code] && item.charts[selectedChartTypeByCat[item.code]]
                && item.charts[selectedChartTypeByCat[item.code]].type
                && item.charts[selectedChartTypeByCat[item.code]].type.length"
              class="field-wrapper"
            >
              <label class="block mb-2">
                {{ t('statsEditor.widgets.widgetData.config.field.filters.labels.type') }}
              </label>
              <MultiSelect
                v-model="selectedTypeFiltersByCat[item.code]"
                :options="item.charts[selectedChartTypeByCat[item.code]].type"
                :show-toggle-all="false"
                option-label="name"
                :placeholder="t('statsEditor.widgets.widgetData.config.field.filters.placeholders.type')"
                :max-selected-labels="3"
                class="w-full"
              />
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                :field="`widgetConfiguration.charts.${i}.filters.type`"
              />
            </div>

            <!-- Selection de filtre "bounce_type" par catégorie -->
            <div
              v-if="selectedChartTypeByCat[item.code] && item.charts[selectedChartTypeByCat[item.code]]
                && item.charts[selectedChartTypeByCat[item.code]].bounce_type
                && item.charts[selectedChartTypeByCat[item.code]].bounce_type.length"
              class="field-wrapper"
            >
              <label class="block mb-2">
                {{ t('statsEditor.widgets.widgetData.config.field.filters.labels.bounceType') }}
              </label>
              <MultiSelect
                v-model="selectedBounceTypeFiltersByCat[item.code]"
                :options="item.charts[selectedChartTypeByCat[item.code]].bounce_type"
                :show-toggle-all="false"
                option-label="name"
                :placeholder="t('statsEditor.widgets.widgetData.config.field.filters.placeholders.bounceType')"
                :max-selected-labels="3"
                class="w-full"
              />
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                :field="`widgetConfiguration.charts.${i}.filters.bounce_type`"
              />
            </div>
          </template>
        </SubPanelConfigContent>
      </div>
      <div
        v-if="listOfChannelFilters.length > 1"
        class="field-wrapper"
      >
        <label class="block mb-2">
          {{ t('statsEditor.widgets.widgetData.config.field.filters.labels.channel') }}
        </label>
        <MultiSelect
          v-model="selectedChannelFilters"
          :options="listOfChannelFilters"
          :show-toggle-all="false"
          option-label="name"
          :placeholder="t('statsEditor.widgets.widgetData.config.field.filters.placeholders.channel')"
          :max-selected-labels="3"
          class="w-full"
        />
        <FieldErrors
          :key="componentFieldErrorsKey"
          :errors="formValidationErrors"
          field="widgetConfiguration.filters.channel"
        />
      </div>
      <div
        v-if="listOfTypeFilters.length > 1"
        class="field-wrapper"
      >
        <label class="block mb-2">
          {{ t('statsEditor.widgets.widgetData.config.field.filters.labels.channel') }}
        </label>
        <MultiSelect
          v-model="selectedTypeFilters"
          :options="listOfTypeFilters"
          :show-toggle-all="false"
          option-label="name"
          :placeholder="t('statsEditor.widgets.widgetData.config.field.filters.placeholders.channel')"
          :max-selected-labels="3"
          class="w-full"
        />
        <FieldErrors
          :key="componentFieldErrorsKey"
          :errors="formValidationErrors"
          field="widgetConfiguration.filters.type"
        />
      </div>
      <div
        v-if="listOfBounceTypeFilters.length > 1"
        class="field-wrapper"
      >
        <label class="block mb-2">
          {{ t('statsEditor.widgets.widgetData.config.field.filters.labels.bounceType') }}
        </label>
        <MultiSelect
          v-model="selectedBounceTypeFilters"
          :options="listOfBounceTypeFilters"
          :show-toggle-all="false"
          option-label="name"
          :placeholder="t('statsEditor.widgets.widgetData.config.field.filters.placeholders.bounceType')"
          :max-selected-labels="3"
          class="w-full"
        />
        <FieldErrors
          :key="componentFieldErrorsKey"
          :errors="formValidationErrors"
          field="widgetConfiguration.filters.bounce_type"
        />
      </div>
    </template>
  </SubPanelConfigContent>
</template>

<script lang="ts">
import {
  computed, defineComponent, onMounted, PropType, ref, Ref, SetupContext, watch, nextTick,
} from 'vue';
import WidgetDataMetadata, { WidgetDataData } from '@/components/stats-builder/metadata/WidgetDataMetadata';
import { useI18n } from 'vue-i18n';
import InputText from 'primevue/inputtext';
import Tooltip from 'primevue/tooltip';
import Dropdown from 'primevue/dropdown';
import { ChartType, StatsWidgetType } from '@/types/generated-types/graphql';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import MultiSelect from 'primevue/multiselect';
import Card from 'primevue/card';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import SubPanelConfigContent from '@/components/automated-scenarios/SubPanelConfigContent.vue';
import CascadeSelect from 'primevue/cascadeselect';
import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import defaultLanguages from '@/configs/languages';
import StatsWidgetDataConfigOption from '@/components/stats-builder/widgets/form-config/StatsWidgetDataConfigOption.vue';

export default defineComponent({
  name: 'StatsWidgetDataConfig',
  components: {
    SubPanelConfigContent,
    FieldErrors,
    InputText,
    Dropdown,
    MultiSelect,
    CascadeSelect,
    Card,
    Accordion,
    AccordionTab,
    LocalizedTextInput,
    StatsWidgetDataConfigOption,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    modelValue: {
      type: Object as PropType<WidgetDataData>,
      required: true,
      default() {
        return WidgetDataMetadata.Create();
      },
    },

    predefinedMetrics: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: {
    modelValue: WidgetDataData;
    predefinedMetrics: any;
    formValidation: any;
  }, context: SetupContext) {
    const { t, locale } = useI18n();
    const widgetMetricUpdated = ref(false);

    /* Listing to form errors */
    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    // widget configuration
    const widgetName: Ref<any> = ref(props.modelValue.widgetName);

    // Convert widgetName to StringMap if current value is string
    if (typeof widgetName.value === 'string') {
      widgetName.value = Object.assign(
        {},
        ...defaultLanguages.map((lang) => ({ [lang]: widgetName.value })),
      );
    }

    const widgetConfiguration: Ref<any> = ref(props.modelValue.widgetConfiguration);
    const useDefaultConfigurationForWidget = ref(!widgetConfiguration.value);

    // Selected metric (if widget edition)
    const selectedMetric = ref();

    // metric widget type
    const listOfMetricWidgetTypes = computed(() => (selectedMetric.value ? Object.keys(selectedMetric.value.value) : []));
    const selectedMetricWidgetType: Ref<StatsWidgetType | null> = ref(null);

    // if selectedMetricWidgetType === CHART => we define chart categories and type
    const listOfChartCategories: any = ref([]);
    const listOfChartCategoriesIsMultiple: Ref<boolean> = ref(false);
    const selectedChartCategory: any = ref([]);
    const selectedChartTypeByCat: any = ref({});
    const selectedChannelFiltersByCat: any = ref({});
    const selectedTypeFiltersByCat: any = ref({});
    const selectedBounceTypeFiltersByCat: any = ref({});

    const chartConfigPanel = computed(() => ([
      {
        name: 'chart_config',
        label: 'statsEditor.widgets.widgetData.config.charts.title',
        show: true,
      },
    ]));
    const chartSubConfigPanels = computed(() => selectedChartCategory.value.map((cat: any) => ({
      name: `chart_subConfig_${cat.code}`,
      label: cat.name,
      code: cat.code,
      charts: cat.charts,
      show: true,
    })));

    // Channels && type Filter for KPI and Table
    const listOfChannelFilters: any = computed(() => {
      if (selectedMetricWidgetType.value && ['kpi', 'table'].includes(selectedMetricWidgetType.value)
      && selectedMetric.value && selectedMetric.value.value[selectedMetricWidgetType.value]) {
        return selectedMetric.value.value[selectedMetricWidgetType.value].channel && selectedMetric.value.value[selectedMetricWidgetType.value].channel.length
          ? selectedMetric.value.value[selectedMetricWidgetType.value].channel : [];
      }
      return [];
    });
    const selectedChannelFilters: any = ref([]);

    const listOfTypeFilters: any = computed(() => {
      if (selectedMetricWidgetType.value && ['kpi', 'table'].includes(selectedMetricWidgetType.value)
        && selectedMetric.value && selectedMetric.value.value[selectedMetricWidgetType.value]) {
        return selectedMetric.value.value[selectedMetricWidgetType.value].type && selectedMetric.value.value[selectedMetricWidgetType.value].type.length
          ? selectedMetric.value.value[selectedMetricWidgetType.value].type : [];
      }
      return [];
    });
    const selectedTypeFilters: any = ref([]);

    const listOfBounceTypeFilters: any = computed(() => {
      if (selectedMetricWidgetType.value && ['kpi', 'table'].includes(selectedMetricWidgetType.value)
        && selectedMetric.value && selectedMetric.value.value[selectedMetricWidgetType.value]) {
        return selectedMetric.value.value[selectedMetricWidgetType.value].bounce_type && selectedMetric.value.value[selectedMetricWidgetType.value].bounce_type.length
          ? selectedMetric.value.value[selectedMetricWidgetType.value].bounce_type : [];
      }
      return [];
    });
    const selectedBounceTypeFilters: any = ref([]);

    watch([selectedChartCategory], (newData, oldData) => {
      // Si la catégorie sélectionnée est 'all' → on retire toutes les autres catégories
      const lastElement = selectedChartCategory.value.length ? selectedChartCategory.value[selectedChartCategory.value.length - 1] : null;
      if (lastElement && (newData[0].length !== oldData[0].length)) {
        selectedChartCategory.value = (lastElement.code === 'all')
          ? [lastElement]
          : selectedChartCategory.value.filter((item: any) => item.code !== 'all');
      }

      const nameOfSelectedChartCategory = selectedChartCategory.value.map((item: any) => item.code);
      // reset selectedChartType and selectedChannelFilters, selectedTypeFiltersByCat, selectedBounceTypeFiltersByCat for not selected categories
      Object.keys(selectedChartTypeByCat.value).forEach((item: any) => {
        if (!nameOfSelectedChartCategory.includes(item)) {
          selectedChartTypeByCat.value[item] = '';
        } else if (Object.keys(newData[0][0].charts).length === 1) {
          // There is only one chart type available, we select it
          [selectedChartTypeByCat.value[item]] = Object.keys(newData[0][0].charts);
        }
      });
      Object.keys(selectedChannelFiltersByCat.value).forEach((item: any) => {
        if (!nameOfSelectedChartCategory.includes(item)) {
          selectedChannelFiltersByCat.value[item] = [];
        }
      });
      Object.keys(selectedTypeFiltersByCat.value).forEach((item: any) => {
        if (!nameOfSelectedChartCategory.includes(item)) {
          selectedTypeFiltersByCat.value[item] = [];
        }
      });
      Object.keys(selectedBounceTypeFiltersByCat.value).forEach((item: any) => {
        if (!nameOfSelectedChartCategory.includes(item)) {
          selectedBounceTypeFiltersByCat.value[item] = [];
        }
      });
    });

    const selectMetricWidgetType = (type: StatsWidgetType | null) => {
      if (selectedMetricWidgetType.value === type) return;
      selectedMetricWidgetType.value = type;

      // init chart data
      listOfChartCategories.value = [];
      selectedChartCategory.value = [];
      selectedChartTypeByCat.value = {};
      selectedChannelFiltersByCat.value = {};
      selectedTypeFiltersByCat.value = {};
      selectedBounceTypeFiltersByCat.value = {};

      // init KPI and TABLE data
      selectedChannelFilters.value = [];
      selectedTypeFilters.value = [];
      selectedBounceTypeFilters.value = [];
      /*
        Si c'est un widget de type CHART
        → on récupère la liste des catégories du "chart" ainsi que les types de chart et filtres associés à chaque catégorie
       */
      if (selectedMetricWidgetType.value && selectedMetricWidgetType.value === StatsWidgetType.CHART) {
        listOfChartCategoriesIsMultiple.value = selectedMetric.value.allowMultiple;
        listOfChartCategories.value = Object.keys(selectedMetric.value.value[StatsWidgetType.CHART]).map((category: any) => {
          const chartsTypeByCat = JSON.parse(JSON.stringify(selectedMetric.value.value[StatsWidgetType.CHART][category]));

          // init selected chart type by category
          selectedChartTypeByCat.value[category] = '';
          // init selected channels by category
          selectedChannelFiltersByCat.value[category] = [];
          selectedTypeFiltersByCat.value[category] = [];
          selectedBounceTypeFiltersByCat.value[category] = [];

          return {
            name: t(`statsEditor.widgets.widgetData.config.charts.categories.lists.${category}`),
            code: category,
            charts: chartsTypeByCat,
          };
        });

        if (listOfChartCategories.value && listOfChartCategories.value.length === 1) {
          // Only one option available, we select it
          selectedChartCategory.value = [listOfChartCategories.value[0]];
        }
      }

      if (listOfChannelFilters.value && listOfChannelFilters.value.length === 1) {
        // Only one option available, we select it
        selectedChannelFilters.value.push(listOfChannelFilters.value[0]);
      }

      if (listOfTypeFilters.value && listOfTypeFilters.value.length === 1) {
        // Only one option available, we select it
        selectedTypeFilters.value.push(listOfTypeFilters.value[0]);
      }

      if (listOfBounceTypeFilters.value && listOfBounceTypeFilters.value.length === 1) {
        // Only one option available, we select it
        selectedBounceTypeFilters.value.push(listOfBounceTypeFilters.value[0]);
      }
    };

    // select chart by category for CHART
    const selectChartTypeByCategory = (category: any, type: any) => {
      selectedChartTypeByCat.value[category] = type;
    };

    // Select all first elements in configuration panel for a widget
    const setDefaultConfigurationForWidget = () => {
      if (!selectedChartCategory.value.length && listOfChartCategories.value.length) {
        selectedChartCategory.value = selectedMetric.value.allowMultiple ? listOfChartCategories.value.reduce((acc: any, category: any) => {
          if ((listOfChartCategories.value.length > 0 && category.code !== 'all') || (listOfChartCategories.value.length === 1 && category.code === 'all')) {
            // Exclude "all" except if this is the only option
            acc.push(category);
          }

          return acc;
        }, []) : [listOfChartCategories.value[0]];

        selectedChartCategory.value.forEach((category: any) => {
          // eslint-disable-next-line prefer-destructuring
          selectedChartTypeByCat.value[category.code] = Object.keys(category.charts)[0];
        });
      }

      if (!selectedChannelFilters.value.length && listOfChannelFilters.value.length) {
        selectedChannelFilters.value = listOfChannelFilters.value.reduce((acc: any, filter: any) => {
          acc.push(filter);
          return acc;
        }, []);
      }

      if (!selectedTypeFilters.value.length && listOfTypeFilters.value.length) {
        selectedTypeFilters.value = listOfTypeFilters.value.reduce((acc: any, filter: any) => {
          acc.push(filter);
          return acc;
        }, []);
      }

      if (!selectedBounceTypeFilters.value.length && listOfBounceTypeFilters.value.length) {
        selectedBounceTypeFilters.value = listOfBounceTypeFilters.value.reduce((acc: any, filter: any) => {
          acc.push(filter);
          return acc;
        }, []);
      }

      if (
        Object.keys(selectedChannelFiltersByCat.value).length > 0
        || Object.keys(selectedTypeFiltersByCat.value).length > 0
        || Object.keys(selectedBounceTypeFiltersByCat.value).length > 0
      ) {
        const channelsFiltersByCat: Record<string, string[]> = {};
        const typesFiltersByCat: Record<string, string[]> = {};
        const bouncesTypesFiltersByCat: Record<string, string[]> = {};

        selectedChartCategory.value.forEach((category: any) => {
          const firstTypeOfGraph = Object.keys(category.charts)[0];

          if (category.charts[firstTypeOfGraph].channel) {
            channelsFiltersByCat[category.code] = category.charts[firstTypeOfGraph].channel.reduce((acc: any, channel: any) => {
              acc.push(channel);
              return acc;
            }, []);
          } else if (category.charts[firstTypeOfGraph].type) {
            typesFiltersByCat[category.code] = category.charts[firstTypeOfGraph].type.reduce((acc: any, type: any) => {
              acc.push(type);
              return acc;
            }, []);
          } else if (category.charts[firstTypeOfGraph].bounce_type) {
            bouncesTypesFiltersByCat[category.code] = category.charts[firstTypeOfGraph].bounce_type.reduce((acc: any, type: any) => {
              acc.push(type);
              return acc;
            }, []);
          }
        });

        if (Object.keys(channelsFiltersByCat).length > 0) {
          selectedChannelFiltersByCat.value = channelsFiltersByCat;
        }

        if (Object.keys(typesFiltersByCat).length > 0) {
          selectedTypeFiltersByCat.value = typesFiltersByCat;
        }

        if (Object.keys(bouncesTypesFiltersByCat).length > 0) {
          selectedBounceTypeFiltersByCat.value = bouncesTypesFiltersByCat;
        }
      }
    };

    // select type of chart if only 1 type is available
    watch(listOfMetricWidgetTypes, (newData) => {
      if (newData.length === 1) {
        const chartType = newData[0] as StatsWidgetType;
        selectMetricWidgetType(chartType);
      }
    });

    watch(selectedMetric, (newValue, oldValue) => {
      if (oldValue) {
        selectMetricWidgetType(null);

        // Change default widget's name value
        // widgetName.value = newValue.childrenLabel;
        Object.keys(widgetName.value).forEach((key: string) => {
          widgetName.value[key] = newValue.childrenLabel;
        });

        // Reset boolean to use default values for widget
        useDefaultConfigurationForWidget.value = true;
      } else if (Object.keys(widgetName.value).some((key) => widgetName.value[key] === '' || !widgetName.value[key])) {
        // Add default widget's name value if empty
        Object.keys(widgetName.value).forEach((key) => {
          if (widgetName.value[key] === '' || !widgetName.value[key]) {
            widgetName.value = { ...widgetName.value, [key]: newValue.childrenLabel };
          }
        });
      }
    });

    watch([selectedMetric, selectedMetricWidgetType, selectedChartCategory, selectedChartTypeByCat,
      selectedChannelFiltersByCat, selectedTypeFiltersByCat, selectedBounceTypeFiltersByCat,
      selectedChannelFilters, selectedTypeFilters, selectedBounceTypeFilters], () => {
      if (!selectedMetricWidgetType.value) {
        // We select the first available option
        selectMetricWidgetType(listOfMetricWidgetTypes.value[0] as StatsWidgetType);
      }

      if (useDefaultConfigurationForWidget.value) {
        setDefaultConfigurationForWidget();
        useDefaultConfigurationForWidget.value = false;
      }

      /* Format data */
      const formattedWidgetData: any = {
        group: selectedMetric.value ? selectedMetric.value.group : '',
        category: selectedMetric.value ? selectedMetric.value.category : '',
        metric: selectedMetric.value ? selectedMetric.value.metric : '',
        widgetType: selectedMetricWidgetType.value,
        isTimeline: selectedChartTypeByCat.value && Object.keys(selectedChartTypeByCat.value).length > 0
          ? [ChartType.LINE, ChartType.BAR, ChartType.SCATTER].includes(selectedChartTypeByCat.value[Object.keys(selectedChartTypeByCat.value)[0]])
          : false,
      };

      if (selectedMetricWidgetType.value === StatsWidgetType.CHART) {
        formattedWidgetData.charts = selectedChartCategory.value.map((itemCategory: any) => {
          const chart: any = {
            category: itemCategory.code,
            type: selectedChartTypeByCat.value[itemCategory.code] ?? '',
          };

          if (chart.type && itemCategory.charts[chart.type]) {
            if (Object.keys(itemCategory.charts[chart.type]).some((key: any) => itemCategory.charts[chart.type][key] && itemCategory.charts[chart.type][key].length)) {
              chart.filters = {};
              Object.keys(itemCategory.charts[chart.type]).forEach((key: any) => {
                if (itemCategory.charts[chart.type][key] && itemCategory.charts[chart.type][key].length) {
                  if (key === 'channel') {
                    chart.filters.channel = selectedChannelFiltersByCat.value[itemCategory.code]
                      ? selectedChannelFiltersByCat.value[itemCategory.code].map((itemChannel: any) => itemChannel.code).join() : '';
                  } else if (key === 'type') {
                    chart.filters.type = selectedTypeFiltersByCat.value[itemCategory.code]
                      ? selectedTypeFiltersByCat.value[itemCategory.code].map((itemType: any) => itemType.code).join() : '';
                  } else if (key === 'bounce_type') {
                    chart.filters.bounce_type = selectedBounceTypeFiltersByCat.value[itemCategory.code]
                      ? selectedBounceTypeFiltersByCat.value[itemCategory.code].map((itemType: any) => itemType.code).join() : '';
                  }
                }
              });
            }
          }

          return chart;
        });
      }

      if (selectedMetricWidgetType.value !== StatsWidgetType.CHART) {
        // on vérifie si des filtres existent
        if (listOfChannelFilters.value.length || listOfTypeFilters.value.length || listOfBounceTypeFilters.value.length) {
          formattedWidgetData.filters = {};
          if (listOfChannelFilters.value.length) {
            formattedWidgetData.filters.channel = selectedChannelFilters.value.map((item: any) => item.code).join();
          }
          if (listOfTypeFilters.value.length) {
            formattedWidgetData.filters.type = selectedTypeFilters.value.map((item: any) => item.code).join();
          }
          if (listOfBounceTypeFilters.value.length) {
            formattedWidgetData.filters.bounce_type = selectedBounceTypeFilters.value.map((item: any) => item.code).join();
          }
        }
      }
      widgetConfiguration.value = formattedWidgetData;
    }, { deep: true });

    watch([widgetName, widgetConfiguration], () => {
      let updatedWidgetName;
      if (typeof widgetName.value === 'string') {
        updatedWidgetName = {
          [locale.value]: widgetName.value,
        };
      } else {
        updatedWidgetName = { ...widgetName.value };
      }

      const updatedModelValue = {
        ...props.modelValue,
        widgetName: updatedWidgetName,
        widgetConfiguration: widgetConfiguration.value,
      };

      context.emit('update:modelValue', WidgetDataMetadata.Create(updatedModelValue));
    }, { deep: true });

    onMounted(async () => {
      await nextTick();
      if (widgetConfiguration.value) {
        selectedMetric.value = props.predefinedMetrics.find((group: any) => group.key === widgetConfiguration.value.group).items
          .find((category: any) => category.key === widgetConfiguration.value.category).value
          .find((metric: any) => metric.metric === widgetConfiguration.value.metric);

        selectMetricWidgetType(widgetConfiguration.value.widgetType);

        if (widgetConfiguration.value.widgetType === StatsWidgetType.CHART) {
          selectedChartCategory.value = listOfChartCategories.value
            .filter((item: any) => widgetConfiguration.value.charts.find((chart: any) => chart.category === item.code));

          selectedChartCategory.value.forEach((item: any) => {
            const chartData = widgetConfiguration.value.charts.find((chart: any) => chart.category === item.code);
            selectedChartTypeByCat.value[item.code] = chartData.type;

            // FILTERS
            /* init selected channels by category */
            const filtersChannelsData = (chartData.filters && chartData.filters.channel) ? chartData.filters.channel.split(',') : [];
            selectedChannelFiltersByCat.value[item.code] = item.charts[chartData.type] && item.charts[chartData.type].channel
              ? item.charts[chartData.type].channel.filter((itemChannel: any) => filtersChannelsData.includes(itemChannel.code)) : [];

            const filtersTypeData = (chartData.filters && chartData.filters.type) ? chartData.filters.type.split(',') : [];
            selectedTypeFiltersByCat.value[item.code] = item.charts[chartData.type] && item.charts[chartData.type].type
              ? item.charts[chartData.type].type.filter((itemType: any) => filtersTypeData.includes(itemType.code)) : [];

            const filtersBounceTypeData = (chartData.filters && chartData.filters.bounce_type) ? chartData.filters.bounce_type.split(',') : [];
            selectedBounceTypeFiltersByCat.value[item.code] = item.charts[chartData.type] && item.charts[chartData.type].bounce_type
              ? item.charts[chartData.type].bounce_type.filter((itemType: any) => filtersBounceTypeData.includes(itemType.code)) : [];
          });
        } else {
          const filtersChannelsData = (widgetConfiguration.value.filters && widgetConfiguration.value.filters.channel)
            ? widgetConfiguration.value.filters.channel.split(',') : [];
          selectedChannelFilters.value = listOfChannelFilters.value.filter((item: any) => filtersChannelsData.includes(item.code));

          const filtersTypeData = (widgetConfiguration.value.filters && widgetConfiguration.value.filters.type)
            ? widgetConfiguration.value.filters.type.split(',') : [];
          selectedTypeFilters.value = listOfTypeFilters.value.filter((item: any) => filtersTypeData.includes(item.code));

          const filtersBounceTypeData = (widgetConfiguration.value.filters && widgetConfiguration.value.filters.bounce_type)
            ? widgetConfiguration.value.filters.bounce_type.split(',') : [];
          selectedBounceTypeFilters.value = listOfBounceTypeFilters.value.filter((item: any) => filtersBounceTypeData.includes(item.code));
        }
      } else {
        widgetMetricUpdated.value = true;
      }
    });

    return {
      t,
      formValidationErrors,
      componentFieldErrorsKey,
      widgetName,

      selectedMetric,

      listOfMetricWidgetTypes,
      selectedMetricWidgetType,
      selectMetricWidgetType,

      listOfChartCategories,
      listOfChartCategoriesIsMultiple,
      selectedChartCategory,
      selectedChartTypeByCat,
      selectChartTypeByCategory,
      selectedChannelFiltersByCat,
      selectedTypeFiltersByCat,
      selectedBounceTypeFiltersByCat,

      listOfChannelFilters,
      selectedChannelFilters,

      listOfTypeFilters,
      selectedTypeFilters,

      listOfBounceTypeFilters,
      selectedBounceTypeFilters,

      chartConfigPanel,
      chartSubConfigPanels,
      defaultLanguages,
    };
  },
});
</script>

<style lang="scss">
.choose-type-graph-container {

  .list-type-graph {
    .type-graph-container {
      .type-graph {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 1px solid #6c757d;
        border-radius: 3px;
        padding: 1rem;
        background-color: #f1f5f9;

        &:hover:not(.selected, .disabled-type-graph) {
          background-color: #6c757d12;
          cursor: pointer;
        }

        &.selected {
          border: 1px solid #94c840;
          background-color: #94c84038;
        }

        &.disabled-type-graph {
          background-color: #e3e3e3;
          border: none;
        }

        i {
          font-size: 1.3rem !important;
        }
        span {
          font-size: 0.7rem !important;
          margin-top: 7px;
        }
      }
    }
  }
}
</style>
