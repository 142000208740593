<template>
  <div class="color-block">
    <div
      v-if="configs.label !== ''"
      class="flex justify-content-between flex-wrap"
    >
      <div class="flex justify-content-center">
        <label class="block my-3">
          {{ t(configs.label) }}
        </label>
      </div>
      <div class="flex justify-content-center align-items-center">
        <ToggleDisplaySettings
          v-if="!Object.prototype.hasOwnProperty.call(configs, 'displayToggle') || configs.displayToggle"
          :configs="configs"
          :parser-values="parserValues"
          @display-settings="(value) => displayProperties = value"
        />
      </div>
    </div>
    <div
      class="p-inputgroup"
      :class="{ 'hidden': !displayProperties }"
    >
      <InputText
        v-model="selectedColor"
        class="p-inputtext-sm color-input"
        @change="filterInputColor"
        @keyup="filterInputColor"
      />
      <span
        class="p-inputgroup-addon button-block"
        @click="toggleColorPicker"
      >
        <Button
          class="p-inputgroup-addon p-button-sm color-button"
          :style="{ 'background': selectedColor }"
        />
        <i
          class="far fa-arrow-down p-inputgroup-addon text-center button-icon"
        />
      </span>
    </div>
    <OverlayPanel
      ref="overlayPanelRef"
    >
      <CustomColorPicker
        data-test-id="color-picker"
        :color="selectedColor"
        :color-format="colorFormat"
        :show-alpha="configs.options.showAlpha"
        :show-gradient="configs.options.showGradient"
        title="Color Picker"
        @color-changed="changeColor"
      />
    </OverlayPanel>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, PropType, Ref, ref, watch,
} from 'vue';
import OverlayPanel from 'primevue/overlaypanel';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

import {
  ColorFieldConfig, ColorFormatEnum, ParserFieldObject, Property,
} from '@/types';
import { useI18n } from 'vue-i18n';
import CustomColorPicker from '@/components/fields/partials/CustomColorPicker.vue';
import { rgbToHex } from '@/helpers';
import ToggleDisplaySettings from '@/components/template-builder/fields/partials/ToggleDisplaySettings.vue';

export default defineComponent({
  name: 'Color',
  components: {
    ToggleDisplaySettings,
    CustomColorPicker,
    OverlayPanel,
    InputText,
    Button,
  },

  props: {
    configs: {
      type: Object as PropType<ColorFieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const configs = ref(props.configs);
    const displayProperties = ref(!(!Object.prototype.hasOwnProperty.call(configs.value, 'displayToggle') || configs.value.displayToggle === true));
    const VALID_COLOR_REGEX_RULE = /^(#([\da-f]{3}){1,2}|(rgb|rgba)\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb|rgba)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\))$/i;
    const overlayPanelRef = ref();
    const colorFormat: Ref<string> = ref(ColorFormatEnum.HEX);
    const property: Ref<Property> = ref(props.configs.properties && props.configs.properties[0]);
    const selectedColor: Ref<string> = ref(props.configs.options.defaultValue);

    watch(() => props.parserValues, (values, oldValues) => {
      if (values.selector !== oldValues?.selector) {
        if (property.value) {
          selectedColor.value = property.value.value || props.configs.options.defaultValue;
        }
      }
    }, { immediate: true });

    const toggleColorPicker = (event: MouseEvent) => {
      overlayPanelRef.value.toggle(event);
    };
    const emitValuesChanges = () => {
      if (property.value.value !== selectedColor.value) {
        property.value.value = selectedColor.value;
        context.emit('on-change-properties', {
          selector: props.parserValues.selector,
          properties: [property.value],
        });
      }
    };
    const changeColor = (color: string) => {
      selectedColor.value = color;
      emitValuesChanges();
    };
    const isValidColor = (color: string) => VALID_COLOR_REGEX_RULE.test(color);
    const filterInputColor = () => {
      if (isValidColor(selectedColor.value)) {
        emitValuesChanges();
      }
    };

    return {
      displayProperties,
      selectedColor,
      overlayPanelRef,
      colorFormat,
      toggleColorPicker,
      changeColor,
      filterInputColor,
      configs,
      t,
    };
  },
});
</script>

<style scoped lang="scss">
.color-block {
  .color-input {
    border-color: $montana;
    font-size: 1rem;

    &:hover {
      border-color: $brand-color-primary;
    }

    &:enabled:focus,
    &:enabled:active {
      border: 1px solid $brand-color-primary;
      box-shadow: none;
    }
  }

  .button-block {
    background-color: $white;
    border-color: $heather;
    padding: 0;

    &:last-child {
      border-color: $heather;
      border-top-right-radius: $field-border-radius;
      border-bottom-right-radius: $field-border-radius;
    }

    .color-button {
      margin: 0.25rem 0 0.25rem 0.25rem;
      min-width: 1rem;
      width: 1rem;
      height: 1rem;
      padding: 0;
      border-color: $heather;

      &:enabled:focus,
      &:enabled:active {
        border: 1px solid $brand-color-primary;
        box-shadow: none;
      }
    }

    .button-icon {
      background-color: $white;
      border: none;
      padding: .25rem;
      color: $heather;
      cursor: pointer;
    }

    &:hover {
      border-color: $brand-color-primary;
    }
  }
}
</style>
