
import {
  defineComponent,
  defineExpose,
  ref,
  reactive,
  computed,
  onMounted,
} from 'vue';

import Skeleton from 'primevue/skeleton';

import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import SimpleColorPicker from '@/components/fields/SimpleColorPicker.vue';
import ImageUploader from '@/components/fields/ImageUploader.vue';
import Loader from '@/components/layout/Loader.vue';

import useVuelidate from '@vuelidate/core';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { showToastError, showToastSuccess } from '@/helpers';
import { localizedTextInputValidator } from '@/helpers/CustomValidator';
import { ShopsConfigurationInput, ShopsConfigurationInputItem, OperatorType } from '@/types/generated-types/graphql';
import { saveShopParamsOnRedis, SaveShopsConfiguration } from '@/composables/shop/ShopsConfiguration';
import {
  loadSenderAddresses,
  searchValuesForAutocompletion,
} from '@/composables/autoCompletionValuesMedia';
import getShopsDomains from '@/composables/shop/ShopsSendDomains';

import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'EmailServiceParameter',

  components: {
    LocalizedTextInput,
    FieldErrors,
    SpmButton,
    Skeleton,
    SimpleColorPicker,
    ImageUploader,
    Loader,
  },

  props: {
    retrieveData: {
      type: Function,
      required: true,
    },

    shopId: {
      type: Number,
      required: true,
    },

    globalSave: {
      type: Boolean,
      required: false,
      default: false,
    },

    name: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['close'],

  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const router = useRouter();

    const isLoading = ref(false);
    const isLogoLoading = ref(false);
    const saveLoading = ref(false);

    const field = reactive<Record<string, any>>({
      shopRequiredDataEmail_senderEmail: {},
      shopRequiredDataEmail_logoPath: '',
      shopRequiredDataEmail_senderName: {},
      shopRequiredDataEmail_colorCta: '',
      shopRequiredDataRgpd_dataManagerEmail: {},
    });

    const componentFieldErrorsKey = ref(0);
    const error = ref();

    const autoCompletionData = ref<any>({
      senderAddresses: [],
      domainsOfShop: [],
    });

    const senderName = computed(() => {
      if (typeof field.shopRequiredDataEmail_senderName !== 'string') {
        if (locale.value in field.shopRequiredDataEmail_senderName) {
          return field.shopRequiredDataEmail_senderName[locale.value];
        }
        return field.shopRequiredDataEmail_senderName.fr;
      }
      return field.shopRequiredDataEmail_senderName;
    });
    const senderEmail = computed(() => {
      if (typeof field.shopRequiredDataEmail_senderEmail !== 'string') {
        if (locale.value in field.shopRequiredDataEmail_senderEmail) {
          return field.shopRequiredDataEmail_senderEmail[locale.value];
        }
        return field.shopRequiredDataEmail_senderEmail.fr;
      }
      return field.shopRequiredDataEmail_senderEmail;
    });

    const handleOnLoading = () => {
      isLogoLoading.value = true;
    };

    const handleUploaded = () => {
      isLogoLoading.value = false;
    };

    const validate = async (): Promise<ErrorConfigForm> => {
      const rules: Record<string, any> = {
        shopRequiredDataEmail_senderEmail: {
          localizedTextInputValidator: localizedTextInputValidator('email'),
        },

        shopRequiredDataEmail_senderName: {
          localizedTextInputValidator: localizedTextInputValidator('text'),
        },

        shopRequiredDataRgpd_dataManagerEmail: {
          localizedTextInputValidator: localizedTextInputValidator('email'),
        },
      };

      const v$ = useVuelidate(rules, field);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    // eslint-disable-next-line consistent-return
    const handleSave = async () => {
      const validation = await validate();
      if (!validation.success) {
        error.value = validation.validate;
        componentFieldErrorsKey.value += 1;
        return {
          err: validation.validate.value.$errors.length,
          configs: [],
        };
      }

      const emailState = { ...field };

      // unpack changed values and update
      const shopsConfigArray: ShopsConfigurationInputItem[] = [];

      Object.entries(emailState).forEach((keyValuePair: any) => {
        const [key, value] = [...keyValuePair];

        if (typeof value === 'object' && !Array.isArray(value)) {
          Object.entries(value).forEach((entry: any) => {
            const [language, inputValue] = [...entry];

            const multiRecord: ShopsConfigurationInputItem = {
              key,
              value: inputValue,
              lang: language,
            };
            shopsConfigArray.push(multiRecord);
          });
        } else {
          const shopsConfigRecord: ShopsConfigurationInputItem = {
            key,
            value,
            lang: '',
          };
          shopsConfigArray.push(shopsConfigRecord);
        }
      });

      if (props.globalSave) {
        return {
          err: null,
          configs: shopsConfigArray,
        };
      }

      const input: ShopsConfigurationInput = {
        id_shop: props.shopId,
        configs: [],
      };
      input.id_shop = props.shopId;
      input.configs = shopsConfigArray;

      saveLoading.value = true;

      try {
        await SaveShopsConfiguration(input);
        await saveShopParamsOnRedis(props.shopId);
        if (!props.globalSave) {
          showToastSuccess('savedSuccessful');
        }
      } catch (err) {
        if (!props.globalSave) {
          showToastError('GENERIC_ERROR');
        } else {
          throw err;
        }
      } finally {
        saveLoading.value = false;
      }
    };

    const goToDomainPage = () => {
      const routePath = router.resolve({ path: '/shop/send-domains' }).href;
      window.open(routePath, '_blank');
    };

    defineExpose({
      handleSave,
    });

    onMounted(async () => {
      try {
        isLoading.value = true;
        await props.retrieveData(field);

        autoCompletionData.value.senderAddresses = await loadSenderAddresses();
        autoCompletionData.value.domainsOfShop = await getShopsDomains(0, [{
          field: 'domain_verified',
          operator: OperatorType.Equals,
          value: true,
        }]);
      } catch (err) {
        showToastError('GENERIC_ERROR');
      } finally {
        isLoading.value = false;
      }
    });

    return {
      t,
      isLoading,
      saveLoading,
      field,
      componentFieldErrorsKey,
      error,
      senderName,
      senderEmail,
      isLogoLoading,
      autoCompletionData,
      handleOnLoading,
      handleUploaded,
      handleSave,
      searchValuesForAutocompletion,
      goToDomainPage,
    };
  },
});
