<template>
  <div
    :class="`flex flex-column col-${layout}`"
  >
    <slot name="content" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StatsWidgetColumn',

  props: {
    layout: {
      type: String,
      required: false,
      default: '12',
    },
  },
});
</script>

<style lang="scss" scoped>
.col-6, .col-3 {
  border-right: solid 1px #e5e7eb;

  &:last-child {
    border-right: none;
  }
}
</style>
