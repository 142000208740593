<template>
  <Card
    v-if="selectedData.isAnonymous"
    class="order-file-card"
  >
    <template #content>
      <div class="flex justify-content-center align-items-center text-2xl">
        {{ t('dataExplorer.anonymous') }}
      </div>
    </template>
  </Card>
  <Card
    v-else
    class="order-file-card"
  >
    <template #title>
      <div class="flex flex-column md:flex-row md:align-items-center md:justify-content-between">
        <div class="flex align-items-start">
          <div>
            <div class="text-xl font-medium text-900 mb-2">
              {{ title }}
            </div>
            <div
              class="font-medium text-500 text-sm"
            >
              {{ t('dataExplorer.orderFile.fields.id') }} : {{ selectedData.idOrder }}
            </div>
          </div>
        </div>
        <div class="flex flex-column gap-2">
          <div>
            <span class="text-base font-medium">
              {{ t('dataExplorer.orderFile.fields.creationDate') }} : {{ formatDate(selectedData.dateCreation, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY HH:mm:ss') }}
            </span>
          </div>
          <div>
            <span class="text-base font-medium">
              {{ t('dataExplorer.orderFile.fields.modificationDate') }} : {{ formatDate(selectedData.dateModification, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY HH:mm:ss') }}
            </span>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="grid grid-nogutter border-top-1 surface-border pt-2">
        <div class="flex flex-column gap-2 col-12 lg:col-4 p-3">
          <!-- Customers informations -->
          <div>
            <SectionTitle
              :title="t('dataExplorer.orderFile.fields.customerInformations')"
            />
            <div class="col-12">
              <div class="text-500 font-medium mb-2">{{ t('dataExplorer.orderFile.fields.customer') }}</div>
              <div class="flex gap-1 text-900">
                <span class="capitalize"> {{ selectedData.firstname }} {{ (selectedData.lastname).toUpperCase() }} </span>
                <span
                  v-if="selectedData.idCustomerShop"
                >
                  {{ `(${selectedData.idCustomerShop})` }}
                </span>
              </div>
            </div>
            <div class="col-12">
              <div class="text-500 font-medium mb-2">{{ t('dataExplorer.orderFile.fields.email') }}</div>
              <div class="text-900">
                <DataExplorerLink
                  :id="selectedData.idShopCustomer"
                  :value="selectedData.email"
                  :show-icon="true"
                />
              </div>
            </div>
          </div>
          <!-- Orders informations -->
          <div>
            <SectionTitle
              :title="t('dataExplorer.orderFile.fields.orderInformations')"
            />
            <div class="col-12">
              <div class="text-500 font-medium mb-2">{{ t('dataExplorer.orderFile.fields.status') }}</div>
              <Skeleton
                v-if="!selectedData.statusLoaded"
                height="1rem"
              />
              <div
                v-else
                class="text-900"
              >
                {{ selectedData.status }}
              </div>
            </div>
            <div class="col-12">
              <div class="text-500 font-medium mb-2">{{ t('dataExplorer.orderFile.fields.orderDate') }}</div>
              <div class="text-900">{{ formatDate(selectedData.date, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY HH:mm:ss') }}</div>
            </div>
            <div class="col-12">
              <div class="text-500 font-medium mb-2">{{ t('dataExplorer.orderFile.fields.idCart') }}</div>
              <div class="text-900">
                <DataExplorerLink
                  :id="selectedData.idCart"
                  :value="selectedData.idCart"
                  :type="dataExplorerType.CART_FILE"
                  :show-icon="true"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="text-500 font-medium mb-2">{{ t('dataExplorer.orderFile.fields.dateCart') }}</div>
              <div class="text-900">{{ formatDate(selectedData.dateCart, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY HH:mm:ss') }}</div>
            </div>
            <div class="col-12">
              <div class="text-500 font-medium mb-2">{{ t('dataExplorer.orderFile.fields.isValid') }}</div>
              <div class="text-900">{{ selectedData.isValid === '1' ? t('dataExplorer.contactFile.fields.account.hasAccount.value.yes') : t('dataExplorer.contactFile.fields.account.hasAccount.value.no') }}</div>
            </div>
            <div
              v-if="selectedData.carrierName"
              class="col-12"
            >
              <div class="text-500 font-medium mb-2">
                <span>
                  {{ t('dataExplorer.orderFile.fields.carrier') }}
                </span>
                <span
                  v-if="selectedData.shippingNumber"
                >
                  ({{ t('dataExplorer.orderFile.fields.shippingNumber') }})
                </span>
              </div>
              <div class="text-900">
                <span>
                  {{ selectedData.carrierName }}
                </span>
                <span
                  v-if="selectedData.shippingNumber"
                >
                  ({{ selectedData.shippingNumber }})
                </span>
              </div>
            </div>
          </div>
          <!-- Delivery address informations -->
          <div>
            <SectionTitle
              :title="t('dataExplorer.orderFile.fields.deliveryInformations')"
            />
            <Skeleton
              v-if="loadingDeliveryAddress"
              height="3rem"
            />
            <div
              v-else-if="!loadingDeliveryAddress && selectedData.deliveryAddress.data.length"
              class="flex md:align-items-center justify-content-between mb-4 border-1 border-dashed surface-border border-round p-3"
            >
              <AddressItem
                :address="selectedData.deliveryAddress.data[0]"
                :show-to-details-button="false"
                :show-map-button="true"
              />
            </div>
          </div>
          <!-- Invoice address informations -->
          <div>
            <SectionTitle
              :title="t('dataExplorer.orderFile.fields.invoiceInformations')"
            />
            <Skeleton
              v-if="loadingInvoiceAddress"
              height="3rem"
            />
            <div
              v-else-if="!loadingInvoiceAddress && selectedData.invoiceAddress.data.length"
              class="flex md:align-items-center justify-content-between mb-4 border-1 border-dashed surface-border border-round p-3"
            >
              <AddressItem
                :address="selectedData.invoiceAddress.data[0]"
                :show-to-details-button="false"
                :show-map-button="true"
              />
            </div>
          </div>
        </div>
        <div class="col-12 lg:col-8 p-3">
          <div class="flex flex-column gap-2">
            <SectionTitle
              :title="t('dataExplorer.orderFile.fields.orderDetails')"
            />
            <div>
              <List
                :list="selectedData.products.data"
                :retrieve-data="getOrdersProducts"
                :retrieve-data-arguments="[selectedData.idShopOrder, locale]"
                :data-loaded="selectedData.products.dataLoaded"
                :no-column="true"
                key-name="idShopOrderProduct"
                @loaded="handleRetrieveOrderProducts"
              >
                <template #content="{ item }">
                  <ProductItem
                    :product="item"
                  />
                </template>
              </List>
            </div>
            <div class="flex flex-wrap justify-content-end mt-3 pb-3">
              <div class="w-full lg:w-8 pl-3 lg:pl-0 lg:pr-3 flex align-items-end mt-5 lg:mt-0">
                <ul class="list-none p-0 m-0 w-full">
                  <li class="mb-4"><span class="font-semibold text-900">{{ t('dataExplorer.orderFile.fields.summary') }}</span></li>
                  <li
                    v-if="selectedData.shippingHT !== null && selectedData.shippingHT !== undefined"
                    class="flex justify-content-between mb-4"
                  >
                    <span class="text-900">{{ t('dataExplorer.orderFile.fields.shippingHT') }}</span>
                    <span class="text-900 font-medium text-lg">{{ formatNumberToCurrency(selectedData.shippingHT, selectedData.currency) }}</span>
                  </li>
                  <li
                    v-if="selectedData.shipping !== null && selectedData.shipping !== undefined"
                    class="flex justify-content-between mb-4"
                  >
                    <span class="text-900">{{ t('dataExplorer.orderFile.fields.shippingTTC') }}</span>
                    <span class="text-900 font-medium text-lg">{{ formatNumberToCurrency(selectedData.shipping, selectedData.currency) }}</span>
                  </li>
                  <li
                    v-if="selectedData.voucherAmount"
                    class="flex justify-content-between mb-4"
                  >
                    <div class="flex flex-column gap-1">
                      <span class="text-900">{{ t('dataExplorer.orderFile.fields.voucherAmount') }}</span>
                      <!-- <div class="flex gap-2">
                        <Chip
                          v-for="(voucher, index) in vouchers"
                          :key="voucher"
                          :label="voucher"
                        />
                      </div> -->
                    </div>
                    <span class="text-900 font-medium text-lg">{{ formatNumberToCurrency(selectedData.voucherAmount, selectedData.currency) }}</span>
                  </li>
                  <li class="flex justify-content-between border-top-1 surface-border py-3">
                    <span class="text-900 font-medium">{{ t('dataExplorer.orderFile.fields.amountHT') }}</span>
                    <span class="text-900 font-medium text-lg">{{ formatNumberToCurrency(selectedData.amountHT, selectedData.currency) }}</span>
                  </li>
                  <li class="flex justify-content-between py-3">
                    <span class="text-900 font-medium">{{ t('dataExplorer.orderFile.fields.amountTTC') }}</span>
                    <span class="font-semibold text-xl order-price">{{ formatNumberToCurrency(selectedData.amount, selectedData.currency) }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>


<script lang="ts">
import {
  defineComponent,
  computed,
  onMounted,
  ref,
} from 'vue';

import Card from 'primevue/card';
import Chip from 'primevue/chip';
import Skeleton from 'primevue/skeleton';

import AddressItem from '@/components/data-explorer/fields/AddressItem.vue';
import DataExplorerLink from '@/components/data-explorer/fields/DataExplorerLink.vue';
import ProductItem from '@/components/data-explorer/fields/ProductItem.vue';
import List from "@/components/data-explorer/fields/List.vue";
import SectionTitle from "@/components/data-explorer/fields/SectionTitle.vue";

import {
  getAddressByIdShopAddress,
  getOrdersProducts,
  getOrderStatus,
} from '@/composables/data-explorer/DataExplorer';

import { formatDate } from '@/helpers/Date';
import { formatNumberToCurrency } from '@/helpers/Number';

import { DataExplorerDataType } from '@/types';
import { OrderProduct } from '@/types/data-explorer-types';

import { useStore } from '@/store';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'OrderFile',

  components: {
    AddressItem,
    Card,
    Chip,
    DataExplorerLink,
    List,
    SectionTitle,
    Skeleton,
    ProductItem,
  },

  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const selectedData = computed(() => store.getters['dataExplorer/getSelectedData']);

    const title = computed(() => {
      if (selectedData.value) {
        return t(`dataExplorer.tabs.${selectedData.value.dataType}`, [selectedData.value.orderReference])
      }
      return '';
    });

    const vouchers = computed(() => {
      if (selectedData.value.voucher) {
        return selectedData.value.voucher.split(',');
      }
      return [];
    });

    const loadingDeliveryAddress = ref(false);
    const loadingInvoiceAddress = ref(false);

    const handleRetrieveOrderProducts = (orderProducts: OrderProduct[]) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          newLists: orderProducts,
          dataKey: 'products',
        }
      );
    };

    const dataExplorerType = ref(DataExplorerDataType);

    onMounted(async () => {
      try {
        if (selectedData.value && !selectedData.value.statusLoaded) {
          const status = await getOrderStatus(selectedData.value.status, locale.value);
          if (status) {
            store.dispatch('dataExplorer/updateOrderFileStatus', { status });
          } else {
            store.dispatch('dataExplorer/updateOrderFileStatus', { status: '' });
          }
        }

        loadingDeliveryAddress.value = true;
        loadingInvoiceAddress.value = true;

        const dispatchAddress = (newLists: any[], dataKey: string) => {
          store.dispatch(
              'dataExplorer/setAdditionnalData',
              {
                dataId: selectedData.value.dataId,
                newLists,
                dataKey,
              }
            );
        }

        if (selectedData.value && !selectedData.value.deliveryAddress.dataLoaded) {
          const deliveryAddress = await getAddressByIdShopAddress(selectedData.value.idAddressDelivery);
          if (deliveryAddress) {
            dispatchAddress([deliveryAddress], 'deliveryAddress');
          } else {
            dispatchAddress([], 'deliveryAddress');
          }
        }
        if (selectedData.value && !selectedData.value.invoiceAddress.dataLoaded) {
          const invoiceAddress = await getAddressByIdShopAddress(selectedData.value.idAddressDelivery);
          if (invoiceAddress) {
            dispatchAddress([invoiceAddress], 'invoiceAddress');
          } else {
            dispatchAddress([], 'invoiceAddress');
          }
        }
      } catch (err) {
        // Empty
      } finally {
        loadingDeliveryAddress.value = false;
        loadingInvoiceAddress.value = false;
      }
    });

    return {
      t,
      locale,
      title,
      selectedData,
      loadingDeliveryAddress,
      loadingInvoiceAddress,
      vouchers,
      dataExplorerType,

      formatDate,
      formatNumberToCurrency,
      getOrdersProducts,
      handleRetrieveOrderProducts,
    }
  }
})
</script>

<style lang="scss">
.order-file-card {
  box-shadow: none !important;
  border: 1px solid lightgray;
  border-radius: 5px;
}
</style>

<style lang="scss" scoped>
.order-price {
  color: $brand-color-primary;
  font-size: 1.5rem;
}
</style>