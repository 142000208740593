<template>
  <div class="line-height-block">
    <div class="flex justify-content-between flex-wrap">
      <div class="flex justify-content-center">
        <label
          class="block my-3"
          data-test-id="font-family-label"
        >
          {{ t(configs.label) }}
        </label>
      </div>
      <div class="flex justify-content-center align-items-center">
        <ToggleDisplaySettings
          v-if="!Object.prototype.hasOwnProperty.call(configs, 'displayToggle') || configs.displayToggle"
          :configs="configs"
          :parser-values="parserValues"
          @display-settings="(value) => displayProperties = value"
        />
      </div>
    </div>
    <Dropdown
      :model-value="selectedLineHeight"
      class="col-12 p-0 line-height-dropdown"
      :class="{ 'hidden': !displayProperties }"
      :placeholder="t(configs.label)"
      :options="lineHeightList"
      option-label="label"
      option-value="value"
      dropdown-class="stepper-input"
      @change="handleSelectedValue"
    >
      <template #option="slotProps">
        <div class="p-dropdown-name">
          <span>{{ slotProps.option.label }}</span>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, PropType, ref, onBeforeMount, Ref,
} from 'vue';
import Dropdown from 'primevue/dropdown';
import { useI18n } from 'vue-i18n';

import { FieldConfig, ParserFieldObject, Property } from '@/types';
import ToggleDisplaySettings from '@/components/template-builder/fields/partials/ToggleDisplaySettings.vue';
import getLineHeightList from '@/configs/line-height';
import { getTemplateIframeDocument } from '@/components/template-builder/utils/helpers';
import { addOrUpdateDynamicStyle } from '@/components/template-builder/utils/parser';

export default defineComponent({
  name: 'LineHeight',
  components: {
    ToggleDisplaySettings,
    Dropdown,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const configs = ref(props.configs);
    const displayProperties = ref(!(!Object.prototype.hasOwnProperty.call(configs.value, 'displayToggle') || configs.value.displayToggle === true));
    const properties: Ref<Property[]> = ref(props.parserValues.properties);
    const selectedLineHeight = ref(properties.value[0].value ?? props.configs.options.defaultValue);
    const lineHeightList = ref([{}]);

    onBeforeMount(async () => {
      try {
        lineHeightList.value = await getLineHeightList();
      } catch (error) {
        lineHeightList.value = [{}];
      }
    });

    const handleSelectedValue = (data: { value: string }) => {
      selectedLineHeight.value = data.value;
      properties.value.forEach((property: Property) => {
        // eslint-disable-next-line no-param-reassign
        property.value = selectedLineHeight.value;
      });

      // Remove line-height in pixels
      const elements = getTemplateIframeDocument()?.querySelectorAll(props.parserValues.selector);

      if (elements && elements.length > 0) {
        elements.forEach((element) => {
          const htmlElement = element as HTMLElement;
          const css = window.getComputedStyle(htmlElement);
          const lineHeight = css.getPropertyValue('line-height');

          if (lineHeight && (new RegExp('px$')).test(lineHeight) && lineHeight !== '0px') {
            htmlElement.style.removeProperty('line-height');

            if (htmlElement.style.length === 0) {
              // Remove style attribute if empty
              htmlElement.removeAttribute('style');
            }
          }
        });
      }

      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: properties.value,
      });
    };

    return {
      t,
      displayProperties,
      selectedLineHeight,
      lineHeightList,
      handleSelectedValue,
    };
  },
});
</script>

<style scoped lang="scss">
.p-dropdown-name{
  white-space: break-spaces;
  width: $configuration-panel-width - 5.25rem;
}
.line-height-block .line-height-dropdown {
  border: 1px solid $heather;
  border-radius: $field-border-radius;

  &:hover {
    border-color: $brand-color-primary;
    color: $brand-color-primary;
    box-shadow: none;
  }

  &.p-focus {
    border-color: $brand-color-primary;
    color: $brand-color-primary;
    box-shadow: $box-shadow;
  }

  &:enabled:focus {
    box-shadow: none;
  }

  :deep() .p-dropdown-label.p-inputtext {
    font-size: .9375rem;
    line-height: 1.25rem;
    padding: .1875rem;
  }
}
</style>
