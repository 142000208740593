<template>
  <Dialog
    class="p-dialog-maximized"
    v-model:visible="displayModal"
    :header="t('templateBuilder.fields.editImage')"
    :maximizable="true"
    style="width: 80rem"
    @update:visible="handleClose"
  >
    <iframe
      sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts"
      class="pixie-wrapper"
      :srcdoc="pixieSrcDoc"
      @load="iframeLoaded"
    />
  </Dialog>
</template>

<script lang="ts">
import Dialog from 'primevue/dialog';
import {
  computed, defineComponent, onBeforeMount, ref, Ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import usePixieEditor from '@/composables/usePixieEditor';
import { uploadFileToMedia } from '@/composables/configs/configuration';
import { useToast } from 'primevue/usetoast';
import { UserState } from '@/composables/User';

export default defineComponent({
  name: 'EditImageModal',

  components: {
    Dialog,
  },

  props: {
    displayDialog: {
      type: Boolean,
      required: true,
    },

    image: {
      type: String,
      required: true,
    },
  },

  emits: {
    'on-close-dialog': null,
    'on-save-image': String,
  },

  setup(props, context) {
    const { t } = useI18n();
    const toast = useToast();

    const imageUrl: Ref<string> = ref(props.image);
    const content: Ref<Document | null> = ref(null);
    const pixieSrcDoc = computed(() => content.value && content.value.documentElement.outerHTML);

    const { showPixieEditor, destroyPixieEditor } = usePixieEditor();

    const displayModal = computed(() => props.displayDialog);

    const handleClose = () => {
      destroyPixieEditor();
      context.emit('on-close-dialog');
    };
    const handleSave = async (image: File, name: string) => {
      const uploadResult = await uploadFileToMedia(image, UserState.activeShop?.id ?? 0);
      if (uploadResult.data === false) {
        toast.add({
          severity: 'error', summary: t('error'), detail: t('templateBuilder.panels.fileManager.updateError'), life: 3000,
        });
      } else {
        context.emit('on-save-image', uploadResult.data);
        handleClose();
      }
    };

    const iframeLoaded = () => {
      const pixieIframe = (document.querySelector('iframe.pixie-wrapper') as HTMLIFrameElement)?.contentWindow;

      if (pixieIframe) {
        showPixieEditor(pixieIframe, imageUrl.value, handleSave);
      }
    };

    watch(props, () => {
      imageUrl.value = props.image;
    });

    onBeforeMount(() => {
      // Create Pixie editor container and insert CSS and JS files into iframe
      const spmApiJs = document.createElement('script');
      spmApiJs.setAttribute('src', '/libs/pixiev2/dist/pixie.umd.js');
      content.value = document.implementation.createHTMLDocument();

      const baseIframeCss = document.createElement('link');
      baseIframeCss.setAttribute('rel', 'stylesheet');
      baseIframeCss.setAttribute('href', '/css/template-builder/baseIframePixie.css');
      content.value.head.appendChild(baseIframeCss);

      const pixieContainer = document.createElement('div');
      pixieContainer.setAttribute('id', 'editor-container');
      content.value.body.appendChild(pixieContainer);
      content.value.body.appendChild(spmApiJs);
    });

    return {
      t,
      displayModal,
      handleClose,
      iframeLoaded,
      pixieSrcDoc,
    };
  },
});
</script>

<style lang="scss">
.pixie-wrapper {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
