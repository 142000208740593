// eslint-disable-next-line import/no-cycle
import { ComponentData, ComponentMetadata, ErrorConfigForm } from '@/types/automated-scenarios';
import { i18n } from '@/i18n';
// eslint-disable-next-line import/no-cycle
import {
  AutomatedScenarioState as state,
  getOperatorChildByOutput,
  isRootOfTree,
} from '@/composables/AutomatedScenarios';

export interface DeclencheurCollectAnonymousVisitorData {
  nothing: string;
}

let translation: any;
(async () => {
  translation = await i18n;
})();

const DeclencheurCollectAnonymousVisitorMetadata: ComponentMetadata<DeclencheurCollectAnonymousVisitorData> = {
  Meta: {
    kind: 'declencheur',
    id: 'boxcollectanonymousvisitordata',
    label: 'automatedScenarios.triggers.collectAnonData.label',
    icon: 'fa-database',
    hasConfiguration: false,
    component: 'DeclencheurCollectAnonymousVisitor',
    availableInCampaign: [],
  },
  Create({ nothing }: DeclencheurCollectAnonymousVisitorData = {
    nothing: '',
  }): ComponentData<DeclencheurCollectAnonymousVisitorData> {
    return {
      id: 'boxcollectanonymousvisitordata',
      form_name: 'boxcollectanonymousvisitordata',
      logo_box: 'fa-database',
      nothing,
    };
  },
  async Validate(): Promise<ErrorConfigForm> {
    return {
      success: true,
    };
  },
  Outputs(data: DeclencheurCollectAnonymousVisitorData): number {
    return 1;
  },
  CanBeDeleted(operatorId: string): boolean|string {
    const isRoot = isRootOfTree(operatorId);

    if (isRoot) {
      const childOperator = getOperatorChildByOutput(operatorId, 'output_1');
      if (childOperator !== null && typeof (state.scenario.data.operators[childOperator]) !== undefined
        && state.scenario.data.operators[childOperator].properties.class !== 'declencheur') {
        return 'automatedScenarios.cant_remove_operator';
      }
    }

    return true;
  },
  Label(data: DeclencheurCollectAnonymousVisitorData): string {
    return translation.global.t('automatedScenarios.triggers.collectAnonData.label');
  },
};

export default DeclencheurCollectAnonymousVisitorMetadata;
