import { ComponentData, ComponentMetadata, ErrorConfigForm } from '@/types/automated-scenarios';
import { i18n } from '@/i18n';
// eslint-disable-next-line import/no-cycle
import {
  AutomatedScenarioState as state,
  getOperatorChildByOutput,
  isRootOfTree,
} from '@/composables/AutomatedScenarios';
import { TypeCampaignEnum } from '@/types';

export interface DeclencheurAbandonPanierData {
  info?: string; // serves nothing; here only to use modelValue in DeclencheurAbandonPanier.vue
}

let translation: any;
(async () => {
  translation = await i18n;
})();

const DeclencheurAbandonPanierMetadata: ComponentMetadata<DeclencheurAbandonPanierData> = {
  Meta: {
    kind: 'declencheur',
    id: 'boxabandonpanier',
    label: 'automatedScenarios.triggers.abandonPanier.label',
    icon: 'fa-shopping-cart',
    hasConfiguration: false,
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxsitepasstime', 'boxquitsite',
          'boxperiodiclaunching', 'boxscrollpage', 'boxstatuscommand',
          'boxcustomjs', 'registeronsite', 'subscribetonewsletter',
          'boxsendemail', 'boxsendsms', 'boxdisplayfreepopup',
          'boxdisplayfreemodel', 'boxsendfbmessenger', 'boxsendpushnotification',
          'boxfiltre_2', 'openemail', 'clicklink',
          'boxvisitpage',
        ],
      },
    },
    persistent: ['boxstatuscommand'],
    component: 'DeclencheurAbandonPanier',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
  },
  Create({ info }: DeclencheurAbandonPanierData = {
    info: 'automatedScenarios.informations.abandonPanier',
  }): ComponentData<DeclencheurAbandonPanierData> {
    return {
      id: 'boxabandonpanier',
      form_name: 'boxabandonpanier',
      logo_box: 'fa-shopping-cart',
      info,
    };
  },
  async Validate(): Promise<ErrorConfigForm> {
    return {
      success: true,
    };
  },
  Outputs(data: DeclencheurAbandonPanierData): number {
    return 1;
  },
  CanBeDeleted(operatorId: string): boolean|string {
    const isRoot = isRootOfTree(operatorId);

    if (isRoot) {
      const childOperator = getOperatorChildByOutput(operatorId, 'output_1');
      if (childOperator !== null && typeof (state.scenario.data.operators[childOperator]) !== undefined
        && state.scenario.data.operators[childOperator].properties.class !== 'declencheur') {
        return 'automatedScenarios.cant_remove_operator';
      }
    }

    return true;
  },
  Label(data: DeclencheurAbandonPanierData): string {
    return translation.global.t('automatedScenarios.triggers.abandonPanier.label');
  },
};

export default DeclencheurAbandonPanierMetadata;
