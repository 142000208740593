
import { useI18n } from 'vue-i18n';
import ScrollPanel from 'primevue/scrollpanel';
import Button from 'primevue/button';
import { AutomatedScenarioState as state, setActiveHistory } from '@/composables/AutomatedScenarios';
import { computed, onMounted, watch, SetupContext, defineComponent } from "vue";

export default defineComponent({
  name: 'HistoryPanel',

  components: {
    Button,
    ScrollPanel,
  },

  setup(_: any, context: SetupContext) {
    const { t } = useI18n();

    const workflowHistory = computed(() => {
      return state.scenario.history && Object.prototype.hasOwnProperty.call(state.scenario.history, 'history') && state.scenario.history.history && state.scenario.history.history !== '' ? JSON.parse(state.scenario.history.history).reverse() : [];
    });

    const restoreHistoryButtonDisplayed = computed(() => workflowHistory.value.length > 0 && state.scenario.savedHistoryIndex !== -1 && state.scenario.savedHistoryIndex !== state.scenario.currentHistoryIndex);

    const calculateScrollPanelHeight = () => {
      if (workflowHistory.value.length > 0) {
        let heightToDeduct = 0;

        const button: HTMLElement | null = document.querySelector('#backToSavedHistory');

        if (button) {
          if (restoreHistoryButtonDisplayed.value) {
            button.classList.remove('hidden');
            button.classList.add('block');
          } else {
            button.classList.add('hidden');
            button.classList.remove('block');
          }
        }

        const legend = <HTMLElement>document.querySelector('#automatedScenariosLegend');
        const scrollPanel = <HTMLElement>document.querySelector('#historyScrollPanel');
        const sideBar = <HTMLElement>document.querySelectorAll('.content-sidebar')[0];
        const panelHeader = <HTMLElement>document.querySelectorAll('.p-panel-header')[0];
        const panelContent = <HTMLElement>document.querySelectorAll('.p-panel-header')[0];
        // const sideBarIcons = <HTMLElement> document.querySelectorAll('.content-sidebar .p-tabview-nav-content')[0];
        const legendStyles = window.getComputedStyle(legend);
        const panelContentStyles = window.getComputedStyle(panelContent);

        heightToDeduct += parseInt(legendStyles.marginTop, 10);
        heightToDeduct += parseInt(legendStyles.marginBottom, 10);
        heightToDeduct += parseInt(legendStyles.paddingTop, 10);
        heightToDeduct += parseInt(legendStyles.paddingBottom, 10);
        heightToDeduct += parseInt(panelContentStyles.marginTop, 10);
        heightToDeduct += parseInt(panelContentStyles.paddingTop, 10);
        heightToDeduct += parseInt(panelContentStyles.borderBottomWidth, 10);
        heightToDeduct += panelHeader.offsetHeight;
        heightToDeduct += legend.offsetHeight;

        scrollPanel.style.height = (sideBar.offsetHeight - heightToDeduct) + 'px';
      }
    };

    const restoreHistory = (historyIndex: number) => {
      setActiveHistory(historyIndex);
    };

    watch(restoreHistoryButtonDisplayed, calculateScrollPanelHeight);

    onMounted(calculateScrollPanelHeight);

    return {
      t,
      state,
      calculateScrollPanelHeight,
      restoreHistory,
      workflowHistory
    };
  }
});
