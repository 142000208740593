import { BaseFieldConfig, ComponentNameEnum, BackgroundImageOptionTypeEnum } from '@/types';

const getBackgroundImageFieldConfig = (type: BackgroundImageOptionTypeEnum): BaseFieldConfig => {
  const componentName = ComponentNameEnum.BACKGROUND_IMAGE;
  if (type === BackgroundImageOptionTypeEnum.SELECTOR) {
    return {
      componentName,
      options: {
        type,
        list: [],
        label: 'templateBuilder.fields.backgroundImageOptions.displayBackgroundOn.label',
        options: [
          {
            label: 'templateBuilder.fields.backgroundImageOptions.displayBackgroundOn.options.fullWidth',
            value: 'full-width',
          },
          {
            label: 'templateBuilder.fields.backgroundImageOptions.displayBackgroundOn.options.centerOnly',
            value: '.container',
          },
        ],
        defaultValue: 'full-width',
      },
    };
  } if (type === BackgroundImageOptionTypeEnum.NO_OPTION) {
    return {
      componentName,
      options: {
        type,
        list: [],
        label: 'templateBuilder.fields.backgroundImageOptions.displayBackgroundOn.label',
      },
    };
  }
  return {
    componentName,
    options: {
      type,
      list: [
        {
          type: 'background-size',
          label: 'templateBuilder.fields.backgroundImageOptions.size.label',
          options: [
            {
              label: 'templateBuilder.fields.backgroundImageOptions.size.options.auto',
              value: 'auto',
            },
            {
              label: 'templateBuilder.fields.backgroundImageOptions.size.options.cover',
              value: 'cover',
            },
            {
              label: 'templateBuilder.fields.backgroundImageOptions.size.options.contain',
              value: 'contain',
            },
          ],
          defaultValue: 'auto',
        },
        {
          type: 'background-position-x',
          label: 'templateBuilder.fields.backgroundImageOptions.positionX.label',
          options: [
            {
              label: 'templateBuilder.fields.backgroundImageOptions.positionX.options.left',
              value: '0%',
            },
            {
              label: 'templateBuilder.fields.backgroundImageOptions.positionX.options.center',
              value: '50%',
            },
            {
              label: 'templateBuilder.fields.backgroundImageOptions.positionX.options.right',
              value: '100%',
            },
          ],
          defaultValue: '0%',
        },
        {
          type: 'background-position-y',
          label: 'templateBuilder.fields.backgroundImageOptions.positionY.label',
          options: [
            {
              label: 'templateBuilder.fields.backgroundImageOptions.positionY.options.top',
              value: '0%',
            },
            {
              label: 'templateBuilder.fields.backgroundImageOptions.positionY.options.middle',
              value: '50%',
            },
            {
              label: 'templateBuilder.fields.backgroundImageOptions.positionY.options.bottom',
              value: '100%',
            },
          ],
          defaultValue: '0%',
        },
        {
          type: 'background-repeat',
          label: 'templateBuilder.fields.backgroundImageOptions.filling.label',
          options: [
            {
              label: 'templateBuilder.fields.backgroundImageOptions.filling.options.repeat',
              value: 'repeat',
            },
            {
              label: 'templateBuilder.fields.backgroundImageOptions.filling.options.repeatX',
              value: 'repeat-x',
            },
            {
              label: 'templateBuilder.fields.backgroundImageOptions.filling.options.repeatY',
              value: 'repeat-y',
            },
            {
              label: 'templateBuilder.fields.backgroundImageOptions.filling.options.noRepeat',
              value: 'no-repeat',
            },
          ],
          defaultValue: 'repeat',
        },
      ],
    },
  };
};

export default getBackgroundImageFieldConfig;
