<template>
  <label>
    {{ t('automatedScenarios.filters.linkedOperators.label') }}
  </label>
  <div
    v-if="linkedOperatorsData.length === 0"
    class="filter-linked-operators-empty mt-2"
  >
    <span>{{ t('automatedScenarios.filters.linkedOperators.empty_error') }}</span>
  </div>
  <div
    v-else
    class="ml-4 mt-2"
  >
    <div class="flex flex-column">
      <div
        v-for="(linkedOperator, index) in linkedOperatorsData"
        :key="index"
        class="flex flex-row pb-2"
      >
        <div
          class="display-box-container display-box-action"
        >
          <div class="display-box-container-icon-box">
            <i
              class="far"
              :class="linkedOperator.icon"
            />
          </div>
          <div class="display-box-container-label-box">
            <span>{{ t(linkedOperator.label) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import {
  AutomatedScenarioState as state,
  OperatorBox,
} from '@/composables/AutomatedScenarios';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'FilterLinkedOperators',

  setup() {
    const { t } = useI18n();

    const linkedOperatorsData = computed(() => state.operators
      .filter((operator: OperatorBox) => state.linkedOperators.includes(operator.operatorId)));

    return {
      t,
      linkedOperatorsData,
    };
  },
});
</script>

<style lang="scss" scoped>
  .filter-linked-operators-empty {
    text-align: center;
    span {
      color: $error-red;
    }
  }
</style>
