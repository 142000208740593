
import {
  defineComponent, onBeforeMount, PropType, Ref, ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
  DropdownOption, FieldConfig, ParserFieldObject, Property,
} from '@/types';
import MultiSelect from 'primevue/multiselect';

export default defineComponent({
  name: 'MultiDropdown',

  components: {
    MultiSelect,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: ['on-change-properties'],

  setup(props, context) {
    const { t } = useI18n();
    const property: Ref<Property> = ref(props.parserValues.properties && props.parserValues.properties[0]);
    const options: Ref<DropdownOption[]> = ref(props.configs.options.list);
    const selectedValues = ref(options.value.filter((option: DropdownOption) => property.value.value.indexOf(option.value) !== -1));
    const handleSelected = (value: DropdownOption[]) => {
      selectedValues.value = value;
      property.value.value = selectedValues.value.reduce((acc: Array<string|number>, option: DropdownOption) => { acc.push(option.value); return acc; }, []);
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: [property.value],
      });
    };

    const isMandatory = (option: DropdownOption): boolean => (props.configs.options.mandatoryFields && props.configs.options.mandatoryFields.includes(option.value));

    watch(() => property.value.value, (values, oldValues) => {
      if (values !== oldValues) {
        selectedValues.value = options.value.filter((option: DropdownOption) => property.value.value.indexOf(option.value) !== -1);
      }
    }, { immediate: true });

    onBeforeMount(() => {
      options.value.forEach((option: DropdownOption) => {
        // eslint-disable-next-line no-param-reassign
        option.label = t(option.label);
      });
    });

    return {
      t,
      selectedValues,
      options,
      handleSelected,
      isMandatory,
    };
  },

});
