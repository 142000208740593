
import { useI18n } from 'vue-i18n';
import { defineComponent, onMounted, ref } from 'vue';
import { UserState } from '@/composables/User';
import CryptoJS from 'crypto-js';

export default defineComponent({
  name: 'MyOldNewslettersStatistics',
  setup() {
    const { t } = useI18n();
    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
    const iframeSrc = ref('');
    const query = ref('/advisor/logAsShop/');
    const iframeRef = ref(null);

    const handleLoad = () => {
      console.log('Iframe loaded successfully');
      // const iframeDocument = iframeRef.value?.contentDocument || iframeRef.value?.contentWindow.document;
      //
      // if (!iframeDocument) {
      //   console.error('Cannot access iframe content');
      // }

      // TODO : access to the iframe content and change some css (remove the header and the footer) (cross origin problem)
    };

    onMounted(() => {
      const cryptedKey = CryptoJS.SHA1(`remotestatisticsZn26687S2682MF2ZV4Ag2g9JGbhF686X${idShop.value}`).toString();
      query.value += `${idShop.value}/remotestatistics/${cryptedKey}`;
      iframeSrc.value = `${process.env.VUE_APP_ZEND_URL}${query.value}`;
    });

    return {
      t,
      iframeSrc,
      iframeRef,
      handleLoad,
    };
  },
});
