
import {
  defineComponent, onMounted,
  PropType, Ref,
  ref,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';

import {
  TabItem,
  TabSubitem,
  ParserObject,
} from '@/types';
import StructureEdit from '@/components/template-builder/fields/StructureEdit.vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

import ConfigurationPanelTabContentFieldGroup from '@/components/template-builder/panels/ConfigurationPanelTabContentFieldGroup.vue';

export default defineComponent({
  name: 'ConfigurationPanelTabContent',

  components: {
    StructureEdit,
    TabView,
    TabPanel,
    ConfigurationPanelTabContentFieldGroup,
  },

  props: {
    tabItem: {
      type: Object as PropType<TabItem | TabSubitem>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserObject>,
      required: true,
    },

    displayLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const activeFieldGroupIndex: Ref<number[]> = ref([]);
    const toggleFieldGroup = (index: number) => {
      if (activeFieldGroupIndex.value.includes(index)) {
        activeFieldGroupIndex.value.splice(activeFieldGroupIndex.value.indexOf(index), 1);
      } else {
        activeFieldGroupIndex.value.push(index);
      }
    };

    watch([
      () => props.parserValues,
      () => props.tabItem,
    ],
    () => {
      if (props.tabItem.tabPanel) {
        activeFieldGroupIndex.value = props.tabItem.tabPanel.items.reduce((acc: number[], item, index) => {
          acc.push(index);
          return acc;
        }, []);
      }
    });

    onMounted(() => {
      if (props.tabItem.tabPanel) {
        activeFieldGroupIndex.value = props.tabItem.tabPanel.items.reduce((acc: number[], item, index) => {
          if (index === 0) {
            acc.push(index);
          }
          return acc;
        }, []);
      }
    });

    return {
      activeFieldGroupIndex,
      toggleFieldGroup,
      t,
    };
  },
});
