import {
  TEMPLATE_COLUMN_IDENTIFIER_PREFIX,
  TEMPLATE_LINE_IDENTIFIER_PREFIX,
  TEMPLATE_SECTION_CONTENT_ID_SUFFIX,
  TEMPLATE_SECTION_IDENTIFIER_PREFIX,
  TEMPLATE_WIDGET_IDENTIFIER_PREFIX,
  TEMPLATE_TRANSLATE_SPECIFIC,
  TEMPLATE_TRANSLATE_SPECIFIC_ITEMS,
  TEMPLATE_TRANSLATE_SPECIFIC_ONLY_ATTRIBUTE,
  TEMPLATE_TRANSLATE_SPECIFIC_ONLY_CONTENT,
  WIDGETS_PLACEHOLDER_IDENTIFIER,
  WIDGET_IMPORT_ZIP_PLACEHOLDER_IDENTIFIER,
} from '@/components/template-builder/utils/constants';
// eslint-disable-next-line import/no-cycle
import { generateUniqStructureId } from '@/components/template-builder/utils/helpers';
import { WidgetTypeEnum } from '@/types';
import { faArrowToBottom, faTrashAltRegular } from '@/components/template-builder/utils/icons-definition';
// eslint-disable-next-line import/no-cycle
import { getSocialNetworksLinks } from '@/components/template-builder/setters';

// structures
export const rawWidgetPlaceholder = () => `<div class="${WIDGETS_PLACEHOLDER_IDENTIFIER}">${faTrashAltRegular}${faArrowToBottom}</div>`;

export const rawColumn = () => {
  const columnId = `${TEMPLATE_COLUMN_IDENTIFIER_PREFIX}_${generateUniqStructureId()}`;
  return `<th id="${columnId}" class="column spm_droppable_widget spm_droppable" style="width: 100%;">${rawWidgetPlaceholder()}</th>`;
};

export const rawLine = () => {
  const lineId = `${TEMPLATE_LINE_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<table id="${lineId}"
    class="spm_draggable_row ui-droppable" width="100%" cellspacing="0" cellpadding="0"><tbody><tr><td>
    <table class="row spm_responsive" cellspacing="0" cellpadding="0"><tbody><tr><td><table class="columns" cellspacing="0" cellpadding="0"><tbody><tr>${rawColumn()}
    </tr></tbody></table></td></tr></tbody></table></td></tr></tbody>`;
};

export const rawSection = () => {
  const sectionId = `${TEMPLATE_SECTION_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<table style="width: 100%;
    border-collapse: collapse;" class="spm_section" id="${sectionId}" cellspacing="0" cellpadding="0"><tbody><tr><td class="center" align="center">
    <center><table class="container" id="${sectionId}${TEMPLATE_SECTION_CONTENT_ID_SUFFIX}" cellspacing="0" cellpadding="0"><tbody><tr><td class="spm_droppable_row">
    ${rawLine()}</td></tr></tbody></table></center></td></tr></tbody></table>`;
};

export const rawMandatoryVariablesWidget = (t: any, hasUnsubscribeUrlLink: boolean) => {
  const lineId = `${TEMPLATE_LINE_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  const columnId = `${TEMPLATE_COLUMN_IDENTIFIER_PREFIX}_${generateUniqStructureId()}`;
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  const texts = [];

  if (!hasUnsubscribeUrlLink) {
    texts.push(t('templateBuilder.unsubscribeLinkWidget').replace('%s', '{var=template.unsubscribe_url}'));
  }

  return `<table id="${lineId}" class="spm_draggable_row" width="100%" cellspacing="0" cellpadding="0">
  <tbody>
    <tr>
      <td>
        <table class="row spm_responsive" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td>
                <table class="columns" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <th id="${columnId}" class="column spm_droppable_widget spm_droppable" style="width: 100%;">
                        <table data-widgettype="spm_widget_text" class="spm_widget_text spm_draggable_widget" id="${widgetId}"
                            style="border-collapse: collapse; display: table;" width="100%" cellspacing="0" cellpadding="0">
                          <tbody>
                            <tr>
                              <th>
                                <div class="spm_rich_editor">${texts.join('<br />')}</div>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>`;
};

// widgets
const rawWidgetSeparator = () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<table cellspacing="0" cellpadding="0" width="100%"
    id="${widgetId}" class="spm_widget_separator spm_draggable_widget" data-widgettype="spm_separator" style="border-collapse: collapse;"><tbody><tr>
    <th><table border="0" width="100%" cellpadding="0" cellspacing="0" class="spm_separator" align="center" style="border-collapse: collapse;">
    <tbody><tr><th>&nbsp;</th></tr></tbody></table></th></tr></tbody></table>`;
};

const rawWidgetText = () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  const text = `<h1>Lorem ipsum dolor sit amet</h1><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ultricies lectus egestas nulla mollis cursus.
  Sed nec lectus non nulla auctor aliquam. <br /><br /> Mauris facilisis at lectus nec consequat.
  Donec at condimentum turpis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>`;
  return `<table cellspacing="0" cellpadding="0" width="100%"
    id="${widgetId}" class="spm_widget_text spm_draggable_widget" data-widgettype="spm_widget_text" style="border-collapse: collapse;"><tbody><tr>
    <th><div class="spm_rich_editor">${text}</div></th></tr></tbody></table>`;
};
const rawWidgetTitle = () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<table cellspacing="0" cellpadding="0" width="100%"
    id="${widgetId}" class="spm_widget_title spm_draggable_widget" data-widgettype="spm_widget_title" style="border-collapse: collapse;">
    <tbody>
      <tr>
        <th>
          <table class="spm_title_wrapper" cellspacing="0" cellpadding="0">
            <tr>
              <th>
                <h1>Votre titre ici</h1>
              </th>
            </tr>
          </table>
        </th>
      </tr>
    </tbody>
  </table>`;
};

const rawWidgetImage = () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  const src = 'http://media.shopimind.io/img/v4/placeholders/image_placeholder.png';
  return `<table cellspacing="0" cellpadding="0" width="100%"
    id="${widgetId}" class="spm_widget_image spm_draggable_widget" data-widgettype="spm_widget_image" style="border-collapse: collapse;"><tbody><tr>
    <th><a href="{var=shop.url}">
      <img src="${src}" width="100%" alt="">
    </a></th></tr></tbody></table>`;
};

const rawWidgetSocialMedia = (additionalConfiguration: Record<string, any>) => () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  let socialNetworksHtml = '';

  if (!additionalConfiguration.socialNetworks || !Array.isArray(additionalConfiguration.socialNetworks)) {
    // eslint-disable-next-line no-param-reassign
    additionalConfiguration.socialNetworks = [];
  }

  if ('socialNetworks' in additionalConfiguration && additionalConfiguration.socialNetworks && Array.isArray(additionalConfiguration.socialNetworks)) {
    if (additionalConfiguration.socialNetworks.length === 0) {
      additionalConfiguration.socialNetworks.push('facebook');
      additionalConfiguration.socialNetworks.push('twitter');
      additionalConfiguration.socialNetworks.push('email');
    }

    additionalConfiguration.socialNetworks.forEach((network: string) => {
      const variable = network === 'email' ? 'contact' : network;
      socialNetworksHtml += `<td class="spm_${network} spm_social_icon_container spm_social_icon">
        <a class="${TEMPLATE_TRANSLATE_SPECIFIC_ONLY_ATTRIBUTE}" href="{var=social.${variable}_url}">
          <img src="https://media.shopimind.io/img/icons/social/2/32/${network}.png" alt="${network}" width="32" style="max-width:32px;" />
        </a>
      </td>`;
    });
  }

  return `<table cellspacing="0" cellpadding="0" width="100%"
    id="${widgetId}" class="spm_widget_follow spm_draggable_widget ${TEMPLATE_TRANSLATE_SPECIFIC}" data-widgettype="spm_widget_follow">
    <tbody><tr><th><table class="spm_social_icons spm_social_icons_horizontal" align="center" cellspacing="0" cellpadding="0">
      <tbody>
        <tr>${socialNetworksHtml}</tr>
      </tbody>
    </table>
  </th></tr></tbody></table>`;
};

const rawWidgetShareSocialMedia = (additionalConfiguration: Record<string, any>) => () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  const shareLinks = getSocialNetworksLinks('{var=template.private_archive_url}');
  let socialNetworksHtml = '';
  if (!additionalConfiguration.socialNetworks || !Array.isArray(additionalConfiguration.socialNetworks)) {
    // eslint-disable-next-line no-param-reassign
    additionalConfiguration.socialNetworks = [];
  }
  if ('socialNetworks' in additionalConfiguration && additionalConfiguration.socialNetworks && Array.isArray(additionalConfiguration.socialNetworks)) {
    if (additionalConfiguration.socialNetworks.length === 0) {
      additionalConfiguration.socialNetworks.push('facebook');
      additionalConfiguration.socialNetworks.push('twitter');
      additionalConfiguration.socialNetworks.push('email');
    }
    additionalConfiguration.socialNetworks.forEach((network: string) => {
      socialNetworksHtml += `<td class="spm_${network} spm_social_icon_container spm_social_icon">
        <a href="${shareLinks[network]}">
          <img src="https://media.shopimind.io/img/icons/social/2/32/${network}.png" alt="${network}" width="32" style="max-width:32px;" />
        </a>
      </td>`;
    });
  }

  return `<table cellspacing="0" cellpadding="0" width="100%"
    id="${widgetId}" class="spm_widget_share spm_draggable_widget ${TEMPLATE_TRANSLATE_SPECIFIC}" data-widgettype="spm_widget_share" style="border-collapse: collapse;"><tbody><tr>
    <th><table class="spm_social_icons spm_social_icons_horizontal" align="center" cellspacing="0" cellpadding="0">
      <tr>${socialNetworksHtml}</tr>
    </table></th></tr></tbody></table>`;
};

const rawWidgetCode = () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<table cellspacing="0" cellpadding="0" width="100%"
    id="${widgetId}" class="spm_widget_code spm_draggable_widget" data-widgettype="spm_widget_code" style="border-collapse: collapse;"><tbody><tr>
    <th><p>Insert your own HTML code</p></th></tr></tbody></table>`;
};

const rawWidgetButton = () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<table cellspacing="0" cellpadding="0" width="100%"
    id="${widgetId}" class="spm_call_to_action spm_draggable_widget" data-widgettype="spm_call_to_action" style="border-collapse: collapse;"><tbody><tr>
    <th><table class="button" cellspacing="0" cellpadding="0"><tr><th valign="middle" style="line-height:100%;mso-line-height-alt:100%;">
      <a href="{var=shop.url}">Votre texte ici</a>
    </th></tr></table></th></tr></tbody></table>`;
};

const rawWidgetGdprButton = (t: Function) => () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<table cellspacing="0" cellpadding="0" width="100%"
    id="${widgetId}" class="spm_call_to_action_rgpd spm_draggable_widget" data-widgettype="spm_call_to_action_rgpd" style="border-collapse: collapse;"><tbody><tr>
    <th><table class="button" cellspacing="0" cellpadding="0"><tr><th valign="middle" style="line-height:100%;mso-line-height-alt:100%;">
      <a href="{var=link_double_optin}" class="spm_inline_editing">${t('templateBuilder.gdprDefaultLabel')}</a>
    </th></tr></table></th></tr></tbody></table>`;
};

const rawWidgetSmartProductList = (t: Function) => () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<table id="${widgetId}" class="spm_draggable_widget spm_smart_products_list" data-widgettype="spm_smart_products_list" width="100%"
    cellspacing="0" cellpadding="0">
    <tbody>
      <tr>
        <th>
          <table class="row spm_title_wrapper spm_product_list_title_wrapper spm_responsive" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <th><h1 class="spm_product_list_title spm_inline_editing">${t('templateBuilder.smartProductListDefaultTitle')}</h1></th>
              </tr>
            </tbody>
          </table>
          <table class="row spm_product_list spm_product_list_vertical_1 spm_responsive" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td>
                  <table class="columns spm_product_list_element_container" cellspacing="0" cellpadding="0">
                    <tbody>
                      <tr class="spm_product_list_element_row">
                        <th class="column spm_product_list_element" style="width: 100%;"
                        s-collection="products" s-item="product" s-grid="1" s-nb="4" s-engine="crossselling"
                        s-ids="" s-excluded_ids="" s-excluded_categories="" s-excluded_manufacturers="" s-nb_weeks="4">
                          <table width="100%" style="width: 100%" cellspacing="0" cellpadding="0">
                            <tbody>
                              <tr>
                                <th class="column" style="width: 30%;">
                                  <a href="{var=product.url}">
                                    <img src="{var=product.image_url resize=400x400 resizeType=fill-to-fit}"
                                    style="max-width: 600px;" width="150" class="spm_product_list_element_image"
                                     alt="{var=product.name}" title="{var=product.name}" />
                                  </a>
                                </th>
                                <th class="column" style="padding: 5px; width: 70%;">
                                  <h4 class="spm_product_list_element_title"><a href="{var=product.url}" title="{var=product.name}">{var=product.name truncate="50"}</a></h4>
                                  <table class="spm_product_list_element_description" style="height:70px;" height="70"
                                    cellpadding="0" cellspacing="0" border="0" width="100%">
                                    <tbody>
                                        <tr><td><p>{var=product.description_short truncate=100}</p></td></tr>
                                    </tbody>
                                  </table>
                                  <table class="columns spm_product_list_element_prices" cellspacing="0" cellpadding="0">
                                    <tbody>
                                      <tr>
                                        <th s-if="product.price_strike" s-condition="!=" s-value="product.price" class="column" align="center" valign="middle">
                                          <p class="spm_product_list_element_price_strike">{var=product.price_strike}</p>
                                        </th>
                                        <th class="column" valign="middle" align="center"><p class="spm_product_list_element_price">{var=product.price}</p></th>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table class="small-button button spm_product_list_element_button" cellspacing="0" cellpadding="0">
                                    <tbody>
                                      <tr>
                                        <th>
                                          <a href="{var=product.url}" title="${t('templateBuilder.smartProductListDefaultButton')}">
                                          ${t('templateBuilder.smartProductListDefaultButton')}
                                          </a>
                                        </th>
                                      </tr>
                                    </tbody>
                                  </table>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </th>
      </tr>
    </tbody>
  </table>`;
};

const rawWidgetImportZip = () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<table cellspacing="0" cellpadding="0" width="100%"
            id="${widgetId}"
            class="spm_widget_import_zip spm_draggable_widget ${TEMPLATE_TRANSLATE_SPECIFIC}"
            data-widgettype="spm_widget_import_zip"
            style="border-collapse: collapse;"
            data-widgetid="${widgetId}"><tbody><tr>
    <th>${WIDGET_IMPORT_ZIP_PLACEHOLDER_IDENTIFIER}</th></tr></tbody></table>`;
};

export const rawColumnsLayout = (percentages: number[]) => {
  let html = '';

  percentages.forEach((percentage) => {
    const columnId = `${TEMPLATE_COLUMN_IDENTIFIER_PREFIX}_${generateUniqStructureId()}`;
    html += `<th id="${columnId}" class="column spm_droppable_widget spm_droppable" style="width: ${percentage}%;">${rawWidgetPlaceholder()}</th>`;
  });

  return html;
};

export const rawLineWithColumns = (percentages: number[]) => {
  const lineId = `${TEMPLATE_LINE_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<table id="${lineId}"
    class="spm_draggable_row ui-droppable" width="100%" cellspacing="0" cellpadding="0"><tbody><tr><td>
    <table class="row spm_responsive" cellspacing="0" cellpadding="0"><tbody><tr><td>
    <table class="columns" cellspacing="0" cellpadding="0"><tbody><tr>${rawColumnsLayout(percentages)}
    </tr></tbody></table></td></tr></tbody></table></td></tr></tbody>`;
};

export const rawWidgets = (t: Function, additionalConfiguration: Record<string, any>): { [key: string]: () => string } => ({
  [WidgetTypeEnum.SEPARATOR]: rawWidgetSeparator,
  [WidgetTypeEnum.TEXT]: rawWidgetText,
  [WidgetTypeEnum.TITLE]: rawWidgetTitle,
  [WidgetTypeEnum.IMAGE]: rawWidgetImage,
  [WidgetTypeEnum.SOCIAL_MEDIA]: rawWidgetSocialMedia(additionalConfiguration),
  [WidgetTypeEnum.SHARE_SOCIAL_MEDIA]: rawWidgetShareSocialMedia(additionalConfiguration),
  [WidgetTypeEnum.CODE]: rawWidgetCode,
  [WidgetTypeEnum.BUTTON]: rawWidgetButton,
  [WidgetTypeEnum.GDPR_BUTTON]: rawWidgetGdprButton(t),
  [WidgetTypeEnum.SMART_PRODUCT_LIST]: rawWidgetSmartProductList(t),
  [WidgetTypeEnum.IMPORT_ZIP]: () => rawWidgetImportZip(),
  [WidgetTypeEnum.COLUMN]: () => '', // Empty string. Only used to display the widget in the panel. Code of widget is returned by getColumnsWidgetLayouts
  [WidgetTypeEnum.SECTION]: () => '', // Empty string. Only used to display the widget in the panel. Code of widget is returned by getColumnsWidgetLayouts
});
