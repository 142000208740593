<template>
  <div class="col-12 widget-title mb-4">
    {{ widgetName }}
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { StringMap } from '@/types';

export default defineComponent({
  name: 'StatsWidgetTitle',

  props: {
    name: {
      type: String || Object as PropType<StringMap>,
      required: true,
    },
  },

  setup(props: { name: string | StringMap }) {
    const { locale } = useI18n();

    const widgetName = typeof props.name === 'string' ? props.name : ref(props.name[locale.value]);

    return {
      widgetName,
    };
  },
});
</script>

<style scoped lang="scss">
.widget-title {
  font-size: 1.2rem;
  line-height: 1.2rem;
  border-bottom: solid 1px $solitude;
  padding: 15px 10px;
}
</style>
