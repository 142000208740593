import { i18n } from '@/i18n';
import { LINE_HEIGHT_POSSIBLE_VALUES } from '@/components/template-builder/utils/constants';

export default async function getLineHeightList() {
  const { global: { t } } = await i18n;
  return LINE_HEIGHT_POSSIBLE_VALUES.map((lineHeight) => {
    if (lineHeight.label !== lineHeight.value) {
      // eslint-disable-next-line no-param-reassign
      lineHeight.label = t(lineHeight.label);
    }

    return lineHeight;
  });
}
