import { ComponentNameEnum, SpacePropertyEnum } from '@/types';

const getHorizontalSpacesFieldConfig = (property = 'padding', defaultValue = 0) => ({
  componentName: ComponentNameEnum.SPACES,
  options: {
    property,
    [SpacePropertyEnum.LEFT]: {
      defaultValue,
      title: 'templateBuilder.fields.spaceLeft',
    },
    [SpacePropertyEnum.RIGHT]: {
      defaultValue,
      title: 'templateBuilder.fields.spaceRight',
    },
  },
});

export default getHorizontalSpacesFieldConfig;
