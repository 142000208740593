import { BorderRadiusPropertyEnum, ComponentNameEnum } from '@/types';

const getBottomBorderRadiusFieldConfig = (property = 'border-radius', defaultValue = 0) => ({
  componentName: ComponentNameEnum.SPACES,
  options: {
    property,
    [BorderRadiusPropertyEnum.BOTTOM_LEFT]: {
      defaultValue,
      title: 'templateBuilder.fields.radiusBottomLeft',
    },
    [BorderRadiusPropertyEnum.BOTTOM_RIGHT]: {
      defaultValue,
      title: 'templateBuilder.fields.radiusBottomRight',
    },
  },
});

export default getBottomBorderRadiusFieldConfig;
