import { Property } from '@/types';
// eslint-disable-next-line import/no-cycle
import { getTemplateIframeDocument } from '../utils/helpers';
// eslint-disable-next-line import/no-cycle
import { setTranslationInState, removeTranslationInState } from '../utils/translate';

export const setLink = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement|null = template?.querySelector(selector)?.firstElementChild as HTMLElement;
  let link: HTMLAnchorElement = document.createElement('a');
  if (element) {
    if (element.tagName === 'A') {
      link = element.cloneNode(true) as HTMLAnchorElement; // link already exists, we get it
    } else {
      link.innerHTML = element.outerHTML; // create link with title content
    }

    if (property.value) {
      link.setAttribute('href', property.value);
      if (property.value?.includes('redirect_')) { // Link is a template redirection, add data-redirect attribute
        link.setAttribute('data-redirect', property.value?.replace('#redirect_', ''));
      } else {
        link.removeAttribute('data-redirect'); // Remove this attribute if is not a template redirection
      }
      element.replaceWith(link);

      setTranslationInState(link, 'attribute', property.name);
    } else {
      // Remove link
      element.replaceWith(link.firstElementChild as HTMLElement);
      if (link) {
        const key = link.getAttribute('data-spmtranslationid-href');
        if (key) {
          removeTranslationInState(key.replace('LANG_', ''));
        }
      }
    }
  }
};

export const setDataRedirectAttribute = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement|null = template?.querySelector(selector);
  if (element) {
    if (property.value?.includes('redirect_')) {
      element.setAttribute('data-redirect', property.value?.replace('#redirect_', '') ?? '');
    } else {
      element.removeAttribute('data-redirect'); // Remove this attribute if is not a template redirection
    }
  }
};
