<template>
  <label
    class="block mb-2"
    data-test-id="item-list-label"
  >
    {{ t(title) }}
  </label>
  <Button
    data-test-id="button"
    :label="t(buttonLabel, { number: modelValue.length })"
    style="white-space: pre; width: 100%;"
    class="p-button-secondary button-base-item-list"
    @click="displayModal=true"
  />
  <ChooseItemsModal
    :key="name"
    :display-dialog="displayModal"
    :header="title"
    :name="name"
    :fields="fields"
    :items="items"
    :default-filters="defaultFilters"
    @on-close-dialog="displayModal=false"
    @on-choose-items="onChooseItems"
  />
</template>

<script lang="ts">
import {
  defineComponent, PropType, ref, SetupContext,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Button from 'primevue/button';
import ChooseItemsModal from '@/components/modals/ChooseItemsModal.vue';
import {
  SpmTableFilter, StringMap,
} from '@/types';

export default defineComponent({
  name: 'BaseItemList',

  components: {
    Button,
    ChooseItemsModal,
  },

  props: {
    modelValue: {
      type: Array as PropType<StringMap[]>,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    buttonLabel: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    fields: {
      type: Object as PropType<StringMap>,
      required: true,
    },

    items: {
      type: String,
      required: false,
      default: '',
    },

    format: {
      type: Function,
      required: false,
      default: (value: StringMap) => value,
    },

    defaultFilters: {
      type: Array as PropType<SpmTableFilter[]>,
      required: false,
      default: () => [],
    },
  },

  emits: ['update:modelValue'],

  setup(props: { format: Function }, { emit }: SetupContext) {
    const { t } = useI18n();
    const displayModal = ref(false);
    const onChooseItems = (items: StringMap[]) => emit('update:modelValue', [...items.map((item: StringMap) => props.format(item))]);

    return {
      t,
      displayModal,
      onChooseItems,
    };
  },

});
</script>

<style lang="scss" scoped>
.button-base-item-list {
  color: $mid-grey;
  font-size: 0.9rem;
  line-height: 1.2rem;
}
</style>
