<template>
  <div class="select-wrapper">
    <Dropdown
      v-model="currentValue"
      class="col-12 p-0"
      :options="dropdownOptions"
      option-label="label"
      option-value="value"
      @change="emitChangedValue"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  Ref,
  ref,
  watch,
} from 'vue';

import { DropdownOption } from '@/types';
import { useI18n } from 'vue-i18n';
import Dropdown from 'primevue/dropdown';

export default defineComponent({
  name: 'BaseDropdown',

  components: {
    Dropdown,
  },

  props: {
    modelValue: {
      type: [String, Number],
      required: true,
    },

    options: {
      type: Object as PropType<DropdownOption[]>,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, context) {
    const { t } = useI18n();
    const dropdownOptions: Ref<DropdownOption[]> = ref(props.options);
    const currentValue = ref(props.modelValue);

    const emitChangedValue = () => {
      context.emit('update:modelValue', currentValue.value);
    };

    watch(() => props.options, (newValue) => {
      dropdownOptions.value = newValue.map((option) => ({ value: option.value, label: t(option.label) }));
    });

    return {
      dropdownOptions,
      currentValue,
      emitChangedValue,
    };
  },
});
</script>
