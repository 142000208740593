<template>
  <draggable
    class="dragArea"
    :class="`level-${level}`"
    :list="items"
    :item-key="type === 'menu' ? 'label' : 'name'"
    style="width: 100%"
    handle=".move-item-action"
    ghost-class="ghost"
    group="rezrez"
    v-bind="dragOptions"
    :move="checkMove"
  >
    <template #item="{ element, index }">
      <div>
        <div
          class="spm-toolbar-item"
          :class="`level-${level}`"
        >
          <div class="sti-left flex align-items-center">
            <i class="far fa-align-justify move-item-action align-items-center" />
            <LocalizedTextInput
              v-if="typeof element.label !== 'string' && type === 'menu'"
              id="stats_widget_menu_item_name"
              v-model="element.label"
              class="name-item w-full align-items-center"
              :display-translate="true"
              auto-focus
              :default-languages="defaultLanguages"
            />
            <InputText
              v-if="type === 'tabs'"
              v-model="element.name"
              class="name-item align-items-center"
              auto-focus
            />
          </div>
          <div class="sti-right">
            <i
              class="far fa-times remove-item-action"
              @click="removeItem(index)"
            />
          </div>
        </div>
        <div v-if="element.items && level < 3">
          <MenuItemsNestedDraggable
            :items="element.items"
            :level="level+1"
            :type="type"
          />
        </div>
      </div>
    </template>
  </draggable>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
} from 'vue';
import draggable from 'vuedraggable';
import InputText from 'primevue/inputtext';
import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import defaultLanguages from '@/configs/languages';

export default defineComponent({
  name: 'MenuItemsNestedDraggable',

  components: {
    InputText,
    draggable,
    LocalizedTextInput,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },

    level: {
      type: Number,
      required: false,
      default: 1,
    },
  },

  setup(props: { items: any[]; type: string }) {
    const dragOptions = ref({
      animation: 200,
      group: 'description',
      ghostClass: 'ghost',
    });

    const removeItem = (index: number) => {
      // eslint-disable-next-line no-param-reassign,vue/no-mutating-props
      props.items.splice(index, 1);
    };

    const checkMove = (evt: any) => true;

    return {
      removeItem,
      dragOptions,
      checkMove,
      defaultLanguages,
    };
  },
});
</script>

<style lang="scss" scoped>
.dragArea {

  &.level-2 {
    margin-left: 1rem;
  }

  &.level-3 {
    margin-left: 2rem;
  }
}

.spm-toolbar-item {
  border: 1px solid rgba(0,0,0,.125);
  margin-bottom: 1rem !important;
  width: 100%;
  height: 50px;
  padding: .75rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: none;

  &.level-2 {
    width: calc(100% - 1rem);
  }

  &.level-3 {
    width: calc(100% - 3rem);
  }

  &:first-child {
    border-top: 1px solid rgba(0,0,0,.125);
  }

  .sti-left {
    .move-item-action {
      cursor: move;
    }
    .name-item {
      margin-left: 10px;
      height: 2rem !important;
    }
  }

  .sti-right {
    .remove-item-action {
      cursor: pointer;
    }
  }

}
</style>
