import { ComponentNameEnum, AlignValueEnum, BaseButtonFieldConfig } from '@/types';

const getElementAlignFieldConfig = (property = 'align', defaultValue = AlignValueEnum.LEFT): BaseButtonFieldConfig => ({
  componentName: ComponentNameEnum.BUTTON_GROUP,
  options: {
    [AlignValueEnum.LEFT]: {
      title: 'templateBuilder.fields.leftAlign',
      iconClass: 'fa-align-left',
      property,
      defaultValue,
    },
    [AlignValueEnum.CENTER]: {
      title: 'templateBuilder.fields.centerAlign',
      iconClass: 'fa-align-center',
      property,
      defaultValue,
    },
    [AlignValueEnum.RIGHT]: {
      title: 'templateBuilder.fields.rightAlign',
      iconClass: 'fa-align-right',
      property,
      defaultValue,
    },
  },
});

export default getElementAlignFieldConfig;
