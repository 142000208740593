
import { useI18n } from 'vue-i18n';
import { defineComponent, onMounted, ref } from 'vue';

import Avatar from 'primevue/avatar';
import Tag from 'primevue/tag';
import Button from 'primevue/button';

import SpmTable from '@/components/table/SpmTable.vue';
import { SpmTableAction, SpmTableColumns, SpmTableFilter } from '@/types';
import { OperatorType, ShopsSendDomains } from '@/types/generated-types/graphql';
import { findPermission, UserState } from '@/composables/User';
import DomainInstructions from '@/views/shop/DomainInstructions.vue';
import CreateDomain from '@/components/shop/my-domains/CreateDomain.vue';
import { useConfirm } from 'primevue/useconfirm';
import { DeleteShopsSendDomains, ValidateShopDomainByToken, VerifyDomain } from '@/composables/shop/ShopsSendDomains';
import { showToastError, showToastSuccess } from '@/helpers';
import ConfirmDialog from 'primevue/confirmdialog';
import Message from 'primevue/message';

// @see class Menu in node_modules/primevue/menu/Menu.d.ts
interface MenuInterface {
  toggle(event: Event): void;
}

export default defineComponent({
  name: 'MyDomains',
  components: {
    DomainInstructions,
    SpmTable,
    Avatar,
    Tag,
    CreateDomain,
    ConfirmDialog,
    Message,
    Button,
  },

  props: {
    token: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const dataTableKey = ref(0);
    const confirm = useConfirm();
    const idShop = UserState.activeShop?.id;
    const locale = UserState.user.lang;
    const domainValid = ref(false);
    const showMessage = ref(false);
    const childComponentRef = ref();
    const shopId = UserState?.activeShop?.id ?? 0; // todo: to do something for invalid shop
    const actions: SpmTableAction[] = [
      {
        label: t('shop.my_domains.verify_dns'),
        icon: 'far fa-sync',
        callback: async (data: ShopsSendDomains, event: any) => {
          if (!event) return;
          const result = await VerifyDomain(data.id_domain);

          if (result.err || result.data?.id_domain || result.data?.id_domain === 0) {
            childComponentRef.value.reloadData();
          }
        },

        show: () => findPermission('my_domains.verify'),
      },
      {
        label: t('shop.my_domains.delete.actionText'),
        icon: 'far fa-trash',
        callback: (data: ShopsSendDomains, event: any) => {
          if (!event) return;
          confirm.require({
            message: t('shop.my_domains.delete.confirmText'),
            header: t('shop.my_domains.delete.confirmHeader'),
            icon: 'fa-regular fa-square-info',
            acceptClass: 'p-button-danger',
            accept: async () => {
              const result = await DeleteShopsSendDomains([{ id_domain: data.id_domain }]);

              if (result.status) {
                await showToastSuccess('shop.my_domains.delete.success');
                dataTableKey.value += 1;
              } else {
                await showToastError('shop.my_domains.delete.error');
              }
            },
          });
        },

        show: () => findPermission('my_domains.delete'),
      },
    ];
    const columns: SpmTableColumns[] = [
      {
        field: 'domain',
        header: t('shop.my_domains.headers.domain.title'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'domain_verified',
        header: t('shop.my_domains.headers.verification.title'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'text-align:center;',
        type: 'text',
        tooltip: t('shop.my_domains.headers.verification.desc'),
        tooltipIcon: 'fa-solid fa-question fa-xs',
      },
      {
        field: 'authentication_verified',
        header: t('shop.my_domains.headers.authentication.title'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'text-align:center;',
        type: 'text',
        tooltip: t('shop.my_domains.headers.authentication.desc'),
        tooltipIcon: 'fa-solid fa-question fa-xs',
      },
      {
        field: 'alignment_verified',
        header: t('shop.my_domains.headers.alignment.title'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'text-align:center;',
        type: 'text',
        tooltip: t('shop.my_domains.headers.alignment.desc'),
        tooltipIcon: 'fa-solid fa-question fa-xs',
      },
      {
        field: 'tracking_domain_verified',
        header: t('shop.my_domains.headers.tracking.title'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'text-align:center;',
        type: 'text',
        tooltip: t('shop.my_domains.headers.tracking.desc'),
        tooltipIcon: 'fa-solid fa-question fa-xs',
      },
      {
        field: 'id_domain',
        header: t('shop.my_domains.headers.options.title'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'text-align:center;width:10%',
        type: 'action',
        actions,
      },
      {
        field: 'dkim_verified',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
      {
        field: 'public_key',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
      {
        field: 'id_shop',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
      {
        field: 'tracking_domain',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
      {
        field: 'tracking_domain_default_medias',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
      {
        field: 'dns_verification_key',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
      {
        field: 'alignment_domain',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
    ];
    const persistentFilters: SpmTableFilter[] = [{ field: 'id_shop', value: shopId, operator: OperatorType.Equals }];

    const displayDialog = ref<boolean>(false);
    const sendDomainsData = ref<ShopsSendDomains>();
    const type = ref('');

    const closeDialog = (refresh: boolean) => {
      displayDialog.value = false;
      if (refresh) childComponentRef.value.reloadData();
    };

    const showDialog = (data: ShopsSendDomains, dialogType: string) => {
      displayDialog.value = true;
      sendDomainsData.value = data;
      type.value = dialogType;
    };

    onMounted(async () => {
      if (props.token && props.token !== '') {
        const result = await ValidateShopDomainByToken(props.token, idShop ?? 0);

        if (result.status && result.id) {
          domainValid.value = true;
          dataTableKey.value += 1;
        }

        showMessage.value = true;
        setTimeout(() => {
          showMessage.value = false;
        }, 5000);
      }
    });

    return {
      t,
      columns,
      persistentFilters,
      findPermission,
      showDialog,
      sendDomainsData,
      displayDialog,
      type,
      dataTableKey,
      closeDialog,
      domainValid,
      showMessage,
      locale,
      childComponentRef,
    };
  },
});
