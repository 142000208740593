import {
  ActionType, DatatablesExportsInputItem, ExportStatus, ResolverType,
} from '@/types/generated-types/graphql';
import { StatsWidgetDataConfiguration } from '@/components/stats/StatsWidgetData.vue';
import { UserState } from '@/composables/User';
import { Insert, ListSettings } from '../GraphQL';

export default async function InsertDatatablesExport(idShop: number,
  settings: ListSettings|StatsWidgetDataConfiguration, resolverName: string, headers: Record<string, string>[], fileName: string, locale: string,
  resolverType: ResolverType, selectedIds: number[], actionType: ActionType| null, indexField: string) {
  let selectedIdsStr = null;
  if (selectedIds && selectedIds.length > 0) {
    selectedIdsStr = selectedIds.join(',');
  }

  const data: DatatablesExportsInputItem = {
    id_shop: idShop,
    filters: JSON.stringify(settings),
    resolver_name: resolverName,
    headers: JSON.stringify(headers),
    file_name: fileName,
    id_user: UserState.user?.id,
    user_type: UserState.user?.userType,
    locale,
    resolver_type: resolverType,
    selected_ids: selectedIdsStr,
    table_id: indexField,
    action_type: actionType,
    status: ExportStatus.Scheduled,
    date_added: new Date(),
  };

  return Insert<DatatablesExportsInputItem>(
    {
      name: 'DatatablesExports',
      input: [data],
      type: 'DatatablesExportsInput',
    },
  );
}
