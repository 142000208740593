import * as cheerio from 'cheerio';

import { TRANSLATION_MARKUP } from '@/components/template-builder/utils/constants';

// eslint-disable-next-line import/no-cycle
import { getTemplateIframeDocument } from '../utils/helpers';

const getContent = (selector: string) => {
  const template = getTemplateIframeDocument();
  const htmlElement: HTMLElement|null = template?.querySelector(selector);

  if (htmlElement) {
    const $ = cheerio.load(htmlElement.innerHTML);

    // Select all spmtranslation elements
    $(TRANSLATION_MARKUP).each((_, element) => {
      // Get the content inside the spmtranslation tag
      const content = $(element).html();

      if (content) {
        // Replace the spmtranslation element with its content
        $(element).replaceWith(content);
      }
    });

    // Return the modified HTML as a string
    return $('body').html();
  }
  return '';
};

export default getContent;
