
import {
  defineComponent,
  computed,
  PropType,
} from 'vue';

import SimpleColorPicker from '@/components/fields/SimpleColorPicker.vue';
import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Popup',

  components: {
    LocalizedTextInput,
    SimpleColorPicker,
    FieldErrors,
  },

  props: {
    modelValue: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },

    errors: {
      type: Object,
      required: true,
    },

    componentFieldErrorsKey: {
      type: Number,
      required: true,
    },

  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const field = computed({
      get() {
        return props.modelValue;
      },

      set(newValue) {
        emit('update:modelValue', newValue);
      },
    });

    return {
      t,
      field,
    };
  },
});
