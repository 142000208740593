// eslint-disable-next-line import/no-cycle
import {
  ComponentData,
  ComponentMetadata,
  ErrorConfigForm,
  NumberRange,
  Period,
  Picker,
} from '@/types/automated-scenarios';
// eslint-disable-next-line import/no-cycle
import { decreaseNumberOfBranchesOfAnOperator } from '@/composables/AutomatedScenarios';
import useVuelidate from '@vuelidate/core';
import {
  integer, maxLength, minLength, required, requiredIf,
} from '@vuelidate/validators';
import { computed } from 'vue';
// eslint-disable-next-line import/no-cycle
import { atLeastOneSegmentValidator, includesValuesValidator } from '@/helpers/CustomValidator';
// eslint-disable-next-line import/no-cycle
import { TypeCampaignEnum } from '@/types';

export interface FiltreCurrentCartFilters {
  filterproductinbag?: Picker;
  filterproductcategoryinbag?: Picker;
  filterproductmanufacturerinbag?: Picker;
  filtercartamount?: NumberRange;
}

export interface FiltreCurrentCartData {
  custom_title: string;
  custom_description: string;
  outputs_number: number;
  initial_number_outputs?: null|number; // for updating outputs
  grant_additional_delay: string;
  extra_time: Period;
  inclusion: FiltreCurrentCartFilters;
  exclusion: FiltreCurrentCartFilters;
}

const FiltreCurrentCartMetadata: ComponentMetadata<FiltreCurrentCartData> = {
  Meta: {
    kind: 'filtre',
    id: 'boxfiltre_1',
    label: 'automatedScenarios.filters.currentCart',
    icon: 'fa-cogs',
    hasConfiguration: true,
    persistent: [
      { segmentId: 'filterproductinbag', boxId: 'boxstatuscommand' },
      { segmentId: 'filterproductcategoryinbag', boxId: 'boxstatuscommand' },
      { segmentId: 'filterproductmanufacturerinbag', boxId: 'boxstatuscommand' },
      { segmentId: 'filtercartamount', boxId: 'boxstatuscommand' },
    ],
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxperiodiclaunching', 'boxfiltre_2',
        ],
      },
      bulk: {
        next_operators: [
          'boxfiltre_2',
        ],
      },
    },
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxscrollpage',
            'boxvisitpage',
            'boxcustomjs',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            { boxId: 'boxquitsite', attribute: 'multiplebox_type', value: '1' },
          ],
        },
      },
    },
    component: 'FiltreCurrentCart',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
  },
  Create(data: FiltreCurrentCartData = {
    custom_title: '',
    custom_description: '',
    outputs_number: 1,
    grant_additional_delay: '1',
    extra_time: {
      value: 1,
      unit: 'DAY',
    },
    inclusion: {},
    exclusion: {},
  }): ComponentData<FiltreCurrentCartData> {
    return {
      id: 'boxfiltre_1',
      form_name: 'boxfiltre_1',
      logo_box: 'fa-cogs',
      ...data,
    };
  },
  async Validate(data: FiltreCurrentCartData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      custom_title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(45),
      },
      outputs_number: {
        required,
        includesValuesValidator: includesValuesValidator([1, 2]),
      },
      grant_additional_delay: {
        required,
        includesValuesValidator: includesValuesValidator(['0', '1']),
      },
      extra_time: {
        value: {
          required: requiredIf(() => data.grant_additional_delay === '1'),
          integer,
        },
        unit: {
          required: requiredIf(() => data.grant_additional_delay === '1'),
          includesValuesValidator: includesValuesValidator(['SECOND', 'MINUTE', 'HOUR', 'DAY']),
        },
      },
      inclusion: {
        required: atLeastOneSegmentValidator(data.exclusion),
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
  BeforeInsertData(data: FiltreCurrentCartData): FiltreCurrentCartData {
    const dataToReturn: FiltreCurrentCartData = data;
    const { initial_number_outputs } = dataToReturn;

    if (typeof dataToReturn.initial_number_outputs !== 'undefined') {
      delete dataToReturn.initial_number_outputs;
    }

    if (initial_number_outputs !== null && initial_number_outputs !== undefined
    && data.outputs_number < initial_number_outputs) {
      /* En cas de dimunition du nombre de branches, on supprime la branche "non" */
      decreaseNumberOfBranchesOfAnOperator(['output_2']);
    }

    return dataToReturn;
  },
  Outputs(data: FiltreCurrentCartData): number {
    return data.outputs_number;
  },
  Label(data: FiltreCurrentCartData): string {
    return data.custom_title;
  },
};

export default FiltreCurrentCartMetadata;
