
import { useI18n } from 'vue-i18n';
import { defineComponent, reactive, ref } from 'vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { emailFormatValidator } from '@/helpers/CustomValidator';
import useVuelidate from '@vuelidate/core';
import { showToastError, showToastSuccess } from '@/helpers';
import BaseTextInput from '@/components/fields/BaseTextInput.vue';
import { useRouter } from 'vue-router';
import { nestPost } from '@/composables/nestApi';

export default defineComponent({
  name: 'ForgotPassword',
  components: {
    BaseTextInput,
    SpmButton,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const isLoadingForm = ref(false);
    const formValidation = ref<any>({});

    const userInfo = reactive({
      email: '',
    });

    const validateRegisterFormData = async (): Promise<ErrorConfigForm> => {
      const rules = {
        email: {
          emailFormatValidator: emailFormatValidator(),
        },
      };

      const v$ = useVuelidate(rules, userInfo);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    const validateRegisterForm = async () => {
      isLoadingForm.value = true;
      const validation = await validateRegisterFormData();
      if (!validation.success) {
        formValidation.value = validation.validate;
        isLoadingForm.value = false;
      } else {
        try {
          const result = await nestPost('v4', '/user/forgot-password', {}, {
            email: userInfo.email,
          });

          if (result && result.success) {
            await showToastSuccess(t('user.password.success'));
            await router.push('/auth/login');
          }
        } catch (e) {
          await showToastError(t('errorMessages.GENERIC_ERROR'));
        }

        isLoadingForm.value = false;
      }
    };

    return {
      t,
      formValidation,
      userInfo,
      isLoadingForm,
      validateRegisterForm,
    };
  },
});
