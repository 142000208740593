<template>
  <div
    v-if="modelType === 'none' || true"
  >
    <Dialog
      id="stats-dialog-type"
      v-model:visible="displayDialog"
      :modal="true"
      :draggable="false"
      :closable="true"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      @hide="handleCloseChoosingModelType"
    >
      <template #header>
        <h3>{{ t('stats.dialogTypeStats.header') }}</h3>
      </template>

      <div class="grid ">
        <div
          v-for="type in modelTypes"
          :key="type"
          class="col-6"
        >
          <Button
            type="button"
            class="p-button-outlined p-button-success button-dialog-type"
            @click="tmpCreateRapport(type)"
          >
            <div class="icon">
              <i
                :class="t(`stats.dialogTypeStats.types.${type}.icon`)"
              />
            </div>
            <div class="legend">
              {{ t(`stats.dialogTypeStats.types.${type}.label`) }}
            </div>
          </Button>
        </div>
      </div>
    </Dialog>
  </div>
  <div
    v-else
  >
    <EditorModal
      :title="t('stats.dialogTypeStats.title')"
      :show-close-button="true"
      @on-close-modal="closeModal"
    >
      <template
        #content
      >
        <div class="p-4">
          <div class="grid p-fluid">
            <div class="col-5">
              <div class="p-inputgroup">
                <InputText
                  v-model="searchTemplatesInput"
                  class="form-control"
                  type="text"
                  :placeholder="t('stats.dialogTypeStats.searchPlaceholder')"
                  @keyup.enter="handleSearchStatsModels(false)"
                />
                <Button
                  icon="far fa-search"
                  :label="t('stats.dialogTypeStats.buttons.search')"
                  class="p-button-success"
                  @click="handleSearchStatsModels(false)"
                />
              </div>
            </div>
          </div>
          <div
            class="mt-3"
            style="text-align: center"
          >
            <Loader v-if="isLoading" />
            <div
              v-else
              class="flex justify-content-between"
            >
              <div
                v-if="list.length"
                class="grid col-12"
              >
                <div
                  v-for="record of list"
                  :key="record.id_users_pages_configuration"
                  class="lg:col-3 md:col-4 sm:col-6 mb-3 p-4"
                >
                  <div>
                    <p style="margin-bottom: 1.5em">
                      <b>
                        {{ record.page_name }}
                      </b>
                    </p>
                    <div
                      class="template_preview_container"
                    >
                      <div class="animate_container">
                        <div
                          v-for="(row, index) of record.structure"
                          :key="index"
                          class="grid list-columns-structure"
                        >
                          <div
                            v-for="(column, colIndex) of row.column"
                            :key="colIndex"
                            class="column-structure"
                            :class="`col-${column}`"
                          >
                            <div class="column-structure-drawing" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="action-buttons">
                      <Button
                        v-if="findPermission('stats_report.preview')"
                        :label="t('stats.dialogTypeStats.buttons.preview')"
                        style="float:left"
                        class="p-button-sm p-button-sm p-button-secondary"
                        @click="handlePreviewImage(record.id_users_pages_configuration, record.page_name)"
                      />
                      <Button
                        :label="t('stats.dialogTypeStats.buttons.chooseModel')"
                        style="float:right"
                        class="p-button-sm p-button-success"
                        @click="handleChooseModel(record.id_users_pages_configuration, record.page_name, record.configuration, record.extra_data)"
                      />
                    </div>
                  </div>
                </div>
                <div class="grid col-12">
                  <Button
                    class="center mt-2 mb-2"
                    :label="t('stats.dialogTypeStats.buttons.loadMore')"
                    @click="handleSearchStatsModels(true)"
                  />
                </div>
              </div>
              <div
                v-else
                class="flex justify-content-between"
              >
                <span>{{ t('templateSelector.list.empty') }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </EditorModal>
    <Dialog
      id="stats-dialog-type"
      v-model:visible="displayPreviewModal"
      :modal="true"
      :closable="false"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '75vw' }"
      @on-close-preview-modal="handleClosePreview"
    >
      <template #header>
        <div
          class="grid col-12"
        >
          <div
            class="col-11 pt-3 pb-1"
          >
            <h3>
              {{ pageName }}
            </h3>
          </div>
          <div
            class="col-1 pt-3 pb-1 text-right"
          >
            <Button
              icon="far fa-times"
              class="p-button-secondary"
              @click="handleClosePreview"
            />
          </div>
        </div>
      </template>
      <StatsCustoms
        :page-id="pageId"
        :predefined="true"
      />
    </Dialog>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import {
  ref, Ref, SetupContext, onMounted, defineComponent,
} from 'vue';
import EditorModal from '@/components/modals/EditorModal.vue';
import InputText from 'primevue/inputtext';
import Loader from '@/components/layout/Loader.vue';
import { findPermission, UserState } from '@/composables/User';
import Dialog from 'primevue/dialog';
import {
  UsersPagesConfigurationInputItem,
  UsersPagesConfiguration,
} from '@/types/generated-types/graphql';
import { StatsType, UserTypeEnum } from '@/types';
import Button from 'primevue/button';
import {
  fetchUsersPagesConfMainList,
  DuplicateUsersPagesConfiguration,
} from '@/composables/Statistics/usersPagesConfiguration';
import { showToastError, showToastSuccess } from '@/helpers';
import StatsCustoms from '@/views/stats/StatsCustoms.vue';
import { store } from '@/store';

type struct = {
  row: number;
  column: number[];
}
export default defineComponent({
  name: 'StatsModelSelector',

  components: {
    StatsCustoms,
    EditorModal,
    InputText,
    Loader,
    Dialog,
    Button,
  },

  props: {
    statsModelType: {
      type: String,
      required: true,
      default: 'category',
    },
  },

  emits: {
    'on-close-dialog': null,
    'on-choose-template': Object,
    'on-preview-template': Object,
  },

  setup(props: { statsModelType: string }, context: SetupContext) {
    const { t } = useI18n();
    const displayDialog = ref(true);
    const modelTypes = [StatsType.STATS, StatsType.EMAIL];
    const isLoading = ref(false);
    const list: Ref<UsersPagesConfiguration[]> = ref([]);
    const idUser = ref(UserState.user.id);
    const modelType = ref(props.statsModelType);
    const displayPreviewModal = ref(false);
    const searchTemplatesInput = ref('');
    const pageId = ref(0);
    const pageName = ref('');
    let limit = 12;
    let structure: struct[] = [];

    const getStructureArray = (items: Array<Record<string, any>>) => {
      items.forEach((item: any, index: number) => {
        const newRow: struct = {
          row: index,
          column: item.layout.split('-'),
        };

        const layout = item.layout.split('-');
        layout.forEach((column: string) => {
          newRow.column.push(Number(column));
        });

        structure.push(newRow);
      });
    };

    const getStatsModelList = async (loadMore: boolean) => {
      if (modelType.value !== 'none') {
        isLoading.value = true;
        const result = await fetchUsersPagesConfMainList(modelType.value, searchTemplatesInput.value, loadMore ? limit : 12);
        result.items.forEach((item) => {
          const config = JSON.parse(item.configuration);
          structure = [];
          // getRowsAndColumns(config);
          getStructureArray(config);
          // eslint-disable-next-line no-param-reassign
          item.structure = structure;
        });
        if (result.items && !result.err) {
          list.value = result.items;
          isLoading.value = false;
        }
      }
    };

    const closeModal = () => {
      context.emit('on-close-dialog');
    };

    const setModelType = async (type: string) => {
      modelType.value = type;
      await getStatsModelList(false);
    };

    const handleChooseModel = async (idUserPageConf: number, name: string, config: string, extraData: any) => {
      let adminUserId = null;
      if (UserState.user.userType === UserTypeEnum.ADMIN) {
        adminUserId = UserState.user.id;
      } else if (UserState.activeShop) {
        adminUserId = UserState.activeShop?.idUser;
      }

      const usersPagesConfInput: UsersPagesConfigurationInputItem = {
        owner: 'user',
        page_name: name,
        navigation_name: name,
        admin_user_id: adminUserId,
        created_by_user_id: UserState.user.id,
        created_by_user_type: UserState.user.userType,
        configuration: config,
        extra_data: extraData,
        type: modelType.value,
        date_creation: new Date().toLocaleString('en-US', { timeZone: 'Europe/Paris' }),
        date_modification: new Date().toLocaleString('en-US', { timeZone: 'Europe/Paris' }),
      };

      const result = await DuplicateUsersPagesConfiguration([usersPagesConfInput]);
      if (!result.status || !result.id || result.err) {
        await showToastError(t('stats.pages.duplicate.error'));
        store.commit('general/hideTheSpinner');
      } else {
        await showToastSuccess(t('stats.pages.duplicate.success'));
        context.emit('on-choose-template', result.id);
      }
    };

    const tmpCreateRapport = async (type: string) => {
      store.commit('general/showTheSpinner');
      modelType.value = type;
      await getStatsModelList(false);
      if (list.value && list.value.length === 1) {
        await handleChooseModel(list.value[0].id_users_pages_configuration, list.value[0].page_name, list.value[0].configuration, list.value[0]?.extra_data);
      }
      store.commit('general/hideTheSpinner');
    };

    const handleSearchStatsModels = async (loadMore: boolean) => {
      limit += 12;
      await getStatsModelList(loadMore);
    };

    const handlePreviewImage = async (idUserPageConf: number, name: string) => {
      pageId.value = idUserPageConf;
      pageName.value = name;
      displayPreviewModal.value = true;
      context.emit('on-preview-template', idUserPageConf, name);
    };

    const handleCloseChoosingModelType = () => {
      context.emit('on-close-dialog');
    };

    const handleClosePreview = () => {
      displayPreviewModal.value = false;
    };

    onMounted(async () => {
      await getStatsModelList(false);
    });

    return {
      t,
      displayDialog,
      modelType,
      modelTypes,
      isLoading,
      setModelType,
      handleSearchStatsModels,
      handleChooseModel,
      list,
      handlePreviewImage,
      handleClosePreview,
      displayPreviewModal,
      handleCloseChoosingModelType,
      closeModal,
      searchTemplatesInput,
      pageId,
      pageName,
      findPermission,
      tmpCreateRapport,
    };
  },
});
</script>

<style scoped lang="scss">
$img-size: 12rem;

.button-dialog-type {
  width: 100%;
  height: 7rem;
  display: block;
  position: relative;

  .icon {
    font-size: 2rem;
  }

  .legend {
    font-size: 1em;
    color: black;
  }
}

.button-dialog-type {
  transition: all 0.5s;
  position: relative;
}
.button-dialog-type::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(134, 128, 128, 0.1);
  transition: all 0.3s;
}
.button-dialog-type:hover::before {
  opacity: 0 ;
  transform: scale(0.5,0.5);
}
.button-dialog-type::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border: 1px solid rgba(13, 121, 104, 0.5);
  transform: scale(1.2,1.2);
}
.button-dialog-type:hover::after {
  opacity: 1;
  transform: scale(1,1);
}

.template-image {
  width: $img-size;
  height: $img-size;
  object-fit: cover;
  object-position: bottom;
}

.preview-container {
  position: relative;
  width: $img-size;
  height: $img-size;
  margin: 1rem auto !important;
  &:hover {
    &::after {
      content: '';
      background: rgba(0, 0, 0, .3);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .p-button {
      display: block;
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
    }
  }
  .p-button {
    display: none;
  }
}

.wrapper {
  height: 100%;
}

.wrapper,
.content-wrapper {
  min-height: 100%;
}

.content {
  padding-top: 8rem;
  flex-grow: 1;
}

.template_search_group {
  margin: 0 5px 0 0;
  max-width: 800px;
}
.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0
}

.chooser-body {
  width: calc(100% - 40px);
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.animate_container {

  &:hover {
    animation: MoveUpDown 10s linear infinite;
    position: relative;
    left: 0;
    bottom: 0;
  }
}

.center {
  margin:0 auto;
  display:block;
}

  .template_preview_container {
    padding: 1rem 1.5rem;
    overflow: clip;
    .animate_container {

      .list-columns-structure {
        flex-wrap: unset;
        margin: 0;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        .column-structure {
          flex-direction: column;
          background-color: #f1f5f9;
          border-radius: 3px;

          span {
            font-size: 0.8rem !important;
            margin-top: 7px;
          }
        }
      }
    }
  }

.column-structure {
  padding-right: 1rem;
  padding-left: 1rem;

  .column-structure-drawing {
    background-color: #7db081;
    border-radius: 0.25rem;
    height: 2rem;
  }
}

.template_preview_container {
  width:100%;
  background-size: 100% auto;
  height:450px;
  text-align: center;
  padding: 2em;
  -webkit-flex:0 0 16%;
  -ms-flex: 0 0 16%;
  flex: 0 0 16%;
  background-position: center top;
}

@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100px);
  }
}
</style>
