export const languages = [
  { label: 'Afrikanns', code: 'AF' },
  { label: 'Albanian', code: 'SQ' },
  { label: 'Arabic', code: 'AR' },
  { label: 'Armenian', code: 'HY' },
  { label: 'Basque', code: 'EU' },
  { label: 'Bengali', code: 'BN' },
  { label: 'Bulgarian', code: 'BG' },
  { label: 'Catalan', code: 'CA' },
  { label: 'Cambodian', code: 'KM' },
  { label: 'Chinese (Mandarin)', code: 'ZH' },
  { label: 'Croation', code: 'HR' },
  { label: 'Czech', code: 'CS' },
  { label: 'Danish', code: 'DA' },
  { label: 'Dutch', code: 'NL' },
  { label: 'English', code: 'EN' },
  { label: 'Estonian', code: 'ET' },
  { label: 'Fiji', code: 'FJ' },
  { label: 'Finnish', code: 'FI' },
  { label: 'France', code: 'FR' },
  { label: 'Georgian', code: 'KA' },
  { label: 'German', code: 'DE' },
  { label: 'Greek', code: 'EL' },
  { label: 'Gujarati', code: 'GU' },
  { label: 'Hebrew', code: 'HE' },
  { label: 'Hindi', code: 'HI' },
  { label: 'Hungarian', code: 'HU' },
  { label: 'Icelandic', code: 'IS' },
  { label: 'Indonesian', code: 'ID' },
  { label: 'Irish', code: 'GA' },
  { label: 'Italian', code: 'IT' },
  { label: 'Indian', code: 'IN' },
  { label: 'Japanese', code: 'JP' },
  { label: 'Javanese', code: 'JW' },
  { label: 'Korean', code: 'KO' },
  { label: 'Latin', code: 'LA' },
  { label: 'Latvian', code: 'LV' },
  { label: 'Lithuanian', code: 'LT' },
  { label: 'Macedonian', code: 'MK' },
  { label: 'Malay', code: 'MS' },
  { label: 'Malayam', code: 'ML' },
  { label: 'Maltese', code: 'MT' },
  { label: 'Maori', code: 'MI' },
  { label: 'Marathi', code: 'MR' },
  { label: 'Mongolian', code: 'MN' },
  { label: 'Nepali', code: 'NE' },
  { label: 'Norwegian', code: 'NO' },
  { label: 'Persian', code: 'FA' },
  { label: 'Polish', code: 'PL' },
  { label: 'Portuguese', code: 'PT' },
  { label: 'Punjabi', code: 'PA' },
  { label: 'Quechua', code: 'QU' },
  { label: 'Romanian', code: 'RO' },
  { label: 'Russian', code: 'RU' },
  { label: 'Samoan', code: 'SM' },
  { label: 'Serbian', code: 'SR' },
  { label: 'Slovak', code: 'SK' },
  { label: 'Slovenian', code: 'SL' },
  { label: 'Spainish', code: 'ES' },
  { label: 'Swahili', code: 'SW' },
  { label: 'Swedish', code: 'SV' },
  { label: 'Tamil', code: 'TA' },
  { label: 'Tatar', code: 'TT' },
  { label: 'Telegu', code: 'TE' },
  { label: 'Thai', code: 'TH' },
  { label: 'Tibetan', code: 'BO' },
  { label: 'Tonga', code: 'TO' },
  { label: 'Turkish', code: 'TR' },
  { label: 'Ukranian', code: 'UK' },
  { label: 'Urdu', code: 'UR' },
  { label: 'Uzbek', code: 'UZ' },
  { label: 'Vietnamese', code: 'VI' },
  { label: 'Welsh', code: 'CY' },
  { label: 'Xhosa', code: 'XH' },
];

export const countries = [
  {
    icon: '/img/flag/af.png',
    label: 'Afghanistan',
    code: 'AF',
  },
  { icon: '/img/flag/ax.png', label: 'Åland Islands', code: 'AX' },
  { icon: '/img/flag/al.png', label: 'Albania', code: 'AL' },
  { icon: '/img/flag/dz.png', label: 'Algeria', code: 'DZ' },
  { icon: '/img/flag/as.png', label: 'American Samoa', code: 'AS' },
  { icon: '/img/flag/ad.png', label: 'Andorra', code: 'AD' },
  { icon: '/img/flag/ao.png', label: 'Angola', code: 'AO' },
  { icon: '/img/flag/ai.png', label: 'Anguilla', code: 'AI' },
  { icon: '/img/flag/aq.png', label: 'Antarctica', code: 'AQ' },
  { icon: '/img/flag/ag.png', label: 'Antigua and Barbuda', code: 'AG' },
  { icon: '/img/flag/ar.png', label: 'Argentina', code: 'AR' },
  { icon: '/img/flag/am.png', label: 'Armenia', code: 'AM' },
  { icon: '/img/flag/aw.png', label: 'Aruba', code: 'AW' },
  { icon: '/img/flag/au.png', label: 'Australia', code: 'AU' },
  { icon: '/img/flag/at.png', label: 'Austria', code: 'AT' },
  { icon: '/img/flag/az.png', label: 'Azerbaijan', code: 'AZ' },
  { icon: '/img/flag/bs.png', label: 'Bahamas', code: 'BS' },
  { icon: '/img/flag/bh.png', label: 'Bahrain', code: 'BH' },
  { icon: '/img/flag/bd.png', label: 'Bangladesh', code: 'BD' },
  { icon: '/img/flag/bb.png', label: 'Barbados', code: 'BB' },
  { icon: '/img/flag/by.png', label: 'Belarus', code: 'BY' },
  { icon: '/img/flag/be.png', label: 'Belgium', code: 'BE' },
  { icon: '/img/flag/bz.png', label: 'Belize', code: 'BZ' },
  { icon: '/img/flag/bj.png', label: 'Benin', code: 'BJ' },
  { icon: '/img/flag/bm.png', label: 'Bermuda', code: 'BM' },
  { icon: '/img/flag/bt.png', label: 'Bhutan', code: 'BT' },
  { icon: '/img/flag/bo.png', label: 'Bolivia', code: 'BO' },
  { icon: '/img/flag/ba.png', label: 'Bosnia and Herzegovina', code: 'BA' },
  { icon: '/img/flag/bw.png', label: 'Botswana', code: 'BW' },
  { icon: '/img/flag/bv.png', label: 'Bouvet Island', code: 'BV' },
  { icon: '/img/flag/br.png', label: 'Brazil', code: 'BR' },
  { icon: '/img/flag/bq.png', label: 'British Antarctic Territory', code: 'BQ' },
  { icon: '/img/flag/io.png', label: 'British Indian Ocean Territory', code: 'IO' },
  { icon: '/img/flag/vg.png', label: 'British Virgin Islands', code: 'VG' },
  { icon: '/img/flag/bn.png', label: 'Brunei', code: 'BN' },
  { icon: '/img/flag/bg.png', label: 'Bulgaria', code: 'BG' },
  { icon: '/img/flag/bf.png', label: 'Burkina Faso', code: 'BF' },
  { icon: '/img/flag/bi.png', label: 'Burundi', code: 'BI' },
  {
    icon: '/img/flag/kh.png',
    label: 'Cambodia',
    code: 'KH',
  },
  {
    icon: '/img/flag/cm.png',
    label: 'Cameroon',
    code: 'CM',
  },
  {
    icon: '/img/flag/ca.png',
    label: 'Canada',
    code: 'CA',
  },
  {
    icon: '/img/flag/00.png',
    label: 'Canton and Enderbury Islands',
    code: 'CT',
  },
  {
    icon: '/img/flag/cv.png',
    label: 'Cape Verde',
    code: 'CV',
  },
  {
    icon: '/img/flag/ky.png',
    label: 'Cayman Islands',
    code: 'KY',
  },
  {
    icon: '/img/flag/cf.png',
    label: 'Central African Republic',
    code: 'CF',
  },
  {
    icon: '/img/flag/td.png',
    label: 'Chad',
    code: 'TD',
  },
  {
    icon: '/img/flag/cl.png',
    label: 'Chile',
    code: 'CL',
  },
  {
    icon: '/img/flag/cn.png',
    label: 'China',
    code: 'CN',
  },
  {
    icon: '/img/flag/cx.png',
    label: 'Christmas Island',
    code: 'CX',
  },
  {
    icon: '/img/flag/cc.png',
    label: 'Cocos [Keeling] Islands',
    code: 'CC',
  },
  {
    icon: '/img/flag/co.png',
    label: 'Colombia',
    code: 'CO',
  },
  {
    icon: '/img/flag/km.png',
    label: 'Comoros',
    code: 'KM',
  },
  {
    icon: '/img/flag/cg.png',
    label: 'Congo - Brazzaville',
    code: 'CG',
  },
  {
    icon: '/img/flag/cd.png',
    label: 'Congo - Kinshasa',
    code: 'CD',
  },
  {
    icon: '/img/flag/ck.png',
    label: 'Cook Islands',
    code: 'CK',
  },
  {
    icon: '/img/flag/cr.png',
    label: 'Costa Rica',
    code: 'CR',
  },
  {
    icon: '/img/flag/ci.png',
    label: 'Côte d’Ivoire',
    code: 'CI',
  },
  {
    icon: '/img/flag/hr.png',
    label: 'Croatia',
    code: 'HR',
  },
  {
    icon: '/img/flag/cu.png',
    label: 'Cuba',
    code: 'CU',
  },
  {
    icon: '/img/flag/cy.png',
    label: 'Cyprus',
    code: 'CY',
  },
  {
    icon: '/img/flag/cz.png',
    label: 'Czech Republic',
    code: 'CZ',
  },
  {
    icon: '/img/flag/dk.png',
    label: 'Denmark',
    code: 'DK',
  },
  {
    icon: '/img/flag/dj.png',
    label: 'Djibouti',
    code: 'DJ',
  },
  {
    icon: '/img/flag/dm.png',
    label: 'Dominica',
    code: 'DM',
  },
  {
    icon: '/img/flag/do.png',
    label: 'Dominican Republic',
    code: 'DO',
  },
  {
    icon: '/img/flag/00.png',
    label: 'Dronning Maud Land',
    code: 'NQ',
  },
  {
    icon: '/img/flag/00.png',
    label: 'East Germany',
    code: 'DD',
  },
  {
    icon: '/img/flag/ec.png',
    label: 'Ecuador',
    code: 'EC',
  },
  {
    icon: '/img/flag/eg.png',
    label: 'Egypt',
    code: 'EG',
  },
  {
    icon: '/img/flag/sv.png',
    label: 'El Salvador',
    code: 'SV',
  },
  {
    icon: '/img/flag/gq.png',
    label: 'Equatorial Guinea',
    code: 'GQ',
  },
  {
    icon: '/img/flag/er.png',
    label: 'Eritrea',
    code: 'ER',
  },
  {
    icon: '/img/flag/ee.png',
    label: 'Estonia',
    code: 'EE',
  },
  {
    icon: '/img/flag/et.png',
    label: 'Ethiopia',
    code: 'ET',
  },
  {
    icon: '/img/flag/fk.png',
    label: 'Falkland Islands',
    code: 'FK',
  },
  {
    icon: '/img/flag/fo.png',
    label: 'Faroe Islands',
    code: 'FO',
  },
  {
    icon: '/img/flag/fj.png',
    label: 'Fiji',
    code: 'FJ',
  },
  {
    icon: '/img/flag/fi.png',
    label: 'Finland',
    code: 'FI',
  },
  {
    icon: '/img/flag/fr.png',
    label: 'France',
    code: 'FR',
  },
  {
    icon: '/img/flag/gf.png',
    label: 'French Guiana',
    code: 'GF',
  },
  {
    icon: '/img/flag/pf.png',
    label: 'French Polynesia',
    code: 'PF',
  },
  {
    icon: '/img/flag/00.png',
    label: 'French Southern and Antarctic Territories',
    code: 'FQ',
  },
  {
    icon: '/img/flag/tf.png',
    label: 'French Southern Territories',
    code: 'TF',
  },
  {
    icon: '/img/flag/ga.png',
    label: 'Gabon',
    code: 'GA',
  },
  {
    icon: '/img/flag/gm.png',
    label: 'Gambia',
    code: 'GM',
  },
  {
    icon: '/img/flag/ge.png',
    label: 'Georgia',
    code: 'GE',
  },
  {
    icon: '/img/flag/de.png',
    label: 'Germany',
    code: 'DE',
  },
  {
    icon: '/img/flag/gh.png',
    label: 'Ghana',
    code: 'GH',
  },
  {
    icon: '/img/flag/gi.png',
    label: 'Gibraltar',
    code: 'GI',
  },
  {
    icon: '/img/flag/gr.png',
    label: 'Greece',
    code: 'GR',
  },
  {
    icon: '/img/flag/gl.png',
    label: 'Greenland',
    code: 'GL',
  },
  {
    icon: '/img/flag/gd.png',
    label: 'Grenada',
    code: 'GD',
  },
  {
    icon: '/img/flag/gp.png',
    label: 'Guadeloupe',
    code: 'GP',
  },
  {
    icon: '/img/flag/gu.png',
    label: 'Guam',
    code: 'GU',
  },
  {
    icon: '/img/flag/gt.png',
    label: 'Guatemala',
    code: 'GT',
  },
  {
    icon: '/img/flag/gg.png',
    label: 'Guernsey',
    code: 'GG',
  },
  {
    icon: '/img/flag/gn.png',
    label: 'Guinea',
    code: 'GN',
  },
  {
    icon: '/img/flag/gw.png',
    label: 'Guinea-Bissau',
    code: 'GW',
  },
  {
    icon: '/img/flag/gy.png',
    label: 'Guyana',
    code: 'GY',
  },
  {
    icon: '/img/flag/ht.png',
    label: 'Haiti',
    code: 'HT',
  },
  {
    icon: '/img/flag/hm.png',
    label: 'Heard Island and McDonald Islands',
    code: 'HM',
  },
  {
    icon: '/img/flag/hn.png',
    label: 'Honduras',
    code: 'HN',
  },
  {
    icon: '/img/flag/hk.png',
    label: 'Hong Kong SAR China',
    code: 'HK',
  },
  {
    icon: '/img/flag/hu.png',
    label: 'Hungary',
    code: 'HU',
  },
  {
    icon: '/img/flag/is.png',
    label: 'Iceland',
    code: 'IS',
  },
  {
    icon: '/img/flag/in.png',
    label: 'India',
    code: 'IN',
  },
  {
    icon: '/img/flag/id.png',
    label: 'Indonesia',
    code: 'ID',
  },
  {
    icon: '/img/flag/ir.png',
    label: 'Iran',
    code: 'IR',
  },
  {
    icon: '/img/flag/iq.png',
    label: 'Iraq',
    code: 'IQ',
  },
  {
    icon: '/img/flag/ie.png',
    label: 'Ireland',
    code: 'IE',
  },
  {
    icon: '/img/flag/im.png',
    label: 'Isle of Man',
    code: 'IM',
  },
  {
    icon: '/img/flag/il.png',
    label: 'Israel',
    code: 'IL',
  },
  {
    icon: '/img/flag/it.png',
    label: 'Italy',
    code: 'IT',
  },
  {
    icon: '/img/flag/jm.png',
    label: 'Jamaica',
    code: 'JM',
  },
  {
    icon: '/img/flag/jp.png',
    label: 'Japan',
    code: 'JP',
  },
  {
    icon: '/img/flag/je.png',
    label: 'Jersey',
    code: 'JE',
  },
  {
    icon: '/img/flag/00.png',
    label: 'Johnston Island',
    code: 'JT',
  },
  {
    icon: '/img/flag/jo.png',
    label: 'Jordan',
    code: 'JO',
  },
  {
    icon: '/img/flag/kz.png',
    label: 'Kazakhstan',
    code: 'KZ',
  },
  {
    icon: '/img/flag/ke.png',
    label: 'Kenya',
    code: 'KE',
  },
  {
    icon: '/img/flag/ki.png',
    label: 'Kiribati',
    code: 'KI',
  },
  {
    icon: '/img/flag/kw.png',
    label: 'Kuwait',
    code: 'KW',
  },
  {
    icon: '/img/flag/kg.png',
    label: 'Kyrgyzstan',
    code: 'KG',
  },
  {
    icon: '/img/flag/la.png',
    label: 'Laos',
    code: 'LA',
  },
  {
    icon: '/img/flag/lv.png',
    label: 'Latvia',
    code: 'LV',
  },
  {
    icon: '/img/flag/lb.png',
    label: 'Lebanon',
    code: 'LB',
  },
  {
    icon: '/img/flag/ls.png',
    label: 'Lesotho',
    code: 'LS',
  },
  {
    icon: '/img/flag/lr.png',
    label: 'Liberia',
    code: 'LR',
  },
  {
    icon: '/img/flag/ly.png',
    label: 'Libya',
    code: 'LY',
  },
  {
    icon: '/img/flag/li.png',
    label: 'Liechtenstein',
    code: 'LI',
  },
  {
    icon: '/img/flag/lt.png',
    label: 'Lithuania',
    code: 'LT',
  },
  {
    icon: '/img/flag/lu.png',
    label: 'Luxembourg',
    code: 'LU',
  },
  {
    icon: '/img/flag/mo.png',
    label: 'Macau SAR China',
    code: 'MO',
  },
  {
    icon: '/img/flag/mk.png',
    label: 'Macedonia',
    code: 'MK',
  },
  {
    icon: '/img/flag/mg.png',
    label: 'Madagascar',
    code: 'MG',
  },
  {
    icon: '/img/flag/mw.png',
    label: 'Malawi',
    code: 'MW',
  },
  {
    icon: '/img/flag/my.png',
    label: 'Malaysia',
    code: 'MY',
  },
  {
    icon: '/img/flag/mv.png',
    label: 'Maldives',
    code: 'MV',
  },
  {
    icon: '/img/flag/ml.png',
    label: 'Mali',
    code: 'ML',
  },
  {
    icon: '/img/flag/mt.png',
    label: 'Malta',
    code: 'MT',
  },
  {
    icon: '/img/flag/mh.png',
    label: 'Marshall Islands',
    code: 'MH',
  },
  {
    icon: '/img/flag/mq.png',
    label: 'Martinique',
    code: 'MQ',
  },
  {
    icon: '/img/flag/mr.png',
    label: 'Mauritania',
    code: 'MR',
  },
  {
    icon: '/img/flag/mu.png',
    label: 'Mauritius',
    code: 'MU',
  },
  {
    icon: '/img/flag/yt.png',
    label: 'Mayotte',
    code: 'YT',
  },
  {
    icon: '/img/flag/fr.png',
    label: 'Metropolitan France',
    code: 'FX',
  },
  {
    icon: '/img/flag/mx.png',
    label: 'Mexico',
    code: 'MX',
  },
  {
    icon: '/img/flag/fm.png',
    label: 'Micronesia',
    code: 'FM',
  },
  {
    icon: '/img/flag/00.png',
    label: 'Midway Islands',
    code: 'MI',
  },
  {
    icon: '/img/flag/md.png',
    label: 'Moldova',
    code: 'MD',
  },
  {
    icon: '/img/flag/mc.png',
    label: 'Monaco',
    code: 'MC',
  },
  {
    icon: '/img/flag/mn.png',
    label: 'Mongolia',
    code: 'MN',
  },
  {
    icon: '/img/flag/me.png',
    label: 'Montenegro',
    code: 'ME',
  },
  {
    icon: '/img/flag/ms.png',
    label: 'Montserrat',
    code: 'MS',
  },
  {
    icon: '/img/flag/ma.png',
    label: 'Morocco',
    code: 'MA',
  },
  {
    icon: '/img/flag/mz.png',
    label: 'Mozambique',
    code: 'MZ',
  },
  {
    icon: '/img/flag/mm.png',
    label: 'Myanmar [Burma]',
    code: 'MM',
  },
  {
    icon: '/img/flag/na.png',
    label: 'Namibia',
    code: 'NA',
  },
  {
    icon: '/img/flag/nr.png',
    label: 'Nauru',
    code: 'NR',
  },
  {
    icon: '/img/flag/np.png',
    label: 'Nepal',
    code: 'NP',
  },
  {
    icon: '/img/flag/nl.png',
    label: 'Netherlands',
    code: 'NL',
  },
  {
    icon: '/img/flag/an.png',
    label: 'Netherlands Antilles',
    code: 'AN',
  },
  {
    icon: '/img/flag/00.png',
    label: 'Neutral Zone',
    code: 'NT',
  },
  {
    icon: '/img/flag/nc.png',
    label: 'New Caledonia',
    code: 'NC',
  },
  {
    icon: '/img/flag/nz.png',
    label: 'New Zealand',
    code: 'NZ',
  },
  {
    icon: '/img/flag/ni.png',
    label: 'Nicaragua',
    code: 'NI',
  },
  {
    icon: '/img/flag/ne.png',
    label: 'Niger',
    code: 'NE',
  },
  {
    icon: '/img/flag/ng.png',
    label: 'Nigeria',
    code: 'NG',
  },
  {
    icon: '/img/flag/nu.png',
    label: 'Niue',
    code: 'NU',
  },
  {
    icon: '/img/flag/nf.png',
    label: 'Norfolk Island',
    code: 'NF',
  },
  {
    icon: '/img/flag/mp.png',
    label: 'Northern Mariana Islands',
    code: 'MP',
  },
  {
    icon: '/img/flag/kp.png',
    label: 'North Korea',
    code: 'KP',
  },
  {
    icon: '/img/flag/00.png',
    label: 'North Vietnam',
    code: 'VD',
  },
  {
    icon: '/img/flag/no.png',
    label: 'Norway',
    code: 'NO',
  },
  {
    icon: '/img/flag/om.png',
    label: 'Oman',
    code: 'OM',
  },
  {
    icon: '/img/flag/00.png',
    label: 'Pacific Islands Trust Territory',
    code: 'PC',
  },
  {
    icon: '/img/flag/pk.png',
    label: 'Pakistan',
    code: 'PK',
  },
  {
    icon: '/img/flag/pw.png',
    label: 'Palau',
    code: 'PW',
  },
  {
    icon: '/img/flag/ps.png',
    label: 'Palestinian Territories',
    code: 'PS',
  },
  {
    icon: '/img/flag/pa.png',
    label: 'Panama',
    code: 'PA',
  },
  {
    icon: '/img/flag/00.png',
    label: 'Panama Canal Zone',
    code: 'PZ',
  },
  {
    icon: '/img/flag/pg.png',
    label: 'Papua New Guinea',
    code: 'PG',
  },
  {
    icon: '/img/flag/py.png',
    label: 'Paraguay',
    code: 'PY',
  },
  {
    icon: '/img/flag/00.png',
    label: 'People"s Democratic Republic of Yemen',
    code: 'YD',
  },
  {
    icon: '/img/flag/pe.png',
    label: 'Peru',
    code: 'PE',
  },
  {
    icon: '/img/flag/ph.png',
    label: 'Philippines',
    code: 'PH',
  },
  {
    icon: '/img/flag/pn.png',
    label: 'Pitcairn Islands',
    code: 'PN',
  },
  {
    icon: '/img/flag/pl.png',
    label: 'Poland',
    code: 'PL',
  },
  {
    icon: '/img/flag/pt.png',
    label: 'Portugal',
    code: 'PT',
  },
  {
    icon: '/img/flag/pr.png',
    label: 'Puerto Rico',
    code: 'PR',
  },
  {
    icon: '/img/flag/qa.png',
    label: 'Qatar',
    code: 'QA',
  },
  {
    icon: '/img/flag/re.png',
    label: 'Réunion',
    code: 'RE',
  },
  {
    icon: '/img/flag/ro.png',
    label: 'Romania',
    code: 'RO',
  },
  {
    icon: '/img/flag/ru.png',
    label: 'Russia',
    code: 'RU',
  },
  {
    icon: '/img/flag/rw.png',
    label: 'Rwanda',
    code: 'RW',
  },
  {
    icon: '/img/flag/bl.png',
    label: 'Saint Barthélemy',
    code: 'BL',
  },
  {
    icon: '/img/flag/sh.png',
    label: 'Saint Helena',
    code: 'SH',
  },
  {
    icon: '/img/flag/kn.png',
    label: 'Saint Kitts and Nevis',
    code: 'KN',
  },
  {
    icon: '/img/flag/lc.png',
    label: 'Saint Lucia',
    code: 'LC',
  },
  {
    icon: '/img/flag/mf.png',
    label: 'Saint Martin',
    code: 'MF',
  },
  {
    icon: '/img/flag/pm.png',
    label: 'Saint Pierre and Miquelon',
    code: 'PM',
  },
  {
    icon: '/img/flag/vc.png',
    label: 'Saint Vincent and the Grenadines',
    code: 'VC',
  },
  {
    icon: '/img/flag/ws.png',
    label: 'Samoa',
    code: 'WS',
  },
  {
    icon: '/img/flag/sm.png',
    label: 'San Marino',
    code: 'SM',
  },
  {
    icon: '/img/flag/st.png',
    label: 'São Tomé and Príncipe',
    code: 'ST',
  },
  {
    icon: '/img/flag/sa.png',
    label: 'Saudi Arabia',
    code: 'SA',
  },
  {
    icon: '/img/flag/sn.png',
    label: 'Senegal',
    code: 'SN',
  },
  {
    icon: '/img/flag/rs.png',
    label: 'Serbia',
    code: 'RS',
  },
  {
    icon: '/img/flag/cs.png',
    label: 'Serbia and Montenegro',
    code: 'CS',
  },
  {
    icon: '/img/flag/sc.png',
    label: 'Seychelles',
    code: 'SC',
  },
  {
    icon: '/img/flag/sl.png',
    label: 'Sierra Leone',
    code: 'SL',
  },
  {
    icon: '/img/flag/sh.png',
    label: 'Singapore',
    code: 'SG',
  },
  {
    icon: '/img/flag/sk.png',
    label: 'Slovakia',
    code: 'SK',
  },
  {
    icon: '/img/flag/si.png',
    label: 'Slovenia',
    code: 'SI',
  },
  {
    icon: '/img/flag/sb.png',
    label: 'Solomon Islands',
    code: 'SB',
  },
  {
    icon: '/img/flag/so.png',
    label: 'Somalia',
    code: 'SO',
  },
  {
    icon: '/img/flag/za.png',
    label: 'South Africa',
    code: 'ZA',
  },
  {
    icon: '/img/flag/gs.png',
    label: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
  },
  {
    icon: '/img/flag/kr.png',
    label: 'South Korea',
    code: 'KR',
  },
  {
    icon: '/img/flag/es.png',
    label: 'Spain',
    code: 'ES',
  },
  {
    icon: '/img/flag/lk.png',
    label: 'Sri Lanka',
    code: 'LK',
  },
  {
    icon: '/img/flag/sd.png',
    label: 'Sudan',
    code: 'SD',
  },
  {
    icon: '/img/flag/sr.png',
    label: 'Suriname',
    code: 'SR',
  },
  {
    icon: '/img/flag/sj.png',
    label: 'Svalbard and Jan Mayen',
    code: 'SJ',
  },
  {
    icon: '/img/flag/sz.png',
    label: 'Swaziland',
    code: 'SZ',
  },
  {
    icon: '/img/flag/se.png',
    label: 'Sweden',
    code: 'SE',
  },
  {
    icon: '/img/flag/ch.png',
    label: 'Switzerland',
    code: 'CH',
  },
  {
    icon: '/img/flag/sy.png',
    label: 'Syria',
    code: 'SY',
  },
  {
    icon: '/img/flag/tw.png',
    label: 'Taiwan',
    code: 'TW',
  },
  {
    icon: '/img/flag/tj.png',
    label: 'Tajikistan',
    code: 'TJ',
  },
  {
    icon: '/img/flag/tz.png',
    label: 'Tanzania',
    code: 'TZ',
  },
  {
    icon: '/img/flag/th.png',
    label: 'Thailand',
    code: 'TH',
  },
  {
    icon: '/img/flag/tl.png',
    label: 'Timor-Leste',
    code: 'TL',
  },
  {
    icon: '/img/flag/tg.png',
    label: 'Togo',
    code: 'TG',
  },
  {
    icon: '/img/flag/tk.png',
    label: 'Tokelau',
    code: 'TK',
  },
  {
    icon: '/img/flag/to.png',
    label: 'Tonga',
    code: 'TO',
  },
  {
    icon: '/img/flag/tt.png',
    label: 'Trinidad and Tobago',
    code: 'TT',
  },
  {
    icon: '/img/flag/tn.png',
    label: 'Tunisia',
    code: 'TN',
  },
  {
    icon: '/img/flag/tr.png',
    label: 'Turkey',
    code: 'TR',
  },
  {
    icon: '/img/flag/tm.png',
    label: 'Turkmenistan',
    code: 'TM',
  },
  {
    icon: '/img/flag/tc.png',
    label: 'Turks and Caicos Islands',
    code: 'TC',
  },
  {
    icon: '/img/flag/tv.png',
    label: 'Tuvalu',
    code: 'TV',
  },
  {
    icon: '/img/flag/ug.png',
    label: 'Uganda',
    code: 'UG',
  },
  {
    icon: '/img/flag/ua.png',
    label: 'Ukraine',
    code: 'UA',
  },
  {
    icon: '/img/flag/00.png',
    label: 'Union of Soviet Socialist Republics',
    code: 'SU',
  },
  {
    icon: '/img/flag/ae.png',
    label: 'United Arab Emirates',
    code: 'AE',
  },
  {
    icon: '/img/flag/gb.png',
    label: 'United Kingdom',
    code: 'GB',
  },
  {
    icon: '/img/flag/us.png',
    label: 'United States',
    code: 'US',
  },
  {
    icon: '/img/flag/00.png',
    label: 'Unknown or Invalid Region',
    code: 'ZZ',
  },
  {
    icon: '/img/flag/uy.png',
    label: 'Uruguay',
    code: 'UY',
  },
  {
    icon: '/img/flag/um.png',
    label: 'U.S. Minor Outlying Islands',
    code: 'UM',
  },
  {
    icon: '/img/flag/00.png',
    label: 'U.S. Miscellaneous Pacific Islands',
    code: 'PU',
  },
  {
    icon: '/img/flag/vi.png',
    label: 'U.S. Virgin Islands',
    code: 'VI',
  },
  {
    icon: '/img/flag/uz.png',
    label: 'Uzbekistan',
    code: 'UZ',
  },
  {
    icon: '/img/flag/vu.png',
    label: 'Vanuatu',
    code: 'VU',
  },
  {
    icon: '/img/flag/va.png',
    label: 'Vatican City',
    code: 'VA',
  },
  {
    icon: '/img/flag/ve.png',
    label: 'Venezuela',
    code: 'VE',
  },
  {
    icon: '/img/flag/vn.png',
    label: 'Vietnam',
    code: 'VN',
  },
  {
    icon: '/img/flag/00.png',
    label: 'Wake Island',
    code: 'WK',
  },
  {
    icon: '/img/flag/wf.png',
    label: 'Wallis and Futuna',
    code: 'WF',
  },
  {
    icon: '/img/flag/eh.png',
    label: 'Western Sahara',
    code: 'EH',
  },
  {
    icon: '/img/flag/ye.png',
    label: 'Yemen',
    code: 'YE',
  },
  {
    icon: '/img/flag/zm.png',
    label: 'Zambia',
    code: 'ZM',
  },
  {
    icon: '/img/flag/zw.png',
    label: 'Zimbabwe',
    code: 'ZW',
  },
];

export const getShopCustomerLanguages = async () => languages.map((language: {code: string; label: string}) => ({
  value: language.code,
  label: language.label,
}));
