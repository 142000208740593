import { i18n } from '@/i18n';

export default async function getFontFamilyList() {
  const { global: { t } } = await i18n;
  return [
    { label: t('templateBuilder.fields.defaultFontFamily'), value: '' },
    { label: 'Georgia, serif', value: '"Georgia", "serif"' },
    { label: 'Palatino Linotype, Book Antiqua, Palatino, serif', value: '"Palatino Linotype", "Book Antiqua", "Palatino", "serif"' },
    { label: 'Times New Roman, Times, serif', value: '"Times New Roman", "Times", "serif"' },
    { label: 'Arial, Helvetica, sans-serif', value: '"Arial", "Helvetica", "sans-serif"' },
    { label: 'Arial Black, Gadget, sans-serif', value: '"Arial Black", "Gadget", "sans-serif"' },
    {
      label: 'Comic Sans, Comic Sans MS, Chalkboard, ChalkboardSE-Regular, sans-serif',
      value: '"Comic Sans", "Comic Sans MS", "Chalkboard", "ChalkboardSE-Regular", '
        + '"sans-serif"',
    },
    { label: 'Impact, Charcoal, sans-serif', value: '"Impact", "Charcoal", "sans-serif"' },
    { label: 'Lucida Sans Unicode, Lucida Grande, sans-serif', value: '"Lucida Sans Unicode", "Lucida Grande", "sans-serif"' },
    { label: 'Tahoma, Geneva, sans-serif', value: '"Tahoma", "Geneva", "sans-serif"' },
    { label: 'Trebuchet MS, Helvetica, sans-serif', value: '"Trebuchet MS", "Helvetica", "sans-serif"' },
    { label: 'Verdana, Geneva, sans-serif', value: '"Verdana", "Geneva", "sans-serif"' },
    { label: 'Courier New, Courier, monospace', value: '"Courier New", "Courier", "monospace"' },
    { label: 'Lucida Console, Monaco, monospace', value: '"Lucida Console", "Monaco", "monospace"' },
  ];
}
