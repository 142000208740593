<template>
  <div class="inline-block p-buttonset">
    <label class="block my-3">
      {{ t(configs.label) }}
    </label>
    <draggable
      v-model="buttonList"
      item-key="id"
      @change="handleChange"
    >
      <template #item="{ element }">
        <span class="draggable">
          <BaseButton
            :button="element"
            :parser-values="parserValues"
            :properties="[mainProperty]"
            @on-click="handleChange"
          />
          <i
            data-test-id="drag-icon"
            class="fas fa-arrows-alt-h"
          />
        </span>
      </template>
    </draggable>
  </div>
</template>

<script lang="ts">
import draggable from 'vuedraggable';
import {
  defineComponent, PropType, ref, Ref,
} from 'vue';

import {
  ButtonFieldOption,
  ButtonFieldConfig,
  ParserFieldObject,
  Property,
} from '@/types';
import { useI18n } from 'vue-i18n';
import BaseButton from '@/components/fields/partials/BaseButton.vue';

export default defineComponent({
  name: 'DraggableButtonGroup',
  components: { BaseButton, draggable },

  props: {
    configs: {
      type: Object as PropType<ButtonFieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props: {parserValues: ParserFieldObject; configs: ButtonFieldConfig}, context) {
    const { t } = useI18n();
    const mainProperty: Ref<Property> = ref(props.parserValues.properties && props.parserValues.properties[0]);
    const buildButtonList = (): ButtonFieldOption[] => Object.entries(props.configs.options).map((data) => {
      const value = data[0];
      const {
        defaultValue, property, iconClass, title, multipleChoice,
      } = data[1];
      return {
        title: t(title),
        iconClass,
        property,
        defaultValue,
        value,
        multipleChoice,
      };
    });
    const buttonList: Ref<ButtonFieldOption[]> = ref(buildButtonList());
    // Initial sort
    buttonList.value.sort((a: ButtonFieldOption, b: ButtonFieldOption) => {
      if (mainProperty.value.value) {
        if (mainProperty.value.value.indexOf(a.value) === -1) { // if a is not checked, we put it at the end
          return 1;
        }
        if (mainProperty.value.value.indexOf(b.value) === -1) { // if b is not checked, we put it at the end
          return -1;
        }
        return mainProperty.value.value.indexOf(a.value) - mainProperty.value.value.indexOf(b.value);
      }
      return 1;
    });

    const handleChange = () => {
      const currentValue = mainProperty.value.value?.split(' ');
      if (currentValue) {
        const sortedValue = buttonList.value.reduce((acc, button) => ((currentValue.includes(button.value)) ? `${acc} ${button.value}` : acc), '');
        mainProperty.value.value = sortedValue;
        context.emit('on-change-properties', {
          selector: props.parserValues.selector,
          properties: [mainProperty.value],
        });
      }
    };

    return {
      buttonList,
      handleChange,
      mainProperty,
      t,
    };
  },
});
</script>

<style scoped lang="scss">
.draggable {
  width: 39px;
  display: inline-block;
  text-align: center;

  & > .p-button {
    height: 39px;
  }

  & > i {
    color: $heather;
  }
}
</style>
