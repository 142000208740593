
import {
  FieldConfig, InputGroupField, ParserFieldObject, Property,
} from '@/types';
import {
  defineComponent, PropType, Ref, ref, watch,
} from 'vue';
import draggable from 'vuedraggable';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'InputGroup',
  components: {
    InputText,
    Checkbox,
    draggable,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props: {parserValues: ParserFieldObject; configs: FieldConfig}, context) {
    const { t } = useI18n();
    const property: Ref<Property> = ref(props.parserValues.properties[0]);
    const inputs = ref(property.value.value);

    const handleChangeRequired = (input: InputGroupField) => {
      property.value.value = inputs.value.map((field: InputGroupField) => [input].find((o) => o.key === field.key) || field);
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: [property.value],
      });
    };

    const handleChangeValue = () => {
      property.value.value = inputs.value;
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: [property.value],
      });
    };

    watch(() => property.value.value, (values, oldValues) => {
      if (values !== oldValues) {
        inputs.value = property.value.value;
      }
    }, { immediate: true });

    return {
      t,
      inputs,
      handleChangeValue,
      handleChangeRequired,
    };
  },
});
