<template>
  <Dialog
    v-model:visible="dialogVisible"
    :modal="true"
    :show-header="false"
    :draggable="false"
    :closable="false"
    style="width: 20vw"
    @update:visible="updateDialogVisibility"
  >
    <Loader v-if="isLoading" style="z-index: 1102;"/>
    <div>
      <div class="flex justify-content-center my-3">
        <h3>{{ translation('storeParameters.storeInformations.languages.addShopLang') }}</h3>
      </div>
      <div class="flex justify-content-center mb-4">
        <Dropdown
          v-model="newLanguage"
          :options="languageOptions"
          option-value="value"
          option-label="label"
          :placeholder="translation('storeParameters.storeInformations.languages.selectLang')"
        />
      </div>
      <div class="flex justify-content-center mb-4">
        <Button
          class="p-button p-button-success mr-2"
          @click="addNewLang"
        >
          {{ translation('validate') }}
        </Button>
        <Button
          class="p-button p-button-danger"
          @click="updateDialogVisibility"
        >
          {{ translation('cancel') }}
        </Button>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import { saveNewLang } from '@/composables/shop/MyShopParameters';
import { ShopsLang, ShopsLangUpdateInputItem } from '@/types/generated-types/graphql';
import Loader from '@/components/layout/Loader.vue';

export default defineComponent({
  name: 'AddNewLang',

  components: {
    Dialog,
    Dropdown,
    Loader,
  },

  props: {
    translation: {
      type: Function,
      required: true,
    },

    idShop: {
      type: Number,
      required: true,
    },

    defaultUrl: {
      type: String,
      required: true,
    },

    defaultUrlCart: {
      type: String,
      required: true,
    },
  },

  emits: [
    'close-action-modal',
  ],

  setup(props, context) {
    const dialogVisible = ref(true);
    const newLanguage = ref();
    const addedShopsLang = ref<ShopsLangUpdateInputItem>();
    const isLoading = ref(false);

    const updateDialogVisibility = () => {
      dialogVisible.value = false;
      context.emit('close-action-modal', [], false);
    };

    const languageOptions = [
      { value: 'ar', label: props.translation('languages.codes.ar') },
      { value: 'az', label: props.translation('languages.codes.az') },
      { value: 'bg', label: props.translation('languages.codes.bg') },
      { value: 'bs', label: props.translation('languages.codes.bs') },
      { value: 'ca', label: props.translation('languages.codes.ca') },
      { value: 'cs', label: props.translation('languages.codes.cs') },
      { value: 'da', label: props.translation('languages.codes.da') },
      { value: 'de', label: props.translation('languages.codes.de') },
      { value: 'el', label: props.translation('languages.codes.el') },
      { value: 'en', label: props.translation('languages.codes.en') },
      { value: 'es', label: props.translation('languages.codes.es') },
      { value: 'et', label: props.translation('languages.codes.et') },
      { value: 'fi', label: props.translation('languages.codes.fi') },
      { value: 'fr', label: props.translation('languages.codes.fr') },
      { value: 'hr', label: props.translation('languages.codes.hr') },
      { value: 'he', label: props.translation('languages.codes.he') },
      { value: 'hu', label: props.translation('languages.codes.hu') },
      { value: 'hy', label: props.translation('languages.codes.hy') },
      { value: 'is', label: props.translation('languages.codes.is') },
      { value: 'it', label: props.translation('languages.codes.it') },
      { value: 'ja', label: props.translation('languages.codes.ja') },
      { value: 'ko', label: props.translation('languages.codes.ko') },
      { value: 'lt', label: props.translation('languages.codes.lt') },
      { value: 'lv', label: props.translation('languages.codes.lv') },
      { value: 'mk', label: props.translation('languages.codes.mk') },
      { value: 'mn', label: props.translation('languages.codes.mn') },
      { value: 'mx', label: props.translation('languages.codes.mx') },
      { value: 'mt', label: props.translation('languages.codes.mt') },
      { value: 'mt', label: props.translation('languages.codes.mt') },
      { value: 'nl', label: props.translation('languages.codes.nl') },
      { value: 'no', label: props.translation('languages.codes.no') },
      { value: 'pl', label: props.translation('languages.codes.pl') },
      { value: 'pt', label: props.translation('languages.codes.pt') },
      { value: 'ro', label: props.translation('languages.codes.ro') },
      { value: 'ru', label: props.translation('languages.codes.ru') },
      { value: 'sk', label: props.translation('languages.codes.sk') },
      { value: 'sl', label: props.translation('languages.codes.sl') },
      { value: 'sq', label: props.translation('languages.codes.sq') },
      { value: 'sr', label: props.translation('languages.codes.sr') },
      { value: 'sv', label: props.translation('languages.codes.sv') },
      { value: 'sz', label: props.translation('languages.codes.sz') },
      { value: 'th', label: props.translation('languages.codes.th') },
      { value: 'tr', label: props.translation('languages.codes.tr') },
      { value: 'uk', label: props.translation('languages.codes.uk') },
      { value: 'uz', label: props.translation('languages.codes.uz') },
      { value: 'vi', label: props.translation('languages.codes.vi') },
      { value: 'zh', label: props.translation('languages.codes.zh') },
    ];

    const addNewLang = async () => {
      isLoading.value = true;
      const shopsLang: ShopsLangUpdateInputItem = {
        id_shop: props.idShop,
        lang: newLanguage.value,
        url: props.defaultUrl,
        url_cart: props.defaultUrlCart,
        default: false,
      };
      addedShopsLang.value = shopsLang;
      const result = await saveNewLang(shopsLang);
      context.emit('close-action-modal', addedShopsLang.value, true);
      isLoading.value = false;
    };

    return {
      languageOptions,
      dialogVisible,
      updateDialogVisibility,
      newLanguage,
      addNewLang,
      isLoading,
    };
  },
});
</script>

<style scoped>

</style>
