<template>
  <div
    :class="maskClass"
  >
    <Dialog
      v-model:visible="show"
      :header="t('TACHeader')"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      style="z-index: 1101;"
      content-style="text-align:left;"
      @hide="hideMask"
    >
      <div v-html="translatedText" />
    </Dialog>
  </div>
</template>

<script lang="ts">
import Dialog from 'primevue/dialog';
import {
  defineComponent, ref, SetupContext, watch,
} from 'vue';
import translation from '@/i18n/LongTexts';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TermsAndConditions',

  components: {
    Dialog,
  },

  emits: {
    'on-close-modal': Object,
  },

  setup(_: any, context: SetupContext) {
    const { t, locale } = useI18n();

    let translatedText = '';
    const maskClass = ref('p-dialog-mask p-component-overlay p-component-overlay-enter');
    const show = ref(true);

    // todo: access the object key using a dynamic variable
    Object.entries(translation).forEach((key) => {
      if (key[0] === locale.value) {
        translatedText = key[1].TAC;
      }
    });

    const hideMask = () => {
      maskClass.value = '';
      context.emit('on-close-modal', true);
    };
    return {
      t, show, translatedText, maskClass, hideMask,
    };
  },
});
</script>

<style scoped lang="scss">
.p-dialog, .p-dialog-mask.p-component-overlay {
  pointer-events: auto;
}

.p-component-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-dialog-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  pointer-events: none;
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.2s;
}
</style>
