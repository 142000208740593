
import {
  defineComponent, onMounted, PropType, Ref, ref, SetupContext, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Tooltip from 'primevue/tooltip';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import { showToastError } from '@/helpers';

export default defineComponent({
  name: 'InputList',

  components: {
    FieldErrors,
    InputText,
    Button,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    modelValue: {
      type: Object as PropType<string[]>,
      required: true,
    },

    label: {
      type: String,
      required: false,
      default: 'automatedScenarios.fields.url',
    },

    validate: {
      type: Function,
      required: false,
      default: null,
    },

    callback: {
      type: Function,
      required: false,
      default: null,
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: string[]; validate: Function; callback: Function }, { emit }: SetupContext) {
    const { t } = useI18n();
    const input: Ref<string> = ref('');
    const inputs: Ref<string[]> = ref(props.modelValue);
    const formValidation = ref<any>({});
    const componentFieldErrorsKey = ref(0);

    const handleAddInput = async () => {
      formValidation.value = {};
      componentFieldErrorsKey.value += 1;

      if (inputs.value.filter((current: string) => current === input.value).length === 0) {
        const tempInput = inputs.value.slice();
        if (props.validate) {
          const validation = await props.validate({ value: input.value });

          if (!validation.success) {
            formValidation.value = validation.validate;
            componentFieldErrorsKey.value += 1;
            await showToastError(t('errorMessages.FORM_ERROR'));
          } else if (props.callback) {
            const value = await props.callback(input.value);
            tempInput.push(value);
            input.value = '';
          } else {
            tempInput.push(input.value);
            input.value = '';
          }
        } else if (props.callback) {
          const value = await props.callback(input.value);
          tempInput.push(value);
          input.value = '';
        } else {
          tempInput.push(input.value);
          input.value = '';
        }
        inputs.value = tempInput;
        emit('update:modelValue', inputs.value);
      }
    };

    const removeInput = (index: number) => {
      const tempInput = inputs.value.slice();
      tempInput.splice(index, 1);
      inputs.value = tempInput;
      emit('update:modelValue', inputs.value);
    };

    watch([inputs.value], () => {
      emit('update:modelValue', inputs.value);
    });

    onMounted(() => emit('update:modelValue', inputs.value));

    return {
      t,
      input,
      inputs,
      formValidation,
      componentFieldErrorsKey,
      handleAddInput,
      removeInput,
    };
  },
});
