<template>
  <TextRenderer
    v-if="col.type === 'text'"
    :key="`${col.field}-${index}`"
    :translatable="Object.prototype.hasOwnProperty.call(col, 'translatable') && col.translatable"
    :content="content"
  />
  <DateTimeRenderer
    v-else-if="col.type === 'datetime'"
    :key="`${col.type}-${index}`"
    :content="slotProps.data[slotProps.column.props.field]"
  />
  <PreviewRenderer
    v-else-if="col.type === 'preview'"
    :key="`${col.type}-${index}-preview`"
    :image-url="col.imageUrl ?? slotProps.data[slotProps.column.props.field]"
  />
  <ActionRenderer
    v-else-if="col.type === 'action'"
    :key="`${col.type}-${index}-action`"
    :data="slotProps.data"
    :actions="col.actions"
  />
  <StatsActionRenderer
    v-else-if="col.type === 'stats-action'"
    :key="`${col.type}-${index}-stats`"
    :data="slotProps.data"
    :actions="col.actionsText"
    :column="col"
  />
  <MenuRenderer
    v-else-if="col.type === 'menu'"
    :key="`${col.type}-${index}-menu`"
    :data="slotProps.data"
    :actions="col.menuActions"
  />
  <ChipRenderer
    v-else-if="col.type === 'chip'"
    :key="`${col.type}-${index}-chip`"
    :data="slotProps.data"
  />
  <UnsubRenderer
    v-else-if="col.type === 'unsub'"
    :key="`${col.type}-${index}-unsub`"
    :data="slotProps.data[slotProps.column.props.field]"
  />
  <Flag
    v-if="col.type === 'flag'"
    :country="slotProps.data[slotProps.column.props.field]"
  />
  <ActiveStatusIcon
    v-else-if="col.type === 'active-status-icon'"
    :key="`${col.type}-${index}`"
    :active="slotProps.data[slotProps.column.props.field] === '' ? false : slotProps.data[slotProps.column.props.field]"
  />
  <LanguageIcons
    v-else-if="col.type === 'language'"
    :translation="t"
    :language="slotProps.data[slotProps.column.props.field]"
  />
  <DropdownRenderer
    v-else-if="col.type === 'dropdown'"
    :key="`${col.type}-${index}-dropdown`"
    :options="col.options ?? []"
    :data="col"
  />
  <DataExplorerLink
    v-else-if="col.type === 'data-explorer-link' && !slotProps.data.deleted"
    :id="slotProps.data[col.dataExplorerOptions?.id ?? 'idShopCustomer']"
    :type="col.dataExplorerOptions?.type"
    :value="content"
    :custom-key="col.dataExplorerOptions?.customKey ?? ''"
    :show-icon="true"
  />
  <TextRenderer
    v-else-if="col.type === 'data-explorer-link' && slotProps.data.deleted"
    :key="`${col.field}-${index}`"
    :translatable="Object.prototype.hasOwnProperty.call(col, 'translatable') && col.translatable"
    :content="content"
  />
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import PreviewRenderer from '@/components/table/renderers/PreviewRenderer.vue';
import TextRenderer from '@/components/table/renderers/TextRenderer.vue';
import ActionRenderer from '@/components/table/renderers/ActionRenderer.vue';
import ChipRenderer from '@/components/table/renderers/ChipRenderer.vue';
import UnsubRenderer from '@/components/table/renderers/UnsubRenderer.vue';
import Flag from '@/components/table/renderers/Flag.vue';
import ActiveStatusIcon from '@/components/table/renderers/ActiveStatusIcon.vue';
import { SpmTableColumns } from '@/types';
import DropdownRenderer from '@/components/table/renderers/DropdownRenderer.vue';
import DateTimeRenderer from '@/components/table/renderers/DateTimeRenderer.vue';
import MenuRenderer from '@/components/table/renderers/MenuRenderer.vue';
import StatsActionRenderer from '@/components/table/renderers/StatsActionRenderer.vue';
import { useI18n } from 'vue-i18n';
import LanguageIcons from "@/components/table/renderers/LanguageIcons.vue";
import DataExplorerLink from "@/components/data-explorer/fields/DataExplorerLink.vue";

export default defineComponent({
  name: 'Renderer',
  components: {
    LanguageIcons,
    DropdownRenderer,
    DateTimeRenderer,
    TextRenderer,
    PreviewRenderer,
    ActionRenderer,
    StatsActionRenderer,
    ChipRenderer,
    UnsubRenderer,
    ActiveStatusIcon,
    Flag,
    MenuRenderer,
    DataExplorerLink,
  },

  props: {
    index: {
      type: String,
      required: true,
    },

    col: {
      type: Object as PropType<SpmTableColumns>,
      required: true,
    },

    slotProps: {
      type: Object as PropType<any>,
      required: true,
    },
  },

  setup(props) {
    const { locale, t } = useI18n();

    const content = computed(() => {
      try {
        const slotContent = JSON.parse(props.slotProps.data[props.slotProps.column.props.field]);

        if (locale.value in slotContent) {
          return slotContent[locale.value];
        }

        return props.slotProps.data[props.slotProps.column.props.field];
      } catch (error) {
        return props.slotProps.data[props.slotProps.column.props.field];
      }
    });

    return {
      t,
      content,
    };
  },

});
</script>
