<template>
  <FileUpload
    id="logoPath"
    :choose-label="t('form.chooseLabelButton')"
    :custom-upload="true"
    :multiple="false"
    accept="image/*"
    :auto="true"
    @uploader="(event) => handleUpload('shopRequiredDataEmail_logoPath', event)"
  >
    <template #header="{ chooseCallback }">
      <div class="flex flex-wrap flex-1 justify-content-between align-items-center gap-2">
        <div class="flex gap-2">
          <Button
            icon="fal fa-image"
            class="p-button-outlined"
            :label="t('form.chooseLabelButton')"
            @click="chooseCallback"
          />
        </div>
        <ProgressBar
          v-if="isLoading"
          class="w-20rem h-1rem w-full md:ml-auto"
          mode="indeterminate"
        />
      </div>
    </template>
    <template #content>
      <div v-if="!isLoading && imagePath && imagePath !== ''">
        <div class="flex flex-wrap justify-content-center">
          <div
            class="card p-5 flex flex-column border-1 surface-border align-items-center"
          >
            <div>
              <img
                role="presentation"
                :src="imagePath"
                class="shadow-3 logo"
              >
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #empty>
      <div
        v-if="!imagePath"
        class="flex align-items-center justify-content-center flex-column"
      >
        <i class="fal fa-cloud-arrow-up border-2 border-circle p-5 text-3xl text-400 border-400" />
        <p class="mt-4 mb-0">
          {{ emptyLabel }}
        </p>
      </div>
    </template>
  </FileUpload>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  computed,
} from 'vue';

import FileUpload from 'primevue/fileupload';
import ProgressBar from 'primevue/progressbar';
import Button from 'primevue/button';

import { uploadFile } from '@/composables/shop/MyShopParameters';
import { showToastError, showToastSuccess } from '@/helpers';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ImageUploader',

  components: {
    FileUpload,
    ProgressBar,
    Button,
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    emptyLabel: {
      type: String,
      required: true,
    },

    shopId: {
      type: Number,
      required: true,
    },
  },

  emits: ['update:modelValue', 'on-loading', 'uploaded'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const isLoading = ref(false);
    const resizedFile: any = ref();
    const data = ref('');

    const imagePath = computed({
      get() {
        return props.modelValue;
      },

      set(newValue) {
        emit('update:modelValue', newValue);
      },
    });

    const resizeImage = async (file: File) => new Promise((resolve, reject) => {
      let tempResizedFile: File;
      if (file) {
        const imageUrl = URL.createObjectURL(file);
        const image = new Image();
        image.src = imageUrl;

        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
          if (!ctx) reject();

          const ratio = image.width / image.height;
          let newWidth = image.width;
          let newHeight = image.height;

          if (newWidth > 300) {
            newWidth = 300;
            newHeight = newWidth / ratio;
          }

          if (newHeight > 300) {
            newHeight = 300;
            newWidth = newHeight * ratio;
          }

          canvas.width = newWidth;
          canvas.height = newHeight;

          if (ctx !== null) {
            ctx.drawImage(image, 0, 0, newWidth, newHeight);
          }

          canvas.toBlob((blob: any) => {
            tempResizedFile = new File([blob], file.name, {
              type: file.type,
            });

            resizedFile.value = tempResizedFile;
            resolve(resizedFile.value);
          }, file.type);
        };
      }
    });

    const convertImageToBase64 = async (file: any) => new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const base64Data = e.target.result;
        data.value = base64Data;
        resolve(base64Data);
      };

      reader.onerror = (err) => {
        reject(err);
      };

      // Read the image file as a data URL
      reader.readAsDataURL(file);
    });

    const handleUpload = async (imageField: any, event: any) => {
      if (event.files.length === 0) {
        await showToastError('GENERIC_ERROR');
        return;
      }

      const file = event.files[0];
      isLoading.value = true;
      emit('on-loading');
      await resizeImage(file);
      await convertImageToBase64(resizedFile.value);
      uploadFile(data.value, props.shopId, 'logoShop').then((response: any) => {
        imagePath.value = `${response.data.replace('\\', '')}?v=${new Date().getTime()}`;
        isLoading.value = false;
        emit('uploaded');
      }).catch(async (err: any) => {
        await showToastError('FILE_UPLOAD_FAILED');
        isLoading.value = false;
        emit('uploaded');
      });
    };

    return {
      t,
      isLoading,
      imagePath,
      handleUpload,
    };
  },

});
</script>

<style scoped lang="scss">
.p-fileupload {
  width: 100%;
}
.logo {
  max-width: 200px;
}
</style>
