
import {
  defineComponent, Ref, ref, watch,
} from 'vue';
import OverlayPanel from 'primevue/overlaypanel';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

import { ColorFormatEnum } from '@/types';
import CustomColorPicker from '@/components/fields/partials/CustomColorPicker.vue';

export default defineComponent({
  name: 'SimpleColorPicker',
  components: {
    CustomColorPicker, OverlayPanel, InputText, Button,
  },

  props: {
    selectedColor: {
      type: String,
      required: true,
    },

  },

  emits: ['color-changed'],

  setup(props, context) {
    const colorFormat: Ref<string> = ref(ColorFormatEnum.HEX);
    const overlayPanelRef = ref();
    const colorSelected: Ref<string> = ref(props.selectedColor);

    const toggleColorPicker = (event: MouseEvent) => {
      overlayPanelRef.value.toggle(event);
    };

    const changeColor = (color: string) => {
      colorSelected.value = color;
      context.emit('color-changed', colorSelected.value);
    };

    const isValidHexColor = (color: string) => {
      const hexColorPattern = /^#([0-9A-F]{3}){1,2}$/i;
      return hexColorPattern.test(color);
    };

    const updateColor = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const color = target.value;
      if (isValidHexColor(color)) {
        colorSelected.value = color;
        context.emit('color-changed', colorSelected.value);
      } else {
        // Optionally, you can show an error message or revert to the previous valid color
        console.error('Invalid hex color code');
      }
    };

    watch(props, () => {
      colorSelected.value = props.selectedColor;
    });

    return {
      overlayPanelRef,
      colorFormat,
      toggleColorPicker,
      changeColor,
      updateColor,
      colorSelected,
    };
  },
});
