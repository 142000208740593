<template>
  <Card class="address-file-card">
    <template #title>
      <div class="mb-3 flex flex-column md:flex-row md:align-items-center md:justify-content-between">
        <div class="flex align-items-start">
          <div>
            <DataExplorerLink
              :id="selectedData.idShopCustomer"
              :value="selectedData.tabLabel"
              :label="t(`dataExplorer.tabs.${selectedData.dataType}`)"
              class-name="text-xl font-medium text-900 mb-2"
            />
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="grid grid-nogutter border-top-1 surface-border pt-2">
        <div class="col-12 sm:col-4 p-3">
          <div class="col-12">
            <div class="text-500 font-medium mb-2">{{ t('dataExplorer.addressFile.fields.address1') }}</div>
            <div class="text-900">{{ selectedData.address1 || '-' }}</div>
          </div>
          <div class="col-12">
            <div class="text-500 font-medium mb-2">{{ t('dataExplorer.addressFile.fields.address2') }}</div>
            <div class="text-900">{{ selectedData.address2 || '-' }}</div>
          </div>
          <div class="col-12">
            <div class="text-500 font-medium mb-2">{{ t('dataExplorer.addressFile.fields.postcode') }}</div>
            <div class="text-900">{{ selectedData.postcode || '-' }}</div>
          </div>
          <div class="col-12">
            <div class="text-500 font-medium mb-2">{{ t('dataExplorer.addressFile.fields.city') }}</div>
            <div class="text-900">{{ selectedData.city || '-' }}</div>
          </div>
          <div class="col-12">
            <div class="text-500 font-medium mb-2">{{ t('dataExplorer.addressFile.fields.country') }}</div>
            <div class="text-900">{{ country.label }}</div>
          </div>
          <div class="col-12">
            <div class="text-500 font-medium mb-2">{{ t('dataExplorer.addressFile.fields.tel') }}</div>
            <div class="text-900">{{ formatPhoneNumber(selectedData.phone1, country.code) }}</div>
          </div>
          <div class="col-12">
            <div class="text-500 font-medium mb-2">{{ t('dataExplorer.addressFile.fields.mobile') }}</div>
            <div class="text-900">{{ formatPhoneNumber(selectedData.phone2, country.code) }}</div>
          </div>
        </div>
        <div class="col-12 sm:col-8 p-3">
          <AddressMap
            :address="selectedData"
            class-name="h-16rem sm:h-full"
          />
        </div>
        <div class="col-12 p-3">
          <List
            :title="t('dataExplorer.addressFile.fields.orders.invoiceOrders')"
            :list="selectedData.invoiceOrders.data"
            :retrieve-data="getAddressOrders"
            :retrieve-data-arguments="[selectedData.idAddressShop, 'so.id_address_invoice']"
            :data-loaded="selectedData.invoiceOrders.dataLoaded"
            :no-column="true"
            :total="selectedData.invoiceOrders.total"
            :current-page="selectedData.invoiceOrders.currentPage"
            key-name="idShopOrder"
            @loaded="(orders, total) => handleRetrieveOrders(orders, 'invoiceOrders', total)"
            @update-page="(currentPage) => handleOrdersNextPage(currentPage, 'invoiceOrders')"
          >
            <template #content="{ item }">
              <OrderItem
                :order="item"
                key-name="invoiceOrders"
              />
            </template>
          </List>
        </div>
        <div class="col-12 p-3">
          <List
            :title="t('dataExplorer.addressFile.fields.orders.deliveryOrders')"
            :list="selectedData.deliveryOrders.data"
            :retrieve-data="getAddressOrders"
            :retrieve-data-arguments="[selectedData.idAddressShop, 'so.id_address_delivery']"
            :data-loaded="selectedData.deliveryOrders.dataLoaded"
            :no-column="true"
            :total="selectedData.deliveryOrders.total"
            :current-page="selectedData.deliveryOrders.currentPage"
            key-name="idShopOrder"
            @loaded="(orders, total) => handleRetrieveOrders(orders, 'deliveryOrders', total)"
            @update-page="(currentPage) => handleOrdersNextPage(currentPage, 'deliveryOrders')"
          >
            <template #content="{ item }">
              <OrderItem
                :order="item"
                key-name="deliveryOrders"
              />
            </template>
          </List>
        </div>
      </div>
    </template>
  </Card>
</template>


<script lang="ts">
import { computed, defineComponent } from 'vue';

import Card from 'primevue/card';

import List from '@/components/data-explorer/fields/List.vue';
import DataExplorerLink from '@/components/data-explorer/fields/DataExplorerLink.vue';
import OrderItem from '@/components/data-explorer/fields/OrderItem.vue';
import AddressMap from '@/components/data-explorer/fields/AddressMap.vue';

import {
  getAddressOrders,
} from '@/composables/data-explorer/DataExplorer';

import { countries } from '@/types/country-language-options';
import {
  Order,
} from '@/types/data-explorer-types';

import { useStore } from '@/store';

import { useI18n } from 'vue-i18n';
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';

export default defineComponent({
  name: 'AddressFile',

  components: {
    Card,
    DataExplorerLink,
    List,
    OrderItem,
    AddressMap,
  },

  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const selectedData = computed(() => store.getters['dataExplorer/getSelectedData']);
    const country = computed(() => {
      if (selectedData.value) {
        const item = countries.find((countryItem) => countryItem.code.toLowerCase() === (selectedData.value.country || '').toLowerCase());
        if (item) {
          return {
            code: item.code,
            label: item.label,
          };
        }
      }
      return {
        label: '-',
        code: '',
      };
    });
    
    const title = computed(() => {
      if (selectedData.value) {
        return t(`dataExplorer.tabs.${selectedData.value.dataType}`, [selectedData.value.tabLabel])
      }
      return '';
    });

    const circleOptions = {
      strokeColor: '#629d03',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#629d03',
      fillOpacity: 0.35,
    };

    const formatPhoneNumber = (phoneNumber: string, country: string) => {
      if (phoneNumber && country) {
        const phoneNumberObj = parsePhoneNumberFromString(phoneNumber, country.toUpperCase() as CountryCode);
        if (phoneNumberObj && phoneNumberObj.isValid()) {
          return phoneNumberObj.formatInternational();
        }
        if (phoneNumber) {
          return phoneNumber;
        }
      }
      return '-';
    };

    const handleRetrieveOrders = (orders: Array<Order>, dataKey: string, total: number) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          newLists: orders,
          dataKey,
          total,
        }
      );
    };

    const handleOrdersNextPage = (currentPage: number, dataKey: string) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          currentPage,
          dataKey,
        }
      );
    };

    return {
      t,
      locale,
      selectedData,
      title,
      country,
      circleOptions,

      getAddressOrders,
      formatPhoneNumber,
      handleRetrieveOrders,
      handleOrdersNextPage,
    }
  }
})
</script>

<style lang="scss">
.address-file-card {
  box-shadow: none !important;
  border: 1px solid lightgray;
  border-radius: 5px;
}
</style>
