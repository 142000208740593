<template>
  <PanelMenu
    v-model:expandedKeys="expandedKeysLocal"
    :model="items"
    class="spm-panel-menu"
  >
    <template #item="{ item }">
      <div v-if="!item.hide">
        <router-link
          v-if="item.to"
          v-slot="{ navigate }"
          :to="item.to"
          custom
        >
          <div
            class="align-items-center"
            style="padding: 0.5rem !important;"
            @click="navigate"
          >
            <span>
              <i
                v-if="item.icon"
                class="mr-2 fa-fw menu-icon"
                :class="item.icon"
                :title="item.label"
              />
              <span
                v-if="item.logo"
                class="menu-logo"
              >
                <img
                  :src="item.logo"
                  :alt="item.label"
                />
              </span>
              <span
                class="menu-label"
                :class="{ 'with-logo': item.logo, 'with-legend': item.legend }"
              >
                {{ item.label }}
                <span
                  v-if="item.legend"
                  class="menu-legend">
                  {{ item.legend }}
                </span>
              </span>
            </span>
          </div>
        </router-link>
        <SpmPanelMenuItemCommand
          v-else-if="item.callback || item.command"
          :item="item"
          :data="data"
        />
        <div v-else-if="item.separator">
          <div
            class="p-menuitem-separator"
            role="separator"
          />
        </div>
        <div
          v-else
          style="padding: 0.5rem !important;"
          @click="item.commandRoot"
        >
          <span>
            <i
              v-if="item.icon"
              class="mr-2 fa-fw menu-icon"
              :class="item.icon"
              :title="item.label"
            />
            <span
              v-if="item.logo"
              class="menu-logo"
            >
              <img
                :src="item.logo"
                :alt="item.label"
              />
            </span>
            <span
              class="menu-label"
              :class="{ 'with-logo': item.logo, 'with-legend': item.legend }"
            >
              {{ item.label }}
              <span
                v-if="item.legend"
                class="menu-legend">
                {{ item.legend }}
              </span>
            </span>
          </span>
          <span style="float:right;">
            <i
              class="right-icon-item fa-regular fa-angle-down"
              :class="{ 'itemExpanded': Object.keys(expandedKeysLocal).includes(item.key) }"
            />
          </span>
        </div>
      </div>
    </template>
  </PanelMenu>
</template>

<script lang="ts">
import {
  defineComponent, ref, Ref, SetupContext, watch,
} from 'vue';
import PanelMenu from 'primevue/panelmenu';
import SpmPanelMenuItemCommand from '@/components/spm-primevue/SpmPanelMenuItemCommand.vue';

export default defineComponent({
  name: 'SpmPanelMenu',

  components: {
    PanelMenu,
    SpmPanelMenuItemCommand,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },

    expandedKeys: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    data: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  emits: ['update:expandedKeys'],

  setup(props, { emit }: SetupContext) {
    const expandedKeysLocal: Ref<any> = ref(props.expandedKeys);

    watch(() => props.expandedKeys, () => {
      expandedKeysLocal.value = props.expandedKeys;
    });

    watch(expandedKeysLocal, () => {
      emit('update:expandedKeys', expandedKeysLocal.value);
    }, { deep: true });

    return {
      expandedKeysLocal,
    };
  },
});
</script>

<style lang="scss">
.spm-panel-menu {
  width: 100%;
  .p-panelmenu-panel {
    &:not(:last-child) {
      margin-bottom: 0.3rem !important;
    }

    .p-panelmenu-header {
      cursor: pointer;

      &:not(.p-disabled):focus .p-panelmenu-header-content {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none !important;
      }

      .p-panelmenu-header-content {
        background-color: inherit !important;
        border: none !important;
        color: #4b5563 !important;
        font-size: 0.9rem !important;

        a {
          color: #4b5563 !important;
        }
        &:hover {
          border-radius: 0.3rem !important;
          background-color: #e9ecef !important;
          color: #111827 !important;
        }

        span {
          i {
            font-size: 1.2rem;
          }
          line-height: 1.5rem;

          &.with-logo {
            line-height: 40px;
            display: inline-block;

            &.with-legend {
              line-height: normal;
            }
          }

          & .menu-legend {
            display: block;
            font-size: 0.8rem;
            color: $heather;
          }

          &.menu-logo {
            display: inline-block;
            width: 40px;
            height: 40px;
            margin-right: 10px;
            vertical-align: top;
            border-radius: 50%;
            background-color: #fff;
            position: relative;

            & > img {
              width: 100%;
              height: auto;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              border-radius: 50%;
            }
          }
        }
      }
    }

    &.activeMenuItem {
      .p-panelmenu-header {
        .p-panelmenu-header-content {
          border-radius: 0.3rem !important;
          background-color: #e9ecef !important;
          color: #111827 !important;
        }
      }
    }

    .p-toggleable-content {
      padding-left: 1rem;
      .p-panelmenu-content {
        background-color: inherit;
        padding: 0.5rem !important;
        padding-left: 0 !important;
        border: none !important;
        color: #4b5563 !important;

        .p-menuitem {
          cursor: pointer;
          .p-menuitem-content {
            background-color: inherit !important;
            border: none !important;
            color: #4b5563 !important;
            margin-bottom: 0.3rem;
            font-size: 0.9rem !important;

            a {
              color: #4b5563 !important;
            }
            &:hover, &.activeMenuItem {
              border-radius: 0.3rem !important;
              background-color: #e9ecef !important;
              color: #111827 !important;
            }

            span {
              i {
                font-size: 1.2rem;
              }
              line-height: 1.5rem;

              &.with-logo {
                line-height: 40px;
                display: inline-block;

                &.with-legend {
                  line-height: normal;
                }
              }

              & .menu-legend {
                display: block;
                font-size: 0.8rem;
                color: $heather;
              }

              &.menu-logo {
                display: inline-block;
                width: 40px;
                height: 40px;
                margin-right: 10px;
                vertical-align: top;
                border-radius: 50%;
                background-color: #fff;
                position: relative;

                & > img {
                  width: 100%;
                  height: auto;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  border-radius: 50%;
                }
              }
            }
          }

          &.activeMenuItem {
            .p-menuitem-content {
              border-radius: 0.3rem !important;
              background-color: #e9ecef !important;
              color: #111827 !important;
            }
          }
        }
      }
    }

    .right-icon-item {
      transition: transform ease-in-out 0.2s;

      &.itemExpanded {
        transform: rotate(180deg);
      }
    }
  }

  .menu-label-star--premium {
    height: 26px;
    width: 26px;
    color: $silver;
  }
  .menu-label-star--ultimate {
    height: 26px;
    width: 26px;
    color: $gold;
  }
}

.p-menuitem-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}

@keyframes custom-spinner-color {
  100%, 0% {
    stroke: grey;
  }
  40% {
    stroke: grey;
  }
  66% {
    stroke: grey;
  }
  80%, 90% {
    stroke: grey;
  }
}

@-webkit-keyframes custom-spinner-color {
  100%, 0% {
    stroke: grey;
  }
  40% {
    stroke: grey;
  }
  66% {
    stroke: grey;
  }
  80%, 90% {
    stroke: grey;
  }
}

.p-progress-spinner {
  margin: unset !important;
}

.p-progress-spinner-circle {
  stroke-dasharray: 89, 200;
  stroke-dashoffset: 0;
  stroke: grey;
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite, custom-spinner-color 6s ease-in-out infinite !important;
  stroke-linecap: round;
}
</style>
