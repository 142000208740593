import { createI18n } from 'vue-i18n';

export const DEFAULT_LOCALE = 'fr';
export const SUPPORTED_LOCALES = ['en', DEFAULT_LOCALE];

export function getMatchedLocale(browserLanguages: readonly string[], supportedLocales: string[]): string {
  for (let i = 0; i < browserLanguages.length; i++) {
    const matchedLocale = supportedLocales.find(
      (locale: string) => locale.toLowerCase() === browserLanguages[i].toLowerCase(),
    );
    if (matchedLocale) return matchedLocale;
  }

  for (let i = 0; i < browserLanguages.length; i++) {
    const matchedLocale = supportedLocales.find(
      (locale: string) => new RegExp(locale, 'ig').test(browserLanguages[i]) || new RegExp(browserLanguages[i], 'ig').test(locale),
    );
    if (matchedLocale) return matchedLocale;
  }

  return DEFAULT_LOCALE;
}

const browserLanguages = window.navigator.languages;
const locale = browserLanguages ? getMatchedLocale(browserLanguages, SUPPORTED_LOCALES) : DEFAULT_LOCALE;

export const i18n = (function setupI18N() {
  // eslint-disable-next-line @typescript-eslint/no-var-requires,import/no-dynamic-require,global-require
  const messages = require(`./${locale}.json`);

  return createI18n({
    legacy: false,
    locale,
    fallbackLocale: DEFAULT_LOCALE,
    messages: { [locale]: messages },
  });
}());

export const translate = (key: string, named?: any) => {
  if (!key) {
    return '';
  }
  let translation = key;
  i18n.then((t: any) => {
    translation = named ? t(key, named) : t(key);
    return translation;
  });
  return translation;
};
