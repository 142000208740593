import { DataViewerState } from '@/types/store-types';
import { generateSha1Hash } from '@/helpers';

export default {
  namespaced: true,
  state: (): DataViewerState => ({
    isVisible: false,
    hasAny: false,
    elements: [],
    index: -1,
    activeElement: null,
  }),

  getters: {
    getIsVisible: (state: DataViewerState) => state.isVisible,
    getElements: (state: DataViewerState) => state.elements,
    getActiveElement: (state: DataViewerState) => state.activeElement,
    getActiveIndex: (state: DataViewerState) => state.index,
  },

  mutations: {
    setIsVisible: (state: DataViewerState, visible: boolean) => {
      state.isVisible = visible;
    },
    setConfiguration: (state: DataViewerState, payload: { name: string; value: any }) => {
      if (state.activeElement) {
        state.activeElement.configuration[payload.name] = payload.value;
      }
    },
    addActiveElement: (state: DataViewerState, payload: any) => {
      const newElement = payload;

      // Calculate payload hash
      const sha1 = generateSha1Hash(JSON.stringify(newElement));
      const index = state.elements.findIndex((element) => element.hash === sha1);

      if (index === -1) {
        // Element is not in state
        newElement.hash = sha1;

        state.elements.push(newElement);
        state.index = state.elements.length - 1;
        state.activeElement = state.elements[state.index];
        state.hasAny = true;
      } else if (state.index !== index) {
        // Element is already in state, we open the current instance
        state.activeElement = state.elements[index];
        state.index = index;
      }

      state.isVisible = true;
    },
    setActiveElement: (state: DataViewerState, index: number) => {
      if (state.index !== index) {
        state.activeElement = state.elements[index];
        state.index = index;
      }
    },
    closeActiveElement: (state: DataViewerState) => {
      state.elements.splice(state.index, 1);
      state.index -= 1;

      if (state.index === -1 && state.elements.length > 0) {
        // In case we close the first item, we move to the next one
        state.index = 0;
      }
    },
  },

  actions: {},
};
