<template>
  <div class="field-group pt-3">
    <div>
      <div class="field-group-content">
        <div class="field-wrapper">
          <label class="block mb-3">
            {{ t('templateBuilder.panels.widgets.headerChooseEmbedPosition') }}
          </label>
          <div class="p-fluid flex">
            <Button
              icon="far fa-crosshairs"
              :label="t('templateBuilder.fields.embedPosition.choosePosition')"
              class="p-button-secondary"
              :disabled="disabledButton"
              @click="enableSelectorChooser"
            />
          </div>
          <div class="p-fluid flex">
            <div
              class="selector w-full"
            >
              <label class="block mt-3">
                {{ t('templateBuilder.fields.embedPosition.container') }} :
              </label>
              <br>
              <Inplace
                :closable="true"
                close-icon="far fa-check"
                @close="handleInPlaceClose"
              >
                <template #display>
                  <span class="chosen-selector">{{ currentSelector }}</span>
                </template>
                <template #content>
                  <BaseInputText
                    v-model="currentSelector"
                    auto-focus
                  />
                </template>
              </Inplace>
              <Message
                v-if="!selectorExists"
                severity="warn"
                :closable="false"
              >
                {{ t('templateBuilder.embedSelectorNotFound') }}
              </Message>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="field-group pt-3">
    <div>
      <div class="field-group-content">
        <div class="field-wrapper">
          <label class="block mb-3">
            {{ t('templateBuilder.fields.embedPosition.position') }} :
          </label>
          <div class="p-fluid flex">
            <BaseDropdown
              v-model="currentPosition"
              :options="availablePositions"
              class="w-full"
              @update:model-value="handlePositionChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed, defineComponent, onMounted, ref, Ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Button from 'primevue/button';
import Inplace from 'primevue/inplace';
import BaseDropdown from '@/components/fields/partials/BaseDropdown.vue';
import BaseInputText from '@/components/fields/BaseInputText.vue';
import { useStore } from '@/store';
import {
  hideLeftToolbar, insertEmbedTemplateInSelectedElement, resetIframe, setTemplateConfigurationKeyInState, TemplateEditorState, updateSectionsInState,
} from '@/composables/template-editor/TemplateEditor';
import { addEmbedContainersListeners } from '@/components/template-builder/utils/listeners';
import { checkIfSavePointNeeded, createHistory } from '@/composables/template-editor/History';
import { HistoryType } from '@/types';
import { getTemplateIframeDocument } from '@/components/template-builder/utils/helpers';
import Message from 'primevue/message';

export default defineComponent({
  name: 'EmbedPosition',

  components: {
    Button,
    Inplace,
    BaseInputText,
    BaseDropdown,
    Message,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const currentSelector: Ref<string> = ref(TemplateEditorState.template?.configuration.embedSelector);
    const currentPosition: Ref<string> = ref(TemplateEditorState.template?.configuration.embedPosition);
    const disabledButton = computed(() => store.getters['liveEditor/getActiveEmbedPositionChooser']);
    const availablePositions = ref([
      { label: t('templateBuilder.fields.embedPosition.availablePositions.before'), value: 'before' },
      { label: t('templateBuilder.fields.embedPosition.availablePositions.prepend'), value: 'prepend' },
      { label: t('templateBuilder.fields.embedPosition.availablePositions.append'), value: 'append' },
      { label: t('templateBuilder.fields.embedPosition.availablePositions.after'), value: 'after' },
      { label: t('templateBuilder.fields.embedPosition.availablePositions.replace'), value: 'replace' },
    ]);
    const selectorExists = computed(() => getTemplateIframeDocument().querySelector(currentSelector.value));

    const enableSelectorChooser = () => {
      hideLeftToolbar();
      store.commit('liveEditor/toggleEmbedPositionChooser', true);
      addEmbedContainersListeners();
    };

    const handleInPlaceClose = () => {
      checkIfSavePointNeeded().then(() => {
        setTemplateConfigurationKeyInState('embedSelector', currentSelector.value);
        insertEmbedTemplateInSelectedElement();

        const template = getTemplateIframeDocument();
        const spmBody = template.querySelector('#spm_body');
        if (spmBody) {
          spmBody.setAttribute('data-target-element', currentSelector.value);
        }

        resetIframe();

        // Update state
        updateSectionsInState();

        createHistory(HistoryType.EMBED_CHANGE_POSITION);
      });
    };

    const handlePositionChange = () => {
      checkIfSavePointNeeded().then(() => {
        setTemplateConfigurationKeyInState('embedPosition', currentPosition.value);
        insertEmbedTemplateInSelectedElement();

        const template = getTemplateIframeDocument();
        const spmBody = template.querySelector('#spm_body');
        if (spmBody) {
          spmBody.setAttribute('data-target-element-position', currentPosition.value);
        }

        resetIframe();

        // Update state
        updateSectionsInState();

        createHistory(HistoryType.EMBED_CHANGE_POSITION);
      });
    };

    watch(currentSelector, (newValue) => {
      if (newValue === 'body') {
        currentPosition.value = 'prepend';
        availablePositions.value = [
          { label: t('templateBuilder.fields.embedPosition.availablePositions.prepend'), value: 'prepend' },
          { label: t('templateBuilder.fields.embedPosition.availablePositions.append'), value: 'append' },
        ];
        handlePositionChange();
      } else {
        availablePositions.value = [
          { label: t('templateBuilder.fields.embedPosition.availablePositions.before'), value: 'before' },
          { label: t('templateBuilder.fields.embedPosition.availablePositions.prepend'), value: 'prepend' },
          { label: t('templateBuilder.fields.embedPosition.availablePositions.append'), value: 'append' },
          { label: t('templateBuilder.fields.embedPosition.availablePositions.after'), value: 'after' },
          { label: t('templateBuilder.fields.embedPosition.availablePositions.replace'), value: 'replace' },
        ];
      }
    });

    onMounted(() => {
      if (currentSelector.value === 'body') {
        availablePositions.value = [
          { label: t('templateBuilder.fields.embedPosition.availablePositions.prepend'), value: 'prepend' },
          { label: t('templateBuilder.fields.embedPosition.availablePositions.append'), value: 'append' },
        ];
      }
    });

    return {
      t,
      enableSelectorChooser,
      handlePositionChange,
      handleInPlaceClose,
      currentSelector,
      disabledButton,
      currentPosition,
      availablePositions,
      selectorExists,
    };
  },
});
</script>

<style scoped lang="scss">
.selector:deep() {
  & .p-inplace {
    & .p-inplace-display {
      padding: 0.5rem 0;
      box-shadow: none !important;

      & .chosen-selector {
        color: $brand-color-primary !important;
        font-size: 0.95rem;
        font-family: "Source Sans Pro", sans-serif;
      }

      &:not(.p-disabled):hover {
        background: none !important;
      }
    }

    & .p-inplace-content {
      & .input-variables-block {
        width: calc(100% - 2.357rem);

        & input {
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      & button {
        background: $white;
        border: solid 1px $solitude;
        border-left: none;
        color: $heather;
        box-shadow: none !important;
      }
    }
  }
}
</style>
