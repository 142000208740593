// eslint-disable-next-line import/no-cycle
import { TemplatesCustom } from '@/types/generated-types/graphql';
// eslint-disable-next-line import/no-cycle
import { asInt } from '@/helpers';
// eslint-disable-next-line import/no-cycle
import {
  AutomatedScenarioState as automatedScenarioState,
  getAllParentsOfBoxToRoot, OperatorAddType,
} from '@/composables/AutomatedScenarios';
// eslint-disable-next-line import/no-cycle
import { Request } from '../GraphQL';

/* Different enums */

export const sendingChannelBoxes = ['boxsendemail', 'boxsendsms', 'boxdisplayfreepopup', 'boxdisplayfreemodel', 'boxsendfbmessenger', 'boxsendpushnotification'];
export enum reusePreviousCouponValues {
  CONFIGURE_NEW_VOUCHER = 0,
  REUSE_FROM_PREVIOUS_BOX = 1,
  USE_EXPIRING_VOUCHER = 2,
}
export enum voucherMethodsValues {
  MANUAL = 2,
  AUTOMATIC = 3,
}
export enum voucherTypeValues {
  PERCENTAGE = 1,
  AMOUNT = 2,
  SHIPPING_FEES = 3,
  DUPLICATED = 4,
}

export const getTemplateAndRedirect = async (idTemplate: number) => {
  const query = 'query( $idTemplates: [Int])'
    + '{ TemplatesListRecursive(id_template: $idTemplates){'
    + 'id_template, imageKey, type, label, template_config, subject}}';
  const variables = {
    idTemplates: idTemplate,
  };

  const {
    data,
    err,
  } = await Request<TemplatesCustom[]>({
    name: 'TemplatesListRecursive',
    query,
    variables,
  });

  if (err) {
    throw new Error(err);
  }

  return data;
};

export const checkIfTemplateOrRedirectContainsVoucher = (templates: TemplatesCustom[]) => {
  let containsVoucher = false;

  if (templates !== null && templates.length) {
    templates.forEach((template) => {
      if (typeof template.template_config === 'string') {
        const templateConfig = JSON.parse(template.template_config);
        if (templateConfig.hasVoucher) {
          containsVoucher = true;
          return false;
        }
      }

      return true;
    });
  }

  return containsVoucher;
};

export const getConfigSendingChannelBox = async (idTemplate: number, typeBox: string) => {
  const listTemplate = await getTemplateAndRedirect(idTemplate);
  const configSendingChannelBox = {
    listTemplate,
    templatesContainsVoucher: false,
    parentsFilterHaveExpiredVoucherSegment: false,
    parentsOperatorAlreadyConfiguredVoucher: false,
  };

  if (listTemplate !== null) {
    configSendingChannelBox.templatesContainsVoucher = checkIfTemplateOrRedirectContainsVoucher(listTemplate);
  }

  /*
    Si le template contient des bons de réductions,
    Vérifier les deux autres paramètres :
     parentsFilterHaveExpiredVoucherSegment
     parentsOperatorAlreadyConfiguredVoucher
   */
  if (configSendingChannelBox.templatesContainsVoucher) {
    const baseOperatorId = typeof (automatedScenarioState.selectedOperator.operatorId) !== 'undefined'
    && automatedScenarioState.selectedOperator.operatorId !== ''
      ? automatedScenarioState.selectedOperator.operatorId : false;

    if (baseOperatorId) {
      const ongoingAction = automatedScenarioState.selectedOperator.operatorAddMethod;
      const parentsOfThatBox = getAllParentsOfBoxToRoot(baseOperatorId);

      // si c'est un ajout en dessous, prendre en compte l'opérateur selectionner parmis les parents à vérifier.
      if (ongoingAction === OperatorAddType.ADD_BELOW) {
        parentsOfThatBox.push(baseOperatorId);
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const key of parentsOfThatBox) {
        const operatorCustomData = automatedScenarioState.scenario.data.operators[key].custom;
        if (operatorCustomData.id === 'boxfiltre_3') {
          const segmentInclusion = Object.keys(operatorCustomData.inclusion);
          const segmentExclusion = Object.keys(operatorCustomData.exclusion);

          if (segmentInclusion.includes('filterhasvouchernearexpiration') || segmentExclusion.includes('filterhasvouchernearexpiration')) {
            configSendingChannelBox.parentsFilterHaveExpiredVoucherSegment = true;
          }
        } else if (sendingChannelBoxes.includes(operatorCustomData.id)) {
          // vérifier si le modèle de la boite ou l'une de ses redirection à un bon de réduction
          // eslint-disable-next-line no-await-in-loop
          const listOfThisTemplate = await getTemplateAndRedirect(operatorCustomData.template_display_selector_id);
          const thisTemplateHasVoucher = listOfThisTemplate !== null
            ? checkIfTemplateOrRedirectContainsVoucher(listOfThisTemplate) : false;

          if (thisTemplateHasVoucher && asInt(operatorCustomData.reuse_a_previous_coupon) === 0) {
            configSendingChannelBox.parentsOperatorAlreadyConfiguredVoucher = true;
          }
        }

        if (configSendingChannelBox.parentsFilterHaveExpiredVoucherSegment
          && configSendingChannelBox.parentsOperatorAlreadyConfiguredVoucher) {
          break;
        }
      }
    }
  }

  return configSendingChannelBox;
};

/*
  Def : Checks the compatibility of an operator with its parents
  Returns true if the verified operator is compatible with its parents
 */
export const checkIfSendingChannelBoxLostConfig = async (operatorId: string) => {
  if (typeof (operatorId) === 'undefined') {
    return true;
  }
  let hasLostConfig = false;
  const data = automatedScenarioState.scenario.data.operators[operatorId].custom;

  if ([reusePreviousCouponValues.REUSE_FROM_PREVIOUS_BOX, reusePreviousCouponValues.USE_EXPIRING_VOUCHER].includes(data?.reuse_a_previous_coupon)) {
    /* Si la boite est configuré pour utilisé le bon de réduction d'une précedente boite
      => Vérifier si y a toujours une boite parente ayant configurer un bon de réduction
      Si la boite est configuré pour utilisé un bon de réduction ayant expiré
      => Vérifier si y a toujours une boite parente ayant configurer le segment "A un bon de réduction arrivant à expiration"
     */
    const parentsOfThatBox = getAllParentsOfBoxToRoot(operatorId);
    hasLostConfig = true;
    // eslint-disable-next-line no-restricted-syntax
    for (const key of parentsOfThatBox) {
      const operatorCustomData = automatedScenarioState.scenario.data.operators[key].custom;
      if (data?.reuse_a_previous_coupon === reusePreviousCouponValues.USE_EXPIRING_VOUCHER
        && operatorCustomData.id === 'boxfiltre_3') {
        const segmentInclusion = Object.keys(operatorCustomData.inclusion);
        const segmentExclusion = Object.keys(operatorCustomData.exclusion);
        hasLostConfig = !(segmentInclusion.includes('filterhasvouchernearexpiration') || segmentExclusion.includes('filterhasvouchernearexpiration'));
      } else if (data?.reuse_a_previous_coupon === reusePreviousCouponValues.REUSE_FROM_PREVIOUS_BOX
        && sendingChannelBoxes.includes(operatorCustomData.id)) {
        // vérifier si le modèle de la boite ou l'une de ses redirection à un bon de réduction
        // eslint-disable-next-line no-await-in-loop
        const listOfThisTemplate = await getTemplateAndRedirect(operatorCustomData.template_display_selector_id);
        const thisTemplateHasVoucher = listOfThisTemplate !== null
          ? checkIfTemplateOrRedirectContainsVoucher(listOfThisTemplate) : false;
        hasLostConfig = !(thisTemplateHasVoucher && asInt(operatorCustomData.reuse_a_previous_coupon) === 0);
      }

      if (!hasLostConfig) {
        break;
      }
    }
  }

  return hasLostConfig;
};
