
import { useI18n } from 'vue-i18n';
import { defineComponent, ref } from 'vue';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { programSync } from '@/composables/shop/ShopSyncs';
import { UserState } from '@/composables/User';
import { ProgramShopsSyncs } from '@/types/generated-types/graphql';
import { RequestResult } from '@/composables/GraphQL';
import { showToastError, showToastSuccess } from '@/helpers';

export default defineComponent({
  name: 'Synchronization',

  components: {
    SpmOverlayPanel,
    SpmPanelMenu,
    Dialog,
    InputText,
    SpmButton,
  },

  props: {
    synchroType: {
      type: String,
      required: true,
    },
  },

  emits: ['synchro-launched'],

  setup(props: {synchroType: string}, context) {
    const { t } = useI18n();
    const type = ref('');
    const showDialog = ref(false);
    const syncId = ref('');
    const contentText = ref('');
    const items = [
      {
        icon: '',
        label: t('myStoreMenu.synchronisation.byId'),
        command: () => {
          contentText.value = t('myLists.disclaimers.confirm_sync_id');
          type.value = 'synchroById';
          showDialog.value = true;
        },
      },
      {
        icon: '',
        label: t('myStoreMenu.synchronisation.remainingData'),
        command: async () => {
          contentText.value = t('myLists.disclaimers.confirm_missed_sync');
          type.value = 'remainingData';
          showDialog.value = true;
        },
      },
      {
        icon: '',
        label: t('myStoreMenu.synchronisation.completeResynchro'),
        command: async () => {
          contentText.value = t('myLists.disclaimers.confirm_force_sync');
          type.value = 'completeResynchro';
          showDialog.value = true;
        },
      },
    ];
    const idShop = UserState.activeShop?.id ?? 0;
    const error = ref(false);
    const errorKey = ref(0);

    const validate = async () => {
      const regExp = new RegExp('^\\d+(,\\d+)*$', 'is');
      const matches = regExp.test(syncId.value);
      if (!matches) {
        error.value = true;
        setTimeout(() => {
          error.value = false;
          errorKey.value += 1;
        }, 3000);
      }
      return matches;
    };

    const programShopSync = async (overrideType: string) => {
      let actionType = 'shop_id';

      if (type.value === 'synchroById') {
        if (!await validate()) {
          return false;
        }
      }

      if (type.value === 'remainingData' || overrideType === 'remainingData') {
        actionType = 'missed';
      } else if (type.value === 'completeResynchro' || overrideType === 'completeResynchro') {
        actionType = 'all';
      }

      const result: RequestResult<ProgramShopsSyncs> = await programSync(props.synchroType, idShop, (type.value === 'synchroById') ? [syncId.value] : null,
        actionType, 'user');

      if (result.data && result.data?.total > 0) {
        await showToastError(t('myLists.disclaimers.synchro_already_in_progress'));
        context.emit('synchro-launched');
      } else if (result.data && result.data?.error !== '') {
        await showToastError(result.data?.error);
      } else {
        await showToastSuccess(t('myLists.disclaimers.synchro_success'));
        context.emit('synchro-launched');
      }
      showDialog.value = false;
      return true;
    };

    return {
      t, items, showDialog, syncId, contentText, programShopSync, type, error, errorKey,
    };
  },
});
