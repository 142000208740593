import { ComponentNameEnum, DropdownOption } from '@/types';

const getTextareaVariablesFieldConfig = (defaultValue = '', variablesList: DropdownOption[], displayEmojis = false) => ({
  componentName: ComponentNameEnum.TEXTAREA_VARIABLES,
  options: {
    defaultValue,
    variablesList,
    displayEmojis,
  },
});

export default getTextareaVariablesFieldConfig;
