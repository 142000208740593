<template>
  <div
    v-if="shopCurrentVersionOK"
    class="flex justify-content-between flex-wrap mb-3"
  >
    <div class="flex align-items-center justify-content-center">
      <h3>
        {{ t('myLists.pushToken.title') }}
      </h3>
    </div>
  </div>
  <div class="flex justify-content-between flex-wrap mb-1">
    <div class="flex-1 align-items-center justify-content-center">
      <Message severity="info">
        {{ t('myLists.pushToken.pushKeys.text') }}<br>
        <b>{{ t('myLists.pushToken.pushKeys.pubKey') }} :</b> {{ publicKey }}<br>
        <b>{{ t('myLists.pushToken.pushKeys.priKey') }} :</b> {{ privateKey }}
      </Message>
    </div>
  </div>
  <div class="lists-push-tokens">
    <SpmTable
      v-if="shopCurrentVersionOK"
      name="ShopsPushToken"
      index="id_shop_push_token"
      expand-rows
      :id-shop="idShop"
      :table-columns="columns"
      :persistent-filters="persistentFilters"
      :custom-selector="true"
      :show-export-menu-item="findPermission('my_push_notif.export')"
      grouped-actions-key="id_shop_push_token"
      :export-file-name="t('myLists.pushToken.title')"
    >
      <template #expandedDetails="slotProps">
        <section :key="slotProps.data.id_shop_push_token">
          <div class="mb-2">
            <span class="font-bold">{{ t('myLists.pushToken.headers.push_endpoint') }}</span>
            <pre class="ml-4">{{ slotProps.data.push_endpoint }}</pre>
          </div>
          <div class="mb-2">
            <span class="font-bold">{{ t('myLists.pushToken.headers.push_key') }}</span>
            <pre class="ml-4">{{ slotProps.data.push_key }}</pre>
          </div>
          <div>
            <span class="font-bold">{{ t('myLists.pushToken.headers.push_token') }}</span>
            <pre class="ml-4">{{ slotProps.data.push_token }}</pre>
          </div>
        </section>
      </template>
    </SpmTable>

    <div
      v-if="!shopCurrentVersionOK"
      class="m-b-30 text-center"
    >
      <Card class="mt-6">
        <template #header>
          <i
            class="fa fa-cogs fa-5x p-m-3"
            style="color:#cbe4a2"
          />
        </template>
        <template #title>
          {{ t("myLists.pushToken.outOfDate.title") }}
        </template>
        <template #content>
          <div class="mt-3">
            {{ t('myLists.pushToken.outOfDate.text') }}
          </div>
          <div class="mt-3">
            <router-link :to="downloadURL">
              <Button
                icon="far fa-save"
                class="mr-1 p-button-lg p-button-secondary"
                :label="t('myLists.pushToken.outOfDate.buttonText')"
              />
            </router-link>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import {
  computed, defineComponent, onMounted, ref,
} from 'vue';
import Card from 'primevue/card';
import Button from 'primevue/button';
import { SpmTableColumns, SpmTableFilter } from '@/types';
import { OperatorType, PushTokenParams } from '@/types/generated-types/graphql';
import SpmTable from '@/components/table/SpmTable.vue';
import { Request } from '@/composables/GraphQL';
import { findPermission, UserState } from '@/composables/User';
import Message from 'primevue/message';
import { getShopCustomerLanguages } from '@/types/country-language-options';

export default defineComponent({
  name: 'ListsPushTokens',
  components: {
    Card,
    Button,
    SpmTable,
    Message,
  },

  setup() {
    const { t } = useI18n();
    const idShop = UserState?.activeShop?.id ?? 0;

    const browserList = [
      { value: 'Firefox', label: 'Firefox' },
      { value: 'Chrome', label: 'Chrome' },
      { value: 'Edge', label: 'Edge' },
      { value: 'Opera', label: 'Opera' },
      { value: 'Chromium', label: 'Chromium' },
      { value: 'Konqueror', label: 'Konqueror' },
      { value: 'Safari', label: 'Safari' },
    ];

    const platformList = [
      { value: 'Desktop', label: 'Desktop' },
      { value: 'Mobile', label: 'Mobile' },
      { value: 'Tablet', label: 'Tablet' },
    ];

    const columns: SpmTableColumns[] = [
      {
        field: 'id_shop_push_token',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
      {
        field: 'date_modification',
        header: t('myLists.pushToken.headers.date_modification'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
      },
      {
        field: 'id_shop_customer',
        header: t('id'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'browser_name',
        header: t('myLists.pushToken.headers.browser_name'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'multiSelect', options: browserList, hideFilterMenu: true },
      },
      {
        field: 'device',
        header: t('myLists.pushToken.headers.device'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'multiSelect', options: platformList, hideFilterMenu: true },
      },
      {
        field: 'push_endpoint',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
      {
        field: 'push_key',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
      {
        field: 'push_token',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
    ];

    const persistentFilters: SpmTableFilter[] = [
      {
        field: 'id_shop',
        value: UserState?.activeShop?.id ?? 0,
        operator: OperatorType.Equals,
      },
    ];

    const params = ref({
      privateKey: '',
      publicKey: '',
      downloadURL: '',
    });
    const privateKey = computed(() => params.value?.privateKey ?? '');
    const publicKey = computed(() => params.value?.publicKey ?? '');
    const downloadURL = computed(() => params.value?.downloadURL ?? '');
    const shopCurrentVersionOK = computed(() => (UserState.activeShop?.currentVersion ?? '') >= '3.1.0');

    const getParams = async () => {
      const { data, err } = await Request<PushTokenParams>({
        name: 'PushTokenParamsGet',
        variables: { id_shop: UserState?.activeShop?.id ?? 0 },
        query: `query ($id_shop: Int!)
          {
            PushTokenParamsGet(id_shop: $id_shop) {
              privateKey
              publicKey
              downloadURL
            }
          }
        `,
      });

      if (err === '') {
        params.value = {
          privateKey: data?.privateKey ?? '',
          publicKey: data?.publicKey ?? '',
          downloadURL: data?.downloadURL ?? '',
        };
      }
    };

    onMounted(async () => {
      await getParams();
    });

    return {
      t,
      columns,
      persistentFilters,
      shopCurrentVersionOK,
      downloadURL,
      privateKey,
      publicKey,
      idShop,
    };
  },

  methods: { findPermission },
});
</script>

<style lang="scss" >
  pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }

  .alert-light {
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border-radius: .25rem;
    color: #818182;
    background-color: #fefefe;
    position: relative;
    border: 0;
  }
</style>
