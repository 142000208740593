<template>
  <div
    :class="classTrigger"
    @click="toggle"
  >
    <slot name="trigger" />
  </div>
  <OverlayPanel
    ref="op"
    class="spm-overlay-panel"
    append-to="body"
    style="min-width: 240px;"
    :style="'min-width: '+ minPanelWidth +';' + (maxPanelWidth ? 'max-width: '+ maxPanelWidth +';' : '') + styleOverlayPanel"
    :breakpoints="{ '960px': '75vw' }"
    @hide="onHide"
  >
    <div
      v-if="$slots.header"
      :class="classHeader"
    >
      <slot name="header" />
    </div>
    <div
      v-if="$slots.header"
      class="solid"
    />
    <div :class="classContent">
      <slot />
    </div>
    <div
      v-if="$slots.footer"
      class="solid"
    />
    <div
      v-if="$slots.footer"
      :class="classFooter"
    >
      <slot name="footer" />
    </div>
  </OverlayPanel>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, ref, watch,
} from 'vue';
import OverlayPanel from 'primevue/overlaypanel';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'SpmOverlayPanel',

  components: {
    OverlayPanel,
  },

  props: {
    classTrigger: {
      type: String,
      required: false,
      default: '',
    },

    classHeader: {
      type: String,
      required: false,
      default: '',
    },

    classContent: {
      type: String,
      required: false,
      default: '',
    },

    classFooter: {
      type: String,
      required: false,
      default: '',
    },

    closeOnRouteChange: {
      type: Boolean,
      required: false,
      default: true,
    },

    minPanelWidth: {
      type: String,
      required: false,
      default: '240px',
    },

    maxPanelWidth: {
      type: String,
      required: false,
      default: '',
    },

    styleOverlayPanel: {
      type: String,
      required: false,
      default: '',
    },

    closePanel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['onclose-panel'],

  setup(props, context) {
    const op = ref();
    const toggle = (event: Event) => op.value.toggle(event);
    const currentRoute = computed(() => useRoute());

    watch(() => currentRoute.value.name, () => {
      // if route changes, close the overlay panel
      if (props.closeOnRouteChange) {
        op.value.hide();
      }
    });

    watch(() => props.closePanel, () => {
      if (props.closePanel) {
        op.value.hide();
        context.emit('onclose-panel');
      }
    });

    const onHide = () => {
      if (props.closePanel) {
        context.emit('onclose-panel');
      }
    };

    return {
      op,
      toggle,
      onHide,
    };
  },
});
</script>

<style lang="scss">
.spm-overlay-panel {
  box-shadow: unset !important;
  border: 1px solid #e5e7eb !important;
  border-radius: 0.3rem !important;

  &:after, &:before {
    content:none !important;
  }
  div.solid {
    border-top: 1px solid #dee2e6;
  }

}
</style>
