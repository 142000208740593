<template>
  <div class="flex justify-content-between flex-wrap mb-3">
    <div class="flex align-items-center justify-content-center">
      <h3>
        {{ t('myLists.unsub.title') }}
      </h3>
    </div>
    <div class="flex align-items-center justify-content-center">
      <SearchCustomers
        header=""
        type="unsub"
        :original-filters="originalFilters"
        @search-customers="execute"
        @close-panel="changeSearchValues"
      />
      <router-link
        v-if="findPermission('my_unsubscribed.import')"
        :to="{ name: 'lists.import-unsub' }"
      >
        <SpmButton
          :label="t('myLists.unsub.btnImporter')"
          icon="fa-regular fa-upload"
          class-style="p-button p-button-success"
        />
      </router-link>
    </div>
  </div>
  <div class="ListsUnSubscribers">
    <SpmTable
      ref="childComponentRef"
      name="ShopsCustomersManage"
      index="id_email_reject"
      :id-shop="idShop"
      :table-columns="columns"
      :expand-rows="true"
      :persistent-filters="persistentFilters"
      :custom-selector="true"
      grouped-actions-key="id_email_reject"
      :show-export-menu-item="findPermission('my_unsubscribed.export')"
      :export-file-name="t('myLists.unsub.title')"
    >
      <template #summary>
        <div class="p-buttonset mt-3">
          <router-link :to="{ name: 'lists.import-unsub' }">
            <Button
              icon="far fa-plus-square"
              class="mr-1 p-button-lg p-button-secondary"
              :label="t('myLists.unsub.btnImporter')"
            />
          </router-link>
        </div>
      </template>
      <template #expandedDetails="slotProps">
        <section :key="slotProps.data.id_shop_customer">
          <span><b>{{ t('description') }}:</b>&nbsp;{{ determineTypeText(slotProps.data.unsub_type) }}</span>
        </section>
      </template>
    </SpmTable>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { defineComponent, ref } from 'vue';
import {
  OperatorType,
} from '@/types/generated-types/graphql';
import { DataExplorerDataType, SpmTableColumns, SpmTableFilter } from '@/types';
import SpmTable from '@/components/table/SpmTable.vue';
import SearchCustomers from '@/components/mes-lists/SearchCustomers.vue';
import { findPermission, UserState } from '@/composables/User';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { getShopCustomerLanguages } from '@/types/country-language-options';

export default defineComponent({
  name: 'ListsUnSubscribers',
  components: {
    SpmTable,
    SpmButton,
    SearchCustomers,
  },

  setup() {
    const { t } = useI18n();
    const search = ref(false);
    const childComponentRef = ref();
    const idShop = UserState?.activeShop?.id ?? 0;

    const persistentFilters = ref<SpmTableFilter[]>([
      {
        field: 'type',
        value: 'unsub',
        operator: OperatorType.Equals,
      },
    ]);

    const determineTypeText = (value: number) => {
      /* eslint no-bitwise: ["error", { "allow": ["&"] }] */
      const textStr: string[] = [];

      if (value === 0) {
        textStr.push(t('none'));
      }
      if ((value & 16) === 16) {
        textStr.push(t('myLists.unsub.icons.noSolicitation'));
      }
      if ((value & 1) === 1) {
        textStr.push(t('myLists.unsub.icons.autoEmail'));
      }

      if ((value & 4) === 4) {
        textStr.push(t('myLists.unsub.icons.newsletter'));
      }

      if ((value & 2) === 2 || (value & 8) === 8) {
        textStr.push(t('myLists.unsub.icons.bulkSms'));
      }

      return textStr.join(',');
    };

    const rejectFromList = [
      { value: 'newsletter', label: 'Newsletter' },
      { value: 'workflow', label: 'Workflow' },
    ];

    const columns: SpmTableColumns[] = [
      {
        field: 'date',
        header: t('myLists.unsub.headers.date'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
        prefix: 'sc',
      },
      {
        field: 'reject_from',
        header: t('From'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'er',
        filterSettings: {
          type: 'multiSelect', options: rejectFromList, hideFilterMenu: true, showFilterInput: false,
        },
      },
      {
        field: 'last_name',
        header: t('myLists.unsub.headers.lastName'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'first_name',
        header: t('myLists.unsub.headers.firstName'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'email',
        header: t('myLists.unsub.headers.email'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'data-explorer-link',
        prefix: 'sc',
        dataExplorerOptions: {
          id: 'id_shop_customer',
          type: DataExplorerDataType.CONTACT_FILE,
        }
      },
      {
        field: 'unsub_type',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
        hidden: true,
      },
      {
        field: 'lang',
        header: t('myLists.unsub.headers.lang'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'language',
        prefix: 'sc',
        filterSettings: { type: 'language', options: getShopCustomerLanguages, hideFilterMenu: true },
      },
      {
        field: 'id_email_reject',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'id_shop_customer',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
    ];

    const originalFilters = persistentFilters.value;

    const changeSearchValues = () => {
      search.value = false;
    };

    const execute = (values: any) => {
      persistentFilters.value = values.value;
    };

    return {
      t,
      columns,
      persistentFilters,
      execute,
      originalFilters,
      changeSearchValues,
      childComponentRef,
      idShop,
      findPermission,
      determineTypeText,
    };
  },
});
</script>
