import { i18n } from '@/i18n';
import {
  ComponentData, ComponentMetadata, ErrorConfigForm, Period,
} from '@/types/automated-scenarios';
import { computed } from 'vue';
import { between, integer, required } from '@vuelidate/validators';
// eslint-disable-next-line import/no-cycle
import { includesValuesValidator } from '@/helpers/CustomValidator';
import useVuelidate from '@vuelidate/core';
import { TypeCampaignEnum } from '@/types';

export interface ActionDelaisMaxData {
  time_unit_select: Period;
}

let translation: any;
(async () => {
  translation = await i18n;
})();

const ActionDelaisMaxMetadata: ComponentMetadata<ActionDelaisMaxData> = {
  Meta: {
    kind: 'action',
    id: 'boxdelaismax',
    label: 'automatedScenarios.actions.maxTime.shortLabel',
    icon: 'fa-alarm-clock',
    hasConfiguration: true,
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxsitepasstime', 'boxperiodiclaunching',
          'boxwait', 'boxtag', 'boxlist',
          'boxsendemail', 'boxsendsms', 'boxabtest',
          'boxdisplayfreepopup', 'boxdisplayfreemodel', 'boxsendfbmessenger',
          'boxsendpushnotification', 'boxfiltre_1', 'boxfiltre_2',
          'boxfiltre_3', 'boxfiltre_4', 'openemail',
          'clicklink', 'boxnavigationtrigger', 'boxdelaismax',
        ],
      },
      bulk: {
        next_operators: [
          'boxabandonpanier', 'boxsitepasstime', 'boxperiodiclaunching',
          'boxwait', 'boxtag', 'boxlist',
          'boxsendemail', 'boxsendsms', 'boxabtest',
          'boxdisplayfreepopup', 'boxdisplayfreemodel', 'boxsendfbmessenger',
          'boxsendpushnotification', 'boxfiltre_1', 'boxfiltre_2',
          'boxfiltre_3', 'boxfiltre_4', 'openemail',
          'clicklink', 'boxnavigationtrigger', 'boxdelaismax',
        ],
      },
    },
    component: 'ActionDelaisMax',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
  },
  Create({ time_unit_select: { value, unit } }: ActionDelaisMaxData = {
    time_unit_select: {
      value: 10,
      unit: 'MINUTE',
    },
  }): ComponentData<ActionDelaisMaxData> {
    return {
      id: 'boxdelaismax',
      form_name: 'boxdelaismax',
      logo_box: 'fa-clock-o',
      time_unit_select: {
        value,
        unit,
      },
    };
  },
  async Validate(data: ActionDelaisMaxData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      time_unit_select: {
        value: {
          required,
          integer,
          between: between(1, 1000),
        },
        unit: {
          required,
          includesValuesValidator: includesValuesValidator([
            'SECOND',
            'MINUTE',
            'HOUR',
            'DAY',
          ]),
        },
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
  Outputs(data: ActionDelaisMaxData): number {
    return 1;
  },
  Label(data: ActionDelaisMaxData): string {
    const unit = translation.global.t(`automatedScenarios.fields.durations.${data.time_unit_select.unit.toLowerCase()}${data.time_unit_select.value > 1 ? 's' : ''}`);
    return translation.global.t('automatedScenarios.actions.maxTime.fullLabel', { duration: `${data.time_unit_select.value} ${unit}` });
  },
};

export default ActionDelaisMaxMetadata;
