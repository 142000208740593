import { ComponentNameEnum, VerticalAlignValueEnum, BaseButtonFieldConfig } from '@/types';

const property = 'vertical-align';

const elementVerticalAlignFieldConfig: BaseButtonFieldConfig = {
  componentName: ComponentNameEnum.BUTTON_GROUP,
  options: {
    [VerticalAlignValueEnum.TOP]: {
      title: 'templateBuilder.fields.alignTop',
      iconClass: 'fa-arrow-up',
      property,
      defaultValue: VerticalAlignValueEnum.TOP,
    },
    [VerticalAlignValueEnum.MIDDLE]: {
      title: 'templateBuilder.fields.alignMiddle',
      iconClass: 'fa-arrow-right',
      property,
      defaultValue: VerticalAlignValueEnum.TOP,
    },
    [VerticalAlignValueEnum.BOTTOM]: {
      title: 'templateBuilder.fields.alignBottom',
      iconClass: 'fa-arrow-down',
      property,
      defaultValue: VerticalAlignValueEnum.TOP,
    },
  },
};

export default elementVerticalAlignFieldConfig;
