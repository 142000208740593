// eslint-disable-next-line import/no-cycle
import { FiltreCustomerFilters } from '@/components/automated-scenarios/metadata/filtres/FiltreCustomerMetadata';
// eslint-disable-next-line import/no-cycle
import { List, ListSettings } from '@/composables/GraphQL';
// eslint-disable-next-line import/no-cycle
import { UserState } from '@/composables/User';
// eslint-disable-next-line import/no-cycle
import getCountries from '@/configs/countries';
// eslint-disable-next-line import/no-cycle
import { asInt, showToastError } from '@/helpers';
import { i18n } from '@/i18n';
// eslint-disable-next-line import/no-cycle
import {
  DropdownOption, StringMap, TypeCampaignEnum, CampaignsBulkStatusEnum,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import { ErrorConfigForm, FilterSegment } from '@/types/automated-scenarios';
// eslint-disable-next-line import/no-cycle
import {
  NewsletterCampaigns,
  OperatorType,
  ShopsCustomersGroupsList,
  ShopsLists,
  ShopsTags,
  CampaignsBulk,
} from '@/types/generated-types/graphql';
import { computed } from 'vue';
import { between, integer, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/no-cycle
import { includesValuesValidator, locationGMapsValidator } from '@/helpers/CustomValidator';

let translation: any;
(async () => {
  translation = await i18n;
})();

const fetchList = async <T>(name: string, settings: ListSettings, fields: string[]): Promise<T[]> => {
  const res = await List<T>(
    {
      name,
      settings,
      fields,
    },
  );
  if (res.err) {
    showToastError('GENERIC_ERROR');
  } else {
    return res.items;
  }
  return [];
};

const fetchGroups = () => {
  const settings = {
    limit: 1000,
    offset: 0,
    order: [],
    filter: [{ field: 'id_shop', operator: OperatorType.Equals, value: UserState.activeShop ? UserState.activeShop.id : 0 }],
  };
  return fetchList<ShopsCustomersGroupsList>('ShopsCustomersGroupsList', settings, ['id_group', 'name']);
};

const fetchNewsletters = () => {
  const settings = {
    limit: 1000,
    offset: 0,
    order: [],
    filter: [
      { field: 'id_shop', operator: OperatorType.Equals, value: UserState.activeShop ? UserState.activeShop.id : 0 },
      { field: 'state', operator: OperatorType.Equals, value: 'terminated' },
    ],
  };
  return fetchList<NewsletterCampaigns>('NewsletterCampaigns', settings, ['id_newsletter_campaign', 'name']);
};

const fetchTags = (tagFilter: string) => {
  const settings = {
    limit: 1000,
    offset: 0,
    order: [],
    filter: [
      { field: 'id_shop', operator: OperatorType.Equals, value: UserState.activeShop ? UserState.activeShop.id : 0 },
      { field: 'tag', operator: OperatorType.StartsWith, value: tagFilter },
    ],
  };
  return fetchList<ShopsTags>('ShopsTags', settings, ['id_shop_tag', 'tag']);
};

const fetchLists = () => {
  const settings = {
    limit: 1000,
    offset: 0,
    order: [],
    filter: [
      { field: 'id_shop', operator: OperatorType.Equals, value: UserState.activeShop ? UserState.activeShop.id : 0 },
    ],
  };
  return fetchList<ShopsLists>('ShopsLists', settings, ['id_shop_list', 'name']);
};

const fetchCampaignBulkLists = (statuses: CampaignsBulkStatusEnum[]) => {
  const settings = {
    limit: 1000,
    offset: 0,
    order: [],
    filter: [
      { field: 'id_shop', operator: OperatorType.Equals, value: UserState.activeShop ? UserState.activeShop.id : 0 },
      { field: 'status', operator: OperatorType.In, value: statuses.join(',') },
    ],
  };
  return fetchList<CampaignsBulk>('CampaignsBulk', settings, ['id_campaign_bulk', 'name']);
};

export default async () => ([
  {
    id: 'filtershopdatecreation',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK, TypeCampaignEnum.CUSTOMER_IMPORT],
    option: { value: 'filtershopdatecreation', label: translation.global.t('automatedScenarios.fields.segments.customersSeniority') },
    fields: [
      {
        id: 'time_unit_select',
        component: 'PeriodSelector',
        data: { value: 1, unit: 'DAY' },
        props: {
          title: 'automatedScenarios.fields.customerRegisteredSince',
          units: [
            { value: 'DAY', label: translation.global.t('automatedScenarios.fields.durations.day(s)') },
            { value: 'MONTH', label: translation.global.t('automatedScenarios.fields.durations.month(s)') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.customerRegisteredSince'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        time_unit_select: {
          value: {
            required,
            integer,
            between: between(1, 1000),
          },
          unit: {
            required,
            includesValuesValidator: includesValuesValidator([
              'DAY',
              'MONTH',
            ]),
          },
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    multiple: true,
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
  },
  {
    id: 'filtercreateaccountfrom',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
    option: { value: 'filtercreateaccountfrom', label: translation.global.t('automatedScenarios.fields.segments.createdAccountFor') },
    fields: [
      {
        id: 'time_unit_select',
        component: 'PeriodSelector',
        data: { value: 1, unit: 'DAY' },
        props: {
          title: 'automatedScenarios.fields.createdAccountFor',
          units: [
            { value: 'DAY', label: translation.global.t('automatedScenarios.fields.durations.day(s)') },
            { value: 'MONTH', label: translation.global.t('automatedScenarios.fields.durations.month(s)') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.createdAccountFor'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        time_unit_select: {
          value: {
            required,
            integer,
            between: between(1, 1000),
          },
          unit: {
            required,
            includesValuesValidator: includesValuesValidator([
              'DAY',
              'MONTH',
            ]),
          },
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
  {
    id: 'filtercreatecartfrom',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
    option: { value: 'filtercreatecartfrom', label: translation.global.t('automatedScenarios.fields.segments.createdCartFor') },
    fields: [
      {
        id: 'time_unit_select',
        component: 'PeriodSelector',
        data: { value: 1, unit: 'DAY' },
        props: {
          title: 'automatedScenarios.fields.createdCartFor',
          units: [
            { value: 'DAY', label: translation.global.t('automatedScenarios.fields.durations.day(s)') },
            { value: 'MONTH', label: translation.global.t('automatedScenarios.fields.durations.month(s)') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.createdCartFor'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        time_unit_select: {
          value: {
            required,
            integer,
            between: between(1, 1000),
          },
          unit: {
            required,
            includesValuesValidator: includesValuesValidator([
              'DAY',
              'MONTH',
            ]),
          },
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxvisitpage',
            'boxsitepasstime',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
      },
      bulk: {},
    },
  },
  {
    id: 'filtergender',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK, TypeCampaignEnum.CUSTOMER_IMPORT],
    option: { value: 'filtergender', label: translation.global.t('automatedScenarios.fields.segments.gender') },
    fields: [
      {
        id: 'gender',
        component: 'MultiDropdown',
        data: [],
        props: {
          label: 'automatedScenarios.fields.onlyCustomers',
          options: [
            { value: 0, label: translation.global.t('automatedScenarios.fields.gender.unknown') },
            { value: 1, label: translation.global.t('automatedScenarios.fields.gender.male') },
            { value: 2, label: translation.global.t('automatedScenarios.fields.gender.female') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.onlyCustomers'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        gender: {
          required,
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    multiple: true,
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
  },
  {
    id: 'filteragerange',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK, TypeCampaignEnum.CUSTOMER_IMPORT],
    option: { value: 'filteragerange', label: translation.global.t('automatedScenarios.fields.segments.ageRange') },
    fields: [
      {
        id: 'ageRange',
        component: 'NumberRange',
        data: { min: 18, max: 25 },
        props: {
          title: 'automatedScenarios.fields.ageRange',
          min: 1,
          max: 130,
          suffix: ` ${translation.global.t('automatedScenarios.fields.yearsOld')}`,
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.ageRange'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        ageRange: {
          required,
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    multiple: true,
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
  },
  {
    id: 'filtergroup',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK, TypeCampaignEnum.CUSTOMER_IMPORT],
    option: { value: 'filtergroup', label: translation.global.t('automatedScenarios.fields.segments.customerGroups') },
    fields: [
      {
        id: 'groups',
        component: 'MultiDropdown',
        data: [],
        props: {
          label: 'automatedScenarios.fields.groups',
          options: (await fetchGroups()).map((item: ShopsCustomersGroupsList) => ({ value: asInt(item.id_group), label: item.name }) as DropdownOption),
          convertValueToInt: true,
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.groups'),
      },
      {
        id: 'operator',
        component: 'ButtonGroup',
        data: 'and',
        props: {
          title: 'automatedScenarios.fields.visitorMustBeIn',
          options: [
            { value: 'and', label: translation.global.t('automatedScenarios.fields.allGroups') },
            { value: 'or', label: translation.global.t('automatedScenarios.fields.atLeastOne') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.visitorMustBeIn'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        groups: {
          required,
        },
        operator: {
          required,
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
  },
  {
    id: 'filterareas',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK, TypeCampaignEnum.CUSTOMER_IMPORT],
    option: { value: 'filterareas', label: translation.global.t('automatedScenarios.fields.segments.geographicArea') },
    fields: [
      {
        id: 'areas',
        component: 'Location',
        data: { 0: {} },
        props: {
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.customerInGeographicArea'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        areas: {
          required,
          locationGMapsValidator: locationGMapsValidator(),
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    multiple: true,
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
  },
  {
    id: 'filtercustomercountrie',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filtercustomercountrie', label: translation.global.t('automatedScenarios.fields.segments.customersCountries') },
    fields: [
      {
        id: 'customers_countries_list',
        component: 'MultiDropdown',
        data: [],
        props: {
          label: 'automatedScenarios.fields.customersCountries',
          options: getCountries(translation.global.t),
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.customersCountries'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        customers_countries_list: {
          required,
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
  },
  {
    id: 'filterbirthdate',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
    option: { value: 'filterbirthdate', label: translation.global.t('automatedScenarios.fields.segments.birthday') },
    fields: [
      {
        id: 'time_unit_select',
        component: 'PeriodSelector',
        data: { value: 1, unit: 'DAY' },
        props: {
          title: 'automatedScenarios.fields.birthdayIsIn',
          units: [
            { value: 'DAY', label: translation.global.t('automatedScenarios.fields.durations.day(s)') },
            { value: 'MONTH', label: translation.global.t('automatedScenarios.fields.durations.month(s)') },
          ],
          minValue: 0,
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.birthdayIsIn'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        time_unit_select: {
          value: {
            required,
            integer,
            between: between(1, 1000),
          },
          unit: {
            required,
            includesValuesValidator: includesValuesValidator([
              'DAY',
              'MONTH',
            ]),
          },
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
  {
    id: 'filtercreationdate',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
    option: { value: 'filtercreationdate', label: translation.global.t('automatedScenarios.fields.segments.accountCreationAnniversary') },
    fields: [
      {
        id: 'time_unit_select',
        component: 'PeriodSelector',
        data: { value: 1, unit: 'DAY' },
        props: {
          title: 'automatedScenarios.fields.accountCreationAnniversaryIsIn',
          units: [
            { value: 'DAY', label: translation.global.t('automatedScenarios.fields.durations.day(s)') },
            { value: 'MONTH', label: translation.global.t('automatedScenarios.fields.durations.month(s)') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.accountCreationAnniversaryIsIn'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        time_unit_select: {
          value: {
            required,
            integer,
            between: between(1, 1000),
          },
          unit: {
            required,
            includesValuesValidator: includesValuesValidator([
              'DAY',
              'MONTH',
            ]),
          },
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
  {
    id: 'filterhasvouchernearexpiration',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
    option: { value: 'filterhasvouchernearexpiration', label: translation.global.t('automatedScenarios.fields.segments.voucher') },
    fields: [
      {
        id: 'time_unit_select',
        component: 'PeriodSelector',
        data: { value: 1, unit: 'DAY' },
        props: {
          title: 'automatedScenarios.fields.expiringVoucherIn',
          units: [
            { value: 'DAY', label: translation.global.t('automatedScenarios.fields.durations.day(s)') },
            { value: 'MONTH', label: translation.global.t('automatedScenarios.fields.durations.month(s)') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.expiringVoucherIn'),
      },
      {
        id: 'code_start',
        component: 'Input',
        data: '',
        props: {
          title: 'automatedScenarios.fields.voucherStartingBy',
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.voucherStartingBy'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        time_unit_select: {
          value: {
            required,
            integer,
            between: between(1, 1000),
          },
          unit: {
            required,
            includesValuesValidator: includesValuesValidator([
              'DAY',
              'MONTH',
            ]),
          },
        },
        code_start: {
          required,
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
  {
    id: 'filtercanreceivesms',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filtercanreceivesms', label: translation.global.t('automatedScenarios.fields.segments.canReceiveSms') },
    fields: [
      {
        id: 'hidden',
        component: 'Hidden',
        data: '',
        hidden: true,
        litteral_title: '',
      },
    ],
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
  },
  {
    id: 'filtercanreceivefbmessenger',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filtercanreceivefbmessenger', label: translation.global.t('automatedScenarios.fields.segments.canReceiveFacebookMessanger') },
    fields: [
      {
        id: 'hidden',
        component: 'Hidden',
        data: '',
        hidden: true,
        litteral_title: '',
      },
    ],
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
  },
  {
    id: 'filtercanreceivepushnotifications',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filtercanreceivepushnotifications', label: translation.global.t('automatedScenarios.fields.segments.canReceivePushNotifications') },
    fields: [
      {
        id: 'hidden',
        component: 'Hidden',
        data: '',
        hidden: true,
        litteral_title: '',
      },
    ],
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
  },
  {
    id: 'filtercustomersubscription',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filtercustomersubscription', label: translation.global.t('automatedScenarios.fields.segments.hasACustomerAccount') },
    fields: [
      {
        id: 'hidden',
        component: 'Hidden',
        data: '',
        hidden: true,
        litteral_title: '',
      },
    ],
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
  },
  {
    id: 'filternewslettersubscription',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filternewslettersubscription', label: translation.global.t('automatedScenarios.fields.segments.optinType') },
    fields: [
      {
        id: 'type_optin',
        component: 'MultiDropdown',
        data: { 0: '0', 1: '1' },
        props: {
          label: 'automatedScenarios.fields.optinType',
          options: [
            { value: 0, label: 'simple' },
            { value: 1, label: 'double' },
          ],
          convertValueToInt: true,
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.optinType'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        type_optin: {
          required,
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
  },
  {
    id: 'filterhaschattedonfb',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filterhaschattedonfb', label: translation.global.t('automatedScenarios.fields.segments.chattedOnFacebook') },
    fields: [
      {
        id: 'time_unit_select',
        component: 'PeriodSelector',
        data: { value: 1, unit: 'DAY' },
        props: {
          title: 'automatedScenarios.fields.forLessThan',
          units: [
            { value: 'DAY', label: translation.global.t('automatedScenarios.fields.durations.day(s)') },
            { value: 'MONTH', label: translation.global.t('automatedScenarios.fields.durations.month(s)') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.forLessThan'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        time_unit_select: {
          value: {
            required,
            integer,
            between: between(1, 1000),
          },
          unit: {
            required,
            includesValuesValidator: includesValuesValidator([
              'DAY',
              'MONTH',
            ]),
          },
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
  },
  {
    id: 'filterhascustomertag',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK, TypeCampaignEnum.CUSTOMER_IMPORT],
    option: { value: 'filterhascustomertag', label: translation.global.t('automatedScenarios.fields.segments.includesTag') },
    fields: [
      {
        id: 'tags',
        component: 'EditAutocomplete',
        data: [],
        props: {
          title: 'automatedScenarios.fields.tagList',
          multiple: true,
          field: 'tag',
          complete: (query: string) => fetchTags(query),
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.tagList'),
        format: (tags: StringMap) => Object.entries(tags).map((item) => ({ id_shop_tag: item[0], tag: item[1] })),
      },
      {
        id: 'operator',
        component: 'ButtonGroup',
        data: 'and',
        props: {
          title: 'automatedScenarios.fields.visitorHaving',
          options: [
            { value: 'and', label: translation.global.t('automatedScenarios.fields.allTags') },
            { value: 'or', label: translation.global.t('automatedScenarios.fields.atLeastOne') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.visitorHaving'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        tags: {
          required,
        },
        operator: {
          required,
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
  },
  {
    id: 'filtermemberoflist',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filtermemberoflist', label: translation.global.t('automatedScenarios.fields.segments.beingPartOfAList') },
    fields: [
      {
        id: 'lists',
        component: 'MultiDropdown',
        data: [],
        props: {
          label: 'automatedScenarios.fields.lists',
          options: (await fetchLists()).map((item: ShopsLists) => ({ value: asInt(item.id_shop_list), label: item.name }) as DropdownOption),
          convertValueToInt: true,
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.lists'),
      },
      {
        id: 'operator',
        component: 'ButtonGroup',
        data: 'and',
        props: {
          title: 'automatedScenarios.fields.visitorBeingPartOf',
          options: [
            { value: 'and', label: translation.global.t('automatedScenarios.fields.allLists') },
            { value: 'or', label: translation.global.t('automatedScenarios.fields.atLeastOneList') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.visitorBeingPartOf'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        lists: {
          required,
        },
        operator: {
          required,
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
  },
  {
    id: 'filterisonmobile',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
    option: { value: 'filterisonmobile', label: translation.global.t('automatedScenarios.fields.segments.useSmartphone') },
    fields: [
      {
        id: 'hidden',
        component: 'Hidden',
        data: '',
        hidden: true,
        litteral_title: '',
      },
    ],
    dependencies: {
      automation: {
        operators: {
          any_parents: ['boxvisitpage', 'boxsitepasstime', 'boxscrollpage', 'boxquitsite', 'boxcustomjs'],
        },
      },
      bulk: {},
    },
  },
  {
    id: 'filterdeliverability',
    availableInCampaign: [TypeCampaignEnum.BULK],
    option: { value: 'filterdeliverability', label: translation.global.t('automatedScenarios.fields.segments.deliverability') },
    fields: [
      {
        id: 'time_unit_select',
        component: 'PeriodSelector',
        data: { value: 1, unit: 'DAY' },
        props: {
          title: 'automatedScenarios.fields.forLessThan',
          units: [
            { value: 'DAY', label: translation.global.t('automatedScenarios.fields.durations.day(s)') },
            { value: 'MONTH', label: translation.global.t('automatedScenarios.fields.durations.month(s)') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.forLessThan'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        time_unit_select: {
          value: {
            required,
            integer,
            between: between(1, 1000),
          },
          unit: {
            required,
            includesValuesValidator: includesValuesValidator([
              'DAY',
              'MONTH',
            ]),
          },
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
  },
  {
    id: 'filteropenedoneoflastcampaigns',
    availableInCampaign: [TypeCampaignEnum.BULK],
    option: { value: 'filteropenedoneoflastcampaigns', label: translation.global.t('automatedScenarios.fields.segments.openOneOfLastCampaigns') },
    fields: [
      {
        id: 'filter_open_sent_campaigns_list',
        component: 'MultiDropdown',
        data: [],
        props: {
          label: 'automatedScenarios.fields.segments.campaignsList',
          options: (await fetchCampaignBulkLists([CampaignsBulkStatusEnum.SENT,
            CampaignsBulkStatusEnum.SENDING,
            CampaignsBulkStatusEnum.SCHEDULED,
          ])).map((item: CampaignsBulk) => ({ value: asInt(item.id_campaign_bulk), label: item.name }) as DropdownOption),
          convertValueToInt: true,
          messageInfo: translation.global.t('automatedScenarios.fields.segments.campaignsListMessage'),
        },
        litteral_title: translation.global.t('automatedScenarios.fields.segments.openOneOfLastCampaigns'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        filter_open_sent_campaigns_list: {
          required,
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
  {
    id: 'filterclickedoneoflastcampaigns',
    availableInCampaign: [TypeCampaignEnum.BULK],
    option: { value: 'filterclickedoneoflastcampaigns', label: translation.global.t('automatedScenarios.fields.segments.clickedOneOfLastCampaigns') },
    fields: [
      {
        id: 'filter_click_sent_campaigns_list',
        component: 'MultiDropdown',
        data: [],
        props: {
          label: 'automatedScenarios.fields.segments.campaignsList',
          options: (await fetchCampaignBulkLists([CampaignsBulkStatusEnum.SENT,
            CampaignsBulkStatusEnum.SENDING,
            CampaignsBulkStatusEnum.SCHEDULED,
          ])).map((item: CampaignsBulk) => ({ value: asInt(item.id_campaign_bulk), label: item.name }) as DropdownOption),
          convertValueToInt: true,
          messageInfo: translation.global.t('automatedScenarios.fields.segments.campaignsListMessage'),
        },
        litteral_title: translation.global.t('automatedScenarios.fields.segments.clickedOneOfLastCampaigns'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        filter_click_sent_campaigns_list: {
          required,
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
]) as FilterSegment<FiltreCustomerFilters>[];
