
import {
  defineComponent,
  PropType,
  ref,
} from 'vue';
import Dropdown from 'primevue/dropdown';
import { useI18n } from 'vue-i18n';
import { DropdownOption } from '@/types';

export default defineComponent({
  name: 'VariablesButton',

  components: {
    Dropdown,
  },

  props: {
    variablesList: {
      type: Array as PropType<DropdownOption[]>,
      required: true,
    },

    placeholder: {
      type: String,
      required: false,
      default: 'Variables',
    },
  },

  emits: {
    'on-choose-variable': Object,
  },

  setup(props, context) {
    const { t } = useI18n();

    const variables = ref(props.variablesList.map((variable: any) => ({
      value: variable.value,
      label: t(variable.label),
    })));

    const handleVariableSelected = (data: DropdownOption) => {
      context.emit('on-choose-variable', data.value);
    };

    return {
      t,
      variables,
      handleVariableSelected,
    };
  },
});
