
import {
  computed,
  defineComponent,
  ref,
  nextTick,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
  StatsBuilderColumnLayoutEnum,
  StatsEditorPanelEnum,
  StatsWidgets,
  TemplateStructureEnum,
  WidgetTypeEnum,
  StatsBuilderRowLayoutEnum,
} from '@/types';
import Panel from 'primevue/panel';
import StatsWidgetMetadata, { getComponentStatsWidgetMetadata } from '@/components/stats-builder/metadata';
import draggable from 'vuedraggable';
import { useStore } from '@/store';
import StatsBuilderAddRowModal from '@/components/stats-builder/StatsBuilderAddRowModal.vue';
import { StatsBuilderSelectedWidget, StatsBuilderColumnConfig } from '@/types/stats-editor-types';
import { uniqueIdS6Generator } from '@/store/modules/stats-editor';

export default defineComponent({
  name: 'StatsBuilderElementsPanel',

  components: {
    StatsBuilderAddRowModal,
    Panel,
    draggable,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const showAddRowModal = ref(false);
    const pageTypeState = computed(() => store.getters['statsEditor/getPageType']);

    const availableElements: any = computed(() => Object.values(StatsWidgetMetadata)
      .filter((item: any) => item.StatsWidgetMeta.availability.includes(pageTypeState.value))
      .map((item: any) => ({
        type: 'placeholder',
        widgetType: item.StatsWidgetMeta.component,
        iconClass: item.StatsWidgetMeta.icon,
        label: item.StatsWidgetMeta.label,
        group: 'widget',
        minColumnsRequired: item.StatsWidgetMeta.min_columns_required,
      })));

    const availableStructures: Array<any> = [
      {
        type: 'placeholder',
        widgetType: WidgetTypeEnum.COLUMN,
        iconClass: 'fal fa-columns',
        label: t('templateBuilder.widgets.column'),
        group: TemplateStructureEnum.LINE,
      },
    ];

    const savedInnerHtmlElement: any = ref('');
    const savedClassNamesElement: any = ref('');

    const clearPlaceholder = () => {
      store.dispatch('statsEditor/removePlaceholderRows');
    };

    const createNewRowAndColumn = () => {
      const columnId = `column_${uniqueIdS6Generator()}`;
      const rowId = `row_${uniqueIdS6Generator()}`;

      const columnConfig: StatsBuilderColumnConfig = {
        id: columnId,
        type: 'StatsWidgetColumn',
        layout: StatsBuilderColumnLayoutEnum.TWELVE,
        widgets: [],
      };

      const children: StatsBuilderColumnConfig[] = [];
      children.push(columnConfig);

      const rowData = {
        id: rowId,
        type: 'StatsWidgetRow',
        layout: StatsBuilderRowLayoutEnum.TWELVE,
        children,
      };

      return {
        rowId,
        columnId,
        rowData,
      };
    };

    const createSelectedWidgetData = (meta: any, widgetType: string, location: any, widgetIndex?: number): StatsBuilderSelectedWidget => {
      const selectedWidget: StatsBuilderSelectedWidget = {
        component: widgetType,
        data: meta.Create(),
        location: {
          rowId: location.rowId,
          columnId: location.columnId,
          columnLayout: location.columnLayout,
        },
      };

      if (location.panelData) {
        selectedWidget.location.panelData = location.panelData;
      }
      if (widgetIndex !== null && widgetIndex !== undefined) {
        selectedWidget.location.widgetIndex = widgetIndex;
        selectedWidget.location.isNew = true;
      }

      return selectedWidget;
    };

    /* Elements D.A.D */
    const dragElementsOptions = ref({
      animation: 200,
      sort: false,
      group: { name: 'widget_element', pull: 'clone', put: false },
      disabled: false,
      onEnd: async (evt: any) => {
        const elements = document.querySelectorAll('.emptyColumnSlot');
        const chosenSlot = document.querySelector('.emptyColumnSlot.chosenSlot');
        elements.forEach((element) => {
          element.classList.remove('chosenSlot');
          element.classList.remove('notCompatibleSlot');
        });

        if (evt.item && (savedClassNamesElement.value !== '' || savedInnerHtmlElement.value !== '')) {
          // eslint-disable-next-line no-param-reassign
          evt.item.className = savedClassNamesElement.value ?? evt.item.className;
          // eslint-disable-next-line no-param-reassign
          evt.item.innerHTML = savedInnerHtmlElement.value ?? evt.item.innerHTML;
          savedClassNamesElement.value = '';
          savedInnerHtmlElement.value = '';
        }

        const widgetType = evt.clone.getAttribute('data-widget-type');
        const meta = getComponentStatsWidgetMetadata(widgetType);

        if (!meta) {
          clearPlaceholder();
          return;
        }

        if (chosenSlot) {
          const columnLayout = (chosenSlot.getAttribute('data-column-layout') as StatsBuilderColumnLayoutEnum) ?? StatsBuilderColumnLayoutEnum.FOUR;
          let locationSlot: any = chosenSlot.getAttribute('data-location');
          locationSlot = locationSlot ? JSON.parse(locationSlot) : null;
          locationSlot.columnLayout = columnLayout;

          if (locationSlot !== null) {
            const selectedWidget = createSelectedWidgetData(meta, widgetType, locationSlot);

            store.commit('statsEditor/setSelectedWidget', selectedWidget);
            store.commit('statsEditor/showLeftToolbar', StatsEditorPanelEnum.WIDGET_EDIT_PANEL);
          }
        } else if (evt.to.getAttribute('data-from-widget') || evt.to.getAttribute('data-from-subwidget')) {
          let location = evt.to.getAttribute('data-location');
          const chosenWidgetMinColumnRequired = evt.item.getAttribute('data-widget-min-column-required');
          const chosenWidgetType = evt.item.getAttribute('data-widget-type');
          if (location) {
            location = JSON.parse(location);

            const hasMenu = evt.to.getAttribute('data-has-menu');

            if ((parseInt(chosenWidgetMinColumnRequired, 10) > parseInt(location.columnLayout, 10))
              || ([StatsWidgets.MENU, StatsWidgets.TABS].includes(chosenWidgetType) && location.panelData) || hasMenu === 'true') {
              clearPlaceholder();

              return;
            }

            const selectedWidget = createSelectedWidgetData(meta, widgetType, location, evt.newIndex);

            store.commit('statsEditor/setSelectedWidget', selectedWidget);
            store.commit('statsEditor/showLeftToolbar', StatsEditorPanelEnum.WIDGET_EDIT_PANEL);
          } else {
            clearPlaceholder();
          }
        } else if (evt.to.getAttribute('data-stats-builder')) { // Add widget directly and create a row
          clearPlaceholder();

          const { columnId, rowId, rowData } = createNewRowAndColumn();

          await nextTick();
          store.dispatch('statsEditor/addRowToIndex', {
            rowData,
            index: evt.newIndex,
          });

          const newLocation = {
            rowId,
            columnId,
            columnLayout: StatsBuilderColumnLayoutEnum.TWELVE,
          };

          await nextTick();
          const selectedWidget = createSelectedWidgetData(meta, widgetType, newLocation);
          store.commit('statsEditor/setTempRow', {
            rowId,
            columnId,
          });
          store.commit('statsEditor/setSelectedWidget', selectedWidget);
          store.commit('statsEditor/showLeftToolbar', StatsEditorPanelEnum.WIDGET_EDIT_PANEL);
        } else if (evt.to.getAttribute('data-menu-render')) {
          const chosenWidgetType = evt.item.getAttribute('data-widget-type');
          clearPlaceholder();
          if (!([StatsWidgets.MENU, StatsWidgets.TABS].includes(chosenWidgetType))) {
            const dataMenuRender = JSON.parse(evt.to.getAttribute('data-menu-render'));

            const { columnId, rowId, rowData } = createNewRowAndColumn();

            await nextTick();
            store.dispatch('statsEditor/addRowToIndex', {
              rowData,
              index: evt.newIndex,
              panelId: dataMenuRender.panel.panelId,
              parentRowId: dataMenuRender.rowId,
              parentColumnId: dataMenuRender.columnId,
            });

            const newLocation = {
              rowId: dataMenuRender.rowId,
              columnId: dataMenuRender.columnId,
              columnLayout: StatsBuilderColumnLayoutEnum.TWELVE,
              panelData: {
                panelId: dataMenuRender.panel.panelId,
                subRowId: rowId,
                subColumnId: columnId,
              },
            };

            const selectedWidget = createSelectedWidgetData(meta, widgetType, newLocation);
            await nextTick();
            store.commit('statsEditor/setTempRow', {
              rowId: dataMenuRender.rowId,
              columnId: dataMenuRender.columnId,
              panelData: {
                panelId: dataMenuRender.panel.panelId,
                subRowId: rowId,
              },
            });
            store.commit('statsEditor/setSelectedWidget', selectedWidget);
            store.commit('statsEditor/showLeftToolbar', StatsEditorPanelEnum.WIDGET_EDIT_PANEL);
          }
        }
      },
      // ne pas pouvoir ajouter un menu dans un panel
      onMove: (evt: any) => {
        if (evt.from !== evt.to) {
          if (evt.dragged && savedClassNamesElement.value === '' && savedInnerHtmlElement.value === '') {
            savedInnerHtmlElement.value = evt.dragged.innerHTML;
            savedClassNamesElement.value = evt.dragged.className;
            // eslint-disable-next-line no-param-reassign
            evt.dragged.innerHTML = '';
            // eslint-disable-next-line no-param-reassign
            evt.dragged.className = '';
          }

          const elements = document.querySelectorAll('.emptyColumnSlot');
          elements.forEach((element) => {
            element.classList.remove('chosenSlot');
            element.classList.remove('notCompatibleSlot');
          });

          const chosenWidgetMinColumnRequired = evt.dragged.getAttribute('data-widget-min-column-required');
          const chosenWidgetType = evt.dragged.getAttribute('data-widget-type');
          const columnDestinationLayout = evt.to.getAttribute('data-column-layout');

          const destination = evt.to.getAttribute('data-from-widget') || evt.to.getAttribute('data-from-subwidget');

          const toStatsBuilder = evt.to.getAttribute('data-stats-builder');
          const toMenuRender = evt.to.getAttribute('data-menu-render');

          if (columnDestinationLayout) {
            const isOnPanel = evt.to.getAttribute('data-is-on-panel');
            // eslint-disable-next-line no-param-reassign
            evt.dragged.className = '';

            if ((parseInt(chosenWidgetMinColumnRequired, 10) > parseInt(columnDestinationLayout, 10))
              || (isOnPanel && [StatsWidgets.MENU, StatsWidgets.TABS].includes(chosenWidgetType))) {
              evt.to.classList.add('notCompatibleSlot');
            } else {
              evt.to.classList.add('chosenSlot');
            }
          } else if (destination) {
            const location = evt.to.getAttribute('data-location');

            const hasMenu = evt.to.getAttribute('data-has-menu');

            if (location) {
              const locationObject = JSON.parse(location);
              if ((parseInt(chosenWidgetMinColumnRequired, 10) > parseInt(locationObject.columnLayout, 10))
                || ([StatsWidgets.MENU, StatsWidgets.TABS].includes(chosenWidgetType) && locationObject.panelData) || hasMenu === 'true') {
                // eslint-disable-next-line no-param-reassign
                evt.dragged.className = 'placeholder-on-add-widget placeholder-on-add-widget--disallowed m-4';
              } else {
                // eslint-disable-next-line no-param-reassign
                evt.dragged.className = 'placeholder-on-add-widget placeholder-on-add-widget--allowed m-4';
              }
            }
          } else if (toStatsBuilder) {
            // eslint-disable-next-line no-param-reassign
            evt.dragged.className = 'placeholder-on-add-widget placeholder-on-add-widget--allowed m-4';
          } else if (toMenuRender) {
            if ([StatsWidgets.MENU, StatsWidgets.TABS].includes(chosenWidgetType)) {
              // eslint-disable-next-line no-param-reassign
              evt.dragged.className = 'placeholder-on-add-widget placeholder-on-add-widget--disallowed m-4';
            } else {
              // eslint-disable-next-line no-param-reassign
              evt.dragged.className = 'placeholder-on-add-widget placeholder-on-add-widget--allowed m-4';
            }
          }
        } else if (evt.dragged && (savedClassNamesElement.value !== '' || savedInnerHtmlElement.value !== '')) {
          // eslint-disable-next-line no-param-reassign
          evt.dragged.className = savedClassNamesElement.value ?? evt.dragged.className;
          // eslint-disable-next-line no-param-reassign
          evt.dragged.innerHTML = savedInnerHtmlElement.value ?? evt.dragged.innerHTML;
          savedClassNamesElement.value = '';
          savedInnerHtmlElement.value = '';
        }
        return true;
      },
    });

    /* Structure D.A.D */
    const dragStructureOptions = ref({
      animation: 200,
      sort: false,
      group: { name: 'line_element', pull: 'clone', put: false },
      disabled: false,
      onEnd: (evt: any) => {
        if (evt.item && (savedClassNamesElement.value !== '' || savedInnerHtmlElement.value !== '')) {
          // eslint-disable-next-line no-param-reassign
          evt.item.className = savedClassNamesElement.value ?? evt.item.className;
          // eslint-disable-next-line no-param-reassign
          evt.item.innerHTML = savedInnerHtmlElement.value ?? evt.item.innerHTML;
          savedClassNamesElement.value = '';
          savedInnerHtmlElement.value = '';
        }

        if (evt.from !== evt.to) {
          if (evt.to.getAttribute('data-from-widget') || evt.to.getAttribute('data-from-subwidget')) {
            clearPlaceholder();
            return false;
          }

          showAddRowModal.value = true;
        }
        return true;
      },
      onMove: (evt: any) => {
        if (evt.from !== evt.to) {
          if (evt.dragged && savedClassNamesElement.value === '' && savedInnerHtmlElement.value === '') {
            savedInnerHtmlElement.value = evt.dragged.innerHTML;
            savedClassNamesElement.value = evt.dragged.className;
            // eslint-disable-next-line no-param-reassign
            evt.dragged.innerHTML = '';
          }

          if (evt.to.getAttribute('data-from-widget') || evt.to.getAttribute('data-from-subwidget')) {
            // eslint-disable-next-line no-param-reassign
            evt.dragged.className = 'placeholder-on-add-widget placeholder-on-add-widget--disallowed m-4';
          } else {
            // eslint-disable-next-line no-param-reassign
            evt.dragged.className = 'placeholder-on-add-widget placeholder-on-add-widget--allowed m-4';
          }
        } else if (evt.dragged && (savedClassNamesElement.value !== '' || savedInnerHtmlElement.value !== '')) {
          // eslint-disable-next-line no-param-reassign
          evt.dragged.className = savedClassNamesElement.value ?? evt.dragged.className;
          // eslint-disable-next-line no-param-reassign
          evt.dragged.innerHTML = savedInnerHtmlElement.value ?? evt.dragged.innerHTML;
          savedClassNamesElement.value = '';
          savedInnerHtmlElement.value = '';
        }

        return true;
      },
    });

    const onHideModalAddRow = async () => {
      await nextTick();
      clearPlaceholder();
      showAddRowModal.value = false;
    };

    const hideLeftToolbar = () => {
      store.commit('statsEditor/hideLeftToolbar');
      store.commit('statsEditor/resetSelectedWidget');
    };

    return {
      t,
      hideLeftToolbar,
      showAddRowModal,
      onHideModalAddRow,
      dragStructureOptions,
      availableElements,
      availableStructures,
      dragElementsOptions,
    };
  },
});
