<template>
  <ul class="list-filter-panels">
    <template
      v-for="(panel, panelIndex) of panels"
      :key="panelIndex"
    >
      <li class="field-group">
        <button
          v-if="!configuringSegment"
          class="field-group-control field-group-control--collapsible flex justify-content-between align-items-center"
          @click="togglePanel(panelIndex)"
        >
          <span>
            <Badge
              v-if="activeItems && activeItems[panel] !== undefined && activeItems[panel] > 0"
              v-tooltip="{ value: activeItems[panel] + ' ' + t('automatedScenarios.panels.tooltip.badge_items_quantity') }"
              class="tooltip-active-items mr-2"
              :value="activeItems[panel]"
            />
            <span style="vertical-align: middle;">{{ t(`automatedScenarios.panels.${panel}`) }}</span>
          </span>
          <i
            class="fa"
            :class="panelIndex === activePanelIndex ? 'fa-caret-up' : 'fa-caret-down'"
          />
        </button>
        <div
          class="field-group-content"
          :class="{ 'hidden': (panelIndex !== activePanelIndex), 'configuring-filter': (!configuringSegment) }"
        >
          <slot :name="panel" />
        </div>
      </li>
    </template>
  </ul>
</template>

<script lang="ts">
import {
  computed, defineComponent, PropType, ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { AutomatedScenarioState as state } from '@/composables/AutomatedScenarios';
import Badge from 'primevue/badge';
import Tooltip from 'primevue/tooltip';

export default defineComponent({
  name: 'FilterPanelContent',

  components: {
    Badge,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    panels: {
      type: Array as PropType<string[]>,
      required: true,
    },

    activeItems: {
      type: Object,
      required: false,
    },
  },

  setup() {
    const { t } = useI18n();
    const activePanelIndex = ref(0);
    const configuringSegment = computed(() => state.configuringSegment);

    const togglePanel = (index: number) => {
      if (index === activePanelIndex.value) {
        activePanelIndex.value = -1;
      } else {
        activePanelIndex.value = index;
      }
    };

    return {
      t,
      activePanelIndex,
      togglePanel,
      configuringSegment,
    };
  },
});
</script>

<style lang="scss" scoped>
.list-filter-panels {
  list-style: none;
}
.tab-panel-label {
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 500;
  color: $montana;
  padding: 0.5rem 0.75rem 1.375rem 0.75rem;
}

.field-group {
  margin-bottom: .5rem;

  .field-group-content {
    &.configuring-filter {
      padding: 1rem;
    }
  }
}

.field-group:last-child {
  margin-bottom: 1rem;
}

.field-group-control {
  font-size: 1rem;
  text-align: left;
  padding: .5rem .75rem;
  color: $montana;
  background-color: $shuttle-light-grey;

  &--collapsible {
    width: 100%;
    border: 0 none;
    cursor: pointer;
  }
}

.tooltip-active-items {
  background-color: $extra-light-grey;
  color: $tuna;
  border: 1px solid $heather;
}
</style>
