<template>
  <div class="border-style-block">
    <div class="flex justify-content-between flex-wrap">
      <div class="flex justify-content-center">
        <label class="block my-3">
          {{ t(configs.label) }}
        </label>
      </div>
      <div class="flex justify-content-center align-items-center">
        <ToggleDisplaySettings
          v-if="!Object.prototype.hasOwnProperty.call(configs, 'displayToggle') || configs.displayToggle"
          :configs="configs"
          :parser-values="parserValues"
          @display-settings="(value) => displayProperties = value"
        />
      </div>
    </div>
    <div
      class="align-items-center"
      :class="{ 'hidden': !displayProperties, 'flex': displayProperties }"
    >
      <BaseDropdown2
        v-model="selectedStyle"
        :options="borderStyleOptions"
        class="style-dropdown"
        data-test-id="border-style-dropdown"
        @update:model-value="changeStyle"
      />
      <BaseInputNumber
        v-model="selectedWidth"
        data-test-id="border-width-input"
        :suffix="SIGN"
        @update:model-value="changeWidth"
      />
      <div class="p-inputgroup">
        <InputText
          v-model="selectedColor"
          class="p-inputtext-sm border-color-input"
          @change="filterInputColor"
          @keyup="filterInputColor"
        />
        <span
          class="p-inputgroup-addon button-block"
          @click="toggleColorPicker"
        >
          <Button
            class="p-inputgroup-addon p-button-sm border-color-button"
            :style="{ 'background-color': selectedColor }"
          />
          <em
            class="far fa-arrow-down p-inputgroup-addon text-center button-icon"
          />
        </span>
      </div>
      <OverlayPanel
        ref="overlayPanelRef"
      >
        <CustomColorPicker
          data-test-id="color-picker"
          :color="selectedColor"
          :color-format="colorFormat"
          title="Color Picker"
          @color-changed="changeColor"
        />
      </OverlayPanel>
    </div>
  </div>
  <div
    class="border-block"
    :class="{ 'hidden': !displayProperties || borders.length <= 1 }"
  >
    <Checkbox
      v-for="border in borders"
      :key="border.type"
      :class="border.class"
      :model-value="border.isChecked"
      binary
      @change="changeStyleDisplay(border.type)"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  Ref,
  ref,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
  BorderFieldConfig,
  BorderConfigurationEnum,
  ColorFormatEnum,
  DropdownOption,
  ParserFieldObject,
  Property,
} from '@/types';

import OverlayPanel from 'primevue/overlaypanel';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';

import BaseDropdown2 from '@/components/fields/BaseDropdown2.vue';
import BaseInputNumber from '@/components/fields/partials/BaseInputNumber.vue';
import CustomColorPicker from '@/components/fields/partials/CustomColorPicker.vue';
import ToggleDisplaySettings from '@/components/template-builder/fields/partials/ToggleDisplaySettings.vue';
import { rgbToHex } from '@/helpers';

const VALID_COLOR_REGEX_RULE = /^(#([\da-f]{3}){1,2}|(rgb|rgba)\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb|rgba)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\))$/i;
const BORDER_STYLES = [
  'none',
  'solid',
  'dashed',
  'dotted',
  'double',
  'groove',
  'ridge',
  'inset',
  'outset',
];

export default defineComponent({
  name: 'Borders',
  components: {
    ToggleDisplaySettings,
    BaseDropdown2,
    BaseInputNumber,
    Button,
    Checkbox,
    CustomColorPicker,
    InputText,
    OverlayPanel,
  },

  props: {
    configs: {
      type: Object as PropType<BorderFieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const configs = ref(props.configs);
    const displayProperties = ref(!(!Object.prototype.hasOwnProperty.call(configs.value, 'displayToggle') || configs.value.displayToggle === true));
    const SIGN = 'px';
    const overlayPanelRef = ref();
    const colorFormat: Ref<string> = ref(ColorFormatEnum.HEX);
    const borderStyleOptions: DropdownOption[] = BORDER_STYLES.map((option) => ({
      label: option,
      value: option,
    }));

    const selectedColor: Ref<string> = ref(props.configs.options.defaultColor);
    const selectedWidth: Ref<number> = ref(props.configs.options.defaultWidth);
    const selectedStyle: Ref<string> = ref(props.configs.options.defaultBorderStyle);
    const borders = ref((props.parserValues.properties || []).filter((property) => (new RegExp('^border-[a-z]+-style$')).test(property.name)).map((property: Property) => ({
      isChecked: property.value !== 'none',
      class: `selected-${property.name}`,
      type: property.name,
    })));

    watch(() => props.parserValues, (values, oldValues) => {
      if (values.selector !== oldValues?.selector) {

        const definedSelectedColor = values?.properties.filter(
          (property: Property) => (new RegExp('^border-[a-z]+-color$')).test(property.name) && property.value
        );
        selectedColor.value = definedSelectedColor.length ? definedSelectedColor[0].value : props.configs.options.defaultColor;

        const definedSelectedWidth = values?.properties.filter(
          (property: Property) => (new RegExp('^border-[a-z]+-width$')).test(property.name) && property.value
        );
        selectedWidth.value = definedSelectedWidth.length ? parseInt(definedSelectedWidth[0].value, 10) : props.configs.options.defaultWidth;

        const definedSelectedStyle = values?.properties.filter(
          (property: Property) => (new RegExp('^border-[a-z]+-style$')).test(property.name) && property.value && property.value !== 'none'
        );
        selectedStyle.value = definedSelectedStyle.length ? definedSelectedStyle[0].value : props.configs.options.defaultBorderStyle;
      }
      if (selectedColor.value.includes(ColorFormatEnum.RGB)) {
        // Convert RGB to HEX for colorpicker field
        selectedColor.value = rgbToHex(selectedColor.value);
      }
    }, { immediate: true });

    const toggleColorPicker = (event: MouseEvent) => {
      overlayPanelRef.value.toggle(event);
    };

    const changeProperty = (propertyName: string, value: string) => {
      const updatedProperty: Property = props.parserValues.properties.filter((property: Property) => property.name === propertyName)[0];
      if (updatedProperty) {
        updatedProperty.value = value;
        context.emit('on-change-properties', {
          selector: props.parserValues.selector,
          properties: [updatedProperty],
        });
      }
    };

    const changeStyleDisplay = (borderStyle: string) => {
      const index = borders.value.findIndex((property) => property.type === borderStyle);
      borders.value[index].isChecked = !borders.value[index].isChecked;
      changeProperty(borderStyle, borders.value[index].isChecked ? selectedStyle.value : 'none');
    };

    const changeWidth = (width: number) => {
      selectedWidth.value = width;
      changeProperty(BorderConfigurationEnum.BORDER_WIDTH, `${selectedWidth.value}${SIGN}`);
    };

    const changeStyle = (style: string) => {
      selectedStyle.value = style;
      const updatedProperties = props.parserValues.properties.filter((property) => (new RegExp('^border-[a-z]+-style$')).test(property.name)
        && borders.value.length && borders.value.filter((border: any) => border.type === property.name)).map((property: Property) => {
        const updatedProperty = property;
        updatedProperty.value = selectedStyle.value;
        return updatedProperty;
      });
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: updatedProperties,
      });
    };

    const changeColor = (color: string) => {
      selectedColor.value = color;
      changeProperty(BorderConfigurationEnum.BORDER_COLOR, selectedColor.value);
    };

    const filterInputColor = () => {
      if (VALID_COLOR_REGEX_RULE.test(selectedColor.value)) {
        changeColor(selectedColor.value);
      }
    };

    return {
      displayProperties,
      SIGN,
      overlayPanelRef,
      borders,
      colorFormat,
      borderStyleOptions,
      selectedColor,
      selectedWidth,
      selectedStyle,
      toggleColorPicker,
      changeStyleDisplay,
      changeColor,
      changeStyle,
      changeWidth,
      filterInputColor,
      t,
    };
  },
});
</script>

<style scoped lang="scss">
.border-style-block {
  .border-color-input {
    border-color: $montana;
    font-size: 1rem;

    &:hover {
      border-color: $brand-color-primary;
    }

    &:enabled:focus,
    &:enabled:active {
      border: 1px solid $brand-color-primary;
      box-shadow: none;
    }
  }

  .p-inputnumber {
    :deep() {
      .stepper-input {
        width: $field-default-height;
      }
    }
  }

  .style-dropdown {
    width: 100%;

    :deep() .select {
      font-size: .9rem;
    }
  }

  .button-block {
    background-color: $white;
    border-color: $heather;
    padding: 0;

    &:last-child {
      border-color: $heather;
      border-top-right-radius: $field-border-radius;
      border-bottom-right-radius: $field-border-radius;
    }

    .border-color-button {
      margin: 0.25rem 0 0.25rem 0.25rem;
      min-width: 1rem;
      width: 1rem;
      height: 1rem;
      padding: 0;

      &:enabled:focus,
      &:enabled:active {
        border: 1px solid $brand-color-primary;
        box-shadow: none;
      }
    }

    .button-icon {
      background-color: $white;
      border: none;
      padding: .25rem;
      color: $heather;
      cursor: pointer;
    }

    &:hover {
      border-color: $brand-color-primary;
    }
  }
}

.border-block {
  position: relative;
  width: calc(100% - 1.25rem);
  height: 50px;
  margin: 1.25rem auto;
  border: 1px dashed $heather;

  .selected-border-top-style {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .selected-border-bottom-style {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }

  .selected-border-left-style {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .selected-border-right-style {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
  }

  &:deep() .p-checkbox:not(.p-checkbox-disabled) {
    width: 22px;
    height: 22px;

    .p-checkbox-box {
      border: 1px solid lightgrey;
    }
  }
}
</style>
