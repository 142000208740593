
import { useI18n } from 'vue-i18n';
import { defineComponent, ref } from 'vue';
import {
  OperatorType,
} from '@/types/generated-types/graphql';
import {
  DataExplorerDataType,
  SpmTableColumns, SpmTableFilter,
} from '@/types';
import SpmTable from '@/components/table/SpmTable.vue';
import SearchCustomers from '@/components/mes-lists/SearchCustomers.vue';
import { findPermission, UserState } from '@/composables/User';
import { getShopCustomerLanguages } from '@/types/country-language-options';

export default defineComponent({
  name: 'ListsBounces',
  components: {
    SpmTable,
    SearchCustomers,
  },

  setup() {
    const { t } = useI18n();
    const search = ref(false);
    const childComponentRef = ref();
    const idShop = UserState?.activeShop?.id ?? 0;
    const rejectFromList = [
      { value: 'newsletter', label: 'Newsletter' },
      { value: 'workflow', label: 'Workflow' },
    ];

    const rejectTypes = [{ value: 'hard-bounce', label: 'hard-bounce' }, { value: 'soft-bounce', label: 'soft-bounce' },
      { value: 'spam', label: 'spam' }, { value: 'unsub', label: 'unsub' }];
    const columns: SpmTableColumns[] = [
      {
        field: 'id_email_reject',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'date',
        header: t('myLists.bounce.headers.date'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
        prefix: 'er',
      },
      {
        field: 'reject_from',
        header: t('From'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'er',
        filterSettings: {
          type: 'multiSelect', options: rejectFromList, hideFilterMenu: true, showFilterInput: false,
        },
      },
      {
        field: 'reject_type',
        header: t('myLists.bounce.headers.rejectType'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'er',
        filterSettings: { type: 'multiSelect', options: rejectTypes, hideFilterMenu: true },
      },
      {
        field: 'last_name',
        header: t('myLists.bounce.headers.lastName'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'first_name',
        header: t('myLists.bounce.headers.firstName'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'email',
        header: t('myLists.bounce.headers.email'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'data-explorer-link',
        prefix: 'sc',
        dataExplorerOptions: {
          id: 'id_shop_customer',
          type: DataExplorerDataType.CONTACT_FILE,
        },
      },
      {
        field: 'country',
        header: t('myLists.bounce.headers.country'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:10%',
        type: 'flag',
        prefix: 'sc',
        filterSettings: { type: 'country', hideFilterMenu: true },
      },
      {
        field: 'lang',
        header: t('myLists.bounce.headers.lang'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:10%',
        type: 'language',
        prefix: 'sc',
        filterSettings: { type: 'language', options: getShopCustomerLanguages, hideFilterMenu: true },
      },
      {
        field: 'reject_message',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'id_shop_customer',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
    ];

    const persistentFilters = ref<SpmTableFilter[]>([
      {
        field: 'type',
        value: 'bounces',
        operator: OperatorType.Equals,
      },
    ]);

    const changeSearchValues = () => {
      search.value = false;
    };

    const execute = (values: any) => {
      persistentFilters.value = values.value;
    };

    const originalFilters = persistentFilters.value;

    return {
      t,
      columns,
      persistentFilters,
      execute,
      originalFilters,
      changeSearchValues,
      childComponentRef,
      idShop,
      findPermission,
    };
  },
});
