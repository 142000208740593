<template>
  <div>
    <div class="p-buttonset button-group">
      <div class="flex justify-content-between flex-wrap">
        <div
          v-if="configs.label !== ''"
          class="flex justify-content-center"
        >
          <label class="block my-3">
            {{ t(configs.label, null) }}
          </label>
        </div>
        <div class="flex justify-content-center align-items-center">
          <ToggleDisplaySettings
            v-if="!Object.prototype.hasOwnProperty.call(configs, 'displayToggle') || configs.displayToggle"
            :configs="configs"
            :parser-values="parserValues"
            @display-settings="(value) => displayProperties = value"
          />
        </div>
      </div>
      <BaseButtonGroup
        v-model="option"
        :options="options"
        :multiple="configs.multiple"
        :class="{ 'hidden': !displayProperties }"
      />
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable no-param-reassign */
import {
  defineComponent, PropType, ref, Ref, watch,
} from 'vue';
import BaseButtonGroup from '@/components/fields/BaseButtonGroup.vue';
import {
  ButtonFieldConfig,
  ParserFieldObject, Property,
} from '@/types';
import { useI18n } from 'vue-i18n';
import ToggleDisplaySettings from '@/components/template-builder/fields/partials/ToggleDisplaySettings.vue';

export default defineComponent({
  name: 'ButtonGroup',
  components: {
    ToggleDisplaySettings,
    BaseButtonGroup,
  },

  props: {
    configs: {
      type: Object as PropType<ButtonFieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props: {parserValues: ParserFieldObject; configs: ButtonFieldConfig}, context) {
    const { t } = useI18n();
    const configs = ref(props.configs);
    const displayProperties = ref(!(!Object.prototype.hasOwnProperty.call(configs.value, 'displayToggle') || configs.value.displayToggle === true));
    const properties: Ref<Array<Property>> = ref(props.parserValues.properties);
    const options = ref(Object.entries(configs.value.options).map((data) => {
      const value = data[0];
      const { iconClass, src, title } = data[1];
      return {
        label: title && t(title),
        value,
        icon: iconClass,
        src,
      };
    }));

    // eslint-disable-next-line no-nested-ternary
    const option: Ref<string|string[]> = ref((configs.value.multiple) ? properties.value.reduce((acc: string[], property: Property) => {
      if (property.value !== undefined) acc.push(...property.value.split(' '));
      return acc;
    }, []) : (properties.value[0].value !== undefined ? properties.value[0].value.toString() : Object.values(configs.value.options).pop()?.defaultValue ?? ''));

    const getDefaultPropertyValue = (propertyName: string): string => Object.values(configs.value.options)
      .filter((current) => current.property === propertyName)[0].defaultValue;

    const getValueFromOptions = (propertyName: string, opts: string[]) => {
      const availableOptions = Object.entries(configs.value.options)
        .filter((current) => current[1].property === propertyName) // keep options of current property
        .filter((current) => opts.includes(current[0])); // keep options wich are selected

      const result = availableOptions.reduce((acc: string, current: any) => {
        if (current[1].multipleChoice) {
          acc += `${current[0]} `;
        } else {
          // eslint-disable-next-line prefer-destructuring
          acc = current[0];
        }
        return acc;
      }, '');

      if (!result) {
        return getDefaultPropertyValue(propertyName);
      }
      return result.trim();
    };

    watch(option, () => {
      const opts: string[] = (Array.isArray(option.value)) ? option.value : [option.value];
      properties.value.forEach((property: Property) => {
        property.value = getValueFromOptions(property.name, opts);
      });

      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: properties.value,
      });
    });

    return {
      t,
      displayProperties,
      options,
      option,
    };
  },
});
</script>

<style scoped lang="scss">
.button-group {
  :deep() .p-selectbutton {
    display: block;
  }
  :deep() .p-button {
    min-width: 39px;
    padding: 10px;
    > i, img {
      margin: auto;
    }
    &:nth-child(7n+1) {
      border-right: 1px solid !important;
    }
    &:enabled:focus,
    &:focus {
      box-shadow: 0 0 0 0.2rem $brand-color-primary;
      border-color: $brand-color-primary;
    }

    &.p-button-raised {
      background-color: $brand-color-primary;
      border-color: $brand-color-primary;
      color: $astronaut !important;
    }
    &.p-button-outlined {
      background-color: #DDDDDD;
    }
    &.p-button-sm {
      font-size: 1rem;
      padding: 0.3125rem 0.5rem;
    }
    &.no-border {
      border: none !important;
    }
  }

  .spm_icon {
    min-width: 1.4rem;
    min-height: 1.01rem;
  }
}
</style>
