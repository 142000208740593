import { ComponentNameEnum, DropdownOption } from '@/types';

const getSpmSocialIconsFieldConfig = (iconList: string[] = [], defaultValue = '') => ({
  componentName: ComponentNameEnum.SOCIAL_ICONS_STYLE,
  options: {
    list: iconList.reduce((acc: DropdownOption[], current) => {
      acc.push({
        label: '',
        value: current,
      });
      return acc;
    }, []),
    defaultValue,
  },
});

export default getSpmSocialIconsFieldConfig;
