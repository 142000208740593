
import {
  defineComponent, PropType, ref, nextTick,
} from 'vue';
import Dropdown from 'primevue/dropdown';
import { useI18n } from 'vue-i18n';
import { DropdownOption } from '@/types';
import OverlayPanel from 'primevue/overlaypanel';

export default defineComponent({
  name: 'Variables',

  components: {
    Dropdown,
    OverlayPanel,
  },

  props: {
    variablesList: {
      type: Array as PropType<DropdownOption[]>,
      required: true,
    },

    placeholder: {
      type: String,
      required: false,
      default: 'Variables',
    },

    parentRef: {
      type: Object, // Ou tout autre type qui convient à votre référence parent
      required: true,
    },
  },

  emits: {
    'on-choose-variable': Object,
    'add-data-menu': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const displayVariablesPicker = ref();
    const variables = ref(props.variablesList.map((variable: any) => ({
      value: variable.value,
      label: t(variable.label),
    })));
    const handleVariableSelected = (data: DropdownOption) => {
      context.emit('on-choose-variable', data.value);
      displayVariablesPicker.value.hide();
    };
    context.emit('add-data-menu', {
      label: 'Insérer une variable',
      icon: 'fal fa-file-code',
      command(event: any) {
        setTimeout(() => {
          displayVariablesPicker.value.show(event, props.parentRef.querySelector('button'));
        }, 0);
      },
    });

    return {
      t,
      handleVariableSelected,
      displayVariablesPicker,
      variables,
    };
  },
});
