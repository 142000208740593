<template>
  <div
    class="configuration-wrapper"
  >
    <Panel>
      <template #header>
        <div class="panel-header-content">
          <h4>{{ t('templateBuilder.panels.history') }}</h4>
        </div>
      </template>

      <template #icons>
        <i
          class="far fa-times icons-header-panel"
          @click="hideLeftToolbar"
        />
      </template>

      <ScrollPanel
        id="historyScrollPanel"
      >
        <ul class="savedList list-group">
          <li
            v-for="(item, index) of historyList"
            :key="item.id_template_elements_history_group"
            class="list-group-item"
            :class="{ 'active': (item.id_template_elements_history_group === selectedHistory?.id_template_elements_history_group) }"
          >
            <div class="date">
              {{ t(`templateBuilder.history.${item.action_detail}`) }}
              <div class="date_creation">
                {{ item.date_creation }}
              </div>
            </div>
            <Button
              class="button-restore p-button-sm"
              :class="item.id_template_elements_history_group !== selected?.id_template_elements_history_group ? 'p-button-secondary' : 'hidden'"
              icon="far fa-redo"
              icon-pos="left"
              @click="handleChangeHistory((index))"
            />
          </li>
        </ul>
      </ScrollPanel>
    </Panel>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, PropType, toRef, Ref, SetupContext,
} from 'vue';
import ScrollPanel from 'primevue/scrollpanel';
import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';
import { TemplatesElementsHistoryGroup } from '@/types/generated-types/graphql';
import { hideLeftToolbar } from '@/composables/template-editor/TemplateEditor';
import Panel from 'primevue/panel';

export default defineComponent({
  name: 'HistoryPanel',

  components: {
    ScrollPanel,
    Button,
    Panel,
  },

  props: {
    historyList: {
      type: Array as PropType<TemplatesElementsHistoryGroup[]>,
      required: true,
    },

    selectedHistory: {
      type: Object as PropType<TemplatesElementsHistoryGroup|null>,
      required: false,
      default: () => null,
    },
  },

  emits: ['on-change-history'],

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();
    const selected: Ref<TemplatesElementsHistoryGroup|null> = toRef(props, 'selectedHistory');

    const handleChangeHistory = async (historyIndex: number) => {
      emit('on-change-history', historyIndex);
    };

    return {
      t,
      selected,
      handleChangeHistory,
      hideLeftToolbar,
    };
  },
});
</script>

<style lang="scss" scoped>
.icons-header-panel {
  cursor: pointer;
  color: #607D8B;
}

.field-group-content {
  div.field-wrapper:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

#historyScrollPanel {
  .p-scrollpanel-wrapper {
    margin-top: 10px;
    padding-left: 13px;
  }

  .p-scrollpanel-bar {
    background: #666 !important;
  }

  .savedList {
    margin: 1rem 1rem 0;

    .list-group-item {
      padding: 10px;
      align-items: stretch;
      display: flex;
      background-color: $white;
      border: 1px solid $solitude;
      font-size: 0.9rem;
      justify-content: space-between;
      border-top-width: 0;

      &:first-child {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        border-top-width: 1px;
      }

      &:last-child {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &.active {
        background: $brand-color-primary;
        border-color: $brand-color-primary;
        color: $white;

        & .date {
          & .date_creation {
            color: $white;
          }
        }
      }

      & .date {
        & .date_creation {
          margin-top: 3px;
          font-size: 0.7rem;
          color: $mid-grey;
        }

        & .button-restore {
          float: right;
        }
      }
    }
  }
}
</style>
