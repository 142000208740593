import { ComponentNameEnum, TextAlignValueEnum, BaseButtonFieldConfig } from '@/types';

const property = 'text-align';

const getTextAlignFieldConfig = (defaultValue = TextAlignValueEnum.LEFT): BaseButtonFieldConfig => ({
  componentName: ComponentNameEnum.BUTTON_GROUP,
  options: {
    [TextAlignValueEnum.LEFT]: {
      title: 'templateBuilder.fields.leftAlign',
      iconClass: 'fa-align-left',
      property,
      defaultValue,
    },
    [TextAlignValueEnum.CENTER]: {
      title: 'templateBuilder.fields.centerAlign',
      iconClass: 'fa-align-center',
      property,
      defaultValue,
    },
    [TextAlignValueEnum.RIGHT]: {
      title: 'templateBuilder.fields.rightAlign',
      iconClass: 'fa-align-right',
      property,
      defaultValue,
    },
    [TextAlignValueEnum.JUSTIFY]: {
      title: 'templateBuilder.fields.justifyAlign',
      iconClass: 'fa-align-justify',
      property,
      defaultValue,
    },
  },
});

export default getTextAlignFieldConfig;
