import {
  ConfigurationPanelStructure,
  ConfigurationPanelStructureItemEnum,
  TemplateStructureEnum,
  TabItem, LinkTypeEnum,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import { setAttribute, setContent, setDataRedirectAttribute } from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import { getAttributeByName, getContent } from '@/components/template-builder/getters';
import getInputVariablesFieldConfig from '@/components/template-builder/config/fields/input-variables-config';
import variables from '@/components/template-builder/utils/variables-list';
import getTextareaVariablesFieldConfig from '@/components/template-builder/config/fields/textarea-variables-config';
import getLinkFieldConfig from '@/components/template-builder/config/fields/link-config';
import imageFieldConfig from '@/components/template-builder/config/fields/image-config';

const linkOptions = [LinkTypeEnum.WEB_ADDRESS, LinkTypeEnum.DISPLAY_TEMPLATE];

export const pageTabItemConfig: TabItem = {
  type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
  tabItemType: TemplateStructureEnum.PAGE,
  label: 'templateBuilder.configs.pushNotification',
  tabPanel: {
    label: 'templateBuilder.configs.pushNotification',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.pushNotificationContent',
        selector: 'body table#spm_body',
        items: [
          {
            label: 'templateBuilder.fields.notificationTitle',
            selector: '{SELECTOR} h1.push-notification-title',
            properties: [{ name: 'content', getter: getContent, setters: [setContent] }],
            ...getInputVariablesFieldConfig('Titre de la notification', variables.allVariables, true, true),
          },
          {
            label: 'templateBuilder.fields.notificationText',
            selector: '{SELECTOR} .push-notification-body',
            properties: [{ name: 'content', getter: getContent, setters: [setContent] }],
            ...getTextareaVariablesFieldConfig('Texte de la notification', variables.allVariables, true),
          },
          {
            label: 'templateBuilder.fields.notificationImage',
            selector: '{SELECTOR} .push-notification-image img',
            properties: [{ name: 'src', getter: getAttributeByName, setters: [setAttribute] }],
            ...imageFieldConfig,
            options: {
              ...imageFieldConfig.options,
              remove: true,
            },
          },
          {
            label: 'templateBuilder.fields.notificationIcon',
            selector: '{SELECTOR} .push-notification-icon img',
            properties: [{ name: 'src', getter: getAttributeByName, setters: [setAttribute] }],
            ...imageFieldConfig,
            options: {
              ...imageFieldConfig.options,
              remove: true,
            },
          },
          {
            label: 'templateBuilder.fields.link',
            selector: '{SELECTOR} .push-notification-link',
            properties: [{ name: 'data-redirect', getter: getAttributeByName, setters: [setAttribute, setDataRedirectAttribute] }],
            ...getLinkFieldConfig(LinkTypeEnum.NONE, linkOptions, variables.allVariables),
          },
        ],
      },
    ],
  },
};

const pageItemConfig: ConfigurationPanelStructure = {
  items: [
    pageTabItemConfig,
  ],
};

export default pageItemConfig;
