import {
  ComponentNameEnum,
  TemplateStructureEnum,
  ConfigurationPanelStructure,
  ConfigurationPanelStructureItemEnum,
  TabItem,
  BackgroundImageOptionTypeEnum,
} from '@/types';
import bordersFieldConfig from '@/components/template-builder/config/fields/borders-config';
import getVerticalSpacesFieldConfig from '@/components/template-builder/config/fields/vertical-spaces-config';
import getHorizontalSpacesFieldConfig from '@/components/template-builder/config/fields/horizontal-spaces-config';
import getColorFieldConfig from '@/components/template-builder/config/fields/color-config';
// eslint-disable-next-line import/no-cycle
import {
  setCssProperty,
  setCssPropertyAndRemoveIfZero,
  setBackgroundImage,
  setBackgroundImageProperty,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import {
  getCssPropertyByName,
  getBackgroundImageValue,
} from '@/components/template-builder/getters';
import itemsVerticalAlignFieldConfig from '@/components/template-builder/config/fields/items-vertical-align-config';
import getBackgroundImageFieldConfig from '@/components/template-builder/config/fields/background-image-config';

export const columnTabItemConfig: TabItem = {
  type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
  tabItemType: TemplateStructureEnum.COLUMN,
  label: 'templateBuilder.configs.column',
  tabPanel: {
    label: 'templateBuilder.configs.columnEdit',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.column',
        selector: 'body #spm_body #{COLUMN_ID}',
        items: [
          {
            label: 'templateBuilder.fields.backgroundColor',
            selector: '{SELECTOR}',
            properties: [{
              name: 'background',
              getter: getCssPropertyByName,
              setters: [setCssProperty],
            }],
            ...getColorFieldConfig('#ffffff', false, true),
          },
          {
            label: 'templateBuilder.fields.backgroundImage',
            selector: '{SELECTOR}',
            properties: [
              { name: 'background-image', getter: getBackgroundImageValue, setters: [setBackgroundImage] },
              { name: 'background-size', getter: getCssPropertyByName, setters: [setBackgroundImageProperty] },
              { name: 'background-position-x', getter: getCssPropertyByName, setters: [setBackgroundImageProperty] },
              { name: 'background-position-y', getter: getCssPropertyByName, setters: [setBackgroundImageProperty] },
              { name: 'background-repeat', getter: getCssPropertyByName, setters: [setBackgroundImageProperty] },
            ],
            ...getBackgroundImageFieldConfig(BackgroundImageOptionTypeEnum.FULL),
          },
          {
            label: 'templateBuilder.fields.verticalAlignment',
            selector: '{SELECTOR}',
            properties: [
              { name: 'align-items', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...itemsVerticalAlignFieldConfig,
          },
          {
            label: 'templateBuilder.fields.internalMargins',
            selector: '{SELECTOR}',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig('padding').options,
            },
          },
          {
            label: 'templateBuilder.fields.borders',
            selector: '{SELECTOR}',
            properties: [
              { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-style', getter: getCssPropertyByName, setters: [] },
              { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...bordersFieldConfig,
          },
        ],
      },
    ],
  },
};

const columnItemConfig: ConfigurationPanelStructure = {
  items: [
    columnTabItemConfig,
  ],
};

export default columnItemConfig;
