<template>
  <div v-if="!configuringSegment">
    <FilterGlobalInformations
      v-model="commonFields"
      v-model:form-validation="formValidationErrors"
    />

    <div
      class="field-group pt-2"
    >
      <div>
        <div class="field-group-content">
          <div class="field-wrapper">
            <ButtonGroup
              v-model="grantAdditionalDelay"
              :options="options"
              title="automatedScenarios.fields.grantAdditionalDelay"
            />
            <FieldErrors
              :key="componentFieldErrorsKey"
              :errors="formValidationErrors"
              field="grant_additional_delay"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="grantAdditionalDelay === '1'"
      class="field-group pt-2"
    >
      <div>
        <div class="field-group-content">
          <div class="field-wrapper">
            <PeriodSelector
              v-model="delay"
              :units="units"
              title="automatedScenarios.fields.duration"
            />
            <FieldErrors
              :key="componentFieldErrorsKey"
              :errors="formValidationErrors"
              field="extra_time"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <FilterPanelContent
    :active-items="activeItemsFilterPanel"
    :panels="['include', 'exclude']"
  >
    <template #include>
      <FilterSegmentForm
        :segments="includeSegments"
        :active-segments="activeIncludeSegments"
        :items="filters.include"
        @add:segment="(segment) => handleAddSegment('include', segment)"
        @remove:segment="({ segmentName, number }) => handleRemoveSegment('include', segmentName, number)"
      />
    </template>
    <template #exclude>
      <FilterSegmentForm
        :segments="excludeSegments"
        :active-segments="activeExcludeSegments"
        :items="filters.exclude"
        @add:segment="(segment) => handleAddSegment('exclude', segment)"
        @remove:segment="({ segmentName, number }) => handleRemoveSegment('exclude', segmentName, number)"
      />
    </template>
  </FilterPanelContent>
  <FieldErrors
    v-if="!configuringSegment"
    :key="componentFieldErrorsKey"
    :errors="formValidationErrors"
    field="inclusion"
  />
</template>

<script lang="ts">
import {
  computed, defineComponent,
  onBeforeMount,
  PropType,
  Ref,
  ref,
  SetupContext,
  watch,
} from 'vue';
import FiltreCustomerMetadata, {
  FiltreCustomerData, FiltreCustomerFilters,
} from '@/components/automated-scenarios/metadata/filtres/FiltreCustomerMetadata';
import ButtonGroup from '@/components/automated-scenarios/fields/ButtonGroup.vue';
import PeriodSelector from '@/components/automated-scenarios/fields/PeriodSelector.vue';
import { FilterField, FilterSegment, Period } from '@/types/automated-scenarios';
import { useI18n } from 'vue-i18n';
import { StringMap } from '@/types';
import {
  addInArraySegment,
  addInSegment,
  getActiveSegments,
  removeInSegment,
} from '@/composables/AutomatedScenarioSegments';
import { AutomatedScenarioState as state } from '@/composables/AutomatedScenarios';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import getSegments from './segments/FiltreCustomerSegments';
import FilterGlobalInformations from '../../fields/FilterGlobalInformations.vue';
import FilterPanelContent from '../../FilterPanelContent.vue';
import FilterSegmentForm from '../../FilterSegmentForm.vue';

export default defineComponent({
  name: 'FiltreCustomer',

  components: {
    FieldErrors,
    FilterGlobalInformations,
    FilterPanelContent,
    ButtonGroup,
    PeriodSelector,
    FilterSegmentForm,
  },

  props: {
    modelValue: {
      type: Object as PropType<FiltreCustomerData>,
      required: true,
      default() {
        return FiltreCustomerMetadata.Create();
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: FiltreCustomerData; formValidation: any }, context: SetupContext) {
    const { t } = useI18n();
    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    const commonFields = ref({
      custom_title: props.modelValue.custom_title,
      custom_description: props.modelValue.custom_description,
      outputs_number: props.modelValue.outputs_number,
    });
    const grantAdditionalDelay = ref(props.modelValue.grant_additional_delay);
    const delay = ref<Period>({
      unit: props.modelValue.extra_time ? props.modelValue.extra_time.unit : 'DAY',
      value: props.modelValue.extra_time ? props.modelValue.extra_time.value : 1,
    });
    const options = [
      { value: '0', label: t('no') },
      { value: '1', label: t('yes') },
    ];
    const units = [
      { value: 'SECOND', label: t('automatedScenarios.fields.durations.second(s)') },
      { value: 'MINUTE', label: t('automatedScenarios.fields.durations.minute(s)') },
      { value: 'HOUR', label: t('automatedScenarios.fields.durations.hour(s)') },
      { value: 'DAY', label: t('automatedScenarios.fields.durations.day(s)') },
    ];

    const includeSegments: Ref<FilterSegment<FiltreCustomerFilters>[]> = ref([]);
    const excludeSegments: Ref<FilterSegment<FiltreCustomerFilters>[]> = ref([]);
    const activeIncludeSegments = computed(() => getActiveSegments<FiltreCustomerFilters>(props.modelValue.inclusion, includeSegments.value));
    const activeExcludeSegments = computed(() => getActiveSegments<FiltreCustomerFilters>(props.modelValue.exclusion, excludeSegments.value));
    const configuringSegment = computed(() => state.configuringSegment);

    const filters: Ref<{ include: FiltreCustomerFilters; exclude: FiltreCustomerFilters}> = ref({
      include: { ...props.modelValue.inclusion },
      exclude: { ...props.modelValue.exclusion },
    });

    const activeItemsFilterPanel = computed(() => ({
      include: activeIncludeSegments.value.length,
      exclude: activeExcludeSegments.value.length,
    }));

    onBeforeMount(async () => {
      includeSegments.value = await getSegments();
      excludeSegments.value = await getSegments();
    });

    const setOnlyKeys = (field: FilterField) => Object.keys(field.data).reduce((acc: StringMap, key: string) => ({ ...acc, [key]: key }), {});

    const setKeyValue = (field: FilterField) => field.data
      .reduce((acc: StringMap, value: { id_shop_tag: string; tag: string }) => ({ ...acc, [value.id_shop_tag]: value.tag }), {});

    const update = () => context.emit('update:modelValue', FiltreCustomerMetadata.Create({
      ...commonFields.value,
      grant_additional_delay: grantAdditionalDelay.value,
      extra_time: delay.value,
      inclusion: filters.value.include,
      exclusion: filters.value.exclude,
    }));

    const handleAddSegment = (type: keyof { include: FiltreCustomerFilters; exclude: FiltreCustomerFilters },
      segment: FilterSegment<FiltreCustomerFilters>) => {
      switch (segment.id) {
        case 'filtercanreceivesms':
        case 'filtercanreceivefbmessenger':
        case 'filtercanreceivepushnotifications':
        case 'filtercustomersubscription':
          filters.value[type] = {
            ...filters.value[type],
            [segment.id]: { ...addInSegment<FiltreCustomerFilters>(segment, filters.value[type][segment.id]) },
          };
          break;
        case 'filterareas':
          // eslint-disable-next-line no-param-reassign
          segment.fields[0].data = { 0: (segment.fields[0].data) };
          filters.value[type] = {
            ...filters.value[type],
            [segment.id]: { ...addInArraySegment<FiltreCustomerFilters>(segment, filters.value[type][segment.id]) },
          };
          break;
        case 'filterhascustomertag':
          // eslint-disable-next-line no-param-reassign
          segment.fields[0].data = setKeyValue(segment.fields[0]);
          filters.value[type] = {
            ...filters.value[type],
            [segment.id]: { ...addInArraySegment<FiltreCustomerFilters>(segment, filters.value[type][segment.id]) },
          };
          break;
        case 'filtergender':
        case 'filternewslettersubscription':
          // eslint-disable-next-line no-param-reassign
          segment.fields[0].data = setOnlyKeys(segment.fields[0]);
          filters.value[type] = {
            ...filters.value[type],
            [segment.id]: { ...addInArraySegment<FiltreCustomerFilters>(segment, filters.value[type][segment.id]) },
          };
          break;
        default:
          filters.value[type] = {
            ...filters.value[type],
            [segment.id]: { ...addInArraySegment<FiltreCustomerFilters>(segment, filters.value[type][segment.id]) },
          };
      }
      update();
    };

    const handleRemoveSegment = (type: keyof { include: FiltreCustomerFilters; exclude: FiltreCustomerFilters },
      segmentName: keyof FiltreCustomerFilters, number: number) => {
      filters.value[type] = removeInSegment<FiltreCustomerFilters>(filters.value[type], segmentName, number);
      update();
    };

    watch([commonFields, grantAdditionalDelay, delay], update, { deep: true });

    return {
      formValidationErrors,
      componentFieldErrorsKey,
      commonFields,
      grantAdditionalDelay,
      options,
      delay,
      units,
      includeSegments,
      excludeSegments,
      activeIncludeSegments,
      activeExcludeSegments,
      filters,
      handleAddSegment,
      handleRemoveSegment,
      configuringSegment,
      activeItemsFilterPanel,
    };
  },
});
</script>
