
import { defineComponent, computed, PropType, ref } from 'vue';
import Dialog from 'primevue/dialog';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import AddressMap from '@/components/data-explorer/fields/AddressMap.vue';
import { countries } from '@/types/country-language-options';
import {
  ContactAddress,
} from '@/types/data-explorer-types';
import { useStore } from '@/store';
import { useI18n } from 'vue-i18n';

interface LatLng {
  lat: number;
  lng: number;
}

export default defineComponent({
  name: 'AddressItem',

  components: {
    SpmButton,
    Dialog,
    AddressMap,
  },

  props: {
    address: {
      type: Object as PropType<ContactAddress>,
      required: true,
    },

    showToDetailsButton: {
      type: Boolean,
      required: false,
      default: true,
    },

    showMapButton: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  setup (props) {
    const { t } = useI18n();
    const store = useStore();

    const mapVisible = ref(false);
    const coordinate = ref<LatLng>({
      lat: 0,
      lng: 0,
    });

    const fullAddress = computed(() => {
      const address = [];
      if (props.address.address1) {
        address.push(props.address.address1);
      }
      if (props.address.city && props.address.postcode) {
        address.push(`${props.address.city} ${props.address.postcode}`);
      } else if (props.address.city && !props.address.postcode) {
        address.push(props.address.city);
      } else if (!props.address.city && props.address.postcode) {
        address.push(props.address.postcode);
      }
      if (props.address.country) {
        const country = countries.find((countryItem) => countryItem.code.toLowerCase() === props.address.country?.toLowerCase());
        if (country) {
          address.push(country.label);
        }
      }
      return address.join(', ');
    });

    const openAddressDetails = async () => {
      store.dispatch('dataExplorer/openAddressFile', { idShopCustomerAddress: props.address.idShopCustomerAddress });
    };

    const openMap = async () => {
      mapVisible.value = true;
    };

    return {
      t,
      fullAddress,
      mapVisible,
      coordinate,

      openAddressDetails,
      openMap,
    };
  }
})
