import { ComponentNameEnum, SocialNetworksValueEnum, BaseFieldConfig } from '@/types';

const listFields = [
  { label: 'templateBuilder.fields.facebook', value: SocialNetworksValueEnum.FACEBOOK },
  { label: 'templateBuilder.fields.twitter', value: SocialNetworksValueEnum.TWITTER },
  { label: 'templateBuilder.fields.tumblr', value: SocialNetworksValueEnum.TUMBLR },
  { label: 'templateBuilder.fields.pinterest', value: SocialNetworksValueEnum.PINTEREST },
  { label: 'templateBuilder.fields.email', value: SocialNetworksValueEnum.EMAIL },
  // { label: 'templateBuilder.fields.instagram', value: SocialNetworksValueEnum.INSTAGRAM },
  // { label: 'templateBuilder.fields.youtube', value: SocialNetworksValueEnum.YOUTUBE },
  // { label: 'templateBuilder.fields.whatsapp', value: SocialNetworksValueEnum.WHATSAPP },
  // { label: 'templateBuilder.fields.snapchat', value: SocialNetworksValueEnum.SNAPCHAT },
];

const activeSharingSocialNetworksFieldConfig: BaseFieldConfig = {
  componentName: ComponentNameEnum.MULTIDROPDOWN,
  options: {
    list: listFields,
    defaultValue: [],
    mandatoryFields: [],
  },
};

export default activeSharingSocialNetworksFieldConfig;
