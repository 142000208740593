<template>
  <TabView ref="tabview1">
    <TabPanel
      v-for="(panel, index) in widgetData.tabs"
      :key="index"
      :header="panel.name"
    >
      <div v-if="!editMode">
        <draggable
          :list="panel.children"
          :disabled="!enabled"
          item-key="name"
          style="min-height: 4rem;"
          class="block grid mt-1"
          handle=".handle"
          v-bind="dragOptions"
          @start="dragging = true"
          @end="dragging = false"
        >
          <template #item="{ element }">
            <div
              class="root-row mb-2 p-2 "
              :class="{ 'not-draggable': !enabled }"
            >
              <StatsBuilderSubRow
                :row-parent-id="rowId"
                :column-parent-id="columnId"
                :panel-id="panel.panelId"
                :row-data="element"
              />
            </div>
          </template>
        </draggable>
      </div>
    </TabPanel>
  </TabView>
</template>

<script lang="ts">
import { defineComponent, ref, SetupContext } from 'vue';
import StatsBuilderSubRow from '@/components/stats-builder/StatsBuilderSubRow.vue';
import draggable from 'vuedraggable';
import { useI18n } from 'vue-i18n';
import Tooltip from 'primevue/tooltip';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

export default defineComponent({
  name: 'StatsWidgetTabsRender',
  components: {
    StatsBuilderSubRow,
    draggable,
    TabView,
    TabPanel,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    rowId: {
      type: String,
      required: true,
    },

    columnId: {
      type: String,
      required: true,
    },

    editMode: {
      type: Boolean,
      required: true,
    },

    widgetData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  setup(props: { widgetData: object; editMode: boolean}, { emit }: SetupContext) {
    const enabled = ref(true);
    const dragging = ref(false);
    const dragOptions = ref({
      animation: 200,
      group: { name: 'line_builder', pull: false, put: ['line_element'] },
      disabled: false,
    });

    return {
      enabled,
      dragging,
      dragOptions,
    };
  },
});
</script>

<style lang="scss">

</style>
