import WidgetDataMetadata from '@/components/stats-builder/metadata/WidgetDataMetadata';
import WidgetTitleMetadata from '@/components/stats-builder/metadata/WidgetTitleMetadata';
// eslint-disable-next-line import/no-cycle
import WidgetMenuMetadata from '@/components/stats-builder/metadata/WidgetMenuMetadata';
// eslint-disable-next-line import/no-cycle
import WidgetTabsMetadata from '@/components/stats-builder/metadata/WidgetTabsMetadata';

import { StatsWidgetComponentMetadata } from '@/types/stats-editor-types';

const StatsWidgetMetadata = {
  WidgetTitleMetadata,
  WidgetDataMetadata,
  WidgetMenuMetadata,
};

export default StatsWidgetMetadata;

export function getComponentStatsWidgetMetadata(component: string): StatsWidgetComponentMetadata<any> | null {
  switch (component) {
    case 'StatsWidgetData':
      return WidgetDataMetadata;
    case 'StatsWidgetTitle':
      return WidgetTitleMetadata;
    case 'StatsWidgetMenu':
      return WidgetMenuMetadata;
    case 'StatsWidgetTabs':
      return WidgetTabsMetadata;
    default:
      return null;
  }
}
