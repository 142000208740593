import {
  WidgetTypeConfig,
  ConfigurationPanelStructureItemEnum,
  TextAlignValueEnum,
  ComponentNameEnum,
  StepperSignEnum,
  TemplateStructureEnum,
} from '@/types';
import getHorizontalSpacesFieldConfig from '@/components/template-builder/config/fields/horizontal-spaces-config';
import getVerticalSpacesFieldConfig from '@/components/template-builder/config/fields/vertical-spaces-config';
// eslint-disable-next-line import/no-cycle
import {
  setCssProperty,
  setMediaDisplay,
  setTruncateLimit,
  setSmartListProperty,
  setDisplayedElements,
  setSmartListHtmlCode,
  setSmartListButtonTitle,
  setSmartListTitle,
  recalculateLineHeightValue,
  setCssPropertyAndRemoveIfZero, setCssButtonWidthProperty,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import {
  getClassByName,
  getContent,
  getCssPropertyByName,
  getTruncateLimit,
  getDisplayedElements,
  getSmartListHtmlCode,
  getSmartListProperty,
} from '@/components/template-builder/getters';
import variables from '@/components/template-builder/utils/variables-list';
// eslint-disable-next-line import/no-cycle
import { refreshSmartProductList } from '@/components/template-builder/callbacks';
import getColorFieldConfig from '@/components/template-builder/config/fields/color-config';
import fontSizeFieldConfig from '@/components/template-builder/config/fields/font-size-config';
import textStylesFieldConfig from '@/components/template-builder/config/fields/text-styles-config';
import getTextAlignFieldConfig from '@/components/template-builder/config/fields/text-align-config';
import bordersFieldConfig from '@/components/template-builder/config/fields/borders-config';
import getWidthFieldConfig from '@/components/template-builder/config/fields/width-config';
import mediaDisplayFieldConfig from '@/components/template-builder/config/fields/media-display-config';
import getInputVariablesFieldConfig from '@/components/template-builder/config/fields/input-variables-config';
import selectionTypeConfig from '@/components/template-builder/config/fields/selection-type-config';
import getItemListFieldConfig from '@/components/template-builder/config/fields/item-list-config';
import listItemsFieldConfig from '@/components/template-builder/config/fields/list-items-config';
import getCodeFieldConfig from '@/components/template-builder/config/fields/code-config';
import getBorderRadiusFieldConfig from '@/components/template-builder/config/fields/border-radius-config';
import { OperatorType } from '@/types/generated-types/graphql';
import getStepperFieldConfig from '@/components/template-builder/config/fields/stepper-config';
import { UserState } from '@/composables/User';
import lineHeightFieldConfig from '@/components/template-builder/config/fields/line-height-config';
import fontFieldConfig from '@/components/template-builder/config/fields/font-config';
import getButtonWidthFieldConfig from '@/components/template-builder/config/fields/button-width-config';

const widgetSmartProductListConfig: WidgetTypeConfig = {
  tabPanel: {
    label: 'templateBuilder.widgetsHeaders.smartProductList',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
        tabItemType: TemplateStructureEnum.GROUP,
        label: 'templateBuilder.configs.widgetConfiguration',
        tabPanel: {
          items: [
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.productListConfiguration.list',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.listTitle',
                  selector: '{SELECTOR} .spm_product_list_title',
                  properties: [{
                    name: 'listTitle', getter: getContent, setters: [setSmartListTitle], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                  }],
                  ...getInputVariablesFieldConfig('', variables.allVariables, false, false),
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.productListConfiguration.products',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.selectionType',
                  selector: '{SELECTOR} .spm_product_list_element_container',
                  properties: [
                    {
                      name: 'engine', getter: getSmartListProperty, setters: [setSmartListProperty], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                    },
                    {
                      name: 'nb_weeks', getter: getSmartListProperty, setters: [setSmartListProperty], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                    },
                    {
                      name: 'ids', getter: getSmartListProperty, setters: [setSmartListProperty], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                    },
                  ],
                  ...selectionTypeConfig,
                },
                {
                  label: 'templateBuilder.fields.productList',
                  selector: '{SELECTOR} .spm_product_list_element_container',
                  properties: [{
                    name: 'excluded_ids', getter: getSmartListProperty, setters: [setSmartListProperty], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                  }],
                  ...getItemListFieldConfig(
                    [],
                    'ShopsProducts',
                    { id: 'id_shop_product', label: 'name' },
                    'templateBuilder.fields.excludeProducts',
                    [{ field: 'lang', value: UserState.user.lang ?? 'fr', operator: OperatorType.Equals }, { field: 'active', value: true, operator: OperatorType.Equals }],
                  ),
                },
                {
                  label: 'templateBuilder.fields.categoryList',
                  selector: '{SELECTOR} .spm_product_list_element_container',
                  properties: [{
                    name: 'excluded_categories', getter: getSmartListProperty, setters: [setSmartListProperty], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                  }],
                  ...getItemListFieldConfig(
                    [],
                    'ShopsProductsCategoriesList',
                    { id: 'id_shop_product_category_list', label: 'name' },
                    'templateBuilder.fields.excludeCategories',
                    [{ field: 'lang', value: UserState.user.lang ?? 'fr', operator: OperatorType.Equals }],
                  ),
                },
                {
                  label: 'templateBuilder.fields.manufacturerList',
                  selector: '{SELECTOR} .spm_product_list_element_container',
                  properties: [{
                    name: 'excluded_manufacturers',
                    getter: getSmartListProperty,
                    setters: [setSmartListProperty],
                    callbacks: [refreshSmartProductList],
                    refreshWidgetFields: true,
                  }],
                  ...getItemListFieldConfig(
                    [],
                    'ShopsManufacturers',
                    { id: 'id_manufacturer', label: 'name' },
                    'templateBuilder.fields.excludeManufacturers',
                    [],
                  ),
                },
                {
                  label: 'templateBuilder.fields.maxDisplayedProducts',
                  selector: '{SELECTOR} .spm_product_list_element_container',
                  properties: [{
                    name: 'nb', getter: getSmartListProperty, setters: [setSmartListProperty], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                  }],
                  ...getStepperFieldConfig(),
                },
                {
                  label: 'templateBuilder.fields.displayedElements',
                  selector: '{SELECTOR}',
                  properties: [
                    {
                      name: 'displayedElements',
                      getter: getDisplayedElements,
                      setters: [setDisplayedElements],
                      callbacks: [refreshSmartProductList],
                      refreshWidgetFields: true,
                    },
                  ],
                  ...listItemsFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.buttonText',
                  selector: '{SELECTOR} a.spm_product_list_element_button',
                  properties: [{
                    name: 'buttonText', getter: getContent, setters: [setSmartListButtonTitle], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                  }],
                  ...getInputVariablesFieldConfig('', variables.allVariables, false, false),
                },
                {
                  label: 'templateBuilder.fields.maxProductTitleCharacters',
                  selector: 'product.name',
                  properties: [{
                    name: 'truncate', getter: getTruncateLimit, setters: [setTruncateLimit], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                  }],
                  ...getStepperFieldConfig(),
                },
                {
                  label: 'templateBuilder.fields.maxProductDescriptionCharacters',
                  selector: 'product.description_short',
                  properties: [{
                    name: 'truncate', getter: getTruncateLimit, setters: [setTruncateLimit], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                  }],
                  ...getStepperFieldConfig(),
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.fields.htmlCode',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: '',
                  selector: '{SELECTOR}',
                  properties: [
                    {
                      name: 'content',
                      getter: getSmartListHtmlCode,
                      setters: [setSmartListHtmlCode],
                      callbacks: [
                        refreshSmartProductList,
                      ],
                      refreshWidgetFields: true,
                    },
                  ],
                  ...getCodeFieldConfig('', true, variables.allVariables),
                },
              ],
            },
          ],
        },
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.productListStyles',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.productwidth',
            selector: '{SELECTOR} .spm_product_list_element_wrapper',
            properties: [{ name: 'width', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getWidthFieldConfig(25),
          },
          {
            label: 'templateBuilder.fields.contentAlignment',
            selector: '{SELECTOR} .spm_product_list_element',
            properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getTextAlignFieldConfig(TextAlignValueEnum.CENTER),
          },
          {
            label: 'templateBuilder.fields.internalMargins',
            selector: '{SELECTOR} .spm_product_list_element',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig('padding').options,
            },
          },
          {
            label: 'templateBuilder.fields.externalMargins',
            selector: '{SELECTOR} .spm_product_list_element',
            properties: [
              { name: 'margin-top', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'margin-bottom', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'margin-left', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'margin-right', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('margin').options,
              ...getHorizontalSpacesFieldConfig('margin').options,
            },
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.productListTitleStyles',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.font',
            selector: '{SELECTOR} .spm_product_list_title',
            properties: [
              { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
              { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...fontFieldConfig,
          },
          {
            label: 'templateBuilder.fields.titleAlign',
            selector: '{SELECTOR} .spm_product_list_title_wrapper',
            properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getTextAlignFieldConfig(TextAlignValueEnum.CENTER),
          },
          {
            label: 'templateBuilder.fields.lineHeight.label',
            selector: '{SELECTOR} .spm_product_list_title',
            properties: [{ name: 'line-height', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...lineHeightFieldConfig,
          },
          {
            label: 'templateBuilder.fields.spaceBetweenChars',
            selector: '{SELECTOR} .spm_product_list_title',
            properties: [{ name: 'letter-spacing', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getStepperFieldConfig(StepperSignEnum.PX),
          },
          {
            label: 'templateBuilder.fields.titleMarginVertical',
            selector: '{SELECTOR} .spm_product_list_title',
            properties: [
              { name: 'margin-top', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'margin-bottom', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...getVerticalSpacesFieldConfig('margin'),
          },
          {
            label: 'templateBuilder.fields.titleMarginHorizontal',
            selector: '{SELECTOR} .spm_product_list_title',
            properties: [
              { name: 'margin-left', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'margin-right', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...getHorizontalSpacesFieldConfig('margin'),
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
        tabItemType: TemplateStructureEnum.GROUP,
        label: 'templateBuilder.configs.productListProductStyles.header',
        tabPanel: {
          items: [
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.productListProductStyles.title',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.productTitleColor',
                  selector: '{SELECTOR} .spm_product_list_element_title, {SELECTOR} a.spm_product_list_element_title, {SELECTOR} .spm_product_list_element_title a, '
                    + '{SELECTOR} a.spm_product_list_element_title:hover, {SELECTOR} .spm_product_list_element_title a:hover, '
                    + '{SELECTOR} a.spm_product_list_element_title:visited, {SELECTOR} .spm_product_list_element_title a:visited, '
                    + '{SELECTOR} a.spm_product_list_element_title:active, {SELECTOR} .spm_product_list_element_title a:active',
                  properties: [{ name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getColorFieldConfig('#000000'),
                },
                {
                  label: 'templateBuilder.fields.productTitleFontSize',
                  selector: '{SELECTOR} .spm_product_list_element_title, {SELECTOR} a.spm_product_list_element_title, {SELECTOR} .spm_product_list_element_title a, '
                    + '{SELECTOR} a.spm_product_list_element_title:hover, {SELECTOR} .spm_product_list_element_title a:hover, '
                    + '{SELECTOR} a.spm_product_list_element_title:visited, {SELECTOR} .spm_product_list_element_title a:visited, '
                    + '{SELECTOR} a.spm_product_list_element_title:active, {SELECTOR} .spm_product_list_element_title a:active',
                  properties: [{ name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] }],
                  ...fontSizeFieldConfig,
                  options: {
                    ...fontSizeFieldConfig.options,
                    px: {
                      ...fontSizeFieldConfig.options.px,
                      defaultValue: 18,
                    },
                  },
                },
                {
                  label: 'templateBuilder.fields.productTitleMarginVertical',
                  selector: '{SELECTOR} .spm_product_list_element_title',
                  properties: [
                    { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                  ],
                  ...getVerticalSpacesFieldConfig('padding'),
                },
                {
                  label: 'templateBuilder.fields.productTitleMarginHorizontal',
                  selector: '{SELECTOR} .spm_product_list_element_title',
                  properties: [
                    { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                  ],
                  ...getHorizontalSpacesFieldConfig('padding'),
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.productListProductStyles.price',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.productPriceFontSize',
                  selector: '{SELECTOR} a.spm_product_list_element_price, {SELECTOR} .spm_product_list_element_price, {SELECTOR} .spm_product_list_element_price a',
                  properties: [
                    {
                      name: 'font-size',
                      getter: getCssPropertyByName,
                      setters: [recalculateLineHeightValue, setCssProperty],
                    },
                  ],
                  ...fontFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.productPriceColor',
                  selector: '{SELECTOR} a.spm_product_list_element_price, {SELECTOR} .spm_product_list_element_price, {SELECTOR} .spm_product_list_element_price a',
                  properties: [{ name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getColorFieldConfig('#333333'),
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.productListProductStyles.priceStrike',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.productCrossedPriceFontSize',
                  selector: '{SELECTOR} a.spm_product_list_element_price_strike, {SELECTOR} .spm_product_list_element_price_strike, '
                    + '{SELECTOR} .spm_product_list_element_price_strike a',
                  properties: [
                    {
                      name: 'font-size',
                      getter: getCssPropertyByName,
                      setters: [recalculateLineHeightValue, setCssProperty],
                    },
                  ],
                  ...fontFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.productCrossedPriceColor',
                  selector: '{SELECTOR} a.spm_product_list_element_price_strike, {SELECTOR} .spm_product_list_element_price_strike, '
                    + '{SELECTOR} .spm_product_list_element_price_strike a',
                  properties: [{ name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getColorFieldConfig('#cc0000'),
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.productListProductStyles.button',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.buttonWidth',
                  selector: '{SELECTOR} a.spm_product_list_element_button',
                  properties: [{
                    name: 'width',
                    getter: getCssPropertyByName,
                    setters: [setCssButtonWidthProperty],
                  }],
                  ...getButtonWidthFieldConfig(),
                },
                {
                  label: 'templateBuilder.fields.contours',
                  selector: '{SELECTOR} a.spm_product_list_element_button',
                  properties: [{ name: 'border-radius', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getBorderRadiusFieldConfig('5px'),
                },
                {
                  label: 'templateBuilder.fields.buttonBorders',
                  selector: '{SELECTOR} a.spm_product_list_element_button',
                  properties: [
                    { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-style', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
                  ],
                  ...bordersFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.buttonBackgroundColor',
                  selector: '{SELECTOR} a.spm_product_list_element_button, {SELECTOR} a.spm_product_list_element_button:hover, '
                    + '{SELECTOR} a.spm_product_list_element_button:active, '
                    + '{SELECTOR} a.spm_product_list_element_button:visited, {SELECTOR} .spm_product_list_element_button a, {SELECTOR} .spm_product_list_element_button a:hover, '
                    + '{SELECTOR} .spm_product_list_element_button a:active, {SELECTOR} .spm_product_list_element_button a:visited',
                  properties: [{
                    name: 'background',
                    getter: getCssPropertyByName,
                    setters: [setCssProperty],
                  }],
                  ...getColorFieldConfig('#ECECEC', false, true),
                },
                {
                  label: 'templateBuilder.fields.font',
                  selector: '{SELECTOR} a.spm_product_list_element_button, {SELECTOR} a.spm_product_list_element_button:hover, '
                    + '{SELECTOR} a.spm_product_list_element_button:active, '
                    + '{SELECTOR} a.spm_product_list_element_button:visited, {SELECTOR} .spm_product_list_element_button a, {SELECTOR} .spm_product_list_element_button a:hover, '
                    + '{SELECTOR} .spm_product_list_element_button a:active, {SELECTOR} .spm_product_list_element_button a:visited',
                  properties: [
                    { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...fontFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.buttonContentAlignment',
                  selector: '{SELECTOR} a.spm_product_list_element_button',
                  properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getTextAlignFieldConfig(TextAlignValueEnum.CENTER),
                },
                {
                  label: 'templateBuilder.fields.buttonSpaceBetweenChars',
                  selector: '{SELECTOR} .spm_product_list_element_button a, {SELECTOR} .spm_product_list_element_button a:hover, '
                    + '{SELECTOR} .spm_product_list_element_button a:active, {SELECTOR} .spm_product_list_element_button a:visited, '
                    + '{SELECTOR} a.spm_product_list_element_button:hover, {SELECTOR} a.spm_product_list_element_button:active, '
                    + '{SELECTOR} a.spm_product_list_element_button:visited, {SELECTOR} a.spm_product_list_element_button',
                  properties: [{ name: 'letter-spacing', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getStepperFieldConfig(StepperSignEnum.PX),
                },
                {
                  label: 'templateBuilder.fields.spaceWithinButton',
                  selector: '{SELECTOR} a.spm_product_list_element_button',
                  properties: [
                    { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                  ],
                  componentName: ComponentNameEnum.SPACES,
                  options: {
                    ...getVerticalSpacesFieldConfig('padding').options,
                    ...getHorizontalSpacesFieldConfig('padding').options,
                  },
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.productListProductStyles.buttonHover',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.buttonHoverBackgroundColor',
                  selector: '{SELECTOR} .spm_product_list_element .spm_button:hover',
                  properties: [{
                    name: 'background',
                    getter: getCssPropertyByName,
                    setters: [setCssProperty],
                  }],
                  ...getColorFieldConfig('#ECECEC', false, true),
                },
                {
                  label: 'templateBuilder.fields.buttonBorders',
                  selector: '{SELECTOR} .spm_product_list_element .spm_button:hover',
                  properties: [
                    { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-style', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
                  ],
                  ...bordersFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.buttonHoverTextColor',
                  selector: '{SELECTOR} .spm_product_list_element .spm_button:hover',
                  properties: [{ name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getColorFieldConfig('#000000'),
                },
                {
                  label: 'templateBuilder.fields.buttonHoverFontSize',
                  selector: '{SELECTOR} .spm_product_list_element .spm_button:hover',
                  properties: [{ name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] }],
                  ...fontSizeFieldConfig,
                  options: {
                    ...fontSizeFieldConfig.options,
                    px: {
                      ...fontSizeFieldConfig.options.px,
                      defaultValue: 16,
                    },
                  },
                },
                {
                  label: 'templateBuilder.fields.buttonHoverTextStyles',
                  selector: '{SELECTOR} .spm_product_list_element .spm_button:hover',
                  properties: [
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...textStylesFieldConfig,
                },
              ],
            },
          ],
        },
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.background',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.backgroundColor',
            selector: '{SELECTOR}',
            properties: [{
              name: 'background',
              getter: getCssPropertyByName,
              setters: [setCssProperty],
            }],
            ...getColorFieldConfig(undefined, false, true),
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.marginsAndBorders',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.internalMargins',
            selector: '{SELECTOR}',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig('padding').options,
            },
          },
          {
            label: 'templateBuilder.fields.bordersCentralArea',
            selector: '{SELECTOR}',
            properties: [
              { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-style', getter: getCssPropertyByName, setters: [] },
              { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
            ],
            ...bordersFieldConfig,
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.media',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.mediaDisplay',
            selector: '{SELECTOR}',
            properties: [{ name: 'show-for-', getter: getClassByName, setters: [setMediaDisplay] }],
            ...mediaDisplayFieldConfig,
          },
        ],
      },
    ],
  },
};

export default widgetSmartProductListConfig;
