import { EcommerceSolution } from '@/types/store-parameters-types';

const solutions: EcommerceSolution[] = [
  { code: 'prestashop', label: 'Prestashop' },
  { code: 'magento', label: 'Magento' },
  { code: 'magento2', label: 'Magento 2' },
  { code: 'woocommerce', label: 'WooCommerce' },
  { code: 'thelia', label: 'Thelia (Bêta)' },
];

export default solutions;
