<template>
  <div class="configuration-wrapper">
    <div class="field-wrapper">
      <label class="block mb-2">
        {{ t('statsEditor.sidebarPanel.settings.stats.nameLabel') }}
      </label>
      <LocalizedTextInput
        id="StatsSettingsPanelSettingName"
        v-model="settingsFields.name"
        class="input-text"
        :display-translate="true"
        style="width: 100%;"
        :default-languages="defaultLanguages"
      />
      <FieldErrors
        :key="componentFieldErrorsKey"
        :errors="formValidationErrors"
        field="name"
        silent="false"
      />
    </div>
    <div class="field-wrapper">
      <label class="block mb-2">
        {{ t('statsEditor.sidebarPanel.settings.stats.navigationNameLabel') }}
      </label>
      <LocalizedTextInput
        id="StatsSettingsPanelSettingNavigationName"
        v-model="settingsFields.navigationName"
        class="input-text"
        :display-translate="true"
        style="width: 100%;"
        :default-languages="defaultLanguages"
      />
      <FieldErrors
        :key="componentFieldErrorsKey"
        :errors="formValidationErrors"
        field="navigationName"
        silent="false"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import {
  defineComponent,
  reactive, ref, watch,
} from 'vue';
import {
  GlobalStatsSettingsData,
} from '@/types/stats-editor-types';
import { useStore } from '@/store';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import defaultLanguages from '@/configs/languages';

export default defineComponent({
  name: 'StatsSettingsPanel',

  components: {
    FieldErrors,
    LocalizedTextInput,
  },

  props: {
    settingPanelValidationErrors: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  setup(props: {settingPanelValidationErrors: any }) {
    const { t, locale } = useI18n();
    const store = useStore();

    const formValidationErrors = ref(props.settingPanelValidationErrors);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.settingPanelValidationErrors, () => {
      formValidationErrors.value = props.settingPanelValidationErrors;
      componentFieldErrorsKey.value += 1;
    });

    const settingsFields: GlobalStatsSettingsData = reactive({
      name: (store.getters['statsEditor/getPageSettings'] as GlobalStatsSettingsData).name,
      navigationName: (store.getters['statsEditor/getPageSettings'] as GlobalStatsSettingsData).navigationName,
    });

    const isJsonString = (str: string) => str.trim().startsWith('{') && str.trim().endsWith('}');

    if (typeof settingsFields.name === 'string') {
      if (isJsonString(settingsFields.name)) {
        settingsFields.name = JSON.parse(settingsFields.name);
      } else {
        settingsFields.name = Object.assign(
          {},
          ...defaultLanguages.map((lang) => ({ [lang]: settingsFields.name })),
        );
      }
    }
    if (typeof settingsFields.navigationName === 'string') {
      if (isJsonString(settingsFields.navigationName)) {
        settingsFields.navigationName = JSON.parse(settingsFields.navigationName);
      } else {
        settingsFields.navigationName = Object.assign(
          {},
          ...defaultLanguages.map((lang) => ({ [lang]: settingsFields.navigationName })),
        );
      }
    }

    watch([settingsFields], () => {
      store.commit('statsEditor/setPageSettings', settingsFields);
    }, { deep: true });

    return {
      t,
      settingsFields,
      formValidationErrors,
      componentFieldErrorsKey,
      defaultLanguages,
    };
  },
});
</script>
