import {
  TEMPLATE_COLUMN_IDENTIFIER_PREFIX,
  TEMPLATE_LINE_IDENTIFIER_PREFIX,
  TEMPLATE_WIDGET_IDENTIFIER_PREFIX,
  TEMPLATE_TRANSLATE_SPECIFIC,
  TEMPLATE_TRANSLATE_SPECIFIC_ITEMS,
  TEMPLATE_TRANSLATE_SPECIFIC_ONLY_ATTRIBUTE,
  TEMPLATE_TRANSLATE_SPECIFIC_ONLY_CONTENT,
  TEMPLATE_TRANSLATE_SPECIFIC_HTML_CONTENT,
  WIDGETS_PLACEHOLDER_IDENTIFIER,
} from '@/components/template-builder/utils/constants';
// eslint-disable-next-line import/no-cycle
import { generateUniqStructureId } from '@/components/template-builder/utils/helpers';
import { WidgetTypeEnum } from '@/types';
import { faArrowToBottom, faTrashAltRegular } from '@/components/template-builder/utils/icons-definition';

// structures
export const rawWidgetPlaceholder = () => `<div class="${WIDGETS_PLACEHOLDER_IDENTIFIER}">${faTrashAltRegular}${faArrowToBottom}</div>`;

export const rawColumn = () => {
  const columnId = `${TEMPLATE_COLUMN_IDENTIFIER_PREFIX}_${generateUniqStructureId()}`;
  return `<div class="spm_column spm_droppable_widget spm_droppable" style="width: calc(50% - 1.5px);" id="${columnId}">${rawWidgetPlaceholder()}</div>`;
};

export const rawLine = () => {
  const lineId = `${TEMPLATE_LINE_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<div id="${lineId}" class="spm_draggable_row spm_row"><div class="spm_columns">${rawColumn()}</div></div>`;
};

export const rawPrivacyPolicy = (t: Function) => {
  const lineId = `${TEMPLATE_LINE_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  const columnId = `${TEMPLATE_COLUMN_IDENTIFIER_PREFIX}_${generateUniqStructureId()}`;
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  const text = t('templateBuilder.privacyPolicy');

  return `<div class="spm_draggable_row spm_row spm_responsive" id="${lineId}">
    <div class="spm_columns">
      <div class="spm_column spm_droppable_widget spm_droppable ui-sortable" id="${columnId}">
        <div id="${widgetId}" class="spm_widget_display_text spm_draggable_widget spm_widget" data-widgettype="spm_widget_display_text" style="display: block;">
          <div class="spm_rich_editor">
            <a class="${TEMPLATE_TRANSLATE_SPECIFIC_HTML_CONTENT}" href="{var=shop.privacy_policy_url}">${text}</a>
          </div>
        </div>
      </div>
    </div>
  </div>`;
};

// widgets
const rawWidgetSeparator = () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<div id="${widgetId}" class="spm_widget_display_separator spm_draggable_widget spm_widget"
    data-widgettype="spm_widget_display_separator"><hr class="spm_display_separator" /></div>`;
};

const rawWidgetText = () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  const text = `<h1>Lorem ipsum dolor sit amet</h1><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ultricies lectus egestas nulla mollis cursus.
  Sed nec lectus non nulla auctor aliquam. <br /><br /> Mauris facilisis at lectus nec consequat.
  Donec at condimentum turpis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>`;
  return `<div id="${widgetId}" class="spm_widget_display_text spm_draggable_widget spm_widget"
    data-widgettype="spm_widget_display_text"><div class="spm_rich_editor">${text}</div></div>`;
};
const rawWidgetTitle = () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<div id="${widgetId}" class="spm_widget_display_title spm_draggable_widget spm_widget"
    data-widgettype="spm_widget_display_title"><h1 class="spm_inline_editing">Votre titre ici</h1></div>`;
};

const rawWidgetImage = () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  const src = 'http://media.shopimind.io/img/v4/placeholders/image_placeholder.png';
  return `<div id="${widgetId}" class="spm_widget_display_image spm_draggable_widget spm_widget"
  data-widgettype="spm_widget_display_image">
    <a href="{var=shop.url}">
      <img src="${src}" width="100%" alt="">
    </a>
  </div>`;
};

const rawWidgetCode = () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<div id="${widgetId}" class="spm_widget_display_code spm_draggable_widget spm_widget"
    data-widgettype="spm_widget_display_code"><p>Insert your own HTML code</p></div>`;
};

const rawWidgetButton = () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<div id="${widgetId}" class="spm_display_call_to_action spm_draggable_widget spm_widget"
    data-widgettype="spm_display_call_to_action">
    <a href="{var=shop.url}" class="spm_inline_editing spm_button spm_div_align_center">
      Votre texte ici
    </a></div>`;
};

const rawWidgetSocialMedia = (additionalConfiguration: Record<string, any>) => () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  let socialNetworksHtml = '';

  if (!additionalConfiguration.socialNetworks || !Array.isArray(additionalConfiguration.socialNetworks)) {
    // eslint-disable-next-line no-param-reassign
    additionalConfiguration.socialNetworks = [];
  }
  if ('socialNetworks' in additionalConfiguration && additionalConfiguration.socialNetworks && Array.isArray(additionalConfiguration.socialNetworks)) {
    if (additionalConfiguration.socialNetworks.length === 0) {
      additionalConfiguration.socialNetworks.push('facebook');
      additionalConfiguration.socialNetworks.push('twitter');
      additionalConfiguration.socialNetworks.push('email');
    }

    additionalConfiguration.socialNetworks.forEach((network: string) => {
      const variable = network === 'email' ? 'contact' : network;
      socialNetworksHtml += `<span class="spm_${network} spm_social_icon_container spm_social_icon">
        <a class="${TEMPLATE_TRANSLATE_SPECIFIC_ONLY_ATTRIBUTE}" href="{var=social.${variable}_url}">
        <img src="https://media.shopimind.io/img/icons/social/2/32/${network}.png" alt="${network}" width="32" style="max-width:32px;" />
        </a>
      </span>`;
    });
  }

  return `<div id="${widgetId}" class="spm_widget_display_follow spm_draggable_widget spm_widget ${TEMPLATE_TRANSLATE_SPECIFIC}" data-widgettype="spm_widget_display_follow">
    <div class="spm_social_icons spm_social_icons_horizontal">${socialNetworksHtml}</div></div>`;
};

const rawWidgetVoucher = () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<div id="${widgetId}" class="spm_widget_display_voucher spm_draggable_widget spm_widget ${TEMPLATE_TRANSLATE_SPECIFIC}" data-widgettype="spm_widget_display_voucher">
    <div class="spm_triggered_button spm_generate_voucher_button spm_with_icon spm_div_align_center">
      <div class="spm_container_voucher_button">
        <a href="javascript:void(0);" class="spm_generate_voucher spm_button spm_inline_editing" onclick="{var=voucher.button}">Voir mon code de réduction</a>
        <i class="spm_voucher_icon spm_icon-gift-2"></i>
      </div>
      <div class="spm_container_voucher_code spm_hidden">
        <a class="spm_voucher spm_button" href="javascript:void(0)">SPM-XXXX</a>
        <i class="spm_voucher_icon_code spm_icon-gift-2"></i>
      </div>
    </div>
  </div>`;
};

const rawWidgetSmartProductList = (t: Function) => () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<div id="${widgetId}" class="spm_smart_products_list spm_draggable_widget spm_widget" data-widgettype="spm_smart_products_list">
    <div class="spm_title_wrapper spm_product_list_title_wrapper">
      <span class="spm_product_list_title">${t('templateBuilder.smartProductListDefaultTitle')}</span>
    </div>
    <div class="spm_product_list_1 spm_product_list">
      <div class="spm_product_list_element_container">
        <div class="spm_product_list_element_wrapper"
          s-collection="products" s-item="product" s-grid="4" s-nb="4" s-engine="crossselling"
          s-ids="" s-excluded_ids="" s-excluded_categories="" s-excluded_manufacturers="" s-nb_weeks="4"
        >
          <div class="spm_product_list_element">
            <div class="spm_product_list_element_image_wrapper">
              <a rel="nofollow" href="{var=product.url}"><img src="{var=product.image_url resize=400x400 resizeType=fill-to-fit}"
              alt="{var=product.name}" class="spm_product_list_element_image"></a>
            </div>
            <h3 class="spm_product_list_element_title">{var=product.name truncate="20"}</h3>
            <div class="spm_product_list_element_prices">
              <span class="spm_product_list_element_price">{var=product.price}</span>
              <span s-if="product.price_strike" s-condition="!=" s-value="product.price" class="spm_product_list_element_price_strike">{var=product.price_strike}</span>
            </div>
            <p class="spm_product_list_element_description spm_hide_on_builder">{var=product.description_short truncate="50"}</p>
            <a rel="nofollow" href="{var=product.url}" class="spm_button spm_product_list_element_button">
              ${t('templateBuilder.smartProductListDefaultButton')}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>`;
};

const rawWidgetCreateAccount = (t: Function) => () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<div id="${widgetId}" class="spm_widget_display_form_registration spm_draggable_widget spm_widget ${TEMPLATE_TRANSLATE_SPECIFIC}" data-widgettype="spm_form_registration">
    <div class="spm_form spm_action_displayMessage">
      <div class="spm_step1">
          <form name="spm_form_registration" class="spm_form_registration spm_label_inside">
              <div class="spm_form_field spm_form_field_base">
                  <label for="spm_form_registration_last_name">
                    <span class="${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}">
                      ${t('templateBuilder.fields.lastname')} :
                    </span>
                    <i class="required_asterisk">*</i>
                  </label>
                  <input type="text" id="spm_form_registration_last_name" name="spm_form_registration_last_name"
                    class="spm_form_input spm_form_required ${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}"
                    placeholder="${t('templateBuilder.fields.lastname')}" />
              </div>
              <div class="spm_form_field spm_form_field_base">
                  <label for="spm_form_registration_first_name">
                    <span class="${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}">
                      ${t('templateBuilder.fields.firstname')} :
                    </span>
                    <i class="required_asterisk">*</i>
                  </label>
                  <input type="text" id="spm_form_registration_first_name" name="spm_form_registration_first_name"
                    class="spm_form_input spm_form_required ${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}"
                    placeholder="${t('templateBuilder.fields.firstname')}" />
              </div>
              <div class="spm_form_field spm_form_field_base">
                  <label for="spm_form_registration_email">
                    <span class="${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}">
                      ${t('templateBuilder.fields.email')} :
                    </span>
                    <i class="required_asterisk">*</i>
                  </label>
                  <input type="text" id="spm_form_registration_email" name="spm_form_registration_email"
                    class="spm_form_input spm_form_required ${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}"
                    placeholder="${t('templateBuilder.fields.email')}" />
              </div>
              <div class="spm_form_field spm_form_field_base">
                  <label for="spm_form_registration_password">
                    <span class="${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}">
                      ${t('templateBuilder.fields.password')} :
                    </span>
                    <i class="required_asterisk">*</i>
                  </label>
                  <input type="password" id="spm_form_registration_password" name="spm_form_registration_password"
                    class="spm_form_input spm_form_required ${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}"
                    placeholder="${t('templateBuilder.fields.password')}" />
              </div>
              <div class="spm_form_field spm_hidden">
                  <label for="spm_form_registration_birthday">
                    <span class="${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}">
                      ${t('templateBuilder.fields.birthday')} :
                    </span>
                  </label>
                  <input type="text" id="spm_form_registration_birthday" name="spm_form_registration_birthday"
                    class="spm_form_input ${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}"
                    placeholder="${t('templateBuilder.fields.birthday')}" />
              </div>
              <div class="spm_form_field spm_form_checkbox spm_form_registration_field">
                  <input type="checkbox" id="spm_form_registration_newsletter" name="spm_form_registration_newsletter" class="spm_form_checkbox" />
                  <label for="spm_form_registration_newsletter" class="spm_force_inline ${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}">
                    ${t('templateBuilder.fields.registerNewsletter')}
                  </label>
              </div>
              <div class="spm_form_button">
                  <a href="javascript:void(0);" class="spm_inline_editing spm_button spm_div_align_center ${TEMPLATE_TRANSLATE_SPECIFIC_ONLY_CONTENT}"
                    onclick="spmWorkflow.submitForm(spmFindAncestor(this, 'form'), 'registration', {var=template.id_template}, {var=id_step}, {var=id_send});"
                    >${t('templateBuilder.fields.validate')}</a>
              </div>
              <div class="spm_form_other_buttons">
                  <label class="${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}">${t('templateBuilder.fields.registerBy')}</label>
                  <div class="spm_socials_buttons">
                      <div class="spm_social_icons spm_social_icons_horizontal">
                          <span class="spm_facebook spm_social_icon_container spm_social_icon">
                              <a href="javascript:void(0);"
                                onclick="spmWorkflow.formToUse = spmFindAncestor(this, 'form');
                                  window.open('https://proxima.shopimind.com/procedural_api/authApi.php?srv=fb');">
                                <img src="https://media.shopimind.io/img/icons/social/2/32/facebook.png" alt="Facebook" width="32" style="max-width:32px;" />
                              </a>
                          </span>
                      </div>
                  </div>
              </div>
              <div style="clear: both;"></div>
          </form>
      </div>
      <div class="spm_step2 spm_hidden"></div>
    </div>
  </div>`;
};

const rawWidgetNewsletterSubscription = (t: Function) => () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<div id="${widgetId}" class="spm_widget_display_form_newsletter spm_draggable_widget spm_widget ${TEMPLATE_TRANSLATE_SPECIFIC}" data-widgettype="spm_form_newsletter">
    <div class="spm_form spm_action_displayMessage">
      <div class="spm_step1">
        <form name="spm_form_newsletter" class="spm_form_newsletter spm_label_inside">
          <div class="spm_form_field spm_hidden">
            <label for="spm_form_registration_last_name"><span class="${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}">${t('templateBuilder.fields.lastname')}</span></label>
            <input type="text" id="spm_form_registration_last_name" name="spm_form_registration_last_name"
              class="spm_form_input ${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}"
              placeholder="${t('templateBuilder.fields.lastname')}" />
          </div>
          <div class="spm_form_field spm_hidden">
            <label for="spm_form_registration_first_name"><span class="${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}">${t('templateBuilder.fields.firstname')}</span></label>
            <input type="text" id="spm_form_registration_first_name" name="spm_form_registration_first_name"
              class="spm_form_input ${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}"
              placeholder="${t('templateBuilder.fields.firstname')}" />
          </div>
          <div class="spm_form_field spm_form_field_base spm_form_field_choosen">
            <label for="spm_form_registration_email">
              <span class="${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}">
                ${t('templateBuilder.fields.email')}
              </span>
              <i class="required_asterisk">*</i>
            </label>
            <input type="text" id="spm_form_registration_email" name="spm_form_registration_email"
              class="spm_form_input spm_form_required ${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}"
              placeholder="${t('templateBuilder.fields.email')}" />
          </div>
          <div class="spm_form_field spm_form_password spm_form_create_account_field spm_hidden">
            <label for="spm_form_registration_password">
              <span class="${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}">
                ${t('templateBuilder.fields.password')}
              </span>
              <i class="required_asterisk">*</i>
            </label>
            <input type="password" id="spm_form_registration_password" name="spm_form_registration_password"
              class="spm_form_input spm_form_required ${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}"
              placeholder="${t('templateBuilder.fields.password')}" />
          </div>
          <div class="spm_form_field spm_hidden">
            <label for="spm_form_registration_birthday"><span class="${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}">${t('templateBuilder.fields.birthday')}</span></label>
            <input type="text" id="spm_form_registration_birthday" name="spm_form_registration_birthday"
              class="spm_form_input ${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}"
              placeholder="${t('templateBuilder.fields.birthday')}" />
          </div>
          <div class="spm_form_button">
            <a href="javascript:void(0);" class="spm_inline_editing spm_button spm_div_align_center ${TEMPLATE_TRANSLATE_SPECIFIC_ONLY_CONTENT}"
              onclick="spmWorkflow.submitForm(spmFindAncestor(this, 'form'), 'newsletter', {var=template.id_template}, {var=id_step}, {var=id_send});"
              >${t('templateBuilder.fields.validate')}</a>
          </div>
          <div class="spm_form_field spm_form_checkbox spm_form_create_account_field">
            <input type="checkbox" id="spm_form_registration_create_account" name="spm_form_registration_create_account" class="spm_form_checkbox" />
            <label for="spm_form_registration_create_account" class="spm_force_inline ${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}">${t('templateBuilder.fields.createAccount')}</label>
          </div>
          <div class="spm_form_other_buttons">
            <label class="${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}">${t('templateBuilder.fields.registerBy')}</label>
            <div class="spm_socials_buttons">
              <div class="spm_social_icons spm_social_icons_horizontal">
                <span class="spm_facebook spm_social_icon_container spm_social_icon">
                  <a href="javascript:void(0);"
                    onclick="spmWorkflow.formToUse = spmFindAncestor(this, 'form'); window.open('https://proxima.shopimind.com/procedural_api/authApi.php?srv=fb');">
                    <img src="https://media.shopimind.io/img/icons/social/2/32/facebook.png" alt="Facebook" width="32" style="max-width:32px;" />
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div style="clear: both;"></div>
        </form>
      </div>
      <div class="spm_step2 spm_hidden">

      </div>
    </div>
  </div>`;
};

const rawWidgetNewsletterUnsubscription = (t: Function) => () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<div
    id="${widgetId}"
    class="spm_widget_display_form_newsletter_unsubscribe spm_draggable_widget spm_widget ${TEMPLATE_TRANSLATE_SPECIFIC}"
    data-widgettype="spm_form_registration_unsubscribe">
      <div class="spm_form spm_action_displayMessage">
        <div class="spm_step1">
          <form name="spm_form_registration_unsubscribe" class="spm_form_registration_unsubscribe spm_label_inside">
            <div class="spm_form_field spm_form_field_base">
              <label for="spm_form_registration_email">
                <span class="${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}">
                  ${t('templateBuilder.fields.email')}
                </span>
                <i class="required_asterisk">*</i>
              </label>
              <input type="text" id="spm_form_registration_email" name="spm_form_registration_email"
                class="spm_form_input spm_form_required ${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}"
                placeholder="${t('templateBuilder.fields.email')}" />
            </div>
            <div class="spm_form_button">
              <a href="javascript:void(0);" class="spm_inline_editing spm_button spm_div_align_center ${TEMPLATE_TRANSLATE_SPECIFIC_ONLY_CONTENT}"
              onclick="spmWorkflow.submitForm(spmFindAncestor(this, 'form'), 'newsletter_unsubscribe', {var=template.id_template}, {var=id_step}, {var=id_send});">
                ${t('templateBuilder.fields.validate')}
              </a>
            </div>
            <div style="clear: both;"></div>
          </form>
        </div>
        <div class="spm_step2 spm_hidden">

        </div>
      </div>
  </div>`;
};

const rawWidgetFacebookMessenger = (t: Function) => () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<div id="${widgetId}" class="spm_facebook_messenger spm_draggable_widget spm_widget ${TEMPLATE_TRANSLATE_SPECIFIC}" data-widgettype="spm_facebook_messenger">
    <img src="https://media.shopimind.io/img/facebook-checkbox-plugin/standard-light.png" id="fbMessengerCheckboxBuilder" />
    <div class="fb-messenger-checkbox" origin="{var=shop.root_url}" page_id="{var=shop.fb_pageid}"
      messenger_app_id="1951290961830126" user_ref="{var=visitor.fb_userref}" allow_login="true"
      size="standard" skin="light" center_align="false"
    ></div>
  </div>`;
};

export const rawColumnsLayout = (percentages: number[]) => {
  let html = '';

  percentages.forEach((percentage) => {
    const columnId = `${TEMPLATE_COLUMN_IDENTIFIER_PREFIX}_${generateUniqStructureId()}`;
    html += `<div class="spm_column spm_droppable_widget spm_droppable" style="width: calc(${percentage}% - 1.5px);" id="${columnId}">${rawWidgetPlaceholder()}</div>`;
  });

  return html;
};

export const rawLineWithColumns = (percentages: number[]) => {
  const lineId = `${TEMPLATE_LINE_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  return `<div id="${lineId}" class="spm_draggable_row spm_row"><div class="spm_columns">${rawColumnsLayout(percentages)}</div></div>`;
};

export const rawWidgets = (t: Function, additionalConfiguration: Record<string, any>): { [key: string]: () => string } => ({
  [WidgetTypeEnum.SEPARATOR]: rawWidgetSeparator,
  [WidgetTypeEnum.TEXT]: rawWidgetText,
  [WidgetTypeEnum.TITLE]: rawWidgetTitle,
  [WidgetTypeEnum.IMAGE]: rawWidgetImage,
  [WidgetTypeEnum.CODE]: rawWidgetCode,
  [WidgetTypeEnum.BUTTON]: rawWidgetButton,
  [WidgetTypeEnum.SOCIAL_MEDIA]: rawWidgetSocialMedia(additionalConfiguration),
  [WidgetTypeEnum.VOUCHER]: rawWidgetVoucher,
  [WidgetTypeEnum.SMART_PRODUCT_LIST]: rawWidgetSmartProductList(t),
  [WidgetTypeEnum.CREATE_ACCOUNT]: rawWidgetCreateAccount(t),
  [WidgetTypeEnum.NEWSLETTER_SUBSCRIPTION]: rawWidgetNewsletterSubscription(t),
  [WidgetTypeEnum.NEWSLETTER_UNSUBSCRIPTION]: rawWidgetNewsletterUnsubscription(t),
  [WidgetTypeEnum.FACEBOOK_MESSENGER]: rawWidgetFacebookMessenger(t),
  [WidgetTypeEnum.COLUMN]: () => '', // Empty string. Only used to display the widget in the panel. Code of widget is returned by getColumnsWidgetLayouts
});
