
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ActiveStatusIcon',
  props: {
    active: {
      type: [Boolean, String, Number],
      default: false,
    },
  },

  setup(props: { active: boolean| string | number}) {
    let status = false;
    try {
      status = props.active !== undefined && props.active ? Boolean(JSON.parse(props.active.toString())).valueOf() : false;
    } catch (error) { /* not empty, right? */ }

    return {
      status,
    };
  },
});
