<template>
  <Loader
    v-if="isLoading"
    style="z-index:1102"
  />
  <div class="flex align-items-center">
    <label>{{ t('storeParameters.socialNetworks.activated') }}</label>
    <div class="ml-5">
      <ToggleButton
        v-for="socialNetwork of socialNetworks"
        :key="socialNetwork"
        :model-value="isSocialNetworkActivated(socialNetwork)"
        on-label=""
        off-label=""
        :on-icon="`icon ${socialNetwork}-icon`"
        :off-icon="`icon ${socialNetwork}-icon icon-off`"
        @change="updateActivatedSocialNetworks(socialNetwork)"
      />
    </div>
  </div>
  <div
    v-if="socialNetworks"
    class="p-fluid mt-5"
  >
    <div
      v-for="socialNetwork of socialNetworks"
      :key="socialNetwork"
    >
      <div
        v-if="isSocialNetworkActivated(socialNetwork)"
        class="field"
      >
        <label>{{ t(`storeParameters.socialNetworks.${socialNetwork}`) }}</label>
        <LocalizedTextInput
          :id="`shopRequiredDataSocialNetworks_${socialNetwork}`"
          v-model="field[`shopRequiredDataSocialNetworks_${socialNetwork}`]"
        />
        <FieldErrors
          :key="componentFieldErrorsKey"
          :errors="error"
          :field="`shopRequiredDataSocialNetworks_${socialNetwork}`"
        />
      </div>
    </div>
  </div>
  <div class="flex align-content-center justify-content-center align-items-center mt-5">
    <Button
      v-if="!globalSave"
      :label="t('save')"
      class="p-button p-button-success"
      type="submit"
      @click="handleSave"
    />
  </div>
  <div
    v-if="globalSave"
    class="flex align-content-center justify-content-center align-items-center mt-5"
  >
    <slot name="save" />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  defineExpose,
  reactive,
  ref,
  Ref,
  onMounted,
} from 'vue';

import ToggleButton from 'primevue/togglebutton';
import Button from 'primevue/button';
import Loader from '@/components/layout/Loader.vue';

import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';

import { saveShopParamsOnRedis, SaveShopsConfiguration } from '@/composables/shop/ShopsConfiguration';
import useVuelidate from '@vuelidate/core';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { ShopsConfigurationInput, ShopsConfigurationInputItem } from '@/types/generated-types/graphql';
import { showToastError, showToastSuccess } from '@/helpers';
import { localizedTextInputValidator } from '@/helpers/CustomValidator';

import { serialize, unserialize } from 'php-serialize';

import { useI18n } from 'vue-i18n';

interface SocialNetworkField {
  shopRequiredDataSocialNetworks_contactPage: object;
  shopRequiredDataSocialNetworks_facebook: object;
  shopRequiredDataSocialNetworks_twitter: object;
  shopRequiredDataSocialNetworks_pinterest: object;
  shopRequiredDataSocialNetworks_tumblr: object;
  shopRequiredDataSocialNetworks_instagram: object;
  shopRequiredDataSocialNetworks_youtube: object;
  shopRequiredDataSocialNetworks_whatsapp: object;
  shopRequiredDataSocialNetworks_snapchat: object;
  shopRequiredDataSocialNetworks_socialNetwork: any;
}

export default defineComponent({
  name: 'SocialNetworkServiceParameter',

  components: {
    ToggleButton,
    LocalizedTextInput,
    Button,
    FieldErrors,
    Loader,
  },

  props: {
    retrieveData: {
      type: Function,
      required: true,
    },

    shopId: {
      type: Number,
      required: true,
    },

    globalSave: {
      type: Boolean,
      required: false,
      default: false,
    },

    name: {
      type: String,
      required: false,
      default: '',
    },
  },

  setup(props) {
    const { t } = useI18n();

    const isLoading = ref(false);

    const componentFieldErrorsKey = ref(0);
    const error = ref();

    const activeSocialNetworks: Ref<Array<string>> = ref([]);

    const socialNetworks = [
      'contactPage',
      'facebook',
      'twitter',
      'pinterest',
      'tumblr',
      'instagram',
      'youtube',
      'whatsapp',
      'snapchat',
    ];

    const field = reactive<SocialNetworkField>({
      shopRequiredDataSocialNetworks_contactPage: {},
      shopRequiredDataSocialNetworks_facebook: {},
      shopRequiredDataSocialNetworks_twitter: {},
      shopRequiredDataSocialNetworks_pinterest: {},
      shopRequiredDataSocialNetworks_tumblr: {},
      shopRequiredDataSocialNetworks_instagram: {},
      shopRequiredDataSocialNetworks_youtube: {},
      shopRequiredDataSocialNetworks_whatsapp: {},
      shopRequiredDataSocialNetworks_snapchat: {},
      shopRequiredDataSocialNetworks_socialNetwork: [],
    });

    const validate = async (): Promise<ErrorConfigForm> => {
      const rules = {
        shopRequiredDataSocialNetworks_contactPage: {
          localizedTextInputValidator: localizedTextInputValidator('url', activeSocialNetworks.value?.includes('contactPage')),
        },

        shopRequiredDataSocialNetworks_facebook: {
          localizedTextInputValidator: localizedTextInputValidator('url', activeSocialNetworks.value?.includes('facebook')),
        },

        shopRequiredDataSocialNetworks_twitter: {
          localizedTextInputValidator: localizedTextInputValidator('url', activeSocialNetworks.value?.includes('twitter')),
        },

        shopRequiredDataSocialNetworks_pinterest: {
          localizedTextInputValidator: localizedTextInputValidator('url', activeSocialNetworks.value?.includes('pinterest')),
        },

        shopRequiredDataSocialNetworks_tumblr: {
          localizedTextInputValidator: localizedTextInputValidator('url', activeSocialNetworks.value?.includes('tumblr')),
        },

        shopRequiredDataSocialNetworks_instagram: {
          localizedTextInputValidator: localizedTextInputValidator('url', activeSocialNetworks.value?.includes('instagram')),
        },

        shopRequiredDataSocialNetworks_youtube: {
          localizedTextInputValidator: localizedTextInputValidator('url', activeSocialNetworks.value?.includes('youtube')),
        },

        shopRequiredDataSocialNetworks_whatsapp: {
          localizedTextInputValidator: localizedTextInputValidator('url', activeSocialNetworks.value?.includes('whatsapp')),
        },

        shopRequiredDataSocialNetworks_snapchat: {
          localizedTextInputValidator: localizedTextInputValidator('url', activeSocialNetworks.value?.includes('snapchat')),
        },
      };

      const v$ = useVuelidate(rules, field);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    // eslint-disable-next-line consistent-return
    const handleSave = async () => {
      const validation = await validate();
      if (!validation.success) {
        error.value = validation.validate;
        componentFieldErrorsKey.value += 1;
        return {
          err: validation.validate.value.$errors.length,
          configs: [],
        };
      }

      // unpack changed values and update
      const shopsConfigArray: ShopsConfigurationInputItem[] = [];
      let key = '';
      let value = '';
      Object.entries(field).forEach((keyValuePair: any) => {
        [key, value] = [...keyValuePair];

        if (typeof value === 'object' && !Array.isArray(value)) {
          let language = '';
          let inputValue = '';
          Object.entries(value).forEach((entry: any) => {
            [language, inputValue] = [...entry];

            const multiRecord: ShopsConfigurationInputItem = {
              key,
              value: inputValue,
              lang: language,
            };
            shopsConfigArray.push(multiRecord);
          });
        } else {
          const shopsConfigRecord: ShopsConfigurationInputItem = {
            key,
            value: serialize(value),
            lang: '',
          };
          shopsConfigArray.push(shopsConfigRecord);
        }
      });

      if (props.globalSave) {
        return {
          err: null,
          configs: shopsConfigArray,
        };
      }

      const input: ShopsConfigurationInput = {
        id_shop: props.shopId,
        configs: [],
      };
      input.id_shop = props.shopId;
      input.configs = shopsConfigArray;

      try {
        await SaveShopsConfiguration(input);
        await saveShopParamsOnRedis(props.shopId);
        if (!props.globalSave) {
          showToastSuccess('savedSuccessful');
        }
      } catch (err) {
        if (!props.globalSave) {
          showToastError('GENERIC_ERROR');
        } else {
          throw err;
        }
      }
    };

    const isSocialNetworkActivated = (socialNetwork: string) => {
      activeSocialNetworks.value = field.shopRequiredDataSocialNetworks_socialNetwork as Array<string>;
      return activeSocialNetworks.value.includes(socialNetwork);
    };

    const updateActivatedSocialNetworks = (socialNetwork: string) => {
      activeSocialNetworks.value = field.shopRequiredDataSocialNetworks_socialNetwork as Array<string>;
      const index = activeSocialNetworks.value.indexOf(socialNetwork, 0);
      if (index > -1) {
        activeSocialNetworks.value.splice(index, 1);
      } else {
        activeSocialNetworks.value.push(socialNetwork);
      }

      field.shopRequiredDataSocialNetworks_socialNetwork = activeSocialNetworks.value;
    };

    defineExpose({
      handleSave,
    });

    onMounted(async () => {
      try {
        isLoading.value = true;
        await props.retrieveData(field);
        let socialNetworkArray = unserialize(field.shopRequiredDataSocialNetworks_socialNetwork);
        socialNetworkArray = socialNetworkArray.filter((network: string) => network !== 'googleplus');
        field.shopRequiredDataSocialNetworks_socialNetwork = socialNetworkArray;
      } catch (err) {
      } finally {
        isLoading.value = false;
      }
    });

    return {
      t,
      socialNetworks,
      field,
      error,
      componentFieldErrorsKey,
      isLoading,

      isSocialNetworkActivated,
      updateActivatedSocialNetworks,
      handleSave,
    };
  },
});
</script>

<style scoped lang="scss">
:deep() .p-togglebutton {
  width: 64px !important;
  height: 64px !important;
  border: none !important;
  background-color: $white !important;
  &:focus {
    box-shadow: 0 0 0 0 !important;
  }
  > .icon {
    width: 40px;
    height: 40px;
    background-size: 100%;
    &.contactPage-icon {
      background-image: url("~@/assets/images/icons/social-networks/contactPage.png");
    }
    &.facebook-icon {
      background-image: url("~@/assets/images/icons/social-networks/facebook.png");
    }
    &.instagram-icon {
      background-image: url("~@/assets/images/icons/social-networks/instagram.png");
    }
    &.pinterest-icon {
      background-image: url("~@/assets/images/icons/social-networks/pinterest.png");
    }
    &.snapchat-icon {
      background-image: url("~@/assets/images/icons/social-networks/snapchat.png");
    }
    &.tumblr-icon {
      background-image: url("~@/assets/images/icons/social-networks/tumblr.png");
    }
    &.twitter-icon {
      background-image: url("~@/assets/images/icons/social-networks/twitter.png");
    }
    &.whatsapp-icon {
      background-image: url("~@/assets/images/icons/social-networks/whatsapp.png");
    }
    &.youtube-icon {
      background-image: url("~@/assets/images/icons/social-networks/youtube.png");
    }
    &.icon-off {
      opacity: 0.25;
    }
  }
}
</style>
