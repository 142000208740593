
import { useI18n } from 'vue-i18n';
import { defineComponent, ref, watch } from 'vue';
import Carousel from 'primevue/carousel';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'LayoutAuthentication',

  components: {
    Carousel,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const carouselData = {
      registration: [
        {
          title: 'Nous avons été séduits par sa facilité d\'utilisation et surtout par l\'accompagnement des équipes ShopiMind',
          image: '/images/logos-testimonials/archiduchesse.png',
          author: 'Pauline Calvo',
          position: 'Cheffe de projet E-commerce',
        },
        {
          title: 'Après un benchmark Shopimind semblait être la solution qui permettait de répondre à une grande partie de nos besoins',
          image: '/images/logos-testimonials/speedway.svg',
          author: 'Rodolphe Ricard',
          position: 'Responsable Acquisition',
        },
        {
          title: 'Une solution performante que nous utilisons depuis plusieurs années. L\'équipe est réactive et très sympathique (ce qui est très appréciable)',
          image: '/images/logos-testimonials/generationsouvenir.png',
          author: 'Sébastien Surget',
          position: 'Directeur',
        },
        {
          title: 'Très bon rapport qualité prix pour ce logiciel d\'abandon de panier omnicanal. Je recommande',
          image: '/images/logos-testimonials/beelong.svg',
          author: 'Benoit Delporte',
          position: 'Co-Fondateur',
        },
      ],

      login: [
        {
          title: t('authentication.slides.newCampaignEditor'),
          image: 'https://media.shopimind.io/img/v4/campagnes-multicanales.jpg',
          // eslint-disable-next-line max-len
          desc: t('authentication.slides.newCampaignEditorText'),
        },
        {
          title: t('authentication.slides.multiUsers'),
          image: 'https://media.shopimind.io/img/v4/multi-user-V2.jpg',
          // eslint-disable-next-line max-len
          desc: t('authentication.slides.multiUsersText'),
        },
        {
          title: t('authentication.slides.contactWithShopiteam'),
          image: 'https://media.shopimind.io/img/v4/aide-utilisateur.jpg',
          // eslint-disable-next-line max-len
          desc: t('authentication.slides.contactWithShopiteamText'),
        },
      ],
    };

    const carousel = ref();

    watch(() => router.currentRoute.value.name, (route) => {
      if (route === 'user.registration-speed') {
        carousel.value = carouselData.registration;
      } else {
        carousel.value = carouselData.login;
      }
    }, { immediate: true });

    return {
      t,
      carousel,
    };
  },
});
