
import {
  defineComponent,
  PropType,
  computed,
} from 'vue';

import {
  TabItem,
  TabSubitem,
  ParserObject,
  FieldsGroup,
} from '@/types';
import StructureEdit from '@/components/template-builder/fields/StructureEdit.vue';
import ButtonGroup from '@/components/template-builder/fields/ButtonGroup.vue';
import DraggableButtonGroup from '@/components/template-builder/fields/DraggableButtonGroup.vue';
import Stepper from '@/components/template-builder/fields/Stepper.vue';
import StepperPxPercentSlider from '@/components/template-builder/fields/StepperPxPercentSlider.vue';
import Color from '@/components/template-builder/fields/Color.vue';
import Spaces from '@/components/template-builder/fields/Spaces.vue';
import FontFamily from '@/components/template-builder/fields/FontFamily.vue';
import Borders from '@/components/template-builder/fields/Borders.vue';
import InputVariables from '@/components/template-builder/fields/InputVariables.vue';
import Code from '@/components/template-builder/fields/Code.vue';
import WysiwygEditor from '@/components/template-builder/fields/WysiwygEditor.vue';
import Link from '@/components/template-builder/fields/Link.vue';
import Dropdown from '@/components/template-builder/fields/Dropdown.vue';
import BoxShadow from '@/components/template-builder/fields/BoxShadow.vue';
import Information from '@/components/template-builder/fields/Information.vue';
import InputGroup from '@/components/template-builder/fields/InputGroup.vue';
import SharingType from '@/components/template-builder/fields/SharingType.vue';
import TextareaVariables from '@/components/template-builder/fields/TextareaVariables.vue';
import ItemList from '@/components/template-builder/fields/ItemList.vue';
import TriggerButton from '@/components/template-builder/fields/TriggerButton.vue';
import MultiDropdown from '@/components/template-builder/fields/MultiDropdown.vue';
import SelectionType from '@/components/template-builder/fields/SelectionType.vue';
import ActionType from '@/components/template-builder/fields/ActionType.vue';
import Image from '@/components/template-builder/fields/Image.vue';
import FacebookButtons from '@/components/template-builder/fields/FacebookButtons.vue';
import LineHeight from '@/components/template-builder/fields/LineHeight.vue';
import Font from '@/components/template-builder/fields/Font.vue';
import SocialIconsStyle from '@/components/template-builder/fields/SocialIconsStyle.vue';
import BackgroundImage from '@/components/template-builder/fields/BackgroundImage.vue';
import CustomHtml from '@/components/template-builder/fields/CustomHtml.vue';

import { handlePropertyValuesChange } from '@/composables/template-editor/TemplateEditor';

export default defineComponent({
  name: 'ConfigurationPanelTabContentFieldGroup',

  components: {
    StructureEdit,
    ButtonGroup,
    DraggableButtonGroup,
    Stepper,
    StepperPxPercentSlider,
    Color,
    Font,
    Spaces,
    FontFamily,
    LineHeight,
    Borders,
    InputVariables,
    TextareaVariables,
    Code,
    WysiwygEditor,
    Link,
    Dropdown,
    BoxShadow,
    Information,
    InputGroup,
    Image,
    SharingType,
    ItemList,
    TriggerButton,
    MultiDropdown,
    SelectionType,
    ActionType,
    FacebookButtons,
    SocialIconsStyle,
    BackgroundImage,
    CustomHtml,
  },

  props: {
    fieldGroup: {
      type: Object as PropType<FieldsGroup>,
      required: true,
    },

    tabItem: {
      type: Object as PropType<TabItem | TabSubitem>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserObject>,
      required: true,
    },

    customClass: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const fieldGroupItems = computed(() => {
      if (props.fieldGroup.items) {
        return props.fieldGroup.items.filter((item) => {
          if (item.isVisible && item.parentFieldId) {
            return item.isVisible(props.fieldGroup.items, item.parentFieldId);
          }
          return true;
        });
      }
      return [];
    });

    return {
      fieldGroupItems,
      handlePropertyValuesChange,
    };
  },
});
