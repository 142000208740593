import {
  CustomMutation, Request,
  UpdateMultiple,
} from '@/composables/GraphQL';
import {
  MarketingBaseWorkflows,
  MarketingWorkflowsUpdateInputItem,
  ShopMarketingWorkflowsDuplicateInputItem,
  ShopMarketingWorkflowsSaveInputItem,
} from '@/types/generated-types/graphql';

export async function updateMultipleMarketingWorkflows(workflowInput: MarketingWorkflowsUpdateInputItem[]) {
  const {
    id, messages, err,
  } = await UpdateMultiple<MarketingWorkflowsUpdateInputItem>({
    name: 'MarketingWorkflows',
    input: workflowInput,
    type: 'MarketingWorkflowsUpdateInput',
  });

  if (err === '') {
    return id;
  }
  return messages;
}

export async function saveWorkflow(workflowSaveInput: ShopMarketingWorkflowsSaveInputItem) {
  const {
    id, messages, err,
  } = await CustomMutation<ShopMarketingWorkflowsSaveInputItem>({
    name: 'SaveShopMarketingWorkflows',
    input: workflowSaveInput,
    type: 'ShopMarketingWorkflowsSaveInput',
  });

  if (err === '') {
    return {
      success: true,
      id,
    };
  }

  return {
    success: false,
    messages,
  };
}

export async function duplicateWorkflow(workflowToDuplicateInput: ShopMarketingWorkflowsDuplicateInputItem) {
  const {
    id, messages, err,
  } = await CustomMutation<ShopMarketingWorkflowsDuplicateInputItem>({
    name: 'DuplicateShopMarketingWorkflows',
    input: workflowToDuplicateInput,
    type: 'ShopMarketingWorkflowsDuplicateInput',
  });

  if (err === '') {
    return {
      success: true,
      id,
    };
  }

  return {
    success: false,
    messages,
  };
}

export async function fetchBaseWorkflows() {
  const query = 'query'
    + '{ GetBaseWorkflows {'
    + 'id_marketing_workflow, id_shop, name_marketing_workflow, legend_marketing_workflow, categories, images_templates, data_marketing_workflow}}';
  const variables = {};

  const { data, err } = await Request<MarketingBaseWorkflows[]>({
    name: 'GetBaseWorkflows',
    query,
    variables,
  });

  if (err || data === null || data.length === 0) {
    return [];
  }

  return data;
}
