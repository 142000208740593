import { ComponentNameEnum, BaseButtonFieldConfig, AlignValueEnum } from '@/types';

const defaultValue = 'center';
const property = 'spm_div_align_';

const divAlignmentFieldConfig: BaseButtonFieldConfig = {
  componentName: ComponentNameEnum.BUTTON_GROUP,
  options: {
    [AlignValueEnum.LEFT]: {
      title: 'templateBuilder.fields.leftAlign',
      iconClass: 'fa-align-left',
      property,
      defaultValue,
    },
    [AlignValueEnum.CENTER]: {
      title: 'templateBuilder.fields.centerAlign',
      iconClass: 'fa-align-center',
      property,
      defaultValue,
    },
    [AlignValueEnum.RIGHT]: {
      title: 'templateBuilder.fields.rightAlign',
      iconClass: 'fa-align-right',
      property,
      defaultValue,
    },
  },
};

export default divAlignmentFieldConfig;
