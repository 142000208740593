<template>
  <header
    class="header justify-content-between flex-wrap card-container"
    :style="{ 'background-color': tenantAttributes?.brandColors?.secondary }"
  >
    <div class="flex align-items-center justify-content-center m-2">
      <Button
        v-if="!isMobile"
        :icon="buttonIcon"
        class="p-button-rounded p-button-text p-button-plain mr-2"
        @click="toggleSidebar"
      />
      <MySitesMenu />
      <Dropdown
        v-if="fastGoProductItems.length > 0"
        v-model="fastGoProductSelected"
        :options="fastGoProductItems"
        option-label="label"
        option-group-label="label"
        option-group-children="items"
        placeholder="Accès rapide"
        class="w-full md:w-14rem ml-2"
      />
    </div>
    <div class="flex align-items-center justify-content-center m-2 mr-4">
      <div class="mr-2">
        <MyNotificationsMenu v-if="findPermission('notifications.access')" />
      </div>
      <div class="mr-3">
        <MyStoreMenu />
      </div>
      <div class="mr-2">
        <MyProfileMenu />
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import {
  inject,
  defineComponent,
  computed, ComputedRef, watch, ref,
} from 'vue';
import BadgeDirective from 'primevue/badgedirective';
import { TenantAttributes, TypeCampaignEnum, DataExplorerDataType } from '@/types';
import { useStore } from '@/store';
import MySitesMenu from '@/components/header/MySitesMenu.vue';
import MyNotificationsMenu from '@/components/header/MyNotificationsMenu.vue';
import MyProfileMenu from '@/components/header/MyProfileMenu.vue';
import MyStoreMenu from '@/components/header/MyStoreMenu.vue';
import Button from 'primevue/button';
import { setLocalStorageElement } from '@/helpers/LocalStorage';
import { findPermission } from '@/composables/User';
import { activateCampaign, AutomatedScenarioState } from '@/composables/AutomatedScenarios';
import Dropdown from 'primevue/dropdown';
import { activate as activateTemplate, TemplateEditorState } from '@/composables/template-editor/TemplateEditor';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Header',

  directives: {
    badge: BadgeDirective,
  },

  components: {
    Button,
    MyStoreMenu,
    MyProfileMenu,
    MyNotificationsMenu,
    MySitesMenu,
    Dropdown,
  },

  props: {
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup() {
    const { t } = useI18n();
    const tenantAttributes: TenantAttributes | undefined = inject('tenantAttributes');
    const store = useStore();
    const isSidebarExpanded = computed(() => store.state.general.isSidebarExpanded);
    const buttonIcon: ComputedRef<string> = computed(() => (isSidebarExpanded.value ? 'fa-light fa-bars-staggered' : 'fa-light fa-bars'));

    const openedDatas = computed(() => store.getters['dataExplorer/getOpenedDatas']);

    const fastGoProductSelected: any = ref(null);
    const fastGoProductItems = computed(() => {
      const products = [
        {
          label: t('header.automationCampaigns'),
          code: 'AC',
          items: AutomatedScenarioState.automationCampaigns.map((campaign) => ({
            label: campaign.scenario.settingsData.name,
            id: campaign.scenario.id,
            type: TypeCampaignEnum.AUTOMATION,
          })),
        },
        {
          label: t('header.bulkCampaigns'),
          code: 'BC',
          items: AutomatedScenarioState.bulkCampaigns.map((campaign) => ({
            label: campaign.scenario.settingsData.name,
            id: campaign.scenario.id,
            type: TypeCampaignEnum.BULK,
          })),
        },
        {
          label: t('header.templates'),
          code: 'TL',
          items: TemplateEditorState.templates.map((template) => ({
            label: template.informations.name,
            id: template.id,
            type: 'template',
          })),
        },
        {
          label: t('header.dataSheets'),
          code: 'DE',
          items: openedDatas.value.map((openedData: any) => ({
            label: t(`dataExplorer.tabs.${openedData.dataType}`, [openedData.tabLabel]),
            id: openedData.dataId,
            type: openedData.dataType,
          })),
        }
      ];
      return products.filter((product) => product.items.length > 0);
    });

    watch(fastGoProductSelected, async () => {
      if (fastGoProductSelected.value && fastGoProductSelected.value?.id && fastGoProductSelected.value?.type) {
        if ([TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK].includes(fastGoProductSelected.value?.type)) {
          await activateCampaign(fastGoProductSelected.value?.id, fastGoProductSelected.value?.type);
        }

        if (fastGoProductSelected.value?.type === 'template') {
          await activateTemplate(fastGoProductSelected.value?.id, '', '');
        }

        if (
          [
            DataExplorerDataType.ADDRESS_FILE,
            DataExplorerDataType.CART_FILE,
            DataExplorerDataType.CONTACT_FILE,
            DataExplorerDataType.ORDER_FILE,
          ].includes(fastGoProductSelected.value?.type)
        ) {
          store.dispatch('dataExplorer/switchData', { dataId: fastGoProductSelected.value?.id });
        }


        fastGoProductSelected.value = null;
      }
    });

    const toggleSidebar = () => {
      setLocalStorageElement('spm_is_sidebar_expanded', Number(!isSidebarExpanded.value));
      store.commit('general/setIsSidebarExpanded', !isSidebarExpanded.value);
    };

    return {
      toggleSidebar,
      tenantAttributes,
      buttonIcon,
      findPermission,
      fastGoProductSelected,
      fastGoProductItems,
    };
  },
});
</script>

<style scoped lang="scss">
.header {
  background-color: #fff;
  border-bottom: 1px solid #e9ecef;
  height: 60px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  top: 0;

  @media screen and (max-width: $sm-max) {
    position: relative;
    z-index: 1;
  }
}
</style>

<style lang="scss">
.header {
  .p-button {
    &:focus {
      border: none !important;
      box-shadow: unset !important;;
    }
  }
}
</style>
