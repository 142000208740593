import {
  TemplateStructureEnum,
  ConfigurationPanelStructure,
  ConfigurationPanelStructureItemEnum,
  TabItem,
  BackgroundImageOptionTypeEnum,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import {
  setBackgroundImage,
  setBackgroundImageProperty,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import {
  getBackgroundImageValue,
  getCssPropertyByName,
} from '@/components/template-builder/getters';
import getBackgroundImageFieldConfig from '@/components/template-builder/config/fields/background-image-config';
// eslint-disable-next-line import/no-cycle

export const rowTabItemConfig: TabItem = {
  type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
  tabItemType: TemplateStructureEnum.LINE,
  label: 'templateBuilder.configs.line',
  tabPanel: {
    label: 'templateBuilder.configs.lineEdit',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.line',
        selector: 'body #spm_body #{LINE_ID}',
        items: [
          {
            label: 'templateBuilder.fields.backgroundImage',
            selector: 'body #spm_body #{LINE_ID}',
            properties: [
              { name: 'background-image', getter: getBackgroundImageValue, setters: [setBackgroundImage] },
              { name: 'background-size', getter: getCssPropertyByName, setters: [setBackgroundImageProperty] },
              { name: 'background-position-x', getter: getCssPropertyByName, setters: [setBackgroundImageProperty] },
              { name: 'background-position-y', getter: getCssPropertyByName, setters: [setBackgroundImageProperty] },
              { name: 'background-repeat', getter: getCssPropertyByName, setters: [setBackgroundImageProperty] },
            ],
            ...getBackgroundImageFieldConfig(BackgroundImageOptionTypeEnum.FULL),
          },
        ],
      },
    ],
  },
};

const rowItemConfig: ConfigurationPanelStructure = {
  items: [
    rowTabItemConfig,
  ],
};

export default rowItemConfig;
