<template>
  <div class="spaces-field-block">
    <div class="flex justify-content-between flex-wrap">
      <div class="flex justify-content-center">
        <label class="block my-3">
          {{ t(configs.label) }}
        </label>
      </div>
      <div class="flex justify-content-center align-items-center">
        <ToggleDisplaySettings
          v-if="!Object.prototype.hasOwnProperty.call(configs, 'displayToggle') || configs.displayToggle"
          :configs="configs"
          :parser-values="parserValues"
          @display-settings="(value) => displayProperties = value"
        />
      </div>
    </div>
    <div
      class="grid justify-content-between align-items-end"
      :class="{ 'hidden': !displayProperties }"
    >
      <template
        v-for="(item, index) in inputList"
        :key="item.property"
      >
        <div
          class="text-center"
          :class="index % 2 ===0 ? 'col-5 pr-0' : 'col-5 pl-0'"
        >
          <label class="block mb-2 input-label">
            {{ item.title }}
          </label>
          <BaseInputNumber
            v-model="item.value"
            data-test-id="input-element"
            :suffix="SIGN"
            @input="handleInput(item, index)"
          />
        </div>
        <div
          v-if="index % 2 ===0 && inputList.length > 1"
          class="col-2 text-center spaces-link"
        >
          <Button
            data-test-id="lock-button"
            class="lock-button"
            :class="{ 'selected-lock-button': !isLocked[index] }"
            @click="changeLockedState(index)"
          >
            <i
              class="fas"
              :class="isLocked[index] ? 'fa-lock' : 'fa-unlock'"
            />
          </Button>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, PropType, ref, Ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Button from 'primevue/button';

import BaseInputNumber from '@/components/fields/partials/BaseInputNumber.vue';
import {
  BooleanMap, FieldConfig, ParserFieldObject, Property, SpacesFieldOption,
} from '@/types';
import ToggleDisplaySettings from '@/components/template-builder/fields/partials/ToggleDisplaySettings.vue';

export default defineComponent({
  name: 'Spaces',
  components: {
    ToggleDisplaySettings,
    Button,
    BaseInputNumber,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const configs = ref(props.configs);
    const displayProperties = ref(!(!Object.prototype.hasOwnProperty.call(configs.value, 'displayToggle') || configs.value.displayToggle === true));
    const SIGN = 'px';
    const isLocked: Ref<BooleanMap> = ref({});
    const baseProperty: Ref<string> = ref(props.configs.options.property);
    const properties: Ref<Array<Property>> = ref(props.parserValues.properties);

    const buildInputList = (): SpacesFieldOption[] => Object.entries(props.configs.options).filter((data) => data[0] !== 'property')
      .map((data, index: number) => {
        const currentPropertyName = baseProperty.value === 'border-radius' ? `border-${data[0]}-radius` : `${baseProperty.value}-${data[0]}`;
        const parserValue = props.parserValues.properties.filter((property) => property.name === currentPropertyName)[0].value?.replace(new RegExp(SIGN, 'g'), '');
        const { defaultValue, title } = data[1];
        const value = !Number.isNaN(Number(parserValue)) ? Number(parserValue) : Number(defaultValue);
        if (index % 2 === 0 && Object.entries(props.configs.options).length > 1) isLocked.value[index] = true;
        return {
          title: t(title),
          property: currentPropertyName,
          defaultValue,
          value,
        };
      });
    const inputList: Ref<SpacesFieldOption[]> = ref(buildInputList());

    const handleInput = (input: SpacesFieldOption, index: number) => {
      const { value, property, defaultValue } = input;
      const newValue: number = value === null ? Number(defaultValue) : value;
      if (isLocked.value[index] || isLocked.value[index - 1]) {
        const startedIndex = isLocked.value[index] ? index : index - 1;
        inputList.value[startedIndex].value = newValue;
        inputList.value[startedIndex + 1].value = newValue;

        const updatedProperties = properties.value
          .filter((prop: Property) => (prop.name === inputList.value[startedIndex].property || prop.name === inputList.value[startedIndex + 1].property))
          .map((prop: Property) => {
            const updatedProperty = prop;
            updatedProperty.value = `${newValue}${SIGN}`;
            return updatedProperty;
          });
        context.emit('on-change-properties', {
          selector: props.parserValues.selector,
          properties: updatedProperties,
        });
      } else {
        const updatedProperty: Property = properties.value.filter((prop: Property) => prop.name === property)[0];
        updatedProperty.value = `${newValue}${SIGN}`;
        context.emit('on-change-properties', {
          selector: props.parserValues.selector,
          properties: [updatedProperty],
        });
      }
    };

    const changeLockedState = (index: number) => {
      isLocked.value[index] = !isLocked.value[index];

      if (isLocked.value[index]) {
        // Déterminer l'index de l'élément pair à verrouiller/déverrouiller
        const relatedIndex = index % 2 === 0 ? index : index - 1;
        const item = inputList.value[relatedIndex];
        // Simuler le changement d'entrée
        handleInput(item, relatedIndex);
      }
    };

    return {
      t,
      isLocked,
      inputList,
      SIGN,
      displayProperties,
      changeLockedState,
      handleInput,
    };
  },
});
</script>

<style lang="scss" scoped>
.spaces-field-block {
  .input-label {
    font-size: 0.875rem;
  }

  .p-inputnumber {
    :deep() {
      .stepper-input {
        width: 100%;
        max-width: 4rem;
      }
    }
  }

  .spaces-link {
    margin-bottom: 8px;

    .lock-button {
      padding: 0.20rem 0.25rem;
      box-shadow: none !important;
      background-color: $brand-color-primary;
      border-color: $brand-color-primary;
    }

    .selected-lock-button {
      background-color: $white !important;
      border-color: $heather !important;
      box-shadow: none !important;

      .fas {
        color: $heather !important;
      }
    }
  }

  .p-button:enabled {
    .fas {
      color: white;
    }
  }
}
</style>
