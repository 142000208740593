
import { useI18n } from 'vue-i18n';
import { store } from '@/store';

import {
  StructureConfig,
  StructureEditDirectionEnum,
} from '@/types';
import {
  computed,
  defineComponent,
  PropType,
  Ref,
  ref,
  watch,
} from 'vue';
import draggable from 'vuedraggable';

import { onSelectStructure, onStructureOrderChange } from '@/components/template-builder/utils/structure-edit-handlers';

export default defineComponent({
  name: 'StructureEdit',

  components: {
    draggable,
  },

  props: {
    structureConfig: {
      type: Object as PropType<StructureConfig>,
      required: true,
    },

    childrenCount: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const direction = StructureEditDirectionEnum;

    const itemsCount: Ref<number> = ref(props.childrenCount);
    const activeItemSelector = computed(() => store.getters['liveEditor/getActiveItemSelector']);
    const itemsList = computed(() => Array.from(Array(itemsCount.value).keys()));
    const config = computed(() => props.structureConfig);

    watch(() => props.childrenCount, (value: number) => {
      itemsCount.value = value;
    }, { immediate: true });

    const handleDragend = (event: any) => {
      onStructureOrderChange(activeItemSelector.value, config.value.type, event.oldIndex, event.newIndex);
    };

    const handleClick = (element: number) => {
      onSelectStructure(activeItemSelector.value, config.value.type, element);
    };

    return {
      handleDragend,
      handleClick,
      direction,
      config,
      itemsList,
      t,
    };
  },
});
