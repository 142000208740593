<template>
  <div
    v-if="element.type === 'placeholder'"
    class="placeholder-on-add-widget placeholder-on-add-widget--allowed m-4"
  />
  <div
    v-else
    :data-widget="JSON.stringify(element)"
    :data-location="JSON.stringify({ rowId: rowParentId, columnId: columnParentId, panelData: {
      panelId: panelId,
      subRowId: rowId,
      subColumnId: columnData.id,
      widgetIndex: index
    } })"
  >
    <div
      v-show="isChoosedForDrag"
    />
    <div
      v-show="!isChoosedForDrag"
      class="relative sb-subcol-item"
      :class="{ 'sb-subcol-item--hovered': hovered }"
      :data-widget="JSON.stringify(element)"
      :data-location="JSON.stringify({ rowId: rowParentId, columnId: columnParentId, panelData: {
        panelId: panelId,
        subRowId: rowId,
        subColumnId: columnData.id,
        widgetIndex: index
      } })"
      @mouseover="onMouseOver"
      @mouseleave="onMouseLeave"
    >
      <div
        v-if="hovered"
        class="sb-subcol-title"
      >
        <span>{{ t('statsEditor.widget') }}</span>
      </div>
      <div
        v-if="hovered"
        class="sb-subcol-actions z-2"
      >
        <div class="flex flex-column">
          <div
            v-tooltip.right="t('statsEditor.actions.tooltip.widget.move')"
            class="flex sb-action handle-subwidget mb-1"
            icon="far fa-arrows"
          >
            <i class="far fa-arrows" />
          </div>
          <div
            v-tooltip.right="t('statsEditor.actions.tooltip.widget.edit')"
            class="flex sb-action mb-1"
            @click="editWidget"
          >
            <i class="far fa-cog" />
          </div>
          <div
            v-tooltip.right="t('statsEditor.actions.tooltip.widget.remove')"
            class="flex sb-action mb-1"
            @click="removeWidget"
          >
            <i class="far fa-trash" />
          </div>
        </div>
      </div>
      <StatsWidgetDataRender
        v-if="element.type === 'StatsWidgetData'"
        :widget-data="((selectedWidgetState !== null) && (selectedWidgetState.location.rowId === rowParentId && selectedWidgetState.location.columnId === columnParentId)
          && (selectedWidgetState.location.panelData !== null && selectedWidgetState.location.panelData.panelId === panelId
            && selectedWidgetState.location.panelData.subRowId === rowId && selectedWidgetState.location.panelData.subColumnId === columnData.id)) && index === selectedWidgetState.location.widgetIndex
          ? selectedWidgetState.data : element"
      />
      <StatsWidgetTitleRender
        v-else-if="element.type === 'StatsWidgetTitle'"
        :widget-data="((selectedWidgetState !== null) && (selectedWidgetState.location.rowId === rowParentId && selectedWidgetState.location.columnId === columnParentId)
          && (selectedWidgetState.location.panelData !== null && selectedWidgetState.location.panelData.panelId === panelId
            && selectedWidgetState.location.panelData.subRowId === rowId && selectedWidgetState.location.panelData.subColumnId === columnData.id)) && index === selectedWidgetState.location.widgetIndex
          ? selectedWidgetState.data : element"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed, defineComponent, Ref, SetupContext, ref,
} from 'vue';

import Tooltip from 'primevue/tooltip';

import StatsWidgetDataRender from '@/components/stats-builder/widgets/render/StatsWidgetDataRender.vue';
import StatsWidgetTitleRender from '@/components/stats-builder/widgets/render/StatsWidgetTitleRender.vue';
import { StatsBuilderSelectedWidget, StatsBuilderChoosedWidgetForDrag } from '@/types/stats-editor-types';
import { useStore } from '@/store';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'StatsBuilderSubColItem',

  components: {
    StatsWidgetDataRender,
    StatsWidgetTitleRender,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    element: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    rowId: {
      type: String,
      required: true,
    },

    columnData: {
      type: Object,
      required: true,
    },

    rowParentId: {
      type: String,
      required: true,
    },

    columnParentId: {
      type: String,
      required: true,
    },

    panelId: {
      type: String,
      required: true,
    },
  },

  emits: ['edit-widget', 'remove-widget'],

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();
    const store = useStore();
    const selectedWidgetState: Ref<StatsBuilderSelectedWidget | null> = computed(
      () => store.getters['statsEditor/getSelectedWidget'],
    );

    const hovered = ref(false);

    const choosedWidgetForDrag = computed<StatsBuilderChoosedWidgetForDrag | null>(() => store.getters['statsEditor/getChoosedWidgetForDrag']);

    const isChoosedForDrag = computed(() => {
      if (!choosedWidgetForDrag.value) {
        return false;
      }
      const continueCheck = choosedWidgetForDrag.value.rowId === props.rowParentId
        && choosedWidgetForDrag.value.columnId === props.columnParentId && choosedWidgetForDrag.value.index === props.index;
      if (continueCheck && choosedWidgetForDrag.value.panelData) {
        const { panelData } = choosedWidgetForDrag.value;
        return panelData.panelId === props.panelId && panelData.subRowId === props.rowId && panelData.subColumnId === props.columnData.id;
      }
      return false;
    });

    const editWidget = () => {
      emit('edit-widget', props.index);
    };

    const removeWidget = () => {
      emit('remove-widget', props.index);
    };

    const onMouseOver = () => {
      hovered.value = true;
    };
    const onMouseLeave = () => {
      hovered.value = false;
    };

    return {
      t,
      selectedWidgetState,
      hovered,
      isChoosedForDrag,

      editWidget,
      removeWidget,
      onMouseOver,
      onMouseLeave,
    };
  },
});
</script>

<style lang="scss">
.sb-subcol-item {
  border: 1px solid #dee2e6;
  background-color: #ffffff;
  min-height: 5rem;
  border-radius: 2px;
}
.sb-subcol-item--hovered {
  border: 2px solid #495057;
  border-top-left-radius: 0;
  padding-left: 3rem;
  padding-right: 3rem;
}

.sb-subcol-actions {
  position: absolute;
  top: 0.1rem;
  left: 0.5rem;

  .sb-action {
    cursor: pointer;
    padding: 9px;
    background: white;
    border: solid 1px #e5e5e5;
    border-radius: 5px;
  }
}
.sb-subcol-title {
  position: absolute;
  background-color: #495057;
  z-index: 2;
  line-height: 1rem;
  font-size: 0.8rem;
  color: white;
  top: -1.6rem;
  left: -0.1rem;
  padding: 6px 10px 3px 8px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>
