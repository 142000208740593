<template>
  <div class="field-wrapper">
    <ButtonGroup
      v-model="type"
      :options="types"
      title="automatedScenarios.fields.user"
    />
    <FieldErrors
      :key="componentFieldErrorsKey"
      :errors="formValidationErrors"
      field="multiplebox_type"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType, ref, SetupContext, watch,
} from 'vue';
import DeclencheurQuitSiteMetaData, {
  DeclencheurQuitSiteData,
} from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurQuitSiteMetadata';
import ButtonGroup from '@/components/automated-scenarios/fields/ButtonGroup.vue';
import { useI18n } from 'vue-i18n';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';

export default defineComponent({
  name: 'DeclencheurQuitSite',
  components: {
    FieldErrors,
    ButtonGroup,
  },

  props: {
    modelValue: {
      type: Object as PropType<DeclencheurQuitSiteData>,
      required: true,
      default() {
        return DeclencheurQuitSiteMetaData.Create();
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: DeclencheurQuitSiteData; formValidation: any }, context: SetupContext) {
    const { t } = useI18n();
    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });
    const type = ref(props.modelValue.multiplebox_type);

    const types = [
      { value: '0', label: t('automatedScenarios.fields.hasQuit') },
      { value: '1', label: t('automatedScenarios.fields.isGoingToQuit') },
    ];

    const update = () => context.emit(
      'update:modelValue', DeclencheurQuitSiteMetaData.Create({
        multiplebox_type: type.value,
      }),
    );

    watch(type, update);

    return {
      formValidationErrors,
      componentFieldErrorsKey,
      types,
      type,
    };
  },
});
</script>
