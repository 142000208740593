
import Dialog from 'primevue/dialog';
import {
  defineComponent, ref, SetupContext, watch,
} from 'vue';
import translation from '@/i18n/LongTexts';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TermsAndConditions',

  components: {
    Dialog,
  },

  emits: {
    'on-close-modal': Object,
  },

  setup(_: any, context: SetupContext) {
    const { t, locale } = useI18n();

    let translatedText = '';
    const maskClass = ref('p-dialog-mask p-component-overlay p-component-overlay-enter');
    const show = ref(true);

    // todo: access the object key using a dynamic variable
    Object.entries(translation).forEach((key) => {
      if (key[0] === locale.value) {
        translatedText = key[1].TAC;
      }
    });

    const hideMask = () => {
      maskClass.value = '';
      context.emit('on-close-modal', true);
    };
    return {
      t, show, translatedText, maskClass, hideMask,
    };
  },
});
