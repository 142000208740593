
import {
  computed,
  defineComponent, onMounted, Ref, ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import Avatar from 'primevue/avatar';
import { fetchNotifications, markNotificationsAsRead } from '@/composables/shop/Notifications';
import { UserState } from '@/composables/User';
import { useRouter } from 'vue-router';
import { ShopsNotificationsReadInputItem } from '@/types/generated-types/graphql';
import Tooltip from 'primevue/tooltip';

export default defineComponent({
  name: 'MyNotificationsMenu',

  components: {
    SpmOverlayPanel,
    Avatar,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    const { t } = useI18n();
    const shopId = UserState.activeShop?.id ?? 0;
    const router = useRouter();

    const notificationItems: Ref<any> = ref([]);
    const notificationKey = ref(0);

    const notReadNotification = computed(() => {
      if (notificationItems.value) {
        return notificationItems.value.some((item: any) => !item.is_read);
      }
      return false;
    });

    const markAllAsRead = async () => {
      const notificationsReadRecords: ShopsNotificationsReadInputItem[] = [];
      notificationItems.value.forEach((item: any) => {
        notificationsReadRecords.push({
          id_shop_notification: item.id_shop_notification,
          id_shop: shopId,
          date: new Date(),
        });
        // eslint-disable-next-line no-param-reassign
        item.is_read = true;
      });

      await markNotificationsAsRead(notificationsReadRecords);
    };

    const goNotification = async () => {
      await router.push({ name: 'shop.MyNotifications' });
    };

    const markNotificationAsRead = async (event: Event, id_shop_notification: number) => {
      event.stopPropagation();
      const notificationsReadRecord: ShopsNotificationsReadInputItem[] = [{
        id_shop_notification,
        id_shop: shopId,
        date: new Date(),
      }];

      await markNotificationsAsRead(notificationsReadRecord);
      notificationItems.value.forEach((item: any) => {
        if (item.id_shop_notification === id_shop_notification) {
          // eslint-disable-next-line no-param-reassign
          item.is_read = true;
        }
      });
    };

    const getNotificationsData = async () => {
      const notifications = await fetchNotifications({ shopId, paging: 0, limit: 5 });
      const notificationsRecords: any[] = [];
      if (notifications) {
        notifications.forEach((item: any) => {
          notificationsRecords.push({
            id_shop_notification: item.id_shop_notification,
            title: item.title,
            type: item.type,
            is_read: item.shopsNotificationsRead.length > 0,
            date_created: new Date(item.date).toLocaleString(),
          });
        });
        notificationKey.value += 1;
      }
      notificationItems.value = notificationsRecords;
    };

    onMounted(async () => {
      await getNotificationsData();
      setInterval(async () => getNotificationsData(), 10000);
    });

    return {
      t,
      notificationItems,
      goNotification,
      markAllAsRead,
      markNotificationAsRead,
      notReadNotification,
      notificationKey,
    };
  },
});
