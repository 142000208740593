// eslint-disable-next-line import/no-cycle
import {
  CustomMutation, Get, Request,
} from '@/composables/GraphQL';
// eslint-disable-next-line import/no-cycle
import {
  CampaignsBulk,
  CampaignsBulkBase,
  CampaignsBulkDuplicateInputItem,
  CampaignsBulkSaveInputItem,
  Maybe,
} from '@/types/generated-types/graphql';
// eslint-disable-next-line import/no-cycle
import { CampaignsBulkStatusEnum } from '@/types';
// eslint-disable-next-line import/no-cycle
import { showToastError } from '@/helpers';

export async function saveBulkCampaign(campaignBulkSaveInput: CampaignsBulkSaveInputItem) {
  const {
    id, messages, err,
  } = await CustomMutation<CampaignsBulkSaveInputItem>({
    name: 'SaveCampaignsBulk',
    input: campaignBulkSaveInput,
    type: 'CampaignsBulkSaveInput',
  });

  if (err === '') {
    return {
      success: true,
      id,
    };
  }

  return {
    success: false,
    messages,
  };
}

export async function duplicateBulkCampaign(campaignBulkDuplicateInput: CampaignsBulkDuplicateInputItem) {
  const {
    id, messages, err,
  } = await CustomMutation<CampaignsBulkDuplicateInputItem>({
    name: 'DuplicateCampaignsBulk',
    input: campaignBulkDuplicateInput,
    type: 'CampaignsBulkDuplicateInput',
  });

  if (err === '') {
    return {
      success: true,
      id,
    };
  }

  return {
    success: false,
    messages,
  };
}

export async function fetchBaseCampaignsBulk() {
  const query = 'query'
    + '{ GetBaseCampaignsBulk {'
    + 'id_campaign_bulk_base, name, legend, categories, images_templates}}';
  const variables = {};

  const { data, err } = await Request<CampaignsBulkBase[]>({
    name: 'GetBaseCampaignsBulk',
    query,
    variables,
  });

  if (err || data === null || data.length === 0) {
    return [];
  }

  return data;
}

export async function getBulkCampaignStatus(id: number): Promise<Maybe<CampaignsBulkStatusEnum>> {
  const bulkCampaign = await Get<CampaignsBulk>({
    name: 'CampaignsBulk',
    id,
    keyName: 'id_campaign_bulk',
    fields: [
      'id_campaign_bulk',
      'status',
    ],
  });

  if (bulkCampaign.item === null) {
    await showToastError(bulkCampaign.err);
    return null;
  }

  return bulkCampaign.item.status as CampaignsBulkStatusEnum;
}
