<template>
  <Dialog
    v-model:visible="dialogVisible"
    :modal="true"
    :draggable="false"
    :closable="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    style="width: 60vw"
    @update:visible="updateDialogVisibility"
  >
    <template #header>
      <div>
        <h3>{{ translation('storeParameters.services.productRecommendations.modalSuppliersTitle') }}</h3>
      </div>
    </template>
    <div class="container">
      <div class="p-fluid flex">
        <span class="p-input-icon-left">
          <i class="fa-thin fa-magnifying-glass" />
          <InputText
            v-model="suppliers"
            placeholder="Search"
            @input="handleSearch"
          />
        </span>
      </div>
      <div class="flex pane-container">
        <div
          class="pane"
          style="width: 45%; overflow: hidden;"
        >
          <Listbox
            v-model="availableSuppliers"
            :options="suppliersAvailableList"
            :virtual-scroller-options="{ itemSize: 38, showLoader: true, delay: 150 }"
            list-style="height:200px"
            multiple
          >
            <template #option="slotProps">
              <div style="text-align:left">
                {{ `#(${slotProps.option.id_manufacturer})` }}
                &nbsp;
                {{ slotProps.option.name }}
              </div>
            </template>
            <template #loader="{ options }">
              <div
                :class="['flex items-center p-2', { 'bg-surface-100 dark:bg-surface-700': options.odd }]"
                style="height: 50px"
              >
                <Skeleton
                  :width="options.even ? '60%' : '50%'"
                  height="1.3rem"
                />
              </div>
            </template>
          </listbox>
        </div>
        <div
          class="mt-4"
          style="width: 5%"
        >
          <Button
            icon="fa-solid fa-chevrons-left"
            @click="moveItemsToAvailable"
          />
          <Button
            icon="fa-solid fa-chevrons-right"
            class="mb-3"
            @click="moveItemsToChosen"
          />
        </div>
        <div
          class="pane"
          style="width: 50%; overflow: hidden;"
        >
          <Listbox
            v-model="chosenSuppliers"
            :options="suppliersChosenList"
            :virtual-scroller-options="{ itemSize: 38, showLoader: true, delay: 150 }"
            list-style="height:200px"
            multiple
          >
            <template #option="slotProps">
              <div style="text-align:left">
                {{ `#(${slotProps.option.id_shop_manufacturer})` }}
                &nbsp;
                {{ slotProps.option.name }}
              </div>
            </template>
            <template #loader="{ options }">
              <div
                :class="['flex items-center p-2', { 'bg-surface-100 dark:bg-surface-700': options.odd }]"
                style="height: 50px"
              >
                <Skeleton
                  :width="options.even ? '60%' : '50%'"
                  height="1.3rem"
                />
              </div>
            </template>
          </Listbox>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex justify-content-end">
        <Button @click="updateDialogVisibility">
          {{ translation('storeParameters.services.productRecommendations.validate') }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { ShopsManufacturers } from '@/types/generated-types/graphql';
import Listbox from 'primevue/listbox';
import InputText from 'primevue/inputtext';
import ShopsManufacturersList from '@/composables/shops-manufacturers/ShopsManufacturers';
import Skeleton from 'primevue/skeleton';

export default defineComponent({
  name: 'SupplierSelection',

  components: {
    Dialog,
    Button,
    Listbox,
    InputText,
    Skeleton,
  },

  props: {
    translation: {
      type: Function,
      require: true,
      default: () => ({}),
    },

    availableManufacturersArr: {
      type: Array as PropType<ShopsManufacturers[]>,
      required: true,
    },

    chosenManufacturersArr: {
      type: Array as PropType<ShopsManufacturers[]>,
      required: true,
    },

    manufacturerSearchStr: {
      type: String,
      required: true,
    },
  },

  emits: ['close-action-modal'],

  setup(props, context) {
    const dialogVisible = ref(true);
    const suppliers = ref(props.manufacturerSearchStr);
    const suppliersAvailableList = ref<ShopsManufacturers[]>(props.availableManufacturersArr);
    const suppliersChosenList = ref<ShopsManufacturers[]>(props.chosenManufacturersArr);
    const chosenSuppliers = ref<ShopsManufacturers[]>([]);
    const availableSuppliers = ref<ShopsManufacturers[]>([]);
    let timer: any = null;

    const updateDialogVisibility = () => {
      dialogVisible.value = false;
      context.emit('close-action-modal', suppliersChosenList.value, suppliersAvailableList.value, suppliers);
    };

    const handleSearch = async () => {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        const result = await ShopsManufacturersList(suppliers.value, 10000);
        if (result !== null) {
          availableSuppliers.value = [];
          suppliersAvailableList.value = result;
        }
      }, 1000);
    };

    const moveItemsToChosen = () => {
      suppliersChosenList.value = chosenSuppliers.value.concat(suppliersChosenList.value);

      suppliersChosenList.value.forEach((record: ShopsManufacturers) => {
        availableSuppliers.value = availableSuppliers.value.filter((chosenRecord: ShopsManufacturers) => chosenRecord.id_shop_manufacturer !== record.id_shop_manufacturer);
      });
      suppliersChosenList.value = suppliersChosenList.value.concat(availableSuppliers.value);

      suppliersChosenList.value.forEach((chosenSupplier: ShopsManufacturers) => {
        suppliersAvailableList.value = suppliersAvailableList.value.filter(
          (supplierAvailable: ShopsManufacturers) => supplierAvailable.id_shop_manufacturer !== chosenSupplier.id_shop_manufacturer,
        );
      });
      availableSuppliers.value = [];
      chosenSuppliers.value = [];
    };

    const moveItemsToAvailable = () => {
      suppliersAvailableList.value = suppliersAvailableList.value.concat(chosenSuppliers.value);

      chosenSuppliers.value.forEach((chosenSupplier: ShopsManufacturers) => {
        suppliersChosenList.value = suppliersChosenList.value.filter(
          (listSupplier: ShopsManufacturers) => listSupplier.id_shop_manufacturer !== chosenSupplier.id_shop_manufacturer,
        );
      });
      chosenSuppliers.value = [];
    };

    return {
      dialogVisible,
      updateDialogVisibility,
      suppliers,
      handleSearch,
      suppliersAvailableList,
      suppliersChosenList,
      chosenSuppliers,
      availableSuppliers,
      moveItemsToChosen,
      moveItemsToAvailable,
    };
  },
});
</script>

<style scoped lang="scss">
.container{
  padding: 1rem;
}

.pane-container {
  height: 200px;
}

.pane {
  margin: 5px 5px 0 0;
  height: 100%;
  width:50%;
  overflow: auto;
  border: 1px solid #cccccc;
}
</style>
