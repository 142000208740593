import { State, StatsEditorState } from '@/types/store-types';
import {
  GlobalDashboardSettingsData,
  GlobalEmailSettingsData,
  GlobalStatsSettingsData, PageStateFilters,
  StatsBuilderColumnConfig,
  StatsBuilderRowConfig,
  StatsBuilderSelectedWidget,
  StatsBuilderChoosedWidgetForDrag,
} from '@/types/stats-editor-types';
import {
  IntervalDateEnum, StatsEditorPanelEnum, StatsType, StatsWidgets,
} from '@/types';
import { ActionContext } from 'vuex';
// eslint-disable-next-line import/no-cycle
import { getComponentStatsWidgetMetadata } from '@/components/stats-builder/metadata';
import {
  GetUsersPagesConfiguration,
  TypeStatsEmailSendingFrequency,
} from '@/composables/Statistics/usersPagesConfiguration';
import { showToastError } from '@/helpers';

export const uniqueIdS6Generator = () => Math.random().toString(36).substr(2, 6);

/* Add a new row to the stats editor */
const insertNewRowInPanel = (items: any[], rowData: StatsBuilderRowConfig) => {
  items.forEach((item) => {
    if (item.children) {
      item.children.forEach((rowConfig: StatsBuilderRowConfig, index: number) => {
        if (rowConfig.type === 'placeholder') {
          // eslint-disable-next-line no-param-reassign
          item.children[index] = rowData;
        }
      });
    } else if (item.items) {
      insertNewRowInPanel(item.items, rowData);
    }
  });
};

const removePlaceholderRowsInPanel = (items: any[]) => {
  items.forEach((item) => {
    if (item.children && Array.isArray(item.children)) {
      // eslint-disable-next-line no-param-reassign
      item.children = item.children.filter((row: any) => row.type !== 'placeholder');
      item.children.forEach((element: any) => {
        if (element.children && Array.isArray(element.children)) {
          element.children.forEach((row: any) => {
            if (row.widgets && Array.isArray(row.widgets)) {
              // eslint-disable-next-line no-param-reassign
              row.widgets = row.widgets.filter((widget: any) => widget.type !== 'placeholder');
            }
          });
        }
      });
    } else if (item.items && Array.isArray(item.items)) {
      removePlaceholderRowsInPanel(item.items);
    }
  });
};

/* assign a new widget data to a column */
function assignWidgetToColumn(items: any, rowId: string, colId: string, widgetIndex: any, dataWidget: any) {
  items.forEach((item: any) => {
    if (item.type === StatsWidgets.ROW && item.id === rowId) {
      item.children.forEach((child: any) => {
        if (child.type === StatsWidgets.COLUMN && child.id === colId) {
          if (dataWidget === null) {
            // eslint-disable-next-line no-param-reassign
            child.widgets.splice(widgetIndex, 1);
          } else {
            // eslint-disable-next-line no-param-reassign
            if (!child.widgets) {
              // eslint-disable-next-line no-param-reassign
              child.widgets = [];
            }
            if (widgetIndex !== null && widgetIndex !== undefined) {
              if (dataWidget.isNew) {
                child.widgets.splice(widgetIndex, 0, dataWidget);
              } else {
                // eslint-disable-next-line no-param-reassign
                child.widgets[widgetIndex] = dataWidget;
              }
            } else {
              child.widgets.push(dataWidget);
            }
          }
          return true;
        }
        return false;
      });
    } else if (item.children) {
      assignWidgetToColumn(item.children, rowId, colId, widgetIndex, dataWidget);
    }
    return false;
  });
}

/* Save widget data to specific column if the column inside tabs or toolbar */
function findObjectWithPanelIdToAssignWidget(items: any[], panelId: string, subRowId: string, subColumnId: string, widgetIndex: any, dataWidget: any) {
  // eslint-disable-next-line no-restricted-syntax,consistent-return
  items.forEach((item) => {
    if (item.panelId === panelId) {
      assignWidgetToColumn(item.children, subRowId, subColumnId, widgetIndex, dataWidget);
      return true;
    }
    if (item.items) {
      findObjectWithPanelIdToAssignWidget(item.items, panelId, subRowId, subColumnId, widgetIndex, dataWidget);
    }
    return false;
  });
}

function findObjectWithPanelIdToRemoveRow(items: any[], panelId: string, subRowId: string) {
  // eslint-disable-next-line no-restricted-syntax,consistent-return
  items.forEach((item) => {
    if (item.panelId === panelId) {
      item.children.forEach((rowConfig: StatsBuilderRowConfig, index: number) => {
        if (rowConfig.id === subRowId) {
          item.children.splice(index, 1);
        }
      });
      return true;
    }
    if (item.items) {
      findObjectWithPanelIdToRemoveRow(item.items, panelId, subRowId);
    }
    return false;
  });
}

function duplicateRowInPanel(item: any, duplicateRowFunc: Function) {
  if (item.panelId) {
    // eslint-disable-next-line no-param-reassign
    item.panelId = `panel_${uniqueIdS6Generator()}`;

    item.children.forEach((rowConfig: StatsBuilderRowConfig, index: number) => {
      duplicateRowFunc(rowConfig);
    });
  }

  if (item.items) {
    item.items.forEach((subItem: any) => {
      duplicateRowInPanel(subItem, duplicateRowFunc);
    });
  }
}

function duplicateRow(rowConfig: any) {
  // eslint-disable-next-line no-param-reassign
  rowConfig.id = `row_${uniqueIdS6Generator()}`;
  rowConfig.children.forEach((columnConfig: any) => {
    // eslint-disable-next-line no-param-reassign
    columnConfig.id = `column_${uniqueIdS6Generator()}`;
    if (columnConfig.widgets && columnConfig.widgets[0]) {
      if (columnConfig.widgets[0].type === StatsWidgets.TABS) {
        columnConfig.widgets[0].tabs.forEach((tab: any) => {
          duplicateRowInPanel(tab, duplicateRow);
        });
      } else if (columnConfig.widgets[0].type === StatsWidgets.MENU) {
        columnConfig.widgets[0].toolbar.forEach((tb: any) => {
          duplicateRowInPanel(tb, duplicateRow);
        });
      }
    }
  });

  return rowConfig;
}

function findObjectWithPanelIdToDuplicateRow(items: any[], panelId: string, subRowId: string) {
  // eslint-disable-next-line no-restricted-syntax,consistent-return
  items.forEach((item) => {
    if (item.panelId === panelId) {
      item.children.forEach((rowConfig: StatsBuilderRowConfig, innerRowIndex: number) => {
        if (rowConfig.id === subRowId) {
          const clonedRowConfig = JSON.parse(JSON.stringify(rowConfig));
          duplicateRow(clonedRowConfig);
          item.children.splice(innerRowIndex + 1, 0, clonedRowConfig);
          return true;
        }
        return false;
      });
      return true;
    }
    if (item.items) {
      findObjectWithPanelIdToDuplicateRow(item.items, panelId, subRowId);
    }
    return false;
  });
}

type ObjectAttribute = string | number;
type ObjectPath = ObjectAttribute[];

// eslint-disable-next-line consistent-return
function searchAndUpdateWidgetsValue(
  rootObject: any,
  targetAttribute: string,
  targetValue: any,
  newValue: any,
  getObject: boolean,
): any {
  const stack: { obj: any; path: ObjectPath }[] = [{ obj: rootObject, path: [] }];

  while (stack.length > 0) {
    const stackItem = stack.pop();

    if (!stackItem) {
      // Stack is empty, exit the loop
      break;
    }

    const { obj, path } = stackItem;

    const keys = Object.keys(obj);
    for (let i = keys.length - 1; i >= 0; i--) {
      const key = keys[i];
      const value = obj[key];
      const newPath = [...path, key];

      if (key === targetAttribute && value === targetValue) {
        // Update the attribute value to an empty array
        if (getObject) {
          return obj;
        }
        obj[key] = newValue;
      } else if (value !== undefined && Array.isArray(value)) {
        for (let j = value.length - 1; j >= 0; j--) {
          stack.push({ obj: value[j], path: [...newPath, j.toString()] });
        }
      } else if (value !== undefined && typeof value === 'object' && value !== null) {
        stack.push({ obj: value, path: newPath });
      }
    }
  }
}

function findRowAndColumn(pageRows: any, rowId: string, columnId: string): any {
  const row = pageRows.find((pageRow: any) => pageRow.id === rowId);
  if (row) {
    const column = row.children.find((item: any) => item.id === columnId);
    if (column) {
      return { row, column };
    }
  }
  return null;
}

function updateLastLocation(pageRows: any, dataLastLocation: any) {
  const { rowId: lastRowId, columnId: lastColumnId, panelData: lastPanelData } = dataLastLocation;
  const { row, column } = findRowAndColumn(pageRows, lastRowId, lastColumnId);
  if (row && column) {
    const { subColumnId, widgetIndex } = lastPanelData;
    const obj = searchAndUpdateWidgetsValue(column.widgets, 'id', subColumnId, [], true);
    obj.widgets.splice(widgetIndex, 1);
  }
}

function removeColumn(row: any, idColumn: string) {
  if (row.type === 'StatsWidgetRow') {
    for (let j = 0; j < row.children.length; j++) {
      const column = row.children[j];
      if (column.type === 'StatsWidgetColumn' && column.id === idColumn) {
        const { layout } = column;
        row.children.splice(j, 1);
        if (row.children.length > 0) {
          // eslint-disable-next-line radix,no-param-reassign
          row.children[0].layout = (parseInt(row.children[0].layout) + parseInt(layout)).toString();

          const newRowLayout = [];
          for (let k = 0; k < row.children.length; k++) {
            newRowLayout.push(row.children[k].layout);
          }
          // eslint-disable-next-line no-param-reassign
          row.layout = newRowLayout.join('-');
        }
        return;
      }
    }
  }
}

const checkWidgetTabs = (tabs: any[], remove: boolean): boolean => {
  let check = true;

  for (let i = 0; i < tabs.length; i++) {
    if ('children' in tabs[i]) {
      if (!tabs[i].children.length) {
        if (remove) {
          tabs.splice(i, 1);
        }
        check = false;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        check = checkRows(tabs[i].children, remove);
      }
    } else if ('items' in tabs[i]) {
      if (!tabs[i].items.length) {
        if (remove) {
          tabs.splice(i, 1);
          break;
        }
        check = false;
      } else {
        check = checkWidgetTabs(tabs[i].items, remove);
      }
    }
    if (!check && !remove) {
      break;
    }
  }
  return check;
};

const checkRows = (rows: StatsBuilderRowConfig[], remove = false): boolean => {
  let check = true;
  if (rows.length) {
    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < rows[i].children.length; j++) {
        const { widgets } = rows[i].children[j];
        if (widgets === null) {
          check = false;
          if (remove) {
            removeColumn(rows[i], rows[i].children[j].id);
            if (!rows[i].children.length) {
              rows.splice(i, 1);
              break;
            }
          }
        } else {
          const widgetTab = widgets.find((widget: any) => widget.type === 'StatsWidgetTabs');
          const widgetMenu = widgets.find((widget: any) => widget.type === 'StatsWidgetMenu');
          if (widgetTab) {
            if (!widgetTab.tabs.length) {
              check = false;
              if (remove) {
                removeColumn(rows[i], rows[i].children[j].id);
                if (!rows[i].children.length) {
                  rows.splice(i, 1);
                  break;
                }
              }
            } else {
              check = checkWidgetTabs(widgetTab.tabs, remove);
            }
          } else if (widgetMenu) {
            if (!widgetMenu.toolbar.length) {
              check = false;
              if (remove) {
                removeColumn(rows[i], rows[i].children[j].id);
                if (!rows[i].children.length) {
                  rows.splice(i, 1);
                  break;
                }
              }
            } else {
              check = checkWidgetTabs(widgetMenu.toolbar, remove);
            }
          }
        }
      }

      if (!check && !remove) {
        break;
      }
    }
  }

  return check;
};

export const defaultPageStateFilters: PageStateFilters = {
  date_interval: {
    value: {
      interval: IntervalDateEnum.LAST_30_DAYS,
    },
    visibleOnView: true,
  },
};

export default {
  namespaced: true,
  state: (): StatsEditorState => ({
    pageId: 0,
    pageType: StatsType.STATS,
    pageSettings: {
      name: '',
      navigationName: '',
    },
    pageFilters: defaultPageStateFilters,
    pageRows: [],
    leftToolbar: {
      show: false,
      panel: StatsEditorPanelEnum.EMPTY,
    },
    selectedWidget: null,
    canConfigureFilters: true,
    refreshComponents: false,
    refreshNavigationBar: false,
    refreshWidgetsCount: 0,
    tempRow: null,
    choosedWidgetForDrag: null,
  }),

  getters: {
    getPageId: (state: StatsEditorState) => state.pageId,
    getPageType: (state: StatsEditorState) => state.pageType,
    getPageRows: (state: StatsEditorState) => state.pageRows,
    getPageFilters: (state: StatsEditorState) => state.pageFilters,
    getLeftToolbar: (state: StatsEditorState) => state.leftToolbar,
    getSelectedWidget: (state: StatsEditorState) => state.selectedWidget,
    getPageSettings: (state: StatsEditorState) => state.pageSettings,
    getCanConfigureFilters: (state: StatsEditorState) => state.canConfigureFilters,
    getRefreshComponents: (state: StatsEditorState) => state.refreshComponents,
    getRefreshNavigationBar: (state: StatsEditorState) => state.refreshNavigationBar,
    getRefreshWidgetsCount: (state: StatsEditorState) => state.refreshWidgetsCount,
    getTempRow: (state: StatsEditorState) => state.tempRow,
    getChoosedWidgetForDrag: (state: StatsEditorState) => state.choosedWidgetForDrag,
  },

  mutations: {
    resetState: (state: StatsEditorState) => {
      state.pageId = 0;
      state.pageType = StatsType.STATS;
      state.pageFilters = defaultPageStateFilters;
      state.pageSettings = {
        name: '',
        navigationName: '',
      };
      state.pageRows = [];
      state.leftToolbar = {
        show: false,
        panel: StatsEditorPanelEnum.EMPTY,
      };
      state.canConfigureFilters = true;
      state.selectedWidget = null;
    },
    setPageRows: (state: StatsEditorState, payload: StatsBuilderRowConfig[]) => {
      state.pageRows = payload;
    },
    setOnePageRow: (state: StatsEditorState, payload: StatsBuilderRowConfig) => {
      state.pageRows.push(payload);
    },
    showLeftToolbar: (state: StatsEditorState, panel: StatsEditorPanelEnum) => {
      state.leftToolbar = {
        show: true,
        panel,
      };
    },
    hideLeftToolbar: (state: StatsEditorState) => {
      if (state.leftToolbar.show) {
        state.leftToolbar = {
          show: false,
          panel: StatsEditorPanelEnum.EMPTY,
        };
      }
    },
    setSelectedWidget: (state: StatsEditorState, payload: StatsBuilderSelectedWidget|null) => {
      state.selectedWidget = payload;
    },
    resetSelectedWidget: (state: StatsEditorState) => {
      state.selectedWidget = null;
    },
    setPageSettings: (state: StatsEditorState, payload: GlobalStatsSettingsData|GlobalEmailSettingsData|GlobalDashboardSettingsData) => {
      state.pageSettings = payload;
    },
    setCanConfigureFilters: (state: StatsEditorState, canConfigure: boolean) => {
      state.canConfigureFilters = canConfigure;
    },
    setRefreshComponents: (state: StatsEditorState, refresh: boolean) => {
      state.refreshComponents = refresh;
    },
    setRefreshNavigationBar: (state: StatsEditorState, refresh: boolean) => {
      state.refreshNavigationBar = refresh;
    },
    setPageFilters: (state: StatsEditorState, payload: PageStateFilters) => {
      state.pageFilters = payload;
    },
    setRefreshWidgetsCount: (state: StatsEditorState, payload: number) => {
      state.refreshWidgetsCount = payload;
    },
    setTempRow: (state: StatsEditorState, payload: { rowId: string; columnId: string; panelData: any }) => {
      state.tempRow = payload;
    },
    resetTempRow: (state: StatsEditorState) => {
      state.tempRow = null;
    },
    setChoosedWidgetForDrag: (state: StatsEditorState, payload: StatsBuilderChoosedWidgetForDrag) => {
      state.choosedWidgetForDrag = payload;
    },
    resetChoosedWidgetForDrag: (state: StatsEditorState) => {
      state.choosedWidgetForDrag = null;
    },
  },

  actions: {
    editPage: async ({ state, commit }: ActionContext<StatsEditorState, State>,
      { pageId }: { pageId: number }) => {
      commit('resetState');
      // récupération de la page en fonction de son id
      const { err, item } = await GetUsersPagesConfiguration(pageId, ['page_name', 'navigation_name', 'configuration', 'extra_data', 'type', 'deleted']);

      // restauré les valeurs state
      if (item && !err) {
        state.pageId = pageId;
        state.pageType = item.type as StatsType;
        state.pageRows = item.configuration ? JSON.parse(item.configuration) : [];
        searchAndUpdateWidgetsValue(state.pageRows, 'widgets', null, [], false);
        const extraData = item.extra_data ? JSON.parse(item.extra_data) : null;

        if (state.pageType === StatsType.EMAIL) {
          state.pageSettings = {
            name: item.page_name,
            navigationName: item.navigation_name,
            mail_subject: extraData ? extraData.mail_subject : '',
            mailing_frequencies: extraData ? extraData.mailing_frequency : { type: TypeStatsEmailSendingFrequency.RECURRENT },
          };

          if (extraData && extraData.mailing_frequency && extraData.mailing_frequency.type === TypeStatsEmailSendingFrequency.TRIGGER_BULK_CAMPAIGN) {
            commit('setCanConfigureFilters', false);
          }
        } else {
          state.pageSettings = {
            name: item.page_name,
            navigationName: item.navigation_name,
          };
        }

        state.pageFilters = extraData && extraData.defaultFilters ? extraData.defaultFilters : defaultPageStateFilters;
        if (state.pageFilters.id_campaign_bulk && state.pageFilters.id_campaign_bulk.value && Array.isArray(state.pageFilters.id_campaign_bulk.value)) {
          if (state.pageFilters.id_campaign_bulk.value.length) {
            /* eslint-disable-next-line prefer-destructuring */
            state.pageFilters.id_campaign_bulk.value = state.pageFilters.id_campaign_bulk.value[0];
          } else {
            state.pageFilters.id_campaign_bulk.value = '';
          }
        }
      } else {
        await showToastError('statsEditor.noExistPage');
      }
    },

    saveWidget: async ({ state }: ActionContext<StatsEditorState, State>) => new Promise((resolve) => {
      if (state.selectedWidget === null) return;
      const {
        rowId,
        columnId,
        panelData,
        widgetIndex,
        isNew,
      } = state.selectedWidget.location;
      const panelId = panelData?.panelId;
      const subRowId = panelData?.subRowId;
      const subColumnId = panelData?.subColumnId;

      const meta = getComponentStatsWidgetMetadata(state.selectedWidget.component);
      if (!meta) {
        return;
      }

      if (meta.Validate) {
        meta.Validate(state.selectedWidget.data).then((validation) => {
          /* if validation is not ok reject */
          if (!validation.success) {
            resolve(validation);
            return;
          }

          /* format data if needed */
          if (meta.formatData && state.selectedWidget) {
            meta.formatData(state.selectedWidget.data);
          }

          /* save data in the specific row, column && panelId */
          state.pageRows.forEach((rowConfig: StatsBuilderRowConfig) => {
            if (rowConfig.id === rowId) {
              rowConfig.children.forEach((columnConfig: StatsBuilderColumnConfig) => {
                if (columnConfig.id === columnId) {
                  if (panelId && subRowId && subColumnId && columnConfig.widgets && columnConfig.widgets[0]) {
                    if (columnConfig.widgets[0].type === StatsWidgets.TABS) {
                      findObjectWithPanelIdToAssignWidget(columnConfig.widgets[0].tabs, panelId, subRowId, subColumnId, widgetIndex, {
                        type: state.selectedWidget?.component ?? '',
                        ...state.selectedWidget?.data,
                        isNew,
                      });
                    } else if (columnConfig.widgets[0].type === StatsWidgets.MENU) {
                      findObjectWithPanelIdToAssignWidget(columnConfig.widgets[0].toolbar, panelId, subRowId, subColumnId, widgetIndex, {
                        type: state.selectedWidget?.component ?? '',
                        ...state.selectedWidget?.data,
                        isNew,
                      });
                    }
                  } else {
                    // eslint-disable-next-line no-param-reassign
                    if (columnConfig.widgets.length === 0) {
                      // eslint-disable-next-line no-param-reassign
                      columnConfig.widgets = [];
                    }
                    if (widgetIndex !== null && widgetIndex !== undefined) {
                      columnConfig.widgets.splice(widgetIndex, 1, {
                        type: state.selectedWidget?.component ?? '',
                        ...state.selectedWidget?.data,
                      });
                    } else {
                      columnConfig.widgets.push({
                        type: state.selectedWidget?.component ?? '',
                        ...state.selectedWidget?.data,
                      });
                    }
                  }
                }
              });
            }
          });
          resolve({ success: true });
        });
      }
    }),

    removeWidget: ({ state }: ActionContext<StatsEditorState, State>,
      { location }: { location: any }) => {
      const {
        rowId,
        columnId,
        panelData,
        widgetIndex,
      } = location;
      const panelId = panelData?.panelId;
      const subRowId = panelData?.subRowId;
      const subColumnId = panelData?.subColumnId;

      state.pageRows.forEach((rowConfig: StatsBuilderRowConfig) => {
        if (rowConfig.id === rowId) {
          rowConfig.children.forEach((columnConfig: StatsBuilderColumnConfig) => {
            if (columnConfig.id === columnId) {
              if (panelId && subRowId && subColumnId && columnConfig.widgets && columnConfig.widgets[0]) {
                if (columnConfig.widgets[0].type === StatsWidgets.TABS) {
                  findObjectWithPanelIdToAssignWidget(columnConfig.widgets[0].tabs, panelId, subRowId, subColumnId, widgetIndex, null);
                } else if (columnConfig.widgets[0].type === StatsWidgets.MENU) {
                  findObjectWithPanelIdToAssignWidget(columnConfig.widgets[0].toolbar, panelId, subRowId, subColumnId, widgetIndex, null);
                }
              } else {
                // eslint-disable-next-line no-param-reassign
                columnConfig.widgets.splice(widgetIndex, 1);
              }
            }
          });
        }
      });
    },

    addRow: ({ state }: ActionContext<StatsEditorState, State>,
      { rowData }: { rowData: StatsBuilderRowConfig}) => {
      state.pageRows.forEach((rowConfig: StatsBuilderRowConfig, index: number) => {
        if (rowConfig.type === 'placeholder') {
          state.pageRows[index] = rowData;
        } else {
          rowConfig.children.forEach((columnConfig: StatsBuilderColumnConfig) => {
            if (columnConfig.widgets && columnConfig.widgets[0]) {
              if (columnConfig.widgets[0].type === StatsWidgets.TABS) {
                insertNewRowInPanel(columnConfig.widgets[0].tabs, rowData);
              } else if (columnConfig.widgets[0].type === StatsWidgets.MENU) {
                insertNewRowInPanel(columnConfig.widgets[0].toolbar, rowData);
              }
            }
          });
        }
      });
    },

    addRowToIndex: ({ state, commit }: ActionContext<StatsEditorState, State>,
      {
        rowData,
        index,
        panelId,
        parentRowId,
        parentColumnId,
      }: { rowData: StatsBuilderRowConfig; index: number; panelId: string; parentRowId: string; parentColumnId: string }) => {
      const clonedPageRows = JSON.parse(JSON.stringify(state.pageRows));
      if (!panelId) {
        clonedPageRows.splice(index, 0, rowData);
      } else {
        const { row, column } = findRowAndColumn(clonedPageRows, parentRowId, parentColumnId);
        if (row && column) {
          const obj = searchAndUpdateWidgetsValue(column, 'panelId', panelId, [], true);
          obj.children.splice(index, 0, rowData);
        }
      }

      commit('setPageRows', clonedPageRows);
    },

    removeRow: ({ state }: ActionContext<StatsEditorState, State>,
      { location }: { location: any; rowData: StatsBuilderRowConfig}) => {
      const { rowId, columnId, panelData } = location;
      const panelId = panelData?.panelId;
      const subRowId = panelData?.subRowId;

      state.pageRows.forEach((rowConfig: StatsBuilderRowConfig, index: number) => {
        if (rowConfig.id === rowId) {
          if (columnId && panelId && subRowId) {
            rowConfig.children.forEach((columnConfig: StatsBuilderColumnConfig) => {
              if (columnConfig.id === columnId && columnConfig.widgets && columnConfig.widgets[0]) {
                if (columnConfig.widgets[0].type === StatsWidgets.TABS) {
                  findObjectWithPanelIdToRemoveRow(columnConfig.widgets[0].tabs, panelId, subRowId);
                } else if (columnConfig.widgets[0].type === StatsWidgets.MENU) {
                  findObjectWithPanelIdToRemoveRow(columnConfig.widgets[0].toolbar, panelId, subRowId);
                }
              }
            });
          } else {
            // eslint-disable-next-line no-param-reassign
            state.pageRows.splice(index, 1);
          }
        }
      });
    },

    duplicateRow: ({ state }: ActionContext<StatsEditorState, State>,
      { location }: { location: any; rowData: StatsBuilderRowConfig}) => {
      const { rowId, columnId, panelData } = location;
      const panelId = panelData?.panelId;
      const subRowId = panelData?.subRowId;

      state.pageRows.forEach((rowConfig: StatsBuilderRowConfig, rowIndex: number) => {
        if (rowConfig.id === rowId) {
          if (columnId && panelId && subRowId) {
            rowConfig.children.forEach((columnConfig: StatsBuilderColumnConfig) => {
              if (columnConfig.id === columnId && columnConfig.widgets && columnConfig.widgets[0]) {
                if (columnConfig.widgets[0].type === StatsWidgets.TABS) {
                  findObjectWithPanelIdToDuplicateRow(columnConfig.widgets[0].tabs, panelId, subRowId);
                } else if (columnConfig.widgets[0].type === StatsWidgets.MENU) {
                  findObjectWithPanelIdToDuplicateRow(columnConfig.widgets[0].toolbar, panelId, subRowId);
                }
              }
            });
          } else {
            const clonedRowConfig = JSON.parse(JSON.stringify(rowConfig));
            duplicateRow(clonedRowConfig);
            state.pageRows.splice(rowIndex + 1, 0, clonedRowConfig);
          }
        }
      });
    },

    removePlaceholderRows: ({ state }: ActionContext<StatsEditorState, State>) => {
      state.pageRows.forEach((rowConfig: StatsBuilderRowConfig, index: number) => {
        if (rowConfig.type && rowConfig.type === 'placeholder') {
          state.pageRows.splice(index, 1);
        } else {
          rowConfig.children.forEach((columnConfig: StatsBuilderColumnConfig) => {
            // eslint-disable-next-line no-param-reassign
            columnConfig.widgets = columnConfig.widgets.filter((widget: any) => widget.type !== 'placeholder');

            columnConfig.widgets.forEach((widget: any) => {
              if (widget.type === StatsWidgets.TABS) {
                removePlaceholderRowsInPanel(widget.tabs);
              } else if (widget.type === StatsWidgets.MENU) {
                removePlaceholderRowsInPanel(widget.toolbar);
              }
            });
          });
        }
      });
    },

    addWidgetToEmptyCol: ({ state, commit }: ActionContext<StatsEditorState, State>,
      { dataWidget, dataLocation, dataLastLocation }: { dataWidget: any; dataLocation: any; dataLastLocation: any }) => {
      const { rowId, columnId } = dataLocation;
      const clonedPageRows = JSON.parse(JSON.stringify(state.pageRows));

      const { row, column } = findRowAndColumn(clonedPageRows, rowId, columnId);

      if (row && column) {
        if (!column.widgets) {
          column.widgets = [];
        }
        column.widgets.push(dataWidget);
      }
      if (dataLastLocation) {
        updateLastLocation(clonedPageRows, dataLastLocation);
      }
      commit('setPageRows', clonedPageRows);
    },

    undoMoveWidget: ({ state, commit }: ActionContext<StatsEditorState, State>, { dataWidget, dataWidgetLocation }: { dataWidget: any; dataWidgetLocation: any }) => {
      const {
        rowId,
        columnId,
        widgetIndex,
        panelData,
      } = dataWidgetLocation;
      const clonedPageRows = JSON.parse(JSON.stringify(state.pageRows));

      const { row, column } = findRowAndColumn(clonedPageRows, rowId, columnId);

      if (row && column) {
        if (!panelData) {
          if (!column.widgets) {
            column.widgets = [];
          }
          if (column.widgets.length === 0) {
            column.widgets.push(dataWidget);
          } else {
            column.widgets.splice(widgetIndex, 0, dataWidget);
          }
        } else {
          const { subColumnId, widgetIndex: panelWidgetIndex } = panelData;
          const obj = searchAndUpdateWidgetsValue(column.widgets, 'id', subColumnId, [], true);
          if (!obj.widgets) {
            obj.widgets = [];
          }
          if (obj.widgets.length === 0) {
            obj.widgets.push(dataWidget);
          } else {
            obj.widgets.splice(panelWidgetIndex, 0, dataWidget);
          }
        }
      }
      commit('setPageRows', clonedPageRows);
    },

    addWidgetToEmptySubCol: ({ state, commit }: ActionContext<StatsEditorState, State>,
      { dataWidget, dataLocation, dataLastLocation }: { dataWidget: any; dataLocation: any; dataLastLocation: any }) => {
      const { rowId, columnId, panelData } = dataLocation;
      const clonedPageRows = JSON.parse(JSON.stringify(state.pageRows));

      const { row, column } = findRowAndColumn(clonedPageRows, rowId, columnId);
      if (row && column) {
        const { subColumnId } = panelData;
        const obj = searchAndUpdateWidgetsValue(column.widgets, 'id', subColumnId, [], true);
        if (!obj.widgets) {
          obj.widgets = [];
        }
        obj.widgets.push(dataWidget);
      }
      if (dataLastLocation) {
        updateLastLocation(clonedPageRows, dataLastLocation);
      }

      commit('setPageRows', clonedPageRows);
    },

    addWidgetToSubCol: ({ state, commit }: ActionContext<StatsEditorState, State>,
      { dataWidget, dataLocation, dataLastLocation }: { dataWidget: any; dataLocation: any; dataLastLocation: any }) => {
      const {
        rowId,
        columnId,
        panelData,
        index,
      } = dataLocation;
      const clonedPageRows = JSON.parse(JSON.stringify(state.pageRows));

      const { row, column } = findRowAndColumn(clonedPageRows, rowId, columnId);
      if (row && column) {
        const { subColumnId } = panelData;
        const obj = searchAndUpdateWidgetsValue(column.widgets, 'id', subColumnId, [], true);
        obj.widgets.splice(index, 0, dataWidget);
      }
      if (dataLastLocation) {
        updateLastLocation(clonedPageRows, dataLastLocation);
      }

      commit('setPageRows', clonedPageRows);
    },

    sortWidgetInSubCol: ({ state, commit }: ActionContext<StatsEditorState, State>, { dataLocation }: { dataLocation: any }) => {
      const {
        rowId,
        columnId,
        panelData,
        oldIndex,
        newIndex,
      } = dataLocation;
      const clonedPageRows = JSON.parse(JSON.stringify(state.pageRows));

      const { row, column } = findRowAndColumn(clonedPageRows, rowId, columnId);
      if (row && column) {
        const { subColumnId } = panelData;
        const obj = searchAndUpdateWidgetsValue(column.widgets, 'id', subColumnId, [], true);
        const widget = obj.widgets[oldIndex];
        obj.widgets.splice(oldIndex, 1);
        obj.widgets.splice(newIndex, 0, widget);
      }

      commit('setPageRows', clonedPageRows);
    },

    removeWidgetFromSubCol: ({ state, commit }: ActionContext<StatsEditorState, State>, { dataLastLocation }: { dataLastLocation: any }) => {
      const clonedPageRows = JSON.parse(JSON.stringify(state.pageRows));
      updateLastLocation(clonedPageRows, dataLastLocation);
      commit('setPageRows', clonedPageRows);
    },

    removeColumn: ({ state, commit }: ActionContext<StatsEditorState, State>, { rowId, columnId, subColumnId }: { rowId: string; columnId: string; subColumnId: any }) => {
      const clonedPageRows = JSON.parse(JSON.stringify(state.pageRows));

      const { row, column } = findRowAndColumn(clonedPageRows, rowId, columnId);

      if (row && column) {
        if (!subColumnId) {
          column.widgets = null;
        } else {
          const obj = searchAndUpdateWidgetsValue(column.widgets, 'id', subColumnId, [], true);
          obj.widgets = null;
        }
        do {
          checkRows(clonedPageRows, true);
        } while (!checkRows(clonedPageRows, false));
      }

      commit('setPageRows', clonedPageRows);
    },
  },
};
