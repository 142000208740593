
import {
  computed, ComputedRef,
  defineComponent,
  PropType,
  ref,
  Ref,
} from 'vue';
import Chart from 'primevue/chart';
import Button from 'primevue/button';
import { ChartType, DataSet, SpmChart } from '@/types/generated-types/graphql';
import moment from 'moment/moment';
import 'moment/locale/fr';
import 'chartjs-adapter-moment';
import { UserState } from '@/composables/User';
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from '@/i18n';
import { useI18n } from 'vue-i18n';
import { store } from '@/store';
import { IntervalDateEnum } from '@/types';

export default defineComponent({
  name: 'SpmGraph',
  components: {
    Chart,
    Button,
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    spmChart: {
      type: Object as PropType<SpmChart>,
      required: true,
    },

    startDate: {
      type: Object,
      required: true,
    },

    endDate: {
      type: Object,
      required: true,
    },
  },

  emits: {
    'load-more-dates': String,
  },

  setup(props, context) {
    const { t } = useI18n();
    const { lang } = UserState.user;
    const locale = lang && SUPPORTED_LOCALES.includes(lang) ? lang : DEFAULT_LOCALE;
    moment().locale(locale);
    const chart: Ref<SpmChart> = ref(props.spmChart);
    const type: Ref<ChartType> = ref(chart.value.data?.datasets && chart.value.data?.datasets[0] && chart.value.data?.datasets[0].type
      ? chart.value.data?.datasets[0]?.type
      : ChartType.PIE);
    const startDate = ref(props.startDate);
    const endDate = ref(props.endDate);
    const isTimeline: ComputedRef<boolean> = computed(() => [ChartType.LINE, ChartType.BAR, ChartType.SCATTER].includes(type.value));

    const pageFilters = computed(() => store.getters['statsEditor/getPageFilters']);
    const displayArrows = computed(() => {
      let left = false;
      let right = false;

      if (Object.prototype.hasOwnProperty.call(pageFilters.value, 'date_interval')
        && pageFilters.value.date_interval.value.interval === IntervalDateEnum.LAST_12_MONTHS
        && isTimeline.value
      ) {
        if (endDate.value.format('YYYY-MM-DD 23:59:59').toString() !== moment().format('YYYY-MM-DD 23:59:59').toString()) {
          right = true;
        }

        if (startDate.value.format('YYYY-MM-DD 00:00:00').toString() !== moment().subtract(12, 'months').format('YYYY-MM-DD 00:00:00').toString()) {
          left = true;
        }
      }

      return {
        left,
        right,
      };
    });

    const options = {
      ...props.spmChart.options,
      maintainAspectRatio: false,

      interaction: {
        mode: 'index',
        intersect: false,
      },

      plugins: {
        legend: {
          display: [ChartType.BAR, ChartType.LINE, ChartType.SCATTER].includes(type.value),
          position: 'bottom',
        },
      },
    };

    if (props.spmChart.options && 'removeValueInTooltip' in props.spmChart.options) {
      options.plugins.tooltip = {
        callbacks: {
          label(ctx: any) {
            let label = ctx.label.split(' : ');
            label[0] = t(label[0]);
            label = label.join(' : ');
            return label;
          },
        },
      };
    }

    if (chart.value.data && chart.value.data.labels && chart.value.data.labels.length) {
      chart.value.data.labels.forEach((label: string | null, index: number) => {
        if (chart.value.data && label && new RegExp('^(stats\\.statsLabels)|(countries\\.codes\\.)|(languages\\.codes\\.)').test(label)) {
          // eslint-disable-next-line no-param-reassign
          chart.value.data.labels[index] = t(label);
        }
      });
    }

    let hasData = false;
    if (chart.value.data && chart.value.data.datasets && chart.value.data.datasets.length) {
      chart.value.data.datasets.forEach((dataset: DataSet | null) => {
        if (dataset) {
          if (dataset.data?.length) {
            hasData = true;
          }

          if (dataset.label && isTimeline.value) {
            // eslint-disable-next-line no-param-reassign
            dataset.label = t(dataset.label);
          }
        }
      });
    }

    const loadMoreDates = (direction: string) => {
      context.emit('load-more-dates', direction);
    };

    return {
      t,
      isTimeline,
      type,
      chart,
      options,
      hasData,
      displayArrows,
      loadMoreDates,
    };
  },
});
