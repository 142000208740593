<template>
  <Dialog
    :visible="isOpenAccessToSupportModalVisible"
    modal
    :header="t('openAccessToSupportTitle')"
    :style="{ width: '45rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :draggable="false"
    @update:visible="handleHideDialog"
    @show="onShow"
  >
    <div
      v-if="isLoading"
      class="p-5"
    >
      <Loader />
    </div>
    <div
      v-else
      class="flex flex-column gap-3 text-left"
    >
      <div class="field">
        <label>{{ t('automatedScenarios.fields.duration') }}</label>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="fa-solid fa-timer" />
          </span>
          <Dropdown
            v-model="field.duration"
            :options="durationOptions"
            option-label="label"
            option-value="value"
            class="value-select"
          />
        </div>
        <FieldErrors
          :key="componentFieldErrorsKey"
          :errors="error"
          field="duration"
        />
      </div>
      <div class="field">
        <label>{{ t('profile.myInformation.subUser.enabledShop') }}</label>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="fa-regular fa-store" />
          </span>
          <MultiSelect
            v-model="field.selectedShops"
            :options="shopOptions"
            :show-toggle-all="false"
            :filter="true"
            option-value="code"
            option-label="label"
            data-key="code"
            :placeholder="t('profile.myInformation.subUser.placeholder')"
          />
        </div>
        <FieldErrors
          :key="componentFieldErrorsKey"
          :errors="error"
          field="selectedShops"
        />
      </div>
      <div
        class="justify-content-center align-items-center mt-2 mb-2"
      >
        <SpmButton
          :label="t('validate')"
          :loading="isRequestLoading"
          class-style="p-button p-button-primary w-full"
          @click="handleValidate"
        />
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  ref,
  reactive,
} from 'vue';

import { useStore } from '@/store';
import { useI18n } from 'vue-i18n';

import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';

import Loader from '@/components/layout/Loader.vue';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';

import { showToastError, showToastSuccess } from '@/helpers';

import { refreshTokenLogin, UserState } from '@/composables/User';
import InsertShopsUsers, {
  UpsertUsersPermissions,
  getShopSubUsers,
} from '@/composables/user/ShopsUsers';
import { getUserShops } from '@/composables/shop/Shops';
import {
  getUserStatsPages,
  InsertUsersPagesSubscriptions,
  DeleteUsersPagesSubscriptions,
} from '@/composables/Statistics/usersPagesSubscriptions';
import { getUsersPagesConfiguration } from '@/composables/Statistics/usersPagesConfiguration';

import { SUPPORT_SHOPIMIND_EMAIL } from '@/configs';
import permissionsArray from '@/configs/permissions';

import {
  DropdownOption, StatsType, TimeUnits, UserTypeEnum,
} from '@/types';

import {
  ShopsUsers,
  ShopsUsersInputItem,
  UsersPagesSubscriptionsInputItem,
  UsersPermissionsUpsertInputItem,
  UsersPagesSubscriptionsDeleteInput,
} from '@/types/generated-types/graphql';

import useVuelidate from '@vuelidate/core';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { required, helpers } from '@vuelidate/validators';

import moment from 'moment';
import * as lzstring from 'lz-string';

interface Node {
  value: string;
  label: string;
  type: string;
  group: string;
  hidden: boolean;
  nodes?: Node[];
}

export default defineComponent({
  name: 'OpenAccessToSupportModal',
  components: {
    Dialog,
    Dropdown,
    MultiSelect,
    FieldErrors,
    SpmButton,
    Loader,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();

    const isLoading = ref(false);
    const isRequestLoading = ref(false);

    const componentFieldErrorsKey = ref(0);
    const error = ref();

    const isOpenAccessToSupportModalVisible = computed(() => store.getters['general/getIsOpenAccessToSupportModalVisible']);

    const field = reactive({
      duration: null,
      selectedShops: [],
    });

    const shopOptions = ref<{code: number; label: string}[]>([]);
    const durationOptions = ref([
      {
        label: t('openAccessToSupportFields.duration.48h'),
        value: 2,
      },
      {
        label: t('openAccessToSupportFields.duration.72h'),
        value: 3,
      },
      {
        label: t('openAccessToSupportFields.duration.oneWeek'),
        value: 7,
      },
      {
        label: t('openAccessToSupportFields.duration.twoWeeks'),
        value: 14,
      },
      {
        label: t('openAccessToSupportFields.duration.oneMonth'),
        value: 30,
      },
      {
        label: t('openAccessToSupportFields.duration.unlimited'),
        value: 0,
      },
    ]);

    const statsPages = ref<any[]>([]);
    const dashboard = ref();

    const groupElements = ref<string[]>([]);
    const subGroupElements = ref<string[]>([]);
    const elements = ref<string[]>([]);

    const shopUsers = ref<number[]>([]);

    const resetState = () => {
      shopOptions.value = [];
      field.duration = null;
      field.selectedShops = [];
      error.value = null;
      componentFieldErrorsKey.value = 0;
      statsPages.value = [];
      dashboard.value = null;
    };

    const handleHideDialog = async () => {
      store.commit('general/setIsOpenAccessToSupportModalVisible', false);
      resetState();
    };

    const onShow = async () => {
      isLoading.value = true;

      const users = new Set<number>();
      if (UserState.activeShop) {
        users.add(UserState.activeShop.idUser);
      }

      shopUsers.value = Array.from(users);

      const shops = await getUserShops(UserState.activeShop?.idUser);
      if (shops && shops.length) {
        shops.forEach((shop) => {
          shopOptions.value.push({
            code: shop.id_shop,
            label: shop.name || '',
          });
        });
      }

      // Stats pages
      const userStatsPages = await getUserStatsPages(UserState.user.id, UserState.user.userType, StatsType.STATS, UserState.activeShop?.idUser);

      if (userStatsPages && userStatsPages.items && userStatsPages.items.length) {
        statsPages.value = userStatsPages.items.map((item: any) => item.id_users_pages_configuration);
      }

      // Dashboard page
      const dashboardPages = await getUserStatsPages(parseInt(UserState.user.id.toString(), 10), UserState.user.userType, StatsType.DASHBOARD, UserState.activeShop?.idUser);
      if (dashboardPages && dashboardPages.items && dashboardPages.items.length) {
        dashboard.value = dashboardPages.items[0].id_users_pages_configuration;
      }

      // Permission
      groupElements.value = permissionsArray.nodes.map((node) => node.value);
      subGroupElements.value = permissionsArray.nodes
        .map((node) => node.nodes)
        .filter(Boolean)
        .flat()
        .filter((node) => node.type === 'sub-title')
        .map((node) => node.value);

      elements.value = (permissionsArray.nodes as Node[])
        .flatMap((node) => node.nodes ?? [])
        .filter((node): node is Node => node?.type === 'sub-title' && Array.isArray(node.nodes) && node.nodes.length > 0)
        .flatMap((node) => node.nodes || [])
        .map((node) => node.value);

      isLoading.value = false;
    };

    const validate = async (): Promise<ErrorConfigForm> => {
      const hasShops = (value: number[]) => value.length > 0;

      const rules = {
        duration: {
          required,
        },

        selectedShops: {
          hasShops: helpers.withMessage(t('vuelidate.hasShops'), hasShops),
        },
      };

      const v$ = useVuelidate(rules, field);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    const handleValidate = async () => {
      const validation = await validate();
      if (!validation.success) {
        error.value = validation.validate;
        componentFieldErrorsKey.value += 1;
        return;
      }

      isRequestLoading.value = true;

      let expirationDate: string | null = null;

      if (field && field.duration) {
        expirationDate = moment(new Date()).add(field.duration, 'days').format('YYYY-MM-DD, HH:mm:ss');
      }

      const compressedIds = lzstring.compressToBase64(JSON.stringify({ perms: elements.value, groups: groupElements.value, subGroups: subGroupElements.value })) ?? '';

      if (!compressedIds) {
        await showToastError('GENERIC_ERROR');
        isRequestLoading.value = false;
        return;
      }

      // Insert support user
      const request: ShopsUsersInputItem = {
        id_ps_customer: UserState.user.idPsCustomer,
        email: SUPPORT_SHOPIMIND_EMAIL,
        active: true,
        date_creation: moment().format('YYYY-MM-DD, HH:mm:ss'),
        shops: field.selectedShops,
      };
      const shopsUserInput: ShopsUsers[] = [];
      shopsUserInput.push(request);

      const result = await InsertShopsUsers(shopsUserInput);
      if (result.err !== '') {
        await showToastError('GENERIC_ERROR');
        isRequestLoading.value = false;
        return;
      }

      // Add dashboard and stats pages
      const pagesToLink: UsersPagesSubscriptionsInputItem[] = [];

      let priority = 0;
      if (dashboard.value && dashboard.value !== 0) {
        const shopsUsersPageInput: UsersPagesSubscriptionsInputItem = {
          id_users_pages_configuration: dashboard.value,
          id_user: parseInt(result.id.toString(), 10),
          user_type: UserTypeEnum.USER,
          type: StatsType.DASHBOARD,
          priority,
        };

        pagesToLink.push(shopsUsersPageInput);
      }

      if (statsPages.value && statsPages.value.length) {
        statsPages.value.forEach((item: DropdownOption) => {
          priority += 1;

          const shopsUsersPageInput: UsersPagesSubscriptionsInputItem = {
            id_user: parseInt(result.id.toString(), 10),
            id_users_pages_configuration: parseInt(item.toString(), 10),
            user_type: UserTypeEnum.USER,
            type: StatsType.STATS,
            priority,
          };

          pagesToLink.push(shopsUsersPageInput);
        });
      }

      const supportDashboardPages = await getUserStatsPages(parseInt(result.id.toString(), 10), UserTypeEnum.USER, StatsType.DASHBOARD);
      const supportStatPages = await getUserStatsPages(parseInt(result.id.toString(), 10), UserTypeEnum.USER, StatsType.STATS);

      const oldSupportStatsPagesListToRemove = new Set<number>();

      // Remove old linked stats report and dashboard granted by the admin of the shop and all of its subusers to support
      // eslint-disable-next-line no-restricted-syntax
      for (const shopUser of shopUsers.value) {
        const userType = UserState.activeShop && UserState.activeShop.idUser === shopUser ? UserTypeEnum.ADMIN : UserTypeEnum.USER;
        // eslint-disable-next-line no-await-in-loop
        const shopUserDashboardPages = await getUserStatsPages(shopUser, userType, StatsType.DASHBOARD, UserState.activeShop?.idUser);

        let shopUserStatsPages: any;
        if (userType === UserTypeEnum.ADMIN) {
          // eslint-disable-next-line no-await-in-loop
          shopUserStatsPages = await getUsersPagesConfiguration(StatsType.STATS, shopUser.toString());
        } else {
          // eslint-disable-next-line no-await-in-loop
          shopUserStatsPages = await getUserStatsPages(shopUser, userType, StatsType.STATS, UserState.activeShop?.idUser);
        }

        shopUserDashboardPages.items.forEach((item) => {
          if (supportDashboardPages.items.filter((supportDashboardPage) => supportDashboardPage.id_users_pages_configuration === item.id_users_pages_configuration).length) {
            oldSupportStatsPagesListToRemove.add(item.id_users_pages_configuration);
          }
        });
        shopUserStatsPages.items.forEach((item: any) => {
          if (supportDashboardPages.items.filter((supportDashboardPage) => supportDashboardPage.id_users_pages_configuration === item.id_users_pages_configuration).length) {
            oldSupportStatsPagesListToRemove.add(item.id_users_pages_configuration);
          }
          if (supportStatPages.items.filter((supportStatPage) => supportStatPage.id_users_pages_configuration === item.id_users_pages_configuration).length) {
            oldSupportStatsPagesListToRemove.add(item.id_users_pages_configuration);
          }
        });
      }
      if (oldSupportStatsPagesListToRemove.size) {
        const usersPagesSubscriptionsDelete: UsersPagesSubscriptionsDeleteInput[] = Array.from(oldSupportStatsPagesListToRemove).map((page) => ({
          id_users_pages_configuration: page,
          id_user: parseInt(result.id.toString(), 10),
          user_type: UserTypeEnum.USER,
        }));
        await DeleteUsersPagesSubscriptions(usersPagesSubscriptionsDelete);
      }

      if (pagesToLink.length) {
        await InsertUsersPagesSubscriptions(pagesToLink);
      }

      // Add permissions
      const UsersPermissionsEntity: UsersPermissionsUpsertInputItem = {
        shop_ids: field.selectedShops,
        id_user: result.id,
        id_ps_customer: UserState.user.idPsCustomer,
        permissions: compressedIds,
        period: expirationDate ? JSON.stringify({ value: field.duration, unit: TimeUnits.DAY }) : null,
        expiry_date: expirationDate,
      };

      const {
        err,
      } = await UpsertUsersPermissions([UsersPermissionsEntity]);

      if (err !== '') {
        await showToastError('GENERIC_ERROR');
        isRequestLoading.value = false;
        return;
      }

      isLoading.value = false;

      await showToastSuccess('savedSuccessful');

      isRequestLoading.value = false;

      store.commit('general/setIsOpenAccessToSupportModalVisible', false);
      resetState();

      // TODO Reactivate when symfony will be published
      // await refreshTokenLogin(UserState.activeShop?.id ?? 0);
    };

    return {
      t,
      isOpenAccessToSupportModalVisible,
      componentFieldErrorsKey,
      error,
      field,
      shopOptions,
      durationOptions,
      isLoading,
      isRequestLoading,

      handleHideDialog,
      handleValidate,
      onShow,
    };
  },
});
</script>
