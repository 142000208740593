
import {
  computed, defineComponent,
  Ref, SetupContext, ref,
} from 'vue';

import Tooltip from 'primevue/tooltip';

import StatsWidgetTabsRender from '@/components/stats-builder/widgets/render/StatsWidgetTabsRender.vue';
import StatsWidgetMenuRender from '@/components/stats-builder/widgets/render/StatsWidgetMenuRender.vue';
import StatsWidgetDataRender from '@/components/stats-builder/widgets/render/StatsWidgetDataRender.vue';
import StatsWidgetTitleRender from '@/components/stats-builder/widgets/render/StatsWidgetTitleRender.vue';
import { StatsBuilderSelectedWidget, StatsBuilderChoosedWidgetForDrag } from '@/types/stats-editor-types';
import { useStore } from '@/store';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'StatsBuilderColItem',

  components: {
    StatsWidgetDataRender,
    StatsWidgetMenuRender,
    StatsWidgetTabsRender,
    StatsWidgetTitleRender,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    element: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    rowId: {
      type: String,
      required: true,
    },

    columnData: {
      type: Object,
      required: true,
    },
  },

  emits: ['edit-widget', 'remove-widget'],

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();
    const store = useStore();
    const selectedWidgetState: Ref<StatsBuilderSelectedWidget|null> = computed(() => store.getters['statsEditor/getSelectedWidget']);
    const choosedWidgetForDrag = computed<StatsBuilderChoosedWidgetForDrag | null>(() => store.getters['statsEditor/getChoosedWidgetForDrag']);

    const hovered = ref(false);

    const isChoosedForDrag = computed(() => {
      if (!choosedWidgetForDrag.value) {
        return false;
      }
      return choosedWidgetForDrag.value.rowId === props.rowId
        && choosedWidgetForDrag.value.columnId === props.columnData.id && choosedWidgetForDrag.value.index === props.index && !choosedWidgetForDrag.value.panelData;
    });

    const editWidget = () => {
      emit('edit-widget', props.index);
    };

    const removeWidget = () => {
      emit('remove-widget', props.index);
    };

    const onMouseOver = () => {
      hovered.value = true;
    };
    const onMouseLeave = () => {
      hovered.value = false;
    };

    return {
      t,
      selectedWidgetState,
      hovered,
      isChoosedForDrag,

      editWidget,
      removeWidget,
      onMouseOver,
      onMouseLeave,
    };
  },

});
