import { ComponentData, ComponentMetadata, ErrorConfigForm } from '@/types/automated-scenarios';
import { i18n } from '@/i18n';
// eslint-disable-next-line import/no-cycle
import {
  AutomatedScenarioState as state,
  getOperatorChildByOutput,
  isRootOfTree,
} from '@/composables/AutomatedScenarios';
import { TypeCampaignEnum } from '@/types';

export interface DeclencheurStatusCommandData {
  label: string;
}

let translation: any;
(async () => {
  translation = await i18n;
})();

const DeclencheurStatusCommandMetadata: ComponentMetadata<DeclencheurStatusCommandData> = {
  Meta: {
    kind: 'declencheur',
    id: 'boxstatuscommand',
    label: 'automatedScenarios.triggers.statusCommand.label',
    icon: 'fa-bags-shopping',
    hasConfiguration: false,
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxsitepasstime', 'boxquitsite',
          'boxperiodiclaunching', 'boxscrollpage', 'boxstatuscommand',
          'boxcustomjs', 'registeronsite', 'subscribetonewsletter',
          'boxdisplayfreepopup', 'boxdisplayfreemodel', 'boxfiltre_1',
          'openemail', 'clicklink', 'boxvisitpage',
          'boxdelaismax',
        ],
      },
    },
    component: 'DeclencheurStatusCommand',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
  },
  Create({ label }: DeclencheurStatusCommandData = {
    label: 'automatedScenarios.triggers.statusCommand.label',
  }): ComponentData<DeclencheurStatusCommandData> {
    return {
      id: 'boxstatuscommand',
      form_name: 'boxstatuscommand',
      logo_box: 'fa-check-circle-o',
      label,
    };
  },
  async Validate(): Promise<ErrorConfigForm> {
    return {
      success: true,
    };
  },
  Outputs(data: DeclencheurStatusCommandData): number {
    return 1;
  },
  CanBeDeleted(operatorId: string): boolean|string {
    const isRoot = isRootOfTree(operatorId);

    if (isRoot) {
      const childOperator = getOperatorChildByOutput(operatorId, 'output_1');
      if (childOperator !== null && typeof (state.scenario.data.operators[childOperator]) !== undefined
        && state.scenario.data.operators[childOperator].properties.class !== 'declencheur') {
        return 'automatedScenarios.cant_remove_operator';
      }
    }

    return true;
  },
  Label(data: DeclencheurStatusCommandData): string {
    return translation.global.t('automatedScenarios.triggers.statusCommand.label');
  },
};

export default DeclencheurStatusCommandMetadata;
