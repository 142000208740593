
import {
  computed, defineComponent, PropType, Ref, ref, watchEffect,
} from 'vue';
import { useI18n } from 'vue-i18n';
import OverlayPanel from 'primevue/overlaypanel';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Slider from 'primevue/slider';
import {
  BoxShadowEnum, ColorFormatEnum, FieldConfig, ParserFieldObject, Property,
} from '@/types';
import BaseDropdown from '@/components/fields/partials/BaseDropdown.vue';
import CustomColorPicker from '@/components/fields/partials/CustomColorPicker.vue';
import ToggleDisplaySettings from '@/components/template-builder/fields/partials/ToggleDisplaySettings.vue';

export default defineComponent({
  name: 'BoxShadow',
  components: {
    ToggleDisplaySettings,
    BaseDropdown,
    Button,
    CustomColorPicker,
    InputText,
    OverlayPanel,
    Slider,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const configs = ref(props.configs);
    const boxShadowList = ref(Object.entries(BoxShadowEnum).map((boxShadow) => ({
      label: t(`templateBuilder.fields.shadows.${boxShadow[0].toLowerCase()}`), value: boxShadow[1],
    })));
    const overlayPanelRef = ref();
    const displayProperties = ref(!(!Object.prototype.hasOwnProperty.call(configs.value, 'displayToggle') || configs.value.displayToggle === true));
    const VALID_COLOR_REGEX_RULE = /^(#([\da-f]{3}){1,2}|(rgb|rgba)\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb|rgba)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\))$/i;
    const property: Ref<Property> = ref(props.parserValues.properties && props.parserValues.properties[0]);
    const colorFormat: Ref<ColorFormatEnum> = ref(ColorFormatEnum.HEX);
    const selectedBoxShadowStyle = ref(props.configs.options.defaultBoxShadowStyle);
    const selectedColor: Ref<string> = ref(props.configs.options.defaultColor);
    const selectedBlur: Ref<number> = ref(props.configs.options.defaultBlur);
    const minValue = ref(0);
    const maxValue = ref(100);
    const sliderStep = ref(1);

    const displayField = computed(() => selectedBoxShadowStyle.value !== BoxShadowEnum.NONE);

    watchEffect(() => {
      if (property.value.value) {
        const matchedGroups = /^(rgb\(.+\)) (.+px .+px) (.+)px 0px$/g.exec(property.value.value);
        if (matchedGroups && matchedGroups[1] && matchedGroups[2] && matchedGroups[3]) {
          // eslint-disable-next-line
          selectedColor.value = matchedGroups[1];
          // eslint-disable-next-line
          selectedBoxShadowStyle.value = matchedGroups[2];
          // eslint-disable-next-line
          selectedBlur.value = Number(matchedGroups[3]);
        }
      }
    });

    const toggleColorPicker = (event: MouseEvent) => {
      overlayPanelRef.value.toggle(event);
    };
    const emitValuesChanges = () => {
      property.value.value = `${selectedBoxShadowStyle.value} ${selectedBlur.value}px 0px ${selectedColor.value}`;
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: [property.value],
      });
    };
    const changeColor = (color: string) => {
      selectedColor.value = color;
      emitValuesChanges();
    };
    const isValidColor = (color: string) => VALID_COLOR_REGEX_RULE.test(color);
    const filterInputColor = () => {
      if (isValidColor(selectedColor.value)) {
        emitValuesChanges();
      }
    };

    return {
      displayProperties,
      boxShadowList,
      selectedBoxShadowStyle,
      selectedColor,
      overlayPanelRef,
      colorFormat,
      selectedBlur,
      toggleColorPicker,
      changeColor,
      filterInputColor,
      emitValuesChanges,
      minValue,
      maxValue,
      sliderStep,
      displayField,
      t,
    };
  },
});
