interface String {
  replaceArray(find: string[], replace: string[]): string;
  ucFirst(): string;
}

// eslint-disable-next-line no-extend-native,func-names
String.prototype.replaceArray = function (find, replace) {
  let string = String(this);
  find.forEach((f, i) => {
    const replaceString = replace[i] ?? '';
    string = string.replace(new RegExp(f, 'ig'), replaceString);
  });
  return string;
};

// eslint-disable-next-line no-extend-native,func-names
String.prototype.ucFirst = function () {
  const word = String(this);
  return word.charAt(0)
    .toUpperCase() + word.slice(1);
};
