import { ComponentNameEnum, DropdownOption } from '@/types';

const getWysiwygEditorFieldConfig = (defaultValue = '', variablesList: DropdownOption[]) => ({
  componentName: ComponentNameEnum.WYSIWYG_EDITOR,
  options: {
    defaultValue,
    variablesList,
  },
});

export default getWysiwygEditorFieldConfig;
