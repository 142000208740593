<template>
  <EditorModal
    :title="t('automatedScenarios.workflowSelector.title')"
    :show-close-button="true"
    @on-close-modal="closeModal"
  >
    <template
      #content
    >
      <div
        class="p-4"
        style="text-align: center;"
      >
        <div
          class="mt-3"
          style="text-align: center"
        >
          <Loader v-if="isListLoading" />
          <div
            v-else-if="list.length"
            class="flex grid"
          >
            <template
              v-for="record of list"
              :key="record.id_campaign_bulk_base"
            >
              <div
                class="lg:col-3 md:col-4 sm:col-6 mb-3 p-4 my-fadein"
              >
                <div>
                  <div
                    class="workflow_title"
                    style="height: 50px;"
                  >
                    <b>
                      {{ record.name }}
                    </b>
                  </div>
                  <div
                    class="workflow_legend"
                    style="margin-bottom: 15px;height: 65px;margin-right: 5px;margin-left: 5px;"
                  >
                    <small>
                      {{ record.legend }}
                    </small>
                  </div>
                </div>
                <div
                  v-if="record.images_templates !== null"
                  class="template_preview_container"
                  :style="'background-image:url('+ record.images_templates[0] +');'"
                />
                <div
                  v-else
                  class="template_preview_container"
                />
                <div class="action-buttons">
                  <Button
                    v-if="record.categories !== MarketingWorkflowsCategoryEnum.EMPTY"
                    :label="t('automatedScenarios.workflowSelector.actions.preview')"
                    style="float:left"
                    class="p-button-sm p-button-sm p-button-secondary"
                    @click="handlePreview(record.id_campaign_bulk_base)"
                  />
                  <Button
                    :label="t('automatedScenarios.workflowSelector.actions.choose')"
                    :style="record.categories !== MarketingWorkflowsCategoryEnum.EMPTY ? 'float: right;' : ''"
                    class="p-button-sm p-button-success"
                    @click="handleChoose(record.id_campaign_bulk_base)"
                  />
                </div>
              </div>
            </template>
          </div>
          <div
            v-else
            class="flex justify-content-between"
          >
            <span>{{ t('automatedScenarios.workflowSelector.emptyList') }}</span>
          </div>
        </div>
      </div>
    </template>
  </EditorModal>
  <PreviewModal
    v-model:visible="displayPreviewModal"
    :images="images"
    @on-close-preview-modal="handleClosePreview"
  />
</template>

<script lang="ts">
import Button from 'primevue/button';
import {
  defineComponent, onMounted, Ref, ref, SetupContext,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { MarketingWorkflowsCategoryEnum, ToolbarItemsModel, TypeCampaignEnum } from '@/types';
import EditorModal from '@/components/modals/EditorModal.vue';
import {
  CampaignsBulkBase, CampaignsBulkDuplicateInputItem,
} from '@/types/generated-types/graphql';
import Loader from '@/components/layout/Loader.vue';
import { asInt, showToastError, showToastSuccess } from '@/helpers';
import { UserState } from '@/composables/User';
import { activateCampaign, setRefreshScenariosList } from '@/composables/AutomatedScenarios';
import PreviewModal from '@/components/modals/PreviewModal.vue';
import { duplicateBulkCampaign, fetchBaseCampaignsBulk } from '@/composables/workflows/CampaignBulk';
import { store } from '@/store';
import { getNowFormattedByTimezone } from '@/helpers/Date';

export default defineComponent({
  name: 'CampaignsBulkBaseSelector',

  components: {
    PreviewModal,
    Loader,
    EditorModal,
    Button,
  },

  emits: {
    'on-close-dialog': null,
  },

  setup(props: { }, context: SetupContext) {
    const { t } = useI18n();
    const idActiveShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
    const isListLoading = ref(false);
    const list: Ref<CampaignsBulkBase[]> = ref([]);
    const displayPreviewModal = ref(false);
    const images = ref([{}]);

    const closeModal = () => {
      context.emit('on-close-dialog');
    };

    const handleChoose = (idCampaignBase: number) => {
      store.commit('general/showTheSpinner');

      const name = `${t('campaignPrefix')} - ${getNowFormattedByTimezone()}`;

      const workflowToDuplicateInput: CampaignsBulkDuplicateInputItem = {
        id_campaign_bulk: idCampaignBase,
        id_shop_destination: idActiveShop.value,
        base_campaign: 1,
        name,
      };

      duplicateBulkCampaign(workflowToDuplicateInput).then((result) => {
        if (result.success && result.id) {
          const id = asInt(result.id);
          showToastSuccess(t('automatedScenarios.create_action.success'));
          setRefreshScenariosList(true);
          activateCampaign(id, TypeCampaignEnum.BULK).then(() => {
            context.emit('on-close-dialog');
            store.commit('general/hideTheSpinner');
          });
        } else {
          showToastError(t('automatedScenarios.duplicate.error'));
          store.commit('general/hideTheSpinner');
        }
      }).catch(() => {
        showToastError(t('automatedScenarios.duplicate.error'));
        store.commit('general/hideTheSpinner');
      });
    };

    const handlePreview = (idCampaignBase: number) => {
      const wfToPreview: CampaignsBulkBase[] = list.value.filter((baseWorkflow) => baseWorkflow.id_campaign_bulk_base === idCampaignBase);

      images.value = [];
      if (wfToPreview[0].images_templates !== null && wfToPreview[0].images_templates.length) {
        wfToPreview[0].images_templates.forEach((image) => {
          images.value.push({
            itemImageSrc: image,
            thumbnailImageSrc: image,
            alt: wfToPreview[0].legend,
            title: wfToPreview[0].name,
          });
        });
      }

      displayPreviewModal.value = true;
    };

    const handleClosePreview = () => {
      displayPreviewModal.value = false;
    };

    onMounted(async () => {
      isListLoading.value = true;
      const result = await fetchBaseCampaignsBulk();
      if (result) {
        list.value = result;
        isListLoading.value = false;
      }
    });

    return {
      t,
      list,
      images,
      MarketingWorkflowsCategoryEnum,
      displayPreviewModal,
      isListLoading,
      closeModal,
      handleChoose,
      handleClosePreview,
      handlePreview,
    };
  },
});
</script>

<style scoped lang="scss">
@keyframes my-fadein {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes my-fadeout {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}

.my-fadein {
  animation: my-fadein 400ms linear;
}

.my-fadeout {
  animation: my-fadeout 400ms linear;
}

.template_preview_container {
  width:100%;
  background-size: 100% auto;
  height:450px;
  text-align: center;
  padding: 2em;
  -webkit-flex:0 0 16%;
  -ms-flex: 0 0 16%;
  flex: 0 0 16%;
  background-position: center top;
  &:hover {
    webkit-animation: UPANDDOWN 10s infinite; /* Safari 4+ */
    -moz-animation:    UPANDDOWN 10s infinite; /* Fx 5+ */
    -o-animation:      UPANDDOWN 10s infinite; /* Opera 12+ */
    animation:         UPANDDOWN 10s infinite; /* IE 10+, Fx 29+ */
  }
}
</style>
