
import {
  reactive, ref, defineComponent, SetupContext, PropType,
} from 'vue';
import MultiSelect from 'primevue/multiselect';
import InputText from 'primevue/inputtext';
import { countries, languages } from '@/types/country-language-options';
import { SpmTableFilter } from '@/types';
import { OperatorType } from '@/types/generated-types/graphql';
import Calendar from 'primevue/calendar';
import moment from 'moment';
import Message from 'primevue/message';
import { useI18n } from 'vue-i18n';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';

export default defineComponent({
  name: 'SearchCustomers',
  components: {
    MultiSelect,
    InputText,
    Calendar,
    Message,
    SpmButton,
    SpmOverlayPanel,
  },

  props: {
    idShopList: {
      type: String,
      required: false,
      default: '',
    },

    originalFilters: {
      type: Array as PropType<SpmTableFilter[]>,
      required: true,
    },

    type: {
      type: String,
      required: false,
      default: '',
    },

  },

  emits: {
    'search-customers': Array,
    'close-panel': Boolean,
  },

  setup(props: {idShopList: string; originalFilters: SpmTableFilter[]; type: string}, { emit }: SetupContext) {
    const { t } = useI18n();
    const persistentFilter = ref<SpmTableFilter[]>([]);
    const errMessage = ref('');
    const todaysDate = new Date();
    const originalFilters = ref<SpmTableFilter[]>(props.originalFilters);
    const reinitialise = ref(false);

    interface Values{
      code: string| number;
      label: string;
      icon: string;
    }

    interface AllFilters {
      gender: Values[];
      last_name: string;
      first_name: string;
      email: string;
      newsletter: Values[];
      birthday_min: string;
      birthday_max: string;
      countries: Values[];
      languages: Values[];
    }

    const filters = reactive<AllFilters>({
      gender: [],
      last_name: '',
      first_name: '',
      email: '',
      newsletter: [],
      birthday_min: '',
      birthday_max: '',
      countries: [],
      languages: [],
    });

    const genders = ref([
      { label: t('myLists.searchComponent.man'), code: 1, icon: 'fa fa-mars' },
      { label: t('myLists.searchComponent.woman'), code: 2, icon: 'fa fa-venus' },
      { label: t('myLists.searchComponent.unknown'), code: 0, icon: 'fa fa-neuter' },
    ]);

    const clientTypeOptions = ref([
      { label: t('myLists.searchComponent.notRegistered.text'), code: 0, icon: 'fa fa-registered' },
      { label: t('myLists.searchComponent.optIn.text'), code: 1, icon: 'fa fa-check' },
      { label: t('myLists.searchComponent.doubleOptIn.text'), code: 2, icon: 'fa fa-check-double' },
      { label: t('myLists.searchComponent.unsubscribed.text'), code: 100, icon: 'fa fa-neuter' },
    ]);

    const resetErrorMessage = () => {
      errMessage.value = '';
    };

    const initAllFilters = () => {
      filters.gender = [];
      filters.last_name = '';
      filters.first_name = '';
      filters.email = '';
      filters.newsletter = [];
      filters.birthday_min = '';
      filters.birthday_max = '';
      filters.countries = [];
      filters.languages = [];
    };

    const showReInit = () => {
      persistentFilter.value = originalFilters.value;
      reinitialise.value = !reinitialise.value;
      emit('search-customers', originalFilters);
      initAllFilters();
    };

    const closePanel = ref(false);

    const searchCustomers = () => {
      closePanel.value = true;
      const filterCopy: SpmTableFilter[] = [];

      if (filters.gender.length > 0) {
        const gender: SpmTableFilter = {
          value: filters.gender.map((genderStr) => genderStr.code).join(','),
          operator: OperatorType.In,
          field: 'sc.gender',
        };
        filterCopy.push(gender);
      }

      if (filters.last_name) {
        const lastName: SpmTableFilter = {
          value: `%${filters.last_name}%`,
          operator: OperatorType.Like,
          field: 'sc.last_name',
        };
        filterCopy.push(lastName);
      }

      if (filters.first_name) {
        const firstName: SpmTableFilter = {
          value: `%${filters.first_name}%`,
          operator: OperatorType.Like,
          field: 'sc.first_name',
        };
        filterCopy.push(firstName);
      }

      if (filters.email) {
        const email: SpmTableFilter = {
          value: filters.email,
          operator: OperatorType.Equals,
          field: 'sc.email',
        };
        filterCopy.push(email);
      }

      if (filters.newsletter.length > 0) {
        const containsOnlyUnsubscribed = filters.newsletter.find((filter) => filter.code === 100) !== undefined && filters.newsletter.length === 1;
        if (containsOnlyUnsubscribed) {
          const unsubType: SpmTableFilter[] = [
            {
              value: '1',
              operator: OperatorType.Ampersand,
              field: 'sc.unsub_type',
              operation: 'or',
            },
            {
              value: '4',
              operator: OperatorType.Ampersand,
              field: 'sc.unsub_type',
              operation: 'or',
            },
            {
              value: '16',
              operator: OperatorType.Ampersand,
              field: 'sc.unsub_type',
              operation: 'or',
            },
          ];
          filterCopy.push(...unsubType);
        } else {
          const containsUnsubscribed = filters.newsletter.find((filter) => filter.code === 100) !== undefined;
          if (containsUnsubscribed) {
            const newsletter: SpmTableFilter[] = [
              {
                value: filters.newsletter.filter((newsletterStr) => newsletterStr.code !== 100).map((item) => item.code).join(','),
                operator: OperatorType.In,
                field: 'sc.newsletter',
              },
            ];
            filterCopy.push(...newsletter);
          } else {
            const newsletter: SpmTableFilter[] = [
              {
                value: filters.newsletter.filter((newsletterStr) => newsletterStr.code !== 100).map((item) => item.code).join(','),
                operator: OperatorType.In,
                field: 'sc.newsletter',
              },
              {
                value: '1',
                operator: OperatorType.NotEqualsAmpersand,
                field: 'sc.unsub_type',
              },
              {
                value: '4',
                operator: OperatorType.NotEqualsAmpersand,
                field: 'sc.unsub_type',
              },
              {
                value: '16',
                operator: OperatorType.NotEqualsAmpersand,
                field: 'sc.unsub_type',
              },
            ];
            filterCopy.push(...newsletter);
          }
        }
      }

      if (filters.birthday_min) {
        const birthday_min: SpmTableFilter = {
          value: moment(filters.birthday_min).format('YYYY-MM-DD'),
          operator: OperatorType.GreaterThan,
          field: 'sc.birthday_min',
        };
        filterCopy.push(birthday_min);
      }

      if (filters.birthday_max) {
        const birthday_min: SpmTableFilter = {
          value: moment(filters.birthday_max).format('YYYY-MM-DD'),
          operator: OperatorType.LessThan,
          field: 'sc.birthday_max',
        };

        filterCopy.push(birthday_min);
      }

      if (filters.birthday_min > filters.birthday_max) {
        errMessage.value = 'BirthDay cannot be less';
      }

      if (filters.countries.length > 0) {
        const country: SpmTableFilter = {
          value: filters.countries.map((countryStr) => countryStr.code).join(','),
          operator: OperatorType.In,
          field: 'sc.country',
        };
        filterCopy.push(country);
      }

      if (filters.languages.length > 0) {
        const lang: SpmTableFilter = {
          value: filters.languages.map((languageStr) => languageStr.code).join(','),
          operator: OperatorType.In,
          field: 'sc.lang',
        };
        filterCopy.push(lang);
      }

      if (filterCopy.length === 0) {
        emit('search-customers', originalFilters);
      } else {
        if (props.type) {
          filterCopy.push({
            value: props.type,
            operator: OperatorType.Equals,
            field: 'sc.type',
          });
        }
        const operationType: SpmTableFilter = {
          value: 'search',
          operator: OperatorType.Equals,
          field: 'list_type',
        };
        filterCopy.push(operationType);

        persistentFilter.value = [...originalFilters.value, ...filterCopy];
        emit('search-customers', persistentFilter);
      }
      reinitialise.value = true;
    };

    return {
      t,
      genders,
      clientTypeOptions,
      countries,
      languages,
      filters,
      searchCustomers,
      errMessage,
      todaysDate,
      resetErrorMessage,
      showReInit,
      reinitialise,
      closePanel,
    };
  },
});
