<template>
  <label class="block mb-2">
    {{ t(title) }}
  </label>
  <div class="flex align-items-center">
    <MenuItemsNestedDraggable
      :items="list"
      :type="type"
    />
  </div>
  <div style="justify-content: flex-end; display: flex;">
    <Button
      :label="t('statsEditor.widgets.widgetMenu.buttonAddItem')"
      icon="far fa-plus"
      class="p-button-sm p-button-secondary"
      @click="addItem"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  SetupContext,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Button from 'primevue/button';
import MenuItemsNestedDraggable from '@/components/stats-builder/fields/MenuItemsNestedDraggable.vue';
import defaultLanguages from '@/configs/languages';

export default defineComponent({
  name: 'MenuItemsManage',

  components: {
    MenuItemsNestedDraggable,
    Button,
  },

  props: {
    list: {
      type: Array,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },
  },

  setup(props: { list: any[]; type: string }, context: SetupContext) {
    const { t } = useI18n();
    const addItem = () => {
      let newItem: any;
      if (props.type === 'menu') {
        newItem = {
          label: Object.assign(
            {},
            ...defaultLanguages.map((lang) => ({ [lang]: 'Menu' })),
          ),

          children: [],
          items: [],
        };
      } else {
        newItem = {
          name: 'Onglet',
          children: [],
        };
      }

      // eslint-disable-next-line vue/no-mutating-props
      props.list.push(newItem);
    };

    return {
      t,
      addItem,
    };
  },
});
</script>

<style lang="scss" scoped>
.spm-toolbar-item {
  border: 1px solid rgba(0,0,0,.125);
  width: 100%;
  height: 50px;
  padding: .75rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: none;

  &:first-child {
    border-top: 1px solid rgba(0,0,0,.125);
  }

  .sti-left {
    .move-item-action {
      cursor: move;
    }
    .name-item {
      margin-left: 10px;
      height: 2rem !important;
    }
  }

  .sti-right {
    .remove-item-action {
      cursor: pointer;
    }
  }

}
</style>
