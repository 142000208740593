<template>
  <SpmOverlayPanel
    class-trigger="flex align-items-center justify-content-center icon-action-app-header"
    class-header="flex justify-content-between flex-wrap p-3 spm-overlay-panel-notification-header"
    class-content="flex flex-column spm-overlay-panel-notification-content"
    class-footer="flex align-items-center justify-content-center spm-overlay-panel-notification-footer"
    min-panel-width="340px"
    max-panel-width="340px"
  >
    <template #trigger>
      <i
        v-if="notReadNotification"
        v-badge.danger=""
        class="fa-regular fa-bell"
      />
      <i
        v-else
        class="fa-regular fa-bell"
      />
    </template>
    <template #header>
      <div class="flex align-items-center justify-content-center">
        <h4>
          {{ t('header_menu.notifications.title') }}
        </h4>
      </div>
      <div
        v-if="notReadNotification"
        v-tooltip.right="t('header_menu.notifications.mark_all_as_read')"
        class="flex align-items-right justify-content-center"
        style="cursor: pointer;"
        @click="markAllAsRead"
      >
        <i class="fa-regular fa-envelope-open" />
      </div>
    </template>
    <div
      v-if="notificationItems.length === 0"
      class="absolute-center"
    >
      {{ t('header_menu.notifications.no_notifs') }}
    </div>
    <div
      v-for="(item, index) in notificationItems"
      :key="`${index}${notificationKey}`"
      class="flex justify-content-between justify-content-center item-notification"
      @click="goNotification"
    >
      <div class="item-notification-icon">
        <Avatar
          :icon="'fa-regular ' + (item.type === 'low' ? 'fa-gauge-min' : item.type === 'important' ? 'fa-gauge-max' : 'fa-gauge')"
          shape="circle"
          class="mr-2"
          :style="'background-color: ' + (item.type === 'low' ? '#10b981' : item.type === 'important' ? '#db5858' : '#f59e0b') + ';'"
          style="color: #ffffff; opacity: .7;"
        />
      </div>
      <div class="item-notification-text ml-1">
        <div
          class="mb-1"
          v-html="item.title"
        />
        <small>{{ item.date_created }}</small>
      </div>
      <div
        v-tooltip.bottom="item.is_read ? '' : t('header_menu.notifications.mark_as_read')"
        class="item-notification-mark-read ml-4"
        @click="markNotificationAsRead($event, item.id_shop_notification)"
      >
        <span
          class="badge-dot"
          :class="item.is_read ? 'read' : 'unread'"
        />
      </div>
    </div>
    <template #footer>
      <router-link
        :to="{ name: 'shop.MyNotifications' }"
      >
        <h5 style="color: #495057;">
          {{ t('header_menu.notifications.see_all') }}
        </h5>
      </router-link>
    </template>
  </SpmOverlayPanel>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, onMounted, Ref, ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import Avatar from 'primevue/avatar';
import { fetchNotifications, markNotificationsAsRead } from '@/composables/shop/Notifications';
import { UserState } from '@/composables/User';
import { useRouter } from 'vue-router';
import { ShopsNotificationsReadInputItem } from '@/types/generated-types/graphql';
import Tooltip from 'primevue/tooltip';

export default defineComponent({
  name: 'MyNotificationsMenu',

  components: {
    SpmOverlayPanel,
    Avatar,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    const { t } = useI18n();
    const shopId = UserState.activeShop?.id ?? 0;
    const router = useRouter();

    const notificationItems: Ref<any> = ref([]);
    const notificationKey = ref(0);

    const notReadNotification = computed(() => {
      if (notificationItems.value) {
        return notificationItems.value.some((item: any) => !item.is_read);
      }
      return false;
    });

    const markAllAsRead = async () => {
      const notificationsReadRecords: ShopsNotificationsReadInputItem[] = [];
      notificationItems.value.forEach((item: any) => {
        notificationsReadRecords.push({
          id_shop_notification: item.id_shop_notification,
          id_shop: shopId,
          date: new Date(),
        });
        // eslint-disable-next-line no-param-reassign
        item.is_read = true;
      });

      await markNotificationsAsRead(notificationsReadRecords);
    };

    const goNotification = async () => {
      await router.push({ name: 'shop.MyNotifications' });
    };

    const markNotificationAsRead = async (event: Event, id_shop_notification: number) => {
      event.stopPropagation();
      const notificationsReadRecord: ShopsNotificationsReadInputItem[] = [{
        id_shop_notification,
        id_shop: shopId,
        date: new Date(),
      }];

      await markNotificationsAsRead(notificationsReadRecord);
      notificationItems.value.forEach((item: any) => {
        if (item.id_shop_notification === id_shop_notification) {
          // eslint-disable-next-line no-param-reassign
          item.is_read = true;
        }
      });
    };

    const getNotificationsData = async () => {
      const notifications = await fetchNotifications({ shopId, paging: 0, limit: 5 });
      const notificationsRecords: any[] = [];
      if (notifications) {
        notifications.forEach((item: any) => {
          notificationsRecords.push({
            id_shop_notification: item.id_shop_notification,
            title: item.title,
            type: item.type,
            is_read: item.shopsNotificationsRead.length > 0,
            date_created: new Date(item.date).toLocaleString(),
          });
        });
        notificationKey.value += 1;
      }
      notificationItems.value = notificationsRecords;
    };

    onMounted(async () => {
      await getNotificationsData();
      setInterval(async () => getNotificationsData(), 10000);
    });

    return {
      t,
      notificationItems,
      goNotification,
      markAllAsRead,
      markNotificationAsRead,
      notReadNotification,
      notificationKey,
    };
  },
});
</script>

<style lang="scss">
.icon-action-app-header {
  .p-badge {
    min-width: 0.5rem;
    height: 0.5rem;
  }
}

.spm-overlay-panel-notification-content {
  overflow-y: auto;
  min-height: 10rem;
  max-height: 15rem;

  .item-notification {
    padding: 1rem;
    cursor: pointer;

    &:hover {
      background-color: #f8f9fa;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e9ecef;
    }

    .item-notification-icon {

    }

    .item-notification-text {
      div {
        font-size: .875rem;
      }

      small {
        font-size: 0.7rem;
      }
    }

    .item-notification-mark-read {
      .badge-dot {
        display: inline-flex;

        &.read {
          background-color: #d1d5db;
        }

        &.unread {
          background-color: #94c840;
        }
      }
    }
  }
}

.spm-overlay-panel-notification-footer {
  padding: .7rem 1rem;
}

.absolute-center {

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;

}
</style>
