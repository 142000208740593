import { ComponentNameEnum, BaseButtonFieldConfig } from '@/types';

const defaultValue = 'horizontal';
const property = 'spm_social_icons_';

const iconLayoutFieldConfig: BaseButtonFieldConfig = {
  componentName: ComponentNameEnum.BUTTON_GROUP,
  options: {
    [defaultValue]: {
      title: 'templateBuilder.fields.horizontal',
      iconClass: 'fa-arrows-h',
      property,
      defaultValue,
    },
    vertical: {
      title: 'templateBuilder.fields.vertical',
      iconClass: 'fa-arrows-v',
      property,
      defaultValue,
    },
  },
};

export default iconLayoutFieldConfig;
