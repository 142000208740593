
import {defineComponent, onMounted, PropType, ref} from 'vue';

export default defineComponent({
  name: 'ImageWithFallback',

  props: {
    alt: {
      type: String,
      required: false,
      default: '',
    },

    sources: {
      type: Array as PropType<string[]>,
      required: true,
      default: () => [],
    },

    placeholder: {
      type: String,
      required: false,
      default: '',
    },
  },

  setup(props: { sources: string[]; placeholder: string }) {
    const src = ref(props.placeholder);

    const tryNext = (index: number) => {
      const next = index + 1;
      if (next >= props.sources.length) {
        return;
      }

      const i = document.createElement('img');
      i.src = props.sources[next];
      i.onerror = () => tryNext(next);
      i.onload = () => {
        src.value = i.src;
      };
    };

    onMounted(() => tryNext(-1));

    return { src };
  },
});
