
import {
  computed, ComputedRef, defineComponent, PropType, Ref, ref,
} from 'vue';

import { useI18n } from 'vue-i18n';
import {
  FieldConfig,
  ParserFieldObject,
  Property,
} from '@/types';
import BaseLink from '@/components/fields/BaseLink.vue';
import { TemplateEditorState } from '@/composables/template-editor/TemplateEditor';

export default defineComponent({
  name: 'Link',

  components: {
    BaseLink,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();

    const mainProperties: Ref<Array<Property>> = ref(props.parserValues.properties);
    const hrefProperty: Ref<Property> = ref(mainProperties.value[0]);
    const originCall: ComputedRef<string> = computed(() => {
      let returnValue = 'templateBuilder';

      if (TemplateEditorState.template?.createdFromCampaign) {
        if ((new RegExp('_automation')).test(TemplateEditorState.template?.createdFromCampaign)) {
          returnValue = 'automation';
        } else {
          returnValue = 'newsletter';
        }
      }
      return returnValue;
    });
    const idCampaign = computed(() => TemplateEditorState.template?.idCampaign ?? null);

    const handleChange = () => {
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: [hrefProperty.value],
      });
    };

    return {
      t,
      hrefProperty,
      originCall,
      idCampaign,
      handleChange,
    };
  },
});
