import { BaseFieldConfig, ComponentNameEnum } from '@/types';

const getDisplayFieldConfig = (property = '', defaultValue = '', disabledOption = 'false', activeOption = 'true'): BaseFieldConfig => ({
  componentName: ComponentNameEnum.BUTTON_GROUP,
  options: {
    [disabledOption]: {
      title: 'no',
      iconClass: 'fa-times-circle',
      property,
      defaultValue,
    },
    [activeOption]: {
      title: 'yes',
      iconClass: 'fa-check-circle',
      property,
      defaultValue,
    },
  },
});
export default getDisplayFieldConfig;
