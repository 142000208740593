import { ButtonFieldConfigOption, ComponentNameEnum } from '@/types';

const getIconsFieldConfig = (iconList: string[] = [], property = 'spm_icon-', defaultValue = '') => ({
  componentName: ComponentNameEnum.BUTTON_GROUP,
  options: iconList.reduce<Record<string, ButtonFieldConfigOption>>((acc, current) => ({
    ...acc,
    [current]: {
      title: '',
      iconClass: `spm_icon ${property}${current}`,
      property,
      defaultValue,
    },
  }), {}),
});

export default getIconsFieldConfig;
