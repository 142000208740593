<template>
  <div class="field-wrapper">
    <label class="block mb-2">
      {{ t('statsEditor.widgets.widgetTitle.title') }}
    </label>
    <LocalizedTextInput
      id="stats_widget_title"
      v-model="widgetName"
      :display-translate="true"
      type="text"
      class="w-full"
      :default-languages="defaultLanguages"
    />
    <FieldErrors
      :key="componentFieldErrorsKey"
      :errors="formValidationErrors"
      field="widgetName"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent, PropType, ref, Ref, SetupContext, watch, shallowRef,
} from 'vue';
import WidgetTitleMetadata, { WidgetTitleData } from '@/components/stats-builder/metadata/WidgetTitleMetadata';
import { useI18n } from 'vue-i18n';
import Tooltip from 'primevue/tooltip';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import defaultLanguages from '@/configs/languages';

export default defineComponent({
  name: 'StatsWidgetTitleConfig',
  components: {
    FieldErrors,
    LocalizedTextInput,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    modelValue: {
      type: Object as PropType<WidgetTitleData>,
      required: true,
      default() {
        return WidgetTitleMetadata.Create();
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: {
    modelValue: WidgetTitleData;
    formValidation: any;
  }, context: SetupContext) {
    const { t, locale } = useI18n();

    /* Listing to form errors */
    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    // widget configuration
    const widgetName: Ref<any> = shallowRef(props.modelValue.widgetName);
    if (typeof widgetName.value === 'string') {
      widgetName.value = Object.assign(
        {},
        ...defaultLanguages.map((lang) => ({ [lang]: widgetName.value })),
      );
    }

    watch(() => props.modelValue, () => {
      widgetName.value = props.modelValue.widgetName;
    });

    watch(widgetName, () => {
      let updatedWidgetName;
      if (typeof widgetName.value === 'string') {
        updatedWidgetName = {
          [locale.value]: widgetName.value,
        };
      } else {
        updatedWidgetName = { ...widgetName.value };
      }

      const updatedModelValue = {
        ...props.modelValue,
        widgetName: updatedWidgetName,
      };

      context.emit('update:modelValue', WidgetTitleMetadata.Create(updatedModelValue));
    });

    return {
      t,
      formValidationErrors,
      componentFieldErrorsKey,
      widgetName,
      defaultLanguages,
    };
  },
});
</script>

<style lang="scss">
.choose-type-graph-container {

  .list-type-graph {
    .type-graph-container {
      .type-graph {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 1px solid #6c757d;
        border-radius: 3px;
        padding: 1rem;
        background-color: #f1f5f9;

        &:hover:not(.selected, .disabled-type-graph) {
          background-color: #6c757d12;
          cursor: pointer;
        }

        &.selected {
          border: 1px solid #94c840;
          background-color: #94c84038;
        }

        &.disabled-type-graph {
          background-color: #e3e3e3;
          border: none;
        }

        i {
          font-size: 1.3rem !important;
        }
        span {
          font-size: 0.7rem !important;
          margin-top: 7px;
        }
      }
    }
  }
}
</style>
