import { featurePermissions } from '@/configs/permissions';
import axios, { AxiosResponse } from 'axios';
import { reactive, readonly } from 'vue';
import defaultLogo from '@/assets/images/logo-tc-black.svg';
// eslint-disable-next-line import/no-cycle
import {
  Maybe, OperatorType, ShopsLang, ShopsPlansUpdateInputItem, ShopsHasBundleActive, Bundles,
} from '@/types/generated-types/graphql';
// eslint-disable-next-line import/no-cycle
import { showToastError } from '@/helpers';
import { CountryCode } from 'libphonenumber-js';
// eslint-disable-next-line import/no-cycle
import { GetShopsConfigurationList } from '@/composables/shop/ShopsConfiguration';
// eslint-disable-next-line import/no-cycle
import { getLocalStorageElement, removeLocalStorageElement, setLocalStorageElement } from '@/helpers/LocalStorage';
// eslint-disable-next-line import/no-cycle
import { UserTypeEnum } from '@/types';
import * as lzstring from 'lz-string';
import { i18n } from '@/i18n';
import Gleap from 'gleap';
// eslint-disable-next-line import/no-cycle
import { getShopPlan, UpdateShopsPlans } from '@/composables/shop/shopsPlans';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import { checkSpmTag, getShopPendingRecurringCharges } from '@/composables/user/ShopifyConfig';
import crypto from 'crypto';
import { nestPost } from '@/composables/nestApi';
// eslint-disable-next-line import/no-cycle
import { getLastUnpaidBill } from '@/composables/shop/ShopsBills';
// eslint-disable-next-line import/no-cycle
import { List } from './GraphQL';

const localstorageSpmActiveShopId = 'spm_active_shop_id';

export interface Credentials {
  username: string;
  password: string;
}

export interface Lang {
  id: string;
  default: boolean;
}

export interface LinkedFacebookPage {
  pageId: string;
  pageToken: string;
  pagePicture: string;
}

export interface Shop {
  id: number;
  idUser: number;
  name: string;
  url: string;
  logo: string;
  currency: string;
  bundleVersion: number;
  currentVersion: string;
  solutionEcommerce: string;
  linkedFacebookPage: LinkedFacebookPage | null;
  idPsCustomer: number;
  permissions: string;
  expiryDate: string;
  period: string;
  timezone: string;
}

type ShopWithLangs = Shop & {
  langs: Lang[];
};

export interface User {
  id: number;
  idPsCustomer: number;
  firstName: string;
  lastName: string;
  societe?: string;
  vatNumber?: string;
  rue?: string;
  complement?: string;
  zipCode?: string;
  phoneMobile?: string;
  phoneFixe?: string;
  email: string;
  username: string;
  roles: Array<string>;
  shops: Array<ShopWithLangs>;
  lang: string;
  city?: string;
  country: CountryCode;
  userType: string;
  permissionString: string[];
}

export interface Status {
  user: User;
  isAuthenticated: boolean;
  noPermissions: boolean;
  changingShop: boolean;
}

type userState = Status & {
  activeOffer: Record<string, any> | null;
  activeShop: ShopWithLangs | null;
  hasOfferV3: boolean;
  offerV3: Record<string, any> | null;
  logos: string[];
};

const blankUser = {
  id: 0,
  idPsCustomer: 0,
  firstName: '',
  lastName: '',
  email: '',
  phoneMobile: '',
  username: '',
  roles: [],
  shops: [],
  lang: 'fr',
  country: 'FR' as CountryCode,
  userPermissions: [],
  permissions: '',
  expiryDate: '',
  period: '',
  userType: '',
  permissionString: [],
};

const defaultState = {
  user: blankUser,
  isAuthenticated: false,
  activeShop: null,
  activeOffer: null,
  hasOfferV3: false,
  offerV3: null,
  logos: [defaultLogo],
  noPermissions: false,
  changingShop: false,
};

const state = reactive<userState>(defaultState);

export const UserState = readonly<userState>(state);

const { locale, setLocaleMessage } = i18n.global;

function setLogos() {
  // TODO : Intégrer le logo de l'apporteur d'affaire pour la marque blanche
  // state.logos = state.activeShop === null ? defaultState.logos : [
  //   `https://proxima.shopimind.com/clients/logos/${state.activeShop.logo}`,
  //   `https://client.shopimind.com/clients/logos/${state.activeShop.logo},`,
  //   defaultLogo,
  // ];

  state.logos = defaultState.logos;
}

export function setPermissionsString() {
  state.user.permissionString = [];
  if (UserState.user.userType === UserTypeEnum.USER && state.activeShop) {
    const decompressedPermStr = lzstring.decompressFromBase64(state.activeShop.permissions);
    let decompressedPerm: {perms: string[]; subGroups: string[]; groups: string[]} = { perms: [], subGroups: [], groups: [] };

    if (decompressedPermStr) {
      decompressedPerm = JSON.parse(decompressedPermStr);
    }
    state.user.permissionString = decompressedPerm.perms ?? [];

    if (state.user.permissionString.length === 0) {
      state.noPermissions = true;
    }
  }
}

export async function Login(credentials: Credentials): Promise<AxiosResponse<void>> {
  return axios.create({ baseURL: '/api' }).post('/login', credentials, {
    // eslint-disable-next-line @typescript-eslint/camelcase
    params: { want_cookie: true },
  });
}

export async function Logout(): Promise<AxiosResponse<void>> {
  if (Gleap.isUserIdentified()) {
    Gleap.clearIdentity();
  }

  removeLocalStorageElement('spm_auth_current_shop_id', false, false);

  return axios.create({ baseURL: '/api' }).post('/logout');
}

export async function refreshToken(newShopId: number, username: string): Promise<AxiosResponse<void>> {
  state.changingShop = true;
  return axios.create({ baseURL: '/api', headers: { 'X-Shop-Id': newShopId }, params: { username } }).post('/refresh-token');
}

export function findPermission(permission: string): boolean {
  // if admin user, he/she has all permissions
  if (state.user.userType === UserTypeEnum.ADMIN) return true;

  if (!state.user.permissionString) return false;

  return state.user.permissionString.includes(permission);
}

export function findIfNoPermissionsGiven(permission: string): boolean {
  // if admin user, he/she has all permissions
  if (state.user.userType === UserTypeEnum.ADMIN) return true;

  if (!state.user.permissionString) return false;

  let permissionCount = 0;
  const permissionArray = permission.split(',');

  permissionArray.forEach((permissionStr) => {
    if (state.user.permissionString.indexOf(permissionStr) !== -1) {
      permissionCount += 1;
    }
  });
  return permissionCount !== 0;
}

export async function getFacebookLinkedPage(shopId: number): Promise<Maybe<LinkedFacebookPage>> {
  const fbLinkedPage = await GetShopsConfigurationList({
    shopId,
    key: 'fbLinkedPage',
    fields: 'value',
    limit: 0,
    offset: 0,
    lang: '',
  });

  if (fbLinkedPage && fbLinkedPage.length) {
    const linkedPage = fbLinkedPage[0].value !== '' ? JSON.parse(fbLinkedPage[0].value) : null;
    const fbLoggedUser = await GetShopsConfigurationList({
      shopId,
      key: 'fbLoggedUser',
      fields: 'value',
      limit: 0,
      offset: 0,
      lang: '',
    });

    if (fbLoggedUser && fbLoggedUser.length) {
      const loggedUser = fbLoggedUser[0].value !== '' ? JSON.parse(fbLoggedUser[0].value) : null;

      if (
        loggedUser
        && Object.prototype.hasOwnProperty.call(loggedUser, 'pages')
        && Object.prototype.hasOwnProperty.call(loggedUser.pages, linkedPage.pageId)
        && Object.prototype.hasOwnProperty.call(loggedUser.pages[linkedPage.pageId], 'picture')
      ) {
        const pictureUrl = loggedUser.pages[linkedPage.pageId].picture ?? '';
        return { ...linkedPage, pagePicture: pictureUrl.replace('http://', 'https://') };
      }
    }
  }

  return null;
}

export const resetUserState = () => {
  state.user = defaultState.user;
  state.isAuthenticated = defaultState.isAuthenticated;
  state.activeShop = defaultState.activeShop;
  state.activeOffer = null;
  state.user.userType = '';
  setLogos();
};

export async function getShopLangs(shopId: number): Promise<ShopsLang[]> {
  const res = await List<ShopsLang>(
    {
      name: 'ShopsLang',
      settings: {
        limit: 50,
        offset: 0,
        order: [],
        filter: [{ field: 'id_shop', operator: OperatorType.Equals, value: shopId }],
      },
      fields: ['lang', 'default'],
    },
  );
  if (res.err) {
    await showToastError('GENERIC_ERROR');
  } else {
    return res.items;
  }
  return [];
}

export async function setCurrentShopOnRedisForUser(id: number): Promise<boolean> {
  return axios.create({ baseURL: '/api' }).put('/set-current-shop', { idShop: id });
}

export const loadShopsLang = async (idShop: number) => {
  if (idShop && state.activeShop) {
    state.activeShop.langs = (await getShopLangs(idShop)).map((shopsLang: ShopsLang) => ({
      id: shopsLang.lang,
      default: shopsLang.default,
    }));
  }
};

const getToken = (params: any, hashString: string) => {
  let paramsCopy = params;

  let formattedString = '';
  const recursiveImplode = (obj: { [x: string]: any }) => {
    const keys = Object.keys(obj);
    keys.forEach((el) => {
      if (typeof obj[el] === 'object') {
        recursiveImplode(obj[el]);
      } else if (formattedString === '' && obj[el] !== '0') {
        formattedString += `${el};${obj[el]}`;
      } else if (obj[el] !== '0') {
        formattedString += `;${el};${obj[el]}`;
      }
    });
  };
  const ordered = Object.keys(paramsCopy)
    .sort()
    .reduce((ob: any, key: any) => {
      const obj = ob;
      obj[key] = paramsCopy[key];
      return obj;
    }, {});
  if (paramsCopy !== null) {
    paramsCopy = ordered;
    recursiveImplode(paramsCopy);
  }
  formattedString = formattedString.replace(/ /g, '');
  return crypto
    .createHmac('sha256', hashString)
    .update(formattedString)
    .digest('hex');
};

export async function refreshTokenLogin(shopId: number) {
  state.changingShop = true;
  const dataToAutoLogin = {
    shopId,
    userId: UserState.user.id,
    type: UserState.user.userType,
    date: new Date().toISOString(),
  };
  const dataToAutoLoginBase64 = Buffer.from(
    JSON.stringify(dataToAutoLogin),
  ).toString('base64');
  const dataToAutoLoginHmac = getToken(
    dataToAutoLogin,
    'myscretkeygood',
  );

  await Logout();
  const res = await axios.create({ baseURL: '/api' }).post('/token/alt-login', { data: dataToAutoLoginBase64, token: dataToAutoLoginHmac });

  return res ? res.data : null;
}

export async function SwitchActiveShop(id: number): Promise<boolean> {
  const shop = state.user.shops.find((s) => s.id === id);
  if (shop) {
    state.activeShop = shop;
    state.activeShop.linkedFacebookPage = await getFacebookLinkedPage(shop.id);

    // Get current shop offer
    const currentPlan = await getShopPlan(shop.id);

    if (currentPlan.item && currentPlan.item.plan) {
      state.activeOffer = currentPlan.item.is_active ? JSON.parse(currentPlan.item.plan) : {};
    } else {
      const { items, err } = await List<ShopsHasBundleActive>({
        name: 'ShopsHasBundleActive',
        settings: {
          offset: 0,
          limit: 1,
          order: [],
          filter: [
            { field: 'id_shop', value: shop.id, operator: OperatorType.Equals },
          ],
        },
        fields: ['id_shop', 'id_bundle_newsletter', 'bundle_options', 'max_newsletter', 'nb_sends_newsletter'],
      });

      if (!err && items && items.length > 0) {
        state.hasOfferV3 = true;
        [state.offerV3] = items;

        // If offer V3 with newsletter bundle, but no max_newsletter, we get the newsletters limit from bundle itself
        if (['2', '3'].includes(state.offerV3.bundle_options) && !state.offerV3.max_newsletter) {
          // Get max newsletter fron bundle
          const { items: itemsBundles } = await List<Bundles>({
            name: 'Bundles',
            settings: {
              offset: 0,
              limit: 1,
              order: [],
              filter: [
                { field: 'id_bundle', value: state.offerV3.id_bundle_newsletter, operator: OperatorType.Equals },
              ],
            },
            fields: ['number_email_newsletter_max'],
          });

          if (itemsBundles && itemsBundles.length) {
            state.offerV3.max_newsletter = itemsBundles[0].number_email_newsletter_max;
          }
        }
      }
    }

    if (state.user.userType === UserTypeEnum.USER) {
      // In case of a subuser, we change the active user id_ps_customer with the one linked to the shop
      state.user.idPsCustomer = shop.idPsCustomer;

      // Update permissions according to active shop
      setPermissionsString();
    }

    await setCurrentShopOnRedisForUser(id);

    await loadShopsLang(shop.id);
    setLocalStorageElement(localstorageSpmActiveShopId, String(id), false);
    setLocalStorageElement('spm_auth_current_shop_id', String(id), false, null, false);
    setLogos();

    if (Gleap.isUserIdentified()) {
      // In case the user is already authentified on Gleap, we clear his identity to set a new one based on the new ID shop
      Gleap.clearIdentity();
    }

    const customData: any = {
      idShop: state.activeShop.id,
      pendingRecurringCharge: 'false',
      missingTag: 'false',
      sendBlock: 'false',
      hasUnpaidBill: 'false',
    };

    if (state.activeShop?.solutionEcommerce === 'shopify') {
      const shopPendingRecurringCharges = await getShopPendingRecurringCharges(state.activeShop.id ?? 0);
      const checkSpmTagOnShopify = await checkSpmTag(state.activeShop.id ?? 0);

      customData.pendingRecurringCharge = shopPendingRecurringCharges.success && shopPendingRecurringCharges.charge ? 'true' : 'false';
      customData.missingTag = checkSpmTagOnShopify.success && !checkSpmTagOnShopify.tagIncluded ? 'true' : 'false';
    }

    // Check for unpaid bills
    const unpaidBill = await getLastUnpaidBill(state.activeShop.id ?? 0);

    if (unpaidBill && unpaidBill.total > 0) {
      customData.hasUnpaidBill = 'true';
    }

    const sendBlockConfig = await GetShopsConfigurationList({
      shopId: state.activeShop.id ?? 0,
      key: 'sendBlock',
      fields: 'value',
      limit: 0,
      offset: 0,
      lang: '',
    });
    customData.sendBlock = sendBlockConfig && sendBlockConfig.length && Number(sendBlockConfig[0].value) === 1 ? 'true' : 'false';

    Gleap.identify(state.user.id.toString(), {
      name: `${state.user.firstName} ${state.user.lastName}`,
      email: state.user.email,
      customData,
    });

    Gleap.trackEvent('trigger gleap banner');
    return true;
  }
  return false;
}

const getCurrentShopOnRedisForUser = async () => axios.create({ baseURL: '/api' }).get<any>('/get-current-shop');

const reloadTranslations = async (newLocale: string) => {
  locale.value = newLocale;

  try {
    // eslint-disable-next-line import/no-dynamic-require,global-require
    const msg = await require(`../i18n/${newLocale}.json`);
    setLocaleMessage(newLocale, msg);
  } catch (error) {
    console.error(`Failed to load translations for ${newLocale}: ${error}`);
  }
};

type MeResponse = Status & { error: string };

export const setChangingShop = (value: boolean): void => {
  state.changingShop = value;
};

export async function Me(switchShop: boolean, idShop?: number): Promise<void> {
  const res = await axios.create({ baseURL: '/api' }).get<MeResponse>('/me');
  if (!res.data.isAuthenticated) {
    state.user = defaultState.user;
    state.isAuthenticated = defaultState.isAuthenticated;
    state.activeShop = defaultState.activeShop;
    setLogos();
    return;
  }

  state.user = res.data.user;
  state.isAuthenticated = true;

  if (switchShop) {
    let activeShopId = 0;
    const localCache = getLocalStorageElement(localstorageSpmActiveShopId, false);
    if (localCache) {
      // If we found the current shop in local storage, we use that value
      activeShopId = parseInt(localCache, 10);
    } else {
      // We try to find on Redis
      const redisValue = await getCurrentShopOnRedisForUser();
      if (redisValue) {
        // If found on Redis, we use that value
        activeShopId = parseInt(redisValue.data, 10);
      } else {
        // Else we use the current active shop from state
        activeShopId = state.activeShop?.id ?? 0;
      }
    }
    setChangingShop(false);
    if (idShop) {
      await SwitchActiveShop(idShop);
    } else if (activeShopId && state.user.shops.find((s) => s.id === activeShopId)) {
      await SwitchActiveShop(activeShopId);
    } else if (res.data.user.shops.length) {
      await SwitchActiveShop(res.data.user.shops[0].id);
    }
  }

  if (state.user.lang !== locale) {
    await reloadTranslations(state.user.lang);
  }

  setLogos();
}

// gymnastics for get and set authentication properties for users
export const getIsAuthenticated = () => state.isAuthenticated;

export const HasNoPermissions = () => state.noPermissions;

export const resetPermissions = () => {
  state.noPermissions = false;
  state.isAuthenticated = false;
};
//

export const getShopDefaultLang = (): string => state.activeShop?.langs
  .filter((language: Lang) => language.default)
  .map((language: Lang) => (language.id))[0] ?? 'fr';

export const getUserLang = (): string => state.user.lang;

export const setUserLang = (newLang: string): void => {
  state.user.lang = newLang;
};

export const checkPlan = (plan: string, planLimit: string) => {
  if (state.activeOffer) {
    if (state.activeOffer[plan]) {
      if (state.activeOffer[planLimit]) {
        if (moment().isBefore(moment(state.activeOffer[planLimit], 'YYYY-MM-DD'))) {
          return state.activeOffer[plan];
        }
        return null;
      }
      return state.activeOffer[plan];
    }
    return null;
  }
  return null;
};

export const hasAccessToFeatures = (feature: any) => {
  let activePlan = null;

  if (state.activeOffer) {
    activePlan = checkPlan('trialPlan', 'trialPlanLimit') || checkPlan('forcedPlan', 'forcedPlanLimit') || state.activeOffer.currentPlan;
  }
  const featurePermission = featurePermissions[feature];
  if (featurePermission) {
    return {
      access: featurePermission.access.includes(activePlan),
      minimumPlan: featurePermission.access[0],
    };
  }
  return null;
};

// Temporarily setting user plan to gold
export const setPlan = async (
  plan: string,
  dateLimit: string,
  frequency: string,
  price: number,
  currency: string,
  smsPlan?: number,
  smsCountry?: string,
  discountV3?: number | null,
  discountV3Limit?: string | null,
) => {
  if (!state.activeOffer && state.hasOfferV3) {
    state.activeOffer = {};
  }
  if (state.activeOffer && state.activeShop) {
    if (plan !== state.activeOffer.currentPlan) {
      state.activeOffer.currentPlan = plan;
      state.activeOffer.currentPlanLimit = dateLimit;
      state.activeOffer.currentPlanFrequency = frequency;
      state.activeOffer.currentPlanPrice = price;
      state.activeOffer.currentPlanPriceCurrency = currency;
      state.activeOffer.nextPlan = plan;
    }
    state.activeOffer.forcedPlan = null;
    state.activeOffer.forcedPlanLimit = null;
    state.activeOffer.trialPlan = null;
    state.activeOffer.trialPlanLimit = null;

    if (smsPlan !== undefined || smsPlan !== null) {
      state.activeOffer.smsPlan = smsPlan;
      state.activeOffer.smsPlanCountry = smsCountry;
      state.activeOffer.smsPlanCurrency = currency;
    }

    if (discountV3 && discountV3 > 0) {
      state.activeOffer.discount = {
        plan: discountV3,
      };
      state.activeOffer.discountLimit = {
        plan: discountV3Limit,
      };
    }

    state.hasOfferV3 = false;
    state.offerV3 = null;

    const shopsPlansUpdateInput: ShopsPlansUpdateInputItem[] = [
      {
        id_shop: state.activeShop.id,
        plan: JSON.stringify(state.activeOffer),
        date_modification: moment().format('YYYYMMDDHHmmss'),
      },
    ];

    await UpdateShopsPlans(shopsPlansUpdateInput);
  }
};

export const tryPlan = async (plan: string) => {
  if (state.activeOffer && state.activeShop) {
    state.activeOffer.trialPlan = plan;
    state.activeOffer.trialPlanLimit = moment().add(14, 'days').format('YYYY-MM-DD');
    state.activeOffer.nextPlan = plan;
    if (!state.activeOffer.trials) {
      state.activeOffer.trials = {};
    }

    state.activeOffer.trials[plan] = true;
    const shopsPlansUpdateInput: ShopsPlansUpdateInputItem[] = [
      {
        id_shop: state.activeShop.id,
        plan: JSON.stringify(state.activeOffer),
        date_modification: moment().format('YYYYMMDDHHmmss'),
      },
    ];

    await UpdateShopsPlans(shopsPlansUpdateInput);
  }
};

export const downgradePlan = async (plan: string, currency: string, smsPlan?: number, smsCountry?: string) => {
  if (state.activeOffer && state.activeShop) {
    state.activeOffer.nextPlan = plan;

    if (smsPlan !== undefined || smsPlan !== null) {
      state.activeOffer.smsPlan = smsPlan;
      state.activeOffer.smsPlanCountry = smsCountry;
      state.activeOffer.smsPlanCurrency = currency;
    }

    const shopsPlansUpdateInput: ShopsPlansUpdateInputItem[] = [
      {
        id_shop: state.activeShop.id,
        plan: JSON.stringify(state.activeOffer),
        date_modification: moment().format('YYYYMMDDHHmmss'),
      },
    ];

    await UpdateShopsPlans(shopsPlansUpdateInput);
  }
};

/**
 * Unsubscribe current plan from plan summary
 * @param unsubscribe
 */
export const setCurrentPlanUnsubscription = async (unsubscribe: boolean) => {
  if (state.activeOffer && state.activeShop) {
    if (unsubscribe) {
      state.activeOffer.unsubscribe = unsubscribe;
    } else {
      delete state.activeOffer.unsubscribe;
    }

    const shopsPlansUpdateInput: ShopsPlansUpdateInputItem[] = [
      {
        id_shop: state.activeShop.id,
        plan: JSON.stringify(state.activeOffer),
        date_modification: moment().format('YYYYMMDDHHmmss'),
      },
    ];

    UpdateShopsPlans(shopsPlansUpdateInput);

    if (unsubscribe) {
      // Send email to customer service
      await nestPost('payments', '/unsubscribe-shop', {}, { idShop: state.activeShop.id });
    }
  }
};

export const cancelPlanSMS = async () => {
  if (state.activeOffer && state.activeShop) {
    state.activeOffer.smsPlan = null;
    state.activeOffer.smsPlanCurrency = null;
    state.activeOffer.smsPlanCountry = null;
    const shopsPlansUpdateInput: ShopsPlansUpdateInputItem[] = [
      {
        id_shop: state.activeShop.id,
        plan: JSON.stringify(state.activeOffer),
        date_modification: moment().format('YYYYMMDDHHmmss'),
      },
    ];

    await UpdateShopsPlans(shopsPlansUpdateInput);
  }
};

export const cancelTrial = async () => {
  if (state.activeOffer && state.activeShop) {
    delete state.activeOffer.trialPlan;
    delete state.activeOffer.trialPlanLimit;

    const shopsPlansUpdateInput: ShopsPlansUpdateInputItem[] = [
      {
        id_shop: state.activeShop.id,
        plan: JSON.stringify(state.activeOffer),
        date_modification: moment().format('YYYYMMDDHHmmss'),
      },
    ];

    await UpdateShopsPlans(shopsPlansUpdateInput);
  }
};
