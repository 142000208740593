
import Dialog from 'primevue/dialog';
import {
  defineComponent, onMounted, PropType, ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
  ShopsSendDomains,
  ShopsSendDomainsUpdateInput,
  ShopsSendDomainsUpdateInputItem,
} from '@/types/generated-types/graphql';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import SpmKeyCard from '@/components/spm-primevue/SpmKeyCard.vue';
import {
  CheckDomainValidForAlignment,
  getShopsDomainsCount,
  MakeDomainDefault,
  ValidateDomainByEmail,
  CheckDomainValidForTracking,
} from '@/composables/shop/ShopsSendDomains';
import { showToastError, showToastSuccess } from '@/helpers';
import Button from 'primevue/button';
import AutoComplete from 'primevue/autocomplete';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import { UserState } from '@/composables/User';
import * as sha1 from 'sha1';

export default defineComponent({
  name: 'DomainInstructions',
  components: {
    Dialog,
    AutoComplete,
    SpmButton,
    Button,
    Checkbox,
    InputText,
    SpmKeyCard,
  },

  props: {
    displayDialog: {
      type: Boolean,
      required: true,
      default: false,
    },

    data: {
      type: Object as PropType<ShopsSendDomains>,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },

    locale: {
      type: String,
      required: true,
    },
  },

  emits: ['on-close-dialog', 'hide-dialog'],

  setup(props, context) {
    const { t } = useI18n();
    const showDialog = ref(props.displayDialog);
    const alignmentDomain = ref(props.data.alignment_domain);
    const trackingDomain = ref(props.data.tracking_domain);
    const verifyText = ref(t('shop.my_domains.alignment.notAvailable'));
    const styleText = ref('');
    const showText = ref(false);
    const showDefaultInput = ref(false);
    const domainConfigValue = process.env.VUE_APP_DOMAIN_CONFIG_VALUE;
    const domainAuthSelector1 = process.env.VUE_APP_DOMAIN_AUTH_SELECTOR1;
    const domainAuthSelector2 = process.env.VUE_APP_DOMAIN_AUTH_SELECTOR2;
    const domainAuthKey1 = process.env.VUE_APP_DOMAIN_AUTH_KEY1;
    const domainAuthKey2 = process.env.VUE_APP_DOMAIN_AUTH_KEY2;
    const trackingDomainDefault = ref(props.data.tracking_domain_default_medias ?? false);
    const dialogWidth = ref('44vw');
    const shopsDomainCount = ref(0);
    const dnsValidationRecordsKey = process.env.VUE_APP_DNS_VALIDATION_RECORDS;
    const domainToConfirm = ref();
    const userId = UserState.user?.id;
    const header = ref();
    const validDomain = ref(false);
    const emptyDomain = ref(false);
    const disableValidate = ref(false);
    const redirectConfigDomain = process.env.VUE_APP_DOMAIN_LINKS_REDIRECT_CONFIGURATION_DOMAIN;
    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);

    const subDomainsList = [
      'marketing',
      'email',
      'communication',
      'newsletter',
      'contact',
      'bounce',
      'information',
      'news',
      'info',
      'support',
      'emailing',
      'notice',
      'emails',
      'infos',
    ];

    switch (props.type) {
      case 'authenticationInstructionNewMethod':
      case 'authenticationInstruction':
        header.value = t('shop.my_domains.authentication.header');
        dialogWidth.value = '41vw';
        break;
      case 'alignmentInstruction':
        header.value = t('shop.my_domains.alignment.header');
        break;
      case 'trackingDomainInstruction':
        header.value = t('shop.my_domains.tracking.header');
        break;
      case 'domainValidationDnsForm':
      case 'domainValidationForm':
        header.value = t('shop.my_domains.validation.header');
        dialogWidth.value = '30vw';
        break;
      default:
        break;
    }

    if (props.type === 'authenticationInstructionNewMethod' || props.type === 'authenticationInstruction') {
      dialogWidth.value = '41vw';
    }

    const suggestions = ref<string[]>([]);

    const verifyDomainForAlignment = async () => {
      if (alignmentDomain.value) {
        // todo: why is id_shop optional??
        const {
          data,
          err,
        } = await CheckDomainValidForAlignment(props.data.id_shop ?? 0, props.data.id_domain, alignmentDomain.value);

        if (err) {
          await showToastError(t('errorMessages.GENERIC_ERROR'));
        } else {
          verifyText.value = data ? t('shop.my_domains.alignment.available') : t('shop.my_domains.alignment.notAvailable');
          styleText.value = data ? 'font-weight: bold; color: green' : 'font-weight: bold; color: red';
          showText.value = true;
          showDefaultInput.value = !!data;
          validDomain.value = !!data;
        }
      }
    };

    const verifyDomainValidForTracking = async () => {
      if (trackingDomain.value) {
        // todo: why is id_shop optional??
        const {
          data,
          err,
        } = await CheckDomainValidForTracking(props.data.id_shop ?? 0, props.data.id_domain, trackingDomain.value);

        if (err) {
          await showToastError(t('errorMessages.GENERIC_ERROR'));
        } else {
          verifyText.value = data ? t('shop.my_domains.alignment.available') : t('shop.my_domains.alignment.notAvailable');
          styleText.value = data ? 'font-weight: bold; color: green' : 'font-weight: bold; color: red';
          showText.value = true;
          showDefaultInput.value = !!data;
          validDomain.value = !!data;
        }
      }
    };

    const validateByEmail = async () => {
      if (domainToConfirm.value === '' || domainToConfirm.value === undefined) {
        emptyDomain.value = true;
        setTimeout(() => {
          emptyDomain.value = false;
        }, 5000);
        return;
      }
      emptyDomain.value = false;
      disableValidate.value = true;
      const response = await ValidateDomainByEmail(idShop.value, props.data.id_domain, domainToConfirm.value);
      if (response && response.success) {
        await showToastSuccess(t('shop.my_domains.verify_success'));
        showDialog.value = false;
      } else await showToastError(t('shop.my_domains.saveError'));
      disableValidate.value = false;
      context.emit('hide-dialog');
    };

    const search = ({
      originalEvent,
      query,
    }: { originalEvent: InputEvent; query: string }) => {
      suggestions.value = subDomainsList.filter((subDomain) => subDomain.includes(query));
    };

    const updateDefaultDomain = async () => {
      if (validDomain.value) {
        const shopsSendDomainInput: ShopsSendDomainsUpdateInputItem = {
          id_domain: props.data.id_domain,
          tracking_domain_default_medias: !trackingDomainDefault.value,
          id_shop: idShop.value,
        };

        if (props.data.id_shop) {
          await MakeDomainDefault([shopsSendDomainInput]);
        }
      }
    };

    const handleClose = async () => {
      if (validDomain.value) {
        context.emit('on-close-dialog', true);
      } else {
        context.emit('on-close-dialog', false);
      }
    };

    onMounted(async () => {
      shopsDomainCount.value = await getShopsDomainsCount(props.data.id_shop ?? 0);
    });

    return {
      t,
      showDialog,
      alignmentDomain,
      verifyDomainValidForTracking,
      verifyDomainForAlignment,
      verifyText,
      styleText,
      handleClose,
      showText,
      domainConfigValue,
      suggestions,
      search,
      trackingDomain,
      domainAuthSelector1,
      domainAuthSelector2,
      domainAuthKey1,
      domainAuthKey2,
      dialogWidth,
      updateDefaultDomain,
      domainToConfirm,
      dnsValidationRecordsKey,
      validateByEmail,
      header,
      showDefaultInput,
      trackingDomainDefault,
      emptyDomain,
      disableValidate,
      redirectConfigDomain,
      validDomain,
    };
  },
});
