<template>
  <div class="field-wrapper">
    <div
      class="mb-2"
      style="display: flex; justify-content: space-between;"
    >
      <label
        class="block"
      >
        {{ t(label) }}
      </label>
      <ListCreatePanel
        v-if="canAddNewList"
      />
    </div>
    <Message
      v-if="messageInfo"
      class="text-justify"
      :closable="false"
    >
      {{ messageInfo }}
    </Message>
    <MultiSelect
      :model-value="selectedValues"
      :options="options"
      :show-toggle-all="false"
      :filter="true"
      option-label="label"
      data-key="value"
      @update:model-value="handleSelected"
    >
      <template #value="slotProps">
        <div>
          <span
            v-for="option of slotProps.value"
            :key="option.value"
          >
            {{ `${option.label}, ` }}
          </span>
        </div>
      </template>
      <template #option="slotProps">
        <div class="p-multiselect-option">
          <span>{{ slotProps.option.label }}</span>
        </div>
      </template>
    </MultiSelect>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  ref,
  Ref,
  SetupContext,
  computed,
  watch,
  onMounted,
} from 'vue';
import { useI18n } from 'vue-i18n';
import MultiSelect from 'primevue/multiselect';
import Message from 'primevue/message';
import ListCreatePanel from '@/components/mes-lists/ListCreatePanel.vue';

import { DropdownOption, StringMap } from '@/types';
import { asInt } from '@/helpers';

export default defineComponent({
  name: 'MultiDropdown',

  components: {
    MultiSelect,
    ListCreatePanel,
    Message,
  },

  props: {
    modelValue: {
      type: Object as PropType<StringMap>,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    options: {
      type: Object as PropType<DropdownOption[]>,
      required: true,
    },

    segmentId: {
      type: String,
      default: '',
    },

    newListId: {
      type: Number,
      default: -1,
    },

    convertValueToInt: {
      type: Boolean,
      required: false,
      default: false,
    },

    messageInfo: {
      type: String,
      required: false,
      default: ''
    }
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: StringMap; options: DropdownOption[]; segmentId: string; newListId: number, convertValueToInt: boolean }, { emit }: SetupContext) {
    const { t } = useI18n();
    const selectedValues: Ref<DropdownOption[]> = ref([]);
    const handleSelected = (value: DropdownOption[]) => {
      selectedValues.value = value;
      emit('update:modelValue', selectedValues.value.reduce((acc: StringMap, current: DropdownOption) => ({ ...acc, [current.value]: current.label }), {}));
    };

    const canAddNewList = computed(() => props.segmentId === 'filtermemberoflist');

    watch(() => props.options, () => {
      if (props.newListId > 0 && canAddNewList.value) {
        const matchingList = props.options.find((option: DropdownOption) => option.value === props.newListId);
        if (matchingList) {
          selectedValues.value.push(matchingList);
          emit('update:modelValue', selectedValues.value.reduce((acc: StringMap, current: DropdownOption) => ({ ...acc, [current.value]: current.label }), {}));
        }
      }
    }, { deep: true });

    onMounted(() => {
      selectedValues.value = Object.entries(props.modelValue).reduce((acc: DropdownOption[], item) => {
        const value = props.convertValueToInt ? asInt(item[0]) : item[0];

        const filteredOption = props.options.filter((option: DropdownOption) => option.value === value);

        if (filteredOption.length > 0) {
          acc.push({
            value,
            label: filteredOption[0].label
          })
        }

        return acc;
      }, []);
    });

    return {
      t,
      selectedValues,
      canAddNewList,

      handleSelected,
    };
  },
});
</script>

<style lang="scss" scoped>
.p-multiselect {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  border: 1px solid $heather;
  border-radius: $field-border-radius;
  width: 100%;
  min-height: 37px;
  font-size: $field-font-size;
  line-height: $field-line-height;
  padding: $field-padding $field-icon-up-down-width $field-padding $field-padding;
  cursor: pointer;

  &:hover {
    border-color: $brand-color-primary;
  }

  &:focus,
  &:focus-visible {
    box-shadow: $box-shadow;
    outline: 0 none;
  }
}
</style>
