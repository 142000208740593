
import { useI18n } from 'vue-i18n';
import {
  defineComponent,
  PropType, ref, SetupContext, watch,
} from 'vue';
import { asInt } from '@/helpers';
import PeriodSelector from '@/components/automated-scenarios/fields/PeriodSelector.vue';
import ButtonGroup from '@/components/automated-scenarios/fields/ButtonGroup.vue';
import BaseInformation from '@/components/fields/BaseInformation.vue';
import DeclencheurSitePassTimeMetaData, {
  DeclencheurSitePassTimeData,
} from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurSitePassTimeMetadata';
import { Period } from '@/types/automated-scenarios';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';

export default defineComponent({
  name: 'DeclencheurSitePassTime',
  components: {
    FieldErrors,
    BaseInformation,
    PeriodSelector,
    ButtonGroup,
  },

  props: {
    modelValue: {
      type: Object as PropType<DeclencheurSitePassTimeData>,
      required: true,
      default() {
        return DeclencheurSitePassTimeMetaData.Create();
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: DeclencheurSitePassTimeData; formValidation: any }, context: SetupContext) {
    const { t } = useI18n();
    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });
    const units = [
      { value: 'SECOND', label: t('automatedScenarios.fields.durations.second(s)') },
      { value: 'MINUTE', label: t('automatedScenarios.fields.durations.minute(s)') },
      { value: 'HOUR', label: t('automatedScenarios.fields.durations.hour(s)') },
    ];

    const session = ref(props.modelValue.multiplebox_global_session);
    const period = ref<Period>({
      unit: props.modelValue.time_unit_select.unit,
      value: asInt(props.modelValue.time_unit_select.value),
    });

    const update = () => context.emit('update:modelValue',
      DeclencheurSitePassTimeMetaData.Create({
        multiplebox_global_session: session.value,
        time_unit_select: {
          value: period.value.value,
          unit: period.value.unit,
        },
      }));

    const sessions = [
      { value: 'global', label: t('automatedScenarios.fields.periodicities.globally') },
      { value: 'session', label: t('automatedScenarios.fields.periodicities.onSession') },
    ];

    watch([period, session], update);

    return {
      formValidationErrors,
      componentFieldErrorsKey,
      units,
      period,
      sessions,
      session,
    };
  },
});
