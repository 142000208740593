<template>
  <SpmOverlayPanel
    class-trigger="flex align-items-center justify-content-center trigger-my-sites-menu"
    class-header="flex align-items-center profile-info justify-content-left"
    :class-content="nbTotalShops > 1 ? 'flex' : 'hidden' + ' align-items-center justify-content-center spm-overlay-panel-content px-0'"
    class-footer="flex align-items-center justify-content-center spm-overlay-panel-content"
    :close-on-route-change="true"
    :close-panel="closePanelActions"
    @onclose-panel="closePanelActions = false"
  >
    <template #trigger>
      <i class="fa-regular fa-eye mr-2" />
      <span>{{ currentShopName }}</span>
      <i class="fa-regular fa-sort class ml-3" />
    </template>
    <div class="w-full">
      <div
        v-if="nbTotalShops > 10"
        class="col-12 px-3"
      >
        <span class="p-input-icon-left">
          <i class="far fa-search" />
          <InputText
            v-model="searchValue"
            :placeholder="t('search')"
          />
        </span>
      </div>
      <div class="col-12 p-0">
        <SpmPanelMenu
          :items="items"
        />
      </div>
    </div>
    <template
      v-if="addShop && openAddShop"
      #footer
    >
      <SpmPanelMenu
        :items="addNewSiteItem"
      />
    </template>
  </SpmOverlayPanel>
  <Dialog
    v-model:visible="visibleAddNewSiteModal"
    modal
    header="Ajout d'une nouvelle boutique"
    :style="{ width: '25rem' }"
  >
    <div class="flex flex-column">
      <div class="flex flex-column field text-left">
        <label
          for="shopUrl"
          class="text-left"
        >{{ t('user.register.shopUrl') }}</label>
        <InputText
          id="shopUrl"
          v-model="newShopInfos.shopUrl"
          placeholder="https://..."
        />
        <FieldErrors
          :key="componentFieldErrorsKey"
          :errors="formValidationErrors"
          field="shopUrl"
        />
      </div>

      <div class="flex flex-column field text-left">
        <label
          for="solutionEcommerce"
          class="text-left"
        >{{ t('user.register.ecommerce') }}</label>
        <Dropdown
          id="solutionEcommerce"
          v-model="newShopInfos.solutionEcommerce"
          :options="solutions"
          option-label="label"
          option-value="code"
          :placeholder="t('user.register.ecommerce')"
        />
        <FieldErrors
          :key="componentFieldErrorsKey"
          :errors="formValidationErrors"
          field="solutionEcommerce"
        />
      </div>
    </div>

    <div class="flex justify-content-end gap-2">
      <SpmButton
        :label="t('Ajouter')"
        :loading="loadingAddNewSite"
        icon="far fa-plus-circle"
        class-style="p-button p-button-success"
        @click="addNewSiteAction"
      />
    </div>
  </Dialog>
</template>

<script lang="ts">
import {
  computed, defineComponent, onMounted, reactive, ref, Ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import { PrimvueMenuModel } from '@/types';
import {
  Me, refreshTokenLogin, setChangingShop, Shop, SwitchActiveShop, UserState,
} from '@/composables/User';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import solutions from '@/configs/ecommerce-solutions';
import Tooltip from 'primevue/tooltip';
import Dropdown from 'primevue/dropdown';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { required } from '@vuelidate/validators';
import { urlFormatValidator } from '@/helpers/CustomValidator';
import useVuelidate from '@vuelidate/core';
import { nestGet } from '@/composables/nestApi';
import { showToastError, showToastSuccess } from '@/helpers';

export default defineComponent({
  name: 'MySitesMenu',
  components: {
    SpmButton,
    FieldErrors,
    SpmOverlayPanel,
    SpmPanelMenu,
    InputText,
    Dialog,
    Dropdown,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    addShop: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const currentShopName = computed(() => (UserState?.activeShop?.name && UserState?.activeShop?.name !== '' ? UserState?.activeShop?.name : t('changeSite')));
    const searchValue = ref('');
    const openAddShop = ref(false);
    const closePanelActions = ref(false);
    const visibleAddNewSiteModal = ref(false);
    const loadingAddNewSite = ref(false);
    const formValidationErrors = ref({});
    const componentFieldErrorsKey = ref(0);

    const newShopInfos = reactive<any>({
      shopUrl: '',
      solutionEcommerce: '',
    });

    const shopToMenu = (shop: Shop) => {
      const label = shop.name ?? shop.url;
      const command = async () => {
        store.commit('general/showTheSpinner');
        try {
          const res: any = await refreshTokenLogin(shop.id);
          if (res && res.success) {
            const { idShop } = res;
            await Me(true, Number(idShop));
            store.commit('liveEditor/resetWebsiteContents');
            window.location.reload();
          } else {
            await showToastError('GENERIC_ERROR');
          }
        } catch (e) {
          await showToastError('GENERIC_ERROR');
        }

        store.commit('general/hideTheSpinner');
      };
      return { label, command };
    };

    const items: Ref<PrimvueMenuModel[]> = computed(() => {
      const regex = new RegExp(searchValue.value, 'i');
      const shops = UserState.user.shops
        .filter((s: Shop) => s.id !== UserState?.activeShop?.id && (regex.test(s.name) || regex.test(s.id.toString()) || regex.test(s.url)))
        .map(shopToMenu);
      return [
        ...shops,
      ].slice(-10);
    });
    const nbTotalShops = computed(() => UserState.user.shops.length);

    const validateShopInfo = async (): Promise<ErrorConfigForm> => {
      const rules = {
        shopUrl: {
          urlFormatValidator: urlFormatValidator(),
        },

        solutionEcommerce: {
          required,
        },
      };

      const v$ = useVuelidate(rules, newShopInfos);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    const addNewSiteAction = async () => {
      loadingAddNewSite.value = true;
      const validation = await validateShopInfo();
      if (!validation.success) {
        formValidationErrors.value = validation.validate;
        componentFieldErrorsKey.value += 1;
        loadingAddNewSite.value = false;
      } else {
        const postData = {
          idUser: UserState.user.id ?? 0,
          shopUrl: newShopInfos.shopUrl,
          solutionEcommerce: newShopInfos.solutionEcommerce,
        };
        const dataQuery = JSON.stringify(postData);

        try {
          store.commit('general/showTheSpinner');
          const result = await nestGet('v4', '/shop/create', {}, `?data=${dataQuery}`);

          if (result && result.success && result.newIdShop && result.newIdShop > 0) {
            await showToastSuccess('savedSuccessful');
            const res: any = await refreshTokenLogin(result.newIdShop);
            if (res && res.success) {
              const { idShop } = res;
              await Me(true, Number(idShop));
              store.commit('liveEditor/resetWebsiteContents');
              window.location.reload();
            } else {
              await showToastError('GENERIC_ERROR');
            }
          }
        } catch (error) {
          await showToastError('GENERIC_ERROR');
        }
        store.commit('general/hideTheSpinner');
        loadingAddNewSite.value = false;
      }
    };

    const addNewSiteItem: Ref<PrimvueMenuModel[]> = ref([
      {
        icon: 'fa-regular fa-plus',
        label: t('mySitesMenu.addNewSite'),
        command: () => {
          visibleAddNewSiteModal.value = true;
          closePanelActions.value = true;
        },
      },
    ]);

    onMounted(async () => {
      const statusRegistration = await nestGet('v4', '/user/stateRegister', {}, '');
      if (statusRegistration && statusRegistration.success && statusRegistration.status === 'closed') {
        openAddShop.value = false;
      } else {
        openAddShop.value = true;
      }
    });

    return {
      t,
      openAddShop,
      formValidationErrors,
      componentFieldErrorsKey,
      nbTotalShops,
      searchValue,
      currentShopName,
      items,
      addNewSiteItem,
      closePanelActions,
      visibleAddNewSiteModal,
      newShopInfos,
      solutions,
      loadingAddNewSite,
      addNewSiteAction,
    };
  },
});
</script>

<style lang="scss">
.trigger-my-sites-menu {
  cursor: pointer;
  color: #6b7280;
  font-size: 0.9rem;
  border: 1px solid #e9ecef;
  padding: 0.6rem;
  border-radius: 5px;

  &:hover {
    border-color: #6b7280 !important;
  }
}
</style>
