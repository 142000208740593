import { Delete, Insert, List } from '@/composables/GraphQL';
import {
  OperatorType, ShopsTags, ShopsTagsInputItem, ShopsTagsUpdateInputItem,
} from '@/types/generated-types/graphql';

interface ShopsTagsArgs {
  shopId: number;
  tag: string;
}
export const ShopsTagsList = async ({ shopId, tag }: ShopsTagsArgs): Promise<Array<ShopsTags>|null> => {
  const { items, err } = await List<ShopsTags>({
    name: 'ShopsTags',
    settings: {
      filter: [
        {
          field: 'id_shop',
          operator: OperatorType.Equals,
          value: shopId ?? 0,
        },
        {
          field: 'tag',
          operator: OperatorType.StartsWith,
          value: tag ?? '',
        },
      ],
      order: [
        { field: 'id_shop', type: 'ASC' },
      ],
      limit: 0,
      offset: 0,
    },
    fields: ['id_shop_tag', 'tag'],
  });

  if (err === '') {
    return items;
  }
  return null;
};

export const InsertShopsTags = async (shopTagsInput: ShopsTagsInputItem[]) => {
  const {
    id, status, messages, err,
  } = await Insert<ShopsTagsInputItem>({
    name: 'ShopsTags',
    input: shopTagsInput,
    type: 'ShopsTagsInput',
  });

  if (err === '') {
    return id;
  }
  return messages;
};

export const DeleteShopsTags = async (shopTagsInput: ShopsTagsUpdateInputItem[]) => {
  const {
    id, messages, err,
  } = await Delete<ShopsTagsUpdateInputItem>({
    name: 'ShopsTags',
    input: shopTagsInput,
    type: 'ShopsTagsDeleteInput',
  });

  if (err === '') {
    return id;
  }
  return messages;
};
