
import { useI18n } from 'vue-i18n';
import {
  defineComponent, onMounted,
  PropType,
  ref,
} from 'vue';
import MultiSelect from 'primevue/multiselect';
import { SpmTableFilterOption } from '@/types';
import LanguageIcons from '@/components/table/renderers/LanguageIcons.vue';

export default defineComponent({
  name: 'LanguageMultiDropdown',
  components: {
    LanguageIcons,
    MultiSelect,
  },

  props: {
    modelValue: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },

    styles: {
      type: String,
      required: false,
      default: '',
    },

    options: {
      type: Array as PropType<SpmTableFilterOption[]|Function>,
      required: true,
    },

    placeholder: {
      type: String,
      required: false,
      default: '',
    },

    showLabel: {
      type: Boolean,
      required: false,
      default: false,
    },

    filter: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, context) {
    const { t } = useI18n();
    const loading = ref(true);

    const optionValues = ref<any>([]);

    const inputValue = ref(props.modelValue ?? []);

    const onLanguageChanged = (event: any) => {
      context.emit('update:modelValue', event.value);
    };

    onMounted(async () => {
      if (typeof props.options === 'function') {
        optionValues.value = await props.options();
      } else {
        optionValues.value = props.options;
      }
      loading.value = false;
    });
    return {
      t,
      inputValue,
      onLanguageChanged,
      optionValues,
      loading,
    };
  },
});
