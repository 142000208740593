<template>
  <Loader
    v-if="isLoading"
    style="z-index:1102"
  />
  <div class="grid">
    <div class="col-12 lg:col-6 flex flex-column">
      <TabView
        :active-index="activeTab"
        @tab-change="({ index }) => { activeTab = index }"
      >
        <TabPanel :header="t('storeParameters.services.notification')">
          <Notification
            v-model="field"
            :id-shop="shopId"
            :errors="errors"
            :component-field-errors-key="componentFieldErrorsKey"
          />
        </TabPanel>
        <TabPanel :header="t('storeParameters.services.icon')">
          <Icon
            v-model="field"
          />
        </TabPanel>
<!--        <TabPanel-->
<!--          :header="t('storeParameters.services.popup')"-->
<!--          :disabled="true"-->
<!--        >-->
<!--          <Popup-->
<!--            v-model="field"-->
<!--            :errors="errors"-->
<!--            :component-field-errors-key="componentFieldErrorsKey"-->
<!--          />-->
<!--        </TabPanel>-->
      </TabView>
      <SpmButton
        v-if="!globalSave"
        :label="t('save')"
        :loading="saveLoading"
        class="p-button p-button-primary"
        @click="handleSave"
      />
    </div>
    <div
      ref="pushnotificationPreviewRef"
      class="pushnotification-preview col-12 lg:col-6 flex flex-column gap-2 border-1 border-gray-200 relative"
    >
      <div class="flex align-items-center justify-content-between">
        <Skeleton
          width="7rem"
          height="1.5rem"
          animation="none"
        />
        <div class="hidden md:flex gap-3">
          <Skeleton
            width="4rem"
            animation="none"
          />
          <Skeleton
            width="4rem"
            animation="none"
          />
          <Skeleton
            width="4rem"
            animation="none"
          />
          <Skeleton
            width="4rem"
            animation="none"
          />
        </div>
        <Skeleton
          width="1rem"
          class="md:hidden"
          animation="none"
        />
      </div>
      <div class="flex-1">
        <div class="px-3 py-5 bg-gray-100 flex">
          <div class="max-w-30rem mx-auto align-items-center">
            <Skeleton
              width="15rem"
              height="2rem"
              class="mb-2"
              animation="none"
            />
            <Skeleton
              width="10rem"
              class="mx-auto mb-2"
              animation="none"
            />
            <Skeleton
              width="3.5rem"
              height="1.5rem"
              class="mx-auto"
              style="background: #94c840;"
              animation="none"
            />
          </div>
        </div>
        <div class="px-3 py-5">
          <Skeleton
            width="13rem"
            height="1.5rem"
            class="mx-auto"
            animation="none"
          />
          <div class="grid mt-2">
            <div
              v-for="(_, index) in [...Array(6).keys()]"
              :key="index"
              class="col"
            >
              <Skeleton
                height="6rem"
                class="mb-1"
                animation="none"
              />
              <Skeleton
                width="6rem"
                height="1rem"
                class="mb-1"
                animation="none"
              />
              <Skeleton
                width="13rem"
                height="0.5rem"
                class="mb-1"
                animation="none"
              />
              <Skeleton
                width="3.5rem"
                height="1.5rem"
                style="background: #94c840;"
                animation="none"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="field.shopRequiredDataPushNotification_activeIcon && field.shopRequiredDataPushNotification_activeIcon === '1' && activeTab === 1"
        class="absolute bottom-0 left-0 border-circle w-3rem h-3rem flex align-items-center justify-content-center ml-3 mb-7"
        :style="{
          backgroundColor: field.shopRequiredDataPushNotification_colorIcon || 'black'
        }"
      >
        <i
          class="fas fa-bell text-xl"
          :style="{
            color: field.shopRequiredDataPushNotification_textColorIcon || 'white'
          }"
        />
      </div>

      <div
        v-if="field.shopRequiredDataPushNotification_activeNotification && field.shopRequiredDataPushNotification_activeNotification === '1' && activeTab === 0"
        ref="notificationRef"
        class="absolute"
        :style="{
          backgroundColor: field.shopRequiredDataPushNotification_backgroundNotifColor || 'white',
          width: field.shopRequiredDataPushNotification_widthOfNotification + 'px' || '0px',
          top: notificationPosition.top + 'px' || '0px',
          left: notificationPosition.left + 'px' || '0px',
          animationName: notificationEffect,
          animationDuration: '1s',
          animationIterationCount: 1
        }"
      >
        <div class="flex justify-content-between align-items-center border-bottom-1 border-gray-200 p-3">
          <div class="flex gap-3 w-full">
            <div
              v-if="field.shopRequiredDataPushNotification_activeImgNotifThumbnail && field.shopRequiredDataPushNotification_activeImgNotifThumbnail === '1'"
            >
              <img
                :key="field.shopRequiredDataPushNotification_imgNotifThumbnailPath"
                role="presentation"
                :src="field.shopRequiredDataPushNotification_imgNotifThumbnailPath"
                class="shadow-3 pushnotification-preview--thumbnail"
              >
            </div>
            <div class="flex flex-column gap-2 w-full">
              <div
                class="font-semibold"
                :style="{ color: field.shopRequiredDataPushNotification_titleNotifColor || 'black' }"
              >
                {{ titleNotif }}
              </div>
              <div
                class="font-medium"
                :style="{ color: field.shopRequiredDataPushNotification_textNotifColor || 'black' }"
              >
                {{ textNotif }}
              </div>
              <div class="flex gap-2 ml-auto mt-3">
                <Button
                  :label="acceptButtonText"
                  class="p-button-sm border-none"
                  unstyled
                  :style="{
                    backgroundColor: field.shopRequiredDataPushNotification_colorNotification || 'white',
                    color: field.shopRequiredDataPushNotification_textColorNotification || 'black'
                  }"
                />
                <Button
                  :label="refuseButtonText"
                  class="p-button-sm border-none"
                  unstyled
                  :style="{
                    backgroundColor: field.shopRequiredDataPushNotification_backgroundButtonRefuse || 'white',
                    color: field.shopRequiredDataPushNotification_textButtonRefuse || 'black'
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="activeTab === 2"
        class="pushnotification-overlay"
      />

      <div
        v-if="activeTab === 2"
        class="pushnotification-preview--popup absolute left-50"
        style="top: 25%"
        :style="{
          backgroundColor: field.shopRequiredDataPushNotification_backgroundColorPopup || 'white'
        }"
      >
        <div class="flex flex-column justify-content-between align-items-center p-3 gap-4">
          <div>
            <img
              v-if="field.shopRequiredDataEmail_logoPath && field.shopRequiredDataEmail_logoPath !== ''"
              :key="field.shopRequiredDataEmail_logoPath"
              role="presentation"
              :src="field.shopRequiredDataEmail_logoPath"
              class="shadow-3 logo"
            >
          </div>
          <div
            class="text-center"
            :style="{
              color: field.shopRequiredDataPushNotification_textColorPopup || 'black'
            }"
          >
            {{ popupText }}
          </div>
          <Button
            :label="popupButtonText"
            class="p-button-sm border-none w-full"
            unstyled
            :style="{
              backgroundColor: field.shopRequiredDataPushNotification_buttonColorPopup || 'white',
              color: field.shopRequiredDataPushNotification_textButtonColorPopup || 'black'
            }"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="globalSave"
    class="flex align-content-center justify-content-center align-items-center"
  >
    <slot name="save" />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  defineExpose,
  ref,
  reactive,
  computed,
  onMounted,
  onUnmounted,
  nextTick,
  watch,
} from 'vue';

import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Skeleton from 'primevue/skeleton';
import Button from 'primevue/button';

import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import Popup from '@/views/shop/tabs/ServicesParameters/services/PushNotificationServiceParameter/tabs/Popup.vue';
import Icon from '@/views/shop/tabs/ServicesParameters/services/PushNotificationServiceParameter/tabs/Icon.vue';
import Notification from '@/views/shop/tabs/ServicesParameters/services/PushNotificationServiceParameter/tabs/Notification.vue';
import Loader from '@/components/layout/Loader.vue';

import { useI18n } from 'vue-i18n';

import useVuelidate from '@vuelidate/core';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { showToastError, showToastSuccess } from '@/helpers';
import { localizedTextInputValidator } from '@/helpers/CustomValidator';
import { ShopsConfigurationInput, ShopsConfigurationInputItem } from '@/types/generated-types/graphql';
import { saveShopParamsOnRedis, SaveShopsConfiguration } from '@/composables/shop/ShopsConfiguration';

import { serialize } from 'php-serialize';

export default defineComponent({
  name: 'PushNotificationServiceParameter',

  components: {
    TabView,
    TabPanel,
    SpmButton,
    Notification,
    Icon,
    Popup,
    Skeleton,
    Button,
    Loader,
  },

  props: {
    retrieveData: {
      type: Function,
      required: true,
    },

    shopId: {
      type: Number,
      required: true,
    },

    globalSave: {
      type: Boolean,
      required: false,
      default: false,
    },

    name: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['close'],

  setup(props, { emit }) {
    const { t, locale } = useI18n();

    const isLoading = ref(false);
    const saveLoading = ref(false);

    const activeTab = ref(0);
    const componentFieldErrorsKey = ref(0);
    const errors = ref();

    const field = reactive<Record<string, any>>({
      shopRequiredDataPushNotification_activeNotification: '0',
      shopRequiredDataPushNotification_titleNotif: {},
      shopRequiredDataPushNotification_titleNotifColor: '0',
      shopRequiredDataPushNotification_activeImgNotifThumbnail: '0',
      shopRequiredDataPushNotification_displayImgInMobile: '0',
      shopRequiredDataPushNotification_imgNotifThumbnailPath: '',
      shopRequiredDataPushNotification_textNotif: {},
      shopRequiredDataPushNotification_displayDescriptionInMobile: '0',
      shopRequiredDataPushNotification_appearEffects: 'none',
      shopRequiredDataPushNotification_delayHide: '1',
      shopRequiredDataPushNotification_backgroundNotifColor: '',
      shopRequiredDataPushNotification_textNotifColor: '',
      shopRequiredDataPushNotification_widthOfNotification: 0,
      shopRequiredDataPushNotification_positionTopOfNotification: 20,
      shopRequiredDataPushNotification_positionLeftOfNotification: 50,
      shopRequiredDataPushNotification_fullTextButtonAccept: {},
      shopRequiredDataPushNotification_colorNotification: '',
      shopRequiredDataPushNotification_textColorNotification: '',
      shopRequiredDataPushNotification_fullTextButtonRefuse: {},
      shopRequiredDataPushNotification_backgroundButtonRefuse: '',
      shopRequiredDataPushNotification_textButtonRefuse: '',
      shopRequiredDataPushNotification_activeIcon: '0',
      shopRequiredDataPushNotification_colorIcon: '',
      shopRequiredDataPushNotification_textColorIcon: '',
      shopRequiredDataPushNotification_textPopup: {},
      shopRequiredDataPushNotification_backgroundColorPopup: '',
      shopRequiredDataPushNotification_textColorPopup: '',
      shopRequiredDataPushNotification_textButtonPopup: {},
      shopRequiredDataPushNotification_buttonColorPopup: '',
      shopRequiredDataPushNotification_textButtonColorPopup: '',
      shopRequiredDataEmail_logoPath: '',
    });

    const pushnotificationPreviewRef = ref<HTMLDivElement | null>(null);
    const notificationRef = ref<HTMLDivElement | null>(null);
    const notificationPosition = reactive({
      top: 0,
      left: 0,
    });

    const calculatePosition = () => {
      if (pushnotificationPreviewRef.value && notificationRef.value) {
        const containerRect = pushnotificationPreviewRef.value.getBoundingClientRect();
        const notificationRect = notificationRef.value.getBoundingClientRect();

        const left = (containerRect.width * field.shopRequiredDataPushNotification_positionLeftOfNotification || 0) / 100 - (notificationRect.width / 2);
        const top = (containerRect.height * field.shopRequiredDataPushNotification_positionTopOfNotification || 0) / 100 - (notificationRect.height / 2);

        // Ensure that the notification div does not go beyond the container's edges
        notificationPosition.left = Math.max(0, Math.min(left, containerRect.width - notificationRect.width));
        notificationPosition.top = Math.max(0, Math.min(top, containerRect.height - notificationRect.height));
      }
    };

    const handleResize = () => {
      // Recalculate position on window resize
      calculatePosition();
    };

    const getLocalizedInputText = (fieldName: string) => {
      if (typeof field[fieldName] !== 'string') {
        if (locale.value in field[fieldName]) {
          return field[fieldName][locale.value];
        }
        return field[fieldName].fr;
      }
      return field[fieldName];
    };

    // Text for notification
    const titleNotif = computed(() => getLocalizedInputText('shopRequiredDataPushNotification_titleNotif'));
    const textNotif = computed(() => getLocalizedInputText('shopRequiredDataPushNotification_textNotif'));
    const acceptButtonText = computed(() => getLocalizedInputText('shopRequiredDataPushNotification_fullTextButtonAccept'));
    const refuseButtonText = computed(() => getLocalizedInputText('shopRequiredDataPushNotification_fullTextButtonRefuse'));

    // Text for popup
    const popupText = computed(() => getLocalizedInputText('shopRequiredDataPushNotification_textPopup'));
    const popupButtonText = computed(() => getLocalizedInputText('shopRequiredDataPushNotification_textButtonPopup'));

    // Effect
    const notificationEffect = computed(() => {
      if (field.shopRequiredDataPushNotification_appearEffects === 'none' || !field.shopRequiredDataPushNotification_appearEffects) {
        return '';
      }
      return `spm${field.shopRequiredDataPushNotification_appearEffects[0].toUpperCase()}${field.shopRequiredDataPushNotification_appearEffects.slice(1)}`;
    });

    const notificationRules = {
      shopRequiredDataPushNotification_textNotif: {
        localizedTextInputValidator: localizedTextInputValidator('text'),
      },

      shopRequiredDataPushNotification_fullTextButtonAccept: {
        localizedTextInputValidator: localizedTextInputValidator('text'),
      },

      shopRequiredDataPushNotification_fullTextButtonRefuse: {
        localizedTextInputValidator: localizedTextInputValidator('text'),
      },
    };

    const rulesTabLocation: Record<string, any> = {
      shopRequiredDataPushNotification_textNotif: 0,
      shopRequiredDataPushNotification_fullTextButtonAccept: 0,
      shopRequiredDataPushNotification_fullTextButtonRefuse: 0,
    };

    const validate = async (): Promise<ErrorConfigForm> => {
      let rules = {};
      if (field.shopRequiredDataPushNotification_activeNotification
        && (field.shopRequiredDataPushNotification_activeNotification === 1 || field.shopRequiredDataPushNotification_activeNotification === '1')) {
        rules = { ...notificationRules };
      }

      const v$ = useVuelidate(rules, field);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
        error: v$.value.$errors.length ? v$.value.$errors[0].$propertyPath : '',
      };
    };

    // eslint-disable-next-line consistent-return
    const handleSave = async () => {
      const validation = await validate();
      if (!validation.success) {
        errors.value = validation.validate;
        componentFieldErrorsKey.value += 1;
        if (validation.error) {
          activeTab.value = rulesTabLocation[validation.error];
        }
        return {
          err: validation.validate.value.$errors.length,
          configs: [],
        };
      }

      const pushNotifState = {
        shopRequiredDataPushNotification_activeConfig: '1',
        ...field,
      };

      // unpack changed values and update
      const shopsConfigArray: ShopsConfigurationInputItem[] = [];
      let key = '';
      let value = '';

      Object.entries(pushNotifState).forEach((keyValuePair: any) => {
        [key, value] = [...keyValuePair];
        const shopsConfigRecord: ShopsConfigurationInputItem = {
          key: '',
          value: '',
          lang: '',
        };

        if (typeof value === 'object' && !Array.isArray(value)) {
          let language = '';
          let inputValue = '';
          Object.entries(value).forEach((entry: any) => {
            [language, inputValue] = [...entry];

            const multiRecord: ShopsConfigurationInputItem = {
              key,
              value: inputValue,
              lang: language,
            };
            shopsConfigArray.push(multiRecord);
          });
        } else {
          // Si les notifications/icons sont activés, on regroupe les paramétres Popup en une seule clé.
          if ((key === 'shopRequiredDataPushNotification_activeNotification' && value === '1')
            || (key === 'shopRequiredDataPushNotification_activeIcon' && value === '1')) {
            const pushPopup = {
              text: field.shopRequiredDataPushNotification_textPopup,
              background_color: field.shopRequiredDataPushNotification_backgroundColorPopup,
              text_color: field.shopRequiredDataPushNotification_textColorPopup,
              button_text: field.shopRequiredDataPushNotification_textButtonPopup,
              button_bg_color: field.shopRequiredDataPushNotification_buttonColorPopup,
              button_text_color: field.shopRequiredDataPushNotification_textButtonColorPopup,
            };
            shopsConfigRecord.key = 'push_popup';
            shopsConfigRecord.value = serialize(pushPopup);
            shopsConfigRecord.lang = '';
          }

          shopsConfigRecord.key = key;
          shopsConfigRecord.value = value;
          shopsConfigRecord.lang = '';

          shopsConfigArray.push(shopsConfigRecord);
        }
      });

      if (props.globalSave) {
        return {
          err: null,
          configs: shopsConfigArray,
        };
      }

      const input: ShopsConfigurationInput = {
        id_shop: props.shopId,
        configs: [],
      };
      input.id_shop = props.shopId;
      input.configs = shopsConfigArray;

      saveLoading.value = true;

      try {
        await SaveShopsConfiguration(input);
        await saveShopParamsOnRedis(props.shopId);
        if (!props.globalSave) {
          showToastSuccess('savedSuccessful');
        }
      } catch (err) {
        if (!props.globalSave) {
          showToastError('GENERIC_ERROR');
        } else {
          throw err;
        }
      } finally {
        saveLoading.value = false;
      }
    };

    defineExpose({
      handleSave,
    });

    onMounted(async () => {
      try {
        isLoading.value = true;
        await props.retrieveData(field);
        window.addEventListener('resize', handleResize);
        await nextTick();
        calculatePosition();
      } catch (error) {
        showToastError('GENERIC_ERROR');
      } finally {
        isLoading.value = false;
      }
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
    });

    watch(
      () => [
        field.shopRequiredDataPushNotification_positionTopOfNotification,
        field.shopRequiredDataPushNotification_positionLeftOfNotification,
        field.shopRequiredDataPushNotification_widthOfNotification,
      ],
      calculatePosition,
      {
        deep: true,
      },
    );

    return {
      t,
      isLoading,
      saveLoading,
      activeTab,
      field,
      componentFieldErrorsKey,
      errors,
      titleNotif,
      textNotif,
      acceptButtonText,
      refuseButtonText,
      popupText,
      popupButtonText,
      pushnotificationPreviewRef,
      notificationRef,
      notificationPosition,
      notificationEffect,
      handleSave,
    };
  },
});
</script>

<style lang="scss" scoped>
.pushnotification-preview {
  height: fit-content;
  z-index: 2;

  &--thumbnail {
    max-width: 75px;
  }

  &--popup {
    transform: translateX(-50%);
  }
}
.pushnotification-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.2);
  z-index: 0;
}

.logo {
  max-width: 200px;
}
</style>
