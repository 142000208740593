<template>
  <Loader
    v-if="isLoading"
    style="z-index: 1102;"
  />
  <div
    v-else
    class="mx-auto"
  >
    <div class="flex flex-column p-4 justify-content-center align-items-center">
      <div class="w-6rem">
        <img
          src="@/assets/images/logo-sm-green.svg"
          alt="logo Icon"
        >
      </div>
      <h3 class="text-2xl font-semibold">
        {{ title }}
      </h3>
      <p class="text-sm">
        {{ subTitle }}
      </p>
    </div>
    <div class="flex flex-column md:flex-row">
      <div class="sm:col-12 md:col-6">
        <div class="flex flex-column gap-2">
          <label for="lastName">{{ t('profile.myInformation.fields.lastName') }}</label>
          <InputText
            id="lastName"
            v-model="userInfosFields.lastName"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="lastName"
          />
        </div>
      </div>
      <div class="sm:col-12 md:col-6">
        <div class="flex flex-column gap-2">
          <label for="firstName">{{ t('profile.myInformation.fields.firstName') }}</label>
          <InputText
            id="firstName"
            v-model="userInfosFields.firstName"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="firstName"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-column md:flex-row">
      <div class="sm:col-12 md:col-6">
        <div class="flex flex-column gap-2">
          <label for="societe">{{ t('profile.myInformation.fields.societe') }}</label>
          <InputText
            id="societe"
            v-model="userInfosFields.societe"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="societe"
          />
        </div>
      </div>
      <div class="sm:col-12 md:col-6">
        <div class="flex flex-column gap-2">
          <label for="vatNumber">{{ t('profile.myInformation.fields.vatNumber') }}</label>
          <InputText
            id="vatNumber"
            v-model="userInfosFields.vatNumber"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="vatNumber"
          />
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="col-12">
        <div class="flex flex-column gap-2">
          <label for="rue">{{ t('complete.userInfos.fields.address') }}</label>
          <InputText
            id="rue"
            v-model="userInfosFields.rue"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="rue"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-column md:flex-row">
      <div class="sm:col-12 md:col-6">
        <div class="flex flex-column gap-2">
          <label for="complement">{{ t('profile.myInformation.fields.complement') }}</label>
          <InputText
            id="complement"
            v-model="userInfosFields.complement"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="complement"
          />
        </div>
      </div>
      <div class="sm:col-12 md:col-6">
        <div class="flex flex-column gap-2">
          <label for="zipCode">{{ t('profile.myInformation.fields.zipCode') }}</label>
          <InputText
            id="zipCode"
            v-model="userInfosFields.zipCode"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="zipCode"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-column md:flex-row">
      <div class="sm:col-12 md:col-6">
        <div class="flex flex-column gap-2">
          <label for="city">{{ t('profile.myInformation.fields.city') }}</label>
          <InputText
            id="city"
            v-model="userInfosFields.city"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="city"
          />
        </div>
      </div>
      <div class="sm:col-12 md:col-6">
        <div class="flex flex-column gap-2">
          <label for="country">{{ t('countries.label') }}</label>
          <Dropdown
            v-model="userInfosFields.country"
            :options="countries"
            :placeholder="t('countries.label')"
            option-label="label"
            option-value="value"
            loading-icon="fas fa-globe"
            filter
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="country"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-column md:flex-row">
      <div class="sm:col-12 md:col-6">
        <div class="flex flex-column gap-2">
          <label for="phoneMobile">{{ t('profile.myInformation.fields.phoneMobile') }}</label>
          <InputText
            id="phoneMobile"
            v-model="userInfosFields.phoneMobile"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="phoneMobile"
          />
        </div>
      </div>
      <div class="sm:col-12 md:col-6">
        <div class="flex flex-column gap-2">
          <label for="phoneFixe">{{ t('profile.myInformation.fields.phoneFixe') }}</label>
          <InputText
            id="phoneFixe"
            v-model="userInfosFields.phoneFixe"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="phoneFixe"
          />
        </div>
      </div>
    </div>
    <div class="flex mt-3 p-2">
      <SpmButton
        :label="t('next')"
        class-style="p-button p-button-primary w-full"
        @click="handleNext"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, PropType,
  ref,
} from 'vue';
import InputText from 'primevue/inputtext';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import UserInfosMetadata, { UserInfosData } from '@/components/complete/metadata/UserInfosMetadata';
import { useI18n } from 'vue-i18n';
import { saveMyProfile } from '@/composables/user/Profile';
import { showToastError } from '@/helpers';
import Loader from '@/components/layout/Loader.vue';
import { UsersUpdateInputItem } from '@/types/generated-types/graphql';
import { UserState } from '@/composables/User';
import getCountries from '@/configs/countries';
import Dropdown from 'primevue/dropdown';

export default defineComponent({
  name: 'UserInfos',

  components: {
    Loader,
    InputText,
    SpmButton,
    FieldErrors,
    Dropdown,
  },

  props: {
    data: {
      type: Object as PropType<UserInfosData>,
      required: true,
    },

    title: {
      type: String,
      required: false,
      default: '',
    },

    subTitle: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['next'],

  setup(props: { data: any }, { emit }) {
    const { t } = useI18n();
    const isLoading = ref(false);
    const userInfosFields = ref<UserInfosData>(UserInfosMetadata.Create(props.data));
    const countries = ref(getCountries(t));

    const formValidationErrors = ref({});
    const componentFieldErrorsKey = ref(0);

    const handleNext = async () => {
      const validation = await UserInfosMetadata.Validate(userInfosFields.value);
      if (!validation.success) {
        formValidationErrors.value = validation.validate;
        componentFieldErrorsKey.value += 1;
      } else {
        isLoading.value = true;
        const userDataToSave: UsersUpdateInputItem = {
          id_user: UserState.user.id,
          first_name: userInfosFields.value.firstName,
          last_name: userInfosFields.value.lastName,
          societe: userInfosFields.value.societe,
          vat_number: userInfosFields.value.vatNumber,
          rue: userInfosFields.value.rue,
          complement: userInfosFields.value.complement,
          zip_code: userInfosFields.value.zipCode,
          city: userInfosFields.value.city,
          country: userInfosFields.value.country,
          phone_mobile: userInfosFields.value.phoneMobile,
          phone_fixe: userInfosFields.value.phoneFixe,
        };
        saveMyProfile(userDataToSave).then(() => {
          isLoading.value = false;
          emit('next');
        }).catch((error: any) => {
          isLoading.value = false;
          showToastError('GENERIC_ERROR');
        });
      }
    };

    return {
      t,
      isLoading,
      userInfosFields,
      countries,
      formValidationErrors,
      componentFieldErrorsKey,
      handleNext,
    };
  },

});
</script>
