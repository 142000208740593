
import {
  computed, defineComponent, PropType,
} from 'vue';
import { SpmTableAction } from '@/types';
import Tooltip from 'primevue/tooltip';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';

export default defineComponent({
  name: 'ActionRenderer',

  components: {
    SpmOverlayPanel,
    SpmPanelMenu,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    actions: {
      type: Array as PropType<Array<SpmTableAction>>,
      required: false,
      default: () => [],
    },

    data: {
      type: Object,
      required: true,
    },
  },

  setup(props: { actions: SpmTableAction[]; data: any }) {
    const items = computed(() => props.actions.filter((action: SpmTableAction) => action.show(props.data)));
    return { items };
  },

});
