<template>
  <Loader
    v-if="isLoading"
    style="z-index:1102"
  />
  <div class="grid">
    <div class="col-12 lg:col-6 flex flex-column">
      <div class="field">
        <label>{{ t('storeParameters.services.storeLogo') }}</label>
        <div class="flex justify-content-between flex-wrap mb-3">
          <div class="flex w-full">
            <ImageUploader
              v-model="field.shopRequiredDataEmail_logoPath"
              :empty-label="t('storeParameters.services.emailServiceParameter.fields.dragAndDropLogo')"
              :shop-id="shopId"
              @on-loading="handleOnLoading"
              @uploaded="handleUploaded"
            />
          </div>
        </div>
      </div>
      <div
        v-if="field.shopRequiredDataEmail_senderName"
        class="field"
      >
        <label>{{ t('storeParameters.services.emailServiceParameter.fields.ctaColor') }}</label>
        <SimpleColorPicker
          id="shopRequiredDataEmail_colorCta"
          :selected-color="field.shopRequiredDataEmail_colorCta"
          @color-changed="(color) => { field.shopRequiredDataEmail_colorCta = color }"
        />
      </div>
      <div
        v-if="field.shopRequiredDataEmail_senderName"
        class="field"
      >
        <label>{{ t('storeParameters.services.senderName') }}</label>
        <LocalizedTextInput
          id="shopRequiredDataEmail_senderName"
          v-model="field.shopRequiredDataEmail_senderName"
          :display-emojis="true"
        />
        <FieldErrors
          :key="componentFieldErrorsKey"
          :errors="error"
          field="shopRequiredDataEmail_senderName"
        />
      </div>
      <div
        v-if="field.shopRequiredDataEmail_senderEmail"
        class="field"
      >
        <label>{{ t('storeParameters.services.senderAddress') }}</label>
        <LocalizedTextInput
          id="shopRequiredDataEmail_senderEmail"
          v-model="field.shopRequiredDataEmail_senderEmail"
          type="auto_complete"
          :complete="(query) => searchValuesForAutocompletion('senderAddress', query, autoCompletionData)"
          :continue-reloading="true"
        />
        <FieldErrors
          :key="componentFieldErrorsKey"
          :errors="error"
          field="shopRequiredDataEmail_senderEmail"
        />
        <span style="font-size: 12px; color: #495057;">
          {{ t('emailDomainValid.message', null) }}
          <span
            style="cursor: pointer; text-decoration: underline; color: blue;"
            @click="goToDomainPage"
          >
            {{ t('emailDomainValid.click', null) }}
          </span>
        </span>
      </div>
      <div
        v-if="field.shopRequiredDataRgpd_dataManagerEmail"
        class="field"
      >
        <label>{{ t('storeParameters.rgpd.dataManagerEmail') }}</label>
        <LocalizedTextInput
          id="shopRequiredDataRgpd_dataManagerEmail"
          v-model="field.shopRequiredDataRgpd_dataManagerEmail"
        />
        <FieldErrors
          :key="componentFieldErrorsKey"
          :errors="error"
          field="shopRequiredDataRgpd_dataManagerEmail"
        />
      </div>
      <SpmButton
        v-if="!globalSave"
        :label="t('save')"
        :loading="saveLoading"
        class="p-button p-button-primary"
        @click="handleSave"
      />
    </div>
    <div class="col-12 lg:col-6 flex flex-column gap-2 border-1 border-gray-200">
      <div class="flex justify-content-between align-items-center border-bottom-1 border-gray-200 pb-3">
        <div class="flex gap-3">
          <Skeleton
            shape="circle"
            size="3rem"
            animation="none"
          />
          <div class="flex flex-column text-sm gap-2">
            <div class="font-medium">
              {{ senderName }} &lt;{{ senderEmail }}&gt;
            </div>
            <Skeleton
              width="9rem"
              class="mt-2"
              animation="none"
            />
          </div>
        </div>
      </div>
      <div
        class="flex"
      >
        <div>
          <img
            v-if="!isLogoLoading && field.shopRequiredDataEmail_logoPath && field.shopRequiredDataEmail_logoPath !== ''"
            :key="field.shopRequiredDataEmail_logoPath"
            role="presentation"
            :src="field.shopRequiredDataEmail_logoPath"
            class="shadow-3 logo"
          >
        </div>
        <div class="flex flex-column gap-7 ml-auto">
          <Skeleton
            width="10rem"
            animation="none"
          />
          <Skeleton
            width="10rem"
            animation="none"
          />
        </div>
      </div>
      <div
        class="flex flex-wrap mt-8"
      >
        <div class="w-full">
          <Skeleton
            class="mb-2"
            width="10rem"
            height="1rem"
            animation="none"
          />
          <Skeleton
            class="mb-4"
            width="8rem"
            height="1rem"
            animation="none"
          />
          <Skeleton
            class="mb-2"
            animation="none"
          />
          <Skeleton
            class="mb-2"
            animation="none"
          />
          <Skeleton
            class="mb-2"
            animation="none"
          />
          <Skeleton
            class="mb-2"
            animation="none"
          />
          <Skeleton
            class="mb-6"
            width="25rem"
            animation="none"
          />
          <Skeleton
            class=" m-auto"
            width="15rem"
            height="3rem"
            :style="{ 'background-color': field.shopRequiredDataEmail_colorCta }"
            animation="none"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="globalSave"
    class="flex align-content-center justify-content-center align-items-center"
  >
    <slot name="save" />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  defineExpose,
  ref,
  reactive,
  computed,
  onMounted,
} from 'vue';

import Skeleton from 'primevue/skeleton';

import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import SimpleColorPicker from '@/components/fields/SimpleColorPicker.vue';
import ImageUploader from '@/components/fields/ImageUploader.vue';
import Loader from '@/components/layout/Loader.vue';

import useVuelidate from '@vuelidate/core';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { showToastError, showToastSuccess } from '@/helpers';
import { localizedTextInputValidator } from '@/helpers/CustomValidator';
import { ShopsConfigurationInput, ShopsConfigurationInputItem, OperatorType } from '@/types/generated-types/graphql';
import { saveShopParamsOnRedis, SaveShopsConfiguration } from '@/composables/shop/ShopsConfiguration';
import {
  loadSenderAddresses,
  searchValuesForAutocompletion,
} from '@/composables/autoCompletionValuesMedia';
import getShopsDomains from '@/composables/shop/ShopsSendDomains';

import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'EmailServiceParameter',

  components: {
    LocalizedTextInput,
    FieldErrors,
    SpmButton,
    Skeleton,
    SimpleColorPicker,
    ImageUploader,
    Loader,
  },

  props: {
    retrieveData: {
      type: Function,
      required: true,
    },

    shopId: {
      type: Number,
      required: true,
    },

    globalSave: {
      type: Boolean,
      required: false,
      default: false,
    },

    name: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['close'],

  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const router = useRouter();

    const isLoading = ref(false);
    const isLogoLoading = ref(false);
    const saveLoading = ref(false);

    const field = reactive<Record<string, any>>({
      shopRequiredDataEmail_senderEmail: {},
      shopRequiredDataEmail_logoPath: '',
      shopRequiredDataEmail_senderName: {},
      shopRequiredDataEmail_colorCta: '',
      shopRequiredDataRgpd_dataManagerEmail: {},
    });

    const componentFieldErrorsKey = ref(0);
    const error = ref();

    const autoCompletionData = ref<any>({
      senderAddresses: [],
      domainsOfShop: [],
    });

    const senderName = computed(() => {
      if (typeof field.shopRequiredDataEmail_senderName !== 'string') {
        if (locale.value in field.shopRequiredDataEmail_senderName) {
          return field.shopRequiredDataEmail_senderName[locale.value];
        }
        return field.shopRequiredDataEmail_senderName.fr;
      }
      return field.shopRequiredDataEmail_senderName;
    });
    const senderEmail = computed(() => {
      if (typeof field.shopRequiredDataEmail_senderEmail !== 'string') {
        if (locale.value in field.shopRequiredDataEmail_senderEmail) {
          return field.shopRequiredDataEmail_senderEmail[locale.value];
        }
        return field.shopRequiredDataEmail_senderEmail.fr;
      }
      return field.shopRequiredDataEmail_senderEmail;
    });

    const handleOnLoading = () => {
      isLogoLoading.value = true;
    };

    const handleUploaded = () => {
      isLogoLoading.value = false;
    };

    const validate = async (): Promise<ErrorConfigForm> => {
      const rules: Record<string, any> = {
        shopRequiredDataEmail_senderEmail: {
          localizedTextInputValidator: localizedTextInputValidator('email'),
        },

        shopRequiredDataEmail_senderName: {
          localizedTextInputValidator: localizedTextInputValidator('text'),
        },

        shopRequiredDataRgpd_dataManagerEmail: {
          localizedTextInputValidator: localizedTextInputValidator('email'),
        },
      };

      const v$ = useVuelidate(rules, field);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    // eslint-disable-next-line consistent-return
    const handleSave = async () => {
      const validation = await validate();
      if (!validation.success) {
        error.value = validation.validate;
        componentFieldErrorsKey.value += 1;
        return {
          err: validation.validate.value.$errors.length,
          configs: [],
        };
      }

      const emailState = { ...field };

      // unpack changed values and update
      const shopsConfigArray: ShopsConfigurationInputItem[] = [];

      Object.entries(emailState).forEach((keyValuePair: any) => {
        const [key, value] = [...keyValuePair];

        if (typeof value === 'object' && !Array.isArray(value)) {
          Object.entries(value).forEach((entry: any) => {
            const [language, inputValue] = [...entry];

            const multiRecord: ShopsConfigurationInputItem = {
              key,
              value: inputValue,
              lang: language,
            };
            shopsConfigArray.push(multiRecord);
          });
        } else {
          const shopsConfigRecord: ShopsConfigurationInputItem = {
            key,
            value,
            lang: '',
          };
          shopsConfigArray.push(shopsConfigRecord);
        }
      });

      if (props.globalSave) {
        return {
          err: null,
          configs: shopsConfigArray,
        };
      }

      const input: ShopsConfigurationInput = {
        id_shop: props.shopId,
        configs: [],
      };
      input.id_shop = props.shopId;
      input.configs = shopsConfigArray;

      saveLoading.value = true;

      try {
        await SaveShopsConfiguration(input);
        await saveShopParamsOnRedis(props.shopId);
        if (!props.globalSave) {
          showToastSuccess('savedSuccessful');
        }
      } catch (err) {
        if (!props.globalSave) {
          showToastError('GENERIC_ERROR');
        } else {
          throw err;
        }
      } finally {
        saveLoading.value = false;
      }
    };

    const goToDomainPage = () => {
      const routePath = router.resolve({ path: '/shop/send-domains' }).href;
      window.open(routePath, '_blank');
    };

    defineExpose({
      handleSave,
    });

    onMounted(async () => {
      try {
        isLoading.value = true;
        await props.retrieveData(field);

        autoCompletionData.value.senderAddresses = await loadSenderAddresses();
        autoCompletionData.value.domainsOfShop = await getShopsDomains(0, [{
          field: 'domain_verified',
          operator: OperatorType.Equals,
          value: true,
        }]);
      } catch (err) {
        showToastError('GENERIC_ERROR');
      } finally {
        isLoading.value = false;
      }
    });

    return {
      t,
      isLoading,
      saveLoading,
      field,
      componentFieldErrorsKey,
      error,
      senderName,
      senderEmail,
      isLogoLoading,
      autoCompletionData,
      handleOnLoading,
      handleUploaded,
      handleSave,
      searchValuesForAutocompletion,
      goToDomainPage,
    };
  },
});
</script>

<style scoped lang="scss">
.p-fileupload {
  width: 100%;
}
.logo {
  max-width: 200px;
}
</style>
