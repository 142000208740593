<template>
  <div
    v-if="success === true"
    class="w-full h-full"
    style="position: relative;"
  >
    <iframe
      ref="previewRef"
      data-test-id="template-iframe"
      sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts"
      :srcdoc="content"
      class="template-wrapper flex-grow-1 w-full h-full"
      style="border: none;"
      @load="iframeLoaded"
    />

    <Teleport
      v-if="previewRef"
      :to="previewRef.contentDocument.body"
    >
      <heatmapjs-vue
        v-if="Object.prototype.hasOwnProperty.call(configuration, 'displayHeatMap') && configuration.displayHeatMap"
        ref="heatMapRef"
        :key="keyHeatMap"
        class="statistics-container"
        :max="maxClicks"
        :min="minClicks"
        :data="heatMapData"
        style="position: absolute !important; top: 0; left: 0; width: 100%;"
        :style="{ 'height': `${heightCanvas}px` }"
      />

      <div
        ref="statisticsRef"
        class="statistics-container"
        :style="{ 'display': Object.prototype.hasOwnProperty.call(configuration, 'displayStatistics') && configuration.displayStatistics ? 'block' : 'none' }"
      />
    </Teleport>
  </div>
  <div
    v-else-if="success === false"
    class="mt-4 text-center"
  >
    {{ content ? t(content) : t('dataViewer.errorLoading') }}
  </div>
  <div
    v-else
    class="mt-4 text-center"
  >
    {{ t('dataViewer.loading') }}
  </div>
</template>

<script lang="ts">
import {
  computed, ComputedRef, defineComponent, onMounted, Ref, ref, SetupContext, watch,
} from 'vue';
import { UserState } from '@/composables/User';
import { useI18n } from 'vue-i18n';
import { DataViewerElement, PreviewSentTemplateHeatmapDataType, PreviewSentTemplateResultClicksType } from '@/types/data-viewer-types';
import { TemplateParentTypeEnum, TemplateParentTypeIntegerEnum } from '@/types';
import { getOffsetOfElement, round5 } from '@/composables/data-viewer/HeatMap';
import { useStore } from '@/store';
import { nestGet } from '@/composables/nestApi';

export default defineComponent({
  name: 'DataViewerPreviewSentTemplate',

  props: {
    configuration: {
      type: Object,
      required: true,
    },
  },

  emits: ['loaded'],

  setup(props, context: SetupContext) {
    const store = useStore();
    const { t } = useI18n();
    const activeElement: ComputedRef<DataViewerElement | null> = computed(() => store.getters['dataViewer/getActiveElement']);
    const activeIndex: ComputedRef<number> = computed(() => store.getters['dataViewer/getActiveIndex']);
    const configuration = computed(() => activeElement.value?.configuration ?? {});

    const previewRef = ref();
    const heatMapRef = ref();
    const statisticsRef = ref();
    const keyHeatMap = ref(0);

    const clicksData: Ref<PreviewSentTemplateResultClicksType[]> = ref([]);
    const success: Ref<boolean | null> = ref(null);
    const channel: Ref<TemplateParentTypeEnum | TemplateParentTypeIntegerEnum | null> = ref(null);
    const content = ref('');
    const selector = ref(null);
    const minClicks: Ref<number | null> = ref(null);
    const maxClicks: Ref<number | null> = ref(null);
    const totalClicks = ref(0);
    const subFromDepth = ref(1);
    const links: Ref<any[]> = ref([]);
    const rawHeatMapData: Ref<PreviewSentTemplateHeatmapDataType[]> = ref([]);
    const heatMapData: Ref<PreviewSentTemplateHeatmapDataType[]> = ref([]);
    const heightCanvas = ref(0);

    // Generate heatmap data
    const calculateHeatMapData = () => {
      if (channel.value
        && [TemplateParentTypeEnum.SMS, TemplateParentTypeIntegerEnum.SMS].includes(channel.value)) {
        subFromDepth.value = 0;
      } else {
        subFromDepth.value = 1;
      }

      // Get links from template
      if (previewRef.value) {
        links.value = Array.from(previewRef.value.contentDocument.querySelector(`body${selector.value ? ` #spm_body-${selector.value}` : ''}`).querySelectorAll('a[href], area, [class*="sli_"], .push-body'))
          .filter((item: any) => !item.parentNode.closest('[class*="sli_"]')
            && !item.parentNode.closest('.spm_form_button')
            && !item.parentNode.closest('.spm_form_other_buttons')
            && !item.classList.contains('spm_close_button')
            && item.hasAttribute('href')
            && (new RegExp('/w(/[ab])?/ln?/')).test(item.getAttribute('href')));

        if (channel.value && [TemplateParentTypeEnum.DISPLAY, TemplateParentTypeIntegerEnum.POPUP, TemplateParentTypeIntegerEnum.EMBED].includes(channel.value)
          && previewRef.value.contentDocument.querySelector('body').querySelector('.spm_form_button a')) {
          links.value[-1] = previewRef.value.contentDocument.querySelector('body').querySelector('.spm_form_button a');
        }

        console.log('links.value', links.value);
        console.log('clicksData.value', clicksData.value);

        // Calculate data
        minClicks.value = null;
        maxClicks.value = null;
        totalClicks.value = 0;
        rawHeatMapData.value = [];

        clicksData.value.forEach((click) => {
          if (links.value[(Number(click.depth) - subFromDepth.value)]) {
            const value = Number(click.count);
            const link = links.value[(Number(click.depth) - subFromDepth.value)];
            const { x, y } = getOffsetOfElement(previewRef.value, link);

            // Calculate total clicks (to calculate percentage)
            totalClicks.value += value;

            // Calculate minimum and maximum values
            if (!maxClicks.value || maxClicks.value < value) {
              maxClicks.value = value;
            }

            if (!minClicks.value || minClicks.value > value) {
              minClicks.value = 0;
            }

            // Add new heatmap data
            rawHeatMapData.value[(Number(click.depth) - subFromDepth.value)] = {
              x,
              y,
              value,
              percent: 0,
            };
          }
        });

        heatMapData.value = Object.values(rawHeatMapData.value);
        heatMapData.value.forEach((data) => {
          // eslint-disable-next-line no-param-reassign
          data.percent = Number(((100 * data.value) / totalClicks.value).toFixed(2));
        });

        keyHeatMap.value += 1;
      }
    };

    // Generate statistics
    const calculateStatistics = () => {
      links.value.forEach((link, index) => {
        if (rawHeatMapData.value[index]) {
          const elementOffset = getOffsetOfElement(previewRef.value, link);

          if (!statisticsRef.value.querySelector(`spm_heatmap_${index}`)) {
            link.setAttribute('data-id', (index).toString());

            const currentData = rawHeatMapData.value[(index)];
            const node = document.createElement('span');
            node.setAttribute('id', `spm_heatmap_${index}`);
            node.setAttribute('style', `left:${elementOffset.x}px;top:${elementOffset.y}px;`);

            const textNode = document.createTextNode('0');
            node.appendChild(textNode);

            statisticsRef.value.appendChild(node);
            statisticsRef.value.querySelector(`#spm_heatmap_${index}`).innerText = `${currentData.percent}% (${currentData.value})`;
            statisticsRef.value.querySelector(`#spm_heatmap_${index}`)
              .setAttribute('class', `spm_heatmap spm_heatmap_${round5(currentData.percent)}`);
          }
        }
      });
    };

    // Create transparent blocks over links
    const createTransparentLinks = () => {
      const iframeDocument = previewRef.value.contentDocument || previewRef.value.contentWindow.document;
      const allLinks = Array.from(previewRef.value.contentDocument.querySelector(`body${selector.value ? ` #spm_body-${selector.value}` : ''}`)
        .querySelectorAll('a[href], area, [class*="sli_"], .push-body'))
        .filter((item: any) => !item.parentNode.closest('[class*="sli_"]')
          && !item.parentNode.closest('.spm_form_button')
          && !item.parentNode.closest('.spm_form_other_buttons')
          && !item.classList.contains('spm_close_button'));

      allLinks.forEach((link: any) => {
        const layer = iframeDocument.createElement('div');
        layer.classList.add('spm-transparent-link');

        const setLayerPosition = () => {
          const image = link.querySelector('img');
          let rect;

          if (image) {
            // Use image size if link has an image child
            rect = image.getBoundingClientRect();
          } else {
            // Use link size
            rect = link.getBoundingClientRect();
          }

          Object.assign(layer.style, {
            position: 'absolute',
            top: `${rect.top + iframeDocument.documentElement.scrollTop}px`,
            left: `${rect.left + iframeDocument.documentElement.scrollLeft}px`,
            width: `${rect.width}px`,
            height: `${rect.height}px`,
            zIndex: '1000002',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            cursor: 'pointer',
          });
        };

        // Add link to layer
        layer.addEventListener('click', () => {
          window.open(link.href, '_blank');
        });

        iframeDocument.body.appendChild(layer);

        const image = link.querySelector('img');
        if (image) {
          // If image is loaded, insert layer
          if (image.complete) {
            setLayerPosition();
          } else {
            // Else, we wait image's loading
            image.addEventListener('load', setLayerPosition);
          }
          // Adjust position if image size changed
          image.addEventListener('resize', setLayerPosition);
        } else {
          // If no image, we place the layer
          setLayerPosition();
        }

        // Recalculate position of layers on resize
        window.addEventListener('resize', setLayerPosition);
        previewRef.value.contentWindow.addEventListener('resize', setLayerPosition);
      });
    };

    // Generate all data
    const generateData = () => {
      if (previewRef.value) {
        previewRef.value.height = previewRef.value.contentWindow.document.body.scrollHeight;

        const head = previewRef.value.contentDocument.querySelector('head');

        if (head) {
          const heatMapCss = document.createElement('link');
          heatMapCss.setAttribute('rel', 'stylesheet');
          heatMapCss.setAttribute('data-do-not-rewrite-url', '1');
          heatMapCss.setAttribute('href', '/css/data-viewer/heatMap.css');
          head.appendChild(heatMapCss);

          let specificStylesheet;
          switch (channel.value) {
            // Add specific CSS stylesheet
            case TemplateParentTypeEnum.SMS:
            case TemplateParentTypeIntegerEnum.SMS:
              specificStylesheet = document.createElement('link');
              specificStylesheet.setAttribute('rel', 'stylesheet');
              specificStylesheet.setAttribute('data-do-not-rewrite-url', '1');
              specificStylesheet.setAttribute('href', '/css/template-builder/baseIframeSms.css');
              break;
            case TemplateParentTypeEnum.PUSHNOTIFICATIONS:
            case TemplateParentTypeIntegerEnum.PUSHNOTIFICATIONS:
              specificStylesheet = document.createElement('link');
              specificStylesheet.setAttribute('rel', 'stylesheet');
              specificStylesheet.setAttribute('data-do-not-rewrite-url', '1');
              specificStylesheet.setAttribute('href', '/css/template-builder/baseIframePushNotifications.css');
              break;
            case TemplateParentTypeEnum.FACEBOOKMESSENGER:
            case TemplateParentTypeIntegerEnum.FACEBOOKMESSENGER:
              specificStylesheet = document.createElement('link');
              specificStylesheet.setAttribute('rel', 'stylesheet');
              specificStylesheet.setAttribute('data-do-not-rewrite-url', '1');
              specificStylesheet.setAttribute('href', '/css/template-builder/baseIframeFacebookMessenger.css');
              break;
            default:
              break;
          }

          if (specificStylesheet) {
            head.appendChild(specificStylesheet);
          }
        }
      }

      heightCanvas.value = previewRef.value.height;

      if (channel.value && [
        TemplateParentTypeEnum.DISPLAY,
        TemplateParentTypeIntegerEnum.POPUP,
        TemplateParentTypeIntegerEnum.EMBED,
        TemplateParentTypeEnum.SMS,
        TemplateParentTypeEnum.FACEBOOKMESSENGER,
        TemplateParentTypeEnum.PUSHNOTIFICATIONS,
        TemplateParentTypeIntegerEnum.SMS,
        TemplateParentTypeIntegerEnum.FACEBOOKMESSENGER,
        TemplateParentTypeIntegerEnum.PUSHNOTIFICATIONS,
      ].includes(channel.value)) {
        setTimeout(() => {
          calculateHeatMapData();
          calculateStatistics();
          createTransparentLinks();

          context.emit('loaded');
        }, 2000);
      } else {
        calculateHeatMapData();
        calculateStatistics();
        createTransparentLinks();

        context.emit('loaded');
      }
    };

    // Parse each link to open in new tab
    const openLinksInNewTab = () => {
      Array.from(previewRef.value.contentDocument.querySelector(`body${selector.value ? ` #spm_body-${selector.value}` : ''}`)
        .querySelectorAll('a[href], area, [class*="sli_"], .push-body'))
        .filter((item: any) => !item.parentNode.closest('[class*="sli_"]')
          && !item.parentNode.closest('.spm_form_button')
          && !item.parentNode.closest('.spm_form_other_buttons')
          && !item.classList.contains('spm_close_button')
          && item.hasAttribute('href')
          && (new RegExp('/w(/[ab])?/ln?/')).test(item.getAttribute('href')))
        .forEach((link: any) => {
          if (link.classList.contains('push-body')) {
            // eslint-disable-next-line no-param-reassign
            link.onclick = (event: any) => {
              event.preventDefault();
              window.open(link.getAttribute('href'), '_blank');
            };
          } else {
            link.setAttribute('target', '_blank');
          }
        });
    };

    const iframeLoaded = () => {
      openLinksInNewTab();
      generateData();
    };

    const getTemplateHtml = async () => {
      let result;
      const path = `/template/preview/${UserState.activeShop?.id ?? 0}/${configuration.value.typeCampaign}/${configuration.value.id}`;
      try {
        result = await nestGet('v4', path, {});
      } catch (e) {
        // Do nothing
      }

      if (result && result.success) {
        content.value = result.html;
        success.value = true;
        channel.value = result.channel;
        selector.value = result.selector;

        // Set heatmap data
        if (result.clicks && Array.isArray(result.clicks) && result.clicks.length > 0) {
          clicksData.value = result.clicks;
        }
      } else {
        success.value = false;

        if ('error' in result && result.error && result.error !== '') {
          content.value = result.error;
        }
      }

      store.commit('general/hideTheSpinner');
    };

    // On component mount, get template preview and heatmap data
    onMounted(async () => {
      if (activeElement.value) {
        // We reactivate tools
        store.commit('dataViewer/setConfiguration', {
          name: 'displayHeatMap',
          value: true,
        });
        store.commit('dataViewer/setConfiguration', {
          name: 'displayStatistics',
          value: true,
        });
      }

      await getTemplateHtml();
    });

    watch(activeIndex, async () => {
      store.commit('general/showTheSpinner');
      success.value = null;
      content.value = '';
      await getTemplateHtml();
    });

    watch(activeElement, () => {
      if (activeElement.value && 'displayHeatMap' in activeElement.value.configuration && 'displayStatistics' in activeElement.value.configuration) {
        if (activeElement.value.configuration.displayHeatMap || activeElement.value.configuration.displayStatistics) {
          // Display transparent links when at least one layer is above the template
          previewRef.value.contentDocument.querySelectorAll('.spm-transparent-link').forEach((link: any) => {
            // eslint-disable-next-line no-param-reassign
            link.style.display = 'block';
          });
        } else {
          // Hide transparent links when no layer is above the template
          previewRef.value.contentDocument.querySelectorAll('.spm-transparent-link').forEach((link: any) => {
            // eslint-disable-next-line no-param-reassign
            link.style.display = 'none';
          });
        }
      }
    }, { deep: true });

    return {
      t,
      heatMapRef,
      previewRef,
      statisticsRef,
      success,
      content,
      minClicks,
      maxClicks,
      keyHeatMap,
      heatMapData,
      heightCanvas,
      iframeLoaded,
    };
  },
});
</script>
