// eslint-disable-next-line import/no-cycle
import {
  CustomSingleMutation,
  Delete, Insert, List, Update,
} from '@/composables/GraphQL';
// eslint-disable-next-line import/no-cycle
import {
  OperatorType, ShopsLists, ShopsListsCustomInput, ShopsListsInputItem, ShopsListsUpdateInputItem, ShopsSendDomainsUpdateInputItem,
} from '@/types/generated-types/graphql';

interface ShopsListsArgs {
  shopId: number;
  name: string;
}

export enum newsletterTypeEnum {
  NONE = '0',
  OPTIN = '1',
  DBLOPTIN = '2'
}
export const ShopsListsList = async ({ shopId, name }: ShopsListsArgs): Promise<Array<ShopsLists>|null> => {
  const { items, err } = await List<ShopsLists>({
    name: 'ShopsLists',
    settings: {
      filter: [
        {
          field: 'id_shop',
          operator: OperatorType.Equals,
          value: shopId ?? 0,
        },
        {
          field: 'name',
          operator: OperatorType.Contains,
          value: name ?? '',
        },
      ],
      order: [
        { field: 'id_shop', type: 'ASC' },
      ],
      limit: 0,
      offset: 0,
    },
    fields: ['id_shop_list', 'name'],
  });

  if (err === '') {
    return items;
  }
  return null;
};

export const InsertShopsLists = async (shopListsInput: ShopsListsInputItem[]) => Insert<ShopsListsInputItem>({
  name: 'ShopsLists',
  input: shopListsInput,
  type: 'ShopsListsInput',
});

export const UpdateShopsLists = async (shopListsUpdateInput: ShopsListsUpdateInputItem[]) => Update<ShopsListsUpdateInputItem>({
  name: 'ShopsLists',
  input: shopListsUpdateInput,
  type: 'ShopsListsUpdateInput',
});

export const DeleteShopsLists = async (shopListsDeleteInput: ShopsListsUpdateInputItem[]) => {
  const {
    id, messages, err,
  } = await Delete<ShopsListsUpdateInputItem>({
    name: 'ShopsLists',
    input: shopListsDeleteInput,
    type: 'ShopsListsDeleteInput',
  });

  if (err === '') {
    return id;
  }
  return messages;
};

export const DuplicateLists = async (shopsListsInput: ShopsListsCustomInput) => CustomSingleMutation({
  name: 'DuplicateList',
  input: shopsListsInput,
  type: 'ShopsListsUpdateInputItem',
});
