
/* eslint-disable no-param-reassign */
import {
  defineComponent, PropType, ref, Ref, watch,
} from 'vue';
import BaseButtonGroup from '@/components/fields/BaseButtonGroup.vue';
import {
  ButtonFieldConfig,
  ParserFieldObject, Property,
} from '@/types';
import { useI18n } from 'vue-i18n';
import ToggleDisplaySettings from '@/components/template-builder/fields/partials/ToggleDisplaySettings.vue';

export default defineComponent({
  name: 'ButtonGroup',
  components: {
    ToggleDisplaySettings,
    BaseButtonGroup,
  },

  props: {
    configs: {
      type: Object as PropType<ButtonFieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props: {parserValues: ParserFieldObject; configs: ButtonFieldConfig}, context) {
    const { t } = useI18n();
    const configs = ref(props.configs);
    const displayProperties = ref(!(!Object.prototype.hasOwnProperty.call(configs.value, 'displayToggle') || configs.value.displayToggle === true));
    const properties: Ref<Array<Property>> = ref(props.parserValues.properties);
    const options = ref(Object.entries(configs.value.options).map((data) => {
      const value = data[0];
      const { iconClass, src, title } = data[1];
      return {
        label: title && t(title),
        value,
        icon: iconClass,
        src,
      };
    }));

    // eslint-disable-next-line no-nested-ternary
    const option: Ref<string|string[]> = ref((configs.value.multiple) ? properties.value.reduce((acc: string[], property: Property) => {
      if (property.value !== undefined) acc.push(...property.value.split(' '));
      return acc;
    }, []) : (properties.value[0].value !== undefined ? properties.value[0].value.toString() : Object.values(configs.value.options).pop()?.defaultValue ?? ''));

    const getDefaultPropertyValue = (propertyName: string): string => Object.values(configs.value.options)
      .filter((current) => current.property === propertyName)[0].defaultValue;

    const getValueFromOptions = (propertyName: string, opts: string[]) => {
      const availableOptions = Object.entries(configs.value.options)
        .filter((current) => current[1].property === propertyName) // keep options of current property
        .filter((current) => opts.includes(current[0])); // keep options wich are selected

      const result = availableOptions.reduce((acc: string, current: any) => {
        if (current[1].multipleChoice) {
          acc += `${current[0]} `;
        } else {
          // eslint-disable-next-line prefer-destructuring
          acc = current[0];
        }
        return acc;
      }, '');

      if (!result) {
        return getDefaultPropertyValue(propertyName);
      }
      return result.trim();
    };

    watch(option, () => {
      const opts: string[] = (Array.isArray(option.value)) ? option.value : [option.value];
      properties.value.forEach((property: Property) => {
        property.value = getValueFromOptions(property.name, opts);
      });

      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: properties.value,
      });
    });

    return {
      t,
      displayProperties,
      options,
      option,
    };
  },
});
