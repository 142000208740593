<template>
  <div
    v-if="hoverRow && (selectedWidgetState === null)"
    class="sb-title"
  >
    <span>{{ t('statsEditor.row') }}</span>
  </div>
  <div
    class="grid stats-builder-row"
    :class="{ 'rowInHover': hoverRow && (selectedWidgetState === null) }"
    @mouseover="(e) => mouseoverRow(e)"
    @mouseleave="(e) => mouseleaveRow(e)"
  >
    <div
      v-if="hoverRow && (selectedWidgetState === null)"
      class="sb-row-actions"
      @mouseover="(e) => mouseoverRow(e)"
      @mouseleave="(e) => mouseleaveRow(e)"
    >
      <div class="flex flex-column">
        <div
          v-tooltip.right="t('statsEditor.actions.tooltip.row.move')"
          class="flex sb-action handle mb-1"
          icon="far fa-arrows"
        >
          <i class="far fa-arrows" />
        </div>
        <div
          v-tooltip.right="t('statsEditor.actions.tooltip.row.duplicate')"
          class="flex sb-action mb-1"
          @click="duplicateRow"
        >
          <i class="far fa-clone" />
        </div>
        <div
          v-tooltip.right="t('statsEditor.actions.tooltip.row.remove')"
          class="flex sb-action mb-1"
          @click="deleteRow"
        >
          <i class="far fa-trash" />
        </div>
      </div>
    </div>
    <StatsBuilderCol
      v-for="(columnData, ii) in rowData.children"
      :key="ii"
      :row-id="rowData.id"
      :column-data="columnData"
      :row-has-column-with-multiple-widgets="rowHasColumnWithMultipleWidgets"
    />
  </div>
  <ConfirmDialog :group="`removeRow_${rowData.id}`" />
</template>

<script lang="ts">
import {
  computed, defineComponent,
  Ref,
  ref, SetupContext,
} from 'vue';
import StatsBuilderCol from '@/components/stats-builder/StatsBuilderCol.vue';
import { store } from '@/store';
import Tooltip from 'primevue/tooltip';
import { useI18n } from 'vue-i18n';
import { StatsBuilderSelectedWidget } from '@/types/stats-editor-types';
import { useConfirm } from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';

export default defineComponent({
  name: 'StatsBuilderRow',

  components: {
    StatsBuilderCol,
    ConfirmDialog,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    rowData: {
      type: Object,
      required: false,
    },

  },

  setup(props: { rowData: any }, { emit }: SetupContext) {
    const { t } = useI18n();
    const confirm = useConfirm();

    const selectedWidgetState: Ref<StatsBuilderSelectedWidget|null> = computed(() => store.getters['statsEditor/getSelectedWidget']);

    const rowHasColumnWithMultipleWidgets = computed(() => {
      if (props.rowData.children) {
        return props.rowData.children.some((column: any) => {
          if (column.widgets && column.widgets.length > 1) {
            return true;
          }
          return false;
        });
      }
      return false;
    });

    const timeoutId = ref<any>(null);

    const hoverRow = ref(false);
    const hoverCol = ref(false);
    const mouseoverRow = (e: any) => {
      hoverRow.value = true;
      if (timeoutId.value) {
        clearTimeout(timeoutId.value);
      }
    };
    const mouseleaveRow = (e: any) => {
      timeoutId.value = setTimeout(() => {
        hoverRow.value = false;
      }, 200);
    };

    const deleteRow = () => {
      confirm.require({
        message: t('statsEditor.confirmationMessages.removeRow'),
        header: 'Confirmation',
        group: `removeRow_${props.rowData.id}`,
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('yes'),
        rejectLabel: t('no'),
        accept: () => {
          store.dispatch('statsEditor/removeRow', {
            location: {
              rowId: props.rowData.id,
            },
          });
        },
      });
    };

    const duplicateRow = () => {
      confirm.require({
        message: t('statsEditor.confirmationMessages.duplicateRow'),
        header: 'Confirmation',
        group: `removeRow_${props.rowData.id}`,
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('yes'),
        rejectLabel: t('no'),
        accept: () => {
          store.dispatch('statsEditor/duplicateRow', {
            location: {
              rowId: props.rowData.id,
            },
          });
        },
      });
    };

    return {
      t,
      selectedWidgetState,
      hoverRow,
      hoverCol,
      rowHasColumnWithMultipleWidgets,

      mouseoverRow,
      mouseleaveRow,
      deleteRow,
      duplicateRow,
    };
  },
});
</script>

<style scoped lang="scss">
  .sb-title {
    position: absolute;
    background-color: #495057;
    z-index: 2;
    line-height: 1rem;
    font-size: 0.8rem;
    color: white;
    top: -1.5rem;
    left: -0.5rem;
    padding: 6px 10px 3px 8px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .stats-builder-row {
    position: relative;
    border-radius: 2px;
    border: 1px solid #dee2e6;
    padding: 0;
    background-color: white;

    &.rowInHover {
      border: 2px solid #495057;
      // padding-left: 3rem;
      border-top-left-radius: 0;
    }
  }

  .sb-row-actions, .sb-col-actions {
    position: absolute;
    top: -0.25rem;
    left: -2.5rem;

    .sb-action {
      cursor: pointer;
      padding: 9px;
      background: white;
      border: solid 1px #e5e5e5;
      border-radius: 5px;

      .handle {
        cursor: grab;
      }
    }
  }
</style>
