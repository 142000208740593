<template>
  <div
    data-test-id="widgets-buttons"
    class="widget-btn p-button inline-flex flex-column justify-content-around align-items-center"
  >
    <em :class="button.iconClass" />
    <span>{{ button.label }}</span>
    <Chip
      v-if="featurePermission && !featurePermission.access"
      :label="translation(`offers.plans.${featurePermission.minimumPlan}`)"
      class="absolute top-0 right-0 text-xs font-semibold"
    />
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
} from 'vue';
import Chip from 'primevue/chip';
import {
  hasAccessToFeatures,
} from '@/composables/User';

export default defineComponent({
  name: 'WidgetsPanelButton',
  components: {
    Chip,
  },

  props: {
    button: {
      type: Object,
      required: true,
    },

    translation: {
      type: Function,
      required: true,
    },
  },

  setup(props) {
    const featurePermission = computed(() => hasAccessToFeatures(props.button.type));
    return {
      featurePermission,
    };
  },
});
</script>
