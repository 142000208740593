<template>
  <Chip
    v-if="data?.label || data?.icon"
    :label="data.label ?? ''"
    :icon="data.icon ?? ''"
    :class="'mr-2 mb-2 ' + (data.class ?? '')"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Chip from 'primevue/chip';
import { SpmTableChip } from '@/types';

export default defineComponent({
  name: 'ChipRenderer',
  components: {
    Chip,
  },

  props: {
    data: {
      type: Object as PropType<SpmTableChip>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.p-chip.green {
  background: var(--green-800);
  color: var(--primary-color-text);
}
.p-chip.yellow {
  background: var(--yellow-800);
  color: var(--primary-color-text);
}
.p-chip.purple {
  background: var(--purple-800);
  color: var(--primary-color-text);
}
.p-chip.red {
  background: var(--pink-800);
  color: var(--primary-color-text);
}
.p-chip.blue {
  background: var(--blue-800);
  color: var(--primary-color-text);
}
.p-chip.orange {
  background: var(--orange-800);
  color: var(--primary-color-text);
}
</style>
