import { ComponentData, ComponentMetadata, ErrorConfigForm } from '@/types/automated-scenarios';
import { i18n } from '@/i18n';
import { Md5 } from 'ts-md5';
// eslint-disable-next-line import/no-cycle
import {
  AutomatedScenarioState as state,
  getOperatorChildByOutput,
  isRootOfTree,
} from '@/composables/AutomatedScenarios';
import { TypeCampaignEnum } from '@/types';

export interface DeclencheurCustomJSData {
  token_script: string;
}

let translation: any;
(async () => {
  translation = await i18n;
})();

const DeclencheurCustomJSMetadata: ComponentMetadata<DeclencheurCustomJSData> = {
  Meta: {
    kind: 'declencheur',
    id: 'boxcustomjs',
    label: 'automatedScenarios.triggers.customJS.label',
    icon: 'fa-flask',
    hasConfiguration: true,
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxperiodiclaunching', 'boxstatuscommand',
          'boxcustomjs', 'boxsendemail', 'boxsendsms',
          'boxsendfbmessenger', 'boxsendpushnotification', 'openemail',
          'clicklink',
        ],
      },
    },
    component: 'DeclencheurCustomJS',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
  },
  Create({ token_script }: DeclencheurCustomJSData = {
    token_script: '',
  }): ComponentData<DeclencheurCustomJSData> {
    if (token_script === '') {
      const getRandom = () => Math.random() * Number.MAX_SAFE_INTEGER;
      const token = Md5.hashStr(`${Date.now().toString()}-${getRandom()}-${getRandom()}`);

      const script = [];
      script.push('<script type="text/javascript">');
      script.push('   var spm_wtk;');
      script.push(`   typeof spm_wtk === "undefined" ? spm_wtk = ["${token}"] : spm_wtk.push("${token}");`);// eslint-disable-next-line no-useless-concat
      script.push('<' + '/script>');

      // eslint-disable-next-line no-param-reassign
      token_script = script.join('\n');
    }

    return {
      id: 'boxcustomjs',
      form_name: 'boxcustomjs',
      logo_box: 'fa-flask',
      token_script,
    };
  },
  async Validate(data: DeclencheurCustomJSData): Promise<ErrorConfigForm> {
    return {
      success: true,
    };
  },
  Outputs(data: DeclencheurCustomJSData): number {
    return 1;
  },
  CanBeDeleted(operatorId: string): boolean|string {
    const isRoot = isRootOfTree(operatorId);

    if (isRoot) {
      const childOperator = getOperatorChildByOutput(operatorId, 'output_1');
      if (childOperator !== null && typeof (state.scenario.data.operators[childOperator]) !== undefined
        && state.scenario.data.operators[childOperator].properties.class !== 'declencheur') {
        return 'automatedScenarios.cant_remove_operator';
      }
    }

    return true;
  },
  Label(data: DeclencheurCustomJSData): string {
    return translation.global.t('automatedScenarios.triggers.customJS.label');
  },
};

export default DeclencheurCustomJSMetadata;
