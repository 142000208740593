<template>
  <Card
    v-if="selectedData.isAnonymous"
    class="contact-file-card"
  >
    <template #content>
      <div class="flex justify-content-center align-items-center text-2xl">
        {{ t('dataExplorer.anonymous') }}
      </div>
    </template>
  </Card>
  <Card
    v-else
    class="contact-file-card"
  >
    <template #title>
      <div class="mb-3 flex flex-column md:flex-row md:align-items-center md:justify-content-between">
        <div class="flex align-items-start">
          <i class="fa-light fa-user mr-2"></i>
          <div>
            <div class="text-xl font-medium text-900 mb-2">
              <span class="capitalize"> {{ selectedData.generalInformation.data.firstName }} {{ (selectedData.generalInformation.data.lastName || '').toUpperCase() }} </span>
            </div>
            <div
              v-if="isNumber(selectedData.generalInformation.data.idCustomerShop)"
              class="font-medium text-500 mb-3 text-sm"
            >
              ID : {{ selectedData.generalInformation.data.idCustomerShop }}
            </div>
          </div>
        </div>
        <div class="flex flex-column gap-2">
          <div>
            <span class="text-base font-medium">
              {{ t('dataExplorer.contactFile.fields.creationDate') }}{{ formatDate(selectedData.generalInformation.data.dateCreation, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY HH:mm:ss') }}
            </span>
          </div>
          <div>
            <span class="text-base font-medium">
              {{ t('dataExplorer.contactFile.fields.modificationDate') }}{{ formatDate(selectedData.generalInformation.data.dateModification, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY HH:mm:ss') }}
            </span>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="grid grid-nogutter border-top-1 surface-border pt-2">
        <div class="col-12 sm:col-6 p-3">
          <div class="text-500 font-medium mb-2">{{ t('dataExplorer.contactFile.fields.email') }}</div>
          <div class="text-900">{{ selectedData.generalInformation.data.email }}</div>
        </div>
        <div class="col-12 sm:col-6 p-3">
          <div class="text-500 font-medium mb-2">{{ t('dataExplorer.contactFile.fields.primaryPhoneNumber') }}</div>
          <div class="text-900">{{ phoneNumber }}</div>
        </div>
        <div class="col-12 sm:col-6 p-3">
          <div class="text-500 font-medium mb-2">{{ t('dataExplorer.contactFile.fields.birthday') }}</div>
          <div
            v-if="selectedData.generalInformation.data.birthday"
            class="text-900"
          >
            {{ formatDate(selectedData.generalInformation.data.birthday, 'YYYY-MM-DD', 'DD/MM/YYYY') }}
          </div>
          <div
            v-else
          >
            -
          </div>
        </div>
        <div class="col-12 sm:col-6 p-3">
          <div class="text-500 font-medium mb-2">{{ t('dataExplorer.contactFile.fields.gender.title') }}</div>
          <div class="text-900">
            <i :class="`${gender.icon} mr-2`"></i>
            <span>{{ gender.label }}</span>
          </div>
        </div>
        <div class="col-12 sm:col-6 p-3">
          <div class="text-500 font-medium mb-2">{{ t('dataExplorer.contactFile.fields.country') }}</div>
          <div class="flex">
            <Flag
              :country="selectedData.generalInformation.data.country || ''"
            />
          </div>
        </div>
        <div class="col-12 sm:col-6 p-3">
          <div class="text-500 font-medium mb-2">{{ t('dataExplorer.contactFile.fields.language') }}</div>
          <div class="flex">
            <LanguageIcons
              :translation="t"
              :language="selectedData.generalInformation.data.lang || ''"
            />
          </div>
        </div>
        <div class="col-12 sm:col-6 p-3">
          <div class="text-500 font-medium mb-2">{{ t('dataExplorer.contactFile.fields.newsletter.title') }}</div>
          <div
            class="flex gap-1 text-900 align-items-center"
          >
            <span>{{ newsletter.value }}</span>
            <i
              v-tooltip="newsletter.tooltip"
              class="fas fa-question-circle"
              data-toggle="tooltip"
              aria-hidden="true"
            />
          </div>
        </div>
        <div class="col-12 sm:col-6 p-3">
          <div class="text-500 font-medium mb-2">{{ t('dataExplorer.contactFile.fields.account.hasAccount.title') }}</div>
          <div class="text-900">{{ hasAccount ? t('dataExplorer.contactFile.fields.account.hasAccount.value.yes') : t('dataExplorer.contactFile.fields.account.hasAccount.value.no') }}</div>
        </div>
        <div
          v-if="hasAccount && selectedData.generalInformation.data.date"
          class="col-12 sm:col-6 p-3"
        >
          <div class="text-500 font-medium mb-2">{{ t('dataExplorer.contactFile.fields.account.creationDate') }}</div>
          <div class="text-900">{{ formatDate(selectedData.generalInformation.data.date, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY HH:mm:ss') }}</div>
        </div>
        <div
          v-if="hasAccount"
          class="col-12 sm:col-6 p-3"
        >
          <div class="text-500 font-medium mb-2">{{ t('dataExplorer.contactFile.fields.account.active.title') }}</div>
          <div class="text-900">{{ isAccountActive ? t('dataExplorer.contactFile.fields.account.active.value.yes') : t('dataExplorer.contactFile.fields.account.active.value.no') }}</div>
        </div>
        <div
          v-if="selectedData.generalInformation.data.tags.length"
          class="col-12 p-3"
        >
          <div class="text-500 font-medium mb-2">{{ t('dataExplorer.contactFile.fields.tags') }}</div>
          <div class="flex gap-2">
            <Chip
              v-for="(tag, index) in selectedData.generalInformation.data.tags"
              :key="tag"
              :label="tag"
            />
          </div>
        </div>

        <!-- Addresses lists -->
        <List
          :title="t('dataExplorer.contactFile.fields.addresses.title')"
          :list="selectedData.addresses.data"
          :no-column="true"
          :retrieve-data="getContactAddresses"
          :retrieve-data-arguments="[selectedData.generalInformation.data.idShopCustomer]"
          :data-loaded="selectedData.addresses.dataLoaded"
          key-name="idShopCustomerAddress"
          @loaded="handleRetrieveAddresses"
        >
          <template #content="{ item }">
            <AddressItem
              :address="item"
            />
          </template>
        </List>

        <!-- Orders lists -->
        <List
          :title="t('dataExplorer.contactFile.fields.orders')"
          :list="selectedData.orders.data"
          :retrieve-data="getContactOrders"
          :retrieve-data-arguments="[selectedData.generalInformation.data.idShopCustomer]"
          :data-loaded="selectedData.orders.dataLoaded"
          :no-column="true"
          :total="selectedData.orders.total"
          :current-page="selectedData.orders.currentPage"
          key-name="idShopOrder"
          @loaded="handleRetrieveOrders"
          @update-page="handleOrdersNextPage"
        >
          <template #content="{ item }">
            <OrderItem
              :order="item"
              key-name="orders"
            />
          </template>
        </List>

        <!-- Carts lists -->
        <List
          :title="t('dataExplorer.contactFile.fields.carts')"
          :list="selectedData.carts.data"
          :retrieve-data="getContactCarts"
          :retrieve-data-arguments="[selectedData.generalInformation.data.idShopCustomer]"
          :data-loaded="selectedData.carts.dataLoaded"
          :no-column="true"
          :total="selectedData.carts.total"
          :current-page="selectedData.carts.currentPage"
          key-name="idShopCart"
          @loaded="handleRetrieveCarts"
          @update-page="handleCartsNextPage"
        >
          <template #content="{ item }">
            <CartItem
              :cart="item"
              key-name="carts"
            />
          </template>
        </List>

        <!-- Groups lists -->
        <List
          :title="t('dataExplorer.contactFile.fields.groups')"
          :list="selectedData.groups.data"
          :retrieve-data="getContactGroups"
          :retrieve-data-arguments="[selectedData.generalInformation.data.idShopCustomer]"
          :data-loaded="selectedData.groups.dataLoaded"
          key-name="idGroup"
          @loaded="handleRetrieveGroups"
        >
          <template #content="{ item }">
            <div class="flex flex-column mr-8 md:mr-8">
              <span class="inline-block font-medium mr-2">
                <span>{{ item.name }}</span>
              </span>
            </div>
          </template>
        </List>

        <!-- Last rejects lists -->
        <!-- <List
          :title="t('dataExplorer.contactFile.fields.bounces.title')"
          :list="selectedData.rejects.data"
          :retrieve-data="getContactRejects"
          :retrieve-data-arguments="[selectedData.generalInformation.data.idShopCustomer]"
          :data-loaded="selectedData.rejects.dataLoaded"
          key-name="idEmailReject"
          @loaded="handleRetrieveRejects"
        >
          <template #content="{ item }">
            <div class="flex flex-column mr-8 md:mr-8">
              <span class="inline-block font-medium mr-2 mb-1 md:mb-2">
                <span>{{ t('dataExplorer.contactFile.fields.bounces.date', [formatDate(item.date, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY HH:mm:ss')]) }}</span>
              </span>
              <span
                v-if="item.reject_from"
                class="inline-block font-medium mr-2 mb-1 md:mb-2"
              >
                {{ t('dataExplorer.contactFile.fields.bounces.from', [item.reject_from]) }}
              </span>
              <span
                v-if="item.reject_message"
              >
                <b>{{ t('dataExplorer.contactFile.fields.bounces.message') }}</b>{{ item.reject_message }}
              </span>
            </div>
            <div
              v-if="item.reject_type"
              class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center"
            >
              <Chip :label="item.reject_type" />
            </div>
          </template>
        </List> -->

        <!-- Shops customers lists -->
        <List
          :title="t('dataExplorer.contactFile.fields.shopsCustomersLists')"
          :list="selectedData.shops.data"
          :retrieve-data="getContactShops"
          :retrieve-data-arguments="[selectedData.generalInformation.data.idShopCustomer, locale]"
          :data-loaded="selectedData.shops.dataLoaded"
          use-virtual-scroller
          key-name=""
          @loaded="handleRetrieveShops"
        />
      </div>
    </template>
  </Card>
</template>


<script lang="ts">
import { computed, defineComponent } from 'vue';

import Card from 'primevue/card';
import Chip from 'primevue/chip';
import Tooltip from 'primevue/tooltip';

import Flag from '@/components/table/renderers/Flag.vue';
import LanguageIcons from "@/components/table/renderers/LanguageIcons.vue";
import List from "@/components/data-explorer/fields/List.vue";
import AddressItem from "@/components/data-explorer/fields/AddressItem.vue";
import OrderItem from "@/components/data-explorer/fields/OrderItem.vue";
import CartItem from "@/components/data-explorer/fields/CartItem.vue";
import SpmButton from '@/components/spm-primevue/SpmButton.vue';

import {
  getContactAddresses,
  getContactGroups,
  getContactRejects,
  getContactShops,
  getContactOrders,
  getContactCarts,
} from '@/composables/data-explorer/DataExplorer';

import { useStore } from '@/store';

import { formatDate } from '@/helpers/Date';

import {
  ContactAddress,
  ContactGroup,
  ContactReject,
  Order,
  Cart,
} from '@/types/data-explorer-types';

import { useI18n } from 'vue-i18n';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default defineComponent({
  name: 'ContactFile',

  directives: {
    tooltip: Tooltip,
  },

  components: {
    AddressItem,
    Card,
    Chip,
    Flag,
    LanguageIcons,
    List,
    OrderItem,
    CartItem,
    SpmButton,
  },

  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const selectedData = computed(() => store.getters['dataExplorer/getSelectedData']);
    const newsletter = computed(() => {
      if ((selectedData.value.generalInformation.data.unSubType & 1) === 1
        || (selectedData.value.generalInformation.data.unSubType & 4) === 4
        || (selectedData.value.generalInformation.data.unSubType & 1) === 16
      ) {
        return {
          value: t('dataExplorer.contactFile.fields.newsletter.unsubscribe.value'),
          tooltip: t('dataExplorer.contactFile.fields.newsletter.unsubscribe.tooltip')
        };
      }

      if (selectedData.value.generalInformation.data.newsletter.toString() === '0') {
        return {
          value: t('dataExplorer.contactFile.fields.newsletter.nonOptin.value'),
          tooltip: t('dataExplorer.contactFile.fields.newsletter.nonOptin.tooltip')
        };
      } else if (selectedData.value.generalInformation.data.newsletter.toString() === '1') {
        return {
          value: t('dataExplorer.contactFile.fields.newsletter.optin.value'),
          tooltip: t('dataExplorer.contactFile.fields.newsletter.optin.tooltip')
        };
      } else if (selectedData.value.generalInformation.data.newsletter.toString() === '2') {
        return {
          value: t('dataExplorer.contactFile.fields.newsletter.doubleOptin.value'),
          tooltip: t('dataExplorer.contactFile.fields.newsletter.doubleOptin.tooltip')
        };
      }
      return {
        value: t('dataExplorer.contactFile.fields.newsletter.unsubscribe.value'),
        tooltip: t('dataExplorer.contactFile.fields.newsletter.unsubscribe.tooltip')
      };
    });
    const gender = computed(() => {
      if (selectedData.value && selectedData.value.generalInformation.data.gender && selectedData.value.generalInformation.data.gender.toString() === '1') {
        return {
          label: t('dataExplorer.contactFile.fields.gender.male'),
          icon: 'fa fa-mars'
        };
      } else if (selectedData.value && selectedData.value.generalInformation.data.gender && selectedData.value.generalInformation.data.gender.toString() === '2') {
        return {
          label: t('dataExplorer.contactFile.fields.gender.female'),
          icon: 'fa fa-venus'
        };
      }
      return {
        label: t('dataExplorer.contactFile.fields.gender.unknown'),
        icon: 'fa fa-neuter'
      };
    });
    const hasAccount = computed(() => selectedData.value
      && (selectedData.value.generalInformation.data.type && selectedData.value.generalInformation.data.type & 1) === 1
    );
    const isAccountActive = computed(() => selectedData.value
      && selectedData.value.generalInformation.data.active && selectedData.value.generalInformation.data.active.toString() === '1'
    );
    const phoneNumber = computed(() => {
      if (selectedData.value && selectedData.value.generalInformation.data.country && selectedData.value.generalInformation.data.mobile) {
        const phoneNumberObj = parsePhoneNumberFromString(
          selectedData.value.generalInformation.data.mobile,
          selectedData.value.generalInformation.data.country.toUpperCase(),
        );
        if (phoneNumberObj && phoneNumberObj.isValid()) {
          return phoneNumberObj.formatInternational();
        }
        if (selectedData.value.generalInformation.data.mobile) {
          return selectedData.value.generalInformation.data.mobile;
        }
      }
      return '-';
    });

    const isNumber = (val: string) => {
      if (!val) {
        return false;
      }
      return /^\d+(\.\d+)?$/.test(val);
    };

    const handleRetrieveAddresses = (addresses: Array<ContactAddress>) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          newLists: addresses,
          dataKey: 'addresses',
        }
      );
    };

    const handleRetrieveGroups = (groups: Array<ContactGroup>) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          newLists: groups,
          dataKey: 'groups',
        }
      );
    };

    const handleRetrieveRejects = (rejects: Array<ContactReject>) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          newLists: rejects,
          dataKey: 'rejects',
        }
      );
    };

    const handleRetrieveShops = (shops: Array<string>) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          newLists: shops,
          dataKey: 'shops',
        }
      );
    };

    const handleRetrieveOrders = (orders: Array<Order>, total: number) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          newLists: orders,
          total,
          dataKey: 'orders',
        }
      );
    };

    const handleOrdersNextPage = (currentPage: number) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          currentPage,
          dataKey: 'orders',
        }
      );
    };

    const handleRetrieveCarts = (carts: Array<Cart>, total: number) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          newLists: carts,
          total,
          dataKey: 'carts',
        }
      );
    };

    const handleCartsNextPage = (currentPage: number) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          currentPage,
          dataKey: 'carts',
        }
      );
    };

    return {
      t,
      locale,
      selectedData,
      newsletter,
      gender,
      hasAccount,
      isAccountActive,
      phoneNumber,

      isNumber,
      formatDate,
      getContactAddresses,
      getContactGroups,
      getContactRejects,
      getContactOrders,
      getContactShops,
      getContactCarts,
      handleRetrieveAddresses,
      handleRetrieveGroups,
      handleRetrieveRejects,
      handleRetrieveShops,
      handleRetrieveOrders,
      handleOrdersNextPage,
      handleRetrieveCarts,
      handleCartsNextPage,
    }
  }
})
</script>

<style lang="scss">
.contact-file-card {
  box-shadow: none !important;
  border: 1px solid lightgray;
  border-radius: 5px;
}
</style>
