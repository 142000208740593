
import {
  defineComponent, onBeforeMount,
  PropType,
  Ref,
  ref,
  watch,
} from 'vue';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import {
  IntervalDateEnum, SpmTableColumns, SpmTableFilterOption,
} from '@/types';
import { useI18n } from 'vue-i18n';
import CustomDatePicker from '@/components/fields/CustomDatePicker.vue';
import MultiSelect from 'primevue/multiselect';
import LanguageMultiDropdown from '@/components/fields/LanguageMultiDropdown.vue';
import CountryMultiDropdown from '@/components/fields/CountryMultiDropdown.vue';

export default defineComponent({
  name: 'Filter',

  components: {
    LanguageMultiDropdown,
    MultiSelect,
    CountryMultiDropdown,
    CustomDatePicker,
    InputText,
    Dropdown,
  },

  props: {
    col: {
      type: Object as PropType<SpmTableColumns>,
      required: true,
    },

    filterModel: {
      type: Object,
      required: true,
    },

    filterCallback: {
      type: Function,
      required: true,
    },

    updateValue: {
      type: Function,
      required: true,
    },

    customOptionsItems: {
      type: Array as PropType<IntervalDateEnum[]>,
      required: false,
      default: () => ([]),
    },
  },

  setup(props) {
    const { t } = useI18n();
    const inputValue = ref(props.filterModel.value);
    const options: Ref<SpmTableFilterOption[]|Function> = ref(props.col.filterSettings?.options ?? []);

    watch(props, () => { inputValue.value = props.filterModel.value; });

    onBeforeMount(async () => {
      if (typeof options.value !== 'function') {
        if (props.col.filterSettings && options.value.length && props.col.translatable) {
          (options.value ?? []).forEach((option: {value: string; label: string}) => {
            // eslint-disable-next-line no-param-reassign
            option.label = t(option.label);
          });
        }
      } else {
        options.value = await options.value();
      }
    });

    return {
      inputValue,
      options,
    };
  },

});
