<template>
  <Dialog
    v-model:visible="displayModal"
    data-test-id="choose-items-dialog"
    :header="t(header)"
    :modal="true"
    :dismissable-mask="true"
    style="width: 70rem;"
    @update:visible="handleClose"
  >
    <InputText
      v-model="searchValue"
      data-test-id="search-item-input"
      :placeholder="t('templateBuilder.modals.typeFirstLetters')"
      :style="{ minWidth: '30rem' }"
      @input="handleValueChange"
    />
    <div class="grid mt-5">
      <div class="col-6 mb-5">
        <h3>{{ t('templateBuilder.modals.availableItems') }}</h3>
        <div
          v-if="availableList.length > 0"
          class="item-list mt-2 overflow-hidden"
        >
          <VirtualScroller
            :key="virtualScrollersKey"
            :items="availableList"
            :item-size="38"
            show-loader
            :delay="250"
            class="border border-surface-200 dark:border-surface-700 rounded"
            style="width: 100%; height: 142px"
          >
            <template #item="{ item }">
              <div
                class="grid mr-0"
                style="height: 50px"
              >
                <div class="col-1">
                  <Button
                    icon="far fa-plus"
                    class="p-button-primary"
                    @click="selectItem(item)"
                  />
                </div>
                <div class="col-10 ml-1 my-auto text-left">
                  (#{{ item[fields.id] }}) {{ item.name }}
                </div>
              </div>
            </template>
            <template #loader="{ options }">
              <div
                :class="['flex items-center p-2', { 'bg-surface-100 dark:bg-surface-700': options.odd }]"
                style="height: 50px"
              >
                <Skeleton
                  :width="options.even ? '60%' : '50%'"
                  height="1.3rem"
                />
              </div>
            </template>
          </VirtualScroller>
        </div>
        <div
          v-else
          class="item-list no-items mt-2 p-3"
        >
          <i class="far fa-search mr-1" />
          {{ t('templateBuilder.modals.noItems.available') }}
        </div>
      </div>
      <div class="col-6 mb-5">
        <h3>{{ t('templateBuilder.modals.selectedItems') }}</h3>
        <div
          v-if="selectedList.length > 0"
          class="item-list selected-list mt-2 overflow-hidden"
        >
          <VirtualScroller
            :key="virtualScrollersKey"
            :items="selectedList"
            :item-size="38"
            show-loader
            :delay="250"
            class="border border-surface-200 dark:border-surface-700 rounded"
            style="width: 100%; height: 142px"
          >
            <template #item="{ item }">
              <div
                class="grid mr-0"
                style="height: 50px"
              >
                <div class="col-1">
                  <Button
                    icon="far fa-minus"
                    class="p-button-danger"
                    @click="removeItem(item)"
                  />
                </div>
                <div class="col-10 ml-1 my-auto text-left">
                  (#{{ item[fields.id] }}) {{ item.name }}
                </div>
              </div>
            </template>
            <template #loader="{ options }">
              <div
                :class="['flex items-center p-2', { 'bg-surface-100 dark:bg-surface-700': options.odd }]"
                style="height: 50px"
              >
                <Skeleton
                  :width="options.even ? '60%' : '50%'"
                  height="1.3rem"
                />
              </div>
            </template>
          </VirtualScroller>
        </div>
        <div
          v-else
          class="item-list no-items mt-2 p-3 vertical-align-top"
        >
          <i class="far fa-list mr-1" />
          {{ t('templateBuilder.modals.noItems.selected') }}
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        data-test-id="choose-items-button"
        :label="t('templateBuilder.modals.cancel')"
        icon="far fa-times"
        class="p-button-danger"
        @click="handleClose"
      />
      <Button
        data-test-id="choose-items-button"
        :label="t('templateBuilder.modals.validate')"
        icon="far fa-check"
        @click="handleChooseItems"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import {
  computed, ComputedRef, defineComponent, onMounted, PropType, ref, Ref,
} from 'vue';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';
import { List, ListSettings } from '@/composables/GraphQL';
import { SpmTableFilter, StringMap } from '@/types';
import { showToastError } from '@/helpers';
import { OperatorType } from '@/types/generated-types/graphql';
import VirtualScroller from 'primevue/virtualscroller';
import Skeleton from 'primevue/skeleton';

export default defineComponent({
  name: 'ChooseItemsModal',

  components: {
    Dialog,
    InputText,
    Button,
    VirtualScroller,
    Skeleton,
  },

  props: {
    displayDialog: {
      type: Boolean,
      required: true,
    },

    header: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    fields: {
      type: Object as PropType<StringMap>,
      required: true,
    },

    items: {
      type: String,
      required: false,
      default: '',
    },

    defaultFilters: {
      type: Array as PropType<SpmTableFilter[]>,
    },
  },

  emits: {
    'on-close-dialog': null,
    'on-choose-items': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const virtualScrollersKey = ref(0);
    const displayModal: ComputedRef<boolean> = computed(() => props.displayDialog);
    const searchValue: Ref<string> = ref('');
    const settings: Ref<ListSettings> = ref({
      limit: 100,
      offset: 0,
      order: [],
      filter: [],
    });

    const availableList: Ref<StringMap[]> = ref([]);
    const selectedList: Ref<StringMap[]> = ref([]);

    const fetchData = async (): Promise<StringMap[]> => {
      const res = await List(
        {
          name: props.name,
          settings: settings.value,
          fields: [...Object.values(props.fields)],
        },
      );
      if (res.err) {
        showToastError('GENERIC_ERROR');
      } else {
        return res.items as StringMap[];
      }
      return [];
    };

    const handleValueChange = async () => {
      if (searchValue.value.length > 0) {
        settings.value.filter = props.defaultFilters ? [...props.defaultFilters] : []; // Some itemLists have default filters (lang, active, ...)
        settings.value.filter.push({ field: props.fields.label, value: searchValue.value, operator: OperatorType.Contains });
        availableList.value = await fetchData();
      } else {
        availableList.value = [];
      }
    };

    const handleClose = () => {
      context.emit('on-close-dialog');
    };

    const selectItem = (element: StringMap) => {
      selectedList.value.push(element);
      // remove from available
      availableList.value = availableList.value.filter((el: StringMap) => el[props.fields.id] !== element[props.fields.id]);
      virtualScrollersKey.value += 1;
    };

    const removeItem = (element: StringMap) => {
      availableList.value.push(element);
      // remove from selected
      selectedList.value = selectedList.value.filter((el: StringMap) => el[props.fields.id] !== element[props.fields.id]);
      virtualScrollersKey.value += 1;
    };

    const handleChooseItems = () => {
      context.emit('on-choose-items', selectedList.value);
      handleClose();
    };

    onMounted(async () => {
      if (props.items && props.items !== '') { // If default items, put them in selected list on mount
        settings.value.filter = [
          { field: props.fields.id, value: props.items, operator: OperatorType.In },
        ];
        selectedList.value = await fetchData();
      }
    });

    return {
      t,
      searchValue,
      availableList,
      selectedList,
      displayModal,
      virtualScrollersKey,
      selectItem,
      removeItem,
      handleClose,
      handleValueChange,
      handleChooseItems,
    };
  },
});
</script>

<style scoped lang="scss">
.item-list {
  height: 10rem;
  width: 100%;
  overflow-y: auto;
  border: 1px solid #ced4da;
  border-radius: 0.3rem;
  padding: 0.5rem;
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0.3rem;
    background: #c2c9d2;
  }
  .p-button {
    padding: 0.2rem 0.2rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}
.no-items {
  display: flex;
  align-items: center;
  color: #ced4da;
}
</style>
