import { onBeforeMount, onBeforeUnmount } from 'vue';

/*
 * This function fixes the issue with scrollbars presence when opening a sidebar over a page having scrollbars
 */
export default function useToggleBodyScrollBars() {
  const setBodyOverflow = (value: string) => { document.body.style.overflow = value; };

  onBeforeMount(() => {
    setBodyOverflow('hidden');
  });

  onBeforeUnmount(() => {
    setBodyOverflow('visible');
  });
}
