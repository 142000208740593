<template>
  <div
    class="spm-key-card relative bg-gray-100"
  >
    <div class="flex justify-content-between align-items-center gap-2">
      <span class="font-medium">{{ secretKey }}</span>
      <Button
        class="p-button-secondary"
        icon="far fa-clipboard"
        severity="secondary"
        text
        rounded
        aria-label="Bookmark"
        @click="copyToClipboard"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
} from 'vue';
import Button from 'primevue/button';

import { useI18n } from 'vue-i18n';

import { showToastSuccess } from '@/helpers';

export default defineComponent({
  name: 'SpmKeyCard',

  components: {
    Button,
  },

  props: {
    secretKey: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const copyToClipboard = () => {
      navigator.clipboard.writeText(props.secretKey);
      showToastSuccess(t('copiedToClipboard'));
    };

    return {
      copyToClipboard,
    };
  },
});
</script>

<style lang="scss" scoped>
.spm-key-card {
  padding: 0.25rem 0.5rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}
</style>
