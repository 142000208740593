<template>
  <div class="flex align-items-center w-full">
    {{ option.childrenLabel }}
    <i
      v-if="minimumPlan"
      class="fas fa-star secure-spm-button-star border-circle border-1 text-sm p-1 ml-auto"
      :class="`stats-widget-data-config-option-star--${minimumPlan}`"
    />
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
} from 'vue';
import {
  hasAccessToFeatures,
} from '@/composables/User';
import { PlanName } from '@/types/enums';

export default defineComponent({
  name: 'StatsWidgetDataConfigOption',
  components: {},

  props: {
    option: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const featurePermission = computed(() => hasAccessToFeatures(`${props.option.group}.${props.option.category}.${props.option.metric}`));
    const minimumPlan = computed(() => {
      if (featurePermission.value && featurePermission.value.minimumPlan !== PlanName.STANDARD) {
        return featurePermission.value.minimumPlan;
      }
      return null;
    });

    return {
      minimumPlan,
    };
  },
});
</script>

<style lang="scss" scoped>
.stats-widget-data-config-option-star {
  top: -5px;
  right: -5px;

  &--premium {
    color: $silver;
    width: 25px;
    height: 25px;
  }
  &--ultimate {
    color: $gold;
    width: 25px;
    height: 25px;
  }
}
</style>
