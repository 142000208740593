
import {
  computed, defineComponent, PropType, ref,
} from 'vue';
import { Options } from '@/types';
import Dropdown from 'primevue/dropdown';

export default defineComponent({
  name: 'DropdownRenderer',

  components: {
    Dropdown,
  },

  props: {
    options: {
      type: Array as PropType<Array<Options>>,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },
  },

  setup(props: { options: Options[]; data: any }) {
    const optionsArray = computed(() => props.options);
    const value = ref('');
    return { optionsArray, value };
  },

});
