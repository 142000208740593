import { InputGroupField, Property, StringMap } from '@/types';
// eslint-disable-next-line import/no-cycle
import { generateTranslations } from '@/components/template-builder/utils/translate';
import { TRANSLATION_ATTRIBUTE } from '@/components/template-builder/utils/constants';

import * as cheerio from 'cheerio';

// eslint-disable-next-line import/no-cycle
import {
  getAttributeByName, getClassByName, getCustomDescription, getCustomUrl, getLinkByNetwork, getSocialNetworkIconStyle,
} from '../getters';
// eslint-disable-next-line import/no-cycle
import { getTemplateIframeDocument } from '../utils/helpers';

const DEFAULT_WIDTH = '24';

const facebookPattern = /^(https:\/\/www.facebook.com\/sharer\/sharer.php\?u=)(.*)$/i;
const twitterPattern = /^(https:\/\/twitter.com\/intent\/tweet\?text=)(.*)(&[amp;]*url=)(.*)$/i;
const emailPattern = /^(mailto:\?subject=)(.*)(&[amp;]*body=)(.*)$/i;
const googlePattern = /^(https:\/\/plus.google.com\/share\?url=)(.*)$/i;

interface Network {
  name: string;
  icon: HTMLAnchorElement;
}

/** GLOBAL SOCIAL NETWORK SETTERS */
export const setSocialNetworkIconWidth = (selector: string, property: Property) => {
  const pattern = /^(https:\/\/media.shopimind.(?:com|io)\/img\/icons\/social\/[0-9]{1,2}\/)([0-9]{1,2})(\/.+)$/i;
  const template = getTemplateIframeDocument();
  const images: Array<HTMLImageElement> = Array.from(template?.querySelectorAll(selector));
  images.forEach((image: HTMLImageElement) => {
    image.setAttribute('style', `max-width: ${property.value}px`);
    image.setAttribute('src', image.getAttribute('src')?.replace(pattern, `$1${property.value}$3`) ?? '');
  });
};

export const setSocialNetworkIconStyle = (selector: string, property: Property) => {
  const pattern = /^(https:\/\/media.shopimind.(?:com|io)\/img\/icons\/social\/)([0-9]{1,2})(\/[0-9]{1,2}\/.+)$/i;
  const template = getTemplateIframeDocument();
  const images: Array<HTMLImageElement> = Array.from(template?.querySelectorAll(selector));
  images.forEach((image: HTMLImageElement) => {
    image.setAttribute('src', image.getAttribute('src')?.replace(pattern, `$1${property.value}$3`) ?? '');
  });
};

function createVerticalIcon(network: string, icon: HTMLAnchorElement): HTMLTableRowElement {
  const row: HTMLTableRowElement = document.createElement('tr');
  row.setAttribute('class', `${network} spm_social_icon_container`);
  const cell: HTMLTableCellElement = document.createElement('td');
  cell.setAttribute('class', 'spm_social_icon');
  cell.appendChild(icon);
  row.appendChild(cell);
  return row;
}

function createHorizontalIcon(network: string, icon: HTMLAnchorElement): HTMLTableCellElement {
  const cell: HTMLTableCellElement = document.createElement('td');
  cell.setAttribute('class', `${network} spm_social_icon_container spm_social_icon`);
  cell.appendChild(icon);
  return cell;
}

function createDisplayIcon(network: string, icon: HTMLAnchorElement): HTMLSpanElement {
  const span: HTMLSpanElement = document.createElement('span');
  span.setAttribute('class', `${network} spm_social_icon_container spm_social_icon`);
  span.appendChild(icon);
  return span;
}

function createTableContent(orientation: string, networks: Array<Network>): HTMLTableSectionElement {
  const tbody: HTMLTableSectionElement = document.createElement('tbody');
  if (orientation === 'vertical') {
    networks.forEach((network: Network) => {
      tbody.appendChild(createVerticalIcon(network.name, network.icon));
    });
  } else {
    const row: HTMLTableRowElement = document.createElement('tr');
    networks.forEach((network: Network) => {
      row.appendChild(createHorizontalIcon(network.name, network.icon));
    });
    tbody.appendChild(row);
  }
  return tbody;
}

export const setSocialNetworkOrientation = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  const table = template.querySelector(selector);
  if (table) {
    const containers: HTMLElement[] = Array.from(table.querySelectorAll('.spm_social_icon_container'));
    const networks: Network[] = containers.map((container: HTMLElement) => ({
      name: container.getAttribute('class')?.replace('spm_social_icon_container', '').replace('spm_social_icon', '').trim() ?? '',
      icon: container.querySelector('a') ?? document.createElement('a'),
    }));
    const tbody: HTMLElement|null = template?.querySelector(`${selector} > tbody`);
    if (tbody && property.value) {
      tbody.replaceWith(createTableContent(property.value, networks));
    }
  }
};

const setActiveSocialNetworks = (selector: string, networks: Network[]) => {
  const template = getTemplateIframeDocument();
  const orientation = getClassByName(selector, 'spm_social_icons_');
  const tbody: HTMLElement = template?.querySelector(`${selector} > tbody`) as HTMLElement;
  if (tbody && orientation) {
    tbody.replaceWith(createTableContent(orientation, networks));
  }
};

/** SHARING SOCIAL NETWORK SETTERS */
export const getSocialNetworksLinks = (url: string, description = ''): StringMap => ({
  facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
  twitter: `https://twitter.com/intent/tweet?text=${description}&url=${url}`,
  email: `mailto:?subject=${description}&amp;body=${url}`,
  pinterest: `https://pinterest.com/pin/create/button/?url=${url}&description=${description}`,
  tumblr: `https://www.tumblr.com/share/link?url=${url}&amp;name=&amp;description=${description}`,
  // instagram: '',
  // youtube: '',
  // whatsapp: '',
  // snapchat: '',
});

function createSharingIcon(network: string, width: string, style: string, url: string, description: string): HTMLAnchorElement {
  const anchor = document.createElement('a');
  anchor.setAttribute('href', getSocialNetworksLinks(url, description)[network.replace('spm_', '')]);
  const image = document.createElement('img');
  image.setAttribute('src', `https://media.shopimind.io/img/icons/social/${style}/${width}/${network.replace('spm_', '')}.png`);
  image.setAttribute('width', width);
  anchor.appendChild(image);

  // TODO Create translation for url
  return anchor;
}

export const setActiveSharingSocialNetworks = (selector: string, property: Property) => {
  if (property.value) {
    const width = getAttributeByName(`${selector} img`, 'width') ?? DEFAULT_WIDTH;
    let style = getSocialNetworkIconStyle(`${selector} img`);
    if (!style) {
      style = '1';
    }
    const customUrl = getCustomUrl(selector);
    const customDescription = getCustomDescription(selector);
    const activeSocialNetworks: Array<string> = property.value;
    const networks: Network[] = activeSocialNetworks.map((network: string) => ({
      name: network,
      icon: createSharingIcon(network, width, style, customUrl, customDescription),
    }));
    setActiveSocialNetworks(selector, networks);
  }
};

export const setCustomUrl = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  // change twitter link if exists
  const twitterLink: HTMLElement|null = template?.querySelector(`${selector} .spm_twitter a`);
  if (twitterLink) {
    twitterLink.setAttribute('href', (twitterLink.getAttribute('href') ?? '').replace(twitterPattern, `$1$2$3${property.value}`));
  }
  // change email link if exists
  const emailLink: HTMLElement|null = template?.querySelector(`${selector} .spm_email a`);
  if (emailLink) {
    emailLink.setAttribute('href', (emailLink.getAttribute('href') ?? '').replace(emailPattern, `$1$2$3${property.value}`));
  }
  // change facebook link if exists
  const facebookLink: HTMLElement|null = template?.querySelector(`${selector} .spm_facebook a`);
  if (facebookLink) {
    facebookLink.setAttribute('href', (facebookLink.getAttribute('href') ?? '').replace(facebookPattern, `$1${property.value}`));
  }
  // change google link if exists
  const googleLink: HTMLElement|null = template?.querySelector(`${selector} .spm_googleplus a`);
  if (googleLink) {
    googleLink.setAttribute('href', (googleLink.getAttribute('href') ?? '').replace(googlePattern, `$1${property.value}`));
  }
};

export const setCustomDescription = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  // change twitter link if exists
  const twitterLink: HTMLElement|null = template?.querySelector(`${selector} .spm_twitter a`);
  if (twitterLink) {
    twitterLink.setAttribute('href', (twitterLink.getAttribute('href') ?? '').replace(twitterPattern, `$1${property.value}$3$4`));
  }
  // change email link if exists
  const emailLink: HTMLElement|null = template?.querySelector(`${selector} .spm_email a`);
  if (emailLink) {
    emailLink.setAttribute('href', (emailLink.getAttribute('href') ?? '').replace(emailPattern, `$1${property.value}$3$4`));
  }
};

/** FOLLOW SOCIAL NETWORK SETTERS */
function createFollowIcon(selector: string, network: string, width: string, style: string, link: string): HTMLAnchorElement {
  let translation: Record<string, any> | string = {};
  const oldElement = getTemplateIframeDocument().querySelector(selector);

  const anchor = document.createElement('a');
  anchor.setAttribute('href', link);
  const image = document.createElement('img');
  image.setAttribute('src', `https://media.shopimind.io/img/icons/social/${style}/${width}/${network.replace('spm_', '')}.png`);
  image.setAttribute('width', width);

  // Check if translation already exists
  const oldLink = oldElement?.querySelector(`.${network} a`);
  if (oldLink && oldLink.hasAttribute(`${TRANSLATION_ATTRIBUTE}-href`)) {
    translation = {
      attribute: `${TRANSLATION_ATTRIBUTE}-href`,
      value: oldLink.getAttribute(`${TRANSLATION_ATTRIBUTE}-href`),
    };
  } else {
    // Create translation for link
    const translationVariable = network.replace('spm_', '') === 'email' ? 'contact' : network.replace('spm_', '');
    const { newHtml } = generateTranslations(`<a href="{var=social.${translationVariable}_url}"></a>`, { newElement: true });
    const $ = cheerio.load(newHtml);
    translation = {
      attribute: `${TRANSLATION_ATTRIBUTE}-href`,
      value: $('a').attr(`${TRANSLATION_ATTRIBUTE}-href`),
    };
  }

  anchor.appendChild(image);

  // Add translation property on new element
  if (typeof translation === 'object' && 'attribute' in translation && translation.attribute) {
    anchor.setAttribute(translation.attribute, translation.value);
  }

  return anchor;
}

export const setActiveFollowSocialNetworks = (selector: string, property: Property) => {
  if (property.value) {
    const width = getAttributeByName(`${selector} img`, 'width') ?? DEFAULT_WIDTH;
    let style = getSocialNetworkIconStyle(`${selector} img`);
    if (!style) {
      style = '1';
    }
    const activeSocialNetworks: Array<string> = property.value;
    const networks: Network[] = activeSocialNetworks.map((network: string) => ({
      name: network,
      icon: createFollowIcon(selector, network, width, style, getLinkByNetwork(selector, network)),
    }));
    setActiveSocialNetworks(selector, networks);
  }
};

export const setActiveFollowSocialNetworksForDisplay = (selector: string, property: Property) => {
  if (property.value) {
    const width = getAttributeByName(`${selector} img`, 'width') ?? DEFAULT_WIDTH;
    let style = getSocialNetworkIconStyle(`${selector} img`);
    if (!style) {
      style = '1';
    }
    const activeSocialNetworks: Array<string> = property.value;
    const template = getTemplateIframeDocument();
    const container = template.querySelector(selector);
    if (container) {
      const newContainer = document.createElement('div');
      newContainer.setAttribute('class', container.getAttribute('class') ?? '');
      activeSocialNetworks.forEach((network: string) => {
        const icon = createFollowIcon(selector, network, width, style, getLinkByNetwork(selector, network));
        newContainer.appendChild(createDisplayIcon(network, icon));
      });
      container.replaceWith(newContainer);
    }
  }
};

export const setSocialNetworkLinks = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  const networks: HTMLElement|null = template?.querySelector(selector);
  property.value.forEach((network: InputGroupField) => {
    const link: HTMLAnchorElement = networks?.querySelector(`.${network.key} a`) as HTMLAnchorElement;
    if (link) {
      link.setAttribute('href', network.value);
    }
  });
};

export const sortSocialNetworks = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  const parent: HTMLElement = template?.querySelector(selector) as HTMLElement;
  const networks: HTMLElement[] = Array.from(parent?.querySelectorAll('.spm_social_icon_container'));
  const sortedIds = property.value.map((element: InputGroupField) => element.key);

  networks.sort((a: Element, b: Element) => { // Sort fields in updated order
    const firstIndex = a.getAttribute('class')?.replace('spm_social_icon_container', '').replace('spm_social_icon', '').replace(new RegExp('spm_hide', 'g'), '');
    const secondIndex = b.getAttribute('class')?.replace('spm_social_icon_container', '').replace('spm_social_icon', '').replace(new RegExp('spm_hide', 'g'), '');
    if (firstIndex && secondIndex) {
      if (sortedIds.indexOf(firstIndex) === -1 || sortedIds.indexOf(secondIndex) === -1) return 1;
      return sortedIds.indexOf(firstIndex) - sortedIds.indexOf(secondIndex);
    }
    return 1;
  })
    .forEach((field: Element) => {
      if (parent) parent.append(field);
    });
};
