import { CompleteComponentData, CompleteComponentMetadata } from '@/types/complete-types';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { computed } from 'vue';
import useVuelidate from '@vuelidate/core';
import {
  helpers,
  maxLength,
  minLength,
  required,
} from '@vuelidate/validators';

export interface UserInfosData {
  firstName: string;
  lastName: string;
  societe: string;
  vatNumber: string;
  rue: string;
  complement: string;
  zipCode: string;
  city: string;
  country: string;
  phoneMobile: string;
  phoneFixe: string;
}

const UserInfosMetadata: CompleteComponentMetadata<UserInfosData> = {
  Create(data: UserInfosData = {
    firstName: '',
    lastName: '',
    societe: '',
    vatNumber: '',
    rue: '',
    complement: '',
    zipCode: '',
    city: '',
    country: '',
    phoneMobile: '',
    phoneFixe: '',
  }): CompleteComponentData<UserInfosData> {
    return {
      type: 'UserInfos',
      ...data,
    };
  },
  async Validate(data: UserInfosData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      lastName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30),
      },
      firstName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30),
      },
      societe: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(30),
      },
      rue: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
      },
      city: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
      },
      zipCode: {
        required,
      },
      country: {
        required,
      },
      phoneMobile: {
        required,
      },
      phoneFixe: {
        required,
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
};

export default UserInfosMetadata;
