
import {
  computed, defineComponent, onMounted, reactive, ref, Ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import { PrimvueMenuModel } from '@/types';
import {
  Me, refreshTokenLogin, setChangingShop, Shop, SwitchActiveShop, UserState,
} from '@/composables/User';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import solutions from '@/configs/ecommerce-solutions';
import Tooltip from 'primevue/tooltip';
import Dropdown from 'primevue/dropdown';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { required } from '@vuelidate/validators';
import { urlFormatValidator } from '@/helpers/CustomValidator';
import useVuelidate from '@vuelidate/core';
import { nestGet } from '@/composables/nestApi';
import { showToastError, showToastSuccess } from '@/helpers';

export default defineComponent({
  name: 'MySitesMenu',
  components: {
    SpmButton,
    FieldErrors,
    SpmOverlayPanel,
    SpmPanelMenu,
    InputText,
    Dialog,
    Dropdown,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    addShop: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const currentShopName = computed(() => (UserState?.activeShop?.name && UserState?.activeShop?.name !== '' ? UserState?.activeShop?.name : t('changeSite')));
    const searchValue = ref('');
    const openAddShop = ref(false);
    const closePanelActions = ref(false);
    const visibleAddNewSiteModal = ref(false);
    const loadingAddNewSite = ref(false);
    const formValidationErrors = ref({});
    const componentFieldErrorsKey = ref(0);

    const newShopInfos = reactive<any>({
      shopUrl: '',
      solutionEcommerce: '',
    });

    const shopToMenu = (shop: Shop) => {
      const label = shop.name ?? shop.url;
      const command = async () => {
        store.commit('general/showTheSpinner');
        try {
          const res: any = await refreshTokenLogin(shop.id);
          if (res && res.success) {
            const { idShop } = res;
            await Me(true, Number(idShop));
            store.commit('liveEditor/resetWebsiteContents');
            window.location.reload();
          } else {
            await showToastError('GENERIC_ERROR');
          }
        } catch (e) {
          await showToastError('GENERIC_ERROR');
        }

        store.commit('general/hideTheSpinner');
      };
      return { label, command };
    };

    const items: Ref<PrimvueMenuModel[]> = computed(() => {
      const regex = new RegExp(searchValue.value, 'i');
      const shops = UserState.user.shops
        .filter((s: Shop) => s.id !== UserState?.activeShop?.id && (regex.test(s.name) || regex.test(s.id.toString()) || regex.test(s.url)))
        .map(shopToMenu);
      return [
        ...shops,
      ].slice(-10);
    });
    const nbTotalShops = computed(() => UserState.user.shops.length);

    const validateShopInfo = async (): Promise<ErrorConfigForm> => {
      const rules = {
        shopUrl: {
          urlFormatValidator: urlFormatValidator(),
        },

        solutionEcommerce: {
          required,
        },
      };

      const v$ = useVuelidate(rules, newShopInfos);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    const addNewSiteAction = async () => {
      loadingAddNewSite.value = true;
      const validation = await validateShopInfo();
      if (!validation.success) {
        formValidationErrors.value = validation.validate;
        componentFieldErrorsKey.value += 1;
        loadingAddNewSite.value = false;
      } else {
        const postData = {
          idUser: UserState.user.id ?? 0,
          shopUrl: newShopInfos.shopUrl,
          solutionEcommerce: newShopInfos.solutionEcommerce,
        };
        const dataQuery = JSON.stringify(postData);

        try {
          store.commit('general/showTheSpinner');
          const result = await nestGet('v4', '/shop/create', {}, `?data=${dataQuery}`);

          if (result && result.success && result.newIdShop && result.newIdShop > 0) {
            await showToastSuccess('savedSuccessful');
            const res: any = await refreshTokenLogin(result.newIdShop);
            if (res && res.success) {
              const { idShop } = res;
              await Me(true, Number(idShop));
              store.commit('liveEditor/resetWebsiteContents');
              window.location.reload();
            } else {
              await showToastError('GENERIC_ERROR');
            }
          }
        } catch (error) {
          await showToastError('GENERIC_ERROR');
        }
        store.commit('general/hideTheSpinner');
        loadingAddNewSite.value = false;
      }
    };

    const addNewSiteItem: Ref<PrimvueMenuModel[]> = ref([
      {
        icon: 'fa-regular fa-plus',
        label: t('mySitesMenu.addNewSite'),
        command: () => {
          visibleAddNewSiteModal.value = true;
          closePanelActions.value = true;
        },
      },
    ]);

    onMounted(async () => {
      const statusRegistration = await nestGet('v4', '/user/stateRegister', {}, '');
      if (statusRegistration && statusRegistration.success && statusRegistration.status === 'closed') {
        openAddShop.value = false;
      } else {
        openAddShop.value = true;
      }
    });

    return {
      t,
      openAddShop,
      formValidationErrors,
      componentFieldErrorsKey,
      nbTotalShops,
      searchValue,
      currentShopName,
      items,
      addNewSiteItem,
      closePanelActions,
      visibleAddNewSiteModal,
      newShopInfos,
      solutions,
      loadingAddNewSite,
      addNewSiteAction,
    };
  },
});
