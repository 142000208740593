<template>
  <div
    v-if="modelValue.shopRequiredDataPushNotification_textPopup"
    class="field"
  >
    <label>{{ t('storeParameters.services.popupSubscriptionText') }}</label>
    <LocalizedTextInput
      id="shopRequiredDataPushNotification_textPopup"
      v-model="field.shopRequiredDataPushNotification_textPopup"
    />
    <FieldErrors
      :key="componentFieldErrorsKey"
      :errors="errors"
      field="shopRequiredDataPushNotification_textPopup"
    />
  </div>
  <div class="grid">
    <div class="p-fluid col-12 lg:col-6 pr-5">
      <div class="field">
        <label>{{ t('storeParameters.services.popupBackgroundColor') }}</label>
        <SimpleColorPicker
          id="shopRequiredDataPushNotification_backgroundColorPopup"
          :selected-color="field.shopRequiredDataPushNotification_backgroundColorPopup"
          @color-changed="(value) => field.shopRequiredDataPushNotification_backgroundColorPopup= value"
        />
      </div>
    </div>
    <div class="p-fluid col-12 lg:col-6 pl-5">
      <div class="field">
        <label>{{ t('storeParameters.services.popupTextColor') }}</label>
        <SimpleColorPicker
          id="shopRequiredDataPushNotification_textColorPopup"
          :selected-color="field.shopRequiredDataPushNotification_textColorPopup"
          @color-changed="(value) => field.shopRequiredDataPushNotification_textColorPopup= value"
        />
      </div>
    </div>
  </div>
  <div
    v-if="field.shopRequiredDataPushNotification_textButtonPopup"
    class="field"
  >
    <label>{{ t('storeParameters.services.buttonText') }}</label>
    <LocalizedTextInput
      id="shopRequiredDataPushNotification_textButtonPopup"
      v-model="field.shopRequiredDataPushNotification_textButtonPopup"
    />
    <FieldErrors
      :key="componentFieldErrorsKey"
      :errors="errors"
      field="shopRequiredDataPushNotification_textButtonPopup"
    />
  </div>
  <div class="grid">
    <div class="p-fluid col-12 lg:col-6 pr-5">
      <div class="field">
        <label>{{ t('storeParameters.services.buttonBackgroundColor') }}</label>
        <SimpleColorPicker
          id="shopRequiredDataPushNotification_buttonColorPopup"
          :selected-color="field.shopRequiredDataPushNotification_buttonColorPopup"
          @color-changed="(value) => field.shopRequiredDataPushNotification_buttonColorPopup = value"
        />
      </div>
    </div>
    <div class="p-fluid col-12 lg:col-6 pl-5">
      <div class="field">
        <label>{{ t('storeParameters.services.buttonTextColor') }}</label>
        <SimpleColorPicker
          id="shopRequiredDataPushNotification_textButtonColorPopup"
          :selected-color="field.shopRequiredDataPushNotification_textButtonColorPopup"
          @color-changed="(value) => field.shopRequiredDataPushNotification_textButtonColorPopup = value"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  PropType,
} from 'vue';

import SimpleColorPicker from '@/components/fields/SimpleColorPicker.vue';
import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Popup',

  components: {
    LocalizedTextInput,
    SimpleColorPicker,
    FieldErrors,
  },

  props: {
    modelValue: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },

    errors: {
      type: Object,
      required: true,
    },

    componentFieldErrorsKey: {
      type: Number,
      required: true,
    },

  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const field = computed({
      get() {
        return props.modelValue;
      },

      set(newValue) {
        emit('update:modelValue', newValue);
      },
    });

    return {
      t,
      field,
    };
  },
});
</script>
