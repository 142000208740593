import { ComponentNameEnum, BaseButtonFieldConfig } from '@/types';

const property = 'border-radius';

const getBorderRadiusFieldConfig = (defaultValue = '15px'): BaseButtonFieldConfig => ({
  componentName: ComponentNameEnum.BUTTON_GROUP,
  options: {
    '0px': {
      title: 'templateBuilder.fields.noBorderRadius',
      iconClass: 'fa-square-full',
      property,
      defaultValue,
    },
    '5px': {
      title: 'templateBuilder.fields.mediumBorderRadius',
      iconClass: 'fa-square',
      property,
      defaultValue,
    },
    '15px': {
      title: 'templateBuilder.fields.roundedBorderRadius',
      iconClass: 'fa-circle',
      property,
      defaultValue,
    },
  },
});

export default getBorderRadiusFieldConfig;
