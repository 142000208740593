import { Feature, PlanName } from '@/types/enums';
import {
  PricingPlan,
  PricingFeature,
  SmsPlan,
} from '@/types/offer-types';

export const allFeatures: Record<Feature, PricingFeature> = {
  [Feature.UNLIMITED_EMAILS_CONTACT_NUMBERS]: { hidden: false },
  [Feature.AUTOMATION_CAMPAIGN]: { hidden: false },
  [Feature.POPIN_SMART_CONTENT_FORMS]: { hidden: false },
  [Feature.SMS_AUTOMATION_AND_CAMPAIGN]: { hidden: false },
  [Feature.PUSH_IN_AUTOMATION]: { hidden: false },
  [Feature.LIST_AND_SEGMENT_MANAGEMENT]: { hidden: false },
  [Feature.MULTICHANNEL_PRODUCT_RECOMMENDATION]: { hidden: false },
  [Feature.STATISTICAL_DASHBOARD_AND_EMAIL_STATISTICAL_REPORTS]: { hidden: false },
  [Feature.EMAIL_SUPPORT]: { hidden: false },
  [Feature.PUSH_IN_CAMPAIGNS]: { hidden: false },
  [Feature.MULTI_ACCOUNT]: { hidden: false },
  [Feature.ADVANCED_EXPORT_ALL_DATATABLES]: { hidden: false },
  [Feature.API]: { hidden: true },
  [Feature.DELIVERABILITY_STATS]: { hidden: false },
  [Feature.STAT_BUILDER]: { hidden: false },
  [Feature.APPOINTMENT_CUSTOMER_SUCCESS]: { hidden: false },
  [Feature.TELEPHONE_SUPPORT]: { hidden: false },
  [Feature.CUSTOM_DATA]: { hidden: true },
  [Feature.APPOINTMENT_DELIVERABILITY_EXPERT]: { hidden: true },
  [Feature.CONDITIONAL_BLOCKS_BUILDER]: { hidden: true },
  [Feature.PRICE_PER_SMS]: { smsPrice: true, hidden: false },
  [Feature.SMS_PRICE_WITHIN_SUBSCRIPTION_NON_CARRYOVER]: { planSmsPrice: true, hidden: false },
};

const standardFeatures = [
  Feature.UNLIMITED_EMAILS_CONTACT_NUMBERS,
  Feature.AUTOMATION_CAMPAIGN,
  Feature.POPIN_SMART_CONTENT_FORMS,
  Feature.SMS_AUTOMATION_AND_CAMPAIGN,
  Feature.PUSH_IN_AUTOMATION,
  Feature.LIST_AND_SEGMENT_MANAGEMENT,
  Feature.MULTICHANNEL_PRODUCT_RECOMMENDATION,
  Feature.STATISTICAL_DASHBOARD_AND_EMAIL_STATISTICAL_REPORTS,
  Feature.EMAIL_SUPPORT,
];

const premiumFeatures = [
  Feature.PUSH_IN_CAMPAIGNS,
  Feature.MULTI_ACCOUNT,
  Feature.ADVANCED_EXPORT_ALL_DATATABLES,
  Feature.API,
  Feature.DELIVERABILITY_STATS,
  Feature.STAT_BUILDER,
  Feature.APPOINTMENT_CUSTOMER_SUCCESS,
  Feature.TELEPHONE_SUPPORT,
];

const ultimateFeatures = [
  Feature.CUSTOM_DATA,
  Feature.APPOINTMENT_DELIVERABILITY_EXPERT,
  Feature.CONDITIONAL_BLOCKS_BUILDER,
];

function createPricingPlan(planName: PlanName, includedFeatures: Feature[]): PricingPlan {
  const planFeatures: Record<Feature, PricingFeature> = {} as Record<Feature, PricingFeature>;

  // Iterate over all features and set them based on includedFeatures
  Object.keys(Feature)
    .forEach((featureKey) => {
      const feature = Feature[featureKey as keyof typeof Feature];

      const featureConfig = allFeatures[feature];
      if (featureConfig) {
        if ('smsPrice' in featureConfig || 'planSmsPrice' in featureConfig) {
          planFeatures[feature] = { ...featureConfig };
        } else {
          // If smsPrice is not present, set the value based on inclusion
          const isFeatureIncluded = includedFeatures.includes(feature);
          planFeatures[feature] = {
            ...featureConfig,
            value: featureConfig.value !== undefined ? featureConfig.value : isFeatureIncluded,
          };
        }
      }
    });

  return {
    name: planName,
    features: planFeatures,
  };
}

const pricingPlansFeatures: Record<PlanName, PricingPlan> = {
  [PlanName.STANDARD]: createPricingPlan(PlanName.STANDARD, standardFeatures),
  [PlanName.PREMIUM]: createPricingPlan(PlanName.PREMIUM, [...standardFeatures, ...premiumFeatures]),
  [PlanName.ULTIMATE]: createPricingPlan(PlanName.ULTIMATE, [...standardFeatures, ...premiumFeatures, ...ultimateFeatures]),
};

export const smsPlans: SmsPlan[] = [
  {
    label: '0 SMS',
    value: 0,
  },
  {
    label: '100 SMS',
    value: 100,
  },
  {
    label: '200 SMS',
    value: 200,
  },
  {
    label: '500 SMS',
    value: 500,
  },
  {
    label: '1 000 SMS',
    value: 1000,
  },
  {
    label: '1 500 SMS',
    value: 1500,
  },
  {
    label: '2 000 SMS',
    value: 2000,
  },
  {
    label: '2 500 SMS',
    value: 2500,
  },
  {
    label: '3 000 SMS',
    value: 3000,
  },
  {
    label: '3 500 SMS',
    value: 3500,
  },
  {
    label: '4 000 SMS',
    value: 4000,
  },
  {
    label: '4 500 SMS',
    value: 4500,
  },
  {
    label: '5 000 SMS',
    value: 5000,
  },
  {
    label: '10 000 SMS',
    value: 10000,
  },
  {
    label: '20 000 SMS',
    value: 20000,
  },
  {
    label: '30 000 SMS',
    value: 30000,
  },
  {
    label: '40 000 SMS',
    value: 40000,
  },
  {
    label: '50 000 SMS',
    value: 50000,
  },
];

export const yearlyPriceReduction = 0;

export default pricingPlansFeatures;

export const pricingPlansLevel: Record<string, number> = {
  [PlanName.STANDARD]: 0,
  [PlanName.PREMIUM]: 1,
  [PlanName.ULTIMATE]: 2,
};

export const productIds: Record<string, any> = {
  [PlanName.STANDARD]: {
    monthly: process.env.VUE_APP_STANDARD_SUBSCRIPTION_MONTHLY_PRODUCT_ID ? +process.env.VUE_APP_STANDARD_SUBSCRIPTION_MONTHLY_PRODUCT_ID : 0,
    yearly: process.env.VUE_APP_STANDARD_SUBSCRIPTION_YEARLY_PRODUCT_ID ? +process.env.VUE_APP_STANDARD_SUBSCRIPTION_YEARLY_PRODUCT_ID : 0,
  },
  [PlanName.PREMIUM]: {
    monthly: process.env.VUE_APP_PREMIUM_SUBSCRIPTION_MONTHLY_PRODUCT_ID ? +process.env.VUE_APP_PREMIUM_SUBSCRIPTION_MONTHLY_PRODUCT_ID : 0,
    yearly: process.env.VUE_APP_PREMIUM_SUBSCRIPTION_YEARLY_PRODUCT_ID ? +process.env.VUE_APP_PREMIUM_SUBSCRIPTION_YEARLY_PRODUCT_ID : 0,
  },
  [PlanName.ULTIMATE]: {
    monthly: process.env.VUE_APP_ULTIMATE_SUBSCRIPTION_MONTHLY_PRODUCT_ID ? +process.env.VUE_APP_ULTIMATE_SUBSCRIPTION_MONTHLY_PRODUCT_ID : 0,
    yearly: process.env.VUE_APP_ULTIMATE_SUBSCRIPTION_YEARLY_PRODUCT_ID ? +process.env.VUE_APP_ULTIMATE_SUBSCRIPTION_YEARLY_PRODUCT_ID : 0,
  },
};

export const smsProductIds = {
  monthly: process.env.VUE_APP_SMS_PACK_MONTHLY_PRODUCT_ID ? +process.env.VUE_APP_SMS_PACK_MONTHLY_PRODUCT_ID : 0,
  yearly: process.env.VUE_APP_SMS_PACK_YEARLY_PRODUCT_ID ? +process.env.VUE_APP_SMS_PACK_YEARLY_PRODUCT_ID : 0,
};
