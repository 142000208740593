<template>
  {{ modelValue.nothing }}
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted, PropType, SetupContext, watch,
} from 'vue';
import DeclencheurSubscribeToNewsletterMetaData, {
  DeclencheurSubscribeToNewsletterData,
} from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurSubscribeToNewsletterMetadata';

export default defineComponent({
  name: 'DeclencheurSubscribeToNewsletter',
  components: {},

  props: {
    modelValue: {
      type: Object as PropType<DeclencheurSubscribeToNewsletterData>,
      required: true,
      default() {
        return DeclencheurSubscribeToNewsletterMetaData.Create();
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: DeclencheurSubscribeToNewsletterData }, context: SetupContext) {
    const update = () => context.emit('update:modelValue', DeclencheurSubscribeToNewsletterMetaData.Create()); // todo

    watch([], update);
    onMounted(update);

    return {};
  },
});
</script>
