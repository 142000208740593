<template>
  <div class="mt-2">
    <h3 class="mb-4">
      {{ t('shop.my_old_newsletter_stats.title') }}
    </h3>
    <div class="grid">
      <div class="col-12">
        <div class="iframe-container">
          <iframe
            ref="iframeRef"
            :src="iframeSrc"
            sandbox="allow-scripts allow-same-origin"
            class="full-size-iframe"
            @load="handleLoad"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { defineComponent, onMounted, ref } from 'vue';
import { UserState } from '@/composables/User';
import CryptoJS from 'crypto-js';

export default defineComponent({
  name: 'MyOldNewslettersStatistics',
  setup() {
    const { t } = useI18n();
    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
    const iframeSrc = ref('');
    const query = ref('/advisor/logAsShop/');
    const iframeRef = ref(null);

    const handleLoad = () => {
      console.log('Iframe loaded successfully');
      // const iframeDocument = iframeRef.value?.contentDocument || iframeRef.value?.contentWindow.document;
      //
      // if (!iframeDocument) {
      //   console.error('Cannot access iframe content');
      // }

      // TODO : access to the iframe content and change some css (remove the header and the footer) (cross origin problem)
    };

    onMounted(() => {
      const cryptedKey = CryptoJS.SHA1(`remotestatisticsZn26687S2682MF2ZV4Ag2g9JGbhF686X${idShop.value}`).toString();
      query.value += `${idShop.value}/remotestatistics/${cryptedKey}`;
      iframeSrc.value = `${process.env.VUE_APP_ZEND_URL}${query.value}`;
    });

    return {
      t,
      iframeSrc,
      iframeRef,
      handleLoad,
    };
  },
});
</script>

<style lang="scss">
.iframe-container {
  width: 100%;
  height: 100vh;
}

.full-size-iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
