<template>
  <div class="flex">
    <div class="w-6 auth-container hidden lg:flex">
      <div class="flex auth-logo">
        <img
          src="@/assets/images/logo-tc-black.svg"
          alt="logo"
        >
      </div>

      <div class="grid align-items-center justify-content-center min-w-full">
        <div class="col-9 text-center mx-auto">
          <Carousel
            v-if="$route.name === 'user.registration-speed'"
            :value="carousel"
            :num-visible="1"
            :num-scroll="1"
            :show-navigators="false"
            :show-indicators="false"
            circular
            :autoplay-interval="3000"
          >
            <template #item="slotProps">
              <div class="carousel-data text-center">
                <q class="my-6 text-2xl lg:text-2xl xl:text-3xl h-8rem block">
                  {{ slotProps.data.title }}
                </q>
                <div
                  class="mx-auto bg-center bg-contain bg-no-repeat w-20rem h-7rem mb-6"
                  :style="{ backgroundImage: 'url(' + slotProps.data.image + ')' }"
                />
                <p class="text-xl">
                  {{ slotProps.data.author }}
                </p>
                <p class="my-2 text-base h-3rem">
                  {{ slotProps.data.position }}
                </p>
                <div
                  class="mx-auto ">
                  <i
                    class="fa-solid fa-star text-2xl"
                    style="color: var(--yellow-300)"
                  />&nbsp;
                  <i
                    class="fa-solid fa-star text-2xl"
                    style="color: var(--yellow-300)"
                  /> &nbsp;
                  <i
                    class="fa-solid fa-star text-2xl"
                    style="color: var(--yellow-300)"
                  /> &nbsp;
                  <i
                    class="fa-solid fa-star text-2xl"
                    style="color: var(--yellow-300)"
                  /> &nbsp;
                  <i
                    class="fa-solid fa-star text-2xl"
                    style="color: var(--yellow-300)"
                  />
                </div>
              </div>
            </template>
          </Carousel>
          <Carousel
            v-else
            :value="carousel"
            :num-visible="1"
            :num-scroll="1"
            :show-navigators="false"
            circular
            :autoplay-interval="3000"
          >
            <template #item="slotProps">
              <div class="carousel-data text-center">
                <h2 class="my-6">
                  {{ slotProps.data.title }}
                </h2>
                <div
                  class="my-6 border-round mx-auto bg-center bg-contain bg-no-repeat w-28rem h-28rem m-3 bg-white"
                  :style="{ backgroundImage: 'url(' + slotProps.data.image + ')' }"
                />
                <p class="my-6 h-4rem">
                  {{ slotProps.data.desc }}
                </p>
              </div>
            </template>
          </Carousel>
        </div>
      </div>

    </div>
    <div class="flex w-12 lg:w-6 auth-content">
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { defineComponent, ref, watch } from 'vue';
import Carousel from 'primevue/carousel';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'LayoutAuthentication',

  components: {
    Carousel,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const carouselData = {
      registration: [
        {
          title: 'Nous avons été séduits par sa facilité d\'utilisation et surtout par l\'accompagnement des équipes ShopiMind',
          image: '/images/logos-testimonials/archiduchesse.png',
          author: 'Pauline Calvo',
          position: 'Cheffe de projet E-commerce',
        },
        {
          title: 'Après un benchmark Shopimind semblait être la solution qui permettait de répondre à une grande partie de nos besoins',
          image: '/images/logos-testimonials/speedway.svg',
          author: 'Rodolphe Ricard',
          position: 'Responsable Acquisition',
        },
        {
          title: 'Une solution performante que nous utilisons depuis plusieurs années. L\'équipe est réactive et très sympathique (ce qui est très appréciable)',
          image: '/images/logos-testimonials/generationsouvenir.png',
          author: 'Sébastien Surget',
          position: 'Directeur',
        },
        {
          title: 'Très bon rapport qualité prix pour ce logiciel d\'abandon de panier omnicanal. Je recommande',
          image: '/images/logos-testimonials/beelong.svg',
          author: 'Benoit Delporte',
          position: 'Co-Fondateur',
        },
      ],

      login: [
        {
          title: t('authentication.slides.newCampaignEditor'),
          image: 'https://media.shopimind.io/img/v4/campagnes-multicanales.jpg',
          // eslint-disable-next-line max-len
          desc: t('authentication.slides.newCampaignEditorText'),
        },
        {
          title: t('authentication.slides.multiUsers'),
          image: 'https://media.shopimind.io/img/v4/multi-user-V2.jpg',
          // eslint-disable-next-line max-len
          desc: t('authentication.slides.multiUsersText'),
        },
        {
          title: t('authentication.slides.contactWithShopiteam'),
          image: 'https://media.shopimind.io/img/v4/aide-utilisateur.jpg',
          // eslint-disable-next-line max-len
          desc: t('authentication.slides.contactWithShopiteamText'),
        },
      ],
    };

    const carousel = ref();

    watch(() => router.currentRoute.value.name, (route) => {
      if (route === 'user.registration-speed') {
        carousel.value = carouselData.registration;
      } else {
        carousel.value = carouselData.login;
      }
    }, { immediate: true });

    return {
      t,
      carousel,
    };
  },
});
</script>

<style scoped lang="scss">
  .auth-container {
    background-color: #F8F9FA;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    color:#495057;

    .auth-logo {
      position: absolute;
      top: 3rem;
      left: 3rem;
      width: 12rem;
    }

    .p-button {
      color:#94c840;
      background:transparent;
    }
    :deep().p-carousel .p-carousel-indicators .p-carousel-indicator button {
      background-color:#fff;
      color:#fff;
      width:1rem;
      height:1rem;
      border-radius:50%;
      background-color:#c7e09e;
      color:#c7e09e;
    }
    :deep().p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      background-color:#94c840;
      color:#94c840;
    }
    :deep().p-carousel .text-base {color:#94c840;}

  }

  .auth-content {
    background: #fff;
    min-height: 100vh;
    max-width: none;
  }
</style>
