<template>
  <SpmOverlayPanel
    class-trigger="flex text-center profile-btn"
    class-header="flex align-items-center profile-info justify-content-left"
    class-content="flex align-items-center justify-content-center spm-overlay-panel-content"
    class-footer="flex align-items-center justify-content-center spm-overlay-panel-content"
  >
    <template #trigger>
      <div
        class="avatar"
        data-test-id="profile-btn"
      >
        <span class="initials">
          {{ initials }}
        </span>
      </div>
      <div class="text ml-1">
        <div
          class="text-xs text-left capitalize"
        >
          {{ t(`user.types.${UserState.user.userType}`) }}
        </div>
        <div
          class="font-bold text-left"
        >
          {{ UserState.user.firstName }} {{ UserState.user.lastName }}
        </div>
      </div>
    </template>
    <template #header>
      <div
        class="avatar"
        data-test-id="profile-btn"
      >
        <span class="initials">
          {{ initials }}
        </span>
      </div>
      <div class="text ml-1">
        <div
          class="font-bold text-left"
        >
          {{ UserState.user.firstName }} {{ UserState.user.lastName }}
        </div>
        <div
          class="text-xs text-left  "
        >
          {{ UserState?.user.email }}
        </div>
      </div>
    </template>
    <SpmPanelMenu
      :items="profileMenuItems"
    />
    <template #footer>
      <SpmPanelMenu
        :items="logoutItem"
      />
    </template>
  </SpmOverlayPanel>
</template>

<script lang="ts">
import {
  computed, defineComponent, ref, Ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import { PrimvueMenuModel } from '@/types';
import { findPermission, Logout, UserState } from '@/composables/User';
import { getErrorMessage, showToastError } from '@/helpers';
import { useRouter } from 'vue-router';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';

export default defineComponent({
  name: 'MyProfileMenu',
  components: {
    SpmOverlayPanel,
    SpmPanelMenu,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    // eslint-disable-next-line max-len
    const initials = computed(() => ((UserState.user?.firstName && UserState.user?.lastName) ? `${UserState.user.firstName.substring(0, 1)}${UserState.user.lastName.substring(0, 1)}` : ''));

    const logout = async () => {
      store.commit('general/showTheSpinner');
      try {
        await Logout();
      } catch (error) {
        await showToastError(getErrorMessage(error));
      } finally {
        await router.push({ name: 'login' });
        window.location.reload();
      }
    };

    const profileMenuItems: Ref<PrimvueMenuModel[]> = ref([
      {
        icon: 'fa-regular fa-user',
        label: t('profileMenu.myInformation'),
        to: { name: 'user.profile' },
      },
      {
        icon: 'fa-regular fa-chart-pie',
        label: t('profileMenu.statsListing'),
        to: { name: 'users.stats' },
      },
      {
        icon: 'fa-regular fa-users-gear',
        label: t('profileMenu.permissions'),
        to: { name: 'user.sub-user-list' },
        visible: () => findPermission('admin'),
      },
      // {
      //   icon: 'fa-regular fa-gear',
      //   label: t('profileMenu.manageMyAccount'),
      //   to: { name: 'user.manage-my-account' },
      // },
    ]);

    const logoutItem: Ref<PrimvueMenuModel[]> = ref([
      {
        label: t('profileMenu.logout'),
        icon: 'fa-regular fa-arrow-right-from-bracket',
        command: logout,
        class: 'data-test-logout-btn',
      },
    ]);

    return {
      t,
      UserState,
      profileMenuItems,
      logoutItem,
      initials,
    };
  },
});
</script>

<style lang="scss" scoped>
.profile-btn {
  cursor: pointer;

  .avatar {
    position: relative;
    padding: 2px;
    border-radius: 50%;
    width: 2.1rem;
    height: 2.1rem;
    background-color: $brand-color-primary;
    color: $white;
    cursor: pointer;

    & .initials {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .text {
    color: #6b7280;
  }
}
</style>

<style lang="scss">
.profile-info {
  padding: 1rem 0.5rem;

  .avatar {
    position: relative;
    padding: 2px;
    border-radius: 50%;
    width: 2.3rem;
    height: 2.3rem;
    background-color: $brand-color-primary;
    color: $white;

    & .initials {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .text {
    :first-child {
      color: #1f2937;
    }

    color: #6b7280;
  }
}
</style>
