
import { defineComponent, ref, watch } from 'vue';
import ToggleButton from 'primevue/togglebutton';
import { AutomatedScenarioState as state, linkOperator, unlinkOperator } from '@/composables/AutomatedScenarios';

export default defineComponent({
  name: 'OperatorSelectForm',

  components: {
    ToggleButton,
  },

  props: {
    operatorId: {
      type: String,
      required: true,
    },
  },

  setup(props: {operatorId: string}) {
    const checked = ref(state.linkedOperators.includes(props.operatorId));

    const handleChange = () => {
      if (checked.value) {
        linkOperator(props.operatorId);
      } else {
        unlinkOperator(props.operatorId);
      }
    };

    watch(state.linkedOperators, () => {
      checked.value = state.linkedOperators.includes(props.operatorId);
    });

    return {
      checked,
      handleChange,
    };
  },
});
