
import {
  computed, defineComponent, PropType, ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { AutomatedScenarioState as state } from '@/composables/AutomatedScenarios';
import Badge from 'primevue/badge';
import Tooltip from 'primevue/tooltip';

export default defineComponent({
  name: 'FilterPanelContent',

  components: {
    Badge,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    panels: {
      type: Array as PropType<string[]>,
      required: true,
    },

    activeItems: {
      type: Object,
      required: false,
    },
  },

  setup() {
    const { t } = useI18n();
    const activePanelIndex = ref(0);
    const configuringSegment = computed(() => state.configuringSegment);

    const togglePanel = (index: number) => {
      if (index === activePanelIndex.value) {
        activePanelIndex.value = -1;
      } else {
        activePanelIndex.value = index;
      }
    };

    return {
      t,
      activePanelIndex,
      togglePanel,
      configuringSegment,
    };
  },
});
