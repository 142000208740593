
import {
  defineComponent, onMounted, reactive, Ref, ref, watch,
} from 'vue';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Tooltip from 'primevue/tooltip';
import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';
import { DropdownOption } from '@/types';
import categories from '@/configs/shop-categories';
import solutions from '@/configs/ecommerce-solutions';
import currencies from '@/configs/currencies';
import { Lang, loadShopsLang, UserState } from '@/composables/User';
import fetchShopInformation, { ShopsLangCustom, updateShop } from '@/composables/shop/Shops';
import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import useVuelidate from '@vuelidate/core';
import { showToastError, showToastSuccess } from '@/helpers';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import { ShopsLang } from '@/types/generated-types/graphql';
import ManageLanguages from '@/views/shop/tabs/modals/ManageLanguages.vue';
import AddNewLang from '@/views/shop/tabs/modals/AddNewLang.vue';
import { required, url } from '@vuelidate/validators';
import moment from 'moment';

interface StoreInfoState {
  name: string;
  solution_ecommerce: string;
  id_category_shop: number;
  currency: string;
  url_client: string;
  default_lang: Record<string, any>;
}

interface ShopsLangState {
  url: Record<string, any>;
  url_cart: Record<string, any>;
}

export default defineComponent({
  name: 'StoreInformations',

  components: {
    AddNewLang,
    InputText,
    Dropdown,
    Button,
    LocalizedTextInput,
    FieldErrors,
    ManageLanguages,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    idShop: {
      type: Number,
      required: true,
    },
  },

  emits: {
    'on-change': Object,
    'default-language': String,
    'refresh-component': String,
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const error = ref();
    const shopsLangData = ref<ShopsLang[]>([]);
    const componentFieldErrorsKey = ref(0);
    const showManageLangsModal = ref(false);
    const showNewLangModal = ref(false);
    const defaultUrl = ref();
    const defaultUrlCart = ref();
    const languagesUpdateKey = ref(0);
    const updateKey = ref(0);
    const needsToReloaded = ref(false);
    const shopLanguages = ref<DropdownOption[]>(UserState.activeShop?.langs ? UserState.activeShop?.langs.map((language: Lang) => ({
      label: language.id,
      value: `/images/flags/${language.id}.png`,
    })) : []);

    const shopsInfostate = reactive<StoreInfoState>({
      name: '',
      solution_ecommerce: '',
      id_category_shop: 0,
      currency: '',
      url_client: '',
      default_lang: {},
    });

    const idShop = UserState.activeShop?.id ?? 0;

    const defaultLang = ref('');

    function sendDefaultLang(lang: string) {
      shopsLangData.value.forEach((shopsLang: ShopsLang) => {
        if (shopsLang.lang === lang) {
          defaultUrl.value = shopsLang.url;
          defaultUrlCart.value = shopsLang.url_cart;
        }
      });
      emit('default-language', { lang });
    }

    async function retrieveShopInfo() {
      const info = await fetchShopInformation({ shopId: idShop });
      shopsLangData.value = [];

      if (info === null) {
        return;
      }

      info.langs.forEach((shopsLang: ShopsLangCustom) => {
        shopsLangData.value.push({
          id_shop: idShop,
          lang: shopsLang.lang,
          url: shopsLang.url,
          url_cart: shopsLang.urlCart,
          default: shopsLang.default,
        });
      });

      defaultLang.value = info.defaultLang;
      shopsInfostate.name = info.name;
      shopsInfostate.solution_ecommerce = info.solutionEcommerce;
      shopsInfostate.id_category_shop = info.idCategoryShop;
      shopsInfostate.currency = info.currency;
      shopsInfostate.url_client = info.urlClient;
      shopsInfostate.default_lang = { label: info.defaultLang, value: `/images/flags/${info.defaultLang}.png` };
    }

    const validateShopInfo = async (): Promise<ErrorConfigForm> => {
      const rules = {
        name: {
          required,
        },

        solution_ecommerce: {
          required,
        },

        id_category_shop: {
          required,
        },

        currency: {
          required,
        },

        url_client: {
          required,
          url,
        },

        default_lang: {
          required,
        },

      };

      const v$ = useVuelidate(rules, shopsInfostate);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    const updateDefaultLangInGlobalState = () => {
      Object.entries(shopsInfostate)
        .every((keyValuePair: any) => {
          if (keyValuePair[0] === 'default_lang') {
            loadShopsLang(idShop);
            emit('refresh-component');
            return true;
          }
          return false;
        });
    };


    const saveStoreInfo = async () => {
      const shopArray: Record<string, any> = {};
      const shopValidations = await validateShopInfo();

      if (!shopValidations.success) {
        error.value = shopValidations.validate;
        componentFieldErrorsKey.value += 1;
        return;
      }

      Object.entries(shopsInfostate)
        .forEach((keyValuePair: any) => {
          if (keyValuePair[0] !== 'default_lang') {
            shopArray[keyValuePair[0]] = keyValuePair[1];
          }
        });

      shopArray.id_shop = idShop;
      shopArray.date_modification = moment().format('YYYY-MM-DD HH:mm:ss');

      updateShop([shopArray])
        .then(() => {
          updateDefaultLangInGlobalState();
          showToastSuccess('savedSuccessful');
        })
        .catch(async (err: any) => {
          await showToastError('GENERIC_ERROR');
        });
    };

    const openNewLang = () => {
      showManageLangsModal.value = false;
      showNewLangModal.value = true;
    };

    const closeNewLangModal = async (newRecordLang: ShopsLang, reload: boolean) => {
      showManageLangsModal.value = true;
      showNewLangModal.value = false;
      if (reload) {
        languagesUpdateKey.value += 1;
        needsToReloaded.value = true;
        shopsLangData.value.push(newRecordLang);
      }
    };

    const closeManageLangModal = async (reload: boolean) => {
      if (reload || needsToReloaded.value) {
        await retrieveShopInfo();
        emit('refresh-component');
        updateKey.value += 1;
      }
      showManageLangsModal.value = false;
      showNewLangModal.value = false;
      needsToReloaded.value = false;
      shopLanguages.value = UserState.activeShop?.langs ? UserState.activeShop?.langs.map((language: Lang) => ({
        label: language.id,
        value: `/images/flags/${language.id}.png`,
      })) : [];
    };

    const reloadLanguages = async () => {
      await retrieveShopInfo();
    };

    onMounted(async () => {
      await retrieveShopInfo();
    });

    watch(shopsInfostate.default_lang, () => {
      sendDefaultLang(defaultLang.value);
    }, { deep: true });

    return {
      solutions,
      categories,
      currencies,
      shopLanguages,
      t,
      saveStoreInfo,
      sendDefaultLang,
      shopsInfostate,
      error,
      componentFieldErrorsKey,
      showManageLangsModal,
      showNewLangModal,
      shopsLangData,
      openNewLang,
      closeNewLangModal,
      defaultUrl,
      defaultUrlCart,
      languagesUpdateKey,
      closeManageLangModal,
      reloadLanguages,

    };
  },
});
