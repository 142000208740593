import {
  OperatorType,
  ShopsUsers,
  ShopsUsersDeleteInput,
  ShopsUsersInputItem,
  ShopsUsersUpdateInputItem,
  UsersPermissionsDeleteInputItem,
  UsersPermissionsInputItem,
  UsersPermissionsUpdateInputItem,
  UsersPermissionsUpsertInputItem,
} from '@/types/generated-types/graphql';
import {
  Insert, List, Delete, Get, Update, CustomSingleMutation,
} from '@/composables/GraphQL';
import { UserState } from '@/composables/User';
import { SpmTableFilter } from '@/types';

export const getShopsUser = async (idUser: number) => Get<ShopsUsers>({
  name: 'ShopsUsers',
  id: idUser,
  keyName: 'id_shops_user',
  fields: ['id_shops_user', 'first_name', 'last_name', 'email', 'lang', 'date_creation',
    'lang', 'user_permissions{id_shop, expiry_date, period, permissions}'],
});

export async function shopUserList(email: string|null = null) {
  const filters: SpmTableFilter[] = [
    { field: 'id_ps_customer', value: UserState.user.idPsCustomer, operator: OperatorType.Equals },
    { field: 'active', value: 1, operator: OperatorType.Equals },
  ];

  if (email) {
    filters.push({ field: 'email', value: email, operator: OperatorType.Equals });
  }

  return List<ShopsUsers>({
    name: 'ShopsUsers',
    settings: {
      limit: 0,
      offset: 0,
      order: [],
      filter: filters,
    },
    fields: [
      'id_shops_user', 'first_name', 'last_name', 'email', 'lang', 'date_creation',
    ],
  });
}

export async function getShopSubUsers(idPsCustomer: number) {
  const filters: SpmTableFilter[] = [
    { field: 'id_ps_customer', value: idPsCustomer, operator: OperatorType.Equals },
    { field: 'active', value: 1, operator: OperatorType.Equals },
  ];

  return List<ShopsUsers>({
    name: 'ShopsUsers',
    settings: {
      limit: 0,
      offset: 0,
      order: [],
      filter: filters,
    },
    fields: [
      'id_shops_user', 'first_name', 'last_name', 'email', 'lang',
    ],
  });
}

export default async function InsertShopsUsers(shopUsersInput: ShopsUsersInputItem[]) {
  return Insert<ShopsUsersInputItem>({
    name: 'ShopsUsers',
    input: shopUsersInput,
    type: 'ShopsUsersInput',
  });
}

export const UpdateShopsUsers = async (shopUsersUpdateInput: ShopsUsersUpdateInputItem[]) => Update<ShopsUsersUpdateInputItem>({
  name: 'ShopsUsers',
  input: shopUsersUpdateInput,
  type: 'ShopsUsersUpdateInput',
});

export const InsertUsersPermissions = async (UsersPermissionsInput: UsersPermissionsInputItem[]) => Insert<UsersPermissionsInputItem>({
  name: 'UsersPermissions',
  input: UsersPermissionsInput,
  type: 'UsersPermissionsInput',
});

export const UpdateUsersPermissions = async (UsersPermissionsUpdateInput: UsersPermissionsUpdateInputItem[]) => Update<UsersPermissionsUpdateInputItem>({
  name: 'UsersPermissions',
  input: UsersPermissionsUpdateInput,
  type: 'UsersPermissionsUpdateInput',
});

export const UpsertUsersPermissions = async (UsersPermissionsUpsertInput: UsersPermissionsUpsertInputItem[]) => CustomSingleMutation<UsersPermissionsUpsertInputItem[]>(
  {
    name: 'UpsertUsersPermissions',
    input: UsersPermissionsUpsertInput,
    type: 'UsersPermissionsUpsertInput',
  },
);

export const DeleteShopsUsers = async (shopUsersDeleteInput: ShopsUsersDeleteInput[]) => Delete<ShopsUsersDeleteInput>({
  name: 'ShopsUsers',
  input: shopUsersDeleteInput,
  type: 'ShopsUsersDeleteInput',
});

export const DeleteUsersPermissions = async (UsersPermissionsDeleteInput: UsersPermissionsDeleteInputItem[]) => Delete<UsersPermissionsDeleteInputItem>({
  name: 'UsersPermissions',
  input: UsersPermissionsDeleteInput,
  type: 'UsersPermissionsDeleteInput',
});
