<template>
  <div class="font-family-block">
    <Dropdown
      data-test-id="font-family-dropdown"
      :model-value="selectedFontFamily"
      class="col-12 p-0 font-family-dropdown"
      :class="{ 'hidden': !displayProperties }"
      :placeholder="t(configs.label)"
      :options="fontFamilyList"
      option-label="label"
      option-value="value"
      dropdown-class="stepper-input"
      @change="handleSelectedFont"
    >
      <template #option="slotProps">
        <div class="p-dropdown-name">
          <span>{{ slotProps.option.label }}</span>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, PropType, ref, onBeforeMount, Ref,
} from 'vue';
import Dropdown from 'primevue/dropdown';
import { useI18n } from 'vue-i18n';

import { FieldConfig, ParserFieldObject, Property } from '@/types';
import getFontFamilyList from '@/configs/font-family';

export default defineComponent({
  name: 'FontFamily',
  components: {
    Dropdown,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const configs = ref(props.configs);
    const displayProperties = ref(!(!Object.prototype.hasOwnProperty.call(configs.value, 'displayToggle') || configs.value.displayToggle === true));
    const property: Ref<Property> = ref(props.parserValues.properties && props.parserValues.properties[0]);
    const selectedFontFamily = ref(property.value.value ?? props.configs.options.defaultValue);
    const fontFamilyList = ref([{}]);

    onBeforeMount(async () => {
      try {
        fontFamilyList.value = await getFontFamilyList();
      } catch (error) {
        fontFamilyList.value = [{}];
      }
    });

    const handleSelectedFont = (data: { value: string }) => {
      selectedFontFamily.value = data.value;
      if (property.value) {
        property.value.value = selectedFontFamily.value;
        context.emit('on-change-properties', {
          selector: props.parserValues.selector,
          properties: [property.value],
        });
      }
    };

    return {
      t,
      displayProperties,
      selectedFontFamily,
      fontFamilyList,
      handleSelectedFont,
    };
  },
});
</script>

<style scoped lang="scss">
.p-dropdown-name{
  white-space: break-spaces;
  width: $configuration-panel-width - 5.25rem;
}
.font-family-block .font-family-dropdown {
  border: 1px solid $heather;
  border-radius: $field-border-radius;

  &:hover {
    border-color: $brand-color-primary;
    color: $brand-color-primary;
    box-shadow: none;
  }

  &.p-focus {
    border-color: $brand-color-primary;
    color: $brand-color-primary;
    box-shadow: $box-shadow;
  }

  &:enabled:focus {
    box-shadow: none;
  }

  :deep() .p-dropdown-label.p-inputtext {
    font-size: .9375rem;
    line-height: 1.25rem;
    padding: .1875rem;
  }
}
</style>
