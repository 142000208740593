import {
  ConfigurationPanelStructure,
  ConfigurationPanelStructureItemEnum,
  TemplateStructureEnum,
  TabItem,
  AppearEffectEnum,
  ElementPositionEnum,
  CloseIconPositionValueEnum,
  CloseIconListEnum,
  AutomaticallyCloseEnum,
  ComponentNameEnum,
  StepperSignEnum,
  BackgroundImageOptionTypeEnum,
} from '@/types';
import getDropdownFieldConfig from '@/components/template-builder/config/fields/dropdown-config';
import boxShadowFieldConfig from '@/components/template-builder/config/fields/box-shadow-config';
import opacityFieldConfig from '@/components/template-builder/config/fields/opacity-config';
import minimalHeightFieldConfig from '@/components/template-builder/config/fields/minimal-height-config';
import getColorFieldConfig from '@/components/template-builder/config/fields/color-config';
import getWidthFieldConfig from '@/components/template-builder/config/fields/width-config';
// eslint-disable-next-line import/no-cycle
import {
  cleanCssByProperty,
  recalculateLineHeightValue,
  setAttribute,
  setClass,
  setCssProperty,
  setCssPropertyAndRemoveIfZero,
  setCssPropertyWithImportant,
  setCssPropertyWithImportantAndRemoveIfZero,
  toggleClass,
  setBackgroundImage,
  setBackgroundImageProperty, setCssButtonWidthProperty,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import {
  getAttributeByName, getBackgroundCssProperty,
  getClassByName,
  getCssPropertyByName,
  hasClassElementAndChild,
  isVisible,
} from '@/components/template-builder/getters';
import getIconsFieldConfig from '@/components/template-builder/config/fields/icons-config';
import getDisplayFieldConfig from '@/components/template-builder/config/fields/display-config';
import { POPIN_AUTO_CLOSE_ATTRIBUTE } from '@/components/template-builder/utils/constants';
import getVerticalSpacesFieldConfig from '@/components/template-builder/config/fields/vertical-spaces-config';
import getHorizontalSpacesFieldConfig from '@/components/template-builder/config/fields/horizontal-spaces-config';
import getTopBorderRadiusFieldConfig from '@/components/template-builder/config/fields/top-border-radius-config';
import getBottomBorderRadiusFieldConfig from '@/components/template-builder/config/fields/bottom-border-radius-config';
import bordersFieldConfig from '@/components/template-builder/config/fields/borders-config';
import itemsVerticalAlignFieldConfig from '@/components/template-builder/config/fields/items-vertical-align-config';
import getBackgroundImageFieldConfig from '@/components/template-builder/config/fields/background-image-config';
import fontFieldConfig from '@/components/template-builder/config/fields/font-config';
import getTextAlignFieldConfig from '@/components/template-builder/config/fields/text-align-config';
import lineHeightFieldConfig from '@/components/template-builder/config/fields/line-height-config';
import getStepperFieldConfig from '@/components/template-builder/config/fields/stepper-config';
import textStylesFieldConfig from '@/components/template-builder/config/fields/text-styles-config';
import getBorderRadiusFieldConfig from '@/components/template-builder/config/fields/border-radius-config';
import getElementAlignFieldConfig from '@/components/template-builder/config/fields/element-align-config';
import divAlignmentFieldConfig from '@/components/template-builder/config/fields/div-alignment-config';
// eslint-disable-next-line import/no-cycle
import { setDivAlignmentClass } from '@/components/template-builder/setters/div-alignment';
import getButtonWidthFieldConfig from '@/components/template-builder/config/fields/button-width-config';

export const pageTabItemConfig: TabItem = {
  type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
  tabItemType: TemplateStructureEnum.PAGE,
  label: 'templateBuilder.configs.page',
  tabPanel: {
    label: 'templateBuilder.configs.page',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.pageStyles',
        selector: 'body #spm_body',
        items: [
          {
            label: 'templateBuilder.fields.backgroundColor',
            selector: '#spm_body.spm_popin #spm_content, #spm_body #spm_content',
            properties: [{ name: 'background', getter: getBackgroundCssProperty, setters: [setCssPropertyWithImportant] }],
            ...getColorFieldConfig('#ffffff', true, true),
            displayToggle: true,
          },
          {
            label: 'templateBuilder.fields.borders',
            selector: '#spm_body #spm_content',
            properties: [
              {
                name: 'border-color',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'border-style',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-width',
                getter: getCssPropertyByName,
                setters: [setCssPropertyAndRemoveIfZero],
              },
              {
                name: 'border-top-color',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-top-width',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-top-style',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'border-bottom-color',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-bottom-width',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-bottom-style',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'border-left-color',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-left-width',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-left-style',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'border-right-color',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-right-width',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-right-style',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
            ],
            ...bordersFieldConfig,
          },
          {
            label: 'templateBuilder.fields.backgroundImage',
            selector: '#spm_body.spm_popin #spm_content, #spm_body #spm_content',
            properties: [
              { name: 'background-image', getter: getCssPropertyByName, setters: [setBackgroundImage] },
              { name: 'background-size', getter: getCssPropertyByName, setters: [setBackgroundImageProperty] },
              { name: 'background-position-x', getter: getCssPropertyByName, setters: [setBackgroundImageProperty] },
              { name: 'background-position-y', getter: getCssPropertyByName, setters: [setBackgroundImageProperty] },
              { name: 'background-repeat', getter: getCssPropertyByName, setters: [setBackgroundImageProperty] },
            ],
            ...getBackgroundImageFieldConfig(BackgroundImageOptionTypeEnum.FULL),
          },
          {
            label: 'templateBuilder.fields.verticalAlignment',
            selector: '#spm_body #spm_content',
            properties: [
              { name: 'align-items', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...itemsVerticalAlignFieldConfig,
            displayToggle: false,
          },
          {
            label: 'templateBuilder.fields.appearEffect',
            selector: '#spm_body #spm_content',
            properties: [{ name: 'spmEffect_', getter: getClassByName, setters: [setClass] }],
            ...getDropdownFieldConfig(Object.values(AppearEffectEnum).map((effect) => ({ label: effect, value: effect })), AppearEffectEnum.NONE),
            displayToggle: false,
          },
          {
            label: 'templateBuilder.fields.popinPosition',
            selector: '#spm_body .spm_content_wrapper',
            properties: [{ name: 'spm_box', getter: getClassByName, setters: [setClass] }],
            ...getDropdownFieldConfig(Object.values(ElementPositionEnum)
              .map((position) => ({ value: position, label: `templateBuilder.fields.positions.${position}` })), ElementPositionEnum.MIDDLE_CENTER),
            displayToggle: false,
          },
          {
            label: 'templateBuilder.fields.overlay',
            selector: '#spm_body .spm_display_overlay',
            properties: [{
              name: 'display', getter: getCssPropertyByName, setters: [setCssPropertyWithImportant],
            }],
            ...getDisplayFieldConfig('display', 'block', 'none', 'block'),
            displayToggle: false,
            fieldId: 'overlay',
            enableValue: 'block',
          },
          {
            label: 'templateBuilder.fields.overlayAppearEffect',
            selector: '#spm_body .spm_display_overlay',
            properties: [{ name: 'spmEffect_', getter: getClassByName, setters: [setClass] }],
            ...getDropdownFieldConfig(Object.values(AppearEffectEnum).map((effect) => ({ label: effect, value: effect })), AppearEffectEnum.NONE),
            displayToggle: false,
            parentFieldId: 'overlay',
            isVisible,
          },
          {
            label: 'templateBuilder.fields.overlayBackgroundColor',
            selector: '#spm_body .spm_display_overlay',
            properties: [{
              name: 'background',
              getter: getCssPropertyByName,
              setters: [setCssProperty],
            }],
            ...getColorFieldConfig('#000000', false, true),
            displayToggle: false,
            parentFieldId: 'overlay',
            isVisible,
          },
          {
            label: 'templateBuilder.fields.overlayOpacity',
            selector: '#spm_body .spm_display_overlay',
            properties: [{ name: 'opacity', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...opacityFieldConfig,
            displayToggle: false,
            parentFieldId: 'overlay',
            isVisible,
          },
          {
            label: 'templateBuilder.fields.hideAfter.label',
            selector: '#spm_body',
            properties: [{ name: POPIN_AUTO_CLOSE_ATTRIBUTE, getter: getAttributeByName, setters: [setAttribute] }],
            ...getDropdownFieldConfig(Object.values(AutomaticallyCloseEnum)
              .filter((v) => !Number.isNaN(Number(v)))
              .map((delay) => ({ label: `templateBuilder.fields.hideAfter.${delay}seconds`, value: delay })), AutomaticallyCloseEnum.NONE),
            displayToggle: false,
          },
          {
            label: 'templateBuilder.fields.displayCloseIcon',
            selector: '#spm_body .spm_close_button',
            properties: [{
              name: 'display', getter: getCssPropertyByName, setters: [setCssPropertyWithImportant],
            }],
            ...getDisplayFieldConfig('display', 'inline-block', 'none', 'inline-block'),
            displayToggle: false,
            fieldId: 'displayCloseIcon',
            enableValue: 'inline-block',
          },
          {
            label: 'templateBuilder.fields.closeIconPosition',
            selector: '#spm_body .spm_close_button',
            properties: [{ name: 'spm_closeposition', getter: getClassByName, setters: [setClass] }],
            ...getDropdownFieldConfig(Object.values(CloseIconPositionValueEnum)
              .map((position) => ({ value: position, label: `templateBuilder.fields.positions.${position}` })), CloseIconPositionValueEnum.TOP_RIGHT),
            displayToggle: false,
            parentFieldId: 'displayCloseIcon',
            isVisible,
          },
          {
            label: 'templateBuilder.fields.closeIconStyle',
            selector: '#spm_body .spm_close_button',
            properties: [{ name: 'spm_icon-', getter: getClassByName, setters: [setClass] }],
            ...getIconsFieldConfig(Object.values(CloseIconListEnum), 'spm_icon-', 'cancel-1'),
            displayToggle: false,
            parentFieldId: 'displayCloseIcon',
            isVisible,
          },
          {
            label: 'templateBuilder.fields.closeIconColor',
            selector: '#spm_body #spm_content a.spm_close_button',
            properties: [{ name: 'color', getter: getCssPropertyByName, setters: [setCssPropertyWithImportant] }],
            ...getColorFieldConfig('#000000'),
            displayToggle: false,
            parentFieldId: 'displayCloseIcon',
            isVisible,
          },
          {
            label: 'templateBuilder.fields.width',
            selector: '#spm_body.spm_popin #spm_content',
            properties: [
              { name: 'width', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'min-width', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...getWidthFieldConfig(50),
            displayToggle: false,
          },
          {
            label: 'templateBuilder.fields.minimalHeight',
            selector: '#spm_body #spm_content',
            properties: [{ name: 'min-height', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...minimalHeightFieldConfig,
            displayToggle: false,
          },
          {
            label: 'templateBuilder.fields.borderRadius',
            selector: '#spm_body.spm_popin #spm_content',
            properties: [
              { name: 'border-top-left-radius', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-top-right-radius', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-bottom-left-radius', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-bottom-right-radius', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getTopBorderRadiusFieldConfig().options,
              ...getBottomBorderRadiusFieldConfig().options,
            },
          },
          {
            label: 'templateBuilder.fields.boxShadow',
            selector: '#spm_body #spm_content',
            properties: [{ name: 'box-shadow', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...boxShadowFieldConfig,
          },
          {
            label: 'templateBuilder.fields.responsiveRow',
            selector: '#spm_body #spm_content .spm_row',
            displayToggle: false,
            properties: [{ name: 'spm_responsive', getter: hasClassElementAndChild, setters: [toggleClass] }],
            ...getDisplayFieldConfig('spm_responsive', ''),
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.pageMargins',
        selector: 'body table#spm_body',
        items: [
          {
            label: 'templateBuilder.fields.contentInnerMargins',
            selector: '#spm_body #spm_content',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig('padding').options,
            },
          },
          {
            label: 'templateBuilder.fields.lineInnerMargins',
            selector: '#spm_body .spm_row',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig('padding').options,
            },
          },
          {
            label: 'templateBuilder.fields.columnInnerMargins',
            selector: '#spm_body .spm_column',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig().options,
            },
          },
          {
            label: 'templateBuilder.fields.widgetInnerMargins',
            selector: '#spm_body .spm_widget',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig().options,
            },
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
        tabItemType: TemplateStructureEnum.GROUP,
        label: 'templateBuilder.configs.pageTextsAndTitles',
        tabPanel: {
          items: [
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.pageTexts',
              selector: '#spm_body #spm_content',
              items: [
                {
                  label: 'templateBuilder.fields.font',
                  selector: '{SELECTOR}, {SELECTOR} td, {SELECTOR} th',
                  properties: [
                    { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...fontFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.textAlign',
                  selector: '{SELECTOR}, {SELECTOR} td, {SELECTOR} th',
                  properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getTextAlignFieldConfig(),
                },
                {
                  label: 'templateBuilder.fields.lineHeight.label',
                  selector: '{SELECTOR}, {SELECTOR} td, {SELECTOR} th',
                  properties: [{ name: 'line-height', getter: getCssPropertyByName, setters: [cleanCssByProperty, setCssProperty] }],
                  ...lineHeightFieldConfig,
                  displayToggle: false,
                },
                {
                  label: 'templateBuilder.fields.spaceBetweenChars',
                  selector: '{SELECTOR}, {SELECTOR} td, {SELECTOR} th',
                  properties: [{ name: 'letter-spacing', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getStepperFieldConfig(StepperSignEnum.PX),
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.pageLinks',
              selector: '#spm_body #spm_content',
              items: [
                {
                  label: 'templateBuilder.fields.color',
                  selector: '{SELECTOR} a, {SELECTOR} a:hover, {SELECTOR} a:visited, {SELECTOR} a:active',
                  properties: [{ name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getColorFieldConfig('#333333'),
                  options: {
                    defaultValue: '#2795b6',
                  },
                },
                {
                  label: 'templateBuilder.fields.style',
                  selector: '{SELECTOR} a, {SELECTOR} a:hover, {SELECTOR} a:visited, {SELECTOR} a:active',
                  properties: [
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...textStylesFieldConfig,
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.title1',
              selector: '#spm_body #spm_content',
              items: [
                {
                  label: 'templateBuilder.fields.font',
                  selector: '{SELECTOR} h1, {SELECTOR} td h1, {SELECTOR} th h1',
                  properties: [
                    { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...fontFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.titleMarginVertical',
                  selector: '{SELECTOR} h1, {SELECTOR} td h1, {SELECTOR} th h1',
                  properties: [
                    { name: 'margin-top', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'margin-bottom', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...getVerticalSpacesFieldConfig('margin'),
                },
                {
                  label: 'templateBuilder.fields.titleMarginHorizontal',
                  selector: '{SELECTOR} h1, {SELECTOR} td h1, {SELECTOR} th h1',
                  properties: [
                    { name: 'margin-left', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'margin-right', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...getHorizontalSpacesFieldConfig('margin'),
                },
                {
                  label: 'templateBuilder.fields.lineHeight.label',
                  selector: '{SELECTOR} h1, {SELECTOR} td h1, {SELECTOR} th h1',
                  properties: [{ name: 'line-height', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...lineHeightFieldConfig,
                  displayToggle: false,
                },
                {
                  label: 'templateBuilder.fields.spaceBetweenChars',
                  selector: '{SELECTOR} h1, {SELECTOR} td h1, {SELECTOR} th h1',
                  properties: [{ name: 'letter-spacing', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getStepperFieldConfig(StepperSignEnum.PX),
                },
                {
                  label: 'templateBuilder.fields.titleAlign',
                  selector: '{SELECTOR} h1, {SELECTOR} td h1, {SELECTOR} th h1',
                  properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getTextAlignFieldConfig(),

                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.title2',
              selector: '#spm_body #spm_content',
              items: [
                {
                  label: 'templateBuilder.fields.font',
                  selector: '{SELECTOR} h2, {SELECTOR} td h2, {SELECTOR} th h2',
                  properties: [
                    { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...fontFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.titleMarginVertical',
                  selector: '{SELECTOR} h2, {SELECTOR} td h2, {SELECTOR} th h2',
                  properties: [
                    { name: 'margin-top', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'margin-bottom', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...getVerticalSpacesFieldConfig('margin'),
                },
                {
                  label: 'templateBuilder.fields.titleMarginHorizontal',
                  selector: '{SELECTOR} h2, {SELECTOR} td h2, {SELECTOR} th h2',
                  properties: [
                    { name: 'margin-left', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'margin-right', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...getHorizontalSpacesFieldConfig('margin'),
                },
                {
                  label: 'templateBuilder.fields.lineHeight.label',
                  selector: '{SELECTOR} h2, {SELECTOR} td h2, {SELECTOR} th h2',
                  properties: [{ name: 'line-height', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...lineHeightFieldConfig,
                  displayToggle: false,
                },
                {
                  label: 'templateBuilder.fields.spaceBetweenChars',
                  selector: '{SELECTOR} h2, {SELECTOR} td h2, {SELECTOR} th h2',
                  properties: [{ name: 'letter-spacing', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getStepperFieldConfig(StepperSignEnum.PX),
                },
                {
                  label: 'templateBuilder.fields.titleAlign',
                  selector: '{SELECTOR} h2, {SELECTOR} td h2, {SELECTOR} th h2',
                  properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getTextAlignFieldConfig(),

                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.title3',
              selector: '#spm_body #spm_content',
              items: [
                {
                  label: 'templateBuilder.fields.font',
                  selector: '{SELECTOR} h3, {SELECTOR} td h3, {SELECTOR} th h3',
                  properties: [
                    { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...fontFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.titleMarginVertical',
                  selector: '{SELECTOR} h3, {SELECTOR} td h3, {SELECTOR} th h3',
                  properties: [
                    { name: 'margin-top', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'margin-bottom', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...getVerticalSpacesFieldConfig('margin'),
                },
                {
                  label: 'templateBuilder.fields.titleMarginHorizontal',
                  selector: '{SELECTOR} h3, {SELECTOR} td h3, {SELECTOR} th h3',
                  properties: [
                    { name: 'margin-left', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'margin-right', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...getHorizontalSpacesFieldConfig('margin'),
                },
                {
                  label: 'templateBuilder.fields.lineHeight.label',
                  selector: '{SELECTOR} h3, {SELECTOR} td h3, {SELECTOR} th h3',
                  properties: [{ name: 'line-height', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...lineHeightFieldConfig,
                  displayToggle: false,
                },
                {
                  label: 'templateBuilder.fields.spaceBetweenChars',
                  selector: '{SELECTOR} h3, {SELECTOR} td h3, {SELECTOR} th h3',
                  properties: [{ name: 'letter-spacing', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getStepperFieldConfig(StepperSignEnum.PX),
                },
                {
                  label: 'templateBuilder.fields.titleAlign',
                  selector: '{SELECTOR} h3, {SELECTOR} td h3, {SELECTOR} th h3',
                  properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getTextAlignFieldConfig(),

                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.title4',
              selector: '#spm_body #spm_content',
              items: [
                {
                  label: 'templateBuilder.fields.font',
                  selector: '{SELECTOR} h4, {SELECTOR} td h4, {SELECTOR} th h4',
                  properties: [
                    { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...fontFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.titleMarginVertical',
                  selector: '{SELECTOR} h4, {SELECTOR} td h4, {SELECTOR} th h4',
                  properties: [
                    { name: 'margin-top', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'margin-bottom', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...getVerticalSpacesFieldConfig('margin', 10),
                },
                {
                  label: 'templateBuilder.fields.titleMarginHorizontal',
                  selector: '{SELECTOR} h4, {SELECTOR} td h4, {SELECTOR} th h4',
                  properties: [
                    { name: 'margin-left', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'margin-right', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...getHorizontalSpacesFieldConfig('margin'),
                },
                {
                  label: 'templateBuilder.fields.lineHeight.label',
                  selector: '{SELECTOR} h4, {SELECTOR} td h4, {SELECTOR} th h4',
                  properties: [{ name: 'line-height', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...lineHeightFieldConfig,
                  displayToggle: false,
                },
                {
                  label: 'templateBuilder.fields.spaceBetweenChars',
                  selector: '{SELECTOR} h4, {SELECTOR} td h4, {SELECTOR} th h4',
                  properties: [{ name: 'letter-spacing', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getStepperFieldConfig(StepperSignEnum.PX),
                },
                {
                  label: 'templateBuilder.fields.titleAlign',
                  selector: '{SELECTOR} h4, {SELECTOR} td h4, {SELECTOR} th h4',
                  properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getTextAlignFieldConfig(),
                },
              ],
            },
          ],
        },
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.pageInputs',
        selector: '#spm_body #spm_content',
        items: [
          {
            label: 'templateBuilder.fields.width',
            selector: '{SELECTOR} .spm_form_field .spm_form_input',
            properties: [
              { name: 'width', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'max-width', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...getWidthFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.alignment',
            selector: '{SELECTOR} .spm_form_field .spm_form_input',
            properties: [{ name: 'spm_div_align_', getter: getClassByName, setters: [setDivAlignmentClass] }],
            ...divAlignmentFieldConfig,
          },
          {
            label: 'templateBuilder.fields.font',
            selector: '{SELECTOR} .spm_form_field .spm_form_input',
            properties: [
              { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
              { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...fontFieldConfig,
          },
          {
            label: 'templateBuilder.fields.textAlign',
            selector: '{SELECTOR} .spm_form_field .spm_form_input',
            properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getTextAlignFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.backgroundColor',
            selector: '{SELECTOR} .spm_form_field .spm_form_input',
            properties: [{
              name: 'background',
              getter: getCssPropertyByName,
              setters: [setCssProperty],
            }],
            ...getColorFieldConfig(undefined, false, true),
          },
          {
            label: 'templateBuilder.fields.contours',
            selector: '{SELECTOR} .spm_form_field .spm_form_input',
            properties: [{ name: 'border-radius', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getBorderRadiusFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.borders',
            selector: '{SELECTOR} .spm_form_field .spm_form_input',
            properties: [
              { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-style', getter: getCssPropertyByName, setters: [] },
              { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
            ],
            ...bordersFieldConfig,
          },
          {
            label: 'templateBuilder.fields.spaceWithinButton',
            selector: '{SELECTOR} .spm_form_field .spm_form_input',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig().options,
            },
          },
          {
            label: 'templateBuilder.fields.titleMarginVertical',
            selector: '{SELECTOR} .spm_form_field .spm_form_input',
            properties: [
              { name: 'margin-top', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'margin-bottom', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...getVerticalSpacesFieldConfig('margin'),
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.pageButtons',
        selector: '#spm_body #spm_content',
        items: [
          {
            label: 'templateBuilder.fields.width',
            selector: '#spm_body #spm_content a.spm_button, #spm_body #spm_content a.spm_button:active, '
              + '#spm_body #spm_content a.spm_button:visited, #spm_body #spm_content a.spm_button:hover',
            properties: [{
              name: 'width',
              getter: getCssPropertyByName,
              setters: [setCssButtonWidthProperty],
            }],
            ...getButtonWidthFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.alignment',
            selector: '#spm_body #spm_content a.spm_button, #spm_body #spm_content a.spm_button:active, '
              + '#spm_body #spm_content a.spm_button:visited, #spm_body #spm_content a.spm_button:hover',
            properties: [{ name: 'spm_div_align_', getter: getClassByName, setters: [setDivAlignmentClass] }],
            ...divAlignmentFieldConfig,
          },
          {
            label: 'templateBuilder.fields.contours',
            selector: '#spm_body #spm_content a.spm_button, #spm_body #spm_content a.spm_button:active, '
              + '#spm_body #spm_content a.spm_button:visited, #spm_body #spm_content a.spm_button:hover',
            properties: [{ name: 'border-radius', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getBorderRadiusFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.borders',
            selector: '#spm_body #spm_content a.spm_button, #spm_body #spm_content a.spm_button:active, '
              + '#spm_body #spm_content a.spm_button:visited, #spm_body #spm_content a.spm_button:hover',
            properties: [
              { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-style', getter: getCssPropertyByName, setters: [] },
              { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
            ],
            ...bordersFieldConfig,
          },
          {
            label: 'templateBuilder.fields.backgroundColor',
            selector: '#spm_body #spm_content a.spm_button, #spm_body #spm_content a.spm_button:active, '
              + '#spm_body #spm_content a.spm_button:visited, #spm_body #spm_content a.spm_button:hover',
            properties: [{
              name: 'background',
              getter: getCssPropertyByName,
              setters: [setCssProperty],
            }],
            ...getColorFieldConfig('#E0E0E0', false, true),
          },
          {
            label: 'templateBuilder.fields.font',
            selector: '#spm_body #spm_content a.spm_button, #spm_body #spm_content a.spm_button:active, '
              + '#spm_body #spm_content a.spm_button:visited, #spm_body #spm_content a.spm_button:hover',
            properties: [
              { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
              { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...fontFieldConfig,
          },
          {
            label: 'templateBuilder.fields.textAlign',
            selector: '#spm_body #spm_content a.spm_button, #spm_body #spm_content a.spm_button:active, '
              + '#spm_body #spm_content a.spm_button:visited, #spm_body #spm_content a.spm_button:hover',
            properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getTextAlignFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.spaceBetweenChars',
            selector: '#spm_body #spm_content a.spm_button, #spm_body #spm_content a.spm_button:active, '
              + '#spm_body #spm_content a.spm_button:visited, #spm_body #spm_content a.spm_button:hover',
            properties: [{ name: 'letter-spacing', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getStepperFieldConfig(StepperSignEnum.PX),
          },
          {
            label: 'templateBuilder.fields.spaceWithinButton',
            selector: '#spm_body #spm_content a.spm_button, #spm_body #spm_content a.spm_button:active, '
              + '#spm_body #spm_content a.spm_button:visited, #spm_body #spm_content a.spm_button:hover',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig().options,
            },
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.pageButtonsHover',
        selector: '#spm_body #spm_content',
        items: [
          {
            label: 'templateBuilder.fields.backgroundColor',
            selector: 'div#spm_body #spm_content a.spm_button:hover',
            properties: [{
              name: 'background',
              getter: getCssPropertyByName,
              setters: [setCssProperty],
            }],
            ...getColorFieldConfig(undefined, false, true),
            options: {
              defaultValue: '#E0E0E0',
            },
          },
          {
            label: 'templateBuilder.fields.font',
            selector: 'div#spm_body #spm_content a.spm_button:hover',
            properties: [
              { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
              { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'color', getter: getCssPropertyByName, setters: [setCssPropertyWithImportant] },
            ],
            ...fontFieldConfig,
          },
          {
            label: 'templateBuilder.fields.borders',
            selector: 'div#spm_body #spm_content a.spm_button:hover',
            properties: [
              { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-style', getter: getCssPropertyByName, setters: [] },
              { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
            ],
            ...bordersFieldConfig,
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.pageVouchers',
        selector: '#spm_body #spm_content',
        items: [
          {
            label: 'templateBuilder.fields.width',
            selector: '#spm_body #spm_content .spm_generate_voucher_button',
            properties: [{ name: 'width', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getWidthFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.iconColor',
            selector: '#spm_body #spm_content .spm_generate_voucher_button .spm_voucher_icon, #spm_body #spm_content .spm_generate_voucher_button .spm_voucher_icon_code',
            properties: [{ name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getColorFieldConfig('#000000'),
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.separators',
        selector: '#spm_body #spm_content',
        items: [
          {
            label: 'templateBuilder.fields.style',
            selector: '{SELECTOR} .spm_widget_display_separator',
            properties: [
              { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-style', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
            ],
            ...bordersFieldConfig,
          },
          {
            label: 'templateBuilder.fields.width',
            selector: '{SELECTOR} .spm_widget_display_separator',
            properties: [{ name: 'width', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getWidthFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.alignment',
            selector: '{SELECTOR} .spm_widget_display_separator',
            properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getElementAlignFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.marginVertical',
            selector: '{SELECTOR} .spm_widget_display_separator',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyWithImportantAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyWithImportantAndRemoveIfZero] },
            ],
            ...getVerticalSpacesFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.marginHorizontal',
            selector: '{SELECTOR} .spm_widget_display_separator',
            properties: [
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyWithImportantAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyWithImportantAndRemoveIfZero] },
            ],
            ...getHorizontalSpacesFieldConfig(),
          },
        ],
      },
    ],
  },
};

const pageItemConfig: ConfigurationPanelStructure = {
  items: [
    pageTabItemConfig,
  ],
};

export default pageItemConfig;
