<template>
  <div class="grid loader">
    <div class="col text-center loader-content">
      <Vue3Lottie
        :animation-data="LoaderSpm"
        :height="200"
        :width="200"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Vue3Lottie } from 'vue3-lottie';
import 'vue3-lottie/dist/style.css';
import LoaderSpm from '@/assets/lottie/Loader.json';

export default defineComponent({
  name: 'Loader',

  components: {
    Vue3Lottie,
  },

  data() {
    return {
      LoaderSpm,
    };
  },
});
</script>

<style scoped lang="scss">
.loader {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
  background: $loaderBackgroundColor;
  opacity: 0.8;
  z-index:1102;
}

.loader-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
