
import { useI18n } from 'vue-i18n';
import { SpmTableColumns, SpmTableFilter } from '@/types';
import { OperatorType } from '@/types/generated-types/graphql';
import SpmTable from '@/components/table/SpmTable.vue';
import { defineComponent } from 'vue';
import { UserState } from '@/composables/User';

export default defineComponent({
  name: 'GdprLogs',
  components: {
    SpmTable,
  },

  setup() {
    const { t } = useI18n();

    const columns: SpmTableColumns[] = [
      {
        field: 'id_shop_log_action',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'email',
        header: t('gdprLogs.headers.email'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'type',
        header: t('gdprLogs.headers.type'),
        sortable: true,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'ip',
        header: t('gdprLogs.headers.ip'),
        sortable: true,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'date',
        header: t('gdprLogs.headers.date'),
        sortable: true,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'location',
        header: t('gdprLogs.headers.location'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'source_url',
        header: t('gdprLogs.headers.source_url'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
    ];

    const persistentFilters: SpmTableFilter[] = [
      {
        field: 'id_shop',
        value: UserState?.activeShop?.id ?? '',
        operator: OperatorType.Equals,
      },
    ];

    return {
      t,
      columns,
      persistentFilters,
    };
  },
});
