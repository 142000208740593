
import { useI18n } from 'vue-i18n';
import {
  ref, Ref, SetupContext, onMounted, defineComponent,
} from 'vue';
import EditorModal from '@/components/modals/EditorModal.vue';
import InputText from 'primevue/inputtext';
import Loader from '@/components/layout/Loader.vue';
import { findPermission, UserState } from '@/composables/User';
import Dialog from 'primevue/dialog';
import {
  UsersPagesConfigurationInputItem,
  UsersPagesConfiguration,
} from '@/types/generated-types/graphql';
import { StatsType, UserTypeEnum } from '@/types';
import Button from 'primevue/button';
import {
  fetchUsersPagesConfMainList,
  DuplicateUsersPagesConfiguration,
} from '@/composables/Statistics/usersPagesConfiguration';
import { showToastError, showToastSuccess } from '@/helpers';
import StatsCustoms from '@/views/stats/StatsCustoms.vue';
import { store } from '@/store';

type struct = {
  row: number;
  column: number[];
}
export default defineComponent({
  name: 'StatsModelSelector',

  components: {
    StatsCustoms,
    EditorModal,
    InputText,
    Loader,
    Dialog,
    Button,
  },

  props: {
    statsModelType: {
      type: String,
      required: true,
      default: 'category',
    },
  },

  emits: {
    'on-close-dialog': null,
    'on-choose-template': Object,
    'on-preview-template': Object,
  },

  setup(props: { statsModelType: string }, context: SetupContext) {
    const { t } = useI18n();
    const displayDialog = ref(true);
    const modelTypes = [StatsType.STATS, StatsType.EMAIL];
    const isLoading = ref(false);
    const list: Ref<UsersPagesConfiguration[]> = ref([]);
    const idUser = ref(UserState.user.id);
    const modelType = ref(props.statsModelType);
    const displayPreviewModal = ref(false);
    const searchTemplatesInput = ref('');
    const pageId = ref(0);
    const pageName = ref('');
    let limit = 12;
    let structure: struct[] = [];

    const getStructureArray = (items: Array<Record<string, any>>) => {
      items.forEach((item: any, index: number) => {
        const newRow: struct = {
          row: index,
          column: item.layout.split('-'),
        };

        const layout = item.layout.split('-');
        layout.forEach((column: string) => {
          newRow.column.push(Number(column));
        });

        structure.push(newRow);
      });
    };

    const getStatsModelList = async (loadMore: boolean) => {
      if (modelType.value !== 'none') {
        isLoading.value = true;
        const result = await fetchUsersPagesConfMainList(modelType.value, searchTemplatesInput.value, loadMore ? limit : 12);
        result.items.forEach((item) => {
          const config = JSON.parse(item.configuration);
          structure = [];
          // getRowsAndColumns(config);
          getStructureArray(config);
          // eslint-disable-next-line no-param-reassign
          item.structure = structure;
        });
        if (result.items && !result.err) {
          list.value = result.items;
          isLoading.value = false;
        }
      }
    };

    const closeModal = () => {
      context.emit('on-close-dialog');
    };

    const setModelType = async (type: string) => {
      modelType.value = type;
      await getStatsModelList(false);
    };

    const handleChooseModel = async (idUserPageConf: number, name: string, config: string, extraData: any) => {
      let adminUserId = null;
      if (UserState.user.userType === UserTypeEnum.ADMIN) {
        adminUserId = UserState.user.id;
      } else if (UserState.activeShop) {
        adminUserId = UserState.activeShop?.idUser;
      }

      const usersPagesConfInput: UsersPagesConfigurationInputItem = {
        owner: 'user',
        page_name: name,
        navigation_name: name,
        admin_user_id: adminUserId,
        created_by_user_id: UserState.user.id,
        created_by_user_type: UserState.user.userType,
        configuration: config,
        extra_data: extraData,
        type: modelType.value,
        date_creation: new Date().toLocaleString('en-US', { timeZone: 'Europe/Paris' }),
        date_modification: new Date().toLocaleString('en-US', { timeZone: 'Europe/Paris' }),
      };

      const result = await DuplicateUsersPagesConfiguration([usersPagesConfInput]);
      if (!result.status || !result.id || result.err) {
        await showToastError(t('stats.pages.duplicate.error'));
        store.commit('general/hideTheSpinner');
      } else {
        await showToastSuccess(t('stats.pages.duplicate.success'));
        context.emit('on-choose-template', result.id);
      }
    };

    const tmpCreateRapport = async (type: string) => {
      store.commit('general/showTheSpinner');
      modelType.value = type;
      await getStatsModelList(false);
      if (list.value && list.value.length === 1) {
        await handleChooseModel(list.value[0].id_users_pages_configuration, list.value[0].page_name, list.value[0].configuration, list.value[0]?.extra_data);
      }
      store.commit('general/hideTheSpinner');
    };

    const handleSearchStatsModels = async (loadMore: boolean) => {
      limit += 12;
      await getStatsModelList(loadMore);
    };

    const handlePreviewImage = async (idUserPageConf: number, name: string) => {
      pageId.value = idUserPageConf;
      pageName.value = name;
      displayPreviewModal.value = true;
      context.emit('on-preview-template', idUserPageConf, name);
    };

    const handleCloseChoosingModelType = () => {
      context.emit('on-close-dialog');
    };

    const handleClosePreview = () => {
      displayPreviewModal.value = false;
    };

    onMounted(async () => {
      await getStatsModelList(false);
    });

    return {
      t,
      displayDialog,
      modelType,
      modelTypes,
      isLoading,
      setModelType,
      handleSearchStatsModels,
      handleChooseModel,
      list,
      handlePreviewImage,
      handleClosePreview,
      displayPreviewModal,
      handleCloseChoosingModelType,
      closeModal,
      searchTemplatesInput,
      pageId,
      pageName,
      findPermission,
      tmpCreateRapport,
    };
  },
});
