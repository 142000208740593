
import {
  defineComponent, PropType, ref, onBeforeMount, Ref,
} from 'vue';
import Dropdown from 'primevue/dropdown';
import { useI18n } from 'vue-i18n';

import { FieldConfig, ParserFieldObject, Property } from '@/types';
import getFontFamilyList from '@/configs/font-family';

export default defineComponent({
  name: 'FontFamily',
  components: {
    Dropdown,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const configs = ref(props.configs);
    const displayProperties = ref(!(!Object.prototype.hasOwnProperty.call(configs.value, 'displayToggle') || configs.value.displayToggle === true));
    const property: Ref<Property> = ref(props.parserValues.properties && props.parserValues.properties[0]);
    const selectedFontFamily = ref(property.value.value ?? props.configs.options.defaultValue);
    const fontFamilyList = ref([{}]);

    onBeforeMount(async () => {
      try {
        fontFamilyList.value = await getFontFamilyList();
      } catch (error) {
        fontFamilyList.value = [{}];
      }
    });

    const handleSelectedFont = (data: { value: string }) => {
      selectedFontFamily.value = data.value;
      if (property.value) {
        property.value.value = selectedFontFamily.value;
        context.emit('on-change-properties', {
          selector: props.parserValues.selector,
          properties: [property.value],
        });
      }
    };

    return {
      t,
      displayProperties,
      selectedFontFamily,
      fontFamilyList,
      handleSelectedFont,
    };
  },
});
