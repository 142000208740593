
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StatsWidgetColumn',

  props: {
    layout: {
      type: String,
      required: false,
      default: '12',
    },
  },
});
