
import { computed, defineComponent } from 'vue';

import Card from 'primevue/card';
import Chip from 'primevue/chip';
import Tooltip from 'primevue/tooltip';

import Flag from '@/components/table/renderers/Flag.vue';
import LanguageIcons from "@/components/table/renderers/LanguageIcons.vue";
import List from "@/components/data-explorer/fields/List.vue";
import AddressItem from "@/components/data-explorer/fields/AddressItem.vue";
import OrderItem from "@/components/data-explorer/fields/OrderItem.vue";
import CartItem from "@/components/data-explorer/fields/CartItem.vue";
import SpmButton from '@/components/spm-primevue/SpmButton.vue';

import {
  getContactAddresses,
  getContactGroups,
  getContactRejects,
  getContactShops,
  getContactOrders,
  getContactCarts,
} from '@/composables/data-explorer/DataExplorer';

import { useStore } from '@/store';

import { formatDate } from '@/helpers/Date';

import {
  ContactAddress,
  ContactGroup,
  ContactReject,
  Order,
  Cart,
} from '@/types/data-explorer-types';

import { useI18n } from 'vue-i18n';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default defineComponent({
  name: 'ContactFile',

  directives: {
    tooltip: Tooltip,
  },

  components: {
    AddressItem,
    Card,
    Chip,
    Flag,
    LanguageIcons,
    List,
    OrderItem,
    CartItem,
    SpmButton,
  },

  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const selectedData = computed(() => store.getters['dataExplorer/getSelectedData']);
    const newsletter = computed(() => {
      if ((selectedData.value.generalInformation.data.unSubType & 1) === 1
        || (selectedData.value.generalInformation.data.unSubType & 4) === 4
        || (selectedData.value.generalInformation.data.unSubType & 1) === 16
      ) {
        return {
          value: t('dataExplorer.contactFile.fields.newsletter.unsubscribe.value'),
          tooltip: t('dataExplorer.contactFile.fields.newsletter.unsubscribe.tooltip')
        };
      }

      if (selectedData.value.generalInformation.data.newsletter.toString() === '0') {
        return {
          value: t('dataExplorer.contactFile.fields.newsletter.nonOptin.value'),
          tooltip: t('dataExplorer.contactFile.fields.newsletter.nonOptin.tooltip')
        };
      } else if (selectedData.value.generalInformation.data.newsletter.toString() === '1') {
        return {
          value: t('dataExplorer.contactFile.fields.newsletter.optin.value'),
          tooltip: t('dataExplorer.contactFile.fields.newsletter.optin.tooltip')
        };
      } else if (selectedData.value.generalInformation.data.newsletter.toString() === '2') {
        return {
          value: t('dataExplorer.contactFile.fields.newsletter.doubleOptin.value'),
          tooltip: t('dataExplorer.contactFile.fields.newsletter.doubleOptin.tooltip')
        };
      }
      return {
        value: t('dataExplorer.contactFile.fields.newsletter.unsubscribe.value'),
        tooltip: t('dataExplorer.contactFile.fields.newsletter.unsubscribe.tooltip')
      };
    });
    const gender = computed(() => {
      if (selectedData.value && selectedData.value.generalInformation.data.gender && selectedData.value.generalInformation.data.gender.toString() === '1') {
        return {
          label: t('dataExplorer.contactFile.fields.gender.male'),
          icon: 'fa fa-mars'
        };
      } else if (selectedData.value && selectedData.value.generalInformation.data.gender && selectedData.value.generalInformation.data.gender.toString() === '2') {
        return {
          label: t('dataExplorer.contactFile.fields.gender.female'),
          icon: 'fa fa-venus'
        };
      }
      return {
        label: t('dataExplorer.contactFile.fields.gender.unknown'),
        icon: 'fa fa-neuter'
      };
    });
    const hasAccount = computed(() => selectedData.value
      && (selectedData.value.generalInformation.data.type && selectedData.value.generalInformation.data.type & 1) === 1
    );
    const isAccountActive = computed(() => selectedData.value
      && selectedData.value.generalInformation.data.active && selectedData.value.generalInformation.data.active.toString() === '1'
    );
    const phoneNumber = computed(() => {
      if (selectedData.value && selectedData.value.generalInformation.data.country && selectedData.value.generalInformation.data.mobile) {
        const phoneNumberObj = parsePhoneNumberFromString(
          selectedData.value.generalInformation.data.mobile,
          selectedData.value.generalInformation.data.country.toUpperCase(),
        );
        if (phoneNumberObj && phoneNumberObj.isValid()) {
          return phoneNumberObj.formatInternational();
        }
        if (selectedData.value.generalInformation.data.mobile) {
          return selectedData.value.generalInformation.data.mobile;
        }
      }
      return '-';
    });

    const isNumber = (val: string) => {
      if (!val) {
        return false;
      }
      return /^\d+(\.\d+)?$/.test(val);
    };

    const handleRetrieveAddresses = (addresses: Array<ContactAddress>) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          newLists: addresses,
          dataKey: 'addresses',
        }
      );
    };

    const handleRetrieveGroups = (groups: Array<ContactGroup>) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          newLists: groups,
          dataKey: 'groups',
        }
      );
    };

    const handleRetrieveRejects = (rejects: Array<ContactReject>) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          newLists: rejects,
          dataKey: 'rejects',
        }
      );
    };

    const handleRetrieveShops = (shops: Array<string>) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          newLists: shops,
          dataKey: 'shops',
        }
      );
    };

    const handleRetrieveOrders = (orders: Array<Order>, total: number) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          newLists: orders,
          total,
          dataKey: 'orders',
        }
      );
    };

    const handleOrdersNextPage = (currentPage: number) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          currentPage,
          dataKey: 'orders',
        }
      );
    };

    const handleRetrieveCarts = (carts: Array<Cart>, total: number) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          newLists: carts,
          total,
          dataKey: 'carts',
        }
      );
    };

    const handleCartsNextPage = (currentPage: number) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          currentPage,
          dataKey: 'carts',
        }
      );
    };

    return {
      t,
      locale,
      selectedData,
      newsletter,
      gender,
      hasAccount,
      isAccountActive,
      phoneNumber,

      isNumber,
      formatDate,
      getContactAddresses,
      getContactGroups,
      getContactRejects,
      getContactOrders,
      getContactShops,
      getContactCarts,
      handleRetrieveAddresses,
      handleRetrieveGroups,
      handleRetrieveRejects,
      handleRetrieveShops,
      handleRetrieveOrders,
      handleOrdersNextPage,
      handleRetrieveCarts,
      handleCartsNextPage,
    }
  }
})
