import { ComponentNameEnum, BaseButtonFieldConfig } from '@/types';

const defaultValue = '48';
const property = 'width';

const iconSizeFieldConfig: BaseButtonFieldConfig = {
  componentName: ComponentNameEnum.BUTTON_GROUP,
  options: {
    24: {
      title: 'templateBuilder.fields.iconSize.small',
      property,
      defaultValue,
    },
    32: {
      title: 'templateBuilder.fields.iconSize.medium',
      property,
      defaultValue,
    },
    48: {
      title: 'templateBuilder.fields.iconSize.large',
      property,
      defaultValue,
    },
  },
};

export default iconSizeFieldConfig;
