import { ComponentNameEnum, SpacePropertyEnum } from '@/types';

const getVerticalSpacesFieldConfig = (property = 'padding', defaultValue = 0) => ({
  componentName: ComponentNameEnum.SPACES,
  options: {
    property,
    [SpacePropertyEnum.TOP]: {
      defaultValue,
      title: 'templateBuilder.fields.spaceTop',
    },
    [SpacePropertyEnum.BOTTOM]: {
      defaultValue,
      title: 'templateBuilder.fields.spaceBottom',
    },
  },
});

export default getVerticalSpacesFieldConfig;
