<template>
  <label class="block my-3">
    {{ t(configs.label) }}
  </label>
  <div v-if="configs.options.displayVariables">
    <VariablesButton
      :variables-list="configs.options.variablesList"
      @on-choose-variable="insertContent"
    />
  </div>
  <div class="p-fluid">
    <VCodeMirror
      ref="codeMirrorEditorRef"
      v-model:value="inputValue"
      :mode="{ name: 'text/html' }"
      @blur="onBlur"
      @paste="handleValueChange"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, PropType, Ref, ref, watch,
} from 'vue';
import { VCodeMirror } from 'vue3-code-mirror';
import { useI18n } from 'vue-i18n';
import { FieldConfig, ParserFieldObject, Property } from '@/types';

import 'codemirror/mode/htmlmixed/htmlmixed';
import beautifier from 'js-beautify';
import VariablesButton from '@/components/fields/partials/VariablesButton.vue';

export default defineComponent({
  name: 'Code',
  components: {
    VariablesButton,
    VCodeMirror,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const properties: Ref<Array<Property>> = ref(props.parserValues.properties ?? []);
    const inputValue: Ref<string> = ref(properties.value[0].value);
    const cursorPosition = ref({ line: 0, ch: 0 });
    const codeMirrorEditorRef = ref();

    const handleValueChange = () => {
      const updatedProperties: Array<Property> = properties.value.map((prop: Property) => {
        const updatedProperty = prop;
        updatedProperty.value = inputValue.value;
        return updatedProperty;
      });

      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: updatedProperties,
      });
    };

    const insertContent = (value: string|number|undefined) => {
      codeMirrorEditorRef.value.editor.getDoc().replaceRange(value, cursorPosition.value);
      handleValueChange();
    };

    const onBlur = (editor: Record<string, Function>) => {
      cursorPosition.value = {
        line: editor.getCursor().line,
        ch: editor.getCursor().ch,
      };

      const currentValue = inputValue.value;
      let newValue;
      properties.value.map((prop: Property) => {
        newValue = prop.value;
        return prop;
      });

      if (currentValue !== newValue) {
        handleValueChange();
      }
    };

    onMounted(() => {
      codeMirrorEditorRef.value.editor.setOption('theme', 'default');
    });

    watch(() => properties.value[0].value, (values, oldValues) => {
      if (values !== oldValues) {
        inputValue.value = beautifier.html(properties.value[0].value);
      }
    }, { immediate: true });

    return {
      t,
      inputValue,
      codeMirrorEditorRef,
      insertContent,
      onBlur,
      handleValueChange,
    };
  },
});
</script>

<style scoped lang="scss">
:deep() .CodeMirror {
  height: 500px !important;
}
</style>
