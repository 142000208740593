import { BaseFieldConfig, ComponentNameEnum, TextStylesValueEnum } from '@/types';

const fontFieldConfig: BaseFieldConfig = {
  componentName: ComponentNameEnum.FONT,
  options: {
    fontFamily: {
      options: {
        defaultValue: '',
      },
    },
    fontSize: {
      options: {
        px: {
          min: 8,
          max: 100,
          step: 1,
          defaultValue: 8,
          isDefaultUnitSelected: true,
        },
      },
    },
    textStyles: {
      multiple: true,
      options: {
        [TextStylesValueEnum.UPPERCASE]: {
          title: 'templateBuilder.fields.uppercase',
          iconClass: 'fa-text-height',
          property: 'text-transform',
          defaultValue: 'none',
        },
        [TextStylesValueEnum.BOLD]: {
          title: 'templateBuilder.fields.bold',
          iconClass: 'fa-bold',
          property: 'font-weight',
          defaultValue: 'normal',
        },
        [TextStylesValueEnum.ITALIC]: {
          title: 'templateBuilder.fields.italic',
          iconClass: 'fa-italic',
          property: 'font-style',
          defaultValue: 'normal',
        },
        [TextStylesValueEnum.UNDERLINE]: {
          title: 'templateBuilder.fields.underline',
          iconClass: 'fa-underline',
          property: 'text-decoration-line',
          defaultValue: '',
          multipleChoice: true,
        },
        [TextStylesValueEnum.STRIKETHROUGH]: {
          title: 'templateBuilder.fields.strikethrough',
          iconClass: 'fa-strikethrough',
          property: 'text-decoration-line',
          defaultValue: '',
          multipleChoice: true,
        },
      },
    },
    fontColor: {
      options: {
        defaultValue: '',
      },
    },
  },
};
export default fontFieldConfig;
