
import { defineComponent, computed, PropType } from 'vue';

import { formatNumberToCurrency } from '@/helpers/Number';

import {
  Product,
} from '@/types/data-explorer-types';

import { useStore } from '@/store';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ProductItem',

  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
  },

  setup (props) {
    const { t } = useI18n();
    const store = useStore();

    const selectedData = computed(() => store.getters['dataExplorer/getSelectedData']);

    const productName = computed(() => {
      return `#${props.product.productReference} - ${props.product.productName} (${props.product.idProduct})`;
    });

    const combinationProductName = computed(() => {
      return `${t('dataExplorer.orderFile.fields.combination')} : #${props.product.combinationReference} - ${props.product.combinationName} (${props.product.idCombination})`;
    });

    const imageLink = computed(() => {
      if (props.product.idShopProductAttribute) {
        return props.product.combinationImageLink;
      }
      return props.product.productImageLink;
    });

    return {
      t,
      productName,
      combinationProductName,
      imageLink,
      selectedData,

      formatNumberToCurrency,
    };
  }
})
