
import {
  inject,
  defineComponent,
  computed, ComputedRef, watch, ref,
} from 'vue';
import BadgeDirective from 'primevue/badgedirective';
import { TenantAttributes, TypeCampaignEnum, DataExplorerDataType } from '@/types';
import { useStore } from '@/store';
import MySitesMenu from '@/components/header/MySitesMenu.vue';
import MyNotificationsMenu from '@/components/header/MyNotificationsMenu.vue';
import MyProfileMenu from '@/components/header/MyProfileMenu.vue';
import MyStoreMenu from '@/components/header/MyStoreMenu.vue';
import Button from 'primevue/button';
import { setLocalStorageElement } from '@/helpers/LocalStorage';
import { findPermission } from '@/composables/User';
import { activateCampaign, AutomatedScenarioState } from '@/composables/AutomatedScenarios';
import Dropdown from 'primevue/dropdown';
import { activate as activateTemplate, TemplateEditorState } from '@/composables/template-editor/TemplateEditor';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Header',

  directives: {
    badge: BadgeDirective,
  },

  components: {
    Button,
    MyStoreMenu,
    MyProfileMenu,
    MyNotificationsMenu,
    MySitesMenu,
    Dropdown,
  },

  props: {
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup() {
    const { t } = useI18n();
    const tenantAttributes: TenantAttributes | undefined = inject('tenantAttributes');
    const store = useStore();
    const isSidebarExpanded = computed(() => store.state.general.isSidebarExpanded);
    const buttonIcon: ComputedRef<string> = computed(() => (isSidebarExpanded.value ? 'fa-light fa-bars-staggered' : 'fa-light fa-bars'));

    const openedDatas = computed(() => store.getters['dataExplorer/getOpenedDatas']);

    const fastGoProductSelected: any = ref(null);
    const fastGoProductItems = computed(() => {
      const products = [
        {
          label: t('header.automationCampaigns'),
          code: 'AC',
          items: AutomatedScenarioState.automationCampaigns.map((campaign) => ({
            label: campaign.scenario.settingsData.name,
            id: campaign.scenario.id,
            type: TypeCampaignEnum.AUTOMATION,
          })),
        },
        {
          label: t('header.bulkCampaigns'),
          code: 'BC',
          items: AutomatedScenarioState.bulkCampaigns.map((campaign) => ({
            label: campaign.scenario.settingsData.name,
            id: campaign.scenario.id,
            type: TypeCampaignEnum.BULK,
          })),
        },
        {
          label: t('header.templates'),
          code: 'TL',
          items: TemplateEditorState.templates.map((template) => ({
            label: template.informations.name,
            id: template.id,
            type: 'template',
          })),
        },
        {
          label: t('header.dataSheets'),
          code: 'DE',
          items: openedDatas.value.map((openedData: any) => ({
            label: t(`dataExplorer.tabs.${openedData.dataType}`, [openedData.tabLabel]),
            id: openedData.dataId,
            type: openedData.dataType,
          })),
        }
      ];
      return products.filter((product) => product.items.length > 0);
    });

    watch(fastGoProductSelected, async () => {
      if (fastGoProductSelected.value && fastGoProductSelected.value?.id && fastGoProductSelected.value?.type) {
        if ([TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK].includes(fastGoProductSelected.value?.type)) {
          await activateCampaign(fastGoProductSelected.value?.id, fastGoProductSelected.value?.type);
        }

        if (fastGoProductSelected.value?.type === 'template') {
          await activateTemplate(fastGoProductSelected.value?.id, '', '');
        }

        if (
          [
            DataExplorerDataType.ADDRESS_FILE,
            DataExplorerDataType.CART_FILE,
            DataExplorerDataType.CONTACT_FILE,
            DataExplorerDataType.ORDER_FILE,
          ].includes(fastGoProductSelected.value?.type)
        ) {
          store.dispatch('dataExplorer/switchData', { dataId: fastGoProductSelected.value?.id });
        }


        fastGoProductSelected.value = null;
      }
    });

    const toggleSidebar = () => {
      setLocalStorageElement('spm_is_sidebar_expanded', Number(!isSidebarExpanded.value));
      store.commit('general/setIsSidebarExpanded', !isSidebarExpanded.value);
    };

    return {
      toggleSidebar,
      tenantAttributes,
      buttonIcon,
      findPermission,
      fastGoProductSelected,
      fastGoProductItems,
    };
  },
});
