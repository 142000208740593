import { BorderRadiusPropertyEnum, ComponentNameEnum } from '@/types';

const getTopBorderRadiusFieldConfig = (property = 'border-radius', defaultValue = 0) => ({
  componentName: ComponentNameEnum.SPACES,
  options: {
    property,
    [BorderRadiusPropertyEnum.TOP_LEFT]: {
      defaultValue,
      title: 'templateBuilder.fields.radiusTopLeft',
    },
    [BorderRadiusPropertyEnum.TOP_RIGHT]: {
      defaultValue,
      title: 'templateBuilder.fields.radiusTopRight',
    },
  },
});

export default getTopBorderRadiusFieldConfig;
