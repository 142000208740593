<template>
  <div
    class="flex flex-column justify-content-center"
    style="margin: auto; width: 70%"
  >
    <div class="flex auth-logo-icon">
      <img
        src="@/assets/images/logo-sm-green.svg"
        width="100%"
        alt="logo Icon"
      />
    </div>
    <h3 class="flex">
      {{ t('login.welcome') }}
    </h3>
    <p class="flex text-page my-2">
      {{ t('login.loginInfo') }}
    </p>

    <form
      class="flex flex-column mt-4"
      data-test-id="form"
      @submit.prevent="checkForm"
    >
      <div class="flex flex-column">
        <BaseTextInput
          v-model="email"
          class="mb-3"
          input-type="email"
          field-id="email"
          label="login.email"
          input-style="width: 100%;"
        />
        <BaseTextInput
          v-model="password"
          class="mb-3"
          type="password"
          input-type="password"
          field-id="password"
          label="login.password"
          input-style="width: 100%;"
        />
      </div>

      <div class="flex flex-row flex-wrap justify-content-between mb-4">
        <div class="flex align-items-center justify-content-center">
          <Checkbox
            id="binary"
            v-model="checked"
            binary
          />
          <label
            for="binary"
            class="text-page ml-2"
          >{{ t('login.rememberMe') }}</label>
        </div>
        <router-link
          class="flex text-page align-items-center justify-content-center"
          :to="{ name: 'user.forgot-password' }"
        >
          Mot de passe oublié ?
        </router-link>
      </div>

      <div class="flex flex-column">
        <SpmButton
          :label="t('login.submit')"
          type="submit"
          style="width: 100%"
          class="flex p-button p-button-success"
        />
        <p class="flex align-items-center justify-content-center text-page mt-3">
          {{ t('login.registerQuestion') }}
          <router-link
            :to="{ name: 'user.registration-speed' }"
            class="text-page ml-1"
          >
            {{ t('login.register') }}
          </router-link>
        </p>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, onBeforeMount, onUnmounted, ref, watch,
} from 'vue';
import Checkbox from 'primevue/checkbox';
import { useI18n } from 'vue-i18n';

import {
  getIsAuthenticated, Login, Me, setPermissionsString, SwitchActiveShop, UserState,
} from '@/composables/User';
import { useStore } from '@/store';
import { showToastError } from '@/helpers';
import { NavigationFailure, useRouter } from 'vue-router';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import BaseTextInput from '@/components/fields/BaseTextInput.vue';

export default defineComponent({
  name: 'Login',
  components: {
    BaseTextInput,
    Checkbox,
    SpmButton,
  },

  setup() {
    const checked = ref(false);
    const email = ref('');
    const password = ref('');
    const isEmailNotValid = ref(false);
    const isPasswordNotValid = ref(false);
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();

    const checkForm = async (): Promise<void|NavigationFailure|undefined|null> => {
      store.commit('general/showTheSpinner');
      isEmailNotValid.value = !email.value;
      isPasswordNotValid.value = !password.value;

      if (isEmailNotValid.value || isPasswordNotValid.value) {
        store.commit('general/hideTheSpinner');
        return null;
      }

      try {
        await Login({ username: email.value, password: password.value });
        await Me(false);

        if (UserState.user.shops.length === 1) {
          await SwitchActiveShop(UserState.user.shops[0].id);
          store.commit('liveEditor/resetWebsiteContents');
          store.commit('general/hideTheSpinner');
          return router.push({ name: 'home' });
        }

        store.commit('general/hideTheSpinner');
        return router.push({ name: 'choose-shop' });
      } catch (error) {
        store.commit('general/hideTheSpinner');
        return showToastError(t('user.wrongCredentials'));
      }
    };

    watch(email, (value: string) => {
      isEmailNotValid.value = !value;
    });

    watch(password, (value: string) => {
      isPasswordNotValid.value = !value;
    });

    onBeforeMount(async () => {
      const isAuthenticated = getIsAuthenticated();
      if (isAuthenticated) {
        return router.push({ name: 'home' });
      }
      document.body.classList.add('no_expand');
      return true;
    });

    onUnmounted(() => {
      document.body.classList.remove('no_expand');
    });

    return {
      checked,
      email,
      password,
      t,
      checkForm,
    };
  },
});
</script>

<style scoped lang="scss">
  .text-page {
    font-size: 14px;
    line-height: 21px;
    color: rgb(107, 114, 128);
  }
  .auth-logo-icon {
    width: 6rem;
    margin-bottom:20px;
  }
</style>
