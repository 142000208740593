import { TemplatesCustom } from '@/types/generated-types/graphql';
import { Request } from '@/composables/GraphQL';
import { getShopDefaultLang, UserState } from '@/composables/User';

export async function fetchUserTemplates(labelSearch: string, category: string, type: string, campaignType: string|null, limit: number, idTemplates: number[] = []) {
  const idShop = UserState.activeShop?.id ?? 0;
  const lang = getShopDefaultLang();

  const query = 'query( $idShop: Int, $idTemplates: [Int], $campaignType: String, $category: String, $type: String, $lang: String, $label: String, $limit: Int, $offset: Int )'
    // eslint-disable-next-line max-len
    + '{ TemplatesListCustom(id_shop: $idShop, id_template: $idTemplates, category: $category, type: $type, campaign_type: $campaignType, language: $lang, label: $label,  limit: $limit, offset: $offset){'
    + 'id_template, id_templates_lang, imageKey, label, template_config, subject}}';
  const variables = {
    idShop, idTemplates, category, type, campaignType, lang, label: labelSearch ?? '', limit, offset: 0,
  };

  const { data, err } = await Request<TemplatesCustom[]>({
    name: 'TemplatesListCustom',
    query,
    variables,
  });

  if (err || data === null || data.length === 0) {
    return [];
  }

  return data;
}

export async function fetchRecursiveTemplate(idTemplate: number) {
  const query = 'query( $idTemplates: [Int])'
    + '{ TemplatesListRecursive(id_template: $idTemplates){'
    + 'id_template, id_templates_lang, imageKey, label}}';
  const variables = {
    idTemplates: idTemplate,
  };

  const { data, err } = await Request<TemplatesCustom[]>({
    name: 'TemplatesListRecursive',
    query,
    variables,
  });

  if (err || data === null || data.length === 0) {
    return [];
  }

  return data;
}
