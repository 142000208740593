
import {
  defineComponent, PropType, ref, SetupContext,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Button from 'primevue/button';
import ChooseItemsModal from '@/components/modals/ChooseItemsModal.vue';
import {
  SpmTableFilter, StringMap,
} from '@/types';

export default defineComponent({
  name: 'BaseItemList',

  components: {
    Button,
    ChooseItemsModal,
  },

  props: {
    modelValue: {
      type: Array as PropType<StringMap[]>,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    buttonLabel: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    fields: {
      type: Object as PropType<StringMap>,
      required: true,
    },

    items: {
      type: String,
      required: false,
      default: '',
    },

    format: {
      type: Function,
      required: false,
      default: (value: StringMap) => value,
    },

    defaultFilters: {
      type: Array as PropType<SpmTableFilter[]>,
      required: false,
      default: () => [],
    },
  },

  emits: ['update:modelValue'],

  setup(props: { format: Function }, { emit }: SetupContext) {
    const { t } = useI18n();
    const displayModal = ref(false);
    const onChooseItems = (items: StringMap[]) => emit('update:modelValue', [...items.map((item: StringMap) => props.format(item))]);

    return {
      t,
      displayModal,
      onChooseItems,
    };
  },

});
