
import {
  computed, defineComponent,
  PropType,
  Ref,
  ref,
  SetupContext,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import FiltrePurchaseHistoryMetaData, {
  FiltrePurchaseHistoryData, FiltrePurchaseHistoryFilters,
} from '@/components/automated-scenarios/metadata/filtres/FiltrePurchaseHistoryMetadata';
import ButtonGroup from '@/components/automated-scenarios/fields/ButtonGroup.vue';
import PeriodSelector from '@/components/automated-scenarios/fields/PeriodSelector.vue';
import { FilterSegment, Period } from '@/types/automated-scenarios';
import {
  addInArraySegment,
  getActiveSegments,
  removeInSegment,
} from '@/composables/AutomatedScenarioSegments';
import { AutomatedScenarioState as state } from '@/composables/AutomatedScenarios';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import getSegments from './segments/FiltrePurchaseHistorySegments';
import FilterGlobalInformations from '../../fields/FilterGlobalInformations.vue';
import FilterPanelContent from '../../FilterPanelContent.vue';
import FilterSegmentForm from '../../FilterSegmentForm.vue';

export default defineComponent({
  name: 'FiltrePurchaseHistory',
  components: {
    FieldErrors,
    FilterGlobalInformations,
    FilterPanelContent,
    ButtonGroup,
    PeriodSelector,
    FilterSegmentForm,
  },

  props: {
    modelValue: {
      type: Object as PropType<FiltrePurchaseHistoryData>,
      required: true,
      default() {
        return FiltrePurchaseHistoryMetaData.Create();
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: FiltrePurchaseHistoryData; formValidation: any }, context: SetupContext) {
    const { t } = useI18n();
    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    const commonFields = ref({
      custom_title: props.modelValue.custom_title,
      custom_description: props.modelValue.custom_description,
      outputs_number: props.modelValue.outputs_number,
    });
    const grantAdditionalDelay = ref(props.modelValue.grant_additional_delay);
    const delay = ref<Period>({
      unit: props.modelValue.extra_time ? props.modelValue.extra_time.unit : 'DAY',
      value: props.modelValue.extra_time ? props.modelValue.extra_time.value : 1,
    });
    const options = [
      { value: '0', label: t('no') },
      { value: '1', label: t('yes') },
    ];
    const units = [
      { value: 'SECOND', label: t('automatedScenarios.fields.durations.second(s)') },
      { value: 'MINUTE', label: t('automatedScenarios.fields.durations.minute(s)') },
      { value: 'HOUR', label: t('automatedScenarios.fields.durations.hour(s)') },
      { value: 'DAY', label: t('automatedScenarios.fields.durations.day(s)') },
    ];

    const includeSegments: Ref<FilterSegment<FiltrePurchaseHistoryFilters>[]> = ref(getSegments());
    const excludeSegments: Ref<FilterSegment<FiltrePurchaseHistoryFilters>[]> = ref(getSegments());
    const activeIncludeSegments = computed(() => getActiveSegments<FiltrePurchaseHistoryFilters>(props.modelValue.inclusion, includeSegments.value));
    const activeExcludeSegments = computed(() => getActiveSegments<FiltrePurchaseHistoryFilters>(props.modelValue.exclusion, excludeSegments.value));
    const configuringSegment = computed(() => state.configuringSegment);

    const filters: Ref<{ include: FiltrePurchaseHistoryFilters; exclude: FiltrePurchaseHistoryFilters}> = ref({
      include: { ...props.modelValue.inclusion },
      exclude: { ...props.modelValue.exclusion },
    });

    const activeItemsFilterPanel = computed(() => ({
      include: activeIncludeSegments.value.length,
      exclude: activeExcludeSegments.value.length,
    }));

    const update = () => context.emit('update:modelValue', FiltrePurchaseHistoryMetaData.Create({
      ...commonFields.value,
      grant_additional_delay: grantAdditionalDelay.value,
      extra_time: delay.value,
      inclusion: filters.value.include,
      exclusion: filters.value.exclude,
    }));

    const handleAddSegment = (type: keyof { include: FiltrePurchaseHistoryFilters; exclude: FiltrePurchaseHistoryFilters },
      segment: FilterSegment<FiltrePurchaseHistoryFilters>) => {
      filters.value[type] = {
        ...filters.value[type],
        [segment.id]: { ...addInArraySegment<FiltrePurchaseHistoryFilters>(segment, filters.value[type][segment.id]) },
      };
      update();
    };

    const handleRemoveSegment = (type: keyof { include: FiltrePurchaseHistoryFilters; exclude: FiltrePurchaseHistoryFilters },
      segmentName: keyof FiltrePurchaseHistoryFilters, number: number) => {
      filters.value[type] = removeInSegment<FiltrePurchaseHistoryFilters>(filters.value[type], segmentName, number);
      update();
    };

    watch([commonFields, grantAdditionalDelay, delay], update, { deep: true });

    return {
      formValidationErrors,
      componentFieldErrorsKey,
      commonFields,
      grantAdditionalDelay,
      options,
      delay,
      units,
      includeSegments,
      excludeSegments,
      activeIncludeSegments,
      activeExcludeSegments,
      filters,
      handleAddSegment,
      handleRemoveSegment,
      configuringSegment,
      activeItemsFilterPanel,
    };
  },
});
