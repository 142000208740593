<template>
  <div class="preview-container">
    <img
      :src="imageUrl"
      alt=""
      class="product-image"
    >
    <Button
      icon="far fa-search-plus"
      class="p-button-secondary"
      @click="isDialogShown = true"
    />
  </div>
  <Dialog
    v-model:visible="isDialogShown"
    :header="t('templates.preview')"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '30vw' }"
    :content-style="{ textAlign: 'center' }"
  >
    <img
      height="500"
      :src="imageUrl"
      :alt="t('templates.preview')"
    >
  </Dialog>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

export default defineComponent({
  name: 'PreviewRenderer',
  components: {
    Button,
    Dialog,
  },

  props: {
    imageUrl: {
      type: String,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();
    const isDialogShown = ref(false);

    return {
      t,
      isDialogShown,
    };
  },

});
</script>

<style scoped lang="scss">
$img-size: 12rem;

.product-image {
  width: $img-size;
  height: $img-size;
  object-fit: cover;
  object-position: bottom;
}

.preview-container {
  position: relative;
  max-width: 100%;

  & .product-image {
    width: auto;
    height: auto;
    max-width: 100%;
  }

  &:hover {
    &::after {
      content: '';
      background: rgba(0, 0, 0, .3);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .p-button {
      display: block;
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
    }
  }

  .p-button {
    display: none;

    &:deep() .p-button-label {
      display: none;
    }
  }
}
</style>
