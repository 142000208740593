
import {
  defineComponent, PropType, ref, SetupContext, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
  MyLists, myListsState,
} from '@/composables/mes-listes/ListsManage';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import {useStore} from "@/store";

export default defineComponent({
  name: 'ImportActions',
  components: {
    Dialog,
    Button,
  },

  props: {
    data: {
      type: Object as PropType<MyLists>,
      required: true,
    },

    displayDialog: {
      type: Boolean,
      required: true,
    },
  },

  emits: [
    'close-import-dialog',
  ],

  setup(props: { data: MyLists; displayDialog: boolean}, context: SetupContext) {
    const { t } = useI18n();
    const store = useStore();
    const dialog = ref(props.displayDialog);

    const closeDisplay = () => {
      context.emit('close-import-dialog', false);
    };

    const showImportCustomers = async (idShopList: number, name: string, typeImport: string) => {
      // await store.dispatch('listImportEditor/importCustomersAction',
      //   { idShopList, typeImport, listName: props.data.Name });
      //
      // dialog.value = false;
      // closeDisplay();
    };

    return {
      showImportCustomers,
      closeDisplay,
      dialog,
      t,
    };
  },
});
