<template>
  <div class="color-block">
    <div class="p-inputgroup">
      <InputText
        :model-value="colorSelected"
        class="p-inputtext-sm color-input"
        @input="updateColor($event)"
      />
      <span
        class="p-inputgroup-addon button-block"
        @click="toggleColorPicker"
      >
        <Button
          class="p-inputgroup-addon p-button-sm color-button"
          :style="{ 'background-color': colorSelected }"
        />
      </span>
    </div>
    <OverlayPanel
      ref="overlayPanelRef"
    >
      <CustomColorPicker
        data-test-id="color-picker"
        :color="colorSelected"
        :color-format="colorFormat"
        title="Color Picker"
        @color-changed="changeColor"
      />
    </OverlayPanel>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, Ref, ref, watch,
} from 'vue';
import OverlayPanel from 'primevue/overlaypanel';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

import { ColorFormatEnum } from '@/types';
import CustomColorPicker from '@/components/fields/partials/CustomColorPicker.vue';

export default defineComponent({
  name: 'SimpleColorPicker',
  components: {
    CustomColorPicker, OverlayPanel, InputText, Button,
  },

  props: {
    selectedColor: {
      type: String,
      required: true,
    },

  },

  emits: ['color-changed'],

  setup(props, context) {
    const colorFormat: Ref<string> = ref(ColorFormatEnum.HEX);
    const overlayPanelRef = ref();
    const colorSelected: Ref<string> = ref(props.selectedColor);

    const toggleColorPicker = (event: MouseEvent) => {
      overlayPanelRef.value.toggle(event);
    };

    const changeColor = (color: string) => {
      colorSelected.value = color;
      context.emit('color-changed', colorSelected.value);
    };

    const isValidHexColor = (color: string) => {
      const hexColorPattern = /^#([0-9A-F]{3}){1,2}$/i;
      return hexColorPattern.test(color);
    };

    const updateColor = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const color = target.value;
      if (isValidHexColor(color)) {
        colorSelected.value = color;
        context.emit('color-changed', colorSelected.value);
      } else {
        // Optionally, you can show an error message or revert to the previous valid color
        console.error('Invalid hex color code');
      }
    };

    watch(props, () => {
      colorSelected.value = props.selectedColor;
    });

    return {
      overlayPanelRef,
      colorFormat,
      toggleColorPicker,
      changeColor,
      updateColor,
      colorSelected,
    };
  },
});
</script>

<style lang="scss">
.color-block {
  .color-input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}
</style>

<style scoped lang="scss">
.color-block {

  .button-block {
    background-color: $white;
    padding: 0;
    cursor: pointer;
    background-color: #F2F2F2;

    &:last-child {
      border-top-right-radius: $field-border-radius;
      border-bottom-right-radius: $field-border-radius;
    }

    .color-button {
      min-width: 1.5rem;
      width: 1.5rem;
      height: 1.5rem;
      padding: 0;
      border: none;
      border-radius: 0;
      border-color: $heather;

      &:enabled:focus,
      &:enabled:active {
        box-shadow: none;
      }
    }

    .button-icon {
      border: none;
      padding: .25rem;
      background-color: #F2F2F2;
      color: $heather;
      cursor: pointer;
    }
  }
}
</style>
