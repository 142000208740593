
import {
  defineComponent,
  PropType,
  computed,
} from 'vue';

import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import Slider from 'primevue/slider';
import SelectButton from 'primevue/selectbutton';

import SimpleColorPicker from '@/components/fields/SimpleColorPicker.vue';
import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import ImageUploader from '@/components/fields/ImageUploader.vue';

import effects from '@/configs/effects';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Notification',

  components: {
    LocalizedTextInput,
    SelectButton,
    SimpleColorPicker,
    Dropdown,
    InputNumber,
    Slider,
    FieldErrors,
    ImageUploader,
  },

  props: {
    modelValue: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },

    idShop: {
      type: Number,
      required: true,
    },

    errors: {
      type: Object,
      required: true,
    },

    componentFieldErrorsKey: {
      type: Number,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const field = computed({
      get() {
        return props.modelValue;
      },

      set(newValue) {
        emit('update:modelValue', newValue);
      },
    });

    const yesno = [
      { name: t('yes'), value: '1' },
      { name: t('no'), value: '0' },
    ];

    return {
      t,
      field,
      effects,
      yesno,
    };
  },
});
