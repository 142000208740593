
import BaseInformation from '@/components/fields/BaseInformation.vue';
import { defineComponent, PropType } from 'vue';
import { FieldConfig } from '@/types';

export default defineComponent({
  name: 'Information',

  components: {
    BaseInformation,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },
  },
});
