import { StoreCurrency } from '@/types/store-parameters-types';

export const currencies: StoreCurrency[] = [
  { code: 'AED', label: 'UAE Dirham' },
  { code: 'AFN', label: 'Afghani' },
  { code: 'ALL', label: 'Lek' },
  { code: 'AMD', label: 'Armenian Dram' },
  { code: 'ANG', label: 'Netherlands Antillian Guilder' },
  { code: 'AOA', label: 'Kwanza' },
  { code: 'ARS', label: 'Argentine Peso' },
  { code: 'AUD', label: 'Australian Dollar' },
  { code: 'AWG', label: 'Aruban Guilder' },
  { code: 'AZN', label: 'Azerbaijanian Manat' },
  { code: 'BAM', label: 'Convertible Marks' },
  { code: 'BBD', label: 'Barbados Dollar' },
  { code: 'BDT', label: 'Taka' },
  { code: 'BGN', label: 'Bulgarian Lev' },
  { code: 'BHD', label: 'Bahraini Dinar' },
  { code: 'BIF', label: 'Burundi Franc' },
  { code: 'BMD', label: 'Bermudian Dollar' },
  { code: 'BND', label: 'Brunei Dollar' },
  { code: 'BOB', label: 'Boliviano' },
  { code: 'BRL', label: 'Brazilian Real' },
  { code: 'BSD', label: 'Bahamian Dollar' },
  { code: 'BTN', label: 'Ngultrum' },
  { code: 'BWP', label: 'Pula' },
  { code: 'BYR', label: 'Belarussian Ruble' },
  { code: 'BZD', label: 'Belize Dollar' },
  { code: 'CAD', label: 'Canadian Dollar' },
  { code: 'CDF', label: 'Congolese Franc' },
  { code: 'CHF', label: 'Swiss Franc' },
  { code: 'CLP', label: 'Chilean Peso' },
  { code: 'CNY', label: 'Chinese Yuan' },
  { code: 'COP', label: 'Colombian Peso' },
  { code: 'CRC', label: 'Costa Rican Colon' },
  { code: 'CUP', label: 'Cuban Peso' },
  { code: 'CVE', label: 'Cape Verde Escudo' },
  { code: 'CZK', label: 'Czech Koruna' },
  { code: 'DJF', label: 'Djibouti Franc' },
  { code: 'DKK', label: 'Danish Krone' },
  { code: 'DOP', label: 'Dominican Peso' },
  { code: 'DZD', label: 'Algerian Dinar' },
  { code: 'EEK', label: 'Kroon' },
  { code: 'EGP', label: 'Egyptian Pound' },
  { code: 'ERN', label: 'Nakfa' },
  { code: 'ETB', label: 'Ethiopian Birr' },
  { code: 'EUR', label: 'Euro' },
  { code: 'FJD', label: 'Fiji Dollar' },
  { code: 'FKP', label: 'Falkland Islands Pound' },
  { code: 'GBP', label: 'Pound Sterling' },
  { code: 'GEL', label: 'Lari' },
  { code: 'GHS', label: 'Cedi' },
  { code: 'GIP', label: 'Gibraltar Pound' },
  { code: 'GMD', label: 'Dalasi' },
  { code: 'GNF', label: 'Guinea Franc' },
  { code: 'GTQ', label: 'Quetzal' },
  { code: 'GWP', label: 'Guinea-Bissau Peso' },
  { code: 'GYD', label: 'Guyana Dollar' },
  { code: 'HKD', label: 'Hong Kong Dollar' },
  { code: 'HNL', label: 'Lempira' },
  { code: 'HRK', label: 'Croatian Kuna' },
  { code: 'HTG', label: 'Gourde' },
  { code: 'HUF', label: 'Hungary Forint' },
  { code: 'IDR', label: 'Rupiah' },
  { code: 'ILS', label: 'Israeli Sheqel' },
  { code: 'INR', label: 'Indian Rupee' },
  { code: 'IQD', label: 'Iraqi Dinar' },
  { code: 'IRR', label: 'Iranian Rial' },
  { code: 'ISK', label: 'Iceland Krona' },
  { code: 'JMD', label: 'Jamaican Dollar' },
  { code: 'JOD', label: 'Jordanian Dinar' },
  { code: 'JPY', label: 'Japan Yen' },
  { code: 'KES', label: 'Kenyan Shilling' },
  { code: 'KGS', label: 'Som' },
  { code: 'KHR', label: 'Riel' },
  { code: 'KMF', label: 'Comoro Franc' },
  { code: 'KPW', label: 'North Korean Won' },
  { code: 'KRW', label: 'Won' },
  { code: 'KWD', label: 'Kuwaiti Dinar' },
  { code: 'KYD', label: 'Cayman Islands Dollar' },
  { code: 'KZT', label: 'Tenge' },
  { code: 'LAK', label: 'Kip' },
  { code: 'LBP', label: 'Lebanese Pound' },
  { code: 'LKR', label: 'Sri Lanka Rupee' },
  { code: 'LRD', label: 'Liberian Dollar' },
  { code: 'LSL', label: 'Loti' },
  { code: 'LTL', label: 'Lithuanian Litas' },
  { code: 'LVL', label: 'Latvian Lats' },
  { code: 'LYD', label: 'Libyan Dinar' },
  { code: 'MAD', label: 'Moroccan Dirham' },
  { code: 'MDL', label: 'Moldovan Leu' },
  { code: 'MGA', label: 'Malagasy Ariary' },
  { code: 'MKD', label: 'Denar' },
  { code: 'MMK', label: 'Kyat' },
  { code: 'MNT', label: 'Tugrik' },
  { code: 'MOP', label: 'Pataca' },
  { code: 'MRO', label: 'Ouguiya' },
  { code: 'MUR', label: 'Mauritius Rupee' },
  { code: 'MVR', label: 'Rufiyaa' },
  { code: 'MWK', label: 'Kwacha' },
  { code: 'MXN', label: 'Mexican Peso' },
  { code: 'MYR', label: 'Malaysian Ringgit' },
  { code: 'MZN', label: 'Metical' },
  { code: 'NAD', label: 'Namibia Dollar' },
  { code: 'NGN', label: 'Naira' },
  { code: 'NIO', label: 'Cordoba Oro' },
  { code: 'NOK', label: 'Norwegian Krone' },
  { code: 'NPR', label: 'Nepalese Rupee' },
  { code: 'NZD', label: 'New Zealand Dollar' },
  { code: 'OMR', label: 'Rial Omani' },
  { code: 'PAB', label: 'Balboa' },
  { code: 'PEN', label: 'Nuevo Sol' },
  { code: 'PGK', label: 'Kina' },
  { code: 'PHP', label: 'Philippine Peso' },
  { code: 'PKR', label: 'Pakistan Rupee' },
  { code: 'PLN', label: 'Zloty' },
  { code: 'PYG', label: 'Guarani' },
  { code: 'QAR', label: 'Qatari Rial' },
  { code: 'RON', label: 'New Leu' },
  { code: 'RSD', label: 'Serbian Dinar' },
  { code: 'RUB', label: 'Russian Ruble' },
  { code: 'RWF', label: 'Rwanda Franc' },
  { code: 'SAR', label: 'Saudi Riyal' },
  { code: 'SBD', label: 'Solomon Islands Dollar' },
  { code: 'SCR', label: 'Seychelles Rupee' },
  { code: 'SDG', label: 'Sudanese Pound' },
  { code: 'SEK', label: 'Swedish Krona' },
  { code: 'SGD', label: 'Singapore Dollar' },
  { code: 'SHP', label: 'Saint Helena Pound' },
  { code: 'SLL', label: 'Leone' },
  { code: 'SOS', label: 'Somali Shilling' },
  { code: 'SRD', label: 'Surinam Dollar' },
  { code: 'STD', label: 'Dobra' },
  { code: 'SVC', label: 'Salvadoran Colon' },
  { code: 'SYP', label: 'Syrian Pound' },
  { code: 'SZL', label: 'Lilangeni' },
  { code: 'THB', label: 'Baht' },
  { code: 'TJS', label: 'Somoni' },
  { code: 'TMT', label: 'Manat' },
  { code: 'TND', label: 'Tunisian Dinar' },
  { code: 'TOP', label: 'Pa\'anga' },
  { code: 'TRY', label: 'Turkish Lira' },
  { code: 'TTD', label: 'Trinidad and Tobago Dollar' },
  { code: 'TWD', label: 'Taiwan Dollar' },
  { code: 'TZS', label: 'Tanzanian Shilling' },
  { code: 'UAH', label: 'Hryvnia' },
  { code: 'UGX', label: 'Uganda Shilling' },
  { code: 'USD', label: 'US Dollar' },
  { code: 'UYU', label: 'Peso Uruguayo' },
  { code: 'UZS', label: 'Uzbekistan Sum' },
  { code: 'VEF', label: 'Bolivar Fuerte' },
  { code: 'VND', label: 'Dong' },
  { code: 'VUV', label: 'Vatu' },
  { code: 'WST', label: 'Tala' },
  { code: 'XAF', label: 'CFA Franc' },
  { code: 'XCD', label: 'East Caribbean Dollar' },
  { code: 'XOF', label: 'West African CFA Franc' },
  { code: 'XPF', label: 'CFP Franc' },
  { code: 'YER', label: 'Yemeni Rial' },
  { code: 'ZAR', label: 'Rand' },
  { code: 'ZMK', label: 'Zambian Kwacha' },
  { code: 'ZWL', label: 'Zimbabwe Dollar' },
];

export const getCurrencies = () => currencies.map((currency: {code: string; label: string}) => ({
  value: currency.code,
  label: currency.label,
}));

export default currencies;
