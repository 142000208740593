import { DataExplorerDataType } from '@/types';
import {
  Cart,
  ContactAddress,
  ContactFile,
  Order,
} from '@/types/data-explorer-types';
import { State, DataExplorerState } from '@/types/store-types';

import { ActionContext } from 'vuex';

export default {
  namespaced: true,
  state: (): DataExplorerState => ({
    isDataExplorerVisible: false,
    selectedData: null,
    openedDatas: localStorage.getItem('data-explorer-datas') ? JSON.parse(localStorage.getItem('data-explorer-datas') as string) : [],
  }),

  getters: {
    getIsDataExplorerVisible: (state: DataExplorerState) => state.isDataExplorerVisible,
    getSelectedData: (state: DataExplorerState) => state.selectedData,
    getOpenedDatas: (state: DataExplorerState) => state.openedDatas,
  },

  mutations: {
    setIsDataExplorerVisible: (state: DataExplorerState, payload: boolean) => {
      state.isDataExplorerVisible = payload;
    },
    setSelectedData: (state: DataExplorerState, payload: any) => {
      state.selectedData = payload;
    },
    setOpenedDatas: (state: DataExplorerState, payload: any) => {
      state.openedDatas = payload;
    },
  },

  actions: {
    leave: async ({ state, commit }: ActionContext<DataExplorerState, State>) => {
      commit('setSelectedData', null);
      commit('setOpenedDatas', []);

      localStorage.removeItem('data-explorer-datas');

      commit('setIsDataExplorerVisible', false);
    },
    minimize: async ({ state, commit }: ActionContext<DataExplorerState, State>) => {
      localStorage.setItem('data-explorer-datas', JSON.stringify(state.openedDatas));
      commit('setIsDataExplorerVisible', false);
    },
    openContactFile: ({ state, commit }: ActionContext<DataExplorerState, State>, { customer }: { customer: ContactFile }) => {
      const data = state.openedDatas
        .filter((openedData: any) => openedData.dataType === DataExplorerDataType.CONTACT_FILE)
        .find((openedData) => openedData.generalInformation.data.idShopCustomer === customer.generalInformation.data.idShopCustomer);
      if (!data) {
        const newData = {
          dataId: new Date().getTime(),
          dataType: DataExplorerDataType.CONTACT_FILE,
          tabLabel: customer.isAnonymous ? '******' : `${customer.generalInformation.data.firstName} ${customer.generalInformation.data.lastName}`,
          ...customer,
        };
        state.openedDatas.push(newData);
        commit('setSelectedData', newData);
        commit('setIsDataExplorerVisible', true);
      } else {
        commit('setSelectedData', data);
        commit('setIsDataExplorerVisible', true);
      }
    },
    setAdditionnalData: ({ state, commit }: ActionContext<DataExplorerState, State>, {
      dataId, newLists, dataKey, total, currentPage,
    }: { dataId: number; newLists: Array<any>; dataKey: string; total: number; currentPage: number }) => {
      const data = state.openedDatas.find((openedData) => openedData.dataId === dataId);
      if (data) {
        if (newLists) {
          data[dataKey].data = newLists;
        }
        if (total) {
          data[dataKey].total = total;
        }
        if (currentPage !== null && currentPage !== undefined) {
          data[dataKey].currentPage = currentPage;
        }
        data[dataKey].dataLoaded = true;
        commit('setSelectedData', data);
        commit('setOpenedDatas', state.openedDatas);
      }
    },
    switchData: ({ state, commit }: ActionContext<DataExplorerState, State>, { dataId }: { dataId: number }) => {
      const data = state.openedDatas.find((openedData) => openedData.dataId === dataId);
      if (data) {
        commit('setSelectedData', data);
        commit('setIsDataExplorerVisible', true);
      }
    },
    closeData: ({ state, commit, dispatch }: ActionContext<DataExplorerState, State>, { dataId }: { dataId: number }) => {
      state.openedDatas = state.openedDatas.filter((data) => data.dataId !== dataId);
      if (state.openedDatas.length) {
        commit('setSelectedData', state.openedDatas[0]);
        localStorage.setItem('data-explorer-datas', JSON.stringify(state.openedDatas));
      } else {
        dispatch('leave');
      }
    },
    openAddressFile: ({ state, commit }: ActionContext<DataExplorerState, State>, { idShopCustomerAddress }: { idShopCustomerAddress: number }) => {
      const address = state.selectedData.addresses.data.find((item: ContactAddress) => item.idShopCustomerAddress === idShopCustomerAddress);
      const openedAddress = state.openedDatas
        .filter((openedData: any) => openedData.dataType === DataExplorerDataType.ADDRESS_FILE)
        .find((openedData: ContactAddress) => openedData.idShopCustomerAddress === idShopCustomerAddress);
      if (address && !openedAddress) {
        const label = state.selectedData.tabLabel;
        const newData = {
          dataId: new Date().getTime(),
          dataType: DataExplorerDataType.ADDRESS_FILE,
          tabLabel: label,
          ...address,
          invoiceOrders: {
            data: [],
            dataLoaded: false,
            total: 0,
            currentPage: 0,
          },
          deliveryOrders: {
            data: [],
            dataLoaded: false,
            total: 0,
            currentPage: 0,
          },
        };
        state.openedDatas.push(newData);
        commit('setSelectedData', newData);
      } else if (address && openedAddress) {
        commit('setSelectedData', openedAddress);
      }
    },
    openOrderFile: ({ state, commit }: ActionContext<DataExplorerState, State>, { idOrder, keyName, order }: { idOrder: string; keyName: string; order?: Order }) => {
      const newOrder = order || state.selectedData[keyName].data.find((item: Order) => item.idOrder === idOrder);
      const openedOrder = state.openedDatas
        .filter((openedData: any) => openedData.dataType === DataExplorerDataType.ORDER_FILE)
        .find((openedData: Order) => openedData.idOrder === newOrder.idOrder);
      if (newOrder && !openedOrder) {
        const finalOrder = JSON.parse(JSON.stringify(newOrder));
        const newData = {
          dataId: new Date().getTime(),
          dataType: DataExplorerDataType.ORDER_FILE,
          tabLabel: finalOrder.isAnonymous ? '******' : finalOrder.orderReference,
          ...finalOrder,
        };
        state.openedDatas.push(newData);
        commit('setSelectedData', newData);
        commit('setIsDataExplorerVisible', true);
      } else if (newOrder && openedOrder) {
        commit('setSelectedData', openedOrder);
        commit('setIsDataExplorerVisible', true);
      }
    },
    updateOrderFileStatus: ({ state }: ActionContext<DataExplorerState, State>, { status }: { status: string }) => {
      state.selectedData.status = status;
      state.selectedData.statusLoaded = true;
    },
    openCartFile: ({ state, commit }: ActionContext<DataExplorerState, State>, { idCart, keyName, cart }: { idCart: string; keyName: string; cart?: Cart }) => {
      const newCart = cart || state.selectedData[keyName].data.find((item: Cart) => item.idCart === idCart);
      const openedCart = state.openedDatas
        .filter((openedData: any) => openedData.dataType === DataExplorerDataType.CART_FILE)
        .find((openedData: Cart) => openedData.idCart === newCart.idCart);
      if (newCart && !openedCart) {
        const finalCart = JSON.parse(JSON.stringify(newCart));
        const newData = {
          dataId: new Date().getTime(),
          dataType: DataExplorerDataType.CART_FILE,
          tabLabel: finalCart.isAnonymous ? '******' : finalCart.idCart,
          products: {
            data: [],
            dataLoaded: false,
          },
          ...finalCart,
        };
        state.openedDatas.push(newData);
        commit('setSelectedData', newData);
        commit('setIsDataExplorerVisible', true);
      } else if (newCart && openedCart) {
        commit('setSelectedData', openedCart);
        commit('setIsDataExplorerVisible', true);
      }
    },
  },
};
