
import {
  defineComponent, PropType, ref,
} from 'vue';
import ToggleButton from 'primevue/togglebutton';
import { translateFromGoogle } from '@/components/template-builder/utils/translate';
import { Lang, UserState } from '@/composables/User';
import { MultiLangInput } from '@/types/store-parameters-types';

export default defineComponent({
  name: 'Translate',

  components: {
    ToggleButton,
  },

  emits: {
    'on-translate': Object,
    'add-data-menu': Object,
  },

  // eslint-disable-next-line vue/order-in-components
  props: {
    allMultiLangInput: {
      type: Object as PropType<MultiLangInput>,
      required: true,
    },

    selectedLanguage: {
      type: String,
      required: true,
    },
  },

  setup(props, context) {
    const defaultLanguage = UserState.activeShop?.langs
      .filter((language: Lang) => language.default)
      .map((language: Lang) => (language.id))[0] ?? 'fr';
    ref(false);
    const translateFromDefaultLanguage = async () => {
      const allMultiLangInput = JSON.parse(JSON.stringify(props.allMultiLangInput));
      const defaultValue = allMultiLangInput[defaultLanguage];
      const translationResult = await translateFromGoogle(defaultValue, props.selectedLanguage);
      context.emit('on-translate', translationResult);
    };

    context.emit('add-data-menu', {
      label: 'Traduire le contenu',
      icon: 'fal fa-language',
      command() {
        translateFromDefaultLanguage();
      },
    });

    return {
    };
  }
  ,

});
