
import {
  computed, defineComponent, onMounted, PropType, Ref, ref, SetupContext, watch,
} from 'vue';
import Calendar from 'primevue/calendar';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import { IntervalDateEnum, PrimvueMenuModel } from '@/types';
import { useI18n } from 'vue-i18n';
import moment from 'moment';

export default defineComponent({
  name: 'CustomDatePicker',

  components: {
    Calendar,
    SpmOverlayPanel,
    SpmPanelMenu,
  },

  props: {
    modelValue: {
      type: Object,
      default: () => Object,
    },

    calendarPosition: {
      type: String,
      default: 'bottom',
    },

    noLimit: {
      type: Boolean,
      required: false,
      default: false,
    },

    customMinDate: {
      type: Number,
      required: false,
      default: 0,
    },

    customOptionsItems: {
      type: Array as PropType<any[]>,
      required: false,
      default: () => ([]),
    },

    updateValue: {
      type: Boolean,
      required: false,
      default: false,
    },

    defaultValue: {
      type: String,
      required: false,
      default: '',
    }
  },

  emits: ['update:modelValue', 'reset-update-value', 'value-choosed'],

  setup(props: { modelValue: any; customMinDate: number; noLimit: boolean; customOptionsItems: IntervalDateEnum[]; updateValue: boolean; defaultValue: string; }, { emit }: SetupContext) {
    const { t } = useI18n();

    const closePanel = ref(false);
    const chosenInterval: Ref<IntervalDateEnum|'empty'|string> = ref('empty');

    const customDate = ref(props.modelValue.customDateRange && props.modelValue.customDateRange.length > 0
      ? props.modelValue.customDateRange.map((date: string) => moment(date).toDate())
      : [moment().subtract(30, 'days').toDate(), moment().toDate()]);

    if (props.modelValue.interval) {
      chosenInterval.value = props.modelValue.interval;
    }
    if (props.defaultValue) {
      chosenInterval.value = props.defaultValue;
    }

    // Limit the date range to a maximum of one year apart
    const maxDate = ref(props.noLimit ? null : moment().toDate());
    const minDate = ref(!props.customMinDate ? null : moment().subtract(props.customMinDate, 'years').toDate());
    const optionsItems = ref<any[]>([]);

    if (props.customOptionsItems.length > 0) {
      optionsItems.value = props.customOptionsItems.map((key) => ({
          label: t(`customDatePicker.${key}`),
          command: () => {
            chosenInterval.value = key;
            closePanel.value = true;
            emit('value-choosed');
          },
          class: computed(() => (chosenInterval.value === key ? 'activeMenuItem' : '')),
        }));
    } else {
      optionsItems.value = Object.values(IntervalDateEnum).map((key) => ({
        label: t(`customDatePicker.${key}`),
        command: () => {
          chosenInterval.value = key;
          closePanel.value = true;
          emit('value-choosed');
        },
        class: computed(() => (chosenInterval.value === key ? 'activeMenuItem' : '')),
      }));
    }

    // add empty value to the optionsItems
    optionsItems.value.unshift({
      label: t('customDatePicker.empty'),
      command: () => {
        chosenInterval.value = 'empty';
        closePanel.value = true;
        emit('value-choosed');
      },
      class: computed(() => (chosenInterval.value === 'empty' ? 'activeMenuItem' : '')),
    });

    watch([chosenInterval, customDate], (newVal) => {
      if (chosenInterval.value === 'customDateRange') {
        if (customDate.value[0] && !customDate.value[1]) {
          if (moment(customDate.value[0]).add(1, 'years').toDate() > moment().toDate()) {
            maxDate.value = moment().toDate();
          } else {
            maxDate.value = moment(customDate.value[0]).add(1, 'years').toDate();
          }
        } else if (customDate.value[0] && customDate.value[1]) {
          maxDate.value = moment().toDate();
          emit('update:modelValue', {
            interval: chosenInterval.value,
            customDateRange: customDate.value,
          });
        }
      } else {
        emit('update:modelValue', {
          interval: chosenInterval.value,
        });
      }
      if (props.updateValue) {
        emit('reset-update-value');
      }
    }, { deep: true });

    watch(() => props.updateValue, () => {
      if (props.updateValue) {
        customDate.value = props.modelValue.customDateRange && props.modelValue.customDateRange.length > 0
        ? props.modelValue.customDateRange.map((date: string) => moment(date).toDate())
        : [moment().subtract(30, 'days').toDate(), moment().toDate()];
        if (props.modelValue.interval) {
          chosenInterval.value = props.modelValue.interval;
        }
      }
    }, { deep: true });

    onMounted(() => {
      if (chosenInterval.value === 'customDateRange') {
        if (customDate.value[0] && customDate.value[1]) {
          emit('update:modelValue', {
            interval: chosenInterval.value,
            customDateRange: customDate.value,
          });
        }
      } else {
        // on load: update the value for the parent
        emit('update:modelValue', {
          interval: chosenInterval.value,
        });
      }
    });

    return {
      t,
      IntervalDateEnum,
      chosenInterval,
      optionsItems,
      minDate,
      maxDate,
      customDate,
      closePanel,
    };
  },

});
