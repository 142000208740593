import { store } from '@/store';
import { ActiveLiveEditorItemData } from '@/types';
import { TemplateStructureEnum } from '@/types/enums';
import { addActiveItemElementDecorations } from './active-item-builder';

import { TEMPLATE_STRUCTURE_MAP } from './constants';
import { getElementFromIframe } from './helpers';

export const onStructureOrderChange = (selector: string, type: string, currentPosition: number, futurePosition: number) => {
  const element = getElementFromIframe(selector);
  const children = Object.values(element.querySelectorAll(TEMPLATE_STRUCTURE_MAP[type]));
  if (element && children) {
    const clonedElement = children[currentPosition] as HTMLElement;
    if (futurePosition < currentPosition) children[futurePosition].before(clonedElement);
    else children[futurePosition].after(clonedElement);
  }
};

export const onSelectStructure = (selector: string, type: TemplateStructureEnum, itemPosition: number) => {
  const element = getElementFromIframe(selector);
  const children: HTMLElement[] = Object.values(element.querySelectorAll(TEMPLATE_STRUCTURE_MAP[type]));
  // Mark child as selected in template
  const activeItemData: ActiveLiveEditorItemData = {
    selector: `#${children[itemPosition].getAttribute('id')}` ?? '',
    type,
  };
  addActiveItemElementDecorations(activeItemData);
  // Refresh left panel with selected structure
  store.dispatch('liveEditor/prepareStructureConfiguration', {
    type,
    identifier: children[itemPosition].getAttribute('id'),
  });
};
