<template>
  <div class="buyable-element-container p-card p-card-border w-full h-full relative">
    <div
      v-if="shopifyPaymentState.blockPaymentUI"
      class="blocking-element absolute flex flex-column gap-2 justify-content-center align-items-center w-full h-full"
    >
      <div class="text-center m-3">
        <p v-html="shopifyPaymentState.paymentMessage" />
      </div>
      <div class="flex gap-2">
        <SpmButton
          :label="t('shop.menu.myOffer.payment.shopify.buttonConfirmationSimpleCharge')"
          class-style="btnBuyableElement p-button p-button-primary"
          @click="redirectToLink(shopifyPaymentState.urlConfirmation)"
        />
      </div>
    </div>
    <BlockUI
      :blocked="shopifyPaymentState.blockPaymentUI"
    >
      <div class="p-card-title">
        {{ t(buyableElement.header) }}
      </div>
      <div class="p-card-body pt-0">
        <div
          v-if="buyableElement.legend !== ''"
          class="buyableElementLegend"
        >
          {{ t(buyableElement.legend) }}
        </div>
        <div
          v-if="buyableElement.displayCountries"
          class="buyableElementCountry"
        >
          <CountryDropdown
            v-model="selectedCountry"
            :configuration="(buyableElement.countryDropdownConfiguration)"
            :error="{ country: false, state: false }"
            @update:model-value="handleCountryDropdownUpdate"
          />
        </div>
        <div
          v-if="buyableElement.options && buyableElement.options.length > 0"
          class="buyableElementOptions"
        >
          <Dropdown
            v-model="modelValues"
            class="w-full"
            :placeholder="t(buyableElement.optionsPlaceholder ?? '')"
            option-label="label"
            option-value="value"
            :options="buyableElement.options"
            @update:model-value="handleDropdownUpdate"
          >
            <template #option="slotProps">
              <div class="flex flex-column align-items-left">
                <div class="buyableElementDropdownLabel">
                  {{ slotProps.option.label }}
                </div>
                <div
                  v-if="slotProps.option.legend"
                  class="buyableElementDropdownLegend"
                >
                  {{ t('offers.dropdownLegendText') }} {{ slotProps.option.legend }}
                </div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div
          v-if="buyableElement.displayAutoRenew"
          class="buyableElementAutoRenew field flex align-items-center pt-3 my-0"
        >
          <InputSwitch v-model="autoRenew" />
          <div class="ml-3">
            {{ t('offers.autoRenewLegend') }}
          </div>
        </div>
        <div
          v-if="pricePerElement !== '' && pricePerElement !== 0"
          class="grid"
        >
          <div class="col-6 buyableElementPrice text-left">
            {{ t('offers.amountToPay') }} : <span class="buyableElementPriceTotal">{{ formatNumberToCurrency(pricePerElement, currency) }}</span>
          </div>
          <div class="col-6 buyableElementButton text-right">
            <SpmButton
              v-if="findPermission('my_offer.pay')"
              :label="`${t('offers.pay')} ${formatNumberToCurrency(pricePerElement, currency)}`"
              icon="far fa-check"
              class-style="btnBuyableElement p-button p-button-primary"
              :loading="loadingBuyButton"
              @click="buyElement"
            />
          </div>
        </div>
      </div>
    </BlockUI>
  </div>
  <ConfirmPopup group="popup-blocked" />
  <Dialog
    v-model:visible="showPaymentModal"
    modal
    :closable="true"
    :style="{ width: '50vw' }"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
  >
    <template #header>
      <h3>{{ t('offers.paymentModal.title') }}</h3>
    </template>
    <section class="contents">
      <div
        id="dropInComponent"
        ref="dropInComponent"
      />
    </section>
    <template #footer>
      <Button
        :label="t('close')"
        icon="far fa-times"
        class="p-button-secondary"
        @click="showPaymentModal = false"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import {
  defineComponent,
  Ref,
  ref,
  PropType,
  watch,
} from 'vue';

import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import ConfirmPopup from 'primevue/confirmpopup';

import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import CountryDropdown from '@/components/fields/CountryDropdown.vue';
import { DEFAULT_CURRENCY } from '@/components/template-builder/utils/constants';

import { useConfirm } from 'primevue/useconfirm';

import {
  findPermission,
  UserState,
} from '@/composables/User';
import GetPaymentMethods, {
  adyenConfig, MakeDetailsCall, MakePaymentCall,
} from '@/composables/user/AdyenConfig';
import { initiateSinglePayment } from '@/composables/user/ShopifyConfig';
import { BuyableElement } from '@/composables/shop/shopsPlans';

import AdyenCheckout from '@adyen/adyen-web';

import { showToastError, showToastSuccess } from '@/helpers';
import { formatNumberToCurrency } from '@/helpers/Number';

import { CountryAndStateValues } from '@/types/country-state-types';
import { SmsPricing } from '@/types/offer-types';
import { PaymentData } from '@/types/payment-types';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'BuyableElementV14',
  components: {
    CountryDropdown,
    SpmButton,
    Dropdown,
    InputSwitch,
    Dialog,
    BlockUI,
    ConfirmPopup,
  },

  props: {
    buyableElement: {
      type: Object as PropType<BuyableElement>,
      required: true,
    },

    smsPricing: {
      type: Object as PropType<Record<string, SmsPricing[]>>,
      required: true,
    },

    shopifyPaymentState: {
      type: Object as PropType<any>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const showPaymentModal = ref(false);

    // User and shop settings
    const userId = UserState.user.id;
    const locale = UserState.user.lang;
    const countryCode = locale.toLocaleUpperCase();
    const currency = ref(UserState.activeShop?.currency ?? DEFAULT_CURRENCY);
    currency.value = UserState.activeShop?.solutionEcommerce === 'shopify' ? 'USD' : currency.value;

    // eslint-disable-next-line no-param-reassign
    props.buyableElement.displayAutoRenew = UserState.activeShop?.solutionEcommerce !== 'shopify';

    const defaultCountry = ref({
      country: UserState.user.country ?? 'FR',
      state: '',
    });
    // User and shop settings

    // Selected values for buyable elements
    const selectedCountry: Ref<CountryAndStateValues> = ref(defaultCountry.value);
    const modelValues: Ref<any> = ref(0);
    const autoRenew: Ref<boolean> = ref(false);
    const pricePerElement: Ref<any> = ref('');
    // Selected values for buyable elements

    // Adyen configuration
    const adyenLocale = `${locale}_${countryCode}`;
    const adyenEnvironment: string = process.env.VUE_APP_ADYEN_ENVIRONMENT ?? 'live';

    const loadingBuyButton = ref(false);

    /**
     * Buy an element
     */
    const buyElement = async () => {
      const dataQuery: PaymentData = {
        idShop: UserState.activeShop?.id ?? 0,
        idUser: userId,
        elements: [
          {
            type: props.buyableElement.type,
            name: props.buyableElement.name,
            productId: props.buyableElement.productId,
            productTitle: t(props.buyableElement.productTitle, {
              qty: modelValues.value,
              country: selectedCountry.value ? t(`countries.codes.${selectedCountry.value.country}`) : '',
            }),

            country: selectedCountry.value ?? null,
            selectedValue: modelValues.value,
            autoRenew: autoRenew.value ?? null,
            totalPrice: pricePerElement.value,
            productPrice: pricePerElement.value,
          },
        ],

        paymentDetails: null,
      };

      if (UserState.activeShop && UserState.activeShop.solutionEcommerce === 'shopify') {
        try {
          loadingBuyButton.value = true;
          const configuration = await initiateSinglePayment(dataQuery);
          if (configuration.success) {
            window.location.href = configuration.confirmation_url;
          } else {
            showToastError('GENERIC_ERROR');
          }
        } catch (error) {
          showToastError('GENERIC_ERROR');
        } finally {
          loadingBuyButton.value = false;
        }
      } else {
        showPaymentModal.value = true;
        const paymentMethods = await GetPaymentMethods({
          id_user: userId, currency: 'EUR', amount: pricePerElement.value, country_code: countryCode, locale: adyenLocale,
        });

        const configuration = {
          translations: {
            'fr-FR': {
              payButton: t('offers.pay'),
            },
          },

          paymentMethodsResponse: paymentMethods.data,
          allowPaymentMethods: ['scheme'],
          removePaymentMethods: ['paypal'], // Retrait temporaire avant de pouvoir générer des tokens
          clientKey: adyenConfig[adyenEnvironment]['client-key'],
          locale: adyenLocale,
          environment: adyenConfig[adyenEnvironment].environment,
          onSubmit: async (state: any, dropin: any) => {
            dropin.setStatus('loading');
            dataQuery.paymentDetails = state.data;

            // Your function calling your server to make the `/payments` request
            MakePaymentCall(JSON.stringify(dataQuery)).then(async (response) => {
              if (response.data.action) {
                // Drop-in handles the action object from the /payments response
                dropin.handleAction(response.data.action);
              } else if (response.data.resultCode === 'Authorised' || response.data.resultCode === 'Received') {
                // Your function to show the final result to the shopper
                dropin.setStatus('success', { message: t('offers.paymentAccepted') });
                showToastSuccess(t('offers.success.buyElement'));

                // Reset buyable element to default values
                modelValues.value = 0;
                autoRenew.value = false;
                selectedCountry.value = defaultCountry.value;
                pricePerElement.value = '';
              } else {
                dropin.setStatus('error', { message: response.data.message });
                showToastError(t('offers.errors.buyElement'));
              }
            }).catch((reason: any) => {
              dropin.setStatus('error', { message: reason.response.data.refusalReason });
            });
          },

          onAdditionalDetails: (state: any, dropin: any) => {
            // Your function calling your server to make a `/payments/details` request
            MakeDetailsCall(JSON.stringify(state.data))
              .then((response) => {
                if (response.data.action) {
                  // Drop-in handles the action object from the /payments response
                  dropin.handleAction(response.data.action);
                } else if (response.data.resultCode === 'Authorised') {
                  // Your function to show the final result to the shopper
                  dropin.setStatus('success', { message: t('offers.paymentAccepted') });
                  showToastSuccess(t('offers.success.buyElement'));

                  // Reset buyable element to default values
                  modelValues.value = 0;
                  autoRenew.value = false;
                  selectedCountry.value = defaultCountry.value;
                  pricePerElement.value = '';
                } else {
                  dropin.setStatus('error', { message: response.message });
                  showToastError(t('offers.errors.buyElement'));
                }
              })
              .catch((error) => {
                throw Error(error);
              });
          },

          onPaymentCompleted: (result: any, component: any) => {
            console.info(result, component);
          },

          onError: (error: any, component: any) => {
            console.error(error.name, error.message);
          },

          paymentMethodsConfiguration: {
            card: {
              hasHolderName: true,
              holderNameRequired: true,
              hideCVC: false,
            },

            threeDS2: {
              challengeWindowSize: '05',
            },
          },
        };

        const checkout = await AdyenCheckout(configuration);
        checkout.create('dropin', { showStoredPaymentMethods: true }).mount('#dropInComponent').setComponentRef('dropInComponent');
      }
    };

    const handleCountryDropdownUpdate = () => {
      if ('countryCallback' in props.buyableElement && props.buyableElement.countryCallback) {
        pricePerElement.value = props.buyableElement.countryCallback(props.buyableElement, selectedCountry.value, modelValues.value, props.smsPricing);
      }
    };

    const handleDropdownUpdate = () => {
      pricePerElement.value = props.buyableElement.callback(modelValues.value, selectedCountry.value, props.smsPricing);
    };

    const redirectToLink = (url: string) => {
      window.location.href = url;
    };

    watch(() => props.smsPricing, () => {
      if ('countryCallback' in props.buyableElement && props.buyableElement.countryCallback) {
        pricePerElement.value = props.buyableElement.countryCallback(props.buyableElement, selectedCountry.value, modelValues.value, props.smsPricing);
      }
    }, { deep: true });

    return {
      t,
      selectedCountry,
      modelValues,
      autoRenew,
      pricePerElement,
      currency,
      showPaymentModal,
      loadingBuyButton,
      buyElement,
      handleCountryDropdownUpdate,
      handleDropdownUpdate,
      redirectToLink,
    };
  },

  methods: { findPermission, formatNumberToCurrency },
});
</script>

<style lang="scss" scoped>
@media all and (max-width: 1199px) {
  .flex-plan {
    display: block !important;
  }
}

.flex-plan {
  margin-top: 5px;
}

.p-card {
  box-shadow: none !important;
  border-radius: 5px;

  & .p-card-title {
    padding: 10px 15px;
  }

  &.p-card-border {
    border: solid 1px $heather;
  }

  &.contactUs {
    background: #f8f9fa;
  }

  &.currentPlan {
    box-shadow: none !important;
    border: solid 1px $brand-color-primary;
    background: #f8ffee !important;
    position: relative;

    & .chosenPlan {
      position: absolute;
      top: 0;
      right: 0;
      background: $brand-color-primary;
      color: $white;
      transform: translate(50%, -50%);
      border-radius: 50%;
      padding: 3px;
      width: 22px;
      height: 22px;
      text-align: center;
    }

    & .p-card-title {
      & .currentPlanPrice {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);

        & .currentPlanPriceCurrency {
          font-size: 1rem;
        }
      }
    }

    & .currentPlanDateLimit {
      padding-top: 3px;
      font-size: 0.9rem;
    }

    & .nextPlan {
      color: red;
    }

    & .btnUpdatePlan {
      float: right;
    }

    & .btnUnsubscribe {
      & .btnUpdatePlan {
        float: none;
      }
    }
  }

  & .offersQuestions {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2.5rem;
    background: #495057;
    color: $white;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 50%;
    padding: 5px;
  }

  &.paymentMethods {
    & .newPaymentMethod {
      cursor: pointer;
      flex-direction: column;

      & .paymentMethodContainer {
        background: #f9f9f9;
        border: solid 1px #f2f2f2;
        border-radius: 5px;
        padding: 15px;
        width: 100%;
        height: 200px;
        position: relative;

        &:hover {
          background: #f2f2f2;
        }

        & > i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 2.5rem;
          background: #eaeaea;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          line-height: 1em !important;
          text-align: center;
          padding: 5px;
        }
      }
    }

    & .paymentMethod {
      flex-direction: column;

      & .paymentMethodContainer {
        height: 200px;
        width: 100%;
        border: solid 1px #d9d9d9;
        padding: 15px;
        border-radius: 5px;
        position: relative;

        & > .cardType {
          display: block;
          width: 100%;
        }

        & > .cardNumber {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          width: 100%;
          text-align: center;

          & > .p-image {
            margin-right: 15px;

            &:deep() > img {
              width: 30px;
            }
          }
        }

        & > .buttons {
          position: absolute;
          bottom: 0px;
          width: 100%;
          left: 0;
          padding: 10px 15px;
          text-align: right;

          & > .p-button {
            margin-left: 5px;
          }
        }
      }

      &.currentPaymentMethod {
        & .paymentMethodContainer {
          box-shadow: none !important;
          border: solid 1px $brand-color-primary;
          background: #f8ffee !important;
          position: relative;

          & .defaultPaymentMethod {
            position: absolute;
            top: 0;
            right: 0;
            background: $brand-color-primary;
            color: $white;
            transform: translate(50%, -50%);
            border-radius: 50%;
            padding: 3px;
            width: 22px;
            height: 22px;
            text-align: center;
          }

          & .buttonDefault {
            display: none;
          }
        }
      }
    }
  }

  &:deep() .CountriesList {
    padding-left: 0px;
    padding-right: 0px;

    & .field {
      margin-bottom: 0px;
    }
  }

  & .buyableElementOptions {
    & .p-dropdown {
      height: 40px;
    }
  }

  & .buyableElementPrice {
    padding: 25px 10px 0;
    font-size: 1.2rem;

    & .buyableElementPriceTotal {
      color: $brand-color-primary;
      font-size: 1.5rem;
    }
  }

  & .buyableElementButton {
    padding: 25px 10px 0;
  }
}

.buyableElementDropdownLabel {
  font-size: 1.1rem;
}

.buyableElementDropdownLegend {
  padding-top: 2px;
  font-size: 0.85rem;
  color: gray;
}

.p-message {
  margin: 0;

  &:deep() .p-message-wrapper {
    padding: 10px 5px;
  }
}
</style>

<style lang="scss">
.buyable-element-container {
  .p-component-overlay-enter {
    background-color: white !important;
    opacity: 0.9;
  }
  .blocking-element {
    opacity: 1 !important;
    z-index: 1103 !important;
  }
}
</style>
