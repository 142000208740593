<template>
  <div class="connect-shop mx-auto">
    <div class="flex flex-column p-4 justify-content-center align-items-center">
      <div class="w-6rem">
        <img
          src="@/assets/images/logo-sm-green.svg"
          alt="logo Icon"
        >
      </div>
      <h3 class="text-2xl font-semibold">
        {{ title }}
      </h3>
      <p class="text-sm">
        {{ subTitle }}
      </p>
    </div>
    <div
      v-if="activeEcommerce"
      class="flex flex-column"
    >
      <div class="col-12">
        <Card
          class="w-full"
        >
          <template #title>
            <div class="flex flex-column p-1">
              <div class="flex align-items-center gap-4">
                <img
                  :src="`/images/ecommerce-solutions/${activeEcommerce}.svg`"
                  width="50"
                  height="50"
                  style="aspect-ratio: 50 / 50; object-fit: cover;"
                  alt="ecommerceSolution"
                >
                <div>
                  <h3 class="text-2xl font-semibold">
                    {{ t('complete.connectShop.installModule.title', null) }}
                  </h3>
                  <div
                    class="text-sm font-normal"
                    v-html="t('complete.connectShop.installModule.subtitle', [ecommerceModule.name])"
                  />
                </div>
              </div>
            </div>
          </template>
          <template #content>
            <div class="p-2 flex align-items-center justify-content-center gap-4">
              <div class="flex gap-2">
                <SpmButton
                  v-if="activeEcommerce !== 'thelia'"
                  :label="t('complete.connectShop.installModule.downloadButton')"
                  icon="fas far fa-arrow-down-to-line"
                  class-style="p-button p-button-success"
                  @click="downloadModule"
                />
                <SpmButton
                  v-else
                  :label="t('Merci de contacter le service-client')"
                  class-style="p-button p-button-warning"
                />
              </div>
            </div>
            <div
              v-if="ecommerceModule.downloadInstructions.length"
              class="mt-4"
            >
              <div class="ml-8">
                <div
                  v-for="(downloadInstruction, index) in ecommerceModule.downloadInstructions"
                  :key="index"
                  class="flex  align-items-baseline gap-2 mt-2"
                >
                  <Badge
                    :value="index + 1"
                    :style="{ 'background': 'transparent', color: '#94c840', border: '1px solid #94c840' }"
                  />
                  <div
                    class="text-sm font-light"
                    v-html="t(downloadInstruction)"
                  />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
      <div class="col-12">
        <Card
          class="w-full"
        >
          <template #title>
            <div class="flex flex-column p-1">
              <div class="flex align-items-center gap-4">
                <i
                  class="fa-light fa-plug"
                  :style="{ fontSize: '50px', width: '50px', height: '50px', color: '#94c840' }"
                />
                <div>
                  <h3 class="text-2xl font-semibold">
                    {{ t('complete.connectShop.connectModule.title') }}
                  </h3>
                  <p class="text-sm font-normal">
                    {{ t('complete.connectShop.connectModule.subtitle') }}
                  </p>
                </div>
              </div>
            </div>
          </template>
          <template #content>
            <div class="ml-8">
              <div
                v-for="(installationInstruction, index) in ecommerceModule.installationInstructions"
                :key="index"
                class="flex align-items-baseline gap-2 mt-2"
              >
                <Badge
                  :value="index + 1"
                  :style="{ 'background': 'transparent', color: '#94c840', border: '1px solid #94c840' }"
                />
                <div
                  class="text-sm font-light"
                  v-html="t(installationInstruction)"
                />
              </div>
            </div>
            <div class="p-2 flex flex-column gap-4 mt-4">
              <div
                v-for="(apiKey, index) in apiKeys"
                :key="index"
                class="relative w-full"
              >
                <div class="font-bold">
                  {{ apiKey.label }}
                </div>
                <div class="text-sm bg-gray-100 p-2 flex justify-content-between align-items-center">
                  <span>{{ apiKey.value }}</span>
                  <Button
                    class="p-button-secondary"
                    icon="far fa-clipboard"
                    severity="secondary"
                    text
                    rounded
                    aria-label="Bookmark"
                    @click="copyToClipboard(apiKey.value)"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-column align-items-center justify-content-center mt-2">
              <ProgressBar
                mode="indeterminate"
                style="height: 10px; width: 50%"
              />
              <small>
                {{ t('complete.connectShop.connectModule.waitingConnection') }}
              </small>
            </div>
          </template>
        </Card>
      </div>
    </div>
    <div
      v-else
      class="flex flex-column"
    >
      <div class="p-fluid field">
        <label for="currency">{{ t('user.register.ecommerce') }}</label>
        <Dropdown
          id="currency"
          v-model="activeEcommerce"
          :options="ecommerceOptions"
          option-label="label"
          option-value="code"
          :placeholder="t('user.register.ecommerce')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed, defineComponent, onMounted, ref,
} from 'vue';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import { checkIfShopConnected, getEcommerceModule } from '@/configs/complete';
import { showToastError, showToastSuccess } from '@/helpers';
import { useI18n } from 'vue-i18n';
import { generateApiIdentifiant, generateApiKey, updateShop } from '@/composables/shop/Shops';
import { UserState } from '@/composables/User';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import Badge from 'primevue/badge';
import ProgressBar from 'primevue/progressbar';
import { nestPost } from '@/composables/nestApi';
import CryptoJS from 'crypto-js';

export default defineComponent({
  name: 'ConnectShop',

  components: {
    SpmButton,
    Card,
    Button,
    Dropdown,
    Badge,
    ProgressBar,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },

    subTitle: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['next'],

  setup(_, { emit }) {
    const { t } = useI18n();
    const activeEcommerce = ref(UserState.activeShop?.solutionEcommerce);
    const ecommerceModule = computed(() => {
      if (activeEcommerce.value) {
        return getEcommerceModule(activeEcommerce.value);
      }
      return {};
    });

    const apiKeys = ref(
      [
        {
          label: t('complete.connectShop.connectModule.apiIdentifiantLabel'),
          value: generateApiIdentifiant(),
        },
        {
          label: t('complete.connectShop.connectModule.apiKeylabel'),
          value: generateApiKey(),
        },
      ],
    );

    const ecommerceOptions = [
      { label: 'Prestashop', code: 'prestashop' },
      { label: 'Magento', code: 'magento' },
      { label: 'Magento 2', code: 'magento2' },
      { label: 'WooCommerce', code: 'woocommerce' },
      { label: 'Shopify', code: 'shopify' },
      { label: 'Thelia (Bêta)', code: 'thelia' },
    ];

    const copyToClipboard = (value: string) => {
      navigator.clipboard.writeText(value);
      showToastSuccess(t('complete.connectShop.apiKeyCopiedToClipboard'));
    };

    const downloadModule = () => {
      window.open(ecommerceModule.value.downloadLink, '_blank');
    };

    const goNextStep = async () => {
      // trigger creation database for this shop
      await nestPost('v4', '/shop/createEnvironment', {}, {
        idUser: UserState.user.id ?? 0,
        idShop: UserState.activeShop?.id ?? 0,
      });
      emit('next');
    };

    onMounted(async () => {
      if (await checkIfShopConnected()) {
        await goNextStep();
        return;
      }
      // save api keys on db
      if (UserState.activeShop?.solutionEcommerce !== 'shopify') {
        try {
          const cryptedApiKey = CryptoJS.SHA1(apiKeys.value[1].value).toString();
          await updateShop([{
            id_shop: UserState.activeShop?.id ?? 0,
            identifiant: apiKeys.value[0].value,
            api_key: cryptedApiKey,
          }]);
        } catch (error) {
          await showToastError('GENERIC_ERROR');
        }
      }


      const interval = setInterval(async () => {
        try {
          if (await checkIfShopConnected()) {
            clearInterval(interval);
            await goNextStep();
          }
          // eslint-disable-next-line no-empty
        } catch (error) {
        }
      }, 5000);
    });

    return {
      t,
      activeEcommerce,
      ecommerceModule,
      downloadModule,
      apiKeys,
      ecommerceOptions,
      copyToClipboard,
    };
  },

});
</script>

<style lang="scss">
.connect-shop {
  .p-card {
    box-shadow: none !important;
    border: 1px solid lightgray;
    border-radius: 5px;
  }
  .p-card-body {
    padding: 0 !important;
    .p-card-content {
      padding: 1rem;
      padding-top: 0rem;
    }
  }
  .p-card-title {
    border-bottom: none !important;
    padding: 10px 15px;
  }
  .instruction-number {
    width: 1rem;
  }

  .p-progressbar .p-progressbar-value {
    background-color: #94c840 !important;
  }
}
</style>
