import { ComponentNameEnum, SharingTypeEnum } from '@/types';
import variables from '@/components/template-builder/utils/variables-list';
// eslint-disable-next-line import/no-cycle
import { getCustomDescription, getCustomUrl } from '@/components/template-builder/getters';
// eslint-disable-next-line import/no-cycle
import { setCustomDescription, setCustomUrl } from '@/components/template-builder/setters';

const getSharingTypeFieldConfig = (defaultValue = SharingTypeEnum.ONLINE_VERSION) => ({
  componentName: ComponentNameEnum.SHARING_TYPE,
  options: {
    defaultValue,
    customUrlConfig: [
      {
        label: 'templateBuilder.fields.sharingUrl',
        properties: [{ name: 'customUrl', getter: getCustomUrl, setters: [setCustomUrl] }],
        options: {
          defaultValue: '',
          variablesList: variables.allVariables,
          displayEmojis: false,
          displayVariables: true,
        },
      },
      {
        label: 'templateBuilder.fields.sharingLinkDescription',
        properties: [{ name: 'customDescription', getter: getCustomDescription, setters: [setCustomDescription] }],
        options: {
          defaultValue: '',
          variablesList: variables.allVariables,
          displayEmojis: false,
          displayVariables: true,
        },
      },
    ],
  },
});

export default getSharingTypeFieldConfig;
