import { ComponentNameEnum, BaseStepperPxPercentSliderFieldConfig } from '@/types';

const opacityFieldConfig: BaseStepperPxPercentSliderFieldConfig = {
  componentName: ComponentNameEnum.STEPPER_PX_PERCENT_SLIDER,
  options: {
    '%': {
      min: 0,
      max: 100,
      step: 1,
      defaultValue: 40,
      isDefaultUnitSelected: true,
    },
  },
};

export default opacityFieldConfig;
