
import {
  defineComponent, PropType, toRef, Ref, SetupContext,
} from 'vue';
import ScrollPanel from 'primevue/scrollpanel';
import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';
import { TemplatesElementsHistoryGroup } from '@/types/generated-types/graphql';
import { hideLeftToolbar } from '@/composables/template-editor/TemplateEditor';
import Panel from 'primevue/panel';

export default defineComponent({
  name: 'HistoryPanel',

  components: {
    ScrollPanel,
    Button,
    Panel,
  },

  props: {
    historyList: {
      type: Array as PropType<TemplatesElementsHistoryGroup[]>,
      required: true,
    },

    selectedHistory: {
      type: Object as PropType<TemplatesElementsHistoryGroup|null>,
      required: false,
      default: () => null,
    },
  },

  emits: ['on-change-history'],

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();
    const selected: Ref<TemplatesElementsHistoryGroup|null> = toRef(props, 'selectedHistory');

    const handleChangeHistory = async (historyIndex: number) => {
      emit('on-change-history', historyIndex);
    };

    return {
      t,
      selected,
      handleChangeHistory,
      hideLeftToolbar,
    };
  },
});
