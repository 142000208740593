<template>
  <div
    style="text-align: center"
  >
    <div v-if="icon">
      <img
        v-tooltip="translation(`languages.codes.${icon}`)"
        :alt="altText"
        :src="iconSrc"
        class="icon"
      >
    </div>
    <div v-else>
      <i class="fa-solid fa-question" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import Tooltip from 'primevue/tooltip';

export default defineComponent({
  name: 'LanguageIcons',

  directives: {
    tooltip: Tooltip,
  },

  props: {
    language: {
      type: String,
      required: false,
      default: '',
    },

    alt: {
      type: String,
      required: false,
      default: null,
    },

    translation: {
      type: Function,
      required: true,
    },
  },

  setup(props: { language: string; alt: string}) {
    const { language } = props;

    const icon = ref('');
    const iconSrc = ref();

    onMounted(async () => {
      if (props.language && props.language !== '') {
        icon.value = language.toLowerCase();
      }
      // Dynamically generate the path to the icon based on the icon
      try {
        const iconModule = await import(`language-icons/icons/${icon.value}.svg`);
        iconSrc.value = iconModule.default;
      } catch (error) {
        icon.value = '';
        iconSrc.value = '';
      }
    });

    return {
      icon,
      iconSrc,
      altText: props.alt ?? props.language,
    };
  },
});
</script>

<style lang="css" scoped>
.icon {
  width: 20px;
  height: 20px;
}
</style>
