<template>
  <div class="configuration-wrapper">
    <Panel>
      <template #header>
        <div class="panel-header-content">
          <h4>{{ t('templateBuilder.panels.cssPanel.header') }}</h4>
        </div>
      </template>

      <template
        v-if="!widgetId"
        #icons
      >
        <i
          class="far fa-times icons-header-panel"
          @click="hideLeftToolbar"
        />
      </template>

      <div class="field-group pt-3">
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <div class="p-fluid flex">
                <BaseInformation
                  :message="message"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="field-group pt-3">
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <div class="p-fluid flex">
                <VCodeMirror
                  ref="codeMirrorEditorRef"
                  v-model:value="inputValue"
                  :options="cmOptions"
                  :auto-height="true"
                  @keyup="handleValueChange"
                  @paste="handleValueChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  Ref,
  ref,
} from 'vue';
import BaseInformation from '@/components/fields/BaseInformation.vue';
import { VCodeMirror } from 'vue3-code-mirror';
import { isDisplayTemplate } from '@/components/template-builder/utils/helpers';
import { hideLeftToolbar, TemplateEditorState, setChangeInLeftToolbar } from '@/composables/template-editor/TemplateEditor';
import {
  getCssStyles,
  setCssStyles,
} from '@/components/template-builder/setters';
import { useI18n } from 'vue-i18n';
import Panel from 'primevue/panel';
import {
  CUSTOM_WIDGET_CSS_PREFIX,
  TEMPLATE_WIDGET_IDENTIFIER_PREFIX,
} from '@/components/template-builder/utils/constants';

export default defineComponent({
  name: 'CssPanel',

  components: {
    BaseInformation,
    VCodeMirror,
    Panel,
  },

  props: {
    widgetId: {
      type: String,
      required: false,
      default: '',
    },
  },

  setup(props) {
    const { t } = useI18n();

    const cmOptions = {
      mode: 'css', // Language mode
      theme: 'default', // Theme
      lineNumbers: true, // Show line number
      smartIndent: true, // Smart indent
      indentUnit: 4, // The smart indent unit is 2 spaces in length
      foldGutter: true, // Code folding
      styleActiveLine: true, // Display the style of the selected row
    };

    const inputValue: Ref<string> = ref('');
    const codeMirrorEditorRef = ref();
    const message: Ref<string> = ref('');
    const cssType = ref('static');

    const handleValueChange = () => {
      // Insert new value in iframe (optional : create the style tag if doesn't exist)
      let sectionId = null;
      if (isDisplayTemplate(TemplateEditorState.template?.type)) {
        sectionId = TemplateEditorState.template?.sections[0].id_template_elements.toString();
      } else {
        sectionId = TemplateEditorState.template?.content.design.toString();
      }
      setCssStyles(cssType.value, inputValue.value, sectionId);
      setChangeInLeftToolbar();
    };

    onMounted(() => {
      message.value = isDisplayTemplate(TemplateEditorState.template?.type) ? 'templateBuilder.informations.cssPanelDisplay' : 'templateBuilder.informations.cssPanelEmail';
      if (TemplateEditorState.template && TemplateEditorState.template.informations.imported) {
        cssType.value = 'dynamic';
      }
      if (props.widgetId) {
        cssType.value = `${CUSTOM_WIDGET_CSS_PREFIX}${props.widgetId.split(TEMPLATE_WIDGET_IDENTIFIER_PREFIX)[1]}`;
      }
      inputValue.value = getCssStyles(cssType.value, true);
    });

    return {
      t,
      cmOptions,
      inputValue,
      codeMirrorEditorRef,
      handleValueChange,
      message,
      hideLeftToolbar,
    };
  },
});
</script>

<style lang="scss" scoped>
.v-code-mirror {
  width: 100%;
}

:deep() .CodeMirror {
  height: 100% !important;
}

.icons-header-panel {
  cursor: pointer;
  color: #607D8B;
}

.field-group-content {
  .field-wrapper:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}
</style>
