
import {
  computed,
  defineComponent,
} from 'vue';
import {
  hasAccessToFeatures,
} from '@/composables/User';
import { PlanName } from '@/types/enums';

export default defineComponent({
  name: 'StatsWidgetDataConfigOption',
  components: {},

  props: {
    option: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const featurePermission = computed(() => hasAccessToFeatures(`${props.option.group}.${props.option.category}.${props.option.metric}`));
    const minimumPlan = computed(() => {
      if (featurePermission.value && featurePermission.value.minimumPlan !== PlanName.STANDARD) {
        return featurePermission.value.minimumPlan;
      }
      return null;
    });

    return {
      minimumPlan,
    };
  },
});
