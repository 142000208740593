
import {
  defineComponent,
  PropType,
  ref,
  reactive,
  SetupContext,
  watch,
  onBeforeMount,
} from 'vue';

import Loader from '@/components/layout/Loader.vue';
import ActionConfig from '@/components/automated-scenarios/ActionConfig.vue';

import ActionSendSmsMetaData, {
  ActionSendSmsData,
} from '@/components/automated-scenarios/metadata/actions/ActionSendSmsMetadata';
import { AutomatedScenarioState } from '@/composables/AutomatedScenarios';
import { retrieveServicesData } from '@/composables/shop/MyShopParameters';
import { UserState } from '@/composables/User';
import { showToastError } from '@/helpers';

export default defineComponent({
  name: 'ActionSendSms',

  components: {
    ActionConfig,
    Loader,
  },

  props: {
    modelValue: {
      type: Object as PropType<ActionSendSmsData>,
      required: true,
      default() {
        return ActionSendSmsMetaData.Create();
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },

    selectedComponent: {
      type: String,
      required: false,
      default: '',
    }
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: ActionSendSmsData; formValidation: any }, context: SetupContext) {

    const isLoading = ref(false);

    const formValidationErrors = ref(props.formValidation);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
    });

    const currentCampaignId = ref(AutomatedScenarioState.scenario.id);
    const currentCampaignType = ref(AutomatedScenarioState.scenario.type);

    const shopId = UserState.activeShop?.id ?? 0;

    const field = reactive({
      shopRequiredDataSms_senderName: {},
    });

    const namesFields = {
      method: 'template_display_reduction_method'.replace('_reduction_', `_reduction${props.modelValue.template_display_selector_id}_`),
      descript: 'template_display_reduction_descript'.replace('_reduction_', `_reduction${props.modelValue.template_display_selector_id}_`),
      type: 'template_display_reduction_type'.replace('_reduction_', `_reduction${props.modelValue.template_display_selector_id}_`),
      identifiant: 'template_display_reduction_identifiant'.replace('_reduction_', `_reduction${props.modelValue.template_display_selector_id}_`),
      amount: 'template_display_reduction_amount'.replace('_reduction_', `_reduction${props.modelValue.template_display_selector_id}_`),
      duplicate: 'template_display_reduction_duplicate'.replace('_reduction_', `_reduction${props.modelValue.template_display_selector_id}_`),
      minorderamount: 'template_display_reduction_minorderamount'.replace('_reduction_', `_reduction${props.modelValue.template_display_selector_id}_`),
      validtime: 'template_display_reduction_validtime'.replace('_reduction_', `_reduction${props.modelValue.template_display_selector_id}_`),
      prefix: 'template_display_reduction_prefix'.replace('_reduction_', `_reduction${props.modelValue.template_display_selector_id}_`),
    };

    const dataFields = ref<ActionSendSmsData>({
      template_display_selector_id: props.modelValue.template_display_selector_id,
      reuse_a_previous_coupon: props.modelValue.reuse_a_previous_coupon,
      display_popup_on_site: props.modelValue.display_popup_on_site,
      template_display_reduction_method: props.modelValue.template_display_reduction_method ?? props.modelValue[namesFields.method],
      template_display_reduction_descript: props.modelValue.template_display_reduction_descript ?? props.modelValue[namesFields.descript],
      template_display_reduction_type: props.modelValue.template_display_reduction_type ?? props.modelValue[namesFields.type],
      template_display_reduction_identifiant: props.modelValue.template_display_reduction_identifiant ?? props.modelValue[namesFields.identifiant],
      template_display_reduction_amount: props.modelValue.template_display_reduction_amount ?? props.modelValue[namesFields.amount],
      template_display_reduction_duplicate: props.modelValue.template_display_reduction_duplicate ?? props.modelValue[namesFields.duplicate],
      template_display_reduction_minorderamount: props.modelValue.template_display_reduction_minorderamount ?? props.modelValue[namesFields.minorderamount],
      template_display_reduction_validtime: props.modelValue.template_display_reduction_validtime ?? props.modelValue[namesFields.validtime],
      template_display_reduction_prefix: props.modelValue.template_display_reduction_prefix ?? props.modelValue[namesFields.prefix],
      template_display_message_langs: props.modelValue.template_display_message_langs,
      template_message_expname: props.modelValue.template_message_expname,
    });

    const update = () => {
      context.emit('update:modelValue', ActionSendSmsMetaData.Create({ ...dataFields.value }));
    };

    watch([dataFields], update, { deep: true });

    onBeforeMount(async () => {
      try {
        if (!Object.values(dataFields.value.template_message_expname).some((value) => value !== null && value !== '')) {
          isLoading.value = true;
          await retrieveServicesData(shopId, field);
          dataFields.value.template_message_expname = field.shopRequiredDataSms_senderName;
        }
      } catch (error) {
        showToastError('GENERIC_ERROR');
      } finally {
        isLoading.value = false;
      }
    });

    return {
      dataFields,
      formValidationErrors,
      currentCampaignId,
      currentCampaignType,
      isLoading,
    };
  },
});
