
import {
  defineComponent,
  computed,
  onMounted,
  ref,
} from 'vue';

import Card from 'primevue/card';
import Chip from 'primevue/chip';
import Skeleton from 'primevue/skeleton';

import AddressItem from '@/components/data-explorer/fields/AddressItem.vue';
import DataExplorerLink from '@/components/data-explorer/fields/DataExplorerLink.vue';
import ProductItem from '@/components/data-explorer/fields/ProductItem.vue';
import List from "@/components/data-explorer/fields/List.vue";
import SectionTitle from "@/components/data-explorer/fields/SectionTitle.vue";

import {
  getAddressByIdShopAddress,
  getOrdersProducts,
  getOrderStatus,
} from '@/composables/data-explorer/DataExplorer';

import { formatDate } from '@/helpers/Date';
import { formatNumberToCurrency } from '@/helpers/Number';

import { DataExplorerDataType } from '@/types';
import { OrderProduct } from '@/types/data-explorer-types';

import { useStore } from '@/store';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'OrderFile',

  components: {
    AddressItem,
    Card,
    Chip,
    DataExplorerLink,
    List,
    SectionTitle,
    Skeleton,
    ProductItem,
  },

  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const selectedData = computed(() => store.getters['dataExplorer/getSelectedData']);

    const title = computed(() => {
      if (selectedData.value) {
        return t(`dataExplorer.tabs.${selectedData.value.dataType}`, [selectedData.value.orderReference])
      }
      return '';
    });

    const vouchers = computed(() => {
      if (selectedData.value.voucher) {
        return selectedData.value.voucher.split(',');
      }
      return [];
    });

    const loadingDeliveryAddress = ref(false);
    const loadingInvoiceAddress = ref(false);

    const handleRetrieveOrderProducts = (orderProducts: OrderProduct[]) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          newLists: orderProducts,
          dataKey: 'products',
        }
      );
    };

    const dataExplorerType = ref(DataExplorerDataType);

    onMounted(async () => {
      try {
        if (selectedData.value && !selectedData.value.statusLoaded) {
          const status = await getOrderStatus(selectedData.value.status, locale.value);
          if (status) {
            store.dispatch('dataExplorer/updateOrderFileStatus', { status });
          } else {
            store.dispatch('dataExplorer/updateOrderFileStatus', { status: '' });
          }
        }

        loadingDeliveryAddress.value = true;
        loadingInvoiceAddress.value = true;

        const dispatchAddress = (newLists: any[], dataKey: string) => {
          store.dispatch(
              'dataExplorer/setAdditionnalData',
              {
                dataId: selectedData.value.dataId,
                newLists,
                dataKey,
              }
            );
        }

        if (selectedData.value && !selectedData.value.deliveryAddress.dataLoaded) {
          const deliveryAddress = await getAddressByIdShopAddress(selectedData.value.idAddressDelivery);
          if (deliveryAddress) {
            dispatchAddress([deliveryAddress], 'deliveryAddress');
          } else {
            dispatchAddress([], 'deliveryAddress');
          }
        }
        if (selectedData.value && !selectedData.value.invoiceAddress.dataLoaded) {
          const invoiceAddress = await getAddressByIdShopAddress(selectedData.value.idAddressDelivery);
          if (invoiceAddress) {
            dispatchAddress([invoiceAddress], 'invoiceAddress');
          } else {
            dispatchAddress([], 'invoiceAddress');
          }
        }
      } catch (err) {
        // Empty
      } finally {
        loadingDeliveryAddress.value = false;
        loadingInvoiceAddress.value = false;
      }
    });

    return {
      t,
      locale,
      title,
      selectedData,
      loadingDeliveryAddress,
      loadingInvoiceAddress,
      vouchers,
      dataExplorerType,

      formatDate,
      formatNumberToCurrency,
      getOrdersProducts,
      handleRetrieveOrderProducts,
    }
  }
})
