
import {
  defineComponent,
  defineExpose,
  ref,
  reactive,
  onMounted,
} from 'vue';

import SelectButton from 'primevue/selectbutton';

import ConfirmPopup from 'primevue/confirmpopup';
import { useConfirm } from 'primevue/useconfirm';

import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import SimpleColorPicker from '@/components/fields/SimpleColorPicker.vue';
import Loader from '@/components/layout/Loader.vue';
import FBDisconnect from '@/views/shop/tabs/modals/FBDisconnect.vue';

import { showToastError, showToastSuccess } from '@/helpers';
import { ShopsConfigurationInput, ShopsConfigurationInputItem } from '@/types/generated-types/graphql';
import { saveShopParamsOnRedis, SaveShopsConfiguration } from '@/composables/shop/ShopsConfiguration';

import {
  deleteFacebookTokens,
  generateUserExtendedToken,
  saveFacebookPage,
  saveFacebookUser,
} from '@/composables/shop/MyShopParameters';

import { useI18n } from 'vue-i18n';

// eslint-disable-next-line @typescript-eslint/no-var-requires,import/extensions
declare global { interface Window { FB: any; fbAsyncInit: any } } const { FB, fbAsyncInit } = window;

interface Pages {
  token: '';
  category: '';
  category_list: {};
  id: '';
  name: '';
  tasks: {};
  access_token: '';
}

interface FacebookUser {
  id: string;
  extendedToken: string;
  name: string;
  email: string;
  picture: string;
  pages: Pages[];
}

interface FacebookUserData {
  fbLinkedPage: {pageId: string; pageToken: string};
  fbLoggedUser: FacebookUser;
}

export default defineComponent({
  name: 'FacebookServiceParameter',

  components: {
    SelectButton,
    SimpleColorPicker,
    SpmButton,
    Loader,
    FBDisconnect,
    ConfirmPopup,
  },

  props: {
    retrieveData: {
      type: Function,
      required: true,
    },

    shopId: {
      type: Number,
      required: true,
    },

    shopUrl: {
      type: String,
      required: true,
    },

    globalSave: {
      type: Boolean,
      required: false,
      default: false,
    },

    name: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['close'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const confirm = useConfirm();
    const isLoading = ref(false);

    const facebookConnected = ref(false);
    const extendedToken = ref();
    const showFbDisconnectModal = ref(false);
    const pagesUpdateKey = ref(0);
    const isLinkingPage = ref(false);
    const pageBeingLinked = ref();
    const chosenPage = ref({});
    const showConfigError = ref(false);
    const showPageLinkError = ref(false);
    const saveLoading = ref(false);

    const options = ref([
      { value: '1', label: t('yes') },
      { value: '0', label: t('no') },
    ]);

    const field = reactive({
      shopRequiredDataFacebook_colorPlugin: '',
      shopRequiredDataFacebook_sizePlugin: '0',
      shopRequiredDataFacebook_chatActiveConfig: '0',
    });

    const facebookUserState = reactive<FacebookUserData>({
      fbLinkedPage: { pageId: '', pageToken: '' },
      fbLoggedUser: {
        id: '',
        extendedToken: '',
        name: '',
        email: '',
        picture: '@/assets/facebook-logo-rond.png',
        pages: [],
      },
    });

    const assignUserData = (user: any) => {
      facebookUserState.fbLoggedUser.id = user.id;
      facebookUserState.fbLoggedUser.name = user.name;
      facebookUserState.fbLoggedUser.picture = (typeof user.picture === 'string' ? user.picture : user.picture.data.url);
      facebookUserState.fbLoggedUser.pages = user.pages;
      facebookUserState.fbLoggedUser.extendedToken = user.extendedToken;
    };

    const getFacebookPages = async () => {
      window.FB.api(`/me/accounts?access_token=${facebookUserState.fbLoggedUser.extendedToken}`, async (response: { data: string | any[] }) => {
        if (typeof (response.data) !== 'undefined') {
          if (response.data.length > 0) {
            const pagesResponse = response.data;
            window.FB.api(`/me?access_token=${facebookUserState.fbLoggedUser.extendedToken}`, { fields: 'id,name,email,picture' }, async (userResponse: any) => {
              const user = userResponse;
              user.pages = pagesResponse;
              user.extendedToken = facebookUserState.fbLoggedUser.extendedToken;
              const fbUserFromResponse = await saveFacebookUser(props.shopId, user);
              const userFromResponse = JSON.parse(fbUserFromResponse.config.data);

              if (userFromResponse.user) {
                assignUserData(userFromResponse.user);
              }

              facebookConnected.value = true;
              isLoading.value = false;
            });
          } else { facebookConnected.value = false; isLoading.value = false; }
        } else { facebookConnected.value = false; isLoading.value = false; }
      });
    };

    const facebookLogin = async () => {
      isLoading.value = true;
      window.FB.login(async (response: any) => {
        if (typeof (response.authResponse) !== 'undefined' && response.status !== 'unknown' && typeof (response.authResponse.accessToken) !== 'undefined') {
          facebookConnected.value = true;
          if (typeof (extendedToken.value) === 'undefined') {
            const result = await generateUserExtendedToken(response.authResponse.accessToken);
            if (result.data && result.data.access_token) {
              facebookUserState.fbLoggedUser.extendedToken = result.data.access_token;
              await getFacebookPages();
            } else {
              isLoading.value = false;
            }
          } else {
            await getFacebookPages();
          }
        } else {
          isLoading.value = false;
        }
      }, {
        scope: 'email, public_profile, manage_pages, pages_messaging',
        enable_profile_selector: true,
      });
    };

    const spmLogout = async () => {
      facebookConnected.value = false;

      facebookUserState.fbLoggedUser = {
        id: '',
        extendedToken: '',
        name: '',
        email: '',
        picture: '@/assets/facebook-logo-rond.png',
        pages: [],
      };

      const keysToDelete = ['fbLoggedUser', 'fbLinkedPage', 'fbLinkedPageId', 'fbChatConfiguration'];
      // On supprime la configuration de la boutique contenant l'ID de la page facebook et son token
      await deleteFacebookTokens(props.shopId, JSON.stringify(keysToDelete));
      showFbDisconnectModal.value = false;
      await showToastSuccess('Disconnected');
    };

    const facebookLogOut = async (event: any) => {
      if (!event) return;
      confirm.require({
        group: 'basicGroup',
        target: event.currentTarget,
        message: t('storeParameters.services.facebook.disconnectConfirm'),
        header: 'Confirmation',
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('yes'),
        rejectLabel: t('no'),
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: async () => {
          window.FB.getLoginStatus(async (response: { authResponse: any }) => {
            if (response.authResponse) {
              window.FB.logout(async (response: any) => {
                await spmLogout();
              });
            } else {
              await spmLogout();
            }
          });
        },
      });
    };

    const closeDCModal = () => {
      showFbDisconnectModal.value = false;
    };

    const deleteAndResetFacebookParams = async () => {
      const keysToDelete = ['fbLinkedPage', 'fbLinkedPageId', 'fbChatConfiguration'];
      await deleteFacebookTokens(props.shopId, JSON.stringify(keysToDelete));

      showPageLinkError.value = true;
      setTimeout(() => {
        showPageLinkError.value = false;
      }, 5000);
    };

    const linkPage = async (id: string, token: string) => {
      isLinkingPage.value = true;
      pageBeingLinked.value = id;
      saveFacebookPage(props.shopId, id, token, props.shopUrl).then((result) => {
        chosenPage.value = { pageId: id, pageToken: token };
        pagesUpdateKey.value += 1;
        isLinkingPage.value = false;
      }).catch(async (error) => {
        if (error.response) {
          await deleteAndResetFacebookParams();
        } else if (error.request) {
          await deleteAndResetFacebookParams();
        } else {
          await deleteAndResetFacebookParams();
          // Something happened in setting up the request that triggered an Error
        }
        isLinkingPage.value = false;
        pageBeingLinked.value = undefined;
      });
    };

    const initFacebook = async () => {
      if (typeof (window.FB) !== 'undefined'
        && window.FB != null) {
        window.FB.init({
          appId: process.env.VUE_APP_FACEBOOK_APP_ID,
          cookie: true,
          xfbml: true,
          version: 'v3.3',
        });

        if (typeof window.fbAsyncInit !== 'function') {
          isLoading.value = false;
        }
        // Get user Pages
        if (facebookUserState.fbLoggedUser.id !== '') {
          getFacebookPages();
        } else {
          isLoading.value = false;
        }
      } else {
        isLoading.value = false;
      }
    };
    const initFacebookSdk = async () => {
      if (typeof (window.FB) !== 'undefined'
          && window.FB != null) {
        await initFacebook();
      } else {
        // wait for facebook sdk to initialize before starting the vue app
        window.fbAsyncInit = () => {
          initFacebook();
        };
        // load facebook sdk script
        const plugin = document.createElement('script');
        plugin.setAttribute(
          'src',
          'https://connect.facebook.net/en_US/sdk.js',
        );
        plugin.id = 'facebook-jssdk';
        plugin.async = true;
        document.head.appendChild(plugin);
      }
    };

    // eslint-disable-next-line consistent-return
    const handleSave = async () => {
      if (!facebookConnected.value) {
        showConfigError.value = true;
        showToastError(t('storeParameters.services.facebook.configError'));
        return {
          err: 1,
          configs: [],
        };
      }

      const facebookState = { ...field };

      // unpack changed values and update
      const shopsConfigArray: ShopsConfigurationInputItem[] = [];
      let key = '';
      let value = '';
      Object.entries(facebookState).forEach((keyValuePair: any) => {
        [key, value] = [...keyValuePair];
        const shopsConfigRecord: ShopsConfigurationInputItem = {
          key: '',
          value: '',
          lang: '',
        };

        shopsConfigRecord.key = key;
        shopsConfigRecord.value = value;
        shopsConfigRecord.lang = '';
        shopsConfigArray.push(shopsConfigRecord);
      });

      if (props.globalSave) {
        return {
          err: null,
          configs: shopsConfigArray,
        };
      }

      const input: ShopsConfigurationInput = {
        id_shop: props.shopId,
        configs: [],
      };
      input.id_shop = props.shopId;
      input.configs = shopsConfigArray;

      saveLoading.value = true;

      try {
        await SaveShopsConfiguration(input);
        await saveShopParamsOnRedis(props.shopId);
        if (!props.globalSave) {
          showToastSuccess('savedSuccessful');
        }
      } catch (err) {
        if (!props.globalSave) {
          showToastError('GENERIC_ERROR');
        } else {
          throw err;
        }
      } finally {
        saveLoading.value = false;
      }
    };

    defineExpose({
      handleSave,
    });

    onMounted(async () => {
      try {
        isLoading.value = true;
        await props.retrieveData(field);
        const fbUserField = reactive({ fbLoggedUser: '', fbLinkedPage: '' });
        await props.retrieveData(fbUserField);
        // Assign variables to retrieve data at load
        if (fbUserField.fbLoggedUser !== '') {
          assignUserData(JSON.parse(fbUserField.fbLoggedUser));
        }
        if (fbUserField.fbLinkedPage !== '') {
          chosenPage.value = JSON.parse(fbUserField.fbLinkedPage);
        }
        await initFacebookSdk();
      } catch (err) {
        showToastError('GENERIC_ERROR');
      }
    });

    return {
      t,
      isLoading,
      saveLoading,
      field,
      options,
      facebookConnected,
      facebookUserState,
      showFbDisconnectModal,
      pagesUpdateKey,
      isLinkingPage,
      pageBeingLinked,
      chosenPage,
      showConfigError,
      showPageLinkError,
      handleSave,
      facebookLogin,
      facebookLogOut,
      closeDCModal,
      linkPage,
    };
  },
});
