import {
  TEMPLATE_WIDGET_IDENTIFIER_PREFIX,
  TEMPLATE_TRANSLATE_SPECIFIC,
  TEMPLATE_TRANSLATE_SPECIFIC_ITEMS,
  TEMPLATE_TRANSLATE_SPECIFIC_ONLY_CONTENT,
} from '@/components/template-builder/utils/constants';
// eslint-disable-next-line import/no-cycle
import { generateUniqStructureId } from '@/components/template-builder/utils/helpers';
import { WidgetTypeEnum } from '@/types';
import { UserState } from '@/composables/User';

// widgets
const rawWidgetText = (t: Function) => () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  // @TODO : utiliser la vraie image de profil facebook enregistrée dans shops_configuration
  const facebookProfilePicture = `//proxima.shopimind.com/clients/logos/${UserState.activeShop?.logo || ''}`;
  const text = t('templateBuilder.widgets.fbTextButtons.default');
  return `<div id="${widgetId}" class="spm_widget_fb_text spm_draggable_widget spm_widget ${TEMPLATE_TRANSLATE_SPECIFIC}" data-widgettype="spm_widget_fb_text">
      <div class="fb_profile_picture">
          <img src="${facebookProfilePicture}" />
      </div>
      <div class="fb_text">
          <div class="text spm_inline_editing ${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}">
            ${text}
          </div>
          <div class="buttons fbButtons"></div>
      </div>
    </div>`;
};

const rawWidgetImage = () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  // @TODO : utiliser la vraie image de profil facebook enregistrée dans shops_configuration
  const facebookProfilePicture = `//proxima.shopimind.com/clients/logos/${UserState.activeShop?.logo || ''}`;
  const src = 'http://media.shopimind.io/img/v4/placeholders/image_placeholder.png';
  return `<div id="${widgetId}" class="spm_widget_image spm_draggable_widget spm_widget fb_media ${TEMPLATE_TRANSLATE_SPECIFIC}" data-widgettype="spm_widget_image">
      <div class="fb_profile_picture">
          <img src="${facebookProfilePicture}" />
      </div>
      <div class="image_widget ${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}">
          <img src="${src}" width="100%" />
      </div>
      <div class="buttons fbButtons"></div>
    </div>`;
};

const rawWidgetSmartProductList = (t: Function) => () => {
  const widgetId = `${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}${generateUniqStructureId()}`;
  // @TODO : utiliser la vraie image de profil facebook enregistrée dans shops_configuration
  const facebookProfilePicture = `//proxima.shopimind.com/clients/logos/${UserState.activeShop?.logo || ''}`;
  return `<div id="${widgetId}" class="spm_fb_productslist spm_fb_smartlist spm_draggable_widget spm_widget" data-widgettype="spm_fb_productslist">
    <div class="fb_profile_picture">
      <img src="${facebookProfilePicture}" />
    </div>
    <div class="fb_list horizontal">
      <div class="fb_list_arrow left disabled">
        <i class="fa fa-2x fa-arrow-left"></i>
      </div>
      <div class="fb_list_arrow right">
        <i class="fa fa-2x fa-arrow-right"></i>
      </div>
      <div class="row spm_product_list spm_product_list_horizontal">
        <div class="columns spm_product_list_element_container" data-limitmin="1" data-limitmax="10" data-widget="spm_fb_productslist">
          <div class="spm_product_list_element_row">
            <div class="column spm_product_list_element"
              s-collection="products" s-item="product" s-grid="10" s-nb="4" s-engine="crossselling"
              s-ids="" s-excluded_ids="" s-excluded_categories="" s-excluded_manufacturers="" s-nb_weeks="4">
              <p class="spm_product_list_element_image_wrapper" style="text-align:center;margin:0;padding:0;">
                <img src="{var=product.image_url resize=400x400 resizeType=fill-to-fit}"
                class="spm_product_list_element_image"
                 alt="{var=product.name}" title="{var=product.name}" />
              </p>
              <h4 class="spm_product_list_element_title" data-limitmax="80">{var=product.name truncate="25"}</h4>
              <div class="spm_product_list_element_description" data-limitmax="80">
                <p>{var=product.description_short truncate="80"}</p>
              </div>
              <div class="small-button button spm_product_list_element_button">
                <a href="{var=product.url}">
                  ${t('templateBuilder.smartProductListDefaultButton')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>`;
};

export const facebookButtonStructure = () => `
    <div class="button" data-redirect=""></div>`;

export const rawWidgets = (t: Function): { [key: string]: () => string } => ({
  [WidgetTypeEnum.TEXT]: rawWidgetText(t),
  [WidgetTypeEnum.IMAGE]: rawWidgetImage,
  [WidgetTypeEnum.SMART_PRODUCT_LIST]: rawWidgetSmartProductList(t),
});
