import { GetShopsConfigurationList } from '@/composables/shop/ShopsConfiguration';
import { UserState } from '@/composables/User';
import { OperatorType, ShopsSendDomains } from '@/types/generated-types/graphql';
import getShopsDomains from '@/composables/shop/ShopsSendDomains';

export const searchValuesForAutocompletion = async (field: string, value: string, myDataAutoCompletion: any): Promise<string[]> => {
  let returnArray = [value];

  if (field === 'senderName') {
    returnArray = [...myDataAutoCompletion.senderNames.filter((element: string) => element.toLowerCase().startsWith(value.toLowerCase()) && element !== value), value];
  } else if (field === 'senderAddress') {
    if (myDataAutoCompletion.domainsOfShop.length) {
      returnArray = [];

      // We use the current shop domains to return additional values
      const explode = value.split('@');
      const after = explode.length > 1 ? explode.pop() : '';
      const before = explode.join('@');

      // 20241602-sv4-52- load domain list on each key down
      const updatedDomainAutoCompletionList = await getShopsDomains(0, [{
        field: 'domain_verified',
        operator: OperatorType.Equals,
        value: true,
      }]);

      // We check if we can add the domains of the shop
      updatedDomainAutoCompletionList.forEach((domain: ShopsSendDomains) => {
        const returnValue = `${before}@${domain.domain}`;

        if (((after && after.trim() === '') || (domain.domain.match(new RegExp(`^${after}`))))) {
          returnArray.unshift(returnValue);
        }
      });
    } else {
      returnArray = [...myDataAutoCompletion.senderAddresses];
    }
  }

  return returnArray;
};

export const loadSenderAddresses = async (): Promise<string[]> => {
  const result = await GetShopsConfigurationList({
    shopId: UserState.activeShop?.id ?? 0,
    key: 'select2_list_emails_from_address',
    fields: 'id_shop_configuration, key, value',
    limit: 0,
    offset: 0,
  });

  const suggestions: string[] = [];
  if (result && result.length && result[0].value !== '') {
    const json = JSON.parse(result[0].value);

    if (json) {
      json.forEach((element: string) => {
        suggestions.push(element);
      });
    }
  }

  return suggestions;
};

export const loadSenderNames = async (mediaType: string): Promise<string[]> => {
  let key = '';
  if (mediaType === 'email') {
    key = 'select2_list_emails_from_name';
  } else if (mediaType === 'sms') {
    key = 'select2_list_sms_from_name';
  }

  const result = await GetShopsConfigurationList({
    shopId: UserState.activeShop?.id ?? 0,
    key,
    fields: 'id_shop_configuration, key, value',
    limit: 0,
    offset: 0,
  });

  const suggestions: string[] = [];
  if (result && result.length && result[0].value !== '') {
    const json = JSON.parse(result[0].value);
    if (json) {
      json.forEach((element: string) => {
        suggestions.push(element);
      });
    }
  }

  return suggestions;
};
