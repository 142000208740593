<template>
  <Dialog
    v-model:visible="displayModal"
    data-test-id="choose-items-dialog"
    :header="t('templateBuilder.modals.sendTest.header')"
    :modal="true"
    :position="position"
    :breakpoints="{ '960px': '60vw', '640px': '100vw' }"
    :style="{ width: '40vw' }"
    :dismissable-mask="true"
    @update:visible="handleClose"
  >
    <div class="grid">
      <div
        v-if="type === 'sms'"
        class="country-dropdown-container"
      >
        <CountryDropdown
          v-model="countryAndState"
          :configuration="{ showStates: false, showLabels: false, showFlags: true, showPhonesCodes: true, showOnlyPhoneCodeForSelection: true }"
          :error="{ country: false, state: false }"
        />
      </div>
      <div :class="type === 'sms' ? 'autocomplete-container' : 'col-12'">
        <AutoComplete
          ref="autocompleteRef"
          v-model="v$.fieldValue.$model"
          class="sendtestmodal-autocomplete"
          input-class="sendtest-modal-autocomplete__input"
          :multiple="type === 'email'"
          :suggestions="dataSource"
          field="value"
          :placeholder="t(`templateBuilder.modals.sendTest.placeholder${type}`)"
          @complete="search($event)"
        />
        <FieldErrors
          :errors="v$"
          field="fieldValue"
          class="text-left"
        />
      </div>
    </div>
    <template #footer>
      <Button
        data-test-id="input-text-button-cancel"
        :label="t(cancelButtonText)"
        icon="far fa-times"
        class="p-button-secondary"
        @click="handleClose"
      />
      <Button
        data-test-id="input-text-button-ok"
        :label="t(validateButtonText)"
        :disabled="v$.$silentErrors.length"
        icon="far fa-check"
        class="p-button-primary"
        @click="handleValidation"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  Ref,
  ref,
  computed,
  ComputedRef,
  watch,
  nextTick,
} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';
import {
  required,
  helpers,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import AutoComplete from 'primevue/autocomplete';
// eslint-disable-next-line import/no-cycle
import { useStore } from '@/store';
// eslint-disable-next-line import/no-cycle
import { TemplateEditorState } from '@/composables/template-editor/TemplateEditor';
import {
  SaveShopsConfiguration,
  GetShopsConfigurationList,
} from '@/composables/shop/ShopsConfiguration';
import { UserState } from '@/composables/User';
import getShopsDomains from '@/composables/shop/ShopsSendDomains';
import {
  ShopsConfigurationInput,
  ShopsSendDomains,
} from '@/types/generated-types/graphql';
import { isValidEmail } from '@/helpers/Email';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import CountryDropdown from '@/components/fields/CountryDropdown.vue';

export interface Suggestion {
  key: string;
  value: string;
  country?: string;
}

export enum TypeSendTestEnum {
  EMAIL = 'email',
  SMS = 'sms',
}

export default defineComponent({
  name: 'SendTestModal',

  components: {
    CountryDropdown,
    Dialog,
    AutoComplete,
    Button,
    FieldErrors,
  },

  props: {
    position: {
      type: String,
      required: false,
      default: 'center',
    },

    cancelButtonText: {
      type: String,
      required: false,
      default: 'templateBuilder.modals.cancel',
    },

    validateButtonText: {
      type: String,
      required: false,
      default: 'templateBuilder.modals.validate',
    },

    typeOfInput: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: {
    'on-close-dialog': null,
    'on-validate': String,
  },

  setup(props, context) {
    const { t } = useI18n();
    const store = useStore();
    const displayModal: ComputedRef<boolean> = computed(() => store.state.general.sendTestModalVisible);
    const type: ComputedRef<string> = computed(() => props.typeOfInput ? props.typeOfInput : TemplateEditorState.template?.type || 'email'); // Template type
    const fieldValue: Ref<Suggestion|Suggestion[]|null> = ref(null); // Final value of the input
    const dataSource: Ref<object[]> = ref([]); // Filtered items for autocompletion
    const domainsOfShop: Ref<ShopsSendDomains[]> = ref([]);
    const autocompleteRef = ref();
    const errorMessage: ComputedRef<string> = computed(() => {
      let translation = '';

      if (type.value === TypeSendTestEnum.EMAIL) {
        translation = 'templateBuilder.modals.sendTest.errorEmail';
      } else if (type.value === TypeSendTestEnum.SMS) {
        translation = 'templateBuilder.modals.sendTest.errorSms';
      }

      return translation;
    });

    // Get the list of possible items by type
    const getItems = async (): Promise<{key: string; value: string}[]> => {
      let key = '';
      if (type.value === TypeSendTestEnum.EMAIL) {
        key = 'select2_list_emails_to_address';
      } else if (type.value === TypeSendTestEnum.SMS) {
        key = 'select2_list_sms_to_number';
      }

      const data = await GetShopsConfigurationList({
        shopId: UserState.activeShop?.id ?? 0,
        key,
        fields: 'id_shop_configuration, key, value',
        limit: 0,
        offset: 0,
      });

      const suggestions: Suggestion[] = [];
      if (data && data.length && data[0].value !== '') {
        const json = JSON.parse(data[0].value);

        if (json) {
          json.forEach((element: string) => {
            const suggestion: Suggestion = {
              key: element,
              value: element,
            };

            suggestions.push(suggestion);
          });
        }
      }

      return suggestions;
    };
    const sendTestItems: Ref<Suggestion[]> = ref([]);
    const countryAndState = ref<{ country: CountryCode; state: string }>({
      country: (UserState.user.country ?? 'FR').toUpperCase() as CountryCode,
      state: '',
    });

    // Search function when typing in the field to return only matching elements
    const search = (event: any) => {
      // We get the current value of the input to exclude the selected values from the suggestions
      const current: string[] = [];

      if (type.value === TypeSendTestEnum.EMAIL && Array.isArray(fieldValue.value)) {
        fieldValue.value.forEach((item) => {
          current.push(item.value);
        });
      } else if (type.value === TypeSendTestEnum.SMS && !Array.isArray(fieldValue.value) && fieldValue.value && fieldValue.value.value !== '') {
        current.push(fieldValue.value.value);
      }

      // Filter suggestions according to current value
      if (!event.query.trim().length) {
        dataSource.value = [...sendTestItems.value];
      } else {
        dataSource.value = sendTestItems.value.filter((item) => item.value.toLowerCase().startsWith(event.query.toLowerCase()) && !current.includes(item.value));
      }

      if (type.value === TypeSendTestEnum.EMAIL) {
        // We use the current shop domains to return additional values
        const currentSearch: string = event.query;
        const explode = currentSearch.split('@');
        const after = explode.length > 1 ? explode.pop() : '';
        const before = explode.join('@');

        // We check if we can add the domains of the shop
        domainsOfShop.value.forEach((domain: ShopsSendDomains) => {
          const returnValue = `${before}@${domain.domain}`;
          const found = dataSource.value.findIndex((el) => (el as Suggestion).value === returnValue);

          if (!current.includes(returnValue) && found === -1 && ((after && after.trim() === '') || (domain.domain.match(new RegExp(`^${after}`))))) {
            const suggestion: Suggestion = {
              key: returnValue,
              value: returnValue,
            };

            dataSource.value.unshift(suggestion);
          }
        });

        // We also include the current search if not in array
        if (dataSource.value.findIndex((el) => (el as Suggestion).value === currentSearch) === -1) {
          dataSource.value.unshift({
            key: currentSearch,
            value: currentSearch,
          });
        }
      } else if (type.value === TypeSendTestEnum.SMS) {
        // We add the current search to the results
        const phoneNumber = event.query.trim().replace(new RegExp('[^0-9]', 'g'), '');
        dataSource.value.push({
          key: phoneNumber,
          value: phoneNumber,
        });
      }
    };

    // Validation function when the user select an item (used to check for valid email or phone number)
    const validateItems = (items: Suggestion|Suggestion[]) => {
      let valid = true;

      if (type.value === TypeSendTestEnum.EMAIL) {
        if (Array.isArray(items) && items.length > 0) {
          items.every((item: Suggestion) => {
            if (!isValidEmail(item.value)) {
              // We check if the value is an e-mail
              valid = false;
              return false;
            }

            return true;
          });
        } else {
          valid = false;
        }
      } else if (type.value === TypeSendTestEnum.SMS && !Array.isArray(items) && (!items.value || !isValidPhoneNumber(items.value, countryAndState.value.country))) {
        valid = false;
      }

      return valid;
    };

    // Vuelidate elements for value validation
    const state = reactive({
      fieldValue,
    });
    const rules = {
      fieldValue: {
        required,
        validateItems: helpers.withMessage(t(errorMessage.value), validateItems),
      },
    };
    const v$ = useVuelidate(rules, state);

    // Modal close event
    const handleClose = () => {
      // We reset the field value and the validator
      fieldValue.value = [];
      v$.value.$reset();
      context.emit('on-close-dialog');
      store.commit('general/hideSendTestModal');
    };

    // Modal validation event
    const handleValidation = async () => {
      // We store the new items in the database
      const newConfigurationValue: string[] = [];
      sendTestItems.value.forEach((item: Suggestion) => {
        newConfigurationValue.push(item.value);
      });

      let insert = false;
      if (Array.isArray(fieldValue.value)) {
        fieldValue.value.forEach((input: Suggestion) => {
          if (!newConfigurationValue.includes(input.value)) {
            newConfigurationValue.push(input.value);
            insert = true;
          }
        });
      } else if (fieldValue.value && fieldValue.value.value !== '') {
        if (!newConfigurationValue.includes(fieldValue.value.value)) {
          newConfigurationValue.push(fieldValue.value.value);
          insert = true;
        }
      }

      if (insert && newConfigurationValue.length) {
        let key = '';
        if (type.value === TypeSendTestEnum.EMAIL) {
          key = 'select2_list_emails_to_address';
        } else if (type.value === TypeSendTestEnum.SMS) {
          key = 'select2_list_sms_to_number';
        }

        const configuration: ShopsConfigurationInput = {
          id_shop: UserState.activeShop?.id ?? 0,
          configs: [
            {
              key,
              value: JSON.stringify(newConfigurationValue),
              lang: '',
            },
          ],
        };

        await SaveShopsConfiguration(configuration);
      }

      if (type.value === TypeSendTestEnum.SMS && !Array.isArray(fieldValue.value) && fieldValue.value) {
        fieldValue.value.country = countryAndState.value.country;
      }

      context.emit('on-validate', fieldValue.value);
      handleClose();
    };

    // If template type changes, we change the available items
    watch(type, async (newType, oldType) => {
      if (newType !== oldType) {
        // If we change the type of template, we get the proper items, we reset the field value and the validator
        sendTestItems.value = await getItems();
        fieldValue.value = null;
        v$.value.$reset();
      }
    });

    watch(() => store.state.general.sendTestModalVisible, () => {
      if (store.state.general.sendTestModalVisible) {
        getItems().then((data) => {
          sendTestItems.value = data;
        });

        getShopsDomains(0).then((data) => {
          domainsOfShop.value = data;
        });

        nextTick().then(() => {
          if (autocompleteRef.value) {
            const input = autocompleteRef.value.$el.querySelector('.sendtest-modal-autocomplete__input');
            if (input) {
              setTimeout(() => {
                input.focus();
              }, 0);
            }
          }
        });
      }
    });

    return {
      t,
      v$,
      displayModal,
      dataSource,
      type,
      countryAndState,
      search,
      handleClose,
      handleValidation,
      autocompleteRef,
    };
  },
});
</script>

<style scoped lang="scss">
.p-autocomplete {
  width: 100%;

  &:deep() .p-inputtext {
    width: 100%;
  }
}

.CountryDropdown:deep() {
  & .CountriesList {
    padding: 0 !important;
  }

  & .p-dropdown {
    border-right-width: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    & .p-dropdown-label {
      padding: 0.23rem 0.5rem;
    }
  }
}

.country-dropdown-container, .autocomplete-container {
  flex: 0 0 auto;
  padding: 0.5rem 0;
}

.country-dropdown-container {
  width: 18%;
}

.autocomplete-container {
  width: 82%;

  .p-autocomplete {
    &:deep() .p-inputtext {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>
