<template>
  <Card
    v-if="selectedData.isAnonymous"
    class="cart-file-card"
  >
    <template #content>
      <div class="flex justify-content-center align-items-center text-2xl">
        {{ t('dataExplorer.anonymous') }}
      </div>
    </template>
  </Card>
  <Card
    v-else
    class="cart-file-card"
  >
    <template #title>
      <div class="flex flex-column md:flex-row md:align-items-center md:justify-content-between">
        <div class="flex align-items-start">
          <div>
            <div class="text-xl font-medium text-900 mb-2">
              {{ title }}
            </div>
            <div
              class="font-medium text-500 text-sm"
            >
              {{ t('dataExplorer.orderFile.fields.id') }} : {{ selectedData.idCart }}
            </div>
            <div
              class="flex flex-column gap-2 font-medium text-sm mt-2"
            >
              <span>
                {{ t('dataExplorer.cartFile.fields.isValid.title') }} :
                {{ selectedData.isValid && selectedData.isValid !== '0' ?
                  t('dataExplorer.cartFile.fields.isValid.value.yes') :
                  t('dataExplorer.cartFile.fields.isValid.value.no') }}
              </span>
              <DataExplorerLink
                v-if="selectedData.isValid && selectedData.isValid !== '0'"
                :id="selectedData.isValid"
                :value="selectedData.isValid"
                :type="dataExplorerType.ORDER_FILE"
                :label="t('dataExplorer.cartFile.fields.orderId')"
                custom-key="id_order"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-column gap-2">
          <div>
            <span class="text-base font-medium">
              {{ t('dataExplorer.cartFile.fields.creationDate') }} : {{ formatDate(selectedData.date, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY HH:mm:ss') }}
            </span>
          </div>
          <div>
            <span class="text-base font-medium">
              {{ t('dataExplorer.cartFile.fields.modificationDate') }} : {{ formatDate(selectedData.dateModification, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY HH:mm:ss') }}
            </span>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="grid grid-nogutter border-top-1 surface-border pt-2">
        <div class="flex flex-column gap-2 col-12 lg:col-4 p-3">
          <!-- Customers informations -->
          <div>
            <SectionTitle
              :title="t('dataExplorer.orderFile.fields.customerInformations')"
            />
            <div class="col-12">
              <div class="text-500 font-medium mb-2">
                {{ t('dataExplorer.orderFile.fields.customer') }}
              </div>
              <div class="flex gap-1 text-900">
                <span class="capitalize"> {{ selectedData.firstname }} {{ (selectedData.lastname || '').toUpperCase() }} </span>
                <span
                  v-if="selectedData.idCustomerShop"
                >
                  {{ `(${selectedData.idCustomerShop})` }}
                </span>
              </div>
            </div>
            <div class="col-12">
              <div class="text-500 font-medium mb-2">
                {{ t('dataExplorer.orderFile.fields.email') }}
              </div>
              <div class="text-900">
                <DataExplorerLink
                  :id="selectedData.idShopCustomer"
                  :value="selectedData.email"
                  :type="dataExplorerType.CONTACT_FILE"
                  :show-icon="true"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 lg:col-8 p-3">
          <div class="flex flex-column gap-2">
            <SectionTitle
              :title="t('dataExplorer.cartFile.fields.cartDetails')"
            />
            <div>
              <List
                :list="selectedData.products.data"
                :retrieve-data="getCartProducts"
                :retrieve-data-arguments="[selectedData.idShopCart, locale]"
                :data-loaded="selectedData.products.dataLoaded"
                :no-column="true"
                key-name="idShopCartProduct"
                @loaded="handleRetrieveProducts"
              >
                <template #content="{ item }">
                  <ProductItem
                    :product="item"
                  />
                </template>
              </List>
            </div>
            <div class="flex flex-wrap justify-content-end mt-3 pb-3">
              <div class="w-full lg:w-8 pl-3 lg:pl-0 lg:pr-3 flex align-items-end mt-5 lg:mt-0">
                <ul class="list-none p-0 m-0 w-full">
                  <li class="mb-4">
                    <span class="font-semibold text-900">{{ t('dataExplorer.orderFile.fields.summary') }}</span>
                  </li>
                  <li
                    v-if="selectedData.voucherAmount"
                    class="flex justify-content-between mb-4"
                  >
                    <div class="flex flex-column gap-1">
                      <span class="text-900">{{ t('dataExplorer.orderFile.fields.voucherAmount') }}</span>
                      <!-- <div class="flex gap-2">
                        <Chip
                          v-for="(voucher, index) in vouchers"
                          :key="voucher"
                          :label="voucher"
                        />
                      </div> -->
                    </div>
                    <span class="text-900 font-medium text-lg">{{ formatNumberToCurrency(selectedData.voucherAmount || 0, selectedData.currency || 'EUR') }}</span>
                  </li>
                  <li class="flex justify-content-between border-top-1 surface-border py-3">
                    <span class="text-900 font-medium">{{ t('dataExplorer.orderFile.fields.amountHT') }}</span>
                    <span class="text-900 font-medium text-lg">{{ formatNumberToCurrency(selectedData.amountHT || 0, selectedData.currency || 'EUR') }}</span>
                  </li>
                  <li class="flex justify-content-between py-3">
                    <span class="text-900 font-medium">{{ t('dataExplorer.orderFile.fields.amountTTC') }}</span>
                    <span class="font-semibold text-xl cart-price">{{ formatNumberToCurrency(selectedData.amount || 0, selectedData.currency || 'EUR') }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  ref,
} from 'vue';

import Card from 'primevue/card';
import Chip from 'primevue/chip';

import DataExplorerLink from '@/components/data-explorer/fields/DataExplorerLink.vue';
import ProductItem from '@/components/data-explorer/fields/ProductItem.vue';
import List from '@/components/data-explorer/fields/List.vue';
import SectionTitle from '@/components/data-explorer/fields/SectionTitle.vue';

import {
  getCartProducts,
} from '@/composables/data-explorer/DataExplorer';

import { formatDate } from '@/helpers/Date';
import { formatNumberToCurrency } from '@/helpers/Number';

import { DataExplorerDataType } from '@/types';

import { CartProduct } from '@/types/data-explorer-types';

import { useStore } from '@/store';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'CartFile',

  components: {
    Card,
    Chip,
    DataExplorerLink,
    List,
    SectionTitle,
    ProductItem,
  },

  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const selectedData = computed(() => store.getters['dataExplorer/getSelectedData']);

    const title = computed(() => {
      if (selectedData.value) {
        return t(`dataExplorer.tabs.${selectedData.value.dataType}`, [selectedData.value.idCart]);
      }
      return '';
    });

    const vouchers = computed(() => {
      if (selectedData.value.voucher) {
        return selectedData.value.voucher.split(',');
      }
      return [];
    });

    const dataExplorerType = ref(DataExplorerDataType);

    const handleRetrieveProducts = (cartProducts: CartProduct[]) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          newLists: cartProducts,
          dataKey: 'products',
        },
      );
    };

    return {
      t,
      locale,
      title,
      selectedData,
      vouchers,
      dataExplorerType,

      formatDate,
      formatNumberToCurrency,
      getCartProducts,
      handleRetrieveProducts,
    };
  },
});
</script>

<style lang="scss">
.cart-file-card {
  box-shadow: none !important;
  border: 1px solid lightgray;
  border-radius: 5px;
}
</style>

<style lang="scss" scoped>
.cart-price {
  color: $brand-color-primary;
  font-size: 1.5rem;
}
</style>
