
import { defineComponent, PropType, computed } from 'vue';
import PreviewRenderer from '@/components/table/renderers/PreviewRenderer.vue';
import TextRenderer from '@/components/table/renderers/TextRenderer.vue';
import ActionRenderer from '@/components/table/renderers/ActionRenderer.vue';
import ChipRenderer from '@/components/table/renderers/ChipRenderer.vue';
import UnsubRenderer from '@/components/table/renderers/UnsubRenderer.vue';
import Flag from '@/components/table/renderers/Flag.vue';
import ActiveStatusIcon from '@/components/table/renderers/ActiveStatusIcon.vue';
import { SpmTableColumns } from '@/types';
import DropdownRenderer from '@/components/table/renderers/DropdownRenderer.vue';
import DateTimeRenderer from '@/components/table/renderers/DateTimeRenderer.vue';
import MenuRenderer from '@/components/table/renderers/MenuRenderer.vue';
import StatsActionRenderer from '@/components/table/renderers/StatsActionRenderer.vue';
import { useI18n } from 'vue-i18n';
import LanguageIcons from "@/components/table/renderers/LanguageIcons.vue";
import DataExplorerLink from "@/components/data-explorer/fields/DataExplorerLink.vue";

export default defineComponent({
  name: 'Renderer',
  components: {
    LanguageIcons,
    DropdownRenderer,
    DateTimeRenderer,
    TextRenderer,
    PreviewRenderer,
    ActionRenderer,
    StatsActionRenderer,
    ChipRenderer,
    UnsubRenderer,
    ActiveStatusIcon,
    Flag,
    MenuRenderer,
    DataExplorerLink,
  },

  props: {
    index: {
      type: String,
      required: true,
    },

    col: {
      type: Object as PropType<SpmTableColumns>,
      required: true,
    },

    slotProps: {
      type: Object as PropType<any>,
      required: true,
    },
  },

  setup(props) {
    const { locale, t } = useI18n();

    const content = computed(() => {
      try {
        const slotContent = JSON.parse(props.slotProps.data[props.slotProps.column.props.field]);

        if (locale.value in slotContent) {
          return slotContent[locale.value];
        }

        return props.slotProps.data[props.slotProps.column.props.field];
      } catch (error) {
        return props.slotProps.data[props.slotProps.column.props.field];
      }
    });

    return {
      t,
      content,
    };
  },

});
