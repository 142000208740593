<template>
  <label
    class="block my-3"
    data-test-id="action-type-label"
  >
    {{ t(configs.label) }}
  </label>

  <div
    class="block text-center"
  >
    <Button
      id="addNewButton"
      class="p-button-secondary mt-3"
      :label="t('templateBuilder.fields.fbButtons.add')"
      icon="far fa-plus"
      icon-pos="left"
      :disabled="buttons.value.length === configs.options.maxButtons"
      @click="addButton"
    />
  </div>
  <div
    v-for="(button, index) in buttons.value"
    :key="index"
  >
    <Panel
      class="mt-3 facebook-buttons-panel"
      :header="t('templateBuilder.fields.fbButtons.buttonN', {
        'index': (index+1)
      })"
    >
      <template #icons>
        <Button
          class="p-panel-header-icon p-link mr-2"
          @click="removeButton(index)"
        >
          <span class="far fa-trash" />
        </Button>
      </template>

      <div class="field-group pt-3">
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <label class="block mb-3">
                {{ t('templateBuilder.fields.fbButtons.label') }}
              </label>
              <div class="p-fluid flex">
                <InputText
                  class="input-text"
                  :model-value="button.text"
                  type="text"
                  maxlength="20"
                  @keyup="(eventParameters) => handleChangeButtonLabel(index, eventParameters)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="field-group pt-3">
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <label class="block mb-3">
                {{ t('templateBuilder.fields.fbButtons.action') }}
              </label>
              <div class="p-fluid flex">
                <Dropdown
                  v-model="button.action"
                  class="col-12 p-0 mb-3"
                  :options="actionTypeOptions"
                  option-label="label"
                  option-value="value"
                  data-test-id="action-type-dropdown"
                  @change="(eventParameters) => handleChangeActionType(index, eventParameters)"
                />
              </div>
              <div
                v-if="button.action === ActionTypeEnum.REDIRECT"
                class="p-fluid flex"
              >
                <InputVariables
                  class="col-12 p-0 mb-3"
                  :configs="configs.options.redirectConfig"
                  :parser-values="dataRedirectParserValues[index]"
                  @on-change-properties="(emitParameters) => handleChangeDataRedirect(index, emitParameters)"
                />
              </div>
              <div
                v-if="button.action === ActionTypeEnum.DISPLAY_TEMPLATE"
                class="p-fluid flex text-center"
              >
                <Button
                  class="col-12 mb-3"
                  :label="t('templateBuilder.chooseTemplate')"
                  @click="indexForTemplateModal=index; displayTemplateModal=true"
                />
              </div>
              <div
                v-if="button.selectedTemplate"
                class="p-fluid text-center p-2 selected-template"
              >
                <label class="mb-2 template-label">
                  {{ button.selectedTemplate.label }}
                </label>
                <div class="preview-container">
                  <img
                    :src="imageUrl"
                    alt="alt"
                    class="product-image"
                  >
                  <Button
                    :label="t('templates.preview')"
                    icon="far fa-check"
                    class="p-button-primary"
                    @click="displayTemplatePreview = true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Panel>
  </div>
  <ChooseTemplateModal
    :display-dialog="displayTemplateModal"
    @on-choose-template="onChooseTemplate"
    @on-close-dialog="displayTemplateModal=false; indexForTemplateModal=-1"
  />
  <Dialog
    v-model:visible="displayTemplatePreview"
    :header="t('templates.preview')"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '30vw' }"
    :content-style="{ textAlign: 'center' }"
  >
    <img
      height="500"
      :src="imageUrl"
      :alt="t('templates.preview')"
    >
  </Dialog>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, PropType, Ref, ref, watch,
} from 'vue';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Panel from 'primevue/panel';
import Dialog from 'primevue/dialog';
import { useI18n } from 'vue-i18n';
import {
  DropdownOption,
  FieldConfig,
  ActionTypeEnum,
  ParserFieldObject,
  Property,
  FacebookMessengerButton,
} from '@/types';
import InputVariables from '@/components/template-builder/fields/InputVariables.vue';
import ChooseTemplateModal from '@/components/modals/ChooseTemplateModal.vue';
import { Templates } from '@/types/generated-types/graphql';
import { Get } from '@/composables/GraphQL';
import { getAttributeByName } from '@/components/template-builder/getters';
import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import { store } from '@/store';

export default defineComponent({
  name: 'FacebookButtons',

  components: {
    LocalizedTextInput,
    ChooseTemplateModal,
    Panel,
    Dropdown,
    Button,
    Dialog,
    InputVariables,
    InputText,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const imageUrl = 'https://media.shopimind.io/img/templates_preview_v3/35b251d8f5dac17f80b3a2b34b9aae1abe24190b.png';
    const displayTemplateModal = ref(false);
    const displayTemplatePreview = ref(false);
    const indexForTemplateModal = ref(-1);

    const redirectTypes = ref(props.configs.options.redirectTypes);
    const properties: Ref<Array<Property>> = ref(props.parserValues.properties);
    const buttons: Ref<Property> = ref(properties.value.filter((property: Property) => property.name === 'buttons')[0]);

    const dataRedirectParserValues = computed((): Array<ParserFieldObject> => {
      const res: Array<ParserFieldObject> = [];

      buttons.value.value.forEach((button: FacebookMessengerButton, index: number) => {
        const parserValues: ParserFieldObject = {
          selector: `${props.parserValues.selector} .button:nth-child(${index})`,
          properties: [{
            name: 'data-redirect', getter: getAttributeByName, setters: [], value: button.dataRedirect,
          }],
        };

        res.push(parserValues);
      });

      return res;
    });

    const actionTypeOptions: DropdownOption[] = redirectTypes.value
      .map((key: string) => ({ value: key, label: t(`templateBuilder.fields.actionOptions.${key}`) }));

    // Handle each change after specific operations
    // eslint-disable-next-line no-shadow
    const handleChange = ({ selector, properties }: { selector: string; properties: Array<Property> }) => {
      context.emit('on-change-properties', {
        selector,
        properties,
      });
    };

    // Handle button label change
    const handleChangeButtonLabel = (index: number, params: any) => {
      buttons.value.value[index].text = params.target.value.trim();
      handleChange({ selector: props.parserValues.selector, properties: [buttons.value] });
    };

    // Handle button action change
    const handleChangeActionType = (index: number, { value }: { value: string }) => {
      buttons.value.value[index].action = value;
      buttons.value.value[index].dataRedirect = '';
      buttons.value.value[index].selectedTemplate = null;
      handleChange({ selector: props.parserValues.selector, properties: [buttons.value] });
    };

    // Handle button url change
    // eslint-disable-next-line no-shadow
    const handleChangeDataRedirect = (index: number, { properties }: { properties: Array<Property> }) => {
      const property = { ...properties[0] };
      buttons.value.value[index].dataRedirect = property.value;
      handleChange({ selector: props.parserValues.selector, properties: [buttons.value] });
    };

    // Handle template choosing action
    const onChooseTemplate = (template: Templates) => {
      const index = indexForTemplateModal.value;
      buttons.value.value[index].dataRedirect = template.id_template.toString();
      buttons.value.value[index].selectedTemplate = template;
      handleChange({ selector: props.parserValues.selector, properties: [buttons.value] });
      store.commit('general/hideTheSpinner');
    };

    const addButton = () => {
      const newButton: FacebookMessengerButton = {
        action: ActionTypeEnum.REDIRECT,
        dataRedirect: '',
        text: t('templateBuilder.fields.fbButtons.label'),
        selectedTemplate: null,
      };

      buttons.value.value.push(newButton);
      handleChange({ selector: props.parserValues.selector, properties: [buttons.value] });
    };

    const removeButton = (index: number) => {
      buttons.value.value.splice(index, 1);
      handleChange({ selector: props.parserValues.selector, properties: [buttons.value] });
    };

    watch(() => buttons.value, async (values, oldValues) => {
      if (values !== oldValues) {
        for await (const button of buttons.value.value) {
          const index: number = buttons.value.value.indexOf(button);

          // We check if the current button is set to redirect to template
          if (button.action === ActionTypeEnum.DISPLAY_TEMPLATE) {
            // Retrieve template informations
            const { item, err } = await Get<Templates>({
              name: 'Templates',
              id: button.dataRedirect,
              keyName: 'id_template',
              fields: ['id_template', 'label', 'type'],
            });

            if (item) {
              buttons.value.value[index].selectedTemplate = item;
            }
          }
        }
      }
    }, { immediate: true });

    return {
      t,
      imageUrl,
      actionTypeOptions,
      displayTemplateModal,
      indexForTemplateModal,
      buttons,
      displayTemplatePreview,
      ActionTypeEnum,
      dataRedirectParserValues,
      onChooseTemplate,
      handleChangeButtonLabel,
      handleChangeActionType,
      handleChangeDataRedirect,
      addButton,
      removeButton,
    };
  },
});
</script>

<style scoped lang="scss">
$img-size: 12rem;

:deep() .facebook-buttons-panel {
  &.p-panel {
    & .p-panel-header {
      border: solid 1px $solitude;
      padding: 0.5rem !important;
    }

    & .p-panel-content {
      border: solid 1px $solitude !important;
      border-top: none !important;
    }
  }
}

.product-image {
  width: $img-size;
  height: $img-size;
  object-fit: cover;
  object-position: bottom;
}

.preview-container {
  position: relative;
  width: $img-size;
  height: $img-size;
  margin: 1rem auto 0 !important;
  &:hover {
    &::after {
      content: '';
      background: rgba(0, 0, 0, .3);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .p-button {
      display: block;
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
    }
  }

  .p-button {
    display: none;
  }
}
.selected-template {
  margin: auto;
  border: 1px solid $solitude !important;
  text-align: center;
  background-color: $white;
}
</style>
