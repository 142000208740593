
import {
  computed, ComputedRef, defineComponent, PropType, Ref, ref,
} from 'vue';
import { SpmTableAction, TypeCampaignEnum } from '@/types';
import Tooltip from 'primevue/tooltip';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';

export default defineComponent({
  name: 'StatsActionRenderer',

  components: {
    SpmOverlayPanel,
    SpmPanelMenu,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    actions: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => [],
    },

    column: {
      type: Object,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },
  },

  setup(props: { actions: string[]; column: any; data: any }) {
    const { t } = useI18n();
    const store = useStore();

    const items: ComputedRef<SpmTableAction[]> = computed(() => props.actions.map((action: string) => {
      const actionObject: SpmTableAction = {
        icon: '',
        label: '',
        show: () => true,
      };

      switch (action) {
        case 'previewSentTemplate':
          actionObject.icon = 'far fa-eye';
          actionObject.label = t(`stats.actions.${action}`);
          actionObject.command = () => {
            const payload = {
              component: 'DataViewerPreviewSentTemplate',
              title: t('dataViewer.titles.sentId', {
                id: props.data[props.column.field],
              }),
              configuration: {
                typeCampaign: (new RegExp('_bulk_')).test(props.column.field) ? TypeCampaignEnum.BULK : TypeCampaignEnum.AUTOMATION,
                id: props.data[props.column.field],
                displayHeatMap: true,
                displayStatistics: true,
              },
            };

            store.commit('dataViewer/addActiveElement', payload);
          };
          break;
        default:
          console.error(`Undefined action ${action} in StatsActionRenderer`);
          break;
      }

      return actionObject;
    }).filter((action) => action.label !== ''));

    return { items };
  },

});
